@charset "UTF-8";
/*!
 * Tabler Icons 3.20.0 by tabler - https://tabler.io
 * License - https://github.com/tabler/tabler-icons/blob/master/LICENSE
 */
$ti-font-family: 'tabler-icons' !default;
$ti-font-path: './fonts' !default;
$ti-font-display: null !default;
$ti-prefix: 'ti' !default;

@font-face {
  font-family: $ti-font-family;
  font-style: normal;
  font-weight: 400;
  font-display: $ti-font-display;
  src: url('#{$ti-font-path}/tabler-icons.woff2?v3.20.0') format('woff2'),
    url('#{$ti-font-path}/tabler-icons.woff?') format('woff'),
    url('#{$ti-font-path}/tabler-icons.ttf?v3.20.0') format('truetype');
}

.#{$ti-prefix} {
  font-family: $ti-font-family !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@function unicode($str) {
  @return unquote("\"")+unquote(str-insert($str, "\\", 1))+unquote("\"")
}


$ti-icon-a-b: unicode('ec36');
$ti-icon-a-b-2: unicode('f25f');
$ti-icon-a-b-off: unicode('f0a6');
$ti-icon-abacus: unicode('f05c');
$ti-icon-abacus-off: unicode('f3b6');
$ti-icon-abc: unicode('f567');
$ti-icon-access-point: unicode('ed1b');
$ti-icon-access-point-off: unicode('ed1a');
$ti-icon-accessible: unicode('eba9');
$ti-icon-accessible-filled: unicode('f6ea');
$ti-icon-accessible-off: unicode('f0a7');
$ti-icon-activity: unicode('ed23');
$ti-icon-activity-heartbeat: unicode('f0db');
$ti-icon-ad: unicode('ea02');
$ti-icon-ad-2: unicode('ef1f');
$ti-icon-ad-circle: unicode('f79e');
$ti-icon-ad-circle-filled: unicode('f7d3');
$ti-icon-ad-circle-off: unicode('f79d');
$ti-icon-ad-filled: unicode('f6eb');
$ti-icon-ad-off: unicode('f3b7');
$ti-icon-address-book: unicode('f021');
$ti-icon-address-book-off: unicode('f3b8');
$ti-icon-adjustments: unicode('ea03');
$ti-icon-adjustments-alt: unicode('ec37');
$ti-icon-adjustments-bolt: unicode('f7fb');
$ti-icon-adjustments-cancel: unicode('f7fc');
$ti-icon-adjustments-check: unicode('f7fd');
$ti-icon-adjustments-code: unicode('f7fe');
$ti-icon-adjustments-cog: unicode('f7ff');
$ti-icon-adjustments-dollar: unicode('f800');
$ti-icon-adjustments-down: unicode('f801');
$ti-icon-adjustments-exclamation: unicode('f802');
$ti-icon-adjustments-filled: unicode('f6ec');
$ti-icon-adjustments-heart: unicode('f803');
$ti-icon-adjustments-horizontal: unicode('ec38');
$ti-icon-adjustments-minus: unicode('f804');
$ti-icon-adjustments-off: unicode('f0a8');
$ti-icon-adjustments-pause: unicode('f805');
$ti-icon-adjustments-pin: unicode('f806');
$ti-icon-adjustments-plus: unicode('f807');
$ti-icon-adjustments-question: unicode('f808');
$ti-icon-adjustments-search: unicode('f809');
$ti-icon-adjustments-share: unicode('f80a');
$ti-icon-adjustments-spark: unicode('ffbe');
$ti-icon-adjustments-star: unicode('f80b');
$ti-icon-adjustments-up: unicode('f80c');
$ti-icon-adjustments-x: unicode('f80d');
$ti-icon-aerial-lift: unicode('edfe');
$ti-icon-affiliate: unicode('edff');
$ti-icon-affiliate-filled: unicode('f6ed');
$ti-icon-ai: unicode('fee7');
$ti-icon-air-balloon: unicode('f4a6');
$ti-icon-air-conditioning: unicode('f3a2');
$ti-icon-air-conditioning-disabled: unicode('f542');
$ti-icon-air-traffic-control: unicode('fb01');
$ti-icon-alarm: unicode('ea04');
$ti-icon-alarm-average: unicode('fc9e');
$ti-icon-alarm-filled: unicode('f709');
$ti-icon-alarm-minus: unicode('f630');
$ti-icon-alarm-minus-filled: unicode('f70a');
$ti-icon-alarm-off: unicode('f0a9');
$ti-icon-alarm-plus: unicode('f631');
$ti-icon-alarm-plus-filled: unicode('f70b');
$ti-icon-alarm-snooze: unicode('f632');
$ti-icon-alarm-snooze-filled: unicode('f70c');
$ti-icon-album: unicode('f022');
$ti-icon-album-off: unicode('f3b9');
$ti-icon-alert-circle: unicode('ea05');
$ti-icon-alert-circle-filled: unicode('f6ee');
$ti-icon-alert-circle-off: unicode('fc65');
$ti-icon-alert-hexagon: unicode('f80e');
$ti-icon-alert-hexagon-filled: unicode('fa34');
$ti-icon-alert-hexagon-off: unicode('fc66');
$ti-icon-alert-octagon: unicode('ecc6');
$ti-icon-alert-octagon-filled: unicode('f6ef');
$ti-icon-alert-small: unicode('f80f');
$ti-icon-alert-small-off: unicode('fc67');
$ti-icon-alert-square: unicode('f811');
$ti-icon-alert-square-filled: unicode('fa35');
$ti-icon-alert-square-rounded: unicode('f810');
$ti-icon-alert-square-rounded-filled: unicode('fa36');
$ti-icon-alert-square-rounded-off: unicode('fc68');
$ti-icon-alert-triangle: unicode('ea06');
$ti-icon-alert-triangle-filled: unicode('f6f0');
$ti-icon-alert-triangle-off: unicode('fc69');
$ti-icon-alien: unicode('ebde');
$ti-icon-alien-filled: unicode('f70d');
$ti-icon-align-box-bottom-center: unicode('f530');
$ti-icon-align-box-bottom-center-filled: unicode('f70e');
$ti-icon-align-box-bottom-left: unicode('f531');
$ti-icon-align-box-bottom-left-filled: unicode('f70f');
$ti-icon-align-box-bottom-right: unicode('f532');
$ti-icon-align-box-bottom-right-filled: unicode('f710');
$ti-icon-align-box-center-bottom: unicode('facb');
$ti-icon-align-box-center-middle: unicode('f79f');
$ti-icon-align-box-center-middle-filled: unicode('f7d4');
$ti-icon-align-box-center-stretch: unicode('facc');
$ti-icon-align-box-center-top: unicode('facd');
$ti-icon-align-box-left-bottom: unicode('f533');
$ti-icon-align-box-left-bottom-filled: unicode('f711');
$ti-icon-align-box-left-middle: unicode('f534');
$ti-icon-align-box-left-middle-filled: unicode('f712');
$ti-icon-align-box-left-stretch: unicode('face');
$ti-icon-align-box-left-top: unicode('f535');
$ti-icon-align-box-left-top-filled: unicode('f713');
$ti-icon-align-box-right-bottom: unicode('f536');
$ti-icon-align-box-right-bottom-filled: unicode('f714');
$ti-icon-align-box-right-middle: unicode('f537');
$ti-icon-align-box-right-middle-filled: unicode('f7d5');
$ti-icon-align-box-right-stretch: unicode('facf');
$ti-icon-align-box-right-top: unicode('f538');
$ti-icon-align-box-right-top-filled: unicode('f715');
$ti-icon-align-box-top-center: unicode('f539');
$ti-icon-align-box-top-center-filled: unicode('f716');
$ti-icon-align-box-top-left: unicode('f53a');
$ti-icon-align-box-top-left-filled: unicode('f717');
$ti-icon-align-box-top-right: unicode('f53b');
$ti-icon-align-box-top-right-filled: unicode('f718');
$ti-icon-align-center: unicode('ea07');
$ti-icon-align-justified: unicode('ea08');
$ti-icon-align-left: unicode('ea09');
$ti-icon-align-left-2: unicode('ff00');
$ti-icon-align-right: unicode('ea0a');
$ti-icon-align-right-2: unicode('feff');
$ti-icon-alpha: unicode('f543');
$ti-icon-alphabet-arabic: unicode('ff2f');
$ti-icon-alphabet-bangla: unicode('ff2e');
$ti-icon-alphabet-cyrillic: unicode('f1df');
$ti-icon-alphabet-greek: unicode('f1e0');
$ti-icon-alphabet-hebrew: unicode('ff2d');
$ti-icon-alphabet-korean: unicode('ff2c');
$ti-icon-alphabet-latin: unicode('f1e1');
$ti-icon-alphabet-thai: unicode('ff2b');
$ti-icon-alt: unicode('fc54');
$ti-icon-ambulance: unicode('ebf5');
$ti-icon-ampersand: unicode('f229');
$ti-icon-analyze: unicode('f3a3');
$ti-icon-analyze-filled: unicode('f719');
$ti-icon-analyze-off: unicode('f3ba');
$ti-icon-anchor: unicode('eb76');
$ti-icon-anchor-off: unicode('f0f7');
$ti-icon-angle: unicode('ef20');
$ti-icon-ankh: unicode('f1cd');
$ti-icon-antenna: unicode('f094');
$ti-icon-antenna-bars-1: unicode('ecc7');
$ti-icon-antenna-bars-2: unicode('ecc8');
$ti-icon-antenna-bars-3: unicode('ecc9');
$ti-icon-antenna-bars-4: unicode('ecca');
$ti-icon-antenna-bars-5: unicode('eccb');
$ti-icon-antenna-bars-off: unicode('f0aa');
$ti-icon-antenna-off: unicode('f3bb');
$ti-icon-aperture: unicode('eb58');
$ti-icon-aperture-off: unicode('f3bc');
$ti-icon-api: unicode('effd');
$ti-icon-api-app: unicode('effc');
$ti-icon-api-app-off: unicode('f0ab');
$ti-icon-api-off: unicode('f0f8');
$ti-icon-app-window: unicode('efe6');
$ti-icon-app-window-filled: unicode('f71a');
$ti-icon-apple: unicode('ef21');
$ti-icon-apple-filled: unicode('10017');
$ti-icon-apps: unicode('ebb6');
$ti-icon-apps-filled: unicode('f6f1');
$ti-icon-apps-off: unicode('f0ac');
$ti-icon-archery-arrow: unicode('fc55');
$ti-icon-archive: unicode('ea0b');
$ti-icon-archive-filled: unicode('fa82');
$ti-icon-archive-off: unicode('f0ad');
$ti-icon-armchair: unicode('ef9e');
$ti-icon-armchair-2: unicode('efe7');
$ti-icon-armchair-2-off: unicode('f3bd');
$ti-icon-armchair-off: unicode('f3be');
$ti-icon-arrow-autofit-content: unicode('ef31');
$ti-icon-arrow-autofit-content-filled: unicode('f6f2');
$ti-icon-arrow-autofit-down: unicode('ef32');
$ti-icon-arrow-autofit-height: unicode('ef33');
$ti-icon-arrow-autofit-left: unicode('ef34');
$ti-icon-arrow-autofit-right: unicode('ef35');
$ti-icon-arrow-autofit-up: unicode('ef36');
$ti-icon-arrow-autofit-width: unicode('ef37');
$ti-icon-arrow-back: unicode('ea0c');
$ti-icon-arrow-back-up: unicode('eb77');
$ti-icon-arrow-back-up-double: unicode('f9ec');
$ti-icon-arrow-badge-down: unicode('f60b');
$ti-icon-arrow-badge-down-filled: unicode('f7d6');
$ti-icon-arrow-badge-left: unicode('f60c');
$ti-icon-arrow-badge-left-filled: unicode('f7d7');
$ti-icon-arrow-badge-right: unicode('f60d');
$ti-icon-arrow-badge-right-filled: unicode('f7d8');
$ti-icon-arrow-badge-up: unicode('f60e');
$ti-icon-arrow-badge-up-filled: unicode('f7d9');
$ti-icon-arrow-bar-both: unicode('fadd');
$ti-icon-arrow-bar-down: unicode('ea0d');
$ti-icon-arrow-bar-left: unicode('ea0e');
$ti-icon-arrow-bar-right: unicode('ea0f');
$ti-icon-arrow-bar-to-down: unicode('ec88');
$ti-icon-arrow-bar-to-left: unicode('ec89');
$ti-icon-arrow-bar-to-right: unicode('ec8a');
$ti-icon-arrow-bar-to-up: unicode('ec8b');
$ti-icon-arrow-bar-up: unicode('ea10');
$ti-icon-arrow-bear-left: unicode('f045');
$ti-icon-arrow-bear-left-2: unicode('f044');
$ti-icon-arrow-bear-right: unicode('f047');
$ti-icon-arrow-bear-right-2: unicode('f046');
$ti-icon-arrow-big-down: unicode('edda');
$ti-icon-arrow-big-down-filled: unicode('f6c6');
$ti-icon-arrow-big-down-line: unicode('efe8');
$ti-icon-arrow-big-down-line-filled: unicode('f6c7');
$ti-icon-arrow-big-down-lines: unicode('efe9');
$ti-icon-arrow-big-down-lines-filled: unicode('f6c8');
$ti-icon-arrow-big-left: unicode('eddb');
$ti-icon-arrow-big-left-filled: unicode('f6c9');
$ti-icon-arrow-big-left-line: unicode('efea');
$ti-icon-arrow-big-left-line-filled: unicode('f6ca');
$ti-icon-arrow-big-left-lines: unicode('efeb');
$ti-icon-arrow-big-left-lines-filled: unicode('f6cb');
$ti-icon-arrow-big-right: unicode('eddc');
$ti-icon-arrow-big-right-filled: unicode('f6cc');
$ti-icon-arrow-big-right-line: unicode('efec');
$ti-icon-arrow-big-right-line-filled: unicode('f6cd');
$ti-icon-arrow-big-right-lines: unicode('efed');
$ti-icon-arrow-big-right-lines-filled: unicode('f6ce');
$ti-icon-arrow-big-up: unicode('eddd');
$ti-icon-arrow-big-up-filled: unicode('f6cf');
$ti-icon-arrow-big-up-line: unicode('efee');
$ti-icon-arrow-big-up-line-filled: unicode('f6d0');
$ti-icon-arrow-big-up-lines: unicode('efef');
$ti-icon-arrow-big-up-lines-filled: unicode('f6d1');
$ti-icon-arrow-bounce: unicode('f3a4');
$ti-icon-arrow-capsule: unicode('fade');
$ti-icon-arrow-curve-left: unicode('f048');
$ti-icon-arrow-curve-right: unicode('f049');
$ti-icon-arrow-down: unicode('ea16');
$ti-icon-arrow-down-bar: unicode('ed98');
$ti-icon-arrow-down-circle: unicode('ea11');
$ti-icon-arrow-down-circle-filled: unicode('1003b');
$ti-icon-arrow-down-dashed: unicode('1006a');
$ti-icon-arrow-down-from-arc: unicode('fd86');
$ti-icon-arrow-down-left: unicode('ea13');
$ti-icon-arrow-down-left-circle: unicode('ea12');
$ti-icon-arrow-down-rhombus: unicode('f61d');
$ti-icon-arrow-down-rhombus-filled: unicode('1003a');
$ti-icon-arrow-down-right: unicode('ea15');
$ti-icon-arrow-down-right-circle: unicode('ea14');
$ti-icon-arrow-down-square: unicode('ed9a');
$ti-icon-arrow-down-square-filled: unicode('10039');
$ti-icon-arrow-down-tail: unicode('ed9b');
$ti-icon-arrow-down-to-arc: unicode('fd87');
$ti-icon-arrow-elbow-left: unicode('f9ed');
$ti-icon-arrow-elbow-right: unicode('f9ee');
$ti-icon-arrow-fork: unicode('f04a');
$ti-icon-arrow-forward: unicode('ea17');
$ti-icon-arrow-forward-up: unicode('eb78');
$ti-icon-arrow-forward-up-double: unicode('f9ef');
$ti-icon-arrow-guide: unicode('f22a');
$ti-icon-arrow-guide-filled: unicode('10038');
$ti-icon-arrow-iteration: unicode('f578');
$ti-icon-arrow-left: unicode('ea19');
$ti-icon-arrow-left-bar: unicode('ed9c');
$ti-icon-arrow-left-circle: unicode('ea18');
$ti-icon-arrow-left-circle-filled: unicode('10037');
$ti-icon-arrow-left-dashed: unicode('10069');
$ti-icon-arrow-left-from-arc: unicode('fd88');
$ti-icon-arrow-left-rhombus: unicode('f61e');
$ti-icon-arrow-left-rhombus-filled: unicode('10036');
$ti-icon-arrow-left-right: unicode('f04b');
$ti-icon-arrow-left-square: unicode('ed9d');
$ti-icon-arrow-left-square-filled: unicode('10035');
$ti-icon-arrow-left-tail: unicode('ed9e');
$ti-icon-arrow-left-to-arc: unicode('fd89');
$ti-icon-arrow-loop-left: unicode('ed9f');
$ti-icon-arrow-loop-left-2: unicode('f04c');
$ti-icon-arrow-loop-right: unicode('eda0');
$ti-icon-arrow-loop-right-2: unicode('f04d');
$ti-icon-arrow-merge: unicode('f04e');
$ti-icon-arrow-merge-alt-left: unicode('fc9f');
$ti-icon-arrow-merge-alt-right: unicode('fca0');
$ti-icon-arrow-merge-both: unicode('f23b');
$ti-icon-arrow-merge-left: unicode('f23c');
$ti-icon-arrow-merge-right: unicode('f23d');
$ti-icon-arrow-move-down: unicode('f2ba');
$ti-icon-arrow-move-down-filled: unicode('10034');
$ti-icon-arrow-move-left: unicode('f2bb');
$ti-icon-arrow-move-left-filled: unicode('10033');
$ti-icon-arrow-move-right: unicode('f2bc');
$ti-icon-arrow-move-right-filled: unicode('10032');
$ti-icon-arrow-move-up: unicode('f2bd');
$ti-icon-arrow-move-up-filled: unicode('10031');
$ti-icon-arrow-narrow-down: unicode('ea1a');
$ti-icon-arrow-narrow-down-dashed: unicode('10068');
$ti-icon-arrow-narrow-left: unicode('ea1b');
$ti-icon-arrow-narrow-left-dashed: unicode('10067');
$ti-icon-arrow-narrow-right: unicode('ea1c');
$ti-icon-arrow-narrow-right-dashed: unicode('10066');
$ti-icon-arrow-narrow-up: unicode('ea1d');
$ti-icon-arrow-narrow-up-dashed: unicode('10065');
$ti-icon-arrow-ramp-left: unicode('ed3c');
$ti-icon-arrow-ramp-left-2: unicode('f04f');
$ti-icon-arrow-ramp-left-3: unicode('f050');
$ti-icon-arrow-ramp-right: unicode('ed3d');
$ti-icon-arrow-ramp-right-2: unicode('f051');
$ti-icon-arrow-ramp-right-3: unicode('f052');
$ti-icon-arrow-right: unicode('ea1f');
$ti-icon-arrow-right-bar: unicode('eda1');
$ti-icon-arrow-right-circle: unicode('ea1e');
$ti-icon-arrow-right-circle-filled: unicode('10030');
$ti-icon-arrow-right-dashed: unicode('10064');
$ti-icon-arrow-right-from-arc: unicode('fd8a');
$ti-icon-arrow-right-rhombus: unicode('f61f');
$ti-icon-arrow-right-rhombus-filled: unicode('1002f');
$ti-icon-arrow-right-square: unicode('eda2');
$ti-icon-arrow-right-square-filled: unicode('1002e');
$ti-icon-arrow-right-tail: unicode('eda3');
$ti-icon-arrow-right-to-arc: unicode('fd8b');
$ti-icon-arrow-rotary-first-left: unicode('f053');
$ti-icon-arrow-rotary-first-right: unicode('f054');
$ti-icon-arrow-rotary-last-left: unicode('f055');
$ti-icon-arrow-rotary-last-right: unicode('f056');
$ti-icon-arrow-rotary-left: unicode('f057');
$ti-icon-arrow-rotary-right: unicode('f058');
$ti-icon-arrow-rotary-straight: unicode('f059');
$ti-icon-arrow-roundabout-left: unicode('f22b');
$ti-icon-arrow-roundabout-right: unicode('f22c');
$ti-icon-arrow-sharp-turn-left: unicode('f05a');
$ti-icon-arrow-sharp-turn-right: unicode('f05b');
$ti-icon-arrow-up: unicode('ea25');
$ti-icon-arrow-up-bar: unicode('eda4');
$ti-icon-arrow-up-circle: unicode('ea20');
$ti-icon-arrow-up-circle-filled: unicode('1002d');
$ti-icon-arrow-up-dashed: unicode('10063');
$ti-icon-arrow-up-from-arc: unicode('fd8c');
$ti-icon-arrow-up-left: unicode('ea22');
$ti-icon-arrow-up-left-circle: unicode('ea21');
$ti-icon-arrow-up-rhombus: unicode('f620');
$ti-icon-arrow-up-rhombus-filled: unicode('1002c');
$ti-icon-arrow-up-right: unicode('ea24');
$ti-icon-arrow-up-right-circle: unicode('ea23');
$ti-icon-arrow-up-square: unicode('eda6');
$ti-icon-arrow-up-square-filled: unicode('1002b');
$ti-icon-arrow-up-tail: unicode('eda7');
$ti-icon-arrow-up-to-arc: unicode('fd8d');
$ti-icon-arrow-wave-left-down: unicode('eda8');
$ti-icon-arrow-wave-left-up: unicode('eda9');
$ti-icon-arrow-wave-right-down: unicode('edaa');
$ti-icon-arrow-wave-right-up: unicode('edab');
$ti-icon-arrow-zig-zag: unicode('f4a7');
$ti-icon-arrows-cross: unicode('effe');
$ti-icon-arrows-diagonal: unicode('ea27');
$ti-icon-arrows-diagonal-2: unicode('ea26');
$ti-icon-arrows-diagonal-minimize: unicode('ef39');
$ti-icon-arrows-diagonal-minimize-2: unicode('ef38');
$ti-icon-arrows-diff: unicode('f296');
$ti-icon-arrows-double-ne-sw: unicode('edde');
$ti-icon-arrows-double-nw-se: unicode('eddf');
$ti-icon-arrows-double-se-nw: unicode('ede0');
$ti-icon-arrows-double-sw-ne: unicode('ede1');
$ti-icon-arrows-down: unicode('edad');
$ti-icon-arrows-down-up: unicode('edac');
$ti-icon-arrows-exchange: unicode('f1f4');
$ti-icon-arrows-exchange-2: unicode('f1f3');
$ti-icon-arrows-horizontal: unicode('eb59');
$ti-icon-arrows-join: unicode('edaf');
$ti-icon-arrows-join-2: unicode('edae');
$ti-icon-arrows-left: unicode('edb1');
$ti-icon-arrows-left-down: unicode('ee00');
$ti-icon-arrows-left-right: unicode('edb0');
$ti-icon-arrows-maximize: unicode('ea28');
$ti-icon-arrows-minimize: unicode('ea29');
$ti-icon-arrows-move: unicode('f22f');
$ti-icon-arrows-move-horizontal: unicode('f22d');
$ti-icon-arrows-move-vertical: unicode('f22e');
$ti-icon-arrows-random: unicode('f095');
$ti-icon-arrows-right: unicode('edb3');
$ti-icon-arrows-right-down: unicode('ee01');
$ti-icon-arrows-right-left: unicode('edb2');
$ti-icon-arrows-shuffle: unicode('f000');
$ti-icon-arrows-shuffle-2: unicode('efff');
$ti-icon-arrows-sort: unicode('eb5a');
$ti-icon-arrows-split: unicode('edb5');
$ti-icon-arrows-split-2: unicode('edb4');
$ti-icon-arrows-transfer-down: unicode('f2cc');
$ti-icon-arrows-transfer-up: unicode('f2cd');
$ti-icon-arrows-transfer-up-down: unicode('ffac');
$ti-icon-arrows-up: unicode('edb7');
$ti-icon-arrows-up-down: unicode('edb6');
$ti-icon-arrows-up-left: unicode('ee02');
$ti-icon-arrows-up-right: unicode('ee03');
$ti-icon-arrows-vertical: unicode('eb5b');
$ti-icon-artboard: unicode('ea2a');
$ti-icon-artboard-filled: unicode('fa83');
$ti-icon-artboard-off: unicode('f0ae');
$ti-icon-article: unicode('f1e2');
$ti-icon-article-filled: unicode('f7da');
$ti-icon-article-off: unicode('f3bf');
$ti-icon-aspect-ratio: unicode('ed30');
$ti-icon-aspect-ratio-filled: unicode('f7db');
$ti-icon-aspect-ratio-off: unicode('f0af');
$ti-icon-assembly: unicode('f24d');
$ti-icon-assembly-filled: unicode('fe9e');
$ti-icon-assembly-off: unicode('f3c0');
$ti-icon-asset: unicode('f1ce');
$ti-icon-asset-filled: unicode('fe9d');
$ti-icon-asterisk: unicode('efd5');
$ti-icon-asterisk-simple: unicode('efd4');
$ti-icon-at: unicode('ea2b');
$ti-icon-at-off: unicode('f0b0');
$ti-icon-atom: unicode('eb79');
$ti-icon-atom-2: unicode('ebdf');
$ti-icon-atom-2-filled: unicode('f71b');
$ti-icon-atom-off: unicode('f0f9');
$ti-icon-augmented-reality: unicode('f023');
$ti-icon-augmented-reality-2: unicode('f37e');
$ti-icon-augmented-reality-off: unicode('f3c1');
$ti-icon-auth-2fa: unicode('eca0');
$ti-icon-automatic-gearbox: unicode('fc89');
$ti-icon-automatic-gearbox-filled: unicode('1002a');
$ti-icon-automation: unicode('fef8');
$ti-icon-avocado: unicode('fd8e');
$ti-icon-award: unicode('ea2c');
$ti-icon-award-filled: unicode('f71c');
$ti-icon-award-off: unicode('f0fa');
$ti-icon-axe: unicode('ef9f');
$ti-icon-axis-x: unicode('ef45');
$ti-icon-axis-y: unicode('ef46');
$ti-icon-baby-bottle: unicode('f5d2');
$ti-icon-baby-carriage: unicode('f05d');
$ti-icon-baby-carriage-filled: unicode('fe9c');
$ti-icon-background: unicode('fd2c');
$ti-icon-backhoe: unicode('ed86');
$ti-icon-backpack: unicode('ef47');
$ti-icon-backpack-off: unicode('f3c2');
$ti-icon-backslash: unicode('fab9');
$ti-icon-backspace: unicode('ea2d');
$ti-icon-backspace-filled: unicode('f7dc');
$ti-icon-badge: unicode('efc2');
$ti-icon-badge-3d: unicode('f555');
$ti-icon-badge-3d-filled: unicode('fe9b');
$ti-icon-badge-4k: unicode('f556');
$ti-icon-badge-4k-filled: unicode('fe9a');
$ti-icon-badge-8k: unicode('f557');
$ti-icon-badge-8k-filled: unicode('fe99');
$ti-icon-badge-ad: unicode('f558');
$ti-icon-badge-ad-filled: unicode('fe98');
$ti-icon-badge-ad-off: unicode('fd8f');
$ti-icon-badge-ar: unicode('f559');
$ti-icon-badge-ar-filled: unicode('fe97');
$ti-icon-badge-cc: unicode('f55a');
$ti-icon-badge-cc-filled: unicode('fe96');
$ti-icon-badge-filled: unicode('f667');
$ti-icon-badge-hd: unicode('f55b');
$ti-icon-badge-hd-filled: unicode('fe95');
$ti-icon-badge-off: unicode('f0fb');
$ti-icon-badge-sd: unicode('f55c');
$ti-icon-badge-sd-filled: unicode('fe94');
$ti-icon-badge-tm: unicode('f55d');
$ti-icon-badge-tm-filled: unicode('fe93');
$ti-icon-badge-vo: unicode('f55e');
$ti-icon-badge-vo-filled: unicode('fe92');
$ti-icon-badge-vr: unicode('f55f');
$ti-icon-badge-vr-filled: unicode('fe91');
$ti-icon-badge-wc: unicode('f560');
$ti-icon-badge-wc-filled: unicode('fe90');
$ti-icon-badges: unicode('efc3');
$ti-icon-badges-filled: unicode('f7dd');
$ti-icon-badges-off: unicode('f0fc');
$ti-icon-baguette: unicode('f3a5');
$ti-icon-ball-american-football: unicode('ee04');
$ti-icon-ball-american-football-off: unicode('f3c3');
$ti-icon-ball-baseball: unicode('efa0');
$ti-icon-ball-basketball: unicode('ec28');
$ti-icon-ball-bowling: unicode('ec29');
$ti-icon-ball-football: unicode('ee06');
$ti-icon-ball-football-off: unicode('ee05');
$ti-icon-ball-tennis: unicode('ec2a');
$ti-icon-ball-volleyball: unicode('ec2b');
$ti-icon-balloon: unicode('ef3a');
$ti-icon-balloon-filled: unicode('fa84');
$ti-icon-balloon-off: unicode('f0fd');
$ti-icon-ballpen: unicode('f06e');
$ti-icon-ballpen-filled: unicode('fa85');
$ti-icon-ballpen-off: unicode('f0b1');
$ti-icon-ban: unicode('ea2e');
$ti-icon-bandage: unicode('eb7a');
$ti-icon-bandage-filled: unicode('f7de');
$ti-icon-bandage-off: unicode('f3c4');
$ti-icon-barbell: unicode('eff0');
$ti-icon-barbell-filled: unicode('fe8f');
$ti-icon-barbell-off: unicode('f0b2');
$ti-icon-barcode: unicode('ebc6');
$ti-icon-barcode-off: unicode('f0b3');
$ti-icon-barrel: unicode('f0b4');
$ti-icon-barrel-off: unicode('f0fe');
$ti-icon-barrier-block: unicode('f00e');
$ti-icon-barrier-block-filled: unicode('fe8e');
$ti-icon-barrier-block-off: unicode('f0b5');
$ti-icon-baseline: unicode('f024');
$ti-icon-baseline-density-large: unicode('f9f0');
$ti-icon-baseline-density-medium: unicode('f9f1');
$ti-icon-baseline-density-small: unicode('f9f2');
$ti-icon-basket: unicode('ebe1');
$ti-icon-basket-bolt: unicode('fb43');
$ti-icon-basket-cancel: unicode('fb44');
$ti-icon-basket-check: unicode('fb45');
$ti-icon-basket-code: unicode('fb46');
$ti-icon-basket-cog: unicode('fb47');
$ti-icon-basket-discount: unicode('fb48');
$ti-icon-basket-dollar: unicode('fb49');
$ti-icon-basket-down: unicode('fb4a');
$ti-icon-basket-exclamation: unicode('fb4b');
$ti-icon-basket-filled: unicode('f7df');
$ti-icon-basket-heart: unicode('fb4c');
$ti-icon-basket-minus: unicode('fb4d');
$ti-icon-basket-off: unicode('f0b6');
$ti-icon-basket-pause: unicode('fb4e');
$ti-icon-basket-pin: unicode('fb4f');
$ti-icon-basket-plus: unicode('fb50');
$ti-icon-basket-question: unicode('fb51');
$ti-icon-basket-search: unicode('fb52');
$ti-icon-basket-share: unicode('fb53');
$ti-icon-basket-star: unicode('fb54');
$ti-icon-basket-up: unicode('fb55');
$ti-icon-basket-x: unicode('fb56');
$ti-icon-bat: unicode('f284');
$ti-icon-bath: unicode('ef48');
$ti-icon-bath-filled: unicode('f71d');
$ti-icon-bath-off: unicode('f0ff');
$ti-icon-battery: unicode('ea34');
$ti-icon-battery-1: unicode('ea2f');
$ti-icon-battery-1-filled: unicode('f71e');
$ti-icon-battery-2: unicode('ea30');
$ti-icon-battery-2-filled: unicode('f71f');
$ti-icon-battery-3: unicode('ea31');
$ti-icon-battery-3-filled: unicode('f720');
$ti-icon-battery-4: unicode('ea32');
$ti-icon-battery-4-filled: unicode('f721');
$ti-icon-battery-automotive: unicode('ee07');
$ti-icon-battery-automotive-filled: unicode('10029');
$ti-icon-battery-charging: unicode('ea33');
$ti-icon-battery-charging-2: unicode('ef3b');
$ti-icon-battery-eco: unicode('ef3c');
$ti-icon-battery-exclamation: unicode('ff1d');
$ti-icon-battery-filled: unicode('f668');
$ti-icon-battery-off: unicode('ed1c');
$ti-icon-battery-spark: unicode('ffbd');
$ti-icon-battery-vertical: unicode('ff13');
$ti-icon-battery-vertical-1: unicode('ff1c');
$ti-icon-battery-vertical-1-filled: unicode('10028');
$ti-icon-battery-vertical-2: unicode('ff1b');
$ti-icon-battery-vertical-2-filled: unicode('10027');
$ti-icon-battery-vertical-3: unicode('ff1a');
$ti-icon-battery-vertical-3-filled: unicode('10026');
$ti-icon-battery-vertical-4: unicode('ff19');
$ti-icon-battery-vertical-4-filled: unicode('10025');
$ti-icon-battery-vertical-charging: unicode('ff17');
$ti-icon-battery-vertical-charging-2: unicode('ff18');
$ti-icon-battery-vertical-eco: unicode('ff16');
$ti-icon-battery-vertical-exclamation: unicode('ff15');
$ti-icon-battery-vertical-filled: unicode('10024');
$ti-icon-battery-vertical-off: unicode('ff14');
$ti-icon-beach: unicode('ef3d');
$ti-icon-beach-off: unicode('f0b7');
$ti-icon-bed: unicode('eb5c');
$ti-icon-bed-filled: unicode('f7e0');
$ti-icon-bed-flat: unicode('fca1');
$ti-icon-bed-flat-filled: unicode('fe8d');
$ti-icon-bed-off: unicode('f100');
$ti-icon-beer: unicode('efa1');
$ti-icon-beer-filled: unicode('f7e1');
$ti-icon-beer-off: unicode('f101');
$ti-icon-bell: unicode('ea35');
$ti-icon-bell-bolt: unicode('f812');
$ti-icon-bell-cancel: unicode('f813');
$ti-icon-bell-check: unicode('f814');
$ti-icon-bell-code: unicode('f815');
$ti-icon-bell-cog: unicode('f816');
$ti-icon-bell-dollar: unicode('f817');
$ti-icon-bell-down: unicode('f818');
$ti-icon-bell-exclamation: unicode('f819');
$ti-icon-bell-filled: unicode('f669');
$ti-icon-bell-heart: unicode('f81a');
$ti-icon-bell-minus: unicode('ede2');
$ti-icon-bell-minus-filled: unicode('f722');
$ti-icon-bell-off: unicode('ece9');
$ti-icon-bell-pause: unicode('f81b');
$ti-icon-bell-pin: unicode('f81c');
$ti-icon-bell-plus: unicode('ede3');
$ti-icon-bell-plus-filled: unicode('f723');
$ti-icon-bell-question: unicode('f81d');
$ti-icon-bell-ringing: unicode('ed07');
$ti-icon-bell-ringing-2: unicode('ede4');
$ti-icon-bell-ringing-2-filled: unicode('f724');
$ti-icon-bell-ringing-filled: unicode('f725');
$ti-icon-bell-school: unicode('f05e');
$ti-icon-bell-search: unicode('f81e');
$ti-icon-bell-share: unicode('f81f');
$ti-icon-bell-star: unicode('f820');
$ti-icon-bell-up: unicode('f821');
$ti-icon-bell-x: unicode('ede5');
$ti-icon-bell-x-filled: unicode('f726');
$ti-icon-bell-z: unicode('eff1');
$ti-icon-bell-z-filled: unicode('f727');
$ti-icon-beta: unicode('f544');
$ti-icon-bible: unicode('efc4');
$ti-icon-bike: unicode('ea36');
$ti-icon-bike-filled: unicode('10023');
$ti-icon-bike-off: unicode('f0b8');
$ti-icon-binary: unicode('ee08');
$ti-icon-binary-off: unicode('f3c5');
$ti-icon-binary-tree: unicode('f5d4');
$ti-icon-binary-tree-2: unicode('f5d3');
$ti-icon-binary-tree-2-filled: unicode('ff65');
$ti-icon-binary-tree-filled: unicode('ff64');
$ti-icon-binoculars: unicode('fefe');
$ti-icon-binoculars-filled: unicode('ff0b');
$ti-icon-biohazard: unicode('ecb8');
$ti-icon-biohazard-filled: unicode('fe8c');
$ti-icon-biohazard-off: unicode('f0b9');
$ti-icon-blade: unicode('f4bd');
$ti-icon-blade-filled: unicode('f7e2');
$ti-icon-bleach: unicode('f2f3');
$ti-icon-bleach-chlorine: unicode('f2f0');
$ti-icon-bleach-no-chlorine: unicode('f2f1');
$ti-icon-bleach-off: unicode('f2f2');
$ti-icon-blend-mode: unicode('feb0');
$ti-icon-blender: unicode('fca2');
$ti-icon-blender-filled: unicode('10022');
$ti-icon-blob: unicode('feaf');
$ti-icon-blob-filled: unicode('feb1');
$ti-icon-blockquote: unicode('ee09');
$ti-icon-bluetooth: unicode('ea37');
$ti-icon-bluetooth-connected: unicode('ecea');
$ti-icon-bluetooth-off: unicode('eceb');
$ti-icon-bluetooth-x: unicode('f081');
$ti-icon-blur: unicode('ef8c');
$ti-icon-blur-off: unicode('f3c6');
$ti-icon-bmp: unicode('f3a6');
$ti-icon-body-scan: unicode('fca3');
$ti-icon-bold: unicode('eb7b');
$ti-icon-bold-off: unicode('f0ba');
$ti-icon-bolt: unicode('ea38');
$ti-icon-bolt-filled: unicode('10021');
$ti-icon-bolt-off: unicode('ecec');
$ti-icon-bomb: unicode('f59c');
$ti-icon-bomb-filled: unicode('fa86');
$ti-icon-bone: unicode('edb8');
$ti-icon-bone-filled: unicode('fe8b');
$ti-icon-bone-off: unicode('f0bb');
$ti-icon-bong: unicode('f3a7');
$ti-icon-bong-filled: unicode('10020');
$ti-icon-bong-off: unicode('f3c7');
$ti-icon-book: unicode('ea39');
$ti-icon-book-2: unicode('efc5');
$ti-icon-book-download: unicode('f070');
$ti-icon-book-filled: unicode('fa87');
$ti-icon-book-off: unicode('f0bc');
$ti-icon-book-upload: unicode('f071');
$ti-icon-bookmark: unicode('ea3a');
$ti-icon-bookmark-ai: unicode('fc8a');
$ti-icon-bookmark-edit: unicode('fa5e');
$ti-icon-bookmark-filled: unicode('fa88');
$ti-icon-bookmark-minus: unicode('fa5f');
$ti-icon-bookmark-off: unicode('eced');
$ti-icon-bookmark-plus: unicode('fa60');
$ti-icon-bookmark-question: unicode('fa61');
$ti-icon-bookmarks: unicode('ed08');
$ti-icon-bookmarks-filled: unicode('fb1f');
$ti-icon-bookmarks-off: unicode('f0bd');
$ti-icon-books: unicode('eff2');
$ti-icon-books-off: unicode('f0be');
$ti-icon-boom: unicode('fdbe');
$ti-icon-boom-filled: unicode('fe8a');
$ti-icon-border-all: unicode('ea3b');
$ti-icon-border-bottom: unicode('ea3c');
$ti-icon-border-bottom-plus: unicode('fdbd');
$ti-icon-border-corner-ios: unicode('fd98');
$ti-icon-border-corner-pill: unicode('fd62');
$ti-icon-border-corner-rounded: unicode('fd63');
$ti-icon-border-corner-square: unicode('fd64');
$ti-icon-border-corners: unicode('f7a0');
$ti-icon-border-horizontal: unicode('ea3d');
$ti-icon-border-inner: unicode('ea3e');
$ti-icon-border-left: unicode('ea3f');
$ti-icon-border-left-plus: unicode('fdbc');
$ti-icon-border-none: unicode('ea40');
$ti-icon-border-outer: unicode('ea41');
$ti-icon-border-radius: unicode('eb7c');
$ti-icon-border-right: unicode('ea42');
$ti-icon-border-right-plus: unicode('fdbb');
$ti-icon-border-sides: unicode('f7a1');
$ti-icon-border-style: unicode('ee0a');
$ti-icon-border-style-2: unicode('ef22');
$ti-icon-border-top: unicode('ea43');
$ti-icon-border-top-plus: unicode('fdba');
$ti-icon-border-vertical: unicode('ea44');
$ti-icon-bottle: unicode('ef0b');
$ti-icon-bottle-filled: unicode('fa89');
$ti-icon-bottle-off: unicode('f3c8');
$ti-icon-bounce-left: unicode('f59d');
$ti-icon-bounce-left-filled: unicode('fb20');
$ti-icon-bounce-right: unicode('f59e');
$ti-icon-bounce-right-filled: unicode('fb21');
$ti-icon-bow: unicode('f096');
$ti-icon-bow-filled: unicode('fe89');
$ti-icon-bowl: unicode('f4fa');
$ti-icon-bowl-chopsticks: unicode('fd90');
$ti-icon-bowl-chopsticks-filled: unicode('fe88');
$ti-icon-bowl-filled: unicode('fb22');
$ti-icon-bowl-spoon: unicode('fd91');
$ti-icon-bowl-spoon-filled: unicode('fe87');
$ti-icon-box: unicode('ea45');
$ti-icon-box-align-bottom: unicode('f2a8');
$ti-icon-box-align-bottom-filled: unicode('fa8a');
$ti-icon-box-align-bottom-left: unicode('f2ce');
$ti-icon-box-align-bottom-left-filled: unicode('fa8b');
$ti-icon-box-align-bottom-right: unicode('f2cf');
$ti-icon-box-align-bottom-right-filled: unicode('fa8c');
$ti-icon-box-align-left: unicode('f2a9');
$ti-icon-box-align-left-filled: unicode('fa8d');
$ti-icon-box-align-right: unicode('f2aa');
$ti-icon-box-align-right-filled: unicode('fa8e');
$ti-icon-box-align-top: unicode('f2ab');
$ti-icon-box-align-top-filled: unicode('fa8f');
$ti-icon-box-align-top-left: unicode('f2d0');
$ti-icon-box-align-top-left-filled: unicode('fa90');
$ti-icon-box-align-top-right: unicode('f2d1');
$ti-icon-box-align-top-right-filled: unicode('fa91');
$ti-icon-box-margin: unicode('ee0b');
$ti-icon-box-model: unicode('ee0c');
$ti-icon-box-model-2: unicode('ef23');
$ti-icon-box-model-2-off: unicode('f3c9');
$ti-icon-box-model-off: unicode('f3ca');
$ti-icon-box-multiple: unicode('ee17');
$ti-icon-box-multiple-0: unicode('ee0d');
$ti-icon-box-multiple-1: unicode('ee0e');
$ti-icon-box-multiple-2: unicode('ee0f');
$ti-icon-box-multiple-3: unicode('ee10');
$ti-icon-box-multiple-4: unicode('ee11');
$ti-icon-box-multiple-5: unicode('ee12');
$ti-icon-box-multiple-6: unicode('ee13');
$ti-icon-box-multiple-7: unicode('ee14');
$ti-icon-box-multiple-8: unicode('ee15');
$ti-icon-box-multiple-9: unicode('ee16');
$ti-icon-box-multiple-filled: unicode('1001f');
$ti-icon-box-off: unicode('f102');
$ti-icon-box-padding: unicode('ee18');
$ti-icon-braces: unicode('ebcc');
$ti-icon-braces-off: unicode('f0bf');
$ti-icon-brackets: unicode('ebcd');
$ti-icon-brackets-angle: unicode('fcb2');
$ti-icon-brackets-angle-off: unicode('fcb1');
$ti-icon-brackets-contain: unicode('f1e5');
$ti-icon-brackets-contain-end: unicode('f1e3');
$ti-icon-brackets-contain-start: unicode('f1e4');
$ti-icon-brackets-off: unicode('f0c0');
$ti-icon-braille: unicode('f545');
$ti-icon-brain: unicode('f59f');
$ti-icon-brand-4chan: unicode('f494');
$ti-icon-brand-abstract: unicode('f495');
$ti-icon-brand-adobe: unicode('f0dc');
$ti-icon-brand-adobe-after-effect: unicode('ff2a');
$ti-icon-brand-adobe-illustrator: unicode('ff29');
$ti-icon-brand-adobe-indesign: unicode('ff28');
$ti-icon-brand-adobe-photoshop: unicode('ff27');
$ti-icon-brand-adobe-premier: unicode('ff26');
$ti-icon-brand-adobe-xd: unicode('ff25');
$ti-icon-brand-adonis-js: unicode('f496');
$ti-icon-brand-airbnb: unicode('ed68');
$ti-icon-brand-airtable: unicode('ef6a');
$ti-icon-brand-algolia: unicode('f390');
$ti-icon-brand-alipay: unicode('f7a2');
$ti-icon-brand-alpine-js: unicode('f324');
$ti-icon-brand-amazon: unicode('f230');
$ti-icon-brand-amd: unicode('f653');
$ti-icon-brand-amie: unicode('ffab');
$ti-icon-brand-amigo: unicode('f5f9');
$ti-icon-brand-among-us: unicode('f205');
$ti-icon-brand-android: unicode('ec16');
$ti-icon-brand-angular: unicode('ef6b');
$ti-icon-brand-ansible: unicode('fa70');
$ti-icon-brand-ao3: unicode('f5e8');
$ti-icon-brand-appgallery: unicode('f231');
$ti-icon-brand-apple: unicode('ec17');
$ti-icon-brand-apple-arcade: unicode('ed69');
$ti-icon-brand-apple-filled: unicode('fd74');
$ti-icon-brand-apple-news: unicode('ff24');
$ti-icon-brand-apple-podcast: unicode('f1e6');
$ti-icon-brand-appstore: unicode('ed24');
$ti-icon-brand-arc: unicode('feae');
$ti-icon-brand-asana: unicode('edc5');
$ti-icon-brand-astro: unicode('fdb9');
$ti-icon-brand-auth0: unicode('fcb3');
$ti-icon-brand-aws: unicode('fa4c');
$ti-icon-brand-azure: unicode('fa4d');
$ti-icon-brand-backbone: unicode('f325');
$ti-icon-brand-badoo: unicode('f206');
$ti-icon-brand-baidu: unicode('f5e9');
$ti-icon-brand-bandcamp: unicode('f207');
$ti-icon-brand-bandlab: unicode('f5fa');
$ti-icon-brand-beats: unicode('f208');
$ti-icon-brand-bebo: unicode('ffaa');
$ti-icon-brand-behance: unicode('ec6e');
$ti-icon-brand-bilibili: unicode('f6d2');
$ti-icon-brand-binance: unicode('f5a0');
$ti-icon-brand-bing: unicode('edc6');
$ti-icon-brand-bitbucket: unicode('edc7');
$ti-icon-brand-blackberry: unicode('f568');
$ti-icon-brand-blender: unicode('f326');
$ti-icon-brand-blogger: unicode('f35a');
$ti-icon-brand-bluesky: unicode('fd75');
$ti-icon-brand-booking: unicode('edc8');
$ti-icon-brand-bootstrap: unicode('ef3e');
$ti-icon-brand-bulma: unicode('f327');
$ti-icon-brand-bumble: unicode('f5fb');
$ti-icon-brand-bunpo: unicode('f4cf');
$ti-icon-brand-c-sharp: unicode('f003');
$ti-icon-brand-cake: unicode('f7a3');
$ti-icon-brand-cakephp: unicode('f7af');
$ti-icon-brand-campaignmonitor: unicode('f328');
$ti-icon-brand-carbon: unicode('f348');
$ti-icon-brand-cashapp: unicode('f391');
$ti-icon-brand-chrome: unicode('ec18');
$ti-icon-brand-cinema-4d: unicode('fa71');
$ti-icon-brand-citymapper: unicode('f5fc');
$ti-icon-brand-cloudflare: unicode('fa4e');
$ti-icon-brand-codecov: unicode('f329');
$ti-icon-brand-codepen: unicode('ec6f');
$ti-icon-brand-codesandbox: unicode('ed6a');
$ti-icon-brand-cohost: unicode('f5d5');
$ti-icon-brand-coinbase: unicode('f209');
$ti-icon-brand-comedy-central: unicode('f217');
$ti-icon-brand-coreos: unicode('f5fd');
$ti-icon-brand-couchdb: unicode('f60f');
$ti-icon-brand-couchsurfing: unicode('f392');
$ti-icon-brand-cpp: unicode('f5fe');
$ti-icon-brand-craft: unicode('fa72');
$ti-icon-brand-crunchbase: unicode('f7e3');
$ti-icon-brand-css3: unicode('ed6b');
$ti-icon-brand-ctemplar: unicode('f4d0');
$ti-icon-brand-cucumber: unicode('ef6c');
$ti-icon-brand-cupra: unicode('f4d1');
$ti-icon-brand-cypress: unicode('f333');
$ti-icon-brand-d3: unicode('f24e');
$ti-icon-brand-databricks: unicode('fc41');
$ti-icon-brand-days-counter: unicode('f4d2');
$ti-icon-brand-dcos: unicode('f32a');
$ti-icon-brand-debian: unicode('ef57');
$ti-icon-brand-deezer: unicode('f78b');
$ti-icon-brand-deliveroo: unicode('f4d3');
$ti-icon-brand-deno: unicode('f24f');
$ti-icon-brand-denodo: unicode('f610');
$ti-icon-brand-deviantart: unicode('ecfb');
$ti-icon-brand-digg: unicode('fa73');
$ti-icon-brand-dingtalk: unicode('f5ea');
$ti-icon-brand-discord: unicode('ece3');
$ti-icon-brand-discord-filled: unicode('f7e4');
$ti-icon-brand-disney: unicode('f20a');
$ti-icon-brand-disqus: unicode('edc9');
$ti-icon-brand-django: unicode('f349');
$ti-icon-brand-docker: unicode('edca');
$ti-icon-brand-doctrine: unicode('ef6d');
$ti-icon-brand-dolby-digital: unicode('f4d4');
$ti-icon-brand-douban: unicode('f5ff');
$ti-icon-brand-dribbble: unicode('ec19');
$ti-icon-brand-dribbble-filled: unicode('f7e5');
$ti-icon-brand-drops: unicode('f4d5');
$ti-icon-brand-drupal: unicode('f393');
$ti-icon-brand-edge: unicode('ecfc');
$ti-icon-brand-elastic: unicode('f611');
$ti-icon-brand-electronic-arts: unicode('fa74');
$ti-icon-brand-ember: unicode('f497');
$ti-icon-brand-envato: unicode('f394');
$ti-icon-brand-etsy: unicode('f654');
$ti-icon-brand-evernote: unicode('f600');
$ti-icon-brand-facebook: unicode('ec1a');
$ti-icon-brand-facebook-filled: unicode('f7e6');
$ti-icon-brand-feedly: unicode('fa75');
$ti-icon-brand-figma: unicode('ec93');
$ti-icon-brand-filezilla: unicode('fa76');
$ti-icon-brand-finder: unicode('f218');
$ti-icon-brand-firebase: unicode('ef6e');
$ti-icon-brand-firefox: unicode('ecfd');
$ti-icon-brand-fiverr: unicode('f7a4');
$ti-icon-brand-flickr: unicode('ecfe');
$ti-icon-brand-flightradar24: unicode('f4d6');
$ti-icon-brand-flipboard: unicode('f20b');
$ti-icon-brand-flutter: unicode('f395');
$ti-icon-brand-fortnite: unicode('f260');
$ti-icon-brand-foursquare: unicode('ecff');
$ti-icon-brand-framer: unicode('ec1b');
$ti-icon-brand-framer-motion: unicode('f78c');
$ti-icon-brand-funimation: unicode('f655');
$ti-icon-brand-gatsby: unicode('f396');
$ti-icon-brand-git: unicode('ef6f');
$ti-icon-brand-github: unicode('ec1c');
$ti-icon-brand-github-copilot: unicode('f4a8');
$ti-icon-brand-github-filled: unicode('f7e7');
$ti-icon-brand-gitlab: unicode('ec1d');
$ti-icon-brand-gmail: unicode('efa2');
$ti-icon-brand-golang: unicode('f78d');
$ti-icon-brand-google: unicode('ec1f');
$ti-icon-brand-google-analytics: unicode('edcb');
$ti-icon-brand-google-big-query: unicode('f612');
$ti-icon-brand-google-drive: unicode('ec1e');
$ti-icon-brand-google-filled: unicode('fd1a');
$ti-icon-brand-google-fit: unicode('f297');
$ti-icon-brand-google-home: unicode('f601');
$ti-icon-brand-google-maps: unicode('fa4f');
$ti-icon-brand-google-one: unicode('f232');
$ti-icon-brand-google-photos: unicode('f20c');
$ti-icon-brand-google-play: unicode('ed25');
$ti-icon-brand-google-podcasts: unicode('f656');
$ti-icon-brand-grammarly: unicode('f32b');
$ti-icon-brand-graphql: unicode('f32c');
$ti-icon-brand-gravatar: unicode('edcc');
$ti-icon-brand-grindr: unicode('f20d');
$ti-icon-brand-guardian: unicode('f4fb');
$ti-icon-brand-gumroad: unicode('f5d6');
$ti-icon-brand-hackerrank: unicode('ff23');
$ti-icon-brand-hbo: unicode('f657');
$ti-icon-brand-headlessui: unicode('f32d');
$ti-icon-brand-hexo: unicode('fa50');
$ti-icon-brand-hipchat: unicode('edcd');
$ti-icon-brand-html5: unicode('ed6c');
$ti-icon-brand-inertia: unicode('f34a');
$ti-icon-brand-instagram: unicode('ec20');
$ti-icon-brand-intercom: unicode('f1cf');
$ti-icon-brand-itch: unicode('fa22');
$ti-icon-brand-javascript: unicode('ef0c');
$ti-icon-brand-juejin: unicode('f7b0');
$ti-icon-brand-kako-talk: unicode('fd2d');
$ti-icon-brand-kbin: unicode('fad0');
$ti-icon-brand-kick: unicode('fa23');
$ti-icon-brand-kickstarter: unicode('edce');
$ti-icon-brand-kotlin: unicode('ed6d');
$ti-icon-brand-laravel: unicode('f34b');
$ti-icon-brand-lastfm: unicode('f001');
$ti-icon-brand-leetcode: unicode('fa51');
$ti-icon-brand-letterboxd: unicode('fa24');
$ti-icon-brand-line: unicode('f7e8');
$ti-icon-brand-linkedin: unicode('ec8c');
$ti-icon-brand-linktree: unicode('f1e7');
$ti-icon-brand-linqpad: unicode('f562');
$ti-icon-brand-livewire: unicode('fd76');
$ti-icon-brand-loom: unicode('ef70');
$ti-icon-brand-mailgun: unicode('f32e');
$ti-icon-brand-mantine: unicode('f32f');
$ti-icon-brand-mastercard: unicode('ef49');
$ti-icon-brand-mastodon: unicode('f250');
$ti-icon-brand-matrix: unicode('f5eb');
$ti-icon-brand-mcdonalds: unicode('f251');
$ti-icon-brand-medium: unicode('ec70');
$ti-icon-brand-meetup: unicode('fc6a');
$ti-icon-brand-mercedes: unicode('f072');
$ti-icon-brand-messenger: unicode('ec71');
$ti-icon-brand-meta: unicode('efb0');
$ti-icon-brand-metabrainz: unicode('ff12');
$ti-icon-brand-minecraft: unicode('faef');
$ti-icon-brand-miniprogram: unicode('f602');
$ti-icon-brand-mixpanel: unicode('f397');
$ti-icon-brand-monday: unicode('f219');
$ti-icon-brand-mongodb: unicode('f613');
$ti-icon-brand-my-oppo: unicode('f4d7');
$ti-icon-brand-mysql: unicode('f614');
$ti-icon-brand-national-geographic: unicode('f603');
$ti-icon-brand-nem: unicode('f5a1');
$ti-icon-brand-netbeans: unicode('ef71');
$ti-icon-brand-netease-music: unicode('f604');
$ti-icon-brand-netflix: unicode('edcf');
$ti-icon-brand-nexo: unicode('f5a2');
$ti-icon-brand-nextcloud: unicode('f4d8');
$ti-icon-brand-nextjs: unicode('f0dd');
$ti-icon-brand-nodejs: unicode('fae0');
$ti-icon-brand-nord-vpn: unicode('f37f');
$ti-icon-brand-notion: unicode('ef7b');
$ti-icon-brand-npm: unicode('f569');
$ti-icon-brand-nuxt: unicode('f0de');
$ti-icon-brand-nytimes: unicode('ef8d');
$ti-icon-brand-oauth: unicode('fa52');
$ti-icon-brand-office: unicode('f398');
$ti-icon-brand-ok-ru: unicode('f399');
$ti-icon-brand-onedrive: unicode('f5d7');
$ti-icon-brand-onlyfans: unicode('f605');
$ti-icon-brand-open-source: unicode('edd0');
$ti-icon-brand-openai: unicode('f78e');
$ti-icon-brand-openvpn: unicode('f39a');
$ti-icon-brand-opera: unicode('ec21');
$ti-icon-brand-pagekit: unicode('edd1');
$ti-icon-brand-parsinta: unicode('fc42');
$ti-icon-brand-patreon: unicode('edd2');
$ti-icon-brand-patreon-filled: unicode('fcff');
$ti-icon-brand-paypal: unicode('ec22');
$ti-icon-brand-paypal-filled: unicode('f7e9');
$ti-icon-brand-paypay: unicode('f5ec');
$ti-icon-brand-peanut: unicode('f39b');
$ti-icon-brand-pepsi: unicode('f261');
$ti-icon-brand-php: unicode('ef72');
$ti-icon-brand-picsart: unicode('f4d9');
$ti-icon-brand-pinterest: unicode('ec8d');
$ti-icon-brand-planetscale: unicode('f78f');
$ti-icon-brand-pnpm: unicode('fd77');
$ti-icon-brand-pocket: unicode('ed00');
$ti-icon-brand-polymer: unicode('f498');
$ti-icon-brand-powershell: unicode('f5ed');
$ti-icon-brand-printables: unicode('fd1b');
$ti-icon-brand-prisma: unicode('f499');
$ti-icon-brand-producthunt: unicode('edd3');
$ti-icon-brand-pushbullet: unicode('f330');
$ti-icon-brand-pushover: unicode('f20e');
$ti-icon-brand-python: unicode('ed01');
$ti-icon-brand-qq: unicode('f606');
$ti-icon-brand-radix-ui: unicode('f790');
$ti-icon-brand-react: unicode('f34c');
$ti-icon-brand-react-native: unicode('ef73');
$ti-icon-brand-reason: unicode('f49a');
$ti-icon-brand-reddit: unicode('ec8e');
$ti-icon-brand-redhat: unicode('f331');
$ti-icon-brand-redux: unicode('f3a8');
$ti-icon-brand-revolut: unicode('f4da');
$ti-icon-brand-rumble: unicode('fad1');
$ti-icon-brand-rust: unicode('fa53');
$ti-icon-brand-safari: unicode('ec23');
$ti-icon-brand-samsungpass: unicode('f4db');
$ti-icon-brand-sass: unicode('edd4');
$ti-icon-brand-sentry: unicode('edd5');
$ti-icon-brand-sharik: unicode('f4dc');
$ti-icon-brand-shazam: unicode('edd6');
$ti-icon-brand-shopee: unicode('f252');
$ti-icon-brand-sketch: unicode('ec24');
$ti-icon-brand-skype: unicode('ed02');
$ti-icon-brand-slack: unicode('ec72');
$ti-icon-brand-snapchat: unicode('ec25');
$ti-icon-brand-snapseed: unicode('f253');
$ti-icon-brand-snowflake: unicode('f615');
$ti-icon-brand-socket-io: unicode('f49b');
$ti-icon-brand-solidjs: unicode('f5ee');
$ti-icon-brand-soundcloud: unicode('ed6e');
$ti-icon-brand-spacehey: unicode('f4fc');
$ti-icon-brand-speedtest: unicode('fa77');
$ti-icon-brand-spotify: unicode('ed03');
$ti-icon-brand-spotify-filled: unicode('fe86');
$ti-icon-brand-stackoverflow: unicode('ef58');
$ti-icon-brand-stackshare: unicode('f607');
$ti-icon-brand-steam: unicode('ed6f');
$ti-icon-brand-stocktwits: unicode('fd78');
$ti-icon-brand-storj: unicode('fa54');
$ti-icon-brand-storybook: unicode('f332');
$ti-icon-brand-storytel: unicode('f608');
$ti-icon-brand-strava: unicode('f254');
$ti-icon-brand-stripe: unicode('edd7');
$ti-icon-brand-sublime-text: unicode('ef74');
$ti-icon-brand-sugarizer: unicode('f7a5');
$ti-icon-brand-supabase: unicode('f6d3');
$ti-icon-brand-superhuman: unicode('f50c');
$ti-icon-brand-supernova: unicode('f49c');
$ti-icon-brand-surfshark: unicode('f255');
$ti-icon-brand-svelte: unicode('f0df');
$ti-icon-brand-swift: unicode('fa55');
$ti-icon-brand-symfony: unicode('f616');
$ti-icon-brand-tabler: unicode('ec8f');
$ti-icon-brand-tailwind: unicode('eca1');
$ti-icon-brand-taobao: unicode('f5ef');
$ti-icon-brand-teams: unicode('fadf');
$ti-icon-brand-ted: unicode('f658');
$ti-icon-brand-telegram: unicode('ec26');
$ti-icon-brand-terraform: unicode('fa56');
$ti-icon-brand-tether: unicode('f5a3');
$ti-icon-brand-thingiverse: unicode('fd1c');
$ti-icon-brand-threads: unicode('fb02');
$ti-icon-brand-threejs: unicode('f5f0');
$ti-icon-brand-tidal: unicode('ed70');
$ti-icon-brand-tiktok: unicode('ec73');
$ti-icon-brand-tiktok-filled: unicode('f7ea');
$ti-icon-brand-tinder: unicode('ed71');
$ti-icon-brand-topbuzz: unicode('f50d');
$ti-icon-brand-torchain: unicode('f5a4');
$ti-icon-brand-toyota: unicode('f262');
$ti-icon-brand-trello: unicode('f39d');
$ti-icon-brand-tripadvisor: unicode('f002');
$ti-icon-brand-tumblr: unicode('ed04');
$ti-icon-brand-twilio: unicode('f617');
$ti-icon-brand-twitch: unicode('ed05');
$ti-icon-brand-twitter: unicode('ec27');
$ti-icon-brand-twitter-filled: unicode('f7eb');
$ti-icon-brand-typescript: unicode('f5f1');
$ti-icon-brand-uber: unicode('ef75');
$ti-icon-brand-ubuntu: unicode('ef59');
$ti-icon-brand-unity: unicode('f49d');
$ti-icon-brand-unsplash: unicode('edd8');
$ti-icon-brand-upwork: unicode('f39e');
$ti-icon-brand-valorant: unicode('f39f');
$ti-icon-brand-vercel: unicode('ef24');
$ti-icon-brand-vimeo: unicode('ed06');
$ti-icon-brand-vinted: unicode('f20f');
$ti-icon-brand-visa: unicode('f380');
$ti-icon-brand-visual-studio: unicode('ef76');
$ti-icon-brand-vite: unicode('f5f2');
$ti-icon-brand-vivaldi: unicode('f210');
$ti-icon-brand-vk: unicode('ed72');
$ti-icon-brand-vlc: unicode('fa78');
$ti-icon-brand-volkswagen: unicode('f50e');
$ti-icon-brand-vsco: unicode('f334');
$ti-icon-brand-vscode: unicode('f3a0');
$ti-icon-brand-vue: unicode('f0e0');
$ti-icon-brand-walmart: unicode('f211');
$ti-icon-brand-waze: unicode('f5d8');
$ti-icon-brand-webflow: unicode('f2d2');
$ti-icon-brand-wechat: unicode('f5f3');
$ti-icon-brand-weibo: unicode('f609');
$ti-icon-brand-whatsapp: unicode('ec74');
$ti-icon-brand-wikipedia: unicode('fa79');
$ti-icon-brand-windows: unicode('ecd8');
$ti-icon-brand-windy: unicode('f4dd');
$ti-icon-brand-wish: unicode('f212');
$ti-icon-brand-wix: unicode('f3a1');
$ti-icon-brand-wordpress: unicode('f2d3');
$ti-icon-brand-x: unicode('fc0f');
$ti-icon-brand-x-filled: unicode('fc21');
$ti-icon-brand-xamarin: unicode('fa7a');
$ti-icon-brand-xbox: unicode('f298');
$ti-icon-brand-xdeep: unicode('fc10');
$ti-icon-brand-xing: unicode('f21a');
$ti-icon-brand-yahoo: unicode('ed73');
$ti-icon-brand-yandex: unicode('fae1');
$ti-icon-brand-yarn: unicode('fd79');
$ti-icon-brand-yatse: unicode('f213');
$ti-icon-brand-ycombinator: unicode('edd9');
$ti-icon-brand-youtube: unicode('ec90');
$ti-icon-brand-youtube-filled: unicode('fc22');
$ti-icon-brand-youtube-kids: unicode('f214');
$ti-icon-brand-zalando: unicode('f49e');
$ti-icon-brand-zapier: unicode('f49f');
$ti-icon-brand-zeit: unicode('f335');
$ti-icon-brand-zhihu: unicode('f60a');
$ti-icon-brand-zoom: unicode('f215');
$ti-icon-brand-zulip: unicode('f4de');
$ti-icon-brand-zwift: unicode('f216');
$ti-icon-bread: unicode('efa3');
$ti-icon-bread-filled: unicode('fe85');
$ti-icon-bread-off: unicode('f3cb');
$ti-icon-briefcase: unicode('ea46');
$ti-icon-briefcase-2: unicode('fb03');
$ti-icon-briefcase-2-filled: unicode('fe84');
$ti-icon-briefcase-filled: unicode('fd00');
$ti-icon-briefcase-off: unicode('f3cc');
$ti-icon-brightness: unicode('eb7f');
$ti-icon-brightness-2: unicode('ee19');
$ti-icon-brightness-auto: unicode('fd99');
$ti-icon-brightness-auto-filled: unicode('fe83');
$ti-icon-brightness-down: unicode('eb7d');
$ti-icon-brightness-down-filled: unicode('fb23');
$ti-icon-brightness-filled: unicode('fe82');
$ti-icon-brightness-half: unicode('ee1a');
$ti-icon-brightness-off: unicode('f3cd');
$ti-icon-brightness-up: unicode('eb7e');
$ti-icon-brightness-up-filled: unicode('fb24');
$ti-icon-broadcast: unicode('f1e9');
$ti-icon-broadcast-off: unicode('f1e8');
$ti-icon-browser: unicode('ebb7');
$ti-icon-browser-check: unicode('efd6');
$ti-icon-browser-off: unicode('f0c1');
$ti-icon-browser-plus: unicode('efd7');
$ti-icon-browser-x: unicode('efd8');
$ti-icon-brush: unicode('ebb8');
$ti-icon-brush-off: unicode('f0c2');
$ti-icon-bubble: unicode('feba');
$ti-icon-bubble-filled: unicode('fec3');
$ti-icon-bubble-minus: unicode('febe');
$ti-icon-bubble-plus: unicode('febd');
$ti-icon-bubble-tea: unicode('ff51');
$ti-icon-bubble-tea-2: unicode('ff52');
$ti-icon-bubble-text: unicode('febc');
$ti-icon-bubble-x: unicode('febb');
$ti-icon-bucket: unicode('ea47');
$ti-icon-bucket-droplet: unicode('f56a');
$ti-icon-bucket-off: unicode('f103');
$ti-icon-bug: unicode('ea48');
$ti-icon-bug-filled: unicode('fd01');
$ti-icon-bug-off: unicode('f0c3');
$ti-icon-building: unicode('ea4f');
$ti-icon-building-airport: unicode('ffa9');
$ti-icon-building-arch: unicode('ea49');
$ti-icon-building-bank: unicode('ebe2');
$ti-icon-building-bridge: unicode('ea4b');
$ti-icon-building-bridge-2: unicode('ea4a');
$ti-icon-building-broadcast-tower: unicode('f4be');
$ti-icon-building-broadcast-tower-filled: unicode('fe81');
$ti-icon-building-burj-al-arab: unicode('ff50');
$ti-icon-building-carousel: unicode('ed87');
$ti-icon-building-castle: unicode('ed88');
$ti-icon-building-church: unicode('ea4c');
$ti-icon-building-circus: unicode('f4bf');
$ti-icon-building-cog: unicode('10062');
$ti-icon-building-community: unicode('ebf6');
$ti-icon-building-cottage: unicode('ee1b');
$ti-icon-building-estate: unicode('f5a5');
$ti-icon-building-factory: unicode('ee1c');
$ti-icon-building-factory-2: unicode('f082');
$ti-icon-building-fortress: unicode('ed89');
$ti-icon-building-hospital: unicode('ea4d');
$ti-icon-building-lighthouse: unicode('ed8a');
$ti-icon-building-minus: unicode('10061');
$ti-icon-building-monument: unicode('ed26');
$ti-icon-building-mosque: unicode('fa57');
$ti-icon-building-off: unicode('fefd');
$ti-icon-building-pavilion: unicode('ebf7');
$ti-icon-building-plus: unicode('10060');
$ti-icon-building-skyscraper: unicode('ec39');
$ti-icon-building-stadium: unicode('f641');
$ti-icon-building-store: unicode('ea4e');
$ti-icon-building-tunnel: unicode('f5a6');
$ti-icon-building-warehouse: unicode('ebe3');
$ti-icon-building-wind-turbine: unicode('f4c0');
$ti-icon-buildings: unicode('ff40');
$ti-icon-bulb: unicode('ea51');
$ti-icon-bulb-filled: unicode('f66a');
$ti-icon-bulb-off: unicode('ea50');
$ti-icon-bulldozer: unicode('ee1d');
$ti-icon-burger: unicode('fcb4');
$ti-icon-bus: unicode('ebe4');
$ti-icon-bus-off: unicode('f3ce');
$ti-icon-bus-stop: unicode('f2d4');
$ti-icon-businessplan: unicode('ee1e');
$ti-icon-butterfly: unicode('efd9');
$ti-icon-butterfly-filled: unicode('10016');
$ti-icon-cactus: unicode('f21b');
$ti-icon-cactus-filled: unicode('fb25');
$ti-icon-cactus-off: unicode('f3cf');
$ti-icon-cake: unicode('f00f');
$ti-icon-cake-off: unicode('f104');
$ti-icon-calculator: unicode('eb80');
$ti-icon-calculator-filled: unicode('fb26');
$ti-icon-calculator-off: unicode('f0c4');
$ti-icon-calendar: unicode('ea53');
$ti-icon-calendar-bolt: unicode('f822');
$ti-icon-calendar-cancel: unicode('f823');
$ti-icon-calendar-check: unicode('f824');
$ti-icon-calendar-clock: unicode('fd2e');
$ti-icon-calendar-code: unicode('f825');
$ti-icon-calendar-cog: unicode('f826');
$ti-icon-calendar-dollar: unicode('f827');
$ti-icon-calendar-dot: unicode('fd3e');
$ti-icon-calendar-down: unicode('f828');
$ti-icon-calendar-due: unicode('f621');
$ti-icon-calendar-event: unicode('ea52');
$ti-icon-calendar-exclamation: unicode('f829');
$ti-icon-calendar-filled: unicode('fb27');
$ti-icon-calendar-heart: unicode('f82a');
$ti-icon-calendar-minus: unicode('ebb9');
$ti-icon-calendar-month: unicode('fd2f');
$ti-icon-calendar-off: unicode('ee1f');
$ti-icon-calendar-pause: unicode('f82b');
$ti-icon-calendar-pin: unicode('f82c');
$ti-icon-calendar-plus: unicode('ebba');
$ti-icon-calendar-question: unicode('f82d');
$ti-icon-calendar-repeat: unicode('fad2');
$ti-icon-calendar-sad: unicode('fd1d');
$ti-icon-calendar-search: unicode('f82e');
$ti-icon-calendar-share: unicode('f82f');
$ti-icon-calendar-smile: unicode('fd1e');
$ti-icon-calendar-star: unicode('f830');
$ti-icon-calendar-stats: unicode('ee20');
$ti-icon-calendar-time: unicode('ee21');
$ti-icon-calendar-up: unicode('f831');
$ti-icon-calendar-user: unicode('fd1f');
$ti-icon-calendar-week: unicode('fd30');
$ti-icon-calendar-x: unicode('f832');
$ti-icon-camera: unicode('ea54');
$ti-icon-camera-ai: unicode('ffa8');
$ti-icon-camera-bitcoin: unicode('ffa7');
$ti-icon-camera-bolt: unicode('f833');
$ti-icon-camera-cancel: unicode('f834');
$ti-icon-camera-check: unicode('f835');
$ti-icon-camera-code: unicode('f836');
$ti-icon-camera-cog: unicode('f837');
$ti-icon-camera-dollar: unicode('f838');
$ti-icon-camera-down: unicode('f839');
$ti-icon-camera-exclamation: unicode('f83a');
$ti-icon-camera-filled: unicode('fa37');
$ti-icon-camera-heart: unicode('f83b');
$ti-icon-camera-minus: unicode('ec3a');
$ti-icon-camera-moon: unicode('ffa6');
$ti-icon-camera-off: unicode('ecee');
$ti-icon-camera-pause: unicode('f83c');
$ti-icon-camera-pin: unicode('f83d');
$ti-icon-camera-plus: unicode('ec3b');
$ti-icon-camera-question: unicode('f83e');
$ti-icon-camera-rotate: unicode('ee22');
$ti-icon-camera-search: unicode('f83f');
$ti-icon-camera-selfie: unicode('ee23');
$ti-icon-camera-share: unicode('f840');
$ti-icon-camera-spark: unicode('ffbc');
$ti-icon-camera-star: unicode('f841');
$ti-icon-camera-up: unicode('f842');
$ti-icon-camera-x: unicode('f843');
$ti-icon-camper: unicode('fa25');
$ti-icon-campfire: unicode('f5a7');
$ti-icon-campfire-filled: unicode('fb28');
$ti-icon-cancel: unicode('ff11');
$ti-icon-candle: unicode('efc6');
$ti-icon-candle-filled: unicode('fc23');
$ti-icon-candy: unicode('ef0d');
$ti-icon-candy-off: unicode('f0c5');
$ti-icon-cane: unicode('f50f');
$ti-icon-cannabis: unicode('f4c1');
$ti-icon-cannabis-filled: unicode('10015');
$ti-icon-cap-projecting: unicode('ff22');
$ti-icon-cap-rounded: unicode('ff21');
$ti-icon-cap-straight: unicode('ff20');
$ti-icon-capsule: unicode('fae3');
$ti-icon-capsule-filled: unicode('fc24');
$ti-icon-capsule-horizontal: unicode('fae2');
$ti-icon-capsule-horizontal-filled: unicode('fc25');
$ti-icon-capture: unicode('ec3c');
$ti-icon-capture-filled: unicode('fb29');
$ti-icon-capture-off: unicode('f0c6');
$ti-icon-car: unicode('ebbb');
$ti-icon-car-4wd: unicode('fdb8');
$ti-icon-car-4wd-filled: unicode('1001e');
$ti-icon-car-crane: unicode('ef25');
$ti-icon-car-crash: unicode('efa4');
$ti-icon-car-fan: unicode('fdb3');
$ti-icon-car-fan-1: unicode('fdb7');
$ti-icon-car-fan-2: unicode('fdb6');
$ti-icon-car-fan-3: unicode('fdb5');
$ti-icon-car-fan-auto: unicode('fdb4');
$ti-icon-car-fan-filled: unicode('1001d');
$ti-icon-car-filled: unicode('1004c');
$ti-icon-car-garage: unicode('fc77');
$ti-icon-car-off: unicode('f0c7');
$ti-icon-car-suv: unicode('fc8b');
$ti-icon-car-suv-filled: unicode('1004d');
$ti-icon-car-turbine: unicode('f4fd');
$ti-icon-carambola: unicode('feb9');
$ti-icon-carambola-filled: unicode('10014');
$ti-icon-caravan: unicode('ec7c');
$ti-icon-cardboards: unicode('ed74');
$ti-icon-cardboards-filled: unicode('1001c');
$ti-icon-cardboards-off: unicode('f0c8');
$ti-icon-cards: unicode('f510');
$ti-icon-cards-filled: unicode('fc26');
$ti-icon-caret-down: unicode('eb5d');
$ti-icon-caret-down-filled: unicode('fb2a');
$ti-icon-caret-left: unicode('eb5e');
$ti-icon-caret-left-filled: unicode('fb2b');
$ti-icon-caret-left-right: unicode('fc43');
$ti-icon-caret-left-right-filled: unicode('fd02');
$ti-icon-caret-right: unicode('eb5f');
$ti-icon-caret-right-filled: unicode('fb2c');
$ti-icon-caret-up: unicode('eb60');
$ti-icon-caret-up-down: unicode('fc44');
$ti-icon-caret-up-down-filled: unicode('fd03');
$ti-icon-caret-up-filled: unicode('fb2d');
$ti-icon-carousel-horizontal: unicode('f659');
$ti-icon-carousel-horizontal-filled: unicode('fa92');
$ti-icon-carousel-vertical: unicode('f65a');
$ti-icon-carousel-vertical-filled: unicode('fa93');
$ti-icon-carrot: unicode('f21c');
$ti-icon-carrot-off: unicode('f3d0');
$ti-icon-cash: unicode('ea55');
$ti-icon-cash-banknote: unicode('ee25');
$ti-icon-cash-banknote-filled: unicode('fe80');
$ti-icon-cash-banknote-off: unicode('ee24');
$ti-icon-cash-off: unicode('f105');
$ti-icon-cash-register: unicode('fee6');
$ti-icon-cast: unicode('ea56');
$ti-icon-cast-off: unicode('f0c9');
$ti-icon-cat: unicode('f65b');
$ti-icon-category: unicode('f1f6');
$ti-icon-category-2: unicode('f1f5');
$ti-icon-category-filled: unicode('fb2e');
$ti-icon-category-minus: unicode('fd20');
$ti-icon-category-plus: unicode('fd21');
$ti-icon-ce: unicode('ed75');
$ti-icon-ce-off: unicode('f0ca');
$ti-icon-cell: unicode('f05f');
$ti-icon-cell-signal-1: unicode('f083');
$ti-icon-cell-signal-2: unicode('f084');
$ti-icon-cell-signal-3: unicode('f085');
$ti-icon-cell-signal-4: unicode('f086');
$ti-icon-cell-signal-5: unicode('f087');
$ti-icon-cell-signal-off: unicode('f088');
$ti-icon-certificate: unicode('ed76');
$ti-icon-certificate-2: unicode('f073');
$ti-icon-certificate-2-off: unicode('f0cb');
$ti-icon-certificate-off: unicode('f0cc');
$ti-icon-chair-director: unicode('f2d5');
$ti-icon-chalkboard: unicode('f34d');
$ti-icon-chalkboard-off: unicode('f3d1');
$ti-icon-charging-pile: unicode('ee26');
$ti-icon-charging-pile-filled: unicode('1001b');
$ti-icon-chart-arcs: unicode('ee28');
$ti-icon-chart-arcs-3: unicode('ee27');
$ti-icon-chart-area: unicode('ea58');
$ti-icon-chart-area-filled: unicode('f66b');
$ti-icon-chart-area-line: unicode('ea57');
$ti-icon-chart-area-line-filled: unicode('f66c');
$ti-icon-chart-arrows: unicode('ee2a');
$ti-icon-chart-arrows-vertical: unicode('ee29');
$ti-icon-chart-bar: unicode('ea59');
$ti-icon-chart-bar-off: unicode('f3d2');
$ti-icon-chart-bar-popular: unicode('fef7');
$ti-icon-chart-bubble: unicode('ec75');
$ti-icon-chart-bubble-filled: unicode('f66d');
$ti-icon-chart-candle: unicode('ea5a');
$ti-icon-chart-candle-filled: unicode('f66e');
$ti-icon-chart-circles: unicode('ee2b');
$ti-icon-chart-cohort: unicode('fef6');
$ti-icon-chart-column: unicode('ffa5');
$ti-icon-chart-covariate: unicode('ffa4');
$ti-icon-chart-donut: unicode('ea5b');
$ti-icon-chart-donut-2: unicode('ee2c');
$ti-icon-chart-donut-3: unicode('ee2d');
$ti-icon-chart-donut-4: unicode('ee2e');
$ti-icon-chart-donut-filled: unicode('f66f');
$ti-icon-chart-dots: unicode('ee2f');
$ti-icon-chart-dots-2: unicode('f097');
$ti-icon-chart-dots-3: unicode('f098');
$ti-icon-chart-dots-filled: unicode('fd04');
$ti-icon-chart-funnel: unicode('fef5');
$ti-icon-chart-grid-dots: unicode('f4c2');
$ti-icon-chart-grid-dots-filled: unicode('fd05');
$ti-icon-chart-histogram: unicode('f65c');
$ti-icon-chart-infographic: unicode('ee30');
$ti-icon-chart-line: unicode('ea5c');
$ti-icon-chart-pie: unicode('ea5d');
$ti-icon-chart-pie-2: unicode('ee31');
$ti-icon-chart-pie-3: unicode('ee32');
$ti-icon-chart-pie-4: unicode('ee33');
$ti-icon-chart-pie-filled: unicode('f670');
$ti-icon-chart-pie-off: unicode('f3d3');
$ti-icon-chart-ppf: unicode('f618');
$ti-icon-chart-radar: unicode('ed77');
$ti-icon-chart-sankey: unicode('f619');
$ti-icon-chart-scatter: unicode('fd93');
$ti-icon-chart-scatter-3d: unicode('fd92');
$ti-icon-chart-treemap: unicode('f381');
$ti-icon-check: unicode('ea5e');
$ti-icon-checkbox: unicode('eba6');
$ti-icon-checklist: unicode('f074');
$ti-icon-checks: unicode('ebaa');
$ti-icon-checkup-list: unicode('ef5a');
$ti-icon-cheese: unicode('ef26');
$ti-icon-chef-hat: unicode('f21d');
$ti-icon-chef-hat-off: unicode('f3d4');
$ti-icon-cherry: unicode('f511');
$ti-icon-cherry-filled: unicode('f728');
$ti-icon-chess: unicode('f382');
$ti-icon-chess-bishop: unicode('f56b');
$ti-icon-chess-bishop-filled: unicode('f729');
$ti-icon-chess-filled: unicode('f72a');
$ti-icon-chess-king: unicode('f56c');
$ti-icon-chess-king-filled: unicode('f72b');
$ti-icon-chess-knight: unicode('f56d');
$ti-icon-chess-knight-filled: unicode('f72c');
$ti-icon-chess-queen: unicode('f56e');
$ti-icon-chess-queen-filled: unicode('f72d');
$ti-icon-chess-rook: unicode('f56f');
$ti-icon-chess-rook-filled: unicode('f72e');
$ti-icon-chevron-compact-down: unicode('faf0');
$ti-icon-chevron-compact-left: unicode('faf1');
$ti-icon-chevron-compact-right: unicode('faf2');
$ti-icon-chevron-compact-up: unicode('faf3');
$ti-icon-chevron-down: unicode('ea5f');
$ti-icon-chevron-down-left: unicode('ed09');
$ti-icon-chevron-down-right: unicode('ed0a');
$ti-icon-chevron-left: unicode('ea60');
$ti-icon-chevron-left-pipe: unicode('fae4');
$ti-icon-chevron-right: unicode('ea61');
$ti-icon-chevron-right-pipe: unicode('fae5');
$ti-icon-chevron-up: unicode('ea62');
$ti-icon-chevron-up-left: unicode('ed0b');
$ti-icon-chevron-up-right: unicode('ed0c');
$ti-icon-chevrons-down: unicode('ea63');
$ti-icon-chevrons-down-left: unicode('ed0d');
$ti-icon-chevrons-down-right: unicode('ed0e');
$ti-icon-chevrons-left: unicode('ea64');
$ti-icon-chevrons-right: unicode('ea65');
$ti-icon-chevrons-up: unicode('ea66');
$ti-icon-chevrons-up-left: unicode('ed0f');
$ti-icon-chevrons-up-right: unicode('ed10');
$ti-icon-chisel: unicode('f383');
$ti-icon-christmas-ball: unicode('fd31');
$ti-icon-christmas-tree: unicode('ed78');
$ti-icon-christmas-tree-filled: unicode('1001a');
$ti-icon-christmas-tree-off: unicode('f3d5');
$ti-icon-circle: unicode('ea6b');
$ti-icon-circle-arrow-down: unicode('f6f9');
$ti-icon-circle-arrow-down-filled: unicode('f6f4');
$ti-icon-circle-arrow-down-left: unicode('f6f6');
$ti-icon-circle-arrow-down-left-filled: unicode('f6f5');
$ti-icon-circle-arrow-down-right: unicode('f6f8');
$ti-icon-circle-arrow-down-right-filled: unicode('f6f7');
$ti-icon-circle-arrow-left: unicode('f6fb');
$ti-icon-circle-arrow-left-filled: unicode('f6fa');
$ti-icon-circle-arrow-right: unicode('f6fd');
$ti-icon-circle-arrow-right-filled: unicode('f6fc');
$ti-icon-circle-arrow-up: unicode('f703');
$ti-icon-circle-arrow-up-filled: unicode('f6fe');
$ti-icon-circle-arrow-up-left: unicode('f700');
$ti-icon-circle-arrow-up-left-filled: unicode('f6ff');
$ti-icon-circle-arrow-up-right: unicode('f702');
$ti-icon-circle-arrow-up-right-filled: unicode('f701');
$ti-icon-circle-caret-down: unicode('f4a9');
$ti-icon-circle-caret-left: unicode('f4aa');
$ti-icon-circle-caret-right: unicode('f4ab');
$ti-icon-circle-caret-up: unicode('f4ac');
$ti-icon-circle-check: unicode('ea67');
$ti-icon-circle-check-filled: unicode('f704');
$ti-icon-circle-chevron-down: unicode('f622');
$ti-icon-circle-chevron-left: unicode('f623');
$ti-icon-circle-chevron-right: unicode('f624');
$ti-icon-circle-chevron-up: unicode('f625');
$ti-icon-circle-chevrons-down: unicode('f642');
$ti-icon-circle-chevrons-left: unicode('f643');
$ti-icon-circle-chevrons-right: unicode('f644');
$ti-icon-circle-chevrons-up: unicode('f645');
$ti-icon-circle-dashed: unicode('ed27');
$ti-icon-circle-dashed-check: unicode('feb8');
$ti-icon-circle-dashed-letter-a: unicode('ff9a');
$ti-icon-circle-dashed-letter-b: unicode('ff99');
$ti-icon-circle-dashed-letter-c: unicode('ff98');
$ti-icon-circle-dashed-letter-d: unicode('ff97');
$ti-icon-circle-dashed-letter-e: unicode('ff96');
$ti-icon-circle-dashed-letter-f: unicode('ff95');
$ti-icon-circle-dashed-letter-g: unicode('ff94');
$ti-icon-circle-dashed-letter-h: unicode('ff93');
$ti-icon-circle-dashed-letter-i: unicode('ff92');
$ti-icon-circle-dashed-letter-j: unicode('ff91');
$ti-icon-circle-dashed-letter-k: unicode('ff90');
$ti-icon-circle-dashed-letter-l: unicode('ff8f');
$ti-icon-circle-dashed-letter-letter-v: unicode('ff8e');
$ti-icon-circle-dashed-letter-m: unicode('ff8d');
$ti-icon-circle-dashed-letter-n: unicode('ff8c');
$ti-icon-circle-dashed-letter-o: unicode('ff8b');
$ti-icon-circle-dashed-letter-p: unicode('ff8a');
$ti-icon-circle-dashed-letter-q: unicode('ff89');
$ti-icon-circle-dashed-letter-r: unicode('ff88');
$ti-icon-circle-dashed-letter-s: unicode('ff87');
$ti-icon-circle-dashed-letter-t: unicode('ff86');
$ti-icon-circle-dashed-letter-u: unicode('ff85');
$ti-icon-circle-dashed-letter-v: unicode('ff84');
$ti-icon-circle-dashed-letter-w: unicode('ff83');
$ti-icon-circle-dashed-letter-x: unicode('ff82');
$ti-icon-circle-dashed-letter-y: unicode('ff81');
$ti-icon-circle-dashed-letter-z: unicode('ff80');
$ti-icon-circle-dashed-minus: unicode('feb7');
$ti-icon-circle-dashed-number-0: unicode('fc6b');
$ti-icon-circle-dashed-number-1: unicode('fc6c');
$ti-icon-circle-dashed-number-2: unicode('fc6d');
$ti-icon-circle-dashed-number-3: unicode('fc6e');
$ti-icon-circle-dashed-number-4: unicode('fc6f');
$ti-icon-circle-dashed-number-5: unicode('fc70');
$ti-icon-circle-dashed-number-6: unicode('fc71');
$ti-icon-circle-dashed-number-7: unicode('fc72');
$ti-icon-circle-dashed-number-8: unicode('fc73');
$ti-icon-circle-dashed-number-9: unicode('fc74');
$ti-icon-circle-dashed-percentage: unicode('fd7a');
$ti-icon-circle-dashed-plus: unicode('feb6');
$ti-icon-circle-dashed-x: unicode('fc75');
$ti-icon-circle-dot: unicode('efb1');
$ti-icon-circle-dot-filled: unicode('f705');
$ti-icon-circle-dotted: unicode('ed28');
$ti-icon-circle-dotted-letter-a: unicode('ff7f');
$ti-icon-circle-dotted-letter-b: unicode('ff7e');
$ti-icon-circle-dotted-letter-c: unicode('ff7d');
$ti-icon-circle-dotted-letter-d: unicode('ff7c');
$ti-icon-circle-dotted-letter-e: unicode('ff7b');
$ti-icon-circle-dotted-letter-f: unicode('ff7a');
$ti-icon-circle-dotted-letter-g: unicode('ff79');
$ti-icon-circle-dotted-letter-h: unicode('ff78');
$ti-icon-circle-dotted-letter-i: unicode('ff77');
$ti-icon-circle-dotted-letter-j: unicode('ff76');
$ti-icon-circle-dotted-letter-k: unicode('ff75');
$ti-icon-circle-dotted-letter-l: unicode('ff74');
$ti-icon-circle-dotted-letter-m: unicode('ff73');
$ti-icon-circle-dotted-letter-n: unicode('ff72');
$ti-icon-circle-dotted-letter-o: unicode('ff71');
$ti-icon-circle-dotted-letter-p: unicode('ff70');
$ti-icon-circle-dotted-letter-q: unicode('ff6f');
$ti-icon-circle-dotted-letter-r: unicode('ff6e');
$ti-icon-circle-dotted-letter-s: unicode('ff6d');
$ti-icon-circle-dotted-letter-t: unicode('ff6c');
$ti-icon-circle-dotted-letter-u: unicode('ff6b');
$ti-icon-circle-dotted-letter-v: unicode('ff6a');
$ti-icon-circle-dotted-letter-w: unicode('ff69');
$ti-icon-circle-dotted-letter-x: unicode('ff68');
$ti-icon-circle-dotted-letter-y: unicode('ff67');
$ti-icon-circle-dotted-letter-z: unicode('ff66');
$ti-icon-circle-filled: unicode('f671');
$ti-icon-circle-half: unicode('ee3f');
$ti-icon-circle-half-2: unicode('eff3');
$ti-icon-circle-half-vertical: unicode('ee3e');
$ti-icon-circle-key: unicode('f633');
$ti-icon-circle-key-filled: unicode('f706');
$ti-icon-circle-letter-a: unicode('f441');
$ti-icon-circle-letter-a-filled: unicode('fe7f');
$ti-icon-circle-letter-b: unicode('f442');
$ti-icon-circle-letter-b-filled: unicode('fe7e');
$ti-icon-circle-letter-c: unicode('f443');
$ti-icon-circle-letter-c-filled: unicode('fe7d');
$ti-icon-circle-letter-d: unicode('f444');
$ti-icon-circle-letter-d-filled: unicode('fe7c');
$ti-icon-circle-letter-e: unicode('f445');
$ti-icon-circle-letter-e-filled: unicode('fe7b');
$ti-icon-circle-letter-f: unicode('f446');
$ti-icon-circle-letter-f-filled: unicode('fe7a');
$ti-icon-circle-letter-g: unicode('f447');
$ti-icon-circle-letter-g-filled: unicode('fe79');
$ti-icon-circle-letter-h: unicode('f448');
$ti-icon-circle-letter-h-filled: unicode('fe78');
$ti-icon-circle-letter-i: unicode('f449');
$ti-icon-circle-letter-i-filled: unicode('fe77');
$ti-icon-circle-letter-j: unicode('f44a');
$ti-icon-circle-letter-j-filled: unicode('fe76');
$ti-icon-circle-letter-k: unicode('f44b');
$ti-icon-circle-letter-k-filled: unicode('fe75');
$ti-icon-circle-letter-l: unicode('f44c');
$ti-icon-circle-letter-l-filled: unicode('fe74');
$ti-icon-circle-letter-m: unicode('f44d');
$ti-icon-circle-letter-m-filled: unicode('fe73');
$ti-icon-circle-letter-n: unicode('f44e');
$ti-icon-circle-letter-n-filled: unicode('fe72');
$ti-icon-circle-letter-o: unicode('f44f');
$ti-icon-circle-letter-o-filled: unicode('fe71');
$ti-icon-circle-letter-p: unicode('f450');
$ti-icon-circle-letter-p-filled: unicode('fe70');
$ti-icon-circle-letter-q: unicode('f451');
$ti-icon-circle-letter-q-filled: unicode('fe6f');
$ti-icon-circle-letter-r: unicode('f452');
$ti-icon-circle-letter-r-filled: unicode('fe6e');
$ti-icon-circle-letter-s: unicode('f453');
$ti-icon-circle-letter-s-filled: unicode('fe6d');
$ti-icon-circle-letter-t: unicode('f454');
$ti-icon-circle-letter-t-filled: unicode('fe6c');
$ti-icon-circle-letter-u: unicode('f455');
$ti-icon-circle-letter-u-filled: unicode('fe6b');
$ti-icon-circle-letter-v: unicode('f4ad');
$ti-icon-circle-letter-v-filled: unicode('fe6a');
$ti-icon-circle-letter-w: unicode('f456');
$ti-icon-circle-letter-w-filled: unicode('fe69');
$ti-icon-circle-letter-x: unicode('f4ae');
$ti-icon-circle-letter-x-filled: unicode('fe68');
$ti-icon-circle-letter-y: unicode('f457');
$ti-icon-circle-letter-y-filled: unicode('fe67');
$ti-icon-circle-letter-z: unicode('f458');
$ti-icon-circle-letter-z-filled: unicode('fe66');
$ti-icon-circle-minus: unicode('ea68');
$ti-icon-circle-minus-2: unicode('fc8c');
$ti-icon-circle-number-0: unicode('ee34');
$ti-icon-circle-number-0-filled: unicode('f72f');
$ti-icon-circle-number-1: unicode('ee35');
$ti-icon-circle-number-1-filled: unicode('f730');
$ti-icon-circle-number-2: unicode('ee36');
$ti-icon-circle-number-2-filled: unicode('f731');
$ti-icon-circle-number-3: unicode('ee37');
$ti-icon-circle-number-3-filled: unicode('f732');
$ti-icon-circle-number-4: unicode('ee38');
$ti-icon-circle-number-4-filled: unicode('f733');
$ti-icon-circle-number-5: unicode('ee39');
$ti-icon-circle-number-5-filled: unicode('f734');
$ti-icon-circle-number-6: unicode('ee3a');
$ti-icon-circle-number-6-filled: unicode('f735');
$ti-icon-circle-number-7: unicode('ee3b');
$ti-icon-circle-number-7-filled: unicode('f736');
$ti-icon-circle-number-8: unicode('ee3c');
$ti-icon-circle-number-8-filled: unicode('f737');
$ti-icon-circle-number-9: unicode('ee3d');
$ti-icon-circle-number-9-filled: unicode('f738');
$ti-icon-circle-off: unicode('ee40');
$ti-icon-circle-percentage: unicode('fd7b');
$ti-icon-circle-percentage-filled: unicode('fed5');
$ti-icon-circle-plus: unicode('ea69');
$ti-icon-circle-plus-2: unicode('fc8d');
$ti-icon-circle-plus-filled: unicode('fef9');
$ti-icon-circle-rectangle: unicode('f010');
$ti-icon-circle-rectangle-filled: unicode('ff63');
$ti-icon-circle-rectangle-off: unicode('f0cd');
$ti-icon-circle-square: unicode('ece4');
$ti-icon-circle-triangle: unicode('f011');
$ti-icon-circle-x: unicode('ea6a');
$ti-icon-circle-x-filled: unicode('f739');
$ti-icon-circles: unicode('ece5');
$ti-icon-circles-filled: unicode('f672');
$ti-icon-circles-relation: unicode('f4c3');
$ti-icon-circuit-ammeter: unicode('f271');
$ti-icon-circuit-battery: unicode('f272');
$ti-icon-circuit-bulb: unicode('f273');
$ti-icon-circuit-capacitor: unicode('f275');
$ti-icon-circuit-capacitor-polarized: unicode('f274');
$ti-icon-circuit-cell: unicode('f277');
$ti-icon-circuit-cell-plus: unicode('f276');
$ti-icon-circuit-changeover: unicode('f278');
$ti-icon-circuit-diode: unicode('f27a');
$ti-icon-circuit-diode-zener: unicode('f279');
$ti-icon-circuit-ground: unicode('f27c');
$ti-icon-circuit-ground-digital: unicode('f27b');
$ti-icon-circuit-inductor: unicode('f27d');
$ti-icon-circuit-motor: unicode('f27e');
$ti-icon-circuit-pushbutton: unicode('f27f');
$ti-icon-circuit-resistor: unicode('f280');
$ti-icon-circuit-switch-closed: unicode('f281');
$ti-icon-circuit-switch-open: unicode('f282');
$ti-icon-circuit-voltmeter: unicode('f283');
$ti-icon-clear-all: unicode('ee41');
$ti-icon-clear-formatting: unicode('ebe5');
$ti-icon-click: unicode('ebbc');
$ti-icon-cliff-jumping: unicode('fefc');
$ti-icon-clipboard: unicode('ea6f');
$ti-icon-clipboard-check: unicode('ea6c');
$ti-icon-clipboard-copy: unicode('f299');
$ti-icon-clipboard-data: unicode('f563');
$ti-icon-clipboard-heart: unicode('f34e');
$ti-icon-clipboard-list: unicode('ea6d');
$ti-icon-clipboard-off: unicode('f0ce');
$ti-icon-clipboard-plus: unicode('efb2');
$ti-icon-clipboard-smile: unicode('fd9a');
$ti-icon-clipboard-text: unicode('f089');
$ti-icon-clipboard-typography: unicode('f34f');
$ti-icon-clipboard-x: unicode('ea6e');
$ti-icon-clock: unicode('ea70');
$ti-icon-clock-12: unicode('fc56');
$ti-icon-clock-2: unicode('f099');
$ti-icon-clock-24: unicode('fc57');
$ti-icon-clock-bitcoin: unicode('ff3f');
$ti-icon-clock-bolt: unicode('f844');
$ti-icon-clock-cancel: unicode('f546');
$ti-icon-clock-check: unicode('f7c1');
$ti-icon-clock-code: unicode('f845');
$ti-icon-clock-cog: unicode('f7c2');
$ti-icon-clock-dollar: unicode('f846');
$ti-icon-clock-down: unicode('f7c3');
$ti-icon-clock-edit: unicode('f547');
$ti-icon-clock-exclamation: unicode('f847');
$ti-icon-clock-filled: unicode('f73a');
$ti-icon-clock-heart: unicode('f7c4');
$ti-icon-clock-hour-1: unicode('f313');
$ti-icon-clock-hour-1-filled: unicode('fe65');
$ti-icon-clock-hour-10: unicode('f314');
$ti-icon-clock-hour-10-filled: unicode('fe64');
$ti-icon-clock-hour-11: unicode('f315');
$ti-icon-clock-hour-11-filled: unicode('fe63');
$ti-icon-clock-hour-12: unicode('f316');
$ti-icon-clock-hour-12-filled: unicode('fe62');
$ti-icon-clock-hour-2: unicode('f317');
$ti-icon-clock-hour-2-filled: unicode('fe61');
$ti-icon-clock-hour-3: unicode('f318');
$ti-icon-clock-hour-3-filled: unicode('fe60');
$ti-icon-clock-hour-4: unicode('f319');
$ti-icon-clock-hour-4-filled: unicode('fe5f');
$ti-icon-clock-hour-5: unicode('f31a');
$ti-icon-clock-hour-5-filled: unicode('fe5e');
$ti-icon-clock-hour-6: unicode('f31b');
$ti-icon-clock-hour-6-filled: unicode('fe5d');
$ti-icon-clock-hour-7: unicode('f31c');
$ti-icon-clock-hour-7-filled: unicode('fe5c');
$ti-icon-clock-hour-8: unicode('f31d');
$ti-icon-clock-hour-8-filled: unicode('fe5b');
$ti-icon-clock-hour-9: unicode('f31e');
$ti-icon-clock-hour-9-filled: unicode('fe5a');
$ti-icon-clock-minus: unicode('f848');
$ti-icon-clock-off: unicode('f0cf');
$ti-icon-clock-pause: unicode('f548');
$ti-icon-clock-pin: unicode('f849');
$ti-icon-clock-play: unicode('f549');
$ti-icon-clock-plus: unicode('f7c5');
$ti-icon-clock-question: unicode('f7c6');
$ti-icon-clock-record: unicode('f54a');
$ti-icon-clock-search: unicode('f7c7');
$ti-icon-clock-share: unicode('f84a');
$ti-icon-clock-shield: unicode('f7c8');
$ti-icon-clock-star: unicode('f7c9');
$ti-icon-clock-stop: unicode('f54b');
$ti-icon-clock-up: unicode('f7ca');
$ti-icon-clock-x: unicode('f7cb');
$ti-icon-clothes-rack: unicode('f285');
$ti-icon-clothes-rack-off: unicode('f3d6');
$ti-icon-cloud: unicode('ea76');
$ti-icon-cloud-bitcoin: unicode('ff3e');
$ti-icon-cloud-bolt: unicode('f84b');
$ti-icon-cloud-cancel: unicode('f84c');
$ti-icon-cloud-check: unicode('f84d');
$ti-icon-cloud-code: unicode('f84e');
$ti-icon-cloud-cog: unicode('f84f');
$ti-icon-cloud-computing: unicode('f1d0');
$ti-icon-cloud-data-connection: unicode('f1d1');
$ti-icon-cloud-dollar: unicode('f850');
$ti-icon-cloud-down: unicode('f851');
$ti-icon-cloud-download: unicode('ea71');
$ti-icon-cloud-exclamation: unicode('f852');
$ti-icon-cloud-filled: unicode('f673');
$ti-icon-cloud-fog: unicode('ecd9');
$ti-icon-cloud-heart: unicode('f853');
$ti-icon-cloud-lock: unicode('efdb');
$ti-icon-cloud-lock-open: unicode('efda');
$ti-icon-cloud-minus: unicode('f854');
$ti-icon-cloud-network: unicode('fc78');
$ti-icon-cloud-off: unicode('ed3e');
$ti-icon-cloud-pause: unicode('f855');
$ti-icon-cloud-pin: unicode('f856');
$ti-icon-cloud-plus: unicode('f857');
$ti-icon-cloud-question: unicode('f858');
$ti-icon-cloud-rain: unicode('ea72');
$ti-icon-cloud-search: unicode('f859');
$ti-icon-cloud-share: unicode('f85a');
$ti-icon-cloud-snow: unicode('ea73');
$ti-icon-cloud-star: unicode('f85b');
$ti-icon-cloud-storm: unicode('ea74');
$ti-icon-cloud-up: unicode('f85c');
$ti-icon-cloud-upload: unicode('ea75');
$ti-icon-cloud-x: unicode('f85d');
$ti-icon-clover: unicode('f1ea');
$ti-icon-clover-2: unicode('f21e');
$ti-icon-clover-filled: unicode('10013');
$ti-icon-clubs: unicode('eff4');
$ti-icon-clubs-filled: unicode('f674');
$ti-icon-code: unicode('ea77');
$ti-icon-code-asterisk: unicode('f312');
$ti-icon-code-circle: unicode('f4ff');
$ti-icon-code-circle-2: unicode('f4fe');
$ti-icon-code-circle-2-filled: unicode('fed4');
$ti-icon-code-circle-filled: unicode('fed3');
$ti-icon-code-dots: unicode('f61a');
$ti-icon-code-minus: unicode('ee42');
$ti-icon-code-off: unicode('f0d0');
$ti-icon-code-plus: unicode('ee43');
$ti-icon-coffee: unicode('ef0e');
$ti-icon-coffee-off: unicode('f106');
$ti-icon-coffin: unicode('f579');
$ti-icon-coin: unicode('eb82');
$ti-icon-coin-bitcoin: unicode('f2be');
$ti-icon-coin-bitcoin-filled: unicode('fd06');
$ti-icon-coin-euro: unicode('f2bf');
$ti-icon-coin-euro-filled: unicode('fd07');
$ti-icon-coin-filled: unicode('fd08');
$ti-icon-coin-monero: unicode('f4a0');
$ti-icon-coin-monero-filled: unicode('fd09');
$ti-icon-coin-off: unicode('f0d1');
$ti-icon-coin-pound: unicode('f2c0');
$ti-icon-coin-pound-filled: unicode('fd0a');
$ti-icon-coin-rupee: unicode('f2c1');
$ti-icon-coin-rupee-filled: unicode('fd0b');
$ti-icon-coin-taka: unicode('fd0d');
$ti-icon-coin-taka-filled: unicode('fd0c');
$ti-icon-coin-yen: unicode('f2c2');
$ti-icon-coin-yen-filled: unicode('fd0e');
$ti-icon-coin-yuan: unicode('f2c3');
$ti-icon-coin-yuan-filled: unicode('fd0f');
$ti-icon-coins: unicode('f65d');
$ti-icon-color-filter: unicode('f5a8');
$ti-icon-color-picker: unicode('ebe6');
$ti-icon-color-picker-off: unicode('f0d2');
$ti-icon-color-swatch: unicode('eb61');
$ti-icon-color-swatch-off: unicode('f0d3');
$ti-icon-column-insert-left: unicode('ee44');
$ti-icon-column-insert-right: unicode('ee45');
$ti-icon-column-remove: unicode('faf4');
$ti-icon-columns: unicode('eb83');
$ti-icon-columns-1: unicode('f6d4');
$ti-icon-columns-2: unicode('f6d5');
$ti-icon-columns-3: unicode('f6d6');
$ti-icon-columns-off: unicode('f0d4');
$ti-icon-comet: unicode('ec76');
$ti-icon-command: unicode('ea78');
$ti-icon-command-off: unicode('f3d7');
$ti-icon-compass: unicode('ea79');
$ti-icon-compass-filled: unicode('fd10');
$ti-icon-compass-off: unicode('f0d5');
$ti-icon-components: unicode('efa5');
$ti-icon-components-off: unicode('f0d6');
$ti-icon-cone: unicode('efdd');
$ti-icon-cone-2: unicode('efdc');
$ti-icon-cone-2-filled: unicode('fe59');
$ti-icon-cone-filled: unicode('fe58');
$ti-icon-cone-off: unicode('f3d8');
$ti-icon-cone-plus: unicode('fa94');
$ti-icon-confetti: unicode('ee46');
$ti-icon-confetti-off: unicode('f3d9');
$ti-icon-confucius: unicode('f58a');
$ti-icon-congruent-to: unicode('ffa3');
$ti-icon-container: unicode('ee47');
$ti-icon-container-off: unicode('f107');
$ti-icon-contract: unicode('fefb');
$ti-icon-contrast: unicode('ec4e');
$ti-icon-contrast-2: unicode('efc7');
$ti-icon-contrast-2-filled: unicode('fe57');
$ti-icon-contrast-2-off: unicode('f3da');
$ti-icon-contrast-filled: unicode('fe56');
$ti-icon-contrast-off: unicode('f3db');
$ti-icon-cooker: unicode('f57a');
$ti-icon-cookie: unicode('fdb1');
$ti-icon-cookie-filled: unicode('fe54');
$ti-icon-cookie-man: unicode('fdb2');
$ti-icon-cookie-man-filled: unicode('fe55');
$ti-icon-cookie-off: unicode('f0d7');
$ti-icon-copy: unicode('ea7a');
$ti-icon-copy-check: unicode('fdb0');
$ti-icon-copy-check-filled: unicode('fe53');
$ti-icon-copy-minus: unicode('fdaf');
$ti-icon-copy-minus-filled: unicode('fe52');
$ti-icon-copy-off: unicode('f0d8');
$ti-icon-copy-plus: unicode('fdae');
$ti-icon-copy-plus-filled: unicode('fe51');
$ti-icon-copy-x: unicode('fdad');
$ti-icon-copy-x-filled: unicode('fe50');
$ti-icon-copyleft: unicode('ec3d');
$ti-icon-copyleft-filled: unicode('f73b');
$ti-icon-copyleft-off: unicode('f0d9');
$ti-icon-copyright: unicode('ea7b');
$ti-icon-copyright-filled: unicode('f73c');
$ti-icon-copyright-off: unicode('f0da');
$ti-icon-corner-down-left: unicode('ea7c');
$ti-icon-corner-down-left-double: unicode('ee48');
$ti-icon-corner-down-right: unicode('ea7d');
$ti-icon-corner-down-right-double: unicode('ee49');
$ti-icon-corner-left-down: unicode('ea7e');
$ti-icon-corner-left-down-double: unicode('ee4a');
$ti-icon-corner-left-up: unicode('ea7f');
$ti-icon-corner-left-up-double: unicode('ee4b');
$ti-icon-corner-right-down: unicode('ea80');
$ti-icon-corner-right-down-double: unicode('ee4c');
$ti-icon-corner-right-up: unicode('ea81');
$ti-icon-corner-right-up-double: unicode('ee4d');
$ti-icon-corner-up-left: unicode('ea82');
$ti-icon-corner-up-left-double: unicode('ee4e');
$ti-icon-corner-up-right: unicode('ea83');
$ti-icon-corner-up-right-double: unicode('ee4f');
$ti-icon-cpu: unicode('ef8e');
$ti-icon-cpu-2: unicode('f075');
$ti-icon-cpu-off: unicode('f108');
$ti-icon-crane: unicode('ef27');
$ti-icon-crane-off: unicode('f109');
$ti-icon-creative-commons: unicode('efb3');
$ti-icon-creative-commons-by: unicode('f21f');
$ti-icon-creative-commons-nc: unicode('f220');
$ti-icon-creative-commons-nd: unicode('f221');
$ti-icon-creative-commons-off: unicode('f10a');
$ti-icon-creative-commons-sa: unicode('f222');
$ti-icon-creative-commons-zero: unicode('f223');
$ti-icon-credit-card: unicode('ea84');
$ti-icon-credit-card-filled: unicode('fd11');
$ti-icon-credit-card-off: unicode('ed11');
$ti-icon-credit-card-pay: unicode('fd32');
$ti-icon-credit-card-refund: unicode('fd33');
$ti-icon-cricket: unicode('f09a');
$ti-icon-crop: unicode('ea85');
$ti-icon-crop-1-1: unicode('fd50');
$ti-icon-crop-1-1-filled: unicode('fe4f');
$ti-icon-crop-16-9: unicode('fd51');
$ti-icon-crop-16-9-filled: unicode('fe4e');
$ti-icon-crop-3-2: unicode('fd52');
$ti-icon-crop-3-2-filled: unicode('fe4d');
$ti-icon-crop-5-4: unicode('fd53');
$ti-icon-crop-5-4-filled: unicode('fe4c');
$ti-icon-crop-7-5: unicode('fd54');
$ti-icon-crop-7-5-filled: unicode('fe4b');
$ti-icon-crop-landscape: unicode('fd55');
$ti-icon-crop-landscape-filled: unicode('fe4a');
$ti-icon-crop-portrait: unicode('fd56');
$ti-icon-crop-portrait-filled: unicode('fe49');
$ti-icon-cross: unicode('ef8f');
$ti-icon-cross-filled: unicode('f675');
$ti-icon-cross-off: unicode('f10b');
$ti-icon-crosshair: unicode('ec3e');
$ti-icon-crown: unicode('ed12');
$ti-icon-crown-off: unicode('ee50');
$ti-icon-crutches: unicode('ef5b');
$ti-icon-crutches-off: unicode('f10c');
$ti-icon-crystal-ball: unicode('f57b');
$ti-icon-csv: unicode('f791');
$ti-icon-cube: unicode('fa97');
$ti-icon-cube-3d-sphere: unicode('ecd7');
$ti-icon-cube-3d-sphere-off: unicode('f3b5');
$ti-icon-cube-off: unicode('fa95');
$ti-icon-cube-plus: unicode('fa96');
$ti-icon-cube-send: unicode('f61b');
$ti-icon-cube-spark: unicode('ffbb');
$ti-icon-cube-unfolded: unicode('f61c');
$ti-icon-cup: unicode('ef28');
$ti-icon-cup-off: unicode('f10d');
$ti-icon-curling: unicode('efc8');
$ti-icon-curly-loop: unicode('ecda');
$ti-icon-currency: unicode('efa6');
$ti-icon-currency-afghani: unicode('f65e');
$ti-icon-currency-bahraini: unicode('ee51');
$ti-icon-currency-baht: unicode('f08a');
$ti-icon-currency-bitcoin: unicode('ebab');
$ti-icon-currency-cent: unicode('ee53');
$ti-icon-currency-dinar: unicode('ee54');
$ti-icon-currency-dirham: unicode('ee55');
$ti-icon-currency-dogecoin: unicode('ef4b');
$ti-icon-currency-dollar: unicode('eb84');
$ti-icon-currency-dollar-australian: unicode('ee56');
$ti-icon-currency-dollar-brunei: unicode('f36c');
$ti-icon-currency-dollar-canadian: unicode('ee57');
$ti-icon-currency-dollar-guyanese: unicode('f36d');
$ti-icon-currency-dollar-off: unicode('f3dc');
$ti-icon-currency-dollar-singapore: unicode('ee58');
$ti-icon-currency-dollar-zimbabwean: unicode('f36e');
$ti-icon-currency-dong: unicode('f36f');
$ti-icon-currency-dram: unicode('f370');
$ti-icon-currency-ethereum: unicode('ee59');
$ti-icon-currency-euro: unicode('eb85');
$ti-icon-currency-euro-off: unicode('f3dd');
$ti-icon-currency-florin: unicode('faf5');
$ti-icon-currency-forint: unicode('ee5a');
$ti-icon-currency-frank: unicode('ee5b');
$ti-icon-currency-guarani: unicode('f371');
$ti-icon-currency-hryvnia: unicode('f372');
$ti-icon-currency-iranian-rial: unicode('fa58');
$ti-icon-currency-kip: unicode('f373');
$ti-icon-currency-krone-czech: unicode('ee5c');
$ti-icon-currency-krone-danish: unicode('ee5d');
$ti-icon-currency-krone-swedish: unicode('ee5e');
$ti-icon-currency-lari: unicode('f374');
$ti-icon-currency-leu: unicode('ee5f');
$ti-icon-currency-lira: unicode('ee60');
$ti-icon-currency-litecoin: unicode('ee61');
$ti-icon-currency-lyd: unicode('f375');
$ti-icon-currency-manat: unicode('f376');
$ti-icon-currency-monero: unicode('f377');
$ti-icon-currency-naira: unicode('ee62');
$ti-icon-currency-nano: unicode('f7a6');
$ti-icon-currency-off: unicode('f3de');
$ti-icon-currency-paanga: unicode('f378');
$ti-icon-currency-peso: unicode('f65f');
$ti-icon-currency-pound: unicode('ebac');
$ti-icon-currency-pound-off: unicode('f3df');
$ti-icon-currency-quetzal: unicode('f379');
$ti-icon-currency-real: unicode('ee63');
$ti-icon-currency-renminbi: unicode('ee64');
$ti-icon-currency-ripple: unicode('ee65');
$ti-icon-currency-riyal: unicode('ee66');
$ti-icon-currency-rubel: unicode('ee67');
$ti-icon-currency-rufiyaa: unicode('f37a');
$ti-icon-currency-rupee: unicode('ebad');
$ti-icon-currency-rupee-nepalese: unicode('f37b');
$ti-icon-currency-shekel: unicode('ee68');
$ti-icon-currency-solana: unicode('f4a1');
$ti-icon-currency-som: unicode('f37c');
$ti-icon-currency-taka: unicode('ee69');
$ti-icon-currency-tenge: unicode('f37d');
$ti-icon-currency-tugrik: unicode('ee6a');
$ti-icon-currency-won: unicode('ee6b');
$ti-icon-currency-xrp: unicode('fd34');
$ti-icon-currency-yen: unicode('ebae');
$ti-icon-currency-yen-off: unicode('f3e0');
$ti-icon-currency-yuan: unicode('f29a');
$ti-icon-currency-zloty: unicode('ee6c');
$ti-icon-current-location: unicode('ecef');
$ti-icon-current-location-off: unicode('f10e');
$ti-icon-cursor-off: unicode('f10f');
$ti-icon-cursor-text: unicode('ee6d');
$ti-icon-cut: unicode('ea86');
$ti-icon-cylinder: unicode('f54c');
$ti-icon-cylinder-off: unicode('fa98');
$ti-icon-cylinder-plus: unicode('fa99');
$ti-icon-dashboard: unicode('ea87');
$ti-icon-dashboard-filled: unicode('10019');
$ti-icon-dashboard-off: unicode('f3e1');
$ti-icon-database: unicode('ea88');
$ti-icon-database-cog: unicode('fa10');
$ti-icon-database-dollar: unicode('fa11');
$ti-icon-database-edit: unicode('fa12');
$ti-icon-database-exclamation: unicode('fa13');
$ti-icon-database-export: unicode('ee6e');
$ti-icon-database-heart: unicode('fa14');
$ti-icon-database-import: unicode('ee6f');
$ti-icon-database-leak: unicode('fa15');
$ti-icon-database-minus: unicode('fa16');
$ti-icon-database-off: unicode('ee70');
$ti-icon-database-plus: unicode('fa17');
$ti-icon-database-search: unicode('fa18');
$ti-icon-database-share: unicode('fa19');
$ti-icon-database-smile: unicode('fd9b');
$ti-icon-database-star: unicode('fa1a');
$ti-icon-database-x: unicode('fa1b');
$ti-icon-decimal: unicode('fa26');
$ti-icon-deer: unicode('f4c5');
$ti-icon-delta: unicode('f53c');
$ti-icon-dental: unicode('f025');
$ti-icon-dental-broken: unicode('f286');
$ti-icon-dental-off: unicode('f110');
$ti-icon-deselect: unicode('f9f3');
$ti-icon-desk: unicode('fd35');
$ti-icon-details: unicode('ee71');
$ti-icon-details-off: unicode('f3e2');
$ti-icon-device-airpods: unicode('f5a9');
$ti-icon-device-airpods-case: unicode('f646');
$ti-icon-device-airtag: unicode('fae6');
$ti-icon-device-analytics: unicode('ee72');
$ti-icon-device-audio-tape: unicode('ee73');
$ti-icon-device-camera-phone: unicode('f233');
$ti-icon-device-cctv: unicode('ee74');
$ti-icon-device-cctv-filled: unicode('1004b');
$ti-icon-device-cctv-off: unicode('f3e3');
$ti-icon-device-computer-camera: unicode('ee76');
$ti-icon-device-computer-camera-off: unicode('ee75');
$ti-icon-device-desktop: unicode('ea89');
$ti-icon-device-desktop-analytics: unicode('ee77');
$ti-icon-device-desktop-bolt: unicode('f85e');
$ti-icon-device-desktop-cancel: unicode('f85f');
$ti-icon-device-desktop-check: unicode('f860');
$ti-icon-device-desktop-code: unicode('f861');
$ti-icon-device-desktop-cog: unicode('f862');
$ti-icon-device-desktop-dollar: unicode('f863');
$ti-icon-device-desktop-down: unicode('f864');
$ti-icon-device-desktop-exclamation: unicode('f865');
$ti-icon-device-desktop-filled: unicode('1004a');
$ti-icon-device-desktop-heart: unicode('f866');
$ti-icon-device-desktop-minus: unicode('f867');
$ti-icon-device-desktop-off: unicode('ee78');
$ti-icon-device-desktop-pause: unicode('f868');
$ti-icon-device-desktop-pin: unicode('f869');
$ti-icon-device-desktop-plus: unicode('f86a');
$ti-icon-device-desktop-question: unicode('f86b');
$ti-icon-device-desktop-search: unicode('f86c');
$ti-icon-device-desktop-share: unicode('f86d');
$ti-icon-device-desktop-star: unicode('f86e');
$ti-icon-device-desktop-up: unicode('f86f');
$ti-icon-device-desktop-x: unicode('f870');
$ti-icon-device-floppy: unicode('eb62');
$ti-icon-device-gamepad: unicode('eb63');
$ti-icon-device-gamepad-2: unicode('f1d2');
$ti-icon-device-gamepad-3: unicode('fc58');
$ti-icon-device-gamepad-3-filled: unicode('10049');
$ti-icon-device-heart-monitor: unicode('f060');
$ti-icon-device-heart-monitor-filled: unicode('fa38');
$ti-icon-device-imac: unicode('f7a7');
$ti-icon-device-imac-bolt: unicode('f871');
$ti-icon-device-imac-cancel: unicode('f872');
$ti-icon-device-imac-check: unicode('f873');
$ti-icon-device-imac-code: unicode('f874');
$ti-icon-device-imac-cog: unicode('f875');
$ti-icon-device-imac-dollar: unicode('f876');
$ti-icon-device-imac-down: unicode('f877');
$ti-icon-device-imac-exclamation: unicode('f878');
$ti-icon-device-imac-filled: unicode('10048');
$ti-icon-device-imac-heart: unicode('f879');
$ti-icon-device-imac-minus: unicode('f87a');
$ti-icon-device-imac-off: unicode('f87b');
$ti-icon-device-imac-pause: unicode('f87c');
$ti-icon-device-imac-pin: unicode('f87d');
$ti-icon-device-imac-plus: unicode('f87e');
$ti-icon-device-imac-question: unicode('f87f');
$ti-icon-device-imac-search: unicode('f880');
$ti-icon-device-imac-share: unicode('f881');
$ti-icon-device-imac-star: unicode('f882');
$ti-icon-device-imac-up: unicode('f883');
$ti-icon-device-imac-x: unicode('f884');
$ti-icon-device-ipad: unicode('f648');
$ti-icon-device-ipad-bolt: unicode('f885');
$ti-icon-device-ipad-cancel: unicode('f886');
$ti-icon-device-ipad-check: unicode('f887');
$ti-icon-device-ipad-code: unicode('f888');
$ti-icon-device-ipad-cog: unicode('f889');
$ti-icon-device-ipad-dollar: unicode('f88a');
$ti-icon-device-ipad-down: unicode('f88b');
$ti-icon-device-ipad-exclamation: unicode('f88c');
$ti-icon-device-ipad-filled: unicode('10047');
$ti-icon-device-ipad-heart: unicode('f88d');
$ti-icon-device-ipad-horizontal: unicode('f647');
$ti-icon-device-ipad-horizontal-bolt: unicode('f88e');
$ti-icon-device-ipad-horizontal-cancel: unicode('f88f');
$ti-icon-device-ipad-horizontal-check: unicode('f890');
$ti-icon-device-ipad-horizontal-code: unicode('f891');
$ti-icon-device-ipad-horizontal-cog: unicode('f892');
$ti-icon-device-ipad-horizontal-dollar: unicode('f893');
$ti-icon-device-ipad-horizontal-down: unicode('f894');
$ti-icon-device-ipad-horizontal-exclamation: unicode('f895');
$ti-icon-device-ipad-horizontal-heart: unicode('f896');
$ti-icon-device-ipad-horizontal-minus: unicode('f897');
$ti-icon-device-ipad-horizontal-off: unicode('f898');
$ti-icon-device-ipad-horizontal-pause: unicode('f899');
$ti-icon-device-ipad-horizontal-pin: unicode('f89a');
$ti-icon-device-ipad-horizontal-plus: unicode('f89b');
$ti-icon-device-ipad-horizontal-question: unicode('f89c');
$ti-icon-device-ipad-horizontal-search: unicode('f89d');
$ti-icon-device-ipad-horizontal-share: unicode('f89e');
$ti-icon-device-ipad-horizontal-star: unicode('f89f');
$ti-icon-device-ipad-horizontal-up: unicode('f8a0');
$ti-icon-device-ipad-horizontal-x: unicode('f8a1');
$ti-icon-device-ipad-minus: unicode('f8a2');
$ti-icon-device-ipad-off: unicode('f8a3');
$ti-icon-device-ipad-pause: unicode('f8a4');
$ti-icon-device-ipad-pin: unicode('f8a5');
$ti-icon-device-ipad-plus: unicode('f8a6');
$ti-icon-device-ipad-question: unicode('f8a7');
$ti-icon-device-ipad-search: unicode('f8a8');
$ti-icon-device-ipad-share: unicode('f8a9');
$ti-icon-device-ipad-star: unicode('f8aa');
$ti-icon-device-ipad-up: unicode('f8ab');
$ti-icon-device-ipad-x: unicode('f8ac');
$ti-icon-device-landline-phone: unicode('f649');
$ti-icon-device-laptop: unicode('eb64');
$ti-icon-device-laptop-off: unicode('f061');
$ti-icon-device-mobile: unicode('ea8a');
$ti-icon-device-mobile-bolt: unicode('f8ad');
$ti-icon-device-mobile-cancel: unicode('f8ae');
$ti-icon-device-mobile-charging: unicode('f224');
$ti-icon-device-mobile-check: unicode('f8af');
$ti-icon-device-mobile-code: unicode('f8b0');
$ti-icon-device-mobile-cog: unicode('f8b1');
$ti-icon-device-mobile-dollar: unicode('f8b2');
$ti-icon-device-mobile-down: unicode('f8b3');
$ti-icon-device-mobile-exclamation: unicode('f8b4');
$ti-icon-device-mobile-filled: unicode('fa39');
$ti-icon-device-mobile-heart: unicode('f8b5');
$ti-icon-device-mobile-message: unicode('ee79');
$ti-icon-device-mobile-minus: unicode('f8b6');
$ti-icon-device-mobile-off: unicode('f062');
$ti-icon-device-mobile-pause: unicode('f8b7');
$ti-icon-device-mobile-pin: unicode('f8b8');
$ti-icon-device-mobile-plus: unicode('f8b9');
$ti-icon-device-mobile-question: unicode('f8ba');
$ti-icon-device-mobile-rotated: unicode('ecdb');
$ti-icon-device-mobile-search: unicode('f8bb');
$ti-icon-device-mobile-share: unicode('f8bc');
$ti-icon-device-mobile-star: unicode('f8bd');
$ti-icon-device-mobile-up: unicode('f8be');
$ti-icon-device-mobile-vibration: unicode('eb86');
$ti-icon-device-mobile-x: unicode('f8bf');
$ti-icon-device-nintendo: unicode('f026');
$ti-icon-device-nintendo-off: unicode('f111');
$ti-icon-device-projector: unicode('fc11');
$ti-icon-device-remote: unicode('f792');
$ti-icon-device-remote-filled: unicode('10046');
$ti-icon-device-sd-card: unicode('f384');
$ti-icon-device-sim: unicode('f4b2');
$ti-icon-device-sim-1: unicode('f4af');
$ti-icon-device-sim-2: unicode('f4b0');
$ti-icon-device-sim-3: unicode('f4b1');
$ti-icon-device-speaker: unicode('ea8b');
$ti-icon-device-speaker-filled: unicode('10045');
$ti-icon-device-speaker-off: unicode('f112');
$ti-icon-device-tablet: unicode('ea8c');
$ti-icon-device-tablet-bolt: unicode('f8c0');
$ti-icon-device-tablet-cancel: unicode('f8c1');
$ti-icon-device-tablet-check: unicode('f8c2');
$ti-icon-device-tablet-code: unicode('f8c3');
$ti-icon-device-tablet-cog: unicode('f8c4');
$ti-icon-device-tablet-dollar: unicode('f8c5');
$ti-icon-device-tablet-down: unicode('f8c6');
$ti-icon-device-tablet-exclamation: unicode('f8c7');
$ti-icon-device-tablet-filled: unicode('fa3a');
$ti-icon-device-tablet-heart: unicode('f8c8');
$ti-icon-device-tablet-minus: unicode('f8c9');
$ti-icon-device-tablet-off: unicode('f063');
$ti-icon-device-tablet-pause: unicode('f8ca');
$ti-icon-device-tablet-pin: unicode('f8cb');
$ti-icon-device-tablet-plus: unicode('f8cc');
$ti-icon-device-tablet-question: unicode('f8cd');
$ti-icon-device-tablet-search: unicode('f8ce');
$ti-icon-device-tablet-share: unicode('f8cf');
$ti-icon-device-tablet-star: unicode('f8d0');
$ti-icon-device-tablet-up: unicode('f8d1');
$ti-icon-device-tablet-x: unicode('f8d2');
$ti-icon-device-tv: unicode('ea8d');
$ti-icon-device-tv-filled: unicode('10043');
$ti-icon-device-tv-off: unicode('f064');
$ti-icon-device-tv-old: unicode('f1d3');
$ti-icon-device-tv-old-filled: unicode('10044');
$ti-icon-device-unknown: unicode('fef4');
$ti-icon-device-unknown-filled: unicode('10018');
$ti-icon-device-usb: unicode('fc59');
$ti-icon-device-usb-filled: unicode('10042');
$ti-icon-device-vision-pro: unicode('fae7');
$ti-icon-device-vision-pro-filled: unicode('10041');
$ti-icon-device-watch: unicode('ebf9');
$ti-icon-device-watch-bolt: unicode('f8d3');
$ti-icon-device-watch-cancel: unicode('f8d4');
$ti-icon-device-watch-check: unicode('f8d5');
$ti-icon-device-watch-code: unicode('f8d6');
$ti-icon-device-watch-cog: unicode('f8d7');
$ti-icon-device-watch-dollar: unicode('f8d8');
$ti-icon-device-watch-down: unicode('f8d9');
$ti-icon-device-watch-exclamation: unicode('f8da');
$ti-icon-device-watch-filled: unicode('10040');
$ti-icon-device-watch-heart: unicode('f8db');
$ti-icon-device-watch-minus: unicode('f8dc');
$ti-icon-device-watch-off: unicode('f065');
$ti-icon-device-watch-pause: unicode('f8dd');
$ti-icon-device-watch-pin: unicode('f8de');
$ti-icon-device-watch-plus: unicode('f8df');
$ti-icon-device-watch-question: unicode('f8e0');
$ti-icon-device-watch-search: unicode('f8e1');
$ti-icon-device-watch-share: unicode('f8e2');
$ti-icon-device-watch-star: unicode('f8e3');
$ti-icon-device-watch-stats: unicode('ef7d');
$ti-icon-device-watch-stats-2: unicode('ef7c');
$ti-icon-device-watch-up: unicode('f8e4');
$ti-icon-device-watch-x: unicode('f8e5');
$ti-icon-devices: unicode('eb87');
$ti-icon-devices-2: unicode('ed29');
$ti-icon-devices-bolt: unicode('f8e6');
$ti-icon-devices-cancel: unicode('f8e7');
$ti-icon-devices-check: unicode('f8e8');
$ti-icon-devices-code: unicode('f8e9');
$ti-icon-devices-cog: unicode('f8ea');
$ti-icon-devices-dollar: unicode('f8eb');
$ti-icon-devices-down: unicode('f8ec');
$ti-icon-devices-exclamation: unicode('f8ed');
$ti-icon-devices-heart: unicode('f8ee');
$ti-icon-devices-minus: unicode('f8ef');
$ti-icon-devices-off: unicode('f3e4');
$ti-icon-devices-pause: unicode('f8f0');
$ti-icon-devices-pc: unicode('ee7a');
$ti-icon-devices-pc-off: unicode('f113');
$ti-icon-devices-pin: unicode('f8f1');
$ti-icon-devices-plus: unicode('f8f2');
$ti-icon-devices-question: unicode('f8f3');
$ti-icon-devices-search: unicode('f8f4');
$ti-icon-devices-share: unicode('f8f5');
$ti-icon-devices-star: unicode('f8f6');
$ti-icon-devices-up: unicode('f8f7');
$ti-icon-devices-x: unicode('f8f8');
$ti-icon-diabolo: unicode('fa9c');
$ti-icon-diabolo-off: unicode('fa9a');
$ti-icon-diabolo-plus: unicode('fa9b');
$ti-icon-dialpad: unicode('f067');
$ti-icon-dialpad-filled: unicode('fa3b');
$ti-icon-dialpad-off: unicode('f114');
$ti-icon-diamond: unicode('eb65');
$ti-icon-diamond-filled: unicode('f73d');
$ti-icon-diamond-off: unicode('f115');
$ti-icon-diamonds: unicode('eff5');
$ti-icon-diamonds-filled: unicode('f676');
$ti-icon-diaper: unicode('ffa2');
$ti-icon-dice: unicode('eb66');
$ti-icon-dice-1: unicode('f08b');
$ti-icon-dice-1-filled: unicode('f73e');
$ti-icon-dice-2: unicode('f08c');
$ti-icon-dice-2-filled: unicode('f73f');
$ti-icon-dice-3: unicode('f08d');
$ti-icon-dice-3-filled: unicode('f740');
$ti-icon-dice-4: unicode('f08e');
$ti-icon-dice-4-filled: unicode('f741');
$ti-icon-dice-5: unicode('f08f');
$ti-icon-dice-5-filled: unicode('f742');
$ti-icon-dice-6: unicode('f090');
$ti-icon-dice-6-filled: unicode('f743');
$ti-icon-dice-filled: unicode('f744');
$ti-icon-dimensions: unicode('ee7b');
$ti-icon-direction: unicode('ebfb');
$ti-icon-direction-arrows: unicode('fd36');
$ti-icon-direction-horizontal: unicode('ebfa');
$ti-icon-direction-sign: unicode('f1f7');
$ti-icon-direction-sign-filled: unicode('f745');
$ti-icon-direction-sign-off: unicode('f3e5');
$ti-icon-directions: unicode('ea8e');
$ti-icon-directions-filled: unicode('1003f');
$ti-icon-directions-off: unicode('f116');
$ti-icon-disabled: unicode('ea8f');
$ti-icon-disabled-2: unicode('ebaf');
$ti-icon-disabled-off: unicode('f117');
$ti-icon-disc: unicode('ea90');
$ti-icon-disc-filled: unicode('1003e');
$ti-icon-disc-golf: unicode('f385');
$ti-icon-disc-off: unicode('f118');
$ti-icon-discount: unicode('ebbd');
$ti-icon-discount-filled: unicode('1003d');
$ti-icon-discount-off: unicode('f3e7');
$ti-icon-divide: unicode('ed5c');
$ti-icon-dna: unicode('ee7d');
$ti-icon-dna-2: unicode('ef5c');
$ti-icon-dna-2-off: unicode('f119');
$ti-icon-dna-off: unicode('f11a');
$ti-icon-dog: unicode('f660');
$ti-icon-dog-bowl: unicode('ef29');
$ti-icon-door: unicode('ef4e');
$ti-icon-door-enter: unicode('ef4c');
$ti-icon-door-exit: unicode('ef4d');
$ti-icon-door-off: unicode('f11b');
$ti-icon-dots: unicode('ea95');
$ti-icon-dots-circle-horizontal: unicode('ea91');
$ti-icon-dots-diagonal: unicode('ea93');
$ti-icon-dots-diagonal-2: unicode('ea92');
$ti-icon-dots-vertical: unicode('ea94');
$ti-icon-download: unicode('ea96');
$ti-icon-download-off: unicode('f11c');
$ti-icon-drag-drop: unicode('eb89');
$ti-icon-drag-drop-2: unicode('eb88');
$ti-icon-drone: unicode('ed79');
$ti-icon-drone-off: unicode('ee7e');
$ti-icon-drop-circle: unicode('efde');
$ti-icon-droplet: unicode('ea97');
$ti-icon-droplet-bolt: unicode('f8f9');
$ti-icon-droplet-cancel: unicode('f8fa');
$ti-icon-droplet-check: unicode('f8fb');
$ti-icon-droplet-code: unicode('f8fc');
$ti-icon-droplet-cog: unicode('f8fd');
$ti-icon-droplet-dollar: unicode('f8fe');
$ti-icon-droplet-down: unicode('f8ff');
$ti-icon-droplet-exclamation: unicode('f900');
$ti-icon-droplet-filled: unicode('ee80');
$ti-icon-droplet-half: unicode('ee82');
$ti-icon-droplet-half-2: unicode('ee81');
$ti-icon-droplet-half-2-filled: unicode('fb6c');
$ti-icon-droplet-half-filled: unicode('f6c5');
$ti-icon-droplet-heart: unicode('f901');
$ti-icon-droplet-minus: unicode('f902');
$ti-icon-droplet-off: unicode('ee83');
$ti-icon-droplet-pause: unicode('f903');
$ti-icon-droplet-pin: unicode('f904');
$ti-icon-droplet-plus: unicode('f905');
$ti-icon-droplet-question: unicode('f906');
$ti-icon-droplet-search: unicode('f907');
$ti-icon-droplet-share: unicode('f908');
$ti-icon-droplet-star: unicode('f909');
$ti-icon-droplet-up: unicode('f90a');
$ti-icon-droplet-x: unicode('f90b');
$ti-icon-droplets: unicode('fc12');
$ti-icon-dual-screen: unicode('fa59');
$ti-icon-dumpling: unicode('feb5');
$ti-icon-e-passport: unicode('f4df');
$ti-icon-ear: unicode('ebce');
$ti-icon-ear-off: unicode('ee84');
$ti-icon-ear-scan: unicode('fd57');
$ti-icon-ease-in: unicode('f573');
$ti-icon-ease-in-control-point: unicode('f570');
$ti-icon-ease-in-out: unicode('f572');
$ti-icon-ease-in-out-control-points: unicode('f571');
$ti-icon-ease-out: unicode('f575');
$ti-icon-ease-out-control-point: unicode('f574');
$ti-icon-edit: unicode('ea98');
$ti-icon-edit-circle: unicode('ee85');
$ti-icon-edit-circle-off: unicode('f11d');
$ti-icon-edit-off: unicode('f11e');
$ti-icon-egg: unicode('eb8a');
$ti-icon-egg-cracked: unicode('f2d6');
$ti-icon-egg-cracked-filled: unicode('10012');
$ti-icon-egg-filled: unicode('f678');
$ti-icon-egg-fried: unicode('f386');
$ti-icon-egg-off: unicode('f11f');
$ti-icon-eggs: unicode('f500');
$ti-icon-elevator: unicode('efdf');
$ti-icon-elevator-filled: unicode('1003c');
$ti-icon-elevator-off: unicode('f3e8');
$ti-icon-emergency-bed: unicode('ef5d');
$ti-icon-empathize: unicode('f29b');
$ti-icon-empathize-off: unicode('f3e9');
$ti-icon-emphasis: unicode('ebcf');
$ti-icon-engine: unicode('ef7e');
$ti-icon-engine-off: unicode('f120');
$ti-icon-equal: unicode('ee87');
$ti-icon-equal-double: unicode('f4e1');
$ti-icon-equal-not: unicode('ee86');
$ti-icon-eraser: unicode('eb8b');
$ti-icon-eraser-off: unicode('f121');
$ti-icon-error-404: unicode('f027');
$ti-icon-error-404-off: unicode('f122');
$ti-icon-escalator: unicode('fb06');
$ti-icon-escalator-down: unicode('fb04');
$ti-icon-escalator-up: unicode('fb05');
$ti-icon-exchange: unicode('ebe7');
$ti-icon-exchange-off: unicode('f123');
$ti-icon-exclamation-circle: unicode('f634');
$ti-icon-exclamation-circle-filled: unicode('ff62');
$ti-icon-exclamation-mark: unicode('efb4');
$ti-icon-exclamation-mark-off: unicode('f124');
$ti-icon-explicit: unicode('f256');
$ti-icon-explicit-off: unicode('f3ea');
$ti-icon-exposure: unicode('eb8c');
$ti-icon-exposure-0: unicode('f29c');
$ti-icon-exposure-minus-1: unicode('f29d');
$ti-icon-exposure-minus-2: unicode('f29e');
$ti-icon-exposure-off: unicode('f3eb');
$ti-icon-exposure-plus-1: unicode('f29f');
$ti-icon-exposure-plus-2: unicode('f2a0');
$ti-icon-external-link: unicode('ea99');
$ti-icon-external-link-off: unicode('f125');
$ti-icon-eye: unicode('ea9a');
$ti-icon-eye-bitcoin: unicode('ff3d');
$ti-icon-eye-bolt: unicode('fb6d');
$ti-icon-eye-cancel: unicode('fb6e');
$ti-icon-eye-check: unicode('ee88');
$ti-icon-eye-closed: unicode('f7ec');
$ti-icon-eye-code: unicode('fb6f');
$ti-icon-eye-cog: unicode('f7ed');
$ti-icon-eye-discount: unicode('fb70');
$ti-icon-eye-dollar: unicode('fb71');
$ti-icon-eye-dotted: unicode('fead');
$ti-icon-eye-down: unicode('fb72');
$ti-icon-eye-edit: unicode('f7ee');
$ti-icon-eye-exclamation: unicode('f7ef');
$ti-icon-eye-filled: unicode('f679');
$ti-icon-eye-heart: unicode('f7f0');
$ti-icon-eye-minus: unicode('fb73');
$ti-icon-eye-off: unicode('ecf0');
$ti-icon-eye-pause: unicode('fb74');
$ti-icon-eye-pin: unicode('fb75');
$ti-icon-eye-plus: unicode('fb76');
$ti-icon-eye-question: unicode('fb77');
$ti-icon-eye-search: unicode('fb78');
$ti-icon-eye-share: unicode('fb79');
$ti-icon-eye-spark: unicode('ffba');
$ti-icon-eye-star: unicode('fb7a');
$ti-icon-eye-table: unicode('ef5e');
$ti-icon-eye-up: unicode('fb7b');
$ti-icon-eye-x: unicode('f7f1');
$ti-icon-eyeglass: unicode('ee8a');
$ti-icon-eyeglass-2: unicode('ee89');
$ti-icon-eyeglass-off: unicode('f126');
$ti-icon-face-id: unicode('ea9b');
$ti-icon-face-id-error: unicode('efa7');
$ti-icon-face-mask: unicode('efb5');
$ti-icon-face-mask-off: unicode('f127');
$ti-icon-fall: unicode('ecb9');
$ti-icon-favicon: unicode('fd65');
$ti-icon-favicon-filled: unicode('10071');
$ti-icon-feather: unicode('ee8b');
$ti-icon-feather-filled: unicode('10011');
$ti-icon-feather-off: unicode('f128');
$ti-icon-fence: unicode('ef2a');
$ti-icon-fence-off: unicode('f129');
$ti-icon-ferry: unicode('10074');
$ti-icon-fidget-spinner: unicode('f068');
$ti-icon-file: unicode('eaa4');
$ti-icon-file-3d: unicode('f032');
$ti-icon-file-ai: unicode('ffa1');
$ti-icon-file-alert: unicode('ede6');
$ti-icon-file-analytics: unicode('ede7');
$ti-icon-file-arrow-left: unicode('f033');
$ti-icon-file-arrow-right: unicode('f034');
$ti-icon-file-barcode: unicode('f035');
$ti-icon-file-bitcoin: unicode('ffa0');
$ti-icon-file-broken: unicode('f501');
$ti-icon-file-certificate: unicode('ed4d');
$ti-icon-file-chart: unicode('f036');
$ti-icon-file-check: unicode('ea9c');
$ti-icon-file-code: unicode('ebd0');
$ti-icon-file-code-2: unicode('ede8');
$ti-icon-file-cv: unicode('fa5a');
$ti-icon-file-database: unicode('f037');
$ti-icon-file-delta: unicode('f53d');
$ti-icon-file-description: unicode('f028');
$ti-icon-file-diff: unicode('ecf1');
$ti-icon-file-digit: unicode('efa8');
$ti-icon-file-dislike: unicode('ed2a');
$ti-icon-file-dollar: unicode('efe0');
$ti-icon-file-dots: unicode('f038');
$ti-icon-file-download: unicode('ea9d');
$ti-icon-file-euro: unicode('efe1');
$ti-icon-file-excel: unicode('fef3');
$ti-icon-file-export: unicode('ede9');
$ti-icon-file-filled: unicode('f747');
$ti-icon-file-function: unicode('f53e');
$ti-icon-file-horizontal: unicode('ebb0');
$ti-icon-file-import: unicode('edea');
$ti-icon-file-infinity: unicode('f502');
$ti-icon-file-info: unicode('edec');
$ti-icon-file-invoice: unicode('eb67');
$ti-icon-file-isr: unicode('feac');
$ti-icon-file-lambda: unicode('f53f');
$ti-icon-file-like: unicode('ed2b');
$ti-icon-file-minus: unicode('ea9e');
$ti-icon-file-music: unicode('ea9f');
$ti-icon-file-neutral: unicode('fd22');
$ti-icon-file-off: unicode('ecf2');
$ti-icon-file-orientation: unicode('f2a1');
$ti-icon-file-pencil: unicode('f039');
$ti-icon-file-percent: unicode('f540');
$ti-icon-file-phone: unicode('ecdc');
$ti-icon-file-plus: unicode('eaa0');
$ti-icon-file-power: unicode('f03a');
$ti-icon-file-report: unicode('eded');
$ti-icon-file-rss: unicode('f03b');
$ti-icon-file-sad: unicode('fd23');
$ti-icon-file-scissors: unicode('f03c');
$ti-icon-file-search: unicode('ed5d');
$ti-icon-file-settings: unicode('f029');
$ti-icon-file-shredder: unicode('eaa1');
$ti-icon-file-signal: unicode('f03d');
$ti-icon-file-smile: unicode('fd24');
$ti-icon-file-spark: unicode('ffb9');
$ti-icon-file-spreadsheet: unicode('f03e');
$ti-icon-file-stack: unicode('f503');
$ti-icon-file-star: unicode('f03f');
$ti-icon-file-symlink: unicode('ed53');
$ti-icon-file-text: unicode('eaa2');
$ti-icon-file-text-ai: unicode('fa27');
$ti-icon-file-text-spark: unicode('ffb8');
$ti-icon-file-time: unicode('f040');
$ti-icon-file-type-bmp: unicode('fb07');
$ti-icon-file-type-css: unicode('fb08');
$ti-icon-file-type-csv: unicode('fb09');
$ti-icon-file-type-doc: unicode('fb0a');
$ti-icon-file-type-docx: unicode('fb0b');
$ti-icon-file-type-html: unicode('fb0c');
$ti-icon-file-type-jpg: unicode('fb0d');
$ti-icon-file-type-js: unicode('fb0e');
$ti-icon-file-type-jsx: unicode('fb0f');
$ti-icon-file-type-pdf: unicode('fb10');
$ti-icon-file-type-php: unicode('fb11');
$ti-icon-file-type-png: unicode('fb12');
$ti-icon-file-type-ppt: unicode('fb13');
$ti-icon-file-type-rs: unicode('fb14');
$ti-icon-file-type-sql: unicode('fb15');
$ti-icon-file-type-svg: unicode('fb16');
$ti-icon-file-type-ts: unicode('fb17');
$ti-icon-file-type-tsx: unicode('fb18');
$ti-icon-file-type-txt: unicode('fb19');
$ti-icon-file-type-vue: unicode('fb1a');
$ti-icon-file-type-xls: unicode('fb1b');
$ti-icon-file-type-xml: unicode('fb1c');
$ti-icon-file-type-zip: unicode('fb1d');
$ti-icon-file-typography: unicode('f041');
$ti-icon-file-unknown: unicode('f042');
$ti-icon-file-upload: unicode('ec91');
$ti-icon-file-vector: unicode('f043');
$ti-icon-file-word: unicode('fef2');
$ti-icon-file-x: unicode('eaa3');
$ti-icon-file-x-filled: unicode('f748');
$ti-icon-file-zip: unicode('ed4e');
$ti-icon-files: unicode('edef');
$ti-icon-files-off: unicode('edee');
$ti-icon-filter: unicode('eaa5');
$ti-icon-filter-bolt: unicode('fb7c');
$ti-icon-filter-cancel: unicode('fb7d');
$ti-icon-filter-check: unicode('fb7e');
$ti-icon-filter-code: unicode('fb7f');
$ti-icon-filter-cog: unicode('f9fe');
$ti-icon-filter-discount: unicode('fb80');
$ti-icon-filter-dollar: unicode('f9ff');
$ti-icon-filter-down: unicode('fb81');
$ti-icon-filter-edit: unicode('fa00');
$ti-icon-filter-exclamation: unicode('fb82');
$ti-icon-filter-filled: unicode('fc27');
$ti-icon-filter-heart: unicode('fb83');
$ti-icon-filter-minus: unicode('fa01');
$ti-icon-filter-off: unicode('ed2c');
$ti-icon-filter-pause: unicode('fb84');
$ti-icon-filter-pin: unicode('fb85');
$ti-icon-filter-plus: unicode('fa02');
$ti-icon-filter-question: unicode('fb86');
$ti-icon-filter-search: unicode('fb87');
$ti-icon-filter-share: unicode('fb88');
$ti-icon-filter-star: unicode('fa03');
$ti-icon-filter-up: unicode('fb89');
$ti-icon-filter-x: unicode('fa04');
$ti-icon-filters: unicode('f793');
$ti-icon-fingerprint: unicode('ebd1');
$ti-icon-fingerprint-off: unicode('f12a');
$ti-icon-fingerprint-scan: unicode('fcb5');
$ti-icon-fire-extinguisher: unicode('faf6');
$ti-icon-fire-hydrant: unicode('f3a9');
$ti-icon-fire-hydrant-off: unicode('f3ec');
$ti-icon-firetruck: unicode('ebe8');
$ti-icon-first-aid-kit: unicode('ef5f');
$ti-icon-first-aid-kit-off: unicode('f3ed');
$ti-icon-fish: unicode('ef2b');
$ti-icon-fish-bone: unicode('f287');
$ti-icon-fish-christianity: unicode('f58b');
$ti-icon-fish-hook: unicode('f1f9');
$ti-icon-fish-hook-off: unicode('f3ee');
$ti-icon-fish-off: unicode('f12b');
$ti-icon-flag: unicode('eaa6');
$ti-icon-flag-2: unicode('ee8c');
$ti-icon-flag-2-filled: unicode('f707');
$ti-icon-flag-2-off: unicode('f12c');
$ti-icon-flag-3: unicode('ee8d');
$ti-icon-flag-3-filled: unicode('f708');
$ti-icon-flag-bitcoin: unicode('ff3c');
$ti-icon-flag-bolt: unicode('fb8a');
$ti-icon-flag-cancel: unicode('fb8b');
$ti-icon-flag-check: unicode('fb8c');
$ti-icon-flag-code: unicode('fb8d');
$ti-icon-flag-cog: unicode('fb8e');
$ti-icon-flag-discount: unicode('fb8f');
$ti-icon-flag-dollar: unicode('fb90');
$ti-icon-flag-down: unicode('fb91');
$ti-icon-flag-exclamation: unicode('fb92');
$ti-icon-flag-filled: unicode('f67a');
$ti-icon-flag-heart: unicode('fb93');
$ti-icon-flag-minus: unicode('fb94');
$ti-icon-flag-off: unicode('f12d');
$ti-icon-flag-pause: unicode('fb95');
$ti-icon-flag-pin: unicode('fb96');
$ti-icon-flag-plus: unicode('fb97');
$ti-icon-flag-question: unicode('fb98');
$ti-icon-flag-search: unicode('fb99');
$ti-icon-flag-share: unicode('fb9a');
$ti-icon-flag-spark: unicode('ffb7');
$ti-icon-flag-star: unicode('fb9b');
$ti-icon-flag-up: unicode('fb9c');
$ti-icon-flag-x: unicode('fb9d');
$ti-icon-flame: unicode('ec2c');
$ti-icon-flame-off: unicode('f12e');
$ti-icon-flare: unicode('ee8e');
$ti-icon-flask: unicode('ebd2');
$ti-icon-flask-2: unicode('ef60');
$ti-icon-flask-2-filled: unicode('fd12');
$ti-icon-flask-2-off: unicode('f12f');
$ti-icon-flask-filled: unicode('fd13');
$ti-icon-flask-off: unicode('f130');
$ti-icon-flip-flops: unicode('f564');
$ti-icon-flip-horizontal: unicode('eaa7');
$ti-icon-flip-vertical: unicode('eaa8');
$ti-icon-float-center: unicode('ebb1');
$ti-icon-float-left: unicode('ebb2');
$ti-icon-float-none: unicode('ed13');
$ti-icon-float-right: unicode('ebb3');
$ti-icon-flower: unicode('eff6');
$ti-icon-flower-filled: unicode('10010');
$ti-icon-flower-off: unicode('f131');
$ti-icon-focus: unicode('eb8d');
$ti-icon-focus-2: unicode('ebd3');
$ti-icon-focus-auto: unicode('fa62');
$ti-icon-focus-centered: unicode('f02a');
$ti-icon-fold: unicode('ed56');
$ti-icon-fold-down: unicode('ed54');
$ti-icon-fold-up: unicode('ed55');
$ti-icon-folder: unicode('eaad');
$ti-icon-folder-bolt: unicode('f90c');
$ti-icon-folder-cancel: unicode('f90d');
$ti-icon-folder-check: unicode('f90e');
$ti-icon-folder-code: unicode('f90f');
$ti-icon-folder-cog: unicode('f910');
$ti-icon-folder-dollar: unicode('f911');
$ti-icon-folder-down: unicode('f912');
$ti-icon-folder-exclamation: unicode('f913');
$ti-icon-folder-filled: unicode('f749');
$ti-icon-folder-heart: unicode('f914');
$ti-icon-folder-minus: unicode('eaaa');
$ti-icon-folder-off: unicode('ed14');
$ti-icon-folder-open: unicode('faf7');
$ti-icon-folder-pause: unicode('f915');
$ti-icon-folder-pin: unicode('f916');
$ti-icon-folder-plus: unicode('eaab');
$ti-icon-folder-question: unicode('f917');
$ti-icon-folder-root: unicode('fd43');
$ti-icon-folder-search: unicode('f918');
$ti-icon-folder-share: unicode('f919');
$ti-icon-folder-star: unicode('f91a');
$ti-icon-folder-symlink: unicode('f91b');
$ti-icon-folder-up: unicode('f91c');
$ti-icon-folder-x: unicode('eaac');
$ti-icon-folders: unicode('eaae');
$ti-icon-folders-off: unicode('f133');
$ti-icon-forbid: unicode('ebd5');
$ti-icon-forbid-2: unicode('ebd4');
$ti-icon-forbid-2-filled: unicode('fc28');
$ti-icon-forbid-filled: unicode('fc29');
$ti-icon-forklift: unicode('ebe9');
$ti-icon-forms: unicode('ee8f');
$ti-icon-fountain: unicode('f09b');
$ti-icon-fountain-filled: unicode('fc2a');
$ti-icon-fountain-off: unicode('f134');
$ti-icon-frame: unicode('eaaf');
$ti-icon-frame-off: unicode('f135');
$ti-icon-free-rights: unicode('efb6');
$ti-icon-freeze-column: unicode('fa63');
$ti-icon-freeze-row: unicode('fa65');
$ti-icon-freeze-row-column: unicode('fa64');
$ti-icon-fridge: unicode('f1fa');
$ti-icon-fridge-off: unicode('f3ef');
$ti-icon-friends: unicode('eab0');
$ti-icon-friends-off: unicode('f136');
$ti-icon-frustum: unicode('fa9f');
$ti-icon-frustum-off: unicode('fa9d');
$ti-icon-frustum-plus: unicode('fa9e');
$ti-icon-function: unicode('f225');
$ti-icon-function-filled: unicode('fc2b');
$ti-icon-function-off: unicode('f3f0');
$ti-icon-galaxy: unicode('fcb6');
$ti-icon-garden-cart: unicode('f23e');
$ti-icon-garden-cart-off: unicode('f3f1');
$ti-icon-gas-station: unicode('ec7d');
$ti-icon-gas-station-off: unicode('f137');
$ti-icon-gauge: unicode('eab1');
$ti-icon-gauge-filled: unicode('fc2c');
$ti-icon-gauge-off: unicode('f138');
$ti-icon-gavel: unicode('ef90');
$ti-icon-gender-agender: unicode('f0e1');
$ti-icon-gender-androgyne: unicode('f0e2');
$ti-icon-gender-bigender: unicode('f0e3');
$ti-icon-gender-demiboy: unicode('f0e4');
$ti-icon-gender-demigirl: unicode('f0e5');
$ti-icon-gender-epicene: unicode('f0e6');
$ti-icon-gender-female: unicode('f0e7');
$ti-icon-gender-femme: unicode('f0e8');
$ti-icon-gender-genderfluid: unicode('f0e9');
$ti-icon-gender-genderless: unicode('f0ea');
$ti-icon-gender-genderqueer: unicode('f0eb');
$ti-icon-gender-hermaphrodite: unicode('f0ec');
$ti-icon-gender-intergender: unicode('f0ed');
$ti-icon-gender-male: unicode('f0ee');
$ti-icon-gender-neutrois: unicode('f0ef');
$ti-icon-gender-third: unicode('f0f0');
$ti-icon-gender-transgender: unicode('f0f1');
$ti-icon-gender-trasvesti: unicode('f0f2');
$ti-icon-geometry: unicode('ee90');
$ti-icon-ghost: unicode('eb8e');
$ti-icon-ghost-2: unicode('f57c');
$ti-icon-ghost-2-filled: unicode('f74a');
$ti-icon-ghost-3: unicode('fc13');
$ti-icon-ghost-filled: unicode('f74b');
$ti-icon-ghost-off: unicode('f3f2');
$ti-icon-gif: unicode('f257');
$ti-icon-gift: unicode('eb68');
$ti-icon-gift-card: unicode('f3aa');
$ti-icon-gift-card-filled: unicode('fc2d');
$ti-icon-gift-filled: unicode('fd14');
$ti-icon-gift-off: unicode('f3f3');
$ti-icon-git-branch: unicode('eab2');
$ti-icon-git-branch-deleted: unicode('f57d');
$ti-icon-git-cherry-pick: unicode('f57e');
$ti-icon-git-commit: unicode('eab3');
$ti-icon-git-compare: unicode('eab4');
$ti-icon-git-fork: unicode('eb8f');
$ti-icon-git-merge: unicode('eab5');
$ti-icon-git-pull-request: unicode('eab6');
$ti-icon-git-pull-request-closed: unicode('ef7f');
$ti-icon-git-pull-request-draft: unicode('efb7');
$ti-icon-gizmo: unicode('f02b');
$ti-icon-glass: unicode('eab8');
$ti-icon-glass-champagne: unicode('fd9c');
$ti-icon-glass-cocktail: unicode('fd9d');
$ti-icon-glass-filled: unicode('1000f');
$ti-icon-glass-full: unicode('eab7');
$ti-icon-glass-full-filled: unicode('fc2e');
$ti-icon-glass-gin: unicode('fd9e');
$ti-icon-glass-off: unicode('ee91');
$ti-icon-globe: unicode('eab9');
$ti-icon-globe-filled: unicode('fc2f');
$ti-icon-globe-off: unicode('f139');
$ti-icon-go-game: unicode('f512');
$ti-icon-golf: unicode('ed8c');
$ti-icon-golf-off: unicode('f13a');
$ti-icon-gps: unicode('ed7a');
$ti-icon-gps-filled: unicode('fe48');
$ti-icon-gradienter: unicode('f3ab');
$ti-icon-grain: unicode('ee92');
$ti-icon-graph: unicode('f288');
$ti-icon-graph-filled: unicode('fd15');
$ti-icon-graph-off: unicode('f3f4');
$ti-icon-grave: unicode('f580');
$ti-icon-grave-2: unicode('f57f');
$ti-icon-grid-3x3: unicode('fca4');
$ti-icon-grid-4x4: unicode('fca5');
$ti-icon-grid-dots: unicode('eaba');
$ti-icon-grid-goldenratio: unicode('fca6');
$ti-icon-grid-pattern: unicode('efc9');
$ti-icon-grid-scan: unicode('fca7');
$ti-icon-grill: unicode('efa9');
$ti-icon-grill-fork: unicode('f35b');
$ti-icon-grill-off: unicode('f3f5');
$ti-icon-grill-spatula: unicode('f35c');
$ti-icon-grip-horizontal: unicode('ec00');
$ti-icon-grip-vertical: unicode('ec01');
$ti-icon-growth: unicode('ee93');
$ti-icon-guitar-pick: unicode('f4c6');
$ti-icon-guitar-pick-filled: unicode('f67b');
$ti-icon-gymnastics: unicode('fd44');
$ti-icon-h-1: unicode('ec94');
$ti-icon-h-2: unicode('ec95');
$ti-icon-h-3: unicode('ec96');
$ti-icon-h-4: unicode('ec97');
$ti-icon-h-5: unicode('ec98');
$ti-icon-h-6: unicode('ec99');
$ti-icon-hammer: unicode('ef91');
$ti-icon-hammer-off: unicode('f13c');
$ti-icon-hand-click: unicode('ef4f');
$ti-icon-hand-finger: unicode('ee94');
$ti-icon-hand-finger-down: unicode('ff4f');
$ti-icon-hand-finger-left: unicode('ff4e');
$ti-icon-hand-finger-off: unicode('f13d');
$ti-icon-hand-finger-right: unicode('ff4d');
$ti-icon-hand-grab: unicode('f091');
$ti-icon-hand-little-finger: unicode('ee95');
$ti-icon-hand-love-you: unicode('ee97');
$ti-icon-hand-middle-finger: unicode('ec2d');
$ti-icon-hand-move: unicode('ef50');
$ti-icon-hand-off: unicode('ed15');
$ti-icon-hand-ring-finger: unicode('ee96');
$ti-icon-hand-sanitizer: unicode('f5f4');
$ti-icon-hand-stop: unicode('ec2e');
$ti-icon-hand-three-fingers: unicode('ee98');
$ti-icon-hand-two-fingers: unicode('ee99');
$ti-icon-hanger: unicode('ee9a');
$ti-icon-hanger-2: unicode('f09c');
$ti-icon-hanger-2-filled: unicode('ff61');
$ti-icon-hanger-off: unicode('f13e');
$ti-icon-hash: unicode('eabc');
$ti-icon-haze: unicode('efaa');
$ti-icon-haze-moon: unicode('faf8');
$ti-icon-hdr: unicode('fa7b');
$ti-icon-heading: unicode('ee9b');
$ti-icon-heading-off: unicode('f13f');
$ti-icon-headphones: unicode('eabd');
$ti-icon-headphones-filled: unicode('fa3c');
$ti-icon-headphones-off: unicode('ed1d');
$ti-icon-headset: unicode('eb90');
$ti-icon-headset-off: unicode('f3f6');
$ti-icon-health-recognition: unicode('f1fb');
$ti-icon-heart: unicode('eabe');
$ti-icon-heart-bitcoin: unicode('ff3b');
$ti-icon-heart-bolt: unicode('fb9e');
$ti-icon-heart-broken: unicode('ecba');
$ti-icon-heart-cancel: unicode('fb9f');
$ti-icon-heart-check: unicode('fba0');
$ti-icon-heart-code: unicode('fba1');
$ti-icon-heart-cog: unicode('fba2');
$ti-icon-heart-discount: unicode('fba3');
$ti-icon-heart-dollar: unicode('fba4');
$ti-icon-heart-down: unicode('fba5');
$ti-icon-heart-exclamation: unicode('fba6');
$ti-icon-heart-filled: unicode('f67c');
$ti-icon-heart-handshake: unicode('f0f3');
$ti-icon-heart-minus: unicode('f140');
$ti-icon-heart-off: unicode('f141');
$ti-icon-heart-pause: unicode('fba7');
$ti-icon-heart-pin: unicode('fba8');
$ti-icon-heart-plus: unicode('f142');
$ti-icon-heart-question: unicode('fba9');
$ti-icon-heart-rate-monitor: unicode('ef61');
$ti-icon-heart-search: unicode('fbaa');
$ti-icon-heart-share: unicode('fbab');
$ti-icon-heart-spark: unicode('ffb6');
$ti-icon-heart-star: unicode('fbac');
$ti-icon-heart-up: unicode('fbad');
$ti-icon-heart-x: unicode('fbae');
$ti-icon-heartbeat: unicode('ef92');
$ti-icon-hearts: unicode('f387');
$ti-icon-hearts-off: unicode('f3f7');
$ti-icon-helicopter: unicode('ed8e');
$ti-icon-helicopter-landing: unicode('ed8d');
$ti-icon-helmet: unicode('efca');
$ti-icon-helmet-off: unicode('f143');
$ti-icon-help: unicode('eabf');
$ti-icon-help-circle: unicode('f91d');
$ti-icon-help-circle-filled: unicode('fa3d');
$ti-icon-help-hexagon: unicode('f7a8');
$ti-icon-help-hexagon-filled: unicode('fa3e');
$ti-icon-help-octagon: unicode('f7a9');
$ti-icon-help-octagon-filled: unicode('fa3f');
$ti-icon-help-off: unicode('f3f8');
$ti-icon-help-small: unicode('f91e');
$ti-icon-help-square: unicode('f920');
$ti-icon-help-square-filled: unicode('fa40');
$ti-icon-help-square-rounded: unicode('f91f');
$ti-icon-help-square-rounded-filled: unicode('fa41');
$ti-icon-help-triangle: unicode('f921');
$ti-icon-help-triangle-filled: unicode('fa42');
$ti-icon-hemisphere: unicode('faa2');
$ti-icon-hemisphere-off: unicode('faa0');
$ti-icon-hemisphere-plus: unicode('faa1');
$ti-icon-hexagon: unicode('ec02');
$ti-icon-hexagon-3d: unicode('f4c7');
$ti-icon-hexagon-filled: unicode('f67d');
$ti-icon-hexagon-letter-a: unicode('f463');
$ti-icon-hexagon-letter-a-filled: unicode('fe47');
$ti-icon-hexagon-letter-b: unicode('f464');
$ti-icon-hexagon-letter-b-filled: unicode('fe46');
$ti-icon-hexagon-letter-c: unicode('f465');
$ti-icon-hexagon-letter-c-filled: unicode('fe45');
$ti-icon-hexagon-letter-d: unicode('f466');
$ti-icon-hexagon-letter-d-filled: unicode('fe44');
$ti-icon-hexagon-letter-e: unicode('f467');
$ti-icon-hexagon-letter-e-filled: unicode('fe43');
$ti-icon-hexagon-letter-f: unicode('f468');
$ti-icon-hexagon-letter-f-filled: unicode('fe42');
$ti-icon-hexagon-letter-g: unicode('f469');
$ti-icon-hexagon-letter-g-filled: unicode('fe41');
$ti-icon-hexagon-letter-h: unicode('f46a');
$ti-icon-hexagon-letter-h-filled: unicode('fe40');
$ti-icon-hexagon-letter-i: unicode('f46b');
$ti-icon-hexagon-letter-i-filled: unicode('fe3f');
$ti-icon-hexagon-letter-j: unicode('f46c');
$ti-icon-hexagon-letter-j-filled: unicode('fe3e');
$ti-icon-hexagon-letter-k: unicode('f46d');
$ti-icon-hexagon-letter-k-filled: unicode('fe3d');
$ti-icon-hexagon-letter-l: unicode('f46e');
$ti-icon-hexagon-letter-l-filled: unicode('fe3c');
$ti-icon-hexagon-letter-m: unicode('f46f');
$ti-icon-hexagon-letter-m-filled: unicode('fe3b');
$ti-icon-hexagon-letter-n: unicode('f470');
$ti-icon-hexagon-letter-n-filled: unicode('fe3a');
$ti-icon-hexagon-letter-o: unicode('f471');
$ti-icon-hexagon-letter-o-filled: unicode('fe39');
$ti-icon-hexagon-letter-p: unicode('f472');
$ti-icon-hexagon-letter-p-filled: unicode('fe38');
$ti-icon-hexagon-letter-q: unicode('f473');
$ti-icon-hexagon-letter-q-filled: unicode('fe37');
$ti-icon-hexagon-letter-r: unicode('f474');
$ti-icon-hexagon-letter-r-filled: unicode('fe36');
$ti-icon-hexagon-letter-s: unicode('f475');
$ti-icon-hexagon-letter-s-filled: unicode('fe35');
$ti-icon-hexagon-letter-t: unicode('f476');
$ti-icon-hexagon-letter-t-filled: unicode('fe34');
$ti-icon-hexagon-letter-u: unicode('f477');
$ti-icon-hexagon-letter-u-filled: unicode('fe33');
$ti-icon-hexagon-letter-v: unicode('f4b3');
$ti-icon-hexagon-letter-v-filled: unicode('fe32');
$ti-icon-hexagon-letter-w: unicode('f478');
$ti-icon-hexagon-letter-w-filled: unicode('fe31');
$ti-icon-hexagon-letter-x: unicode('f479');
$ti-icon-hexagon-letter-x-filled: unicode('fe30');
$ti-icon-hexagon-letter-y: unicode('f47a');
$ti-icon-hexagon-letter-y-filled: unicode('fe2f');
$ti-icon-hexagon-letter-z: unicode('f47b');
$ti-icon-hexagon-letter-z-filled: unicode('fe2e');
$ti-icon-hexagon-minus: unicode('fc8f');
$ti-icon-hexagon-minus-2: unicode('fc8e');
$ti-icon-hexagon-minus-filled: unicode('fe2d');
$ti-icon-hexagon-number-0: unicode('f459');
$ti-icon-hexagon-number-0-filled: unicode('f74c');
$ti-icon-hexagon-number-1: unicode('f45a');
$ti-icon-hexagon-number-1-filled: unicode('f74d');
$ti-icon-hexagon-number-2: unicode('f45b');
$ti-icon-hexagon-number-2-filled: unicode('f74e');
$ti-icon-hexagon-number-3: unicode('f45c');
$ti-icon-hexagon-number-3-filled: unicode('f74f');
$ti-icon-hexagon-number-4: unicode('f45d');
$ti-icon-hexagon-number-4-filled: unicode('f750');
$ti-icon-hexagon-number-5: unicode('f45e');
$ti-icon-hexagon-number-5-filled: unicode('f751');
$ti-icon-hexagon-number-6: unicode('f45f');
$ti-icon-hexagon-number-6-filled: unicode('f752');
$ti-icon-hexagon-number-7: unicode('f460');
$ti-icon-hexagon-number-7-filled: unicode('f753');
$ti-icon-hexagon-number-8: unicode('f461');
$ti-icon-hexagon-number-8-filled: unicode('f754');
$ti-icon-hexagon-number-9: unicode('f462');
$ti-icon-hexagon-number-9-filled: unicode('f755');
$ti-icon-hexagon-off: unicode('ee9c');
$ti-icon-hexagon-plus: unicode('fc45');
$ti-icon-hexagon-plus-2: unicode('fc90');
$ti-icon-hexagon-plus-filled: unicode('fe2c');
$ti-icon-hexagonal-prism: unicode('faa5');
$ti-icon-hexagonal-prism-off: unicode('faa3');
$ti-icon-hexagonal-prism-plus: unicode('faa4');
$ti-icon-hexagonal-pyramid: unicode('faa8');
$ti-icon-hexagonal-pyramid-off: unicode('faa6');
$ti-icon-hexagonal-pyramid-plus: unicode('faa7');
$ti-icon-hexagons: unicode('f09d');
$ti-icon-hexagons-off: unicode('f3f9');
$ti-icon-hierarchy: unicode('ee9e');
$ti-icon-hierarchy-2: unicode('ee9d');
$ti-icon-hierarchy-3: unicode('f289');
$ti-icon-hierarchy-off: unicode('f3fa');
$ti-icon-highlight: unicode('ef3f');
$ti-icon-highlight-off: unicode('f144');
$ti-icon-history: unicode('ebea');
$ti-icon-history-off: unicode('f3fb');
$ti-icon-history-toggle: unicode('f1fc');
$ti-icon-home: unicode('eac1');
$ti-icon-home-2: unicode('eac0');
$ti-icon-home-bitcoin: unicode('ff3a');
$ti-icon-home-bolt: unicode('f336');
$ti-icon-home-cancel: unicode('f350');
$ti-icon-home-check: unicode('f337');
$ti-icon-home-cog: unicode('f338');
$ti-icon-home-dollar: unicode('f339');
$ti-icon-home-dot: unicode('f33a');
$ti-icon-home-down: unicode('f33b');
$ti-icon-home-eco: unicode('f351');
$ti-icon-home-edit: unicode('f352');
$ti-icon-home-exclamation: unicode('f33c');
$ti-icon-home-filled: unicode('fe2b');
$ti-icon-home-hand: unicode('f504');
$ti-icon-home-heart: unicode('f353');
$ti-icon-home-infinity: unicode('f505');
$ti-icon-home-link: unicode('f354');
$ti-icon-home-minus: unicode('f33d');
$ti-icon-home-move: unicode('f33e');
$ti-icon-home-off: unicode('f145');
$ti-icon-home-plus: unicode('f33f');
$ti-icon-home-question: unicode('f340');
$ti-icon-home-ribbon: unicode('f355');
$ti-icon-home-search: unicode('f341');
$ti-icon-home-share: unicode('f342');
$ti-icon-home-shield: unicode('f343');
$ti-icon-home-signal: unicode('f356');
$ti-icon-home-spark: unicode('ffb5');
$ti-icon-home-star: unicode('f344');
$ti-icon-home-stats: unicode('f345');
$ti-icon-home-up: unicode('f346');
$ti-icon-home-x: unicode('f347');
$ti-icon-horse: unicode('fc46');
$ti-icon-horse-toy: unicode('f28a');
$ti-icon-horseshoe: unicode('fcb7');
$ti-icon-hospital: unicode('fd59');
$ti-icon-hospital-circle: unicode('fd58');
$ti-icon-hospital-circle-filled: unicode('fed2');
$ti-icon-hotel-service: unicode('ef80');
$ti-icon-hourglass: unicode('ef93');
$ti-icon-hourglass-empty: unicode('f146');
$ti-icon-hourglass-filled: unicode('f756');
$ti-icon-hourglass-high: unicode('f092');
$ti-icon-hourglass-low: unicode('f093');
$ti-icon-hourglass-off: unicode('f147');
$ti-icon-hours-12: unicode('fc53');
$ti-icon-hours-24: unicode('f5e7');
$ti-icon-html: unicode('f7b1');
$ti-icon-http-connect: unicode('fa28');
$ti-icon-http-delete: unicode('fa29');
$ti-icon-http-get: unicode('fa2a');
$ti-icon-http-head: unicode('fa2b');
$ti-icon-http-options: unicode('fa2c');
$ti-icon-http-patch: unicode('fa2d');
$ti-icon-http-post: unicode('fa2e');
$ti-icon-http-put: unicode('fa2f');
$ti-icon-http-que: unicode('fa5b');
$ti-icon-http-trace: unicode('fa30');
$ti-icon-ice-cream: unicode('eac2');
$ti-icon-ice-cream-2: unicode('ee9f');
$ti-icon-ice-cream-off: unicode('f148');
$ti-icon-ice-skating: unicode('efcb');
$ti-icon-icons: unicode('f1d4');
$ti-icon-icons-filled: unicode('10070');
$ti-icon-icons-off: unicode('f3fc');
$ti-icon-id: unicode('eac3');
$ti-icon-id-badge: unicode('eff7');
$ti-icon-id-badge-2: unicode('f076');
$ti-icon-id-badge-off: unicode('f3fd');
$ti-icon-id-off: unicode('f149');
$ti-icon-ikosaedr: unicode('fec6');
$ti-icon-image-in-picture: unicode('fd9f');
$ti-icon-inbox: unicode('eac4');
$ti-icon-inbox-off: unicode('f14a');
$ti-icon-indent-decrease: unicode('eb91');
$ti-icon-indent-increase: unicode('eb92');
$ti-icon-infinity: unicode('eb69');
$ti-icon-infinity-off: unicode('f3fe');
$ti-icon-info-circle: unicode('eac5');
$ti-icon-info-circle-filled: unicode('f6d8');
$ti-icon-info-hexagon: unicode('f7aa');
$ti-icon-info-hexagon-filled: unicode('fa43');
$ti-icon-info-octagon: unicode('f7ab');
$ti-icon-info-octagon-filled: unicode('fa44');
$ti-icon-info-small: unicode('f922');
$ti-icon-info-square: unicode('eac6');
$ti-icon-info-square-filled: unicode('fa45');
$ti-icon-info-square-rounded: unicode('f635');
$ti-icon-info-square-rounded-filled: unicode('f6d9');
$ti-icon-info-triangle: unicode('f923');
$ti-icon-info-triangle-filled: unicode('fa46');
$ti-icon-inner-shadow-bottom: unicode('f520');
$ti-icon-inner-shadow-bottom-filled: unicode('f757');
$ti-icon-inner-shadow-bottom-left: unicode('f51e');
$ti-icon-inner-shadow-bottom-left-filled: unicode('f758');
$ti-icon-inner-shadow-bottom-right: unicode('f51f');
$ti-icon-inner-shadow-bottom-right-filled: unicode('f759');
$ti-icon-inner-shadow-left: unicode('f521');
$ti-icon-inner-shadow-left-filled: unicode('f75a');
$ti-icon-inner-shadow-right: unicode('f522');
$ti-icon-inner-shadow-right-filled: unicode('f75b');
$ti-icon-inner-shadow-top: unicode('f525');
$ti-icon-inner-shadow-top-filled: unicode('f75c');
$ti-icon-inner-shadow-top-left: unicode('f523');
$ti-icon-inner-shadow-top-left-filled: unicode('f75d');
$ti-icon-inner-shadow-top-right: unicode('f524');
$ti-icon-inner-shadow-top-right-filled: unicode('f75e');
$ti-icon-input-ai: unicode('fc5a');
$ti-icon-input-check: unicode('fc5b');
$ti-icon-input-search: unicode('f2a2');
$ti-icon-input-spark: unicode('ffb4');
$ti-icon-input-x: unicode('fc5c');
$ti-icon-invoice: unicode('feab');
$ti-icon-ironing: unicode('fa7c');
$ti-icon-ironing-1: unicode('f2f4');
$ti-icon-ironing-1-filled: unicode('1006f');
$ti-icon-ironing-2: unicode('f2f5');
$ti-icon-ironing-2-filled: unicode('1006e');
$ti-icon-ironing-3: unicode('f2f6');
$ti-icon-ironing-3-filled: unicode('1006d');
$ti-icon-ironing-filled: unicode('fe2a');
$ti-icon-ironing-off: unicode('f2f7');
$ti-icon-ironing-steam: unicode('f2f9');
$ti-icon-ironing-steam-filled: unicode('1006c');
$ti-icon-ironing-steam-off: unicode('f2f8');
$ti-icon-irregular-polyhedron: unicode('faab');
$ti-icon-irregular-polyhedron-off: unicode('faa9');
$ti-icon-irregular-polyhedron-plus: unicode('faaa');
$ti-icon-italic: unicode('eb93');
$ti-icon-jacket: unicode('f661');
$ti-icon-jetpack: unicode('f581');
$ti-icon-jetpack-filled: unicode('fe29');
$ti-icon-jewish-star: unicode('f3ff');
$ti-icon-jewish-star-filled: unicode('f67e');
$ti-icon-join-bevel: unicode('ff4c');
$ti-icon-join-round: unicode('ff4b');
$ti-icon-join-straight: unicode('ff4a');
$ti-icon-joker: unicode('1005f');
$ti-icon-jpg: unicode('f3ac');
$ti-icon-json: unicode('f7b2');
$ti-icon-jump-rope: unicode('ed8f');
$ti-icon-karate: unicode('ed32');
$ti-icon-kayak: unicode('f1d6');
$ti-icon-kerning: unicode('efb8');
$ti-icon-key: unicode('eac7');
$ti-icon-key-filled: unicode('fe28');
$ti-icon-key-off: unicode('f14b');
$ti-icon-keyboard: unicode('ebd6');
$ti-icon-keyboard-hide: unicode('ec7e');
$ti-icon-keyboard-off: unicode('eea0');
$ti-icon-keyboard-show: unicode('ec7f');
$ti-icon-keyframe: unicode('f576');
$ti-icon-keyframe-align-center: unicode('f582');
$ti-icon-keyframe-align-center-filled: unicode('fc30');
$ti-icon-keyframe-align-horizontal: unicode('f583');
$ti-icon-keyframe-align-horizontal-filled: unicode('fc31');
$ti-icon-keyframe-align-vertical: unicode('f584');
$ti-icon-keyframe-align-vertical-filled: unicode('fc32');
$ti-icon-keyframe-filled: unicode('fc33');
$ti-icon-keyframes: unicode('f585');
$ti-icon-keyframes-filled: unicode('fc34');
$ti-icon-label: unicode('ff38');
$ti-icon-label-filled: unicode('ff41');
$ti-icon-label-important: unicode('ff49');
$ti-icon-label-important-filled: unicode('ff60');
$ti-icon-label-off: unicode('ff39');
$ti-icon-ladder: unicode('efe2');
$ti-icon-ladder-off: unicode('f14c');
$ti-icon-ladle: unicode('fc14');
$ti-icon-lambda: unicode('f541');
$ti-icon-lamp: unicode('efab');
$ti-icon-lamp-2: unicode('f09e');
$ti-icon-lamp-off: unicode('f14d');
$ti-icon-lane: unicode('faf9');
$ti-icon-language: unicode('ebbe');
$ti-icon-language-hiragana: unicode('ef77');
$ti-icon-language-katakana: unicode('ef78');
$ti-icon-language-off: unicode('f14e');
$ti-icon-lasso: unicode('efac');
$ti-icon-lasso-off: unicode('f14f');
$ti-icon-lasso-polygon: unicode('f388');
$ti-icon-lasso-polygon-filled: unicode('ff5f');
$ti-icon-laurel-wreath: unicode('ff45');
$ti-icon-laurel-wreath-1: unicode('ff48');
$ti-icon-laurel-wreath-2: unicode('ff47');
$ti-icon-laurel-wreath-3: unicode('ff46');
$ti-icon-layers-difference: unicode('eac8');
$ti-icon-layers-intersect: unicode('eac9');
$ti-icon-layers-intersect-2: unicode('eff8');
$ti-icon-layers-linked: unicode('eea1');
$ti-icon-layers-off: unicode('f150');
$ti-icon-layers-selected: unicode('fea9');
$ti-icon-layers-selected-bottom: unicode('feaa');
$ti-icon-layers-subtract: unicode('eaca');
$ti-icon-layers-union: unicode('eacb');
$ti-icon-layout: unicode('eadb');
$ti-icon-layout-2: unicode('eacc');
$ti-icon-layout-2-filled: unicode('fe27');
$ti-icon-layout-align-bottom: unicode('eacd');
$ti-icon-layout-align-bottom-filled: unicode('fe26');
$ti-icon-layout-align-center: unicode('eace');
$ti-icon-layout-align-center-filled: unicode('fe25');
$ti-icon-layout-align-left: unicode('eacf');
$ti-icon-layout-align-left-filled: unicode('fe24');
$ti-icon-layout-align-middle: unicode('ead0');
$ti-icon-layout-align-middle-filled: unicode('fe23');
$ti-icon-layout-align-right: unicode('ead1');
$ti-icon-layout-align-right-filled: unicode('fe22');
$ti-icon-layout-align-top: unicode('ead2');
$ti-icon-layout-align-top-filled: unicode('fe21');
$ti-icon-layout-board: unicode('ef95');
$ti-icon-layout-board-split: unicode('ef94');
$ti-icon-layout-bottombar: unicode('ead3');
$ti-icon-layout-bottombar-collapse: unicode('f28b');
$ti-icon-layout-bottombar-collapse-filled: unicode('fc35');
$ti-icon-layout-bottombar-expand: unicode('f28c');
$ti-icon-layout-bottombar-expand-filled: unicode('fc36');
$ti-icon-layout-bottombar-filled: unicode('fc37');
$ti-icon-layout-bottombar-inactive: unicode('fd45');
$ti-icon-layout-cards: unicode('ec13');
$ti-icon-layout-cards-filled: unicode('fe20');
$ti-icon-layout-collage: unicode('f389');
$ti-icon-layout-columns: unicode('ead4');
$ti-icon-layout-dashboard: unicode('f02c');
$ti-icon-layout-dashboard-filled: unicode('fe1f');
$ti-icon-layout-distribute-horizontal: unicode('ead5');
$ti-icon-layout-distribute-horizontal-filled: unicode('fe1e');
$ti-icon-layout-distribute-vertical: unicode('ead6');
$ti-icon-layout-distribute-vertical-filled: unicode('fe1d');
$ti-icon-layout-filled: unicode('fe17');
$ti-icon-layout-grid: unicode('edba');
$ti-icon-layout-grid-add: unicode('edb9');
$ti-icon-layout-grid-filled: unicode('fe1c');
$ti-icon-layout-grid-remove: unicode('fa7d');
$ti-icon-layout-kanban: unicode('ec3f');
$ti-icon-layout-kanban-filled: unicode('fe1b');
$ti-icon-layout-list: unicode('ec14');
$ti-icon-layout-list-filled: unicode('fe1a');
$ti-icon-layout-navbar: unicode('ead7');
$ti-icon-layout-navbar-collapse: unicode('f28d');
$ti-icon-layout-navbar-collapse-filled: unicode('fc38');
$ti-icon-layout-navbar-expand: unicode('f28e');
$ti-icon-layout-navbar-expand-filled: unicode('fc39');
$ti-icon-layout-navbar-filled: unicode('fc3a');
$ti-icon-layout-navbar-inactive: unicode('fd46');
$ti-icon-layout-off: unicode('f151');
$ti-icon-layout-rows: unicode('ead8');
$ti-icon-layout-sidebar: unicode('eada');
$ti-icon-layout-sidebar-filled: unicode('fe18');
$ti-icon-layout-sidebar-inactive: unicode('fd47');
$ti-icon-layout-sidebar-left-collapse: unicode('f004');
$ti-icon-layout-sidebar-left-collapse-filled: unicode('fc3b');
$ti-icon-layout-sidebar-left-expand: unicode('f005');
$ti-icon-layout-sidebar-left-expand-filled: unicode('fc3c');
$ti-icon-layout-sidebar-right: unicode('ead9');
$ti-icon-layout-sidebar-right-collapse: unicode('f006');
$ti-icon-layout-sidebar-right-collapse-filled: unicode('fc3d');
$ti-icon-layout-sidebar-right-expand: unicode('f007');
$ti-icon-layout-sidebar-right-expand-filled: unicode('fc3e');
$ti-icon-layout-sidebar-right-filled: unicode('fe19');
$ti-icon-layout-sidebar-right-inactive: unicode('fd48');
$ti-icon-leaf: unicode('ed4f');
$ti-icon-leaf-2: unicode('ff44');
$ti-icon-leaf-off: unicode('f400');
$ti-icon-lego: unicode('eadc');
$ti-icon-lego-filled: unicode('fe16');
$ti-icon-lego-off: unicode('f401');
$ti-icon-lemon: unicode('ef10');
$ti-icon-lemon-2: unicode('ef81');
$ti-icon-letter-a: unicode('ec50');
$ti-icon-letter-a-small: unicode('fcc7');
$ti-icon-letter-b: unicode('ec51');
$ti-icon-letter-b-small: unicode('fcc8');
$ti-icon-letter-c: unicode('ec52');
$ti-icon-letter-c-small: unicode('fcc9');
$ti-icon-letter-case: unicode('eea5');
$ti-icon-letter-case-lower: unicode('eea2');
$ti-icon-letter-case-toggle: unicode('eea3');
$ti-icon-letter-case-upper: unicode('eea4');
$ti-icon-letter-d: unicode('ec53');
$ti-icon-letter-d-small: unicode('fcca');
$ti-icon-letter-e: unicode('ec54');
$ti-icon-letter-e-small: unicode('fccb');
$ti-icon-letter-f: unicode('ec55');
$ti-icon-letter-f-small: unicode('fccc');
$ti-icon-letter-g: unicode('ec56');
$ti-icon-letter-g-small: unicode('fccd');
$ti-icon-letter-h: unicode('ec57');
$ti-icon-letter-h-small: unicode('fcce');
$ti-icon-letter-i: unicode('ec58');
$ti-icon-letter-i-small: unicode('fccf');
$ti-icon-letter-j: unicode('ec59');
$ti-icon-letter-j-small: unicode('fcd0');
$ti-icon-letter-k: unicode('ec5a');
$ti-icon-letter-k-small: unicode('fcd1');
$ti-icon-letter-l: unicode('ec5b');
$ti-icon-letter-l-small: unicode('fcd2');
$ti-icon-letter-m: unicode('ec5c');
$ti-icon-letter-m-small: unicode('fcd3');
$ti-icon-letter-n: unicode('ec5d');
$ti-icon-letter-n-small: unicode('fcd4');
$ti-icon-letter-o: unicode('ec5e');
$ti-icon-letter-o-small: unicode('fcd5');
$ti-icon-letter-p: unicode('ec5f');
$ti-icon-letter-p-small: unicode('fcd6');
$ti-icon-letter-q: unicode('ec60');
$ti-icon-letter-q-small: unicode('fcd7');
$ti-icon-letter-r: unicode('ec61');
$ti-icon-letter-r-small: unicode('fcd8');
$ti-icon-letter-s: unicode('ec62');
$ti-icon-letter-s-small: unicode('fcd9');
$ti-icon-letter-spacing: unicode('eea6');
$ti-icon-letter-t: unicode('ec63');
$ti-icon-letter-t-small: unicode('fcda');
$ti-icon-letter-u: unicode('ec64');
$ti-icon-letter-u-small: unicode('fcdb');
$ti-icon-letter-v: unicode('ec65');
$ti-icon-letter-v-small: unicode('fcdc');
$ti-icon-letter-w: unicode('ec66');
$ti-icon-letter-w-small: unicode('fcdd');
$ti-icon-letter-x: unicode('ec67');
$ti-icon-letter-x-small: unicode('fcde');
$ti-icon-letter-y: unicode('ec68');
$ti-icon-letter-y-small: unicode('fcdf');
$ti-icon-letter-z: unicode('ec69');
$ti-icon-letter-z-small: unicode('fce0');
$ti-icon-library: unicode('fd4c');
$ti-icon-library-minus: unicode('fd49');
$ti-icon-library-photo: unicode('fd4a');
$ti-icon-library-plus: unicode('fd4b');
$ti-icon-license: unicode('ebc0');
$ti-icon-license-off: unicode('f153');
$ti-icon-lifebuoy: unicode('eadd');
$ti-icon-lifebuoy-off: unicode('f154');
$ti-icon-lighter: unicode('f794');
$ti-icon-line: unicode('ec40');
$ti-icon-line-dashed: unicode('eea7');
$ti-icon-line-dotted: unicode('eea8');
$ti-icon-line-height: unicode('eb94');
$ti-icon-line-scan: unicode('fcb8');
$ti-icon-link: unicode('eade');
$ti-icon-link-minus: unicode('fd16');
$ti-icon-link-off: unicode('f402');
$ti-icon-link-plus: unicode('fd17');
$ti-icon-list: unicode('eb6b');
$ti-icon-list-check: unicode('eb6a');
$ti-icon-list-details: unicode('ef40');
$ti-icon-list-letters: unicode('fc47');
$ti-icon-list-numbers: unicode('ef11');
$ti-icon-list-search: unicode('eea9');
$ti-icon-list-tree: unicode('fafa');
$ti-icon-live-photo: unicode('eadf');
$ti-icon-live-photo-filled: unicode('fed1');
$ti-icon-live-photo-off: unicode('f403');
$ti-icon-live-view: unicode('ec6b');
$ti-icon-load-balancer: unicode('fa5c');
$ti-icon-loader: unicode('eca3');
$ti-icon-loader-2: unicode('f226');
$ti-icon-loader-3: unicode('f513');
$ti-icon-loader-quarter: unicode('eca2');
$ti-icon-location: unicode('eae0');
$ti-icon-location-bolt: unicode('fbaf');
$ti-icon-location-broken: unicode('f2c4');
$ti-icon-location-cancel: unicode('fbb0');
$ti-icon-location-check: unicode('fbb1');
$ti-icon-location-code: unicode('fbb2');
$ti-icon-location-cog: unicode('fbb3');
$ti-icon-location-discount: unicode('fbb4');
$ti-icon-location-dollar: unicode('fbb5');
$ti-icon-location-down: unicode('fbb6');
$ti-icon-location-exclamation: unicode('fbb7');
$ti-icon-location-filled: unicode('f67f');
$ti-icon-location-heart: unicode('fbb8');
$ti-icon-location-minus: unicode('fbb9');
$ti-icon-location-off: unicode('f155');
$ti-icon-location-pause: unicode('fbba');
$ti-icon-location-pin: unicode('fbbb');
$ti-icon-location-plus: unicode('fbbc');
$ti-icon-location-question: unicode('fbbd');
$ti-icon-location-search: unicode('fbbe');
$ti-icon-location-share: unicode('fbbf');
$ti-icon-location-star: unicode('fbc0');
$ti-icon-location-up: unicode('fbc1');
$ti-icon-location-x: unicode('fbc2');
$ti-icon-lock: unicode('eae2');
$ti-icon-lock-access: unicode('eeaa');
$ti-icon-lock-access-off: unicode('f404');
$ti-icon-lock-bitcoin: unicode('ff37');
$ti-icon-lock-bolt: unicode('f924');
$ti-icon-lock-cancel: unicode('f925');
$ti-icon-lock-check: unicode('f926');
$ti-icon-lock-code: unicode('f927');
$ti-icon-lock-cog: unicode('f928');
$ti-icon-lock-dollar: unicode('f929');
$ti-icon-lock-down: unicode('f92a');
$ti-icon-lock-exclamation: unicode('f92b');
$ti-icon-lock-filled: unicode('fe15');
$ti-icon-lock-heart: unicode('f92c');
$ti-icon-lock-minus: unicode('f92d');
$ti-icon-lock-off: unicode('ed1e');
$ti-icon-lock-open: unicode('eae1');
$ti-icon-lock-open-2: unicode('fea8');
$ti-icon-lock-open-off: unicode('f156');
$ti-icon-lock-password: unicode('ff9f');
$ti-icon-lock-pause: unicode('f92e');
$ti-icon-lock-pin: unicode('f92f');
$ti-icon-lock-plus: unicode('f930');
$ti-icon-lock-question: unicode('f931');
$ti-icon-lock-search: unicode('f932');
$ti-icon-lock-share: unicode('f933');
$ti-icon-lock-square: unicode('ef51');
$ti-icon-lock-square-rounded: unicode('f636');
$ti-icon-lock-square-rounded-filled: unicode('f6da');
$ti-icon-lock-star: unicode('f934');
$ti-icon-lock-up: unicode('f935');
$ti-icon-lock-x: unicode('f936');
$ti-icon-logic-and: unicode('f240');
$ti-icon-logic-buffer: unicode('f241');
$ti-icon-logic-nand: unicode('f242');
$ti-icon-logic-nor: unicode('f243');
$ti-icon-logic-not: unicode('f244');
$ti-icon-logic-or: unicode('f245');
$ti-icon-logic-xnor: unicode('f246');
$ti-icon-logic-xor: unicode('f247');
$ti-icon-login: unicode('eba7');
$ti-icon-login-2: unicode('fc76');
$ti-icon-logout: unicode('eba8');
$ti-icon-logout-2: unicode('fa7e');
$ti-icon-logs: unicode('fea7');
$ti-icon-lollipop: unicode('efcc');
$ti-icon-lollipop-off: unicode('f157');
$ti-icon-luggage: unicode('efad');
$ti-icon-luggage-off: unicode('f158');
$ti-icon-lungs: unicode('ef62');
$ti-icon-lungs-filled: unicode('fe14');
$ti-icon-lungs-off: unicode('f405');
$ti-icon-macro: unicode('eeab');
$ti-icon-macro-filled: unicode('fe13');
$ti-icon-macro-off: unicode('f406');
$ti-icon-magnet: unicode('eae3');
$ti-icon-magnet-filled: unicode('fe12');
$ti-icon-magnet-off: unicode('f159');
$ti-icon-magnetic: unicode('fcb9');
$ti-icon-mail: unicode('eae5');
$ti-icon-mail-ai: unicode('fa31');
$ti-icon-mail-bitcoin: unicode('ff36');
$ti-icon-mail-bolt: unicode('f937');
$ti-icon-mail-cancel: unicode('f938');
$ti-icon-mail-check: unicode('f939');
$ti-icon-mail-code: unicode('f93a');
$ti-icon-mail-cog: unicode('f93b');
$ti-icon-mail-dollar: unicode('f93c');
$ti-icon-mail-down: unicode('f93d');
$ti-icon-mail-exclamation: unicode('f93e');
$ti-icon-mail-fast: unicode('f069');
$ti-icon-mail-filled: unicode('fa47');
$ti-icon-mail-forward: unicode('eeac');
$ti-icon-mail-heart: unicode('f93f');
$ti-icon-mail-minus: unicode('f940');
$ti-icon-mail-off: unicode('f15a');
$ti-icon-mail-opened: unicode('eae4');
$ti-icon-mail-opened-filled: unicode('fa48');
$ti-icon-mail-pause: unicode('f941');
$ti-icon-mail-pin: unicode('f942');
$ti-icon-mail-plus: unicode('f943');
$ti-icon-mail-question: unicode('f944');
$ti-icon-mail-search: unicode('f945');
$ti-icon-mail-share: unicode('f946');
$ti-icon-mail-spark: unicode('ffb3');
$ti-icon-mail-star: unicode('f947');
$ti-icon-mail-up: unicode('f948');
$ti-icon-mail-x: unicode('f949');
$ti-icon-mailbox: unicode('eead');
$ti-icon-mailbox-off: unicode('f15b');
$ti-icon-man: unicode('eae6');
$ti-icon-man-filled: unicode('fe11');
$ti-icon-manual-gearbox: unicode('ed7b');
$ti-icon-manual-gearbox-filled: unicode('fe10');
$ti-icon-map: unicode('eae9');
$ti-icon-map-2: unicode('eae7');
$ti-icon-map-bolt: unicode('fbc3');
$ti-icon-map-cancel: unicode('fbc4');
$ti-icon-map-check: unicode('fbc5');
$ti-icon-map-code: unicode('fbc6');
$ti-icon-map-cog: unicode('fbc7');
$ti-icon-map-discount: unicode('fbc8');
$ti-icon-map-dollar: unicode('fbc9');
$ti-icon-map-down: unicode('fbca');
$ti-icon-map-east: unicode('fc5d');
$ti-icon-map-exclamation: unicode('fbcb');
$ti-icon-map-heart: unicode('fbcc');
$ti-icon-map-minus: unicode('fbcd');
$ti-icon-map-north: unicode('fc5e');
$ti-icon-map-off: unicode('f15c');
$ti-icon-map-pause: unicode('fbce');
$ti-icon-map-pin: unicode('eae8');
$ti-icon-map-pin-2: unicode('fc48');
$ti-icon-map-pin-bolt: unicode('f94a');
$ti-icon-map-pin-cancel: unicode('f94b');
$ti-icon-map-pin-check: unicode('f94c');
$ti-icon-map-pin-code: unicode('f94d');
$ti-icon-map-pin-cog: unicode('f94e');
$ti-icon-map-pin-dollar: unicode('f94f');
$ti-icon-map-pin-down: unicode('f950');
$ti-icon-map-pin-exclamation: unicode('f951');
$ti-icon-map-pin-filled: unicode('f680');
$ti-icon-map-pin-heart: unicode('f952');
$ti-icon-map-pin-minus: unicode('f953');
$ti-icon-map-pin-off: unicode('ecf3');
$ti-icon-map-pin-pause: unicode('f954');
$ti-icon-map-pin-pin: unicode('f955');
$ti-icon-map-pin-plus: unicode('f956');
$ti-icon-map-pin-question: unicode('f957');
$ti-icon-map-pin-search: unicode('f958');
$ti-icon-map-pin-share: unicode('f795');
$ti-icon-map-pin-star: unicode('f959');
$ti-icon-map-pin-up: unicode('f95a');
$ti-icon-map-pin-x: unicode('f95b');
$ti-icon-map-pins: unicode('ed5e');
$ti-icon-map-plus: unicode('fbcf');
$ti-icon-map-question: unicode('fbd0');
$ti-icon-map-route: unicode('fc79');
$ti-icon-map-search: unicode('ef82');
$ti-icon-map-share: unicode('fbd1');
$ti-icon-map-south: unicode('fc5f');
$ti-icon-map-star: unicode('fbd2');
$ti-icon-map-up: unicode('fbd3');
$ti-icon-map-west: unicode('fc60');
$ti-icon-map-x: unicode('fbd4');
$ti-icon-markdown: unicode('ec41');
$ti-icon-markdown-off: unicode('f407');
$ti-icon-marquee: unicode('ec77');
$ti-icon-marquee-2: unicode('eeae');
$ti-icon-marquee-off: unicode('f15d');
$ti-icon-mars: unicode('ec80');
$ti-icon-mask: unicode('eeb0');
$ti-icon-mask-off: unicode('eeaf');
$ti-icon-masks-theater: unicode('f263');
$ti-icon-masks-theater-off: unicode('f408');
$ti-icon-massage: unicode('eeb1');
$ti-icon-matchstick: unicode('f577');
$ti-icon-math: unicode('ebeb');
$ti-icon-math-1-divide-2: unicode('f4e2');
$ti-icon-math-1-divide-3: unicode('f4e3');
$ti-icon-math-avg: unicode('f0f4');
$ti-icon-math-cos: unicode('ff1f');
$ti-icon-math-ctg: unicode('ff35');
$ti-icon-math-equal-greater: unicode('f4e4');
$ti-icon-math-equal-lower: unicode('f4e5');
$ti-icon-math-function: unicode('eeb2');
$ti-icon-math-function-off: unicode('f15e');
$ti-icon-math-function-y: unicode('f4e6');
$ti-icon-math-greater: unicode('f4e7');
$ti-icon-math-integral: unicode('f4e9');
$ti-icon-math-integral-x: unicode('f4e8');
$ti-icon-math-integrals: unicode('f4ea');
$ti-icon-math-lower: unicode('f4eb');
$ti-icon-math-max: unicode('f0f5');
$ti-icon-math-max-min: unicode('fda0');
$ti-icon-math-min: unicode('f0f6');
$ti-icon-math-not: unicode('f4ec');
$ti-icon-math-off: unicode('f409');
$ti-icon-math-pi: unicode('f4ee');
$ti-icon-math-pi-divide-2: unicode('f4ed');
$ti-icon-math-sec: unicode('ff34');
$ti-icon-math-sin: unicode('ff1e');
$ti-icon-math-symbols: unicode('eeb3');
$ti-icon-math-tg: unicode('ff33');
$ti-icon-math-x-divide-2: unicode('f4ef');
$ti-icon-math-x-divide-y: unicode('f4f1');
$ti-icon-math-x-divide-y-2: unicode('f4f0');
$ti-icon-math-x-floor-divide-y: unicode('10073');
$ti-icon-math-x-minus-x: unicode('f4f2');
$ti-icon-math-x-minus-y: unicode('f4f3');
$ti-icon-math-x-plus-x: unicode('f4f4');
$ti-icon-math-x-plus-y: unicode('f4f5');
$ti-icon-math-xy: unicode('f4f6');
$ti-icon-math-y-minus-y: unicode('f4f7');
$ti-icon-math-y-plus-y: unicode('f4f8');
$ti-icon-maximize: unicode('eaea');
$ti-icon-maximize-off: unicode('f15f');
$ti-icon-meat: unicode('ef12');
$ti-icon-meat-off: unicode('f40a');
$ti-icon-medal: unicode('ec78');
$ti-icon-medal-2: unicode('efcd');
$ti-icon-medical-cross: unicode('ec2f');
$ti-icon-medical-cross-circle: unicode('fae8');
$ti-icon-medical-cross-filled: unicode('f681');
$ti-icon-medical-cross-off: unicode('f160');
$ti-icon-medicine-syrup: unicode('ef63');
$ti-icon-meeple: unicode('f514');
$ti-icon-melon: unicode('fc7a');
$ti-icon-melon-filled: unicode('1000e');
$ti-icon-menorah: unicode('f58c');
$ti-icon-menu: unicode('eaeb');
$ti-icon-menu-2: unicode('ec42');
$ti-icon-menu-3: unicode('ff43');
$ti-icon-menu-4: unicode('ff42');
$ti-icon-menu-deep: unicode('fafb');
$ti-icon-menu-order: unicode('f5f5');
$ti-icon-message: unicode('eaef');
$ti-icon-message-2: unicode('eaec');
$ti-icon-message-2-bolt: unicode('f95c');
$ti-icon-message-2-cancel: unicode('f95d');
$ti-icon-message-2-check: unicode('f95e');
$ti-icon-message-2-code: unicode('f012');
$ti-icon-message-2-cog: unicode('f95f');
$ti-icon-message-2-dollar: unicode('f960');
$ti-icon-message-2-down: unicode('f961');
$ti-icon-message-2-exclamation: unicode('f962');
$ti-icon-message-2-heart: unicode('f963');
$ti-icon-message-2-minus: unicode('f964');
$ti-icon-message-2-off: unicode('f40b');
$ti-icon-message-2-pause: unicode('f965');
$ti-icon-message-2-pin: unicode('f966');
$ti-icon-message-2-plus: unicode('f967');
$ti-icon-message-2-question: unicode('f968');
$ti-icon-message-2-search: unicode('f969');
$ti-icon-message-2-share: unicode('f077');
$ti-icon-message-2-star: unicode('f96a');
$ti-icon-message-2-up: unicode('f96b');
$ti-icon-message-2-x: unicode('f96c');
$ti-icon-message-bolt: unicode('f96d');
$ti-icon-message-cancel: unicode('f96e');
$ti-icon-message-chatbot: unicode('f38a');
$ti-icon-message-chatbot-filled: unicode('fed0');
$ti-icon-message-check: unicode('f96f');
$ti-icon-message-circle: unicode('eaed');
$ti-icon-message-circle-bolt: unicode('f970');
$ti-icon-message-circle-cancel: unicode('f971');
$ti-icon-message-circle-check: unicode('f972');
$ti-icon-message-circle-code: unicode('f973');
$ti-icon-message-circle-cog: unicode('f974');
$ti-icon-message-circle-dollar: unicode('f975');
$ti-icon-message-circle-down: unicode('f976');
$ti-icon-message-circle-exclamation: unicode('f977');
$ti-icon-message-circle-filled: unicode('fecf');
$ti-icon-message-circle-heart: unicode('f978');
$ti-icon-message-circle-minus: unicode('f979');
$ti-icon-message-circle-off: unicode('ed40');
$ti-icon-message-circle-pause: unicode('f97a');
$ti-icon-message-circle-pin: unicode('f97b');
$ti-icon-message-circle-plus: unicode('f97c');
$ti-icon-message-circle-question: unicode('f97d');
$ti-icon-message-circle-search: unicode('f97e');
$ti-icon-message-circle-share: unicode('f97f');
$ti-icon-message-circle-star: unicode('f980');
$ti-icon-message-circle-up: unicode('f981');
$ti-icon-message-circle-user: unicode('fec5');
$ti-icon-message-circle-x: unicode('f982');
$ti-icon-message-code: unicode('f013');
$ti-icon-message-cog: unicode('f983');
$ti-icon-message-dollar: unicode('f984');
$ti-icon-message-dots: unicode('eaee');
$ti-icon-message-down: unicode('f985');
$ti-icon-message-exclamation: unicode('f986');
$ti-icon-message-filled: unicode('fecd');
$ti-icon-message-forward: unicode('f28f');
$ti-icon-message-heart: unicode('f987');
$ti-icon-message-language: unicode('efae');
$ti-icon-message-minus: unicode('f988');
$ti-icon-message-off: unicode('ed41');
$ti-icon-message-pause: unicode('f989');
$ti-icon-message-pin: unicode('f98a');
$ti-icon-message-plus: unicode('ec9a');
$ti-icon-message-question: unicode('f98b');
$ti-icon-message-reply: unicode('fd4d');
$ti-icon-message-report: unicode('ec9b');
$ti-icon-message-report-filled: unicode('fece');
$ti-icon-message-search: unicode('f98c');
$ti-icon-message-share: unicode('f078');
$ti-icon-message-star: unicode('f98d');
$ti-icon-message-up: unicode('f98e');
$ti-icon-message-user: unicode('fec4');
$ti-icon-message-x: unicode('f98f');
$ti-icon-messages: unicode('eb6c');
$ti-icon-messages-off: unicode('ed42');
$ti-icon-meteor: unicode('f1fd');
$ti-icon-meteor-filled: unicode('1000d');
$ti-icon-meteor-off: unicode('f40c');
$ti-icon-meter-cube: unicode('fd7c');
$ti-icon-meter-square: unicode('fd7d');
$ti-icon-metronome: unicode('fd25');
$ti-icon-michelin-bib-gourmand: unicode('fae9');
$ti-icon-michelin-star: unicode('faeb');
$ti-icon-michelin-star-filled: unicode('1000c');
$ti-icon-michelin-star-green: unicode('faea');
$ti-icon-mickey: unicode('f2a3');
$ti-icon-mickey-filled: unicode('f683');
$ti-icon-microphone: unicode('eaf0');
$ti-icon-microphone-2: unicode('ef2c');
$ti-icon-microphone-2-off: unicode('f40d');
$ti-icon-microphone-filled: unicode('fe0f');
$ti-icon-microphone-off: unicode('ed16');
$ti-icon-microscope: unicode('ef64');
$ti-icon-microscope-off: unicode('f40e');
$ti-icon-microwave: unicode('f248');
$ti-icon-microwave-filled: unicode('fe0e');
$ti-icon-microwave-off: unicode('f264');
$ti-icon-military-award: unicode('f079');
$ti-icon-military-rank: unicode('efcf');
$ti-icon-military-rank-filled: unicode('ff5e');
$ti-icon-milk: unicode('ef13');
$ti-icon-milk-filled: unicode('1000b');
$ti-icon-milk-off: unicode('f40f');
$ti-icon-milkshake: unicode('f4c8');
$ti-icon-minimize: unicode('eaf1');
$ti-icon-minus: unicode('eaf2');
$ti-icon-minus-vertical: unicode('eeb4');
$ti-icon-mist: unicode('ec30');
$ti-icon-mist-off: unicode('f410');
$ti-icon-mobiledata: unicode('f9f5');
$ti-icon-mobiledata-off: unicode('f9f4');
$ti-icon-moneybag: unicode('f506');
$ti-icon-monkeybar: unicode('feb4');
$ti-icon-mood-angry: unicode('f2de');
$ti-icon-mood-angry-filled: unicode('ff0a');
$ti-icon-mood-annoyed: unicode('f2e0');
$ti-icon-mood-annoyed-2: unicode('f2df');
$ti-icon-mood-bitcoin: unicode('ff32');
$ti-icon-mood-boy: unicode('ed2d');
$ti-icon-mood-check: unicode('f7b3');
$ti-icon-mood-cog: unicode('f7b4');
$ti-icon-mood-confuzed: unicode('eaf3');
$ti-icon-mood-confuzed-filled: unicode('f7f2');
$ti-icon-mood-crazy-happy: unicode('ed90');
$ti-icon-mood-crazy-happy-filled: unicode('ff09');
$ti-icon-mood-cry: unicode('ecbb');
$ti-icon-mood-dollar: unicode('f7b5');
$ti-icon-mood-edit: unicode('fa05');
$ti-icon-mood-empty: unicode('eeb5');
$ti-icon-mood-empty-filled: unicode('f7f3');
$ti-icon-mood-happy: unicode('eaf4');
$ti-icon-mood-happy-filled: unicode('f7f4');
$ti-icon-mood-heart: unicode('f7b6');
$ti-icon-mood-kid: unicode('ec03');
$ti-icon-mood-kid-filled: unicode('f7f5');
$ti-icon-mood-look-down: unicode('fd37');
$ti-icon-mood-look-left: unicode('f2c5');
$ti-icon-mood-look-right: unicode('f2c6');
$ti-icon-mood-look-up: unicode('fd38');
$ti-icon-mood-minus: unicode('f7b7');
$ti-icon-mood-nerd: unicode('f2e1');
$ti-icon-mood-nervous: unicode('ef96');
$ti-icon-mood-neutral: unicode('eaf5');
$ti-icon-mood-neutral-filled: unicode('f7f6');
$ti-icon-mood-off: unicode('f161');
$ti-icon-mood-pin: unicode('f7b8');
$ti-icon-mood-plus: unicode('f7b9');
$ti-icon-mood-puzzled: unicode('fd39');
$ti-icon-mood-sad: unicode('eaf6');
$ti-icon-mood-sad-2: unicode('f2e2');
$ti-icon-mood-sad-dizzy: unicode('f2e3');
$ti-icon-mood-sad-filled: unicode('f7f7');
$ti-icon-mood-sad-squint: unicode('f2e4');
$ti-icon-mood-search: unicode('f7ba');
$ti-icon-mood-share: unicode('fa06');
$ti-icon-mood-sick: unicode('f2e5');
$ti-icon-mood-silence: unicode('f2e6');
$ti-icon-mood-sing: unicode('f2c7');
$ti-icon-mood-smile: unicode('eaf7');
$ti-icon-mood-smile-beam: unicode('f2e7');
$ti-icon-mood-smile-dizzy: unicode('f2e8');
$ti-icon-mood-smile-filled: unicode('f7f8');
$ti-icon-mood-spark: unicode('ffb2');
$ti-icon-mood-surprised: unicode('ec04');
$ti-icon-mood-tongue: unicode('eb95');
$ti-icon-mood-tongue-wink: unicode('f2ea');
$ti-icon-mood-tongue-wink-2: unicode('f2e9');
$ti-icon-mood-unamused: unicode('f2eb');
$ti-icon-mood-up: unicode('f7bb');
$ti-icon-mood-wink: unicode('f2ed');
$ti-icon-mood-wink-2: unicode('f2ec');
$ti-icon-mood-wrrr: unicode('f2ee');
$ti-icon-mood-wrrr-filled: unicode('ff08');
$ti-icon-mood-x: unicode('f7bc');
$ti-icon-mood-xd: unicode('f2ef');
$ti-icon-moon: unicode('eaf8');
$ti-icon-moon-2: unicode('ece6');
$ti-icon-moon-filled: unicode('f684');
$ti-icon-moon-off: unicode('f162');
$ti-icon-moon-stars: unicode('ece7');
$ti-icon-moped: unicode('ecbc');
$ti-icon-motorbike: unicode('eeb6');
$ti-icon-mountain: unicode('ef97');
$ti-icon-mountain-filled: unicode('1000a');
$ti-icon-mountain-off: unicode('f411');
$ti-icon-mouse: unicode('eaf9');
$ti-icon-mouse-2: unicode('f1d7');
$ti-icon-mouse-filled: unicode('fb2f');
$ti-icon-mouse-off: unicode('f163');
$ti-icon-moustache: unicode('f4c9');
$ti-icon-movie: unicode('eafa');
$ti-icon-movie-off: unicode('f164');
$ti-icon-mug: unicode('eafb');
$ti-icon-mug-filled: unicode('10009');
$ti-icon-mug-off: unicode('f165');
$ti-icon-multiplier-0-5x: unicode('ef41');
$ti-icon-multiplier-1-5x: unicode('ef42');
$ti-icon-multiplier-1x: unicode('ef43');
$ti-icon-multiplier-2x: unicode('ef44');
$ti-icon-mushroom: unicode('ef14');
$ti-icon-mushroom-filled: unicode('f7f9');
$ti-icon-mushroom-off: unicode('f412');
$ti-icon-music: unicode('eafc');
$ti-icon-music-bolt: unicode('fbd5');
$ti-icon-music-cancel: unicode('fbd6');
$ti-icon-music-check: unicode('fbd7');
$ti-icon-music-code: unicode('fbd8');
$ti-icon-music-cog: unicode('fbd9');
$ti-icon-music-discount: unicode('fbda');
$ti-icon-music-dollar: unicode('fbdb');
$ti-icon-music-down: unicode('fbdc');
$ti-icon-music-exclamation: unicode('fbdd');
$ti-icon-music-heart: unicode('fbde');
$ti-icon-music-minus: unicode('fbdf');
$ti-icon-music-off: unicode('f166');
$ti-icon-music-pause: unicode('fbe0');
$ti-icon-music-pin: unicode('fbe1');
$ti-icon-music-plus: unicode('fbe2');
$ti-icon-music-question: unicode('fbe3');
$ti-icon-music-search: unicode('fbe4');
$ti-icon-music-share: unicode('fbe5');
$ti-icon-music-star: unicode('fbe6');
$ti-icon-music-up: unicode('fbe7');
$ti-icon-music-x: unicode('fbe8');
$ti-icon-navigation: unicode('f2c8');
$ti-icon-navigation-bolt: unicode('fbe9');
$ti-icon-navigation-cancel: unicode('fbea');
$ti-icon-navigation-check: unicode('fbeb');
$ti-icon-navigation-code: unicode('fbec');
$ti-icon-navigation-cog: unicode('fbed');
$ti-icon-navigation-discount: unicode('fbee');
$ti-icon-navigation-dollar: unicode('fbef');
$ti-icon-navigation-down: unicode('fbf0');
$ti-icon-navigation-east: unicode('fcba');
$ti-icon-navigation-exclamation: unicode('fbf1');
$ti-icon-navigation-filled: unicode('f685');
$ti-icon-navigation-heart: unicode('fbf2');
$ti-icon-navigation-minus: unicode('fbf3');
$ti-icon-navigation-north: unicode('fcbb');
$ti-icon-navigation-off: unicode('f413');
$ti-icon-navigation-pause: unicode('fbf4');
$ti-icon-navigation-pin: unicode('fbf5');
$ti-icon-navigation-plus: unicode('fbf6');
$ti-icon-navigation-question: unicode('fbf7');
$ti-icon-navigation-search: unicode('fbf8');
$ti-icon-navigation-share: unicode('fbf9');
$ti-icon-navigation-south: unicode('fcbc');
$ti-icon-navigation-star: unicode('fbfa');
$ti-icon-navigation-top: unicode('faec');
$ti-icon-navigation-up: unicode('fbfb');
$ti-icon-navigation-west: unicode('fcbd');
$ti-icon-navigation-x: unicode('fbfc');
$ti-icon-needle: unicode('f508');
$ti-icon-needle-thread: unicode('f507');
$ti-icon-network: unicode('f09f');
$ti-icon-network-off: unicode('f414');
$ti-icon-new-section: unicode('ebc1');
$ti-icon-news: unicode('eafd');
$ti-icon-news-off: unicode('f167');
$ti-icon-nfc: unicode('eeb7');
$ti-icon-nfc-off: unicode('f168');
$ti-icon-no-copyright: unicode('efb9');
$ti-icon-no-creative-commons: unicode('efba');
$ti-icon-no-derivatives: unicode('efbb');
$ti-icon-north-star: unicode('f014');
$ti-icon-note: unicode('eb6d');
$ti-icon-note-off: unicode('f169');
$ti-icon-notebook: unicode('eb96');
$ti-icon-notebook-off: unicode('f415');
$ti-icon-notes: unicode('eb6e');
$ti-icon-notes-off: unicode('f16a');
$ti-icon-notification: unicode('eafe');
$ti-icon-notification-off: unicode('f16b');
$ti-icon-number: unicode('f1fe');
$ti-icon-number-0: unicode('edf0');
$ti-icon-number-0-small: unicode('fce1');
$ti-icon-number-1: unicode('edf1');
$ti-icon-number-1-small: unicode('fce2');
$ti-icon-number-10: unicode('1005e');
$ti-icon-number-10-small: unicode('fce3');
$ti-icon-number-100-small: unicode('10005');
$ti-icon-number-11: unicode('1005d');
$ti-icon-number-11-small: unicode('fce4');
$ti-icon-number-12-small: unicode('fce5');
$ti-icon-number-123: unicode('f554');
$ti-icon-number-13-small: unicode('fce6');
$ti-icon-number-14-small: unicode('fce7');
$ti-icon-number-15-small: unicode('fce8');
$ti-icon-number-16-small: unicode('fce9');
$ti-icon-number-17-small: unicode('fcea');
$ti-icon-number-18-small: unicode('fceb');
$ti-icon-number-19-small: unicode('fcec');
$ti-icon-number-2: unicode('edf2');
$ti-icon-number-2-small: unicode('fced');
$ti-icon-number-20-small: unicode('fcee');
$ti-icon-number-21-small: unicode('fcef');
$ti-icon-number-22-small: unicode('fcf0');
$ti-icon-number-23-small: unicode('fcf1');
$ti-icon-number-24-small: unicode('fcf2');
$ti-icon-number-25-small: unicode('fcf3');
$ti-icon-number-26-small: unicode('fcf4');
$ti-icon-number-27-small: unicode('fcf5');
$ti-icon-number-28-small: unicode('fcf6');
$ti-icon-number-29-small: unicode('fcf7');
$ti-icon-number-3: unicode('edf3');
$ti-icon-number-3-small: unicode('fcf8');
$ti-icon-number-30-small: unicode('10004');
$ti-icon-number-31-small: unicode('10003');
$ti-icon-number-32-small: unicode('10002');
$ti-icon-number-33-small: unicode('10001');
$ti-icon-number-34-small: unicode('10000');
$ti-icon-number-35-small: unicode('ffff');
$ti-icon-number-36-small: unicode('fffe');
$ti-icon-number-37-small: unicode('fffd');
$ti-icon-number-38-small: unicode('fffc');
$ti-icon-number-39-small: unicode('fffb');
$ti-icon-number-4: unicode('edf4');
$ti-icon-number-4-small: unicode('fcf9');
$ti-icon-number-40-small: unicode('fffa');
$ti-icon-number-41-small: unicode('fff9');
$ti-icon-number-42-small: unicode('fff8');
$ti-icon-number-43-small: unicode('fff7');
$ti-icon-number-44-small: unicode('fff6');
$ti-icon-number-45-small: unicode('fff5');
$ti-icon-number-46-small: unicode('fff4');
$ti-icon-number-47-small: unicode('fff3');
$ti-icon-number-48-small: unicode('fff2');
$ti-icon-number-49-small: unicode('fff1');
$ti-icon-number-5: unicode('edf5');
$ti-icon-number-5-small: unicode('fcfa');
$ti-icon-number-50-small: unicode('fff0');
$ti-icon-number-51-small: unicode('ffef');
$ti-icon-number-52-small: unicode('ffee');
$ti-icon-number-53-small: unicode('ffed');
$ti-icon-number-54-small: unicode('ffec');
$ti-icon-number-55-small: unicode('ffeb');
$ti-icon-number-56-small: unicode('ffea');
$ti-icon-number-57-small: unicode('ffe9');
$ti-icon-number-58-small: unicode('ffe8');
$ti-icon-number-59-small: unicode('ffe7');
$ti-icon-number-6: unicode('edf6');
$ti-icon-number-6-small: unicode('fcfb');
$ti-icon-number-60-small: unicode('ffe6');
$ti-icon-number-61-small: unicode('ffe5');
$ti-icon-number-62-small: unicode('ffe4');
$ti-icon-number-63-small: unicode('ffe3');
$ti-icon-number-64-small: unicode('ffe2');
$ti-icon-number-65-small: unicode('ffe1');
$ti-icon-number-66-small: unicode('ffe0');
$ti-icon-number-67-small: unicode('ffdf');
$ti-icon-number-68-small: unicode('ffde');
$ti-icon-number-69-small: unicode('ffdd');
$ti-icon-number-7: unicode('edf7');
$ti-icon-number-7-small: unicode('fcfc');
$ti-icon-number-70-small: unicode('ffdc');
$ti-icon-number-71-small: unicode('ffdb');
$ti-icon-number-72-small: unicode('ffda');
$ti-icon-number-73-small: unicode('ffd9');
$ti-icon-number-74-small: unicode('ffd8');
$ti-icon-number-75-small: unicode('ffd7');
$ti-icon-number-76-small: unicode('ffd6');
$ti-icon-number-77-small: unicode('ffd5');
$ti-icon-number-78-small: unicode('ffd4');
$ti-icon-number-79-small: unicode('ffd3');
$ti-icon-number-8: unicode('edf8');
$ti-icon-number-8-small: unicode('fcfd');
$ti-icon-number-80-small: unicode('ffd2');
$ti-icon-number-81-small: unicode('ffd1');
$ti-icon-number-82-small: unicode('ffd0');
$ti-icon-number-83-small: unicode('ffcf');
$ti-icon-number-84-small: unicode('ffce');
$ti-icon-number-85-small: unicode('ffcd');
$ti-icon-number-86-small: unicode('ffcc');
$ti-icon-number-87-small: unicode('ffcb');
$ti-icon-number-88-small: unicode('ffca');
$ti-icon-number-89-small: unicode('ffc9');
$ti-icon-number-9: unicode('edf9');
$ti-icon-number-9-small: unicode('fcfe');
$ti-icon-number-90-small: unicode('ffc8');
$ti-icon-number-91-small: unicode('ffc7');
$ti-icon-number-92-small: unicode('ffc6');
$ti-icon-number-93-small: unicode('ffc5');
$ti-icon-number-94-small: unicode('ffc4');
$ti-icon-number-95-small: unicode('ffc3');
$ti-icon-number-96-small: unicode('ffc2');
$ti-icon-number-97-small: unicode('ffc1');
$ti-icon-number-98-small: unicode('ffc0');
$ti-icon-number-99-small: unicode('ffbf');
$ti-icon-numbers: unicode('f015');
$ti-icon-nurse: unicode('ef65');
$ti-icon-nut: unicode('fc61');
$ti-icon-object-scan: unicode('fef1');
$ti-icon-octagon: unicode('ecbd');
$ti-icon-octagon-filled: unicode('f686');
$ti-icon-octagon-minus: unicode('fc92');
$ti-icon-octagon-minus-2: unicode('fc91');
$ti-icon-octagon-off: unicode('eeb8');
$ti-icon-octagon-plus: unicode('fc94');
$ti-icon-octagon-plus-2: unicode('fc93');
$ti-icon-octahedron: unicode('faae');
$ti-icon-octahedron-off: unicode('faac');
$ti-icon-octahedron-plus: unicode('faad');
$ti-icon-old: unicode('eeb9');
$ti-icon-olympics: unicode('eeba');
$ti-icon-olympics-off: unicode('f416');
$ti-icon-om: unicode('f58d');
$ti-icon-omega: unicode('eb97');
$ti-icon-outbound: unicode('f249');
$ti-icon-outlet: unicode('ebd7');
$ti-icon-oval: unicode('f02e');
$ti-icon-oval-filled: unicode('f687');
$ti-icon-oval-vertical: unicode('f02d');
$ti-icon-oval-vertical-filled: unicode('f688');
$ti-icon-overline: unicode('eebb');
$ti-icon-package: unicode('eaff');
$ti-icon-package-export: unicode('f07a');
$ti-icon-package-import: unicode('f07b');
$ti-icon-package-off: unicode('f16c');
$ti-icon-packages: unicode('f2c9');
$ti-icon-pacman: unicode('eebc');
$ti-icon-page-break: unicode('ec81');
$ti-icon-paint: unicode('eb00');
$ti-icon-paint-filled: unicode('f75f');
$ti-icon-paint-off: unicode('f16d');
$ti-icon-palette: unicode('eb01');
$ti-icon-palette-off: unicode('f16e');
$ti-icon-panorama-horizontal: unicode('ed33');
$ti-icon-panorama-horizontal-filled: unicode('fecc');
$ti-icon-panorama-horizontal-off: unicode('f417');
$ti-icon-panorama-vertical: unicode('ed34');
$ti-icon-panorama-vertical-filled: unicode('fecb');
$ti-icon-panorama-vertical-off: unicode('f418');
$ti-icon-paper-bag: unicode('f02f');
$ti-icon-paper-bag-off: unicode('f16f');
$ti-icon-paperclip: unicode('eb02');
$ti-icon-parachute: unicode('ed7c');
$ti-icon-parachute-off: unicode('f170');
$ti-icon-parentheses: unicode('ebd8');
$ti-icon-parentheses-off: unicode('f171');
$ti-icon-parking: unicode('eb03');
$ti-icon-parking-circle: unicode('fd5a');
$ti-icon-parking-circle-filled: unicode('feca');
$ti-icon-parking-off: unicode('f172');
$ti-icon-password: unicode('f4ca');
$ti-icon-password-fingerprint: unicode('fc7b');
$ti-icon-password-mobile-phone: unicode('fc7c');
$ti-icon-password-user: unicode('fc7d');
$ti-icon-paw: unicode('eff9');
$ti-icon-paw-filled: unicode('f689');
$ti-icon-paw-off: unicode('f419');
$ti-icon-paywall: unicode('fd7e');
$ti-icon-pdf: unicode('f7ac');
$ti-icon-peace: unicode('ecbe');
$ti-icon-pencil: unicode('eb04');
$ti-icon-pencil-bolt: unicode('fbfd');
$ti-icon-pencil-cancel: unicode('fbfe');
$ti-icon-pencil-check: unicode('fbff');
$ti-icon-pencil-code: unicode('fc00');
$ti-icon-pencil-cog: unicode('fc01');
$ti-icon-pencil-discount: unicode('fc02');
$ti-icon-pencil-dollar: unicode('fc03');
$ti-icon-pencil-down: unicode('fc04');
$ti-icon-pencil-exclamation: unicode('fc05');
$ti-icon-pencil-heart: unicode('fc06');
$ti-icon-pencil-minus: unicode('f1eb');
$ti-icon-pencil-off: unicode('f173');
$ti-icon-pencil-pause: unicode('fc07');
$ti-icon-pencil-pin: unicode('fc08');
$ti-icon-pencil-plus: unicode('f1ec');
$ti-icon-pencil-question: unicode('fc09');
$ti-icon-pencil-search: unicode('fc0a');
$ti-icon-pencil-share: unicode('fc0b');
$ti-icon-pencil-star: unicode('fc0c');
$ti-icon-pencil-up: unicode('fc0d');
$ti-icon-pencil-x: unicode('fc0e');
$ti-icon-pennant: unicode('ed7d');
$ti-icon-pennant-2: unicode('f06a');
$ti-icon-pennant-2-filled: unicode('f68a');
$ti-icon-pennant-filled: unicode('f68b');
$ti-icon-pennant-off: unicode('f174');
$ti-icon-pentagon: unicode('efe3');
$ti-icon-pentagon-filled: unicode('f68c');
$ti-icon-pentagon-minus: unicode('feb3');
$ti-icon-pentagon-number-0: unicode('fc7e');
$ti-icon-pentagon-number-1: unicode('fc7f');
$ti-icon-pentagon-number-2: unicode('fc80');
$ti-icon-pentagon-number-3: unicode('fc81');
$ti-icon-pentagon-number-4: unicode('fc82');
$ti-icon-pentagon-number-5: unicode('fc83');
$ti-icon-pentagon-number-6: unicode('fc84');
$ti-icon-pentagon-number-7: unicode('fc85');
$ti-icon-pentagon-number-8: unicode('fc86');
$ti-icon-pentagon-number-9: unicode('fc87');
$ti-icon-pentagon-off: unicode('f41a');
$ti-icon-pentagon-plus: unicode('fc49');
$ti-icon-pentagon-x: unicode('fc88');
$ti-icon-pentagram: unicode('f586');
$ti-icon-pepper: unicode('ef15');
$ti-icon-pepper-off: unicode('f175');
$ti-icon-percentage: unicode('ecf4');
$ti-icon-percentage-0: unicode('fee5');
$ti-icon-percentage-10: unicode('fee4');
$ti-icon-percentage-100: unicode('fee3');
$ti-icon-percentage-20: unicode('fee2');
$ti-icon-percentage-25: unicode('fee1');
$ti-icon-percentage-30: unicode('fee0');
$ti-icon-percentage-33: unicode('fedf');
$ti-icon-percentage-40: unicode('fede');
$ti-icon-percentage-50: unicode('fedd');
$ti-icon-percentage-60: unicode('fedc');
$ti-icon-percentage-66: unicode('fedb');
$ti-icon-percentage-70: unicode('feda');
$ti-icon-percentage-75: unicode('fed9');
$ti-icon-percentage-80: unicode('fed8');
$ti-icon-percentage-90: unicode('fed7');
$ti-icon-perfume: unicode('f509');
$ti-icon-perspective: unicode('eebd');
$ti-icon-perspective-off: unicode('f176');
$ti-icon-phone: unicode('eb09');
$ti-icon-phone-call: unicode('eb05');
$ti-icon-phone-calling: unicode('ec43');
$ti-icon-phone-check: unicode('ec05');
$ti-icon-phone-done: unicode('ff9e');
$ti-icon-phone-end: unicode('ff9d');
$ti-icon-phone-filled: unicode('fa49');
$ti-icon-phone-incoming: unicode('eb06');
$ti-icon-phone-off: unicode('ecf5');
$ti-icon-phone-outgoing: unicode('eb07');
$ti-icon-phone-pause: unicode('eb08');
$ti-icon-phone-plus: unicode('ec06');
$ti-icon-phone-ringing: unicode('ff9c');
$ti-icon-phone-spark: unicode('ffb1');
$ti-icon-phone-x: unicode('ec07');
$ti-icon-photo: unicode('eb0a');
$ti-icon-photo-ai: unicode('fa32');
$ti-icon-photo-bitcoin: unicode('ff31');
$ti-icon-photo-bolt: unicode('f990');
$ti-icon-photo-cancel: unicode('f35d');
$ti-icon-photo-check: unicode('f35e');
$ti-icon-photo-circle: unicode('fc4a');
$ti-icon-photo-circle-minus: unicode('fc62');
$ti-icon-photo-circle-plus: unicode('fc63');
$ti-icon-photo-code: unicode('f991');
$ti-icon-photo-cog: unicode('f992');
$ti-icon-photo-dollar: unicode('f993');
$ti-icon-photo-down: unicode('f35f');
$ti-icon-photo-edit: unicode('f360');
$ti-icon-photo-exclamation: unicode('f994');
$ti-icon-photo-filled: unicode('fa4a');
$ti-icon-photo-heart: unicode('f361');
$ti-icon-photo-hexagon: unicode('fc4b');
$ti-icon-photo-minus: unicode('f362');
$ti-icon-photo-off: unicode('ecf6');
$ti-icon-photo-pause: unicode('f995');
$ti-icon-photo-pentagon: unicode('fc4c');
$ti-icon-photo-pin: unicode('f996');
$ti-icon-photo-plus: unicode('f363');
$ti-icon-photo-question: unicode('f997');
$ti-icon-photo-scan: unicode('fca8');
$ti-icon-photo-search: unicode('f364');
$ti-icon-photo-sensor: unicode('f798');
$ti-icon-photo-sensor-2: unicode('f796');
$ti-icon-photo-sensor-3: unicode('f797');
$ti-icon-photo-share: unicode('f998');
$ti-icon-photo-shield: unicode('f365');
$ti-icon-photo-spark: unicode('ffb0');
$ti-icon-photo-square-rounded: unicode('fc4d');
$ti-icon-photo-star: unicode('f366');
$ti-icon-photo-up: unicode('f38b');
$ti-icon-photo-video: unicode('fc95');
$ti-icon-photo-x: unicode('f367');
$ti-icon-physotherapist: unicode('eebe');
$ti-icon-piano: unicode('fad3');
$ti-icon-pick: unicode('fafc');
$ti-icon-picnic-table: unicode('fed6');
$ti-icon-picture-in-picture: unicode('ed35');
$ti-icon-picture-in-picture-filled: unicode('fec1');
$ti-icon-picture-in-picture-off: unicode('ed43');
$ti-icon-picture-in-picture-on: unicode('ed44');
$ti-icon-picture-in-picture-top: unicode('efe4');
$ti-icon-picture-in-picture-top-filled: unicode('fec2');
$ti-icon-pig: unicode('ef52');
$ti-icon-pig-money: unicode('f38c');
$ti-icon-pig-off: unicode('f177');
$ti-icon-pilcrow: unicode('f5f6');
$ti-icon-pilcrow-left: unicode('fd7f');
$ti-icon-pilcrow-right: unicode('fd80');
$ti-icon-pill: unicode('ec44');
$ti-icon-pill-filled: unicode('ff07');
$ti-icon-pill-off: unicode('f178');
$ti-icon-pills: unicode('ef66');
$ti-icon-pin: unicode('ec9c');
$ti-icon-pin-end: unicode('fd5b');
$ti-icon-pin-filled: unicode('f68d');
$ti-icon-pin-invoke: unicode('fd5c');
$ti-icon-ping-pong: unicode('f38d');
$ti-icon-pinned: unicode('ed60');
$ti-icon-pinned-filled: unicode('f68e');
$ti-icon-pinned-off: unicode('ed5f');
$ti-icon-pizza: unicode('edbb');
$ti-icon-pizza-filled: unicode('10008');
$ti-icon-pizza-off: unicode('f179');
$ti-icon-placeholder: unicode('f626');
$ti-icon-plane: unicode('eb6f');
$ti-icon-plane-arrival: unicode('eb99');
$ti-icon-plane-departure: unicode('eb9a');
$ti-icon-plane-inflight: unicode('ef98');
$ti-icon-plane-off: unicode('f17a');
$ti-icon-plane-tilt: unicode('f1ed');
$ti-icon-planet: unicode('ec08');
$ti-icon-planet-off: unicode('f17b');
$ti-icon-plant: unicode('ed50');
$ti-icon-plant-2: unicode('ed7e');
$ti-icon-plant-2-off: unicode('f17c');
$ti-icon-plant-off: unicode('f17d');
$ti-icon-play-basketball: unicode('fa66');
$ti-icon-play-card: unicode('eebf');
$ti-icon-play-card-1: unicode('1005c');
$ti-icon-play-card-1-filled: unicode('10083');
$ti-icon-play-card-10: unicode('1005b');
$ti-icon-play-card-10-filled: unicode('10082');
$ti-icon-play-card-2: unicode('1005a');
$ti-icon-play-card-2-filled: unicode('10081');
$ti-icon-play-card-3: unicode('10059');
$ti-icon-play-card-3-filled: unicode('10080');
$ti-icon-play-card-4: unicode('10058');
$ti-icon-play-card-4-filled: unicode('1007f');
$ti-icon-play-card-5: unicode('10057');
$ti-icon-play-card-5-filled: unicode('1007e');
$ti-icon-play-card-6: unicode('10056');
$ti-icon-play-card-6-filled: unicode('1007d');
$ti-icon-play-card-7: unicode('10055');
$ti-icon-play-card-7-filled: unicode('1007c');
$ti-icon-play-card-8: unicode('10054');
$ti-icon-play-card-8-filled: unicode('1007b');
$ti-icon-play-card-9: unicode('10053');
$ti-icon-play-card-9-filled: unicode('1007a');
$ti-icon-play-card-a: unicode('10052');
$ti-icon-play-card-a-filled: unicode('10079');
$ti-icon-play-card-j: unicode('10051');
$ti-icon-play-card-j-filled: unicode('10078');
$ti-icon-play-card-k: unicode('10050');
$ti-icon-play-card-k-filled: unicode('10077');
$ti-icon-play-card-off: unicode('f17e');
$ti-icon-play-card-q: unicode('1004f');
$ti-icon-play-card-q-filled: unicode('10076');
$ti-icon-play-card-star: unicode('1004e');
$ti-icon-play-card-star-filled: unicode('10075');
$ti-icon-play-football: unicode('fa67');
$ti-icon-play-handball: unicode('fa68');
$ti-icon-play-volleyball: unicode('fa69');
$ti-icon-player-eject: unicode('efbc');
$ti-icon-player-eject-filled: unicode('f68f');
$ti-icon-player-pause: unicode('ed45');
$ti-icon-player-pause-filled: unicode('f690');
$ti-icon-player-play: unicode('ed46');
$ti-icon-player-play-filled: unicode('f691');
$ti-icon-player-record: unicode('ed47');
$ti-icon-player-record-filled: unicode('f692');
$ti-icon-player-skip-back: unicode('ed48');
$ti-icon-player-skip-back-filled: unicode('f693');
$ti-icon-player-skip-forward: unicode('ed49');
$ti-icon-player-skip-forward-filled: unicode('f694');
$ti-icon-player-stop: unicode('ed4a');
$ti-icon-player-stop-filled: unicode('f695');
$ti-icon-player-track-next: unicode('ed4b');
$ti-icon-player-track-next-filled: unicode('f696');
$ti-icon-player-track-prev: unicode('ed4c');
$ti-icon-player-track-prev-filled: unicode('f697');
$ti-icon-playlist: unicode('eec0');
$ti-icon-playlist-add: unicode('f008');
$ti-icon-playlist-off: unicode('f17f');
$ti-icon-playlist-x: unicode('f009');
$ti-icon-playstation-circle: unicode('f2ad');
$ti-icon-playstation-square: unicode('f2ae');
$ti-icon-playstation-triangle: unicode('f2af');
$ti-icon-playstation-x: unicode('f2b0');
$ti-icon-plug: unicode('ebd9');
$ti-icon-plug-connected: unicode('f00a');
$ti-icon-plug-connected-x: unicode('f0a0');
$ti-icon-plug-off: unicode('f180');
$ti-icon-plug-x: unicode('f0a1');
$ti-icon-plus: unicode('eb0b');
$ti-icon-plus-equal: unicode('f7ad');
$ti-icon-plus-minus: unicode('f7ae');
$ti-icon-png: unicode('f3ad');
$ti-icon-podium: unicode('f1d8');
$ti-icon-podium-off: unicode('f41b');
$ti-icon-point: unicode('eb0c');
$ti-icon-point-filled: unicode('f698');
$ti-icon-point-off: unicode('f181');
$ti-icon-pointer: unicode('f265');
$ti-icon-pointer-bolt: unicode('f999');
$ti-icon-pointer-cancel: unicode('f99a');
$ti-icon-pointer-check: unicode('f99b');
$ti-icon-pointer-code: unicode('f99c');
$ti-icon-pointer-cog: unicode('f99d');
$ti-icon-pointer-dollar: unicode('f99e');
$ti-icon-pointer-down: unicode('f99f');
$ti-icon-pointer-exclamation: unicode('f9a0');
$ti-icon-pointer-filled: unicode('fb30');
$ti-icon-pointer-heart: unicode('f9a1');
$ti-icon-pointer-minus: unicode('f9a2');
$ti-icon-pointer-off: unicode('f9a3');
$ti-icon-pointer-pause: unicode('f9a4');
$ti-icon-pointer-pin: unicode('f9a5');
$ti-icon-pointer-plus: unicode('f9a6');
$ti-icon-pointer-question: unicode('f9a7');
$ti-icon-pointer-search: unicode('f9a8');
$ti-icon-pointer-share: unicode('f9a9');
$ti-icon-pointer-star: unicode('f9aa');
$ti-icon-pointer-up: unicode('f9ab');
$ti-icon-pointer-x: unicode('f9ac');
$ti-icon-pokeball: unicode('eec1');
$ti-icon-pokeball-off: unicode('f41c');
$ti-icon-poker-chip: unicode('f515');
$ti-icon-polaroid: unicode('eec2');
$ti-icon-polaroid-filled: unicode('fa4b');
$ti-icon-polygon: unicode('efd0');
$ti-icon-polygon-off: unicode('f182');
$ti-icon-poo: unicode('f258');
$ti-icon-poo-filled: unicode('fec9');
$ti-icon-pool: unicode('ed91');
$ti-icon-pool-off: unicode('f41d');
$ti-icon-power: unicode('eb0d');
$ti-icon-pray: unicode('ecbf');
$ti-icon-premium-rights: unicode('efbd');
$ti-icon-prescription: unicode('ef99');
$ti-icon-presentation: unicode('eb70');
$ti-icon-presentation-analytics: unicode('eec3');
$ti-icon-presentation-analytics-filled: unicode('ff5d');
$ti-icon-presentation-filled: unicode('ff5c');
$ti-icon-presentation-off: unicode('f183');
$ti-icon-printer: unicode('eb0e');
$ti-icon-printer-off: unicode('f184');
$ti-icon-prism: unicode('fab1');
$ti-icon-prism-light: unicode('fea6');
$ti-icon-prism-off: unicode('faaf');
$ti-icon-prism-plus: unicode('fab0');
$ti-icon-prison: unicode('ef79');
$ti-icon-progress: unicode('fa0d');
$ti-icon-progress-alert: unicode('fa07');
$ti-icon-progress-bolt: unicode('fa08');
$ti-icon-progress-check: unicode('fa09');
$ti-icon-progress-down: unicode('fa0a');
$ti-icon-progress-help: unicode('fa0b');
$ti-icon-progress-x: unicode('fa0c');
$ti-icon-prompt: unicode('eb0f');
$ti-icon-prong: unicode('fda1');
$ti-icon-propeller: unicode('eec4');
$ti-icon-propeller-off: unicode('f185');
$ti-icon-protocol: unicode('fd81');
$ti-icon-pumpkin-scary: unicode('f587');
$ti-icon-puzzle: unicode('eb10');
$ti-icon-puzzle-2: unicode('ef83');
$ti-icon-puzzle-filled: unicode('f699');
$ti-icon-puzzle-off: unicode('f186');
$ti-icon-pyramid: unicode('eec5');
$ti-icon-pyramid-off: unicode('f187');
$ti-icon-pyramid-plus: unicode('fab2');
$ti-icon-qrcode: unicode('eb11');
$ti-icon-qrcode-off: unicode('f41e');
$ti-icon-question-mark: unicode('ec9d');
$ti-icon-quote: unicode('efbe');
$ti-icon-quote-off: unicode('f188');
$ti-icon-quotes: unicode('fb1e');
$ti-icon-radar: unicode('f017');
$ti-icon-radar-2: unicode('f016');
$ti-icon-radar-filled: unicode('fe0d');
$ti-icon-radar-off: unicode('f41f');
$ti-icon-radio: unicode('ef2d');
$ti-icon-radio-off: unicode('f420');
$ti-icon-radioactive: unicode('ecc0');
$ti-icon-radioactive-filled: unicode('f760');
$ti-icon-radioactive-off: unicode('f189');
$ti-icon-radius-bottom-left: unicode('eec6');
$ti-icon-radius-bottom-right: unicode('eec7');
$ti-icon-radius-top-left: unicode('eec8');
$ti-icon-radius-top-right: unicode('eec9');
$ti-icon-rainbow: unicode('edbc');
$ti-icon-rainbow-off: unicode('f18a');
$ti-icon-rating-12-plus: unicode('f266');
$ti-icon-rating-14-plus: unicode('f267');
$ti-icon-rating-16-plus: unicode('f268');
$ti-icon-rating-18-plus: unicode('f269');
$ti-icon-rating-21-plus: unicode('f26a');
$ti-icon-razor: unicode('f4b5');
$ti-icon-razor-electric: unicode('f4b4');
$ti-icon-receipt: unicode('edfd');
$ti-icon-receipt-2: unicode('edfa');
$ti-icon-receipt-bitcoin: unicode('fd66');
$ti-icon-receipt-dollar: unicode('fd67');
$ti-icon-receipt-euro: unicode('fd68');
$ti-icon-receipt-filled: unicode('ff06');
$ti-icon-receipt-off: unicode('edfb');
$ti-icon-receipt-pound: unicode('fd69');
$ti-icon-receipt-refund: unicode('edfc');
$ti-icon-receipt-rupee: unicode('fd82');
$ti-icon-receipt-tax: unicode('edbd');
$ti-icon-receipt-yen: unicode('fd6a');
$ti-icon-receipt-yuan: unicode('fd6b');
$ti-icon-recharging: unicode('eeca');
$ti-icon-record-mail: unicode('eb12');
$ti-icon-record-mail-off: unicode('f18b');
$ti-icon-rectangle: unicode('ed37');
$ti-icon-rectangle-filled: unicode('f69a');
$ti-icon-rectangle-rounded-bottom: unicode('faed');
$ti-icon-rectangle-rounded-top: unicode('faee');
$ti-icon-rectangle-vertical: unicode('ed36');
$ti-icon-rectangle-vertical-filled: unicode('f69b');
$ti-icon-rectangular-prism: unicode('fab5');
$ti-icon-rectangular-prism-off: unicode('fab3');
$ti-icon-rectangular-prism-plus: unicode('fab4');
$ti-icon-recycle: unicode('eb9b');
$ti-icon-recycle-off: unicode('f18c');
$ti-icon-refresh: unicode('eb13');
$ti-icon-refresh-alert: unicode('ed57');
$ti-icon-refresh-dot: unicode('efbf');
$ti-icon-refresh-off: unicode('f18d');
$ti-icon-regex: unicode('f31f');
$ti-icon-regex-off: unicode('f421');
$ti-icon-registered: unicode('eb14');
$ti-icon-relation-many-to-many: unicode('ed7f');
$ti-icon-relation-many-to-many-filled: unicode('fe0c');
$ti-icon-relation-one-to-many: unicode('ed80');
$ti-icon-relation-one-to-many-filled: unicode('fe0b');
$ti-icon-relation-one-to-one: unicode('ed81');
$ti-icon-relation-one-to-one-filled: unicode('fe0a');
$ti-icon-reload: unicode('f3ae');
$ti-icon-reorder: unicode('fc15');
$ti-icon-repeat: unicode('eb72');
$ti-icon-repeat-off: unicode('f18e');
$ti-icon-repeat-once: unicode('eb71');
$ti-icon-replace: unicode('ebc7');
$ti-icon-replace-filled: unicode('f69c');
$ti-icon-replace-off: unicode('f422');
$ti-icon-report: unicode('eece');
$ti-icon-report-analytics: unicode('eecb');
$ti-icon-report-medical: unicode('eecc');
$ti-icon-report-money: unicode('eecd');
$ti-icon-report-off: unicode('f18f');
$ti-icon-report-search: unicode('ef84');
$ti-icon-reserved-line: unicode('f9f6');
$ti-icon-resize: unicode('eecf');
$ti-icon-restore: unicode('fafd');
$ti-icon-rewind-backward-10: unicode('faba');
$ti-icon-rewind-backward-15: unicode('fabb');
$ti-icon-rewind-backward-20: unicode('fabc');
$ti-icon-rewind-backward-30: unicode('fabd');
$ti-icon-rewind-backward-40: unicode('fabe');
$ti-icon-rewind-backward-5: unicode('fabf');
$ti-icon-rewind-backward-50: unicode('fac0');
$ti-icon-rewind-backward-60: unicode('fac1');
$ti-icon-rewind-forward-10: unicode('fac2');
$ti-icon-rewind-forward-15: unicode('fac3');
$ti-icon-rewind-forward-20: unicode('fac4');
$ti-icon-rewind-forward-30: unicode('fac5');
$ti-icon-rewind-forward-40: unicode('fac6');
$ti-icon-rewind-forward-5: unicode('fac7');
$ti-icon-rewind-forward-50: unicode('fac8');
$ti-icon-rewind-forward-60: unicode('fac9');
$ti-icon-ribbon-health: unicode('f58e');
$ti-icon-rings: unicode('fa6a');
$ti-icon-ripple: unicode('ed82');
$ti-icon-ripple-off: unicode('f190');
$ti-icon-road: unicode('f018');
$ti-icon-road-off: unicode('f191');
$ti-icon-road-sign: unicode('ecdd');
$ti-icon-robot: unicode('f00b');
$ti-icon-robot-face: unicode('fcbe');
$ti-icon-robot-off: unicode('f192');
$ti-icon-rocket: unicode('ec45');
$ti-icon-rocket-off: unicode('f193');
$ti-icon-roller-skating: unicode('efd1');
$ti-icon-rollercoaster: unicode('f0a2');
$ti-icon-rollercoaster-off: unicode('f423');
$ti-icon-rosette: unicode('f599');
$ti-icon-rosette-discount: unicode('ee7c');
$ti-icon-rosette-discount-check: unicode('f1f8');
$ti-icon-rosette-discount-check-filled: unicode('f746');
$ti-icon-rosette-discount-check-off: unicode('ff10');
$ti-icon-rosette-discount-filled: unicode('ff05');
$ti-icon-rosette-discount-off: unicode('f3e6');
$ti-icon-rosette-filled: unicode('f69d');
$ti-icon-rosette-number-0: unicode('f58f');
$ti-icon-rosette-number-1: unicode('f590');
$ti-icon-rosette-number-2: unicode('f591');
$ti-icon-rosette-number-3: unicode('f592');
$ti-icon-rosette-number-4: unicode('f593');
$ti-icon-rosette-number-5: unicode('f594');
$ti-icon-rosette-number-6: unicode('f595');
$ti-icon-rosette-number-7: unicode('f596');
$ti-icon-rosette-number-8: unicode('f597');
$ti-icon-rosette-number-9: unicode('f598');
$ti-icon-rotate: unicode('eb16');
$ti-icon-rotate-2: unicode('ebb4');
$ti-icon-rotate-360: unicode('ef85');
$ti-icon-rotate-3d: unicode('f020');
$ti-icon-rotate-clockwise: unicode('eb15');
$ti-icon-rotate-clockwise-2: unicode('ebb5');
$ti-icon-rotate-dot: unicode('efe5');
$ti-icon-rotate-rectangle: unicode('ec15');
$ti-icon-route: unicode('eb17');
$ti-icon-route-2: unicode('f4b6');
$ti-icon-route-alt-left: unicode('fca9');
$ti-icon-route-alt-right: unicode('fcaa');
$ti-icon-route-off: unicode('f194');
$ti-icon-route-scan: unicode('fcbf');
$ti-icon-route-square: unicode('fcac');
$ti-icon-route-square-2: unicode('fcab');
$ti-icon-route-x: unicode('fcae');
$ti-icon-route-x-2: unicode('fcad');
$ti-icon-router: unicode('eb18');
$ti-icon-router-off: unicode('f424');
$ti-icon-row-insert-bottom: unicode('eed0');
$ti-icon-row-insert-top: unicode('eed1');
$ti-icon-row-remove: unicode('fafe');
$ti-icon-rss: unicode('eb19');
$ti-icon-rubber-stamp: unicode('f5ab');
$ti-icon-rubber-stamp-off: unicode('f5aa');
$ti-icon-ruler: unicode('eb1a');
$ti-icon-ruler-2: unicode('eed2');
$ti-icon-ruler-2-off: unicode('f195');
$ti-icon-ruler-3: unicode('f290');
$ti-icon-ruler-measure: unicode('f291');
$ti-icon-ruler-measure-2: unicode('ff0f');
$ti-icon-ruler-off: unicode('f196');
$ti-icon-run: unicode('ec82');
$ti-icon-rv-truck: unicode('fcc0');
$ti-icon-s-turn-down: unicode('f516');
$ti-icon-s-turn-left: unicode('f517');
$ti-icon-s-turn-right: unicode('f518');
$ti-icon-s-turn-up: unicode('f519');
$ti-icon-sailboat: unicode('ec83');
$ti-icon-sailboat-2: unicode('f5f7');
$ti-icon-sailboat-off: unicode('f425');
$ti-icon-salad: unicode('f50a');
$ti-icon-salad-filled: unicode('10007');
$ti-icon-salt: unicode('ef16');
$ti-icon-sandbox: unicode('fd6c');
$ti-icon-satellite: unicode('eed3');
$ti-icon-satellite-off: unicode('f197');
$ti-icon-sausage: unicode('ef17');
$ti-icon-scale: unicode('ebc2');
$ti-icon-scale-off: unicode('f198');
$ti-icon-scale-outline: unicode('ef53');
$ti-icon-scale-outline-off: unicode('f199');
$ti-icon-scan: unicode('ebc8');
$ti-icon-scan-eye: unicode('f1ff');
$ti-icon-scan-position: unicode('fdac');
$ti-icon-schema: unicode('f200');
$ti-icon-schema-off: unicode('f426');
$ti-icon-school: unicode('ecf7');
$ti-icon-school-bell: unicode('f64a');
$ti-icon-school-off: unicode('f19a');
$ti-icon-scissors: unicode('eb1b');
$ti-icon-scissors-off: unicode('f19b');
$ti-icon-scooter: unicode('ec6c');
$ti-icon-scooter-electric: unicode('ecc1');
$ti-icon-scoreboard: unicode('fa6b');
$ti-icon-screen-share: unicode('ed18');
$ti-icon-screen-share-off: unicode('ed17');
$ti-icon-screenshot: unicode('f201');
$ti-icon-scribble: unicode('f0a3');
$ti-icon-scribble-off: unicode('f427');
$ti-icon-script: unicode('f2da');
$ti-icon-script-minus: unicode('f2d7');
$ti-icon-script-plus: unicode('f2d8');
$ti-icon-script-x: unicode('f2d9');
$ti-icon-scuba-diving: unicode('fd4e');
$ti-icon-scuba-diving-tank: unicode('fefa');
$ti-icon-scuba-diving-tank-filled: unicode('ff04');
$ti-icon-scuba-mask: unicode('eed4');
$ti-icon-scuba-mask-off: unicode('f428');
$ti-icon-sdk: unicode('f3af');
$ti-icon-search: unicode('eb1c');
$ti-icon-search-off: unicode('f19c');
$ti-icon-section: unicode('eed5');
$ti-icon-section-filled: unicode('fe09');
$ti-icon-section-sign: unicode('f019');
$ti-icon-seeding: unicode('ed51');
$ti-icon-seeding-filled: unicode('10006');
$ti-icon-seeding-off: unicode('f19d');
$ti-icon-select: unicode('ec9e');
$ti-icon-select-all: unicode('f9f7');
$ti-icon-selector: unicode('eb1d');
$ti-icon-send: unicode('eb1e');
$ti-icon-send-2: unicode('fd5d');
$ti-icon-send-off: unicode('f429');
$ti-icon-seo: unicode('f26b');
$ti-icon-separator: unicode('ebda');
$ti-icon-separator-horizontal: unicode('ec79');
$ti-icon-separator-vertical: unicode('ec7a');
$ti-icon-server: unicode('eb1f');
$ti-icon-server-2: unicode('f07c');
$ti-icon-server-bolt: unicode('f320');
$ti-icon-server-cog: unicode('f321');
$ti-icon-server-off: unicode('f19e');
$ti-icon-server-spark: unicode('ffaf');
$ti-icon-servicemark: unicode('ec09');
$ti-icon-settings: unicode('eb20');
$ti-icon-settings-2: unicode('f5ac');
$ti-icon-settings-automation: unicode('eed6');
$ti-icon-settings-bolt: unicode('f9ad');
$ti-icon-settings-cancel: unicode('f9ae');
$ti-icon-settings-check: unicode('f9af');
$ti-icon-settings-code: unicode('f9b0');
$ti-icon-settings-cog: unicode('f9b1');
$ti-icon-settings-dollar: unicode('f9b2');
$ti-icon-settings-down: unicode('f9b3');
$ti-icon-settings-exclamation: unicode('f9b4');
$ti-icon-settings-filled: unicode('f69e');
$ti-icon-settings-heart: unicode('f9b5');
$ti-icon-settings-minus: unicode('f9b6');
$ti-icon-settings-off: unicode('f19f');
$ti-icon-settings-pause: unicode('f9b7');
$ti-icon-settings-pin: unicode('f9b8');
$ti-icon-settings-plus: unicode('f9b9');
$ti-icon-settings-question: unicode('f9ba');
$ti-icon-settings-search: unicode('f9bb');
$ti-icon-settings-share: unicode('f9bc');
$ti-icon-settings-spark: unicode('ffae');
$ti-icon-settings-star: unicode('f9bd');
$ti-icon-settings-up: unicode('f9be');
$ti-icon-settings-x: unicode('f9bf');
$ti-icon-shadow: unicode('eed8');
$ti-icon-shadow-off: unicode('eed7');
$ti-icon-shape: unicode('eb9c');
$ti-icon-shape-2: unicode('eed9');
$ti-icon-shape-3: unicode('eeda');
$ti-icon-shape-off: unicode('f1a0');
$ti-icon-share: unicode('eb21');
$ti-icon-share-2: unicode('f799');
$ti-icon-share-3: unicode('f7bd');
$ti-icon-share-off: unicode('f1a1');
$ti-icon-shareplay: unicode('fea5');
$ti-icon-shield: unicode('eb24');
$ti-icon-shield-bolt: unicode('f9c0');
$ti-icon-shield-cancel: unicode('f9c1');
$ti-icon-shield-check: unicode('eb22');
$ti-icon-shield-check-filled: unicode('f761');
$ti-icon-shield-checkered: unicode('ef9a');
$ti-icon-shield-checkered-filled: unicode('f762');
$ti-icon-shield-chevron: unicode('ef9b');
$ti-icon-shield-code: unicode('f9c2');
$ti-icon-shield-cog: unicode('f9c3');
$ti-icon-shield-dollar: unicode('f9c4');
$ti-icon-shield-down: unicode('f9c5');
$ti-icon-shield-exclamation: unicode('f9c6');
$ti-icon-shield-filled: unicode('f69f');
$ti-icon-shield-half: unicode('f358');
$ti-icon-shield-half-filled: unicode('f357');
$ti-icon-shield-heart: unicode('f9c7');
$ti-icon-shield-lock: unicode('ed58');
$ti-icon-shield-lock-filled: unicode('f763');
$ti-icon-shield-minus: unicode('f9c8');
$ti-icon-shield-off: unicode('ecf8');
$ti-icon-shield-pause: unicode('f9c9');
$ti-icon-shield-pin: unicode('f9ca');
$ti-icon-shield-plus: unicode('f9cb');
$ti-icon-shield-question: unicode('f9cc');
$ti-icon-shield-search: unicode('f9cd');
$ti-icon-shield-share: unicode('f9ce');
$ti-icon-shield-star: unicode('f9cf');
$ti-icon-shield-up: unicode('f9d0');
$ti-icon-shield-x: unicode('eb23');
$ti-icon-ship: unicode('ec84');
$ti-icon-ship-off: unicode('f42a');
$ti-icon-shirt: unicode('ec0a');
$ti-icon-shirt-filled: unicode('f6a0');
$ti-icon-shirt-off: unicode('f1a2');
$ti-icon-shirt-sport: unicode('f26c');
$ti-icon-shoe: unicode('efd2');
$ti-icon-shoe-off: unicode('f1a4');
$ti-icon-shopping-bag: unicode('f5f8');
$ti-icon-shopping-bag-check: unicode('fc16');
$ti-icon-shopping-bag-discount: unicode('fc17');
$ti-icon-shopping-bag-edit: unicode('fc18');
$ti-icon-shopping-bag-exclamation: unicode('fc19');
$ti-icon-shopping-bag-heart: unicode('fda2');
$ti-icon-shopping-bag-minus: unicode('fc1a');
$ti-icon-shopping-bag-plus: unicode('fc1b');
$ti-icon-shopping-bag-search: unicode('fc1c');
$ti-icon-shopping-bag-x: unicode('fc1d');
$ti-icon-shopping-cart: unicode('eb25');
$ti-icon-shopping-cart-bolt: unicode('fb57');
$ti-icon-shopping-cart-cancel: unicode('fb58');
$ti-icon-shopping-cart-check: unicode('fb59');
$ti-icon-shopping-cart-code: unicode('fb5a');
$ti-icon-shopping-cart-cog: unicode('fb5b');
$ti-icon-shopping-cart-copy: unicode('fb5c');
$ti-icon-shopping-cart-discount: unicode('fb5d');
$ti-icon-shopping-cart-dollar: unicode('fb5e');
$ti-icon-shopping-cart-down: unicode('fb5f');
$ti-icon-shopping-cart-exclamation: unicode('fb60');
$ti-icon-shopping-cart-filled: unicode('fc3f');
$ti-icon-shopping-cart-heart: unicode('fb61');
$ti-icon-shopping-cart-minus: unicode('fb62');
$ti-icon-shopping-cart-off: unicode('eedc');
$ti-icon-shopping-cart-pause: unicode('fb63');
$ti-icon-shopping-cart-pin: unicode('fb64');
$ti-icon-shopping-cart-plus: unicode('fb65');
$ti-icon-shopping-cart-question: unicode('fb66');
$ti-icon-shopping-cart-search: unicode('fb67');
$ti-icon-shopping-cart-share: unicode('fb68');
$ti-icon-shopping-cart-star: unicode('fb69');
$ti-icon-shopping-cart-up: unicode('fb6a');
$ti-icon-shopping-cart-x: unicode('fb6b');
$ti-icon-shovel: unicode('f1d9');
$ti-icon-shovel-pitchforks: unicode('fd3a');
$ti-icon-shredder: unicode('eedf');
$ti-icon-sign-left: unicode('f06b');
$ti-icon-sign-left-filled: unicode('f6a1');
$ti-icon-sign-right: unicode('f06c');
$ti-icon-sign-right-filled: unicode('f6a2');
$ti-icon-signal-2g: unicode('f79a');
$ti-icon-signal-3g: unicode('f1ee');
$ti-icon-signal-4g: unicode('f1ef');
$ti-icon-signal-4g-plus: unicode('f259');
$ti-icon-signal-5g: unicode('f1f0');
$ti-icon-signal-6g: unicode('f9f8');
$ti-icon-signal-e: unicode('f9f9');
$ti-icon-signal-g: unicode('f9fa');
$ti-icon-signal-h: unicode('f9fc');
$ti-icon-signal-h-plus: unicode('f9fb');
$ti-icon-signal-lte: unicode('f9fd');
$ti-icon-signature: unicode('eee0');
$ti-icon-signature-off: unicode('f1a5');
$ti-icon-sitemap: unicode('eb9d');
$ti-icon-sitemap-filled: unicode('1006b');
$ti-icon-sitemap-off: unicode('f1a6');
$ti-icon-skateboard: unicode('ecc2');
$ti-icon-skateboard-off: unicode('f42b');
$ti-icon-skateboarding: unicode('faca');
$ti-icon-skew-x: unicode('fd3b');
$ti-icon-skew-y: unicode('fd3c');
$ti-icon-ski-jumping: unicode('fa6c');
$ti-icon-skull: unicode('f292');
$ti-icon-slash: unicode('f4f9');
$ti-icon-slashes: unicode('f588');
$ti-icon-sleigh: unicode('ef9c');
$ti-icon-slice: unicode('ebdb');
$ti-icon-slideshow: unicode('ebc9');
$ti-icon-smart-home: unicode('ecde');
$ti-icon-smart-home-off: unicode('f1a7');
$ti-icon-smoking: unicode('ecc4');
$ti-icon-smoking-no: unicode('ecc3');
$ti-icon-snowboarding: unicode('fd4f');
$ti-icon-snowflake: unicode('ec0b');
$ti-icon-snowflake-off: unicode('f1a8');
$ti-icon-snowman: unicode('f26d');
$ti-icon-soccer-field: unicode('ed92');
$ti-icon-social: unicode('ebec');
$ti-icon-social-off: unicode('f1a9');
$ti-icon-sock: unicode('eee1');
$ti-icon-sofa: unicode('efaf');
$ti-icon-sofa-off: unicode('f42c');
$ti-icon-solar-electricity: unicode('fcc1');
$ti-icon-solar-panel: unicode('f7bf');
$ti-icon-solar-panel-2: unicode('f7be');
$ti-icon-sort-0-9: unicode('f54d');
$ti-icon-sort-9-0: unicode('f54e');
$ti-icon-sort-a-z: unicode('f54f');
$ti-icon-sort-ascending: unicode('eb26');
$ti-icon-sort-ascending-2: unicode('eee2');
$ti-icon-sort-ascending-2-filled: unicode('ff5b');
$ti-icon-sort-ascending-letters: unicode('ef18');
$ti-icon-sort-ascending-numbers: unicode('ef19');
$ti-icon-sort-ascending-shapes: unicode('fd94');
$ti-icon-sort-ascending-shapes-filled: unicode('ff5a');
$ti-icon-sort-ascending-small-big: unicode('fd95');
$ti-icon-sort-descending: unicode('eb27');
$ti-icon-sort-descending-2: unicode('eee3');
$ti-icon-sort-descending-2-filled: unicode('ff59');
$ti-icon-sort-descending-letters: unicode('ef1a');
$ti-icon-sort-descending-numbers: unicode('ef1b');
$ti-icon-sort-descending-shapes: unicode('fd97');
$ti-icon-sort-descending-shapes-filled: unicode('ff58');
$ti-icon-sort-descending-small-big: unicode('fd96');
$ti-icon-sort-z-a: unicode('f550');
$ti-icon-sos: unicode('f24a');
$ti-icon-soup: unicode('ef2e');
$ti-icon-soup-filled: unicode('fe08');
$ti-icon-soup-off: unicode('f42d');
$ti-icon-source-code: unicode('f4a2');
$ti-icon-space: unicode('ec0c');
$ti-icon-space-off: unicode('f1aa');
$ti-icon-spaces: unicode('fea4');
$ti-icon-spacing-horizontal: unicode('ef54');
$ti-icon-spacing-vertical: unicode('ef55');
$ti-icon-spade: unicode('effa');
$ti-icon-spade-filled: unicode('f6a3');
$ti-icon-sparkles: unicode('f6d7');
$ti-icon-speakerphone: unicode('ed61');
$ti-icon-speedboat: unicode('ed93');
$ti-icon-sphere: unicode('fab8');
$ti-icon-sphere-off: unicode('fab6');
$ti-icon-sphere-plus: unicode('fab7');
$ti-icon-spider: unicode('f293');
$ti-icon-spiral: unicode('f294');
$ti-icon-spiral-off: unicode('f42e');
$ti-icon-sport-billard: unicode('eee4');
$ti-icon-spray: unicode('f50b');
$ti-icon-spy: unicode('f227');
$ti-icon-spy-off: unicode('f42f');
$ti-icon-sql: unicode('f7c0');
$ti-icon-square: unicode('eb2c');
$ti-icon-square-arrow-down: unicode('f4b7');
$ti-icon-square-arrow-down-filled: unicode('fb31');
$ti-icon-square-arrow-left: unicode('f4b8');
$ti-icon-square-arrow-left-filled: unicode('fb32');
$ti-icon-square-arrow-right: unicode('f4b9');
$ti-icon-square-arrow-right-filled: unicode('fb33');
$ti-icon-square-arrow-up: unicode('f4ba');
$ti-icon-square-arrow-up-filled: unicode('fb34');
$ti-icon-square-asterisk: unicode('f01a');
$ti-icon-square-asterisk-filled: unicode('fb35');
$ti-icon-square-check: unicode('eb28');
$ti-icon-square-check-filled: unicode('f76d');
$ti-icon-square-chevron-down: unicode('f627');
$ti-icon-square-chevron-down-filled: unicode('fb36');
$ti-icon-square-chevron-left: unicode('f628');
$ti-icon-square-chevron-left-filled: unicode('fb37');
$ti-icon-square-chevron-right: unicode('f629');
$ti-icon-square-chevron-right-filled: unicode('fb38');
$ti-icon-square-chevron-up: unicode('f62a');
$ti-icon-square-chevron-up-filled: unicode('fb39');
$ti-icon-square-chevrons-down: unicode('f64b');
$ti-icon-square-chevrons-down-filled: unicode('fb3a');
$ti-icon-square-chevrons-left: unicode('f64c');
$ti-icon-square-chevrons-left-filled: unicode('fb3b');
$ti-icon-square-chevrons-right: unicode('f64d');
$ti-icon-square-chevrons-right-filled: unicode('fb3c');
$ti-icon-square-chevrons-up: unicode('f64e');
$ti-icon-square-chevrons-up-filled: unicode('fb3d');
$ti-icon-square-dot: unicode('ed59');
$ti-icon-square-dot-filled: unicode('fb3e');
$ti-icon-square-f0: unicode('f526');
$ti-icon-square-f0-filled: unicode('f76e');
$ti-icon-square-f1: unicode('f527');
$ti-icon-square-f1-filled: unicode('f76f');
$ti-icon-square-f2: unicode('f528');
$ti-icon-square-f2-filled: unicode('f770');
$ti-icon-square-f3: unicode('f529');
$ti-icon-square-f3-filled: unicode('f771');
$ti-icon-square-f4: unicode('f52a');
$ti-icon-square-f4-filled: unicode('f772');
$ti-icon-square-f5: unicode('f52b');
$ti-icon-square-f5-filled: unicode('f773');
$ti-icon-square-f6: unicode('f52c');
$ti-icon-square-f6-filled: unicode('f774');
$ti-icon-square-f7: unicode('f52d');
$ti-icon-square-f7-filled: unicode('f775');
$ti-icon-square-f8: unicode('f52e');
$ti-icon-square-f8-filled: unicode('f776');
$ti-icon-square-f9: unicode('f52f');
$ti-icon-square-f9-filled: unicode('f777');
$ti-icon-square-filled: unicode('fc40');
$ti-icon-square-forbid: unicode('ed5b');
$ti-icon-square-forbid-2: unicode('ed5a');
$ti-icon-square-half: unicode('effb');
$ti-icon-square-key: unicode('f638');
$ti-icon-square-letter-a: unicode('f47c');
$ti-icon-square-letter-a-filled: unicode('fe07');
$ti-icon-square-letter-b: unicode('f47d');
$ti-icon-square-letter-b-filled: unicode('fe06');
$ti-icon-square-letter-c: unicode('f47e');
$ti-icon-square-letter-c-filled: unicode('fe05');
$ti-icon-square-letter-d: unicode('f47f');
$ti-icon-square-letter-d-filled: unicode('fe04');
$ti-icon-square-letter-e: unicode('f480');
$ti-icon-square-letter-e-filled: unicode('fe03');
$ti-icon-square-letter-f: unicode('f481');
$ti-icon-square-letter-f-filled: unicode('fe02');
$ti-icon-square-letter-g: unicode('f482');
$ti-icon-square-letter-g-filled: unicode('fe01');
$ti-icon-square-letter-h: unicode('f483');
$ti-icon-square-letter-h-filled: unicode('fe00');
$ti-icon-square-letter-i: unicode('f484');
$ti-icon-square-letter-i-filled: unicode('fdff');
$ti-icon-square-letter-j: unicode('f485');
$ti-icon-square-letter-j-filled: unicode('fdfe');
$ti-icon-square-letter-k: unicode('f486');
$ti-icon-square-letter-k-filled: unicode('fdfd');
$ti-icon-square-letter-l: unicode('f487');
$ti-icon-square-letter-l-filled: unicode('fdfc');
$ti-icon-square-letter-m: unicode('f488');
$ti-icon-square-letter-m-filled: unicode('fdfb');
$ti-icon-square-letter-n: unicode('f489');
$ti-icon-square-letter-n-filled: unicode('fdfa');
$ti-icon-square-letter-o: unicode('f48a');
$ti-icon-square-letter-o-filled: unicode('fdf9');
$ti-icon-square-letter-p: unicode('f48b');
$ti-icon-square-letter-p-filled: unicode('fdf8');
$ti-icon-square-letter-q: unicode('f48c');
$ti-icon-square-letter-q-filled: unicode('fdf7');
$ti-icon-square-letter-r: unicode('f48d');
$ti-icon-square-letter-r-filled: unicode('fdf6');
$ti-icon-square-letter-s: unicode('f48e');
$ti-icon-square-letter-s-filled: unicode('fdf5');
$ti-icon-square-letter-t: unicode('f48f');
$ti-icon-square-letter-t-filled: unicode('fdf4');
$ti-icon-square-letter-u: unicode('f490');
$ti-icon-square-letter-u-filled: unicode('fdf3');
$ti-icon-square-letter-v: unicode('f4bb');
$ti-icon-square-letter-v-filled: unicode('fdf2');
$ti-icon-square-letter-w: unicode('f491');
$ti-icon-square-letter-w-filled: unicode('fdf1');
$ti-icon-square-letter-x: unicode('f4bc');
$ti-icon-square-letter-x-filled: unicode('fdf0');
$ti-icon-square-letter-y: unicode('f492');
$ti-icon-square-letter-y-filled: unicode('fdef');
$ti-icon-square-letter-z: unicode('f493');
$ti-icon-square-letter-z-filled: unicode('fdee');
$ti-icon-square-minus: unicode('eb29');
$ti-icon-square-minus-filled: unicode('fb3f');
$ti-icon-square-number-0: unicode('eee5');
$ti-icon-square-number-0-filled: unicode('f764');
$ti-icon-square-number-1: unicode('eee6');
$ti-icon-square-number-1-filled: unicode('f765');
$ti-icon-square-number-2: unicode('eee7');
$ti-icon-square-number-2-filled: unicode('f7fa');
$ti-icon-square-number-3: unicode('eee8');
$ti-icon-square-number-3-filled: unicode('f766');
$ti-icon-square-number-4: unicode('eee9');
$ti-icon-square-number-4-filled: unicode('f767');
$ti-icon-square-number-5: unicode('eeea');
$ti-icon-square-number-5-filled: unicode('f768');
$ti-icon-square-number-6: unicode('eeeb');
$ti-icon-square-number-6-filled: unicode('f769');
$ti-icon-square-number-7: unicode('eeec');
$ti-icon-square-number-7-filled: unicode('f76a');
$ti-icon-square-number-8: unicode('eeed');
$ti-icon-square-number-8-filled: unicode('f76b');
$ti-icon-square-number-9: unicode('eeee');
$ti-icon-square-number-9-filled: unicode('f76c');
$ti-icon-square-off: unicode('eeef');
$ti-icon-square-percentage: unicode('fd83');
$ti-icon-square-plus: unicode('eb2a');
$ti-icon-square-plus-2: unicode('fc96');
$ti-icon-square-root: unicode('eef1');
$ti-icon-square-root-2: unicode('eef0');
$ti-icon-square-rotated: unicode('ecdf');
$ti-icon-square-rotated-filled: unicode('f6a4');
$ti-icon-square-rotated-forbid: unicode('f01c');
$ti-icon-square-rotated-forbid-2: unicode('f01b');
$ti-icon-square-rotated-off: unicode('eef2');
$ti-icon-square-rounded: unicode('f59a');
$ti-icon-square-rounded-arrow-down: unicode('f639');
$ti-icon-square-rounded-arrow-down-filled: unicode('f6db');
$ti-icon-square-rounded-arrow-left: unicode('f63a');
$ti-icon-square-rounded-arrow-left-filled: unicode('f6dc');
$ti-icon-square-rounded-arrow-right: unicode('f63b');
$ti-icon-square-rounded-arrow-right-filled: unicode('f6dd');
$ti-icon-square-rounded-arrow-up: unicode('f63c');
$ti-icon-square-rounded-arrow-up-filled: unicode('f6de');
$ti-icon-square-rounded-check: unicode('f63d');
$ti-icon-square-rounded-check-filled: unicode('f6df');
$ti-icon-square-rounded-chevron-down: unicode('f62b');
$ti-icon-square-rounded-chevron-down-filled: unicode('f6e0');
$ti-icon-square-rounded-chevron-left: unicode('f62c');
$ti-icon-square-rounded-chevron-left-filled: unicode('f6e1');
$ti-icon-square-rounded-chevron-right: unicode('f62d');
$ti-icon-square-rounded-chevron-right-filled: unicode('f6e2');
$ti-icon-square-rounded-chevron-up: unicode('f62e');
$ti-icon-square-rounded-chevron-up-filled: unicode('f6e3');
$ti-icon-square-rounded-chevrons-down: unicode('f64f');
$ti-icon-square-rounded-chevrons-down-filled: unicode('f6e4');
$ti-icon-square-rounded-chevrons-left: unicode('f650');
$ti-icon-square-rounded-chevrons-left-filled: unicode('f6e5');
$ti-icon-square-rounded-chevrons-right: unicode('f651');
$ti-icon-square-rounded-chevrons-right-filled: unicode('f6e6');
$ti-icon-square-rounded-chevrons-up: unicode('f652');
$ti-icon-square-rounded-chevrons-up-filled: unicode('f6e7');
$ti-icon-square-rounded-filled: unicode('f6a5');
$ti-icon-square-rounded-letter-a: unicode('f5ae');
$ti-icon-square-rounded-letter-a-filled: unicode('fded');
$ti-icon-square-rounded-letter-b: unicode('f5af');
$ti-icon-square-rounded-letter-b-filled: unicode('fdec');
$ti-icon-square-rounded-letter-c: unicode('f5b0');
$ti-icon-square-rounded-letter-c-filled: unicode('fdeb');
$ti-icon-square-rounded-letter-d: unicode('f5b1');
$ti-icon-square-rounded-letter-d-filled: unicode('fdea');
$ti-icon-square-rounded-letter-e: unicode('f5b2');
$ti-icon-square-rounded-letter-e-filled: unicode('fde9');
$ti-icon-square-rounded-letter-f: unicode('f5b3');
$ti-icon-square-rounded-letter-f-filled: unicode('fde8');
$ti-icon-square-rounded-letter-g: unicode('f5b4');
$ti-icon-square-rounded-letter-g-filled: unicode('fde7');
$ti-icon-square-rounded-letter-h: unicode('f5b5');
$ti-icon-square-rounded-letter-h-filled: unicode('fde6');
$ti-icon-square-rounded-letter-i: unicode('f5b6');
$ti-icon-square-rounded-letter-i-filled: unicode('fde5');
$ti-icon-square-rounded-letter-j: unicode('f5b7');
$ti-icon-square-rounded-letter-j-filled: unicode('fde4');
$ti-icon-square-rounded-letter-k: unicode('f5b8');
$ti-icon-square-rounded-letter-k-filled: unicode('fde3');
$ti-icon-square-rounded-letter-l: unicode('f5b9');
$ti-icon-square-rounded-letter-l-filled: unicode('fde2');
$ti-icon-square-rounded-letter-m: unicode('f5ba');
$ti-icon-square-rounded-letter-m-filled: unicode('fde1');
$ti-icon-square-rounded-letter-n: unicode('f5bb');
$ti-icon-square-rounded-letter-n-filled: unicode('fde0');
$ti-icon-square-rounded-letter-o: unicode('f5bc');
$ti-icon-square-rounded-letter-o-filled: unicode('fddf');
$ti-icon-square-rounded-letter-p: unicode('f5bd');
$ti-icon-square-rounded-letter-p-filled: unicode('fdde');
$ti-icon-square-rounded-letter-q: unicode('f5be');
$ti-icon-square-rounded-letter-q-filled: unicode('fddd');
$ti-icon-square-rounded-letter-r: unicode('f5bf');
$ti-icon-square-rounded-letter-r-filled: unicode('fddc');
$ti-icon-square-rounded-letter-s: unicode('f5c0');
$ti-icon-square-rounded-letter-s-filled: unicode('fddb');
$ti-icon-square-rounded-letter-t: unicode('f5c1');
$ti-icon-square-rounded-letter-t-filled: unicode('fdda');
$ti-icon-square-rounded-letter-u: unicode('f5c2');
$ti-icon-square-rounded-letter-u-filled: unicode('fdd9');
$ti-icon-square-rounded-letter-v: unicode('f5c3');
$ti-icon-square-rounded-letter-v-filled: unicode('fdd8');
$ti-icon-square-rounded-letter-w: unicode('f5c4');
$ti-icon-square-rounded-letter-w-filled: unicode('fdd7');
$ti-icon-square-rounded-letter-x: unicode('f5c5');
$ti-icon-square-rounded-letter-x-filled: unicode('fdd6');
$ti-icon-square-rounded-letter-y: unicode('f5c6');
$ti-icon-square-rounded-letter-y-filled: unicode('fdd5');
$ti-icon-square-rounded-letter-z: unicode('f5c7');
$ti-icon-square-rounded-letter-z-filled: unicode('fdd4');
$ti-icon-square-rounded-minus: unicode('f63e');
$ti-icon-square-rounded-minus-2: unicode('fc97');
$ti-icon-square-rounded-minus-filled: unicode('fb40');
$ti-icon-square-rounded-number-0: unicode('f5c8');
$ti-icon-square-rounded-number-0-filled: unicode('f778');
$ti-icon-square-rounded-number-1: unicode('f5c9');
$ti-icon-square-rounded-number-1-filled: unicode('f779');
$ti-icon-square-rounded-number-2: unicode('f5ca');
$ti-icon-square-rounded-number-2-filled: unicode('f77a');
$ti-icon-square-rounded-number-3: unicode('f5cb');
$ti-icon-square-rounded-number-3-filled: unicode('f77b');
$ti-icon-square-rounded-number-4: unicode('f5cc');
$ti-icon-square-rounded-number-4-filled: unicode('f77c');
$ti-icon-square-rounded-number-5: unicode('f5cd');
$ti-icon-square-rounded-number-5-filled: unicode('f77d');
$ti-icon-square-rounded-number-6: unicode('f5ce');
$ti-icon-square-rounded-number-6-filled: unicode('f77e');
$ti-icon-square-rounded-number-7: unicode('f5cf');
$ti-icon-square-rounded-number-7-filled: unicode('f77f');
$ti-icon-square-rounded-number-8: unicode('f5d0');
$ti-icon-square-rounded-number-8-filled: unicode('f780');
$ti-icon-square-rounded-number-9: unicode('f5d1');
$ti-icon-square-rounded-number-9-filled: unicode('f781');
$ti-icon-square-rounded-percentage: unicode('fd84');
$ti-icon-square-rounded-plus: unicode('f63f');
$ti-icon-square-rounded-plus-2: unicode('fc98');
$ti-icon-square-rounded-plus-filled: unicode('f6e8');
$ti-icon-square-rounded-x: unicode('f640');
$ti-icon-square-rounded-x-filled: unicode('f6e9');
$ti-icon-square-toggle: unicode('eef4');
$ti-icon-square-toggle-horizontal: unicode('eef3');
$ti-icon-square-x: unicode('eb2b');
$ti-icon-square-x-filled: unicode('fb41');
$ti-icon-squares: unicode('eef6');
$ti-icon-squares-diagonal: unicode('eef5');
$ti-icon-squares-filled: unicode('fe9f');
$ti-icon-squares-selected: unicode('fea3');
$ti-icon-stack: unicode('eb2d');
$ti-icon-stack-2: unicode('eef7');
$ti-icon-stack-2-filled: unicode('fdd3');
$ti-icon-stack-3: unicode('ef9d');
$ti-icon-stack-3-filled: unicode('fdd2');
$ti-icon-stack-back: unicode('fd26');
$ti-icon-stack-backward: unicode('fd27');
$ti-icon-stack-filled: unicode('fdd1');
$ti-icon-stack-forward: unicode('fd28');
$ti-icon-stack-front: unicode('fd29');
$ti-icon-stack-middle: unicode('fd2a');
$ti-icon-stack-pop: unicode('f234');
$ti-icon-stack-push: unicode('f235');
$ti-icon-stairs: unicode('eca6');
$ti-icon-stairs-down: unicode('eca4');
$ti-icon-stairs-up: unicode('eca5');
$ti-icon-star: unicode('eb2e');
$ti-icon-star-filled: unicode('f6a6');
$ti-icon-star-half: unicode('ed19');
$ti-icon-star-half-filled: unicode('f6a7');
$ti-icon-star-off: unicode('ed62');
$ti-icon-stars: unicode('ed38');
$ti-icon-stars-filled: unicode('f6a8');
$ti-icon-stars-off: unicode('f430');
$ti-icon-status-change: unicode('f3b0');
$ti-icon-steam: unicode('f24b');
$ti-icon-steering-wheel: unicode('ec7b');
$ti-icon-steering-wheel-filled: unicode('ff03');
$ti-icon-steering-wheel-off: unicode('f431');
$ti-icon-step-into: unicode('ece0');
$ti-icon-step-out: unicode('ece1');
$ti-icon-stereo-glasses: unicode('f4cb');
$ti-icon-stethoscope: unicode('edbe');
$ti-icon-stethoscope-off: unicode('f432');
$ti-icon-sticker: unicode('eb2f');
$ti-icon-sticker-2: unicode('fd3d');
$ti-icon-stopwatch: unicode('ff9b');
$ti-icon-storm: unicode('f24c');
$ti-icon-storm-off: unicode('f433');
$ti-icon-stretching: unicode('f2db');
$ti-icon-stretching-2: unicode('fa6d');
$ti-icon-strikethrough: unicode('eb9e');
$ti-icon-submarine: unicode('ed94');
$ti-icon-subscript: unicode('eb9f');
$ti-icon-subtask: unicode('ec9f');
$ti-icon-sum: unicode('eb73');
$ti-icon-sum-off: unicode('f1ab');
$ti-icon-sun: unicode('eb30');
$ti-icon-sun-electricity: unicode('fcc2');
$ti-icon-sun-filled: unicode('f6a9');
$ti-icon-sun-high: unicode('f236');
$ti-icon-sun-low: unicode('f237');
$ti-icon-sun-moon: unicode('f4a3');
$ti-icon-sun-off: unicode('ed63');
$ti-icon-sun-wind: unicode('f238');
$ti-icon-sunglasses: unicode('f239');
$ti-icon-sunglasses-filled: unicode('fec8');
$ti-icon-sunrise: unicode('ef1c');
$ti-icon-sunset: unicode('ec31');
$ti-icon-sunset-2: unicode('f23a');
$ti-icon-superscript: unicode('eba0');
$ti-icon-svg: unicode('f25a');
$ti-icon-swimming: unicode('ec92');
$ti-icon-swipe: unicode('f551');
$ti-icon-swipe-down: unicode('fd5e');
$ti-icon-swipe-down-filled: unicode('ff57');
$ti-icon-swipe-left: unicode('fd5f');
$ti-icon-swipe-left-filled: unicode('ff56');
$ti-icon-swipe-right: unicode('fd60');
$ti-icon-swipe-right-filled: unicode('ff55');
$ti-icon-swipe-up: unicode('fd61');
$ti-icon-swipe-up-filled: unicode('ff54');
$ti-icon-switch: unicode('eb33');
$ti-icon-switch-2: unicode('edbf');
$ti-icon-switch-3: unicode('edc0');
$ti-icon-switch-horizontal: unicode('eb31');
$ti-icon-switch-vertical: unicode('eb32');
$ti-icon-sword: unicode('f030');
$ti-icon-sword-off: unicode('f434');
$ti-icon-swords: unicode('f132');
$ti-icon-table: unicode('eba1');
$ti-icon-table-alias: unicode('f25b');
$ti-icon-table-column: unicode('faff');
$ti-icon-table-down: unicode('fa1c');
$ti-icon-table-export: unicode('eef8');
$ti-icon-table-filled: unicode('f782');
$ti-icon-table-heart: unicode('fa1d');
$ti-icon-table-import: unicode('eef9');
$ti-icon-table-minus: unicode('fa1e');
$ti-icon-table-off: unicode('eefa');
$ti-icon-table-options: unicode('f25c');
$ti-icon-table-plus: unicode('fa1f');
$ti-icon-table-row: unicode('fb00');
$ti-icon-table-share: unicode('fa20');
$ti-icon-table-shortcut: unicode('f25d');
$ti-icon-table-spark: unicode('ffad');
$ti-icon-tag: unicode('eb34');
$ti-icon-tag-filled: unicode('ff02');
$ti-icon-tag-off: unicode('efc0');
$ti-icon-tag-starred: unicode('fc99');
$ti-icon-tags: unicode('ef86');
$ti-icon-tags-filled: unicode('ff01');
$ti-icon-tags-off: unicode('efc1');
$ti-icon-tallymark-1: unicode('ec46');
$ti-icon-tallymark-2: unicode('ec47');
$ti-icon-tallymark-3: unicode('ec48');
$ti-icon-tallymark-4: unicode('ec49');
$ti-icon-tallymarks: unicode('ec4a');
$ti-icon-tank: unicode('ed95');
$ti-icon-target: unicode('eb35');
$ti-icon-target-arrow: unicode('f51a');
$ti-icon-target-off: unicode('f1ad');
$ti-icon-tax: unicode('feee');
$ti-icon-tax-euro: unicode('fef0');
$ti-icon-tax-pound: unicode('feef');
$ti-icon-teapot: unicode('f552');
$ti-icon-telescope: unicode('f07d');
$ti-icon-telescope-off: unicode('f1ae');
$ti-icon-temperature: unicode('eb38');
$ti-icon-temperature-celsius: unicode('eb36');
$ti-icon-temperature-fahrenheit: unicode('eb37');
$ti-icon-temperature-minus: unicode('ebed');
$ti-icon-temperature-off: unicode('f1af');
$ti-icon-temperature-plus: unicode('ebee');
$ti-icon-temperature-snow: unicode('fda3');
$ti-icon-temperature-sun: unicode('fda4');
$ti-icon-template: unicode('eb39');
$ti-icon-template-off: unicode('f1b0');
$ti-icon-tent: unicode('eefb');
$ti-icon-tent-off: unicode('f435');
$ti-icon-terminal: unicode('ebdc');
$ti-icon-terminal-2: unicode('ebef');
$ti-icon-test-pipe: unicode('eb3a');
$ti-icon-test-pipe-2: unicode('f0a4');
$ti-icon-test-pipe-2-filled: unicode('ff53');
$ti-icon-test-pipe-off: unicode('f1b1');
$ti-icon-tex: unicode('f4e0');
$ti-icon-text-caption: unicode('f4a4');
$ti-icon-text-color: unicode('f2dc');
$ti-icon-text-decrease: unicode('f202');
$ti-icon-text-direction-ltr: unicode('eefc');
$ti-icon-text-direction-rtl: unicode('eefd');
$ti-icon-text-grammar: unicode('fd6d');
$ti-icon-text-increase: unicode('f203');
$ti-icon-text-orientation: unicode('f2a4');
$ti-icon-text-plus: unicode('f2a5');
$ti-icon-text-recognition: unicode('f204');
$ti-icon-text-resize: unicode('ef87');
$ti-icon-text-scan-2: unicode('fcc3');
$ti-icon-text-size: unicode('f2b1');
$ti-icon-text-spellcheck: unicode('f2a6');
$ti-icon-text-wrap: unicode('ebdd');
$ti-icon-text-wrap-column: unicode('feb2');
$ti-icon-text-wrap-disabled: unicode('eca7');
$ti-icon-texture: unicode('f51b');
$ti-icon-theater: unicode('f79b');
$ti-icon-thermometer: unicode('ef67');
$ti-icon-thumb-down: unicode('eb3b');
$ti-icon-thumb-down-filled: unicode('f6aa');
$ti-icon-thumb-down-off: unicode('f436');
$ti-icon-thumb-up: unicode('eb3c');
$ti-icon-thumb-up-filled: unicode('f6ab');
$ti-icon-thumb-up-off: unicode('f437');
$ti-icon-tic-tac: unicode('f51c');
$ti-icon-ticket: unicode('eb3d');
$ti-icon-ticket-off: unicode('f1b2');
$ti-icon-tie: unicode('f07e');
$ti-icon-tilde: unicode('f4a5');
$ti-icon-tilt-shift: unicode('eefe');
$ti-icon-tilt-shift-filled: unicode('fec7');
$ti-icon-tilt-shift-off: unicode('f1b3');
$ti-icon-time-duration-0: unicode('fad4');
$ti-icon-time-duration-10: unicode('fad5');
$ti-icon-time-duration-15: unicode('fad6');
$ti-icon-time-duration-30: unicode('fad7');
$ti-icon-time-duration-45: unicode('fad8');
$ti-icon-time-duration-5: unicode('fad9');
$ti-icon-time-duration-60: unicode('fada');
$ti-icon-time-duration-90: unicode('fadb');
$ti-icon-time-duration-off: unicode('fadc');
$ti-icon-timeline: unicode('f031');
$ti-icon-timeline-event: unicode('f553');
$ti-icon-timeline-event-exclamation: unicode('f662');
$ti-icon-timeline-event-filled: unicode('fd18');
$ti-icon-timeline-event-minus: unicode('f663');
$ti-icon-timeline-event-plus: unicode('f664');
$ti-icon-timeline-event-text: unicode('f665');
$ti-icon-timeline-event-x: unicode('f666');
$ti-icon-timezone: unicode('feed');
$ti-icon-tip-jar: unicode('feea');
$ti-icon-tip-jar-euro: unicode('feec');
$ti-icon-tip-jar-pound: unicode('feeb');
$ti-icon-tir: unicode('ebf0');
$ti-icon-toggle-left: unicode('eb3e');
$ti-icon-toggle-left-filled: unicode('fec0');
$ti-icon-toggle-right: unicode('eb3f');
$ti-icon-toggle-right-filled: unicode('febf');
$ti-icon-toilet-paper: unicode('efd3');
$ti-icon-toilet-paper-off: unicode('f1b4');
$ti-icon-toml: unicode('fa5d');
$ti-icon-tool: unicode('eb40');
$ti-icon-tools: unicode('ebca');
$ti-icon-tools-kitchen: unicode('ed64');
$ti-icon-tools-kitchen-2: unicode('eeff');
$ti-icon-tools-kitchen-2-off: unicode('f1b5');
$ti-icon-tools-kitchen-3: unicode('fd2b');
$ti-icon-tools-kitchen-off: unicode('f1b6');
$ti-icon-tools-off: unicode('f1b7');
$ti-icon-tooltip: unicode('f2dd');
$ti-icon-topology-bus: unicode('f5d9');
$ti-icon-topology-complex: unicode('f5da');
$ti-icon-topology-full: unicode('f5dc');
$ti-icon-topology-full-hierarchy: unicode('f5db');
$ti-icon-topology-ring: unicode('f5df');
$ti-icon-topology-ring-2: unicode('f5dd');
$ti-icon-topology-ring-3: unicode('f5de');
$ti-icon-topology-star: unicode('f5e5');
$ti-icon-topology-star-2: unicode('f5e0');
$ti-icon-topology-star-3: unicode('f5e1');
$ti-icon-topology-star-ring: unicode('f5e4');
$ti-icon-topology-star-ring-2: unicode('f5e2');
$ti-icon-topology-star-ring-3: unicode('f5e3');
$ti-icon-torii: unicode('f59b');
$ti-icon-tornado: unicode('ece2');
$ti-icon-tournament: unicode('ecd0');
$ti-icon-tower: unicode('f2cb');
$ti-icon-tower-off: unicode('f2ca');
$ti-icon-track: unicode('ef00');
$ti-icon-tractor: unicode('ec0d');
$ti-icon-trademark: unicode('ec0e');
$ti-icon-traffic-cone: unicode('ec0f');
$ti-icon-traffic-cone-off: unicode('f1b8');
$ti-icon-traffic-lights: unicode('ed39');
$ti-icon-traffic-lights-off: unicode('f1b9');
$ti-icon-train: unicode('ed96');
$ti-icon-transaction-bitcoin: unicode('fd6e');
$ti-icon-transaction-dollar: unicode('fd6f');
$ti-icon-transaction-euro: unicode('fd70');
$ti-icon-transaction-pound: unicode('fd71');
$ti-icon-transaction-rupee: unicode('fd85');
$ti-icon-transaction-yen: unicode('fd72');
$ti-icon-transaction-yuan: unicode('fd73');
$ti-icon-transfer: unicode('fc1f');
$ti-icon-transfer-in: unicode('ef2f');
$ti-icon-transfer-out: unicode('ef30');
$ti-icon-transfer-vertical: unicode('fc1e');
$ti-icon-transform: unicode('f38e');
$ti-icon-transform-filled: unicode('f6ac');
$ti-icon-transform-point: unicode('fda9');
$ti-icon-transform-point-bottom-left: unicode('fda5');
$ti-icon-transform-point-bottom-right: unicode('fda6');
$ti-icon-transform-point-top-left: unicode('fda7');
$ti-icon-transform-point-top-right: unicode('fda8');
$ti-icon-transition-bottom: unicode('f2b2');
$ti-icon-transition-bottom-filled: unicode('fdd0');
$ti-icon-transition-left: unicode('f2b3');
$ti-icon-transition-left-filled: unicode('fdcf');
$ti-icon-transition-right: unicode('f2b4');
$ti-icon-transition-right-filled: unicode('fdce');
$ti-icon-transition-top: unicode('f2b5');
$ti-icon-transition-top-filled: unicode('fdcd');
$ti-icon-trash: unicode('eb41');
$ti-icon-trash-filled: unicode('f783');
$ti-icon-trash-off: unicode('ed65');
$ti-icon-trash-x: unicode('ef88');
$ti-icon-trash-x-filled: unicode('f784');
$ti-icon-treadmill: unicode('fa6e');
$ti-icon-tree: unicode('ef01');
$ti-icon-trees: unicode('ec10');
$ti-icon-trekking: unicode('f5ad');
$ti-icon-trending-down: unicode('eb42');
$ti-icon-trending-down-2: unicode('edc1');
$ti-icon-trending-down-3: unicode('edc2');
$ti-icon-trending-up: unicode('eb43');
$ti-icon-trending-up-2: unicode('edc3');
$ti-icon-trending-up-3: unicode('edc4');
$ti-icon-triangle: unicode('eb44');
$ti-icon-triangle-filled: unicode('f6ad');
$ti-icon-triangle-inverted: unicode('f01d');
$ti-icon-triangle-inverted-filled: unicode('f6ae');
$ti-icon-triangle-minus: unicode('fc9b');
$ti-icon-triangle-minus-2: unicode('fc9a');
$ti-icon-triangle-off: unicode('ef02');
$ti-icon-triangle-plus: unicode('fc9d');
$ti-icon-triangle-plus-2: unicode('fc9c');
$ti-icon-triangle-square-circle: unicode('ece8');
$ti-icon-triangle-square-circle-filled: unicode('fb42');
$ti-icon-triangles: unicode('f0a5');
$ti-icon-trident: unicode('ecc5');
$ti-icon-trolley: unicode('f4cc');
$ti-icon-trophy: unicode('eb45');
$ti-icon-trophy-filled: unicode('f6af');
$ti-icon-trophy-off: unicode('f438');
$ti-icon-trowel: unicode('f368');
$ti-icon-truck: unicode('ebc4');
$ti-icon-truck-delivery: unicode('ec4b');
$ti-icon-truck-loading: unicode('f1da');
$ti-icon-truck-off: unicode('ef03');
$ti-icon-truck-return: unicode('ec4c');
$ti-icon-txt: unicode('f3b1');
$ti-icon-typeface: unicode('fdab');
$ti-icon-typography: unicode('ebc5');
$ti-icon-typography-off: unicode('f1ba');
$ti-icon-u-turn-left: unicode('fea2');
$ti-icon-u-turn-right: unicode('fea1');
$ti-icon-ufo: unicode('f26f');
$ti-icon-ufo-off: unicode('f26e');
$ti-icon-umbrella: unicode('ebf1');
$ti-icon-umbrella-2: unicode('ff0e');
$ti-icon-umbrella-closed: unicode('ff0c');
$ti-icon-umbrella-closed-2: unicode('ff0d');
$ti-icon-umbrella-filled: unicode('f6b0');
$ti-icon-umbrella-off: unicode('f1bb');
$ti-icon-underline: unicode('eba2');
$ti-icon-universe: unicode('fcc4');
$ti-icon-unlink: unicode('eb46');
$ti-icon-upload: unicode('eb47');
$ti-icon-urgent: unicode('eb48');
$ti-icon-usb: unicode('f00c');
$ti-icon-user: unicode('eb4d');
$ti-icon-user-bitcoin: unicode('ff30');
$ti-icon-user-bolt: unicode('f9d1');
$ti-icon-user-cancel: unicode('f9d2');
$ti-icon-user-check: unicode('eb49');
$ti-icon-user-circle: unicode('ef68');
$ti-icon-user-code: unicode('f9d3');
$ti-icon-user-cog: unicode('f9d4');
$ti-icon-user-dollar: unicode('f9d5');
$ti-icon-user-down: unicode('f9d6');
$ti-icon-user-edit: unicode('f7cc');
$ti-icon-user-exclamation: unicode('ec12');
$ti-icon-user-filled: unicode('fd19');
$ti-icon-user-heart: unicode('f7cd');
$ti-icon-user-hexagon: unicode('fc4e');
$ti-icon-user-minus: unicode('eb4a');
$ti-icon-user-off: unicode('ecf9');
$ti-icon-user-pause: unicode('f9d7');
$ti-icon-user-pentagon: unicode('fc4f');
$ti-icon-user-pin: unicode('f7ce');
$ti-icon-user-plus: unicode('eb4b');
$ti-icon-user-question: unicode('f7cf');
$ti-icon-user-scan: unicode('fcaf');
$ti-icon-user-screen: unicode('fea0');
$ti-icon-user-search: unicode('ef89');
$ti-icon-user-share: unicode('f9d8');
$ti-icon-user-shield: unicode('f7d0');
$ti-icon-user-square: unicode('fc51');
$ti-icon-user-square-rounded: unicode('fc50');
$ti-icon-user-star: unicode('f7d1');
$ti-icon-user-up: unicode('f7d2');
$ti-icon-user-x: unicode('eb4c');
$ti-icon-users: unicode('ebf2');
$ti-icon-users-group: unicode('fa21');
$ti-icon-users-minus: unicode('fa0e');
$ti-icon-users-plus: unicode('fa0f');
$ti-icon-uv-index: unicode('f3b2');
$ti-icon-ux-circle: unicode('f369');
$ti-icon-vaccine: unicode('ef04');
$ti-icon-vaccine-bottle: unicode('ef69');
$ti-icon-vaccine-bottle-off: unicode('f439');
$ti-icon-vaccine-off: unicode('f1bc');
$ti-icon-vacuum-cleaner: unicode('f5e6');
$ti-icon-variable: unicode('ef05');
$ti-icon-variable-minus: unicode('f36a');
$ti-icon-variable-off: unicode('f1bd');
$ti-icon-variable-plus: unicode('f36b');
$ti-icon-vector: unicode('eca9');
$ti-icon-vector-bezier: unicode('ef1d');
$ti-icon-vector-bezier-2: unicode('f1a3');
$ti-icon-vector-bezier-arc: unicode('f4cd');
$ti-icon-vector-bezier-circle: unicode('f4ce');
$ti-icon-vector-off: unicode('f1be');
$ti-icon-vector-spline: unicode('f565');
$ti-icon-vector-triangle: unicode('eca8');
$ti-icon-vector-triangle-off: unicode('f1bf');
$ti-icon-venus: unicode('ec86');
$ti-icon-versions: unicode('ed52');
$ti-icon-versions-filled: unicode('f6b1');
$ti-icon-versions-off: unicode('f1c0');
$ti-icon-video: unicode('ed22');
$ti-icon-video-minus: unicode('ed1f');
$ti-icon-video-off: unicode('ed20');
$ti-icon-video-plus: unicode('ed21');
$ti-icon-view-360: unicode('ed84');
$ti-icon-view-360-arrow: unicode('f62f');
$ti-icon-view-360-number: unicode('f566');
$ti-icon-view-360-off: unicode('f1c1');
$ti-icon-viewfinder: unicode('eb4e');
$ti-icon-viewfinder-off: unicode('f1c2');
$ti-icon-viewport-narrow: unicode('ebf3');
$ti-icon-viewport-short: unicode('fee9');
$ti-icon-viewport-tall: unicode('fee8');
$ti-icon-viewport-wide: unicode('ebf4');
$ti-icon-vinyl: unicode('f00d');
$ti-icon-vip: unicode('f3b3');
$ti-icon-vip-off: unicode('f43a');
$ti-icon-virus: unicode('eb74');
$ti-icon-virus-off: unicode('ed66');
$ti-icon-virus-search: unicode('ed67');
$ti-icon-vocabulary: unicode('ef1e');
$ti-icon-vocabulary-off: unicode('f43b');
$ti-icon-volcano: unicode('f79c');
$ti-icon-volume: unicode('eb51');
$ti-icon-volume-2: unicode('eb4f');
$ti-icon-volume-3: unicode('eb50');
$ti-icon-volume-off: unicode('f1c3');
$ti-icon-vs: unicode('fc52');
$ti-icon-walk: unicode('ec87');
$ti-icon-wall: unicode('ef7a');
$ti-icon-wall-off: unicode('f43c');
$ti-icon-wallet: unicode('eb75');
$ti-icon-wallet-off: unicode('f1c4');
$ti-icon-wallpaper: unicode('ef56');
$ti-icon-wallpaper-off: unicode('f1c5');
$ti-icon-wand: unicode('ebcb');
$ti-icon-wand-off: unicode('f1c6');
$ti-icon-wash: unicode('f311');
$ti-icon-wash-dry: unicode('f304');
$ti-icon-wash-dry-1: unicode('f2fa');
$ti-icon-wash-dry-2: unicode('f2fb');
$ti-icon-wash-dry-3: unicode('f2fc');
$ti-icon-wash-dry-a: unicode('f2fd');
$ti-icon-wash-dry-dip: unicode('f2fe');
$ti-icon-wash-dry-f: unicode('f2ff');
$ti-icon-wash-dry-flat: unicode('fa7f');
$ti-icon-wash-dry-hang: unicode('f300');
$ti-icon-wash-dry-off: unicode('f301');
$ti-icon-wash-dry-p: unicode('f302');
$ti-icon-wash-dry-shade: unicode('f303');
$ti-icon-wash-dry-w: unicode('f322');
$ti-icon-wash-dryclean: unicode('f305');
$ti-icon-wash-dryclean-off: unicode('f323');
$ti-icon-wash-eco: unicode('fa80');
$ti-icon-wash-gentle: unicode('f306');
$ti-icon-wash-hand: unicode('fa81');
$ti-icon-wash-machine: unicode('f25e');
$ti-icon-wash-off: unicode('f307');
$ti-icon-wash-press: unicode('f308');
$ti-icon-wash-temperature-1: unicode('f309');
$ti-icon-wash-temperature-2: unicode('f30a');
$ti-icon-wash-temperature-3: unicode('f30b');
$ti-icon-wash-temperature-4: unicode('f30c');
$ti-icon-wash-temperature-5: unicode('f30d');
$ti-icon-wash-temperature-6: unicode('f30e');
$ti-icon-wash-tumble-dry: unicode('f30f');
$ti-icon-wash-tumble-off: unicode('f310');
$ti-icon-waterpolo: unicode('fa6f');
$ti-icon-wave-saw-tool: unicode('ecd3');
$ti-icon-wave-sine: unicode('ecd4');
$ti-icon-wave-square: unicode('ecd5');
$ti-icon-waves-electricity: unicode('fcc5');
$ti-icon-webhook: unicode('f01e');
$ti-icon-webhook-off: unicode('f43d');
$ti-icon-weight: unicode('f589');
$ti-icon-wheel: unicode('fc64');
$ti-icon-wheelchair: unicode('f1db');
$ti-icon-wheelchair-off: unicode('f43e');
$ti-icon-whirl: unicode('f51d');
$ti-icon-wifi: unicode('eb52');
$ti-icon-wifi-0: unicode('eba3');
$ti-icon-wifi-1: unicode('eba4');
$ti-icon-wifi-2: unicode('eba5');
$ti-icon-wifi-off: unicode('ecfa');
$ti-icon-wind: unicode('ec34');
$ti-icon-wind-electricity: unicode('fcc6');
$ti-icon-wind-off: unicode('f1c7');
$ti-icon-windmill: unicode('ed85');
$ti-icon-windmill-filled: unicode('f6b2');
$ti-icon-windmill-off: unicode('f1c8');
$ti-icon-window: unicode('ef06');
$ti-icon-window-maximize: unicode('f1f1');
$ti-icon-window-minimize: unicode('f1f2');
$ti-icon-window-off: unicode('f1c9');
$ti-icon-windsock: unicode('f06d');
$ti-icon-wiper: unicode('ecab');
$ti-icon-wiper-wash: unicode('ecaa');
$ti-icon-woman: unicode('eb53');
$ti-icon-woman-filled: unicode('fdcc');
$ti-icon-wood: unicode('f359');
$ti-icon-world: unicode('eb54');
$ti-icon-world-bolt: unicode('f9d9');
$ti-icon-world-cancel: unicode('f9da');
$ti-icon-world-check: unicode('f9db');
$ti-icon-world-code: unicode('f9dc');
$ti-icon-world-cog: unicode('f9dd');
$ti-icon-world-dollar: unicode('f9de');
$ti-icon-world-down: unicode('f9df');
$ti-icon-world-download: unicode('ef8a');
$ti-icon-world-exclamation: unicode('f9e0');
$ti-icon-world-heart: unicode('f9e1');
$ti-icon-world-latitude: unicode('ed2e');
$ti-icon-world-longitude: unicode('ed2f');
$ti-icon-world-minus: unicode('f9e2');
$ti-icon-world-off: unicode('f1ca');
$ti-icon-world-pause: unicode('f9e3');
$ti-icon-world-pin: unicode('f9e4');
$ti-icon-world-plus: unicode('f9e5');
$ti-icon-world-question: unicode('f9e6');
$ti-icon-world-search: unicode('f9e7');
$ti-icon-world-share: unicode('f9e8');
$ti-icon-world-star: unicode('f9e9');
$ti-icon-world-up: unicode('f9ea');
$ti-icon-world-upload: unicode('ef8b');
$ti-icon-world-www: unicode('f38f');
$ti-icon-world-x: unicode('f9eb');
$ti-icon-wrecking-ball: unicode('ed97');
$ti-icon-writing: unicode('ef08');
$ti-icon-writing-off: unicode('f1cb');
$ti-icon-writing-sign: unicode('ef07');
$ti-icon-writing-sign-off: unicode('f1cc');
$ti-icon-x: unicode('eb55');
$ti-icon-x-power-y: unicode('10072');
$ti-icon-xbox-a: unicode('f2b6');
$ti-icon-xbox-a-filled: unicode('fdcb');
$ti-icon-xbox-b: unicode('f2b7');
$ti-icon-xbox-b-filled: unicode('fdca');
$ti-icon-xbox-x: unicode('f2b8');
$ti-icon-xbox-x-filled: unicode('fdc9');
$ti-icon-xbox-y: unicode('f2b9');
$ti-icon-xbox-y-filled: unicode('fdc8');
$ti-icon-xd: unicode('fa33');
$ti-icon-xxx: unicode('fc20');
$ti-icon-yin-yang: unicode('ec35');
$ti-icon-yin-yang-filled: unicode('f785');
$ti-icon-yoga: unicode('f01f');
$ti-icon-zeppelin: unicode('f270');
$ti-icon-zeppelin-filled: unicode('fdc7');
$ti-icon-zeppelin-off: unicode('f43f');
$ti-icon-zip: unicode('f3b4');
$ti-icon-zodiac-aquarius: unicode('ecac');
$ti-icon-zodiac-aries: unicode('ecad');
$ti-icon-zodiac-cancer: unicode('ecae');
$ti-icon-zodiac-capricorn: unicode('ecaf');
$ti-icon-zodiac-gemini: unicode('ecb0');
$ti-icon-zodiac-leo: unicode('ecb1');
$ti-icon-zodiac-libra: unicode('ecb2');
$ti-icon-zodiac-pisces: unicode('ecb3');
$ti-icon-zodiac-sagittarius: unicode('ecb4');
$ti-icon-zodiac-scorpio: unicode('ecb5');
$ti-icon-zodiac-taurus: unicode('ecb6');
$ti-icon-zodiac-virgo: unicode('ecb7');
$ti-icon-zoom: unicode('fdaa');
$ti-icon-zoom-cancel: unicode('ec4d');
$ti-icon-zoom-cancel-filled: unicode('fdc6');
$ti-icon-zoom-check: unicode('ef09');
$ti-icon-zoom-check-filled: unicode('f786');
$ti-icon-zoom-code: unicode('f07f');
$ti-icon-zoom-code-filled: unicode('fdc5');
$ti-icon-zoom-exclamation: unicode('f080');
$ti-icon-zoom-exclamation-filled: unicode('fdc4');
$ti-icon-zoom-filled: unicode('f787');
$ti-icon-zoom-in: unicode('eb56');
$ti-icon-zoom-in-area: unicode('f1dc');
$ti-icon-zoom-in-area-filled: unicode('f788');
$ti-icon-zoom-in-filled: unicode('f789');
$ti-icon-zoom-money: unicode('ef0a');
$ti-icon-zoom-money-filled: unicode('fdc3');
$ti-icon-zoom-out: unicode('eb57');
$ti-icon-zoom-out-area: unicode('f1dd');
$ti-icon-zoom-out-area-filled: unicode('fdc2');
$ti-icon-zoom-out-filled: unicode('f78a');
$ti-icon-zoom-pan: unicode('f1de');
$ti-icon-zoom-pan-filled: unicode('fdc1');
$ti-icon-zoom-question: unicode('edeb');
$ti-icon-zoom-question-filled: unicode('fdc0');
$ti-icon-zoom-replace: unicode('f2a7');
$ti-icon-zoom-reset: unicode('f295');
$ti-icon-zoom-scan: unicode('fcb0');
$ti-icon-zoom-scan-filled: unicode('fdbf');
$ti-icon-zzz: unicode('f228');
$ti-icon-zzz-off: unicode('f440');


.#{$ti-prefix}-a-b:before { content: $ti-icon-a-b; }
.#{$ti-prefix}-a-b-2:before { content: $ti-icon-a-b-2; }
.#{$ti-prefix}-a-b-off:before { content: $ti-icon-a-b-off; }
.#{$ti-prefix}-abacus:before { content: $ti-icon-abacus; }
.#{$ti-prefix}-abacus-off:before { content: $ti-icon-abacus-off; }
.#{$ti-prefix}-abc:before { content: $ti-icon-abc; }
.#{$ti-prefix}-access-point:before { content: $ti-icon-access-point; }
.#{$ti-prefix}-access-point-off:before { content: $ti-icon-access-point-off; }
.#{$ti-prefix}-accessible:before { content: $ti-icon-accessible; }
.#{$ti-prefix}-accessible-filled:before { content: $ti-icon-accessible-filled; }
.#{$ti-prefix}-accessible-off:before { content: $ti-icon-accessible-off; }
.#{$ti-prefix}-activity:before { content: $ti-icon-activity; }
.#{$ti-prefix}-activity-heartbeat:before { content: $ti-icon-activity-heartbeat; }
.#{$ti-prefix}-ad:before { content: $ti-icon-ad; }
.#{$ti-prefix}-ad-2:before { content: $ti-icon-ad-2; }
.#{$ti-prefix}-ad-circle:before { content: $ti-icon-ad-circle; }
.#{$ti-prefix}-ad-circle-filled:before { content: $ti-icon-ad-circle-filled; }
.#{$ti-prefix}-ad-circle-off:before { content: $ti-icon-ad-circle-off; }
.#{$ti-prefix}-ad-filled:before { content: $ti-icon-ad-filled; }
.#{$ti-prefix}-ad-off:before { content: $ti-icon-ad-off; }
.#{$ti-prefix}-address-book:before { content: $ti-icon-address-book; }
.#{$ti-prefix}-address-book-off:before { content: $ti-icon-address-book-off; }
.#{$ti-prefix}-adjustments:before { content: $ti-icon-adjustments; }
.#{$ti-prefix}-adjustments-alt:before { content: $ti-icon-adjustments-alt; }
.#{$ti-prefix}-adjustments-bolt:before { content: $ti-icon-adjustments-bolt; }
.#{$ti-prefix}-adjustments-cancel:before { content: $ti-icon-adjustments-cancel; }
.#{$ti-prefix}-adjustments-check:before { content: $ti-icon-adjustments-check; }
.#{$ti-prefix}-adjustments-code:before { content: $ti-icon-adjustments-code; }
.#{$ti-prefix}-adjustments-cog:before { content: $ti-icon-adjustments-cog; }
.#{$ti-prefix}-adjustments-dollar:before { content: $ti-icon-adjustments-dollar; }
.#{$ti-prefix}-adjustments-down:before { content: $ti-icon-adjustments-down; }
.#{$ti-prefix}-adjustments-exclamation:before { content: $ti-icon-adjustments-exclamation; }
.#{$ti-prefix}-adjustments-filled:before { content: $ti-icon-adjustments-filled; }
.#{$ti-prefix}-adjustments-heart:before { content: $ti-icon-adjustments-heart; }
.#{$ti-prefix}-adjustments-horizontal:before { content: $ti-icon-adjustments-horizontal; }
.#{$ti-prefix}-adjustments-minus:before { content: $ti-icon-adjustments-minus; }
.#{$ti-prefix}-adjustments-off:before { content: $ti-icon-adjustments-off; }
.#{$ti-prefix}-adjustments-pause:before { content: $ti-icon-adjustments-pause; }
.#{$ti-prefix}-adjustments-pin:before { content: $ti-icon-adjustments-pin; }
.#{$ti-prefix}-adjustments-plus:before { content: $ti-icon-adjustments-plus; }
.#{$ti-prefix}-adjustments-question:before { content: $ti-icon-adjustments-question; }
.#{$ti-prefix}-adjustments-search:before { content: $ti-icon-adjustments-search; }
.#{$ti-prefix}-adjustments-share:before { content: $ti-icon-adjustments-share; }
.#{$ti-prefix}-adjustments-spark:before { content: $ti-icon-adjustments-spark; }
.#{$ti-prefix}-adjustments-star:before { content: $ti-icon-adjustments-star; }
.#{$ti-prefix}-adjustments-up:before { content: $ti-icon-adjustments-up; }
.#{$ti-prefix}-adjustments-x:before { content: $ti-icon-adjustments-x; }
.#{$ti-prefix}-aerial-lift:before { content: $ti-icon-aerial-lift; }
.#{$ti-prefix}-affiliate:before { content: $ti-icon-affiliate; }
.#{$ti-prefix}-affiliate-filled:before { content: $ti-icon-affiliate-filled; }
.#{$ti-prefix}-ai:before { content: $ti-icon-ai; }
.#{$ti-prefix}-air-balloon:before { content: $ti-icon-air-balloon; }
.#{$ti-prefix}-air-conditioning:before { content: $ti-icon-air-conditioning; }
.#{$ti-prefix}-air-conditioning-disabled:before { content: $ti-icon-air-conditioning-disabled; }
.#{$ti-prefix}-air-traffic-control:before { content: $ti-icon-air-traffic-control; }
.#{$ti-prefix}-alarm:before { content: $ti-icon-alarm; }
.#{$ti-prefix}-alarm-average:before { content: $ti-icon-alarm-average; }
.#{$ti-prefix}-alarm-filled:before { content: $ti-icon-alarm-filled; }
.#{$ti-prefix}-alarm-minus:before { content: $ti-icon-alarm-minus; }
.#{$ti-prefix}-alarm-minus-filled:before { content: $ti-icon-alarm-minus-filled; }
.#{$ti-prefix}-alarm-off:before { content: $ti-icon-alarm-off; }
.#{$ti-prefix}-alarm-plus:before { content: $ti-icon-alarm-plus; }
.#{$ti-prefix}-alarm-plus-filled:before { content: $ti-icon-alarm-plus-filled; }
.#{$ti-prefix}-alarm-snooze:before { content: $ti-icon-alarm-snooze; }
.#{$ti-prefix}-alarm-snooze-filled:before { content: $ti-icon-alarm-snooze-filled; }
.#{$ti-prefix}-album:before { content: $ti-icon-album; }
.#{$ti-prefix}-album-off:before { content: $ti-icon-album-off; }
.#{$ti-prefix}-alert-circle:before { content: $ti-icon-alert-circle; }
.#{$ti-prefix}-alert-circle-filled:before { content: $ti-icon-alert-circle-filled; }
.#{$ti-prefix}-alert-circle-off:before { content: $ti-icon-alert-circle-off; }
.#{$ti-prefix}-alert-hexagon:before { content: $ti-icon-alert-hexagon; }
.#{$ti-prefix}-alert-hexagon-filled:before { content: $ti-icon-alert-hexagon-filled; }
.#{$ti-prefix}-alert-hexagon-off:before { content: $ti-icon-alert-hexagon-off; }
.#{$ti-prefix}-alert-octagon:before { content: $ti-icon-alert-octagon; }
.#{$ti-prefix}-alert-octagon-filled:before { content: $ti-icon-alert-octagon-filled; }
.#{$ti-prefix}-alert-small:before { content: $ti-icon-alert-small; }
.#{$ti-prefix}-alert-small-off:before { content: $ti-icon-alert-small-off; }
.#{$ti-prefix}-alert-square:before { content: $ti-icon-alert-square; }
.#{$ti-prefix}-alert-square-filled:before { content: $ti-icon-alert-square-filled; }
.#{$ti-prefix}-alert-square-rounded:before { content: $ti-icon-alert-square-rounded; }
.#{$ti-prefix}-alert-square-rounded-filled:before { content: $ti-icon-alert-square-rounded-filled; }
.#{$ti-prefix}-alert-square-rounded-off:before { content: $ti-icon-alert-square-rounded-off; }
.#{$ti-prefix}-alert-triangle:before { content: $ti-icon-alert-triangle; }
.#{$ti-prefix}-alert-triangle-filled:before { content: $ti-icon-alert-triangle-filled; }
.#{$ti-prefix}-alert-triangle-off:before { content: $ti-icon-alert-triangle-off; }
.#{$ti-prefix}-alien:before { content: $ti-icon-alien; }
.#{$ti-prefix}-alien-filled:before { content: $ti-icon-alien-filled; }
.#{$ti-prefix}-align-box-bottom-center:before { content: $ti-icon-align-box-bottom-center; }
.#{$ti-prefix}-align-box-bottom-center-filled:before { content: $ti-icon-align-box-bottom-center-filled; }
.#{$ti-prefix}-align-box-bottom-left:before { content: $ti-icon-align-box-bottom-left; }
.#{$ti-prefix}-align-box-bottom-left-filled:before { content: $ti-icon-align-box-bottom-left-filled; }
.#{$ti-prefix}-align-box-bottom-right:before { content: $ti-icon-align-box-bottom-right; }
.#{$ti-prefix}-align-box-bottom-right-filled:before { content: $ti-icon-align-box-bottom-right-filled; }
.#{$ti-prefix}-align-box-center-bottom:before { content: $ti-icon-align-box-center-bottom; }
.#{$ti-prefix}-align-box-center-middle:before { content: $ti-icon-align-box-center-middle; }
.#{$ti-prefix}-align-box-center-middle-filled:before { content: $ti-icon-align-box-center-middle-filled; }
.#{$ti-prefix}-align-box-center-stretch:before { content: $ti-icon-align-box-center-stretch; }
.#{$ti-prefix}-align-box-center-top:before { content: $ti-icon-align-box-center-top; }
.#{$ti-prefix}-align-box-left-bottom:before { content: $ti-icon-align-box-left-bottom; }
.#{$ti-prefix}-align-box-left-bottom-filled:before { content: $ti-icon-align-box-left-bottom-filled; }
.#{$ti-prefix}-align-box-left-middle:before { content: $ti-icon-align-box-left-middle; }
.#{$ti-prefix}-align-box-left-middle-filled:before { content: $ti-icon-align-box-left-middle-filled; }
.#{$ti-prefix}-align-box-left-stretch:before { content: $ti-icon-align-box-left-stretch; }
.#{$ti-prefix}-align-box-left-top:before { content: $ti-icon-align-box-left-top; }
.#{$ti-prefix}-align-box-left-top-filled:before { content: $ti-icon-align-box-left-top-filled; }
.#{$ti-prefix}-align-box-right-bottom:before { content: $ti-icon-align-box-right-bottom; }
.#{$ti-prefix}-align-box-right-bottom-filled:before { content: $ti-icon-align-box-right-bottom-filled; }
.#{$ti-prefix}-align-box-right-middle:before { content: $ti-icon-align-box-right-middle; }
.#{$ti-prefix}-align-box-right-middle-filled:before { content: $ti-icon-align-box-right-middle-filled; }
.#{$ti-prefix}-align-box-right-stretch:before { content: $ti-icon-align-box-right-stretch; }
.#{$ti-prefix}-align-box-right-top:before { content: $ti-icon-align-box-right-top; }
.#{$ti-prefix}-align-box-right-top-filled:before { content: $ti-icon-align-box-right-top-filled; }
.#{$ti-prefix}-align-box-top-center:before { content: $ti-icon-align-box-top-center; }
.#{$ti-prefix}-align-box-top-center-filled:before { content: $ti-icon-align-box-top-center-filled; }
.#{$ti-prefix}-align-box-top-left:before { content: $ti-icon-align-box-top-left; }
.#{$ti-prefix}-align-box-top-left-filled:before { content: $ti-icon-align-box-top-left-filled; }
.#{$ti-prefix}-align-box-top-right:before { content: $ti-icon-align-box-top-right; }
.#{$ti-prefix}-align-box-top-right-filled:before { content: $ti-icon-align-box-top-right-filled; }
.#{$ti-prefix}-align-center:before { content: $ti-icon-align-center; }
.#{$ti-prefix}-align-justified:before { content: $ti-icon-align-justified; }
.#{$ti-prefix}-align-left:before { content: $ti-icon-align-left; }
.#{$ti-prefix}-align-left-2:before { content: $ti-icon-align-left-2; }
.#{$ti-prefix}-align-right:before { content: $ti-icon-align-right; }
.#{$ti-prefix}-align-right-2:before { content: $ti-icon-align-right-2; }
.#{$ti-prefix}-alpha:before { content: $ti-icon-alpha; }
.#{$ti-prefix}-alphabet-arabic:before { content: $ti-icon-alphabet-arabic; }
.#{$ti-prefix}-alphabet-bangla:before { content: $ti-icon-alphabet-bangla; }
.#{$ti-prefix}-alphabet-cyrillic:before { content: $ti-icon-alphabet-cyrillic; }
.#{$ti-prefix}-alphabet-greek:before { content: $ti-icon-alphabet-greek; }
.#{$ti-prefix}-alphabet-hebrew:before { content: $ti-icon-alphabet-hebrew; }
.#{$ti-prefix}-alphabet-korean:before { content: $ti-icon-alphabet-korean; }
.#{$ti-prefix}-alphabet-latin:before { content: $ti-icon-alphabet-latin; }
.#{$ti-prefix}-alphabet-thai:before { content: $ti-icon-alphabet-thai; }
.#{$ti-prefix}-alt:before { content: $ti-icon-alt; }
.#{$ti-prefix}-ambulance:before { content: $ti-icon-ambulance; }
.#{$ti-prefix}-ampersand:before { content: $ti-icon-ampersand; }
.#{$ti-prefix}-analyze:before { content: $ti-icon-analyze; }
.#{$ti-prefix}-analyze-filled:before { content: $ti-icon-analyze-filled; }
.#{$ti-prefix}-analyze-off:before { content: $ti-icon-analyze-off; }
.#{$ti-prefix}-anchor:before { content: $ti-icon-anchor; }
.#{$ti-prefix}-anchor-off:before { content: $ti-icon-anchor-off; }
.#{$ti-prefix}-angle:before { content: $ti-icon-angle; }
.#{$ti-prefix}-ankh:before { content: $ti-icon-ankh; }
.#{$ti-prefix}-antenna:before { content: $ti-icon-antenna; }
.#{$ti-prefix}-antenna-bars-1:before { content: $ti-icon-antenna-bars-1; }
.#{$ti-prefix}-antenna-bars-2:before { content: $ti-icon-antenna-bars-2; }
.#{$ti-prefix}-antenna-bars-3:before { content: $ti-icon-antenna-bars-3; }
.#{$ti-prefix}-antenna-bars-4:before { content: $ti-icon-antenna-bars-4; }
.#{$ti-prefix}-antenna-bars-5:before { content: $ti-icon-antenna-bars-5; }
.#{$ti-prefix}-antenna-bars-off:before { content: $ti-icon-antenna-bars-off; }
.#{$ti-prefix}-antenna-off:before { content: $ti-icon-antenna-off; }
.#{$ti-prefix}-aperture:before { content: $ti-icon-aperture; }
.#{$ti-prefix}-aperture-off:before { content: $ti-icon-aperture-off; }
.#{$ti-prefix}-api:before { content: $ti-icon-api; }
.#{$ti-prefix}-api-app:before { content: $ti-icon-api-app; }
.#{$ti-prefix}-api-app-off:before { content: $ti-icon-api-app-off; }
.#{$ti-prefix}-api-off:before { content: $ti-icon-api-off; }
.#{$ti-prefix}-app-window:before { content: $ti-icon-app-window; }
.#{$ti-prefix}-app-window-filled:before { content: $ti-icon-app-window-filled; }
.#{$ti-prefix}-apple:before { content: $ti-icon-apple; }
.#{$ti-prefix}-apple-filled:before { content: $ti-icon-apple-filled; }
.#{$ti-prefix}-apps:before { content: $ti-icon-apps; }
.#{$ti-prefix}-apps-filled:before { content: $ti-icon-apps-filled; }
.#{$ti-prefix}-apps-off:before { content: $ti-icon-apps-off; }
.#{$ti-prefix}-archery-arrow:before { content: $ti-icon-archery-arrow; }
.#{$ti-prefix}-archive:before { content: $ti-icon-archive; }
.#{$ti-prefix}-archive-filled:before { content: $ti-icon-archive-filled; }
.#{$ti-prefix}-archive-off:before { content: $ti-icon-archive-off; }
.#{$ti-prefix}-armchair:before { content: $ti-icon-armchair; }
.#{$ti-prefix}-armchair-2:before { content: $ti-icon-armchair-2; }
.#{$ti-prefix}-armchair-2-off:before { content: $ti-icon-armchair-2-off; }
.#{$ti-prefix}-armchair-off:before { content: $ti-icon-armchair-off; }
.#{$ti-prefix}-arrow-autofit-content:before { content: $ti-icon-arrow-autofit-content; }
.#{$ti-prefix}-arrow-autofit-content-filled:before { content: $ti-icon-arrow-autofit-content-filled; }
.#{$ti-prefix}-arrow-autofit-down:before { content: $ti-icon-arrow-autofit-down; }
.#{$ti-prefix}-arrow-autofit-height:before { content: $ti-icon-arrow-autofit-height; }
.#{$ti-prefix}-arrow-autofit-left:before { content: $ti-icon-arrow-autofit-left; }
.#{$ti-prefix}-arrow-autofit-right:before { content: $ti-icon-arrow-autofit-right; }
.#{$ti-prefix}-arrow-autofit-up:before { content: $ti-icon-arrow-autofit-up; }
.#{$ti-prefix}-arrow-autofit-width:before { content: $ti-icon-arrow-autofit-width; }
.#{$ti-prefix}-arrow-back:before { content: $ti-icon-arrow-back; }
.#{$ti-prefix}-arrow-back-up:before { content: $ti-icon-arrow-back-up; }
.#{$ti-prefix}-arrow-back-up-double:before { content: $ti-icon-arrow-back-up-double; }
.#{$ti-prefix}-arrow-badge-down:before { content: $ti-icon-arrow-badge-down; }
.#{$ti-prefix}-arrow-badge-down-filled:before { content: $ti-icon-arrow-badge-down-filled; }
.#{$ti-prefix}-arrow-badge-left:before { content: $ti-icon-arrow-badge-left; }
.#{$ti-prefix}-arrow-badge-left-filled:before { content: $ti-icon-arrow-badge-left-filled; }
.#{$ti-prefix}-arrow-badge-right:before { content: $ti-icon-arrow-badge-right; }
.#{$ti-prefix}-arrow-badge-right-filled:before { content: $ti-icon-arrow-badge-right-filled; }
.#{$ti-prefix}-arrow-badge-up:before { content: $ti-icon-arrow-badge-up; }
.#{$ti-prefix}-arrow-badge-up-filled:before { content: $ti-icon-arrow-badge-up-filled; }
.#{$ti-prefix}-arrow-bar-both:before { content: $ti-icon-arrow-bar-both; }
.#{$ti-prefix}-arrow-bar-down:before { content: $ti-icon-arrow-bar-down; }
.#{$ti-prefix}-arrow-bar-left:before { content: $ti-icon-arrow-bar-left; }
.#{$ti-prefix}-arrow-bar-right:before { content: $ti-icon-arrow-bar-right; }
.#{$ti-prefix}-arrow-bar-to-down:before { content: $ti-icon-arrow-bar-to-down; }
.#{$ti-prefix}-arrow-bar-to-left:before { content: $ti-icon-arrow-bar-to-left; }
.#{$ti-prefix}-arrow-bar-to-right:before { content: $ti-icon-arrow-bar-to-right; }
.#{$ti-prefix}-arrow-bar-to-up:before { content: $ti-icon-arrow-bar-to-up; }
.#{$ti-prefix}-arrow-bar-up:before { content: $ti-icon-arrow-bar-up; }
.#{$ti-prefix}-arrow-bear-left:before { content: $ti-icon-arrow-bear-left; }
.#{$ti-prefix}-arrow-bear-left-2:before { content: $ti-icon-arrow-bear-left-2; }
.#{$ti-prefix}-arrow-bear-right:before { content: $ti-icon-arrow-bear-right; }
.#{$ti-prefix}-arrow-bear-right-2:before { content: $ti-icon-arrow-bear-right-2; }
.#{$ti-prefix}-arrow-big-down:before { content: $ti-icon-arrow-big-down; }
.#{$ti-prefix}-arrow-big-down-filled:before { content: $ti-icon-arrow-big-down-filled; }
.#{$ti-prefix}-arrow-big-down-line:before { content: $ti-icon-arrow-big-down-line; }
.#{$ti-prefix}-arrow-big-down-line-filled:before { content: $ti-icon-arrow-big-down-line-filled; }
.#{$ti-prefix}-arrow-big-down-lines:before { content: $ti-icon-arrow-big-down-lines; }
.#{$ti-prefix}-arrow-big-down-lines-filled:before { content: $ti-icon-arrow-big-down-lines-filled; }
.#{$ti-prefix}-arrow-big-left:before { content: $ti-icon-arrow-big-left; }
.#{$ti-prefix}-arrow-big-left-filled:before { content: $ti-icon-arrow-big-left-filled; }
.#{$ti-prefix}-arrow-big-left-line:before { content: $ti-icon-arrow-big-left-line; }
.#{$ti-prefix}-arrow-big-left-line-filled:before { content: $ti-icon-arrow-big-left-line-filled; }
.#{$ti-prefix}-arrow-big-left-lines:before { content: $ti-icon-arrow-big-left-lines; }
.#{$ti-prefix}-arrow-big-left-lines-filled:before { content: $ti-icon-arrow-big-left-lines-filled; }
.#{$ti-prefix}-arrow-big-right:before { content: $ti-icon-arrow-big-right; }
.#{$ti-prefix}-arrow-big-right-filled:before { content: $ti-icon-arrow-big-right-filled; }
.#{$ti-prefix}-arrow-big-right-line:before { content: $ti-icon-arrow-big-right-line; }
.#{$ti-prefix}-arrow-big-right-line-filled:before { content: $ti-icon-arrow-big-right-line-filled; }
.#{$ti-prefix}-arrow-big-right-lines:before { content: $ti-icon-arrow-big-right-lines; }
.#{$ti-prefix}-arrow-big-right-lines-filled:before { content: $ti-icon-arrow-big-right-lines-filled; }
.#{$ti-prefix}-arrow-big-up:before { content: $ti-icon-arrow-big-up; }
.#{$ti-prefix}-arrow-big-up-filled:before { content: $ti-icon-arrow-big-up-filled; }
.#{$ti-prefix}-arrow-big-up-line:before { content: $ti-icon-arrow-big-up-line; }
.#{$ti-prefix}-arrow-big-up-line-filled:before { content: $ti-icon-arrow-big-up-line-filled; }
.#{$ti-prefix}-arrow-big-up-lines:before { content: $ti-icon-arrow-big-up-lines; }
.#{$ti-prefix}-arrow-big-up-lines-filled:before { content: $ti-icon-arrow-big-up-lines-filled; }
.#{$ti-prefix}-arrow-bounce:before { content: $ti-icon-arrow-bounce; }
.#{$ti-prefix}-arrow-capsule:before { content: $ti-icon-arrow-capsule; }
.#{$ti-prefix}-arrow-curve-left:before { content: $ti-icon-arrow-curve-left; }
.#{$ti-prefix}-arrow-curve-right:before { content: $ti-icon-arrow-curve-right; }
.#{$ti-prefix}-arrow-down:before { content: $ti-icon-arrow-down; }
.#{$ti-prefix}-arrow-down-bar:before { content: $ti-icon-arrow-down-bar; }
.#{$ti-prefix}-arrow-down-circle:before { content: $ti-icon-arrow-down-circle; }
.#{$ti-prefix}-arrow-down-circle-filled:before { content: $ti-icon-arrow-down-circle-filled; }
.#{$ti-prefix}-arrow-down-dashed:before { content: $ti-icon-arrow-down-dashed; }
.#{$ti-prefix}-arrow-down-from-arc:before { content: $ti-icon-arrow-down-from-arc; }
.#{$ti-prefix}-arrow-down-left:before { content: $ti-icon-arrow-down-left; }
.#{$ti-prefix}-arrow-down-left-circle:before { content: $ti-icon-arrow-down-left-circle; }
.#{$ti-prefix}-arrow-down-rhombus:before { content: $ti-icon-arrow-down-rhombus; }
.#{$ti-prefix}-arrow-down-rhombus-filled:before { content: $ti-icon-arrow-down-rhombus-filled; }
.#{$ti-prefix}-arrow-down-right:before { content: $ti-icon-arrow-down-right; }
.#{$ti-prefix}-arrow-down-right-circle:before { content: $ti-icon-arrow-down-right-circle; }
.#{$ti-prefix}-arrow-down-square:before { content: $ti-icon-arrow-down-square; }
.#{$ti-prefix}-arrow-down-square-filled:before { content: $ti-icon-arrow-down-square-filled; }
.#{$ti-prefix}-arrow-down-tail:before { content: $ti-icon-arrow-down-tail; }
.#{$ti-prefix}-arrow-down-to-arc:before { content: $ti-icon-arrow-down-to-arc; }
.#{$ti-prefix}-arrow-elbow-left:before { content: $ti-icon-arrow-elbow-left; }
.#{$ti-prefix}-arrow-elbow-right:before { content: $ti-icon-arrow-elbow-right; }
.#{$ti-prefix}-arrow-fork:before { content: $ti-icon-arrow-fork; }
.#{$ti-prefix}-arrow-forward:before { content: $ti-icon-arrow-forward; }
.#{$ti-prefix}-arrow-forward-up:before { content: $ti-icon-arrow-forward-up; }
.#{$ti-prefix}-arrow-forward-up-double:before { content: $ti-icon-arrow-forward-up-double; }
.#{$ti-prefix}-arrow-guide:before { content: $ti-icon-arrow-guide; }
.#{$ti-prefix}-arrow-guide-filled:before { content: $ti-icon-arrow-guide-filled; }
.#{$ti-prefix}-arrow-iteration:before { content: $ti-icon-arrow-iteration; }
.#{$ti-prefix}-arrow-left:before { content: $ti-icon-arrow-left; }
.#{$ti-prefix}-arrow-left-bar:before { content: $ti-icon-arrow-left-bar; }
.#{$ti-prefix}-arrow-left-circle:before { content: $ti-icon-arrow-left-circle; }
.#{$ti-prefix}-arrow-left-circle-filled:before { content: $ti-icon-arrow-left-circle-filled; }
.#{$ti-prefix}-arrow-left-dashed:before { content: $ti-icon-arrow-left-dashed; }
.#{$ti-prefix}-arrow-left-from-arc:before { content: $ti-icon-arrow-left-from-arc; }
.#{$ti-prefix}-arrow-left-rhombus:before { content: $ti-icon-arrow-left-rhombus; }
.#{$ti-prefix}-arrow-left-rhombus-filled:before { content: $ti-icon-arrow-left-rhombus-filled; }
.#{$ti-prefix}-arrow-left-right:before { content: $ti-icon-arrow-left-right; }
.#{$ti-prefix}-arrow-left-square:before { content: $ti-icon-arrow-left-square; }
.#{$ti-prefix}-arrow-left-square-filled:before { content: $ti-icon-arrow-left-square-filled; }
.#{$ti-prefix}-arrow-left-tail:before { content: $ti-icon-arrow-left-tail; }
.#{$ti-prefix}-arrow-left-to-arc:before { content: $ti-icon-arrow-left-to-arc; }
.#{$ti-prefix}-arrow-loop-left:before { content: $ti-icon-arrow-loop-left; }
.#{$ti-prefix}-arrow-loop-left-2:before { content: $ti-icon-arrow-loop-left-2; }
.#{$ti-prefix}-arrow-loop-right:before { content: $ti-icon-arrow-loop-right; }
.#{$ti-prefix}-arrow-loop-right-2:before { content: $ti-icon-arrow-loop-right-2; }
.#{$ti-prefix}-arrow-merge:before { content: $ti-icon-arrow-merge; }
.#{$ti-prefix}-arrow-merge-alt-left:before { content: $ti-icon-arrow-merge-alt-left; }
.#{$ti-prefix}-arrow-merge-alt-right:before { content: $ti-icon-arrow-merge-alt-right; }
.#{$ti-prefix}-arrow-merge-both:before { content: $ti-icon-arrow-merge-both; }
.#{$ti-prefix}-arrow-merge-left:before { content: $ti-icon-arrow-merge-left; }
.#{$ti-prefix}-arrow-merge-right:before { content: $ti-icon-arrow-merge-right; }
.#{$ti-prefix}-arrow-move-down:before { content: $ti-icon-arrow-move-down; }
.#{$ti-prefix}-arrow-move-down-filled:before { content: $ti-icon-arrow-move-down-filled; }
.#{$ti-prefix}-arrow-move-left:before { content: $ti-icon-arrow-move-left; }
.#{$ti-prefix}-arrow-move-left-filled:before { content: $ti-icon-arrow-move-left-filled; }
.#{$ti-prefix}-arrow-move-right:before { content: $ti-icon-arrow-move-right; }
.#{$ti-prefix}-arrow-move-right-filled:before { content: $ti-icon-arrow-move-right-filled; }
.#{$ti-prefix}-arrow-move-up:before { content: $ti-icon-arrow-move-up; }
.#{$ti-prefix}-arrow-move-up-filled:before { content: $ti-icon-arrow-move-up-filled; }
.#{$ti-prefix}-arrow-narrow-down:before { content: $ti-icon-arrow-narrow-down; }
.#{$ti-prefix}-arrow-narrow-down-dashed:before { content: $ti-icon-arrow-narrow-down-dashed; }
.#{$ti-prefix}-arrow-narrow-left:before { content: $ti-icon-arrow-narrow-left; }
.#{$ti-prefix}-arrow-narrow-left-dashed:before { content: $ti-icon-arrow-narrow-left-dashed; }
.#{$ti-prefix}-arrow-narrow-right:before { content: $ti-icon-arrow-narrow-right; }
.#{$ti-prefix}-arrow-narrow-right-dashed:before { content: $ti-icon-arrow-narrow-right-dashed; }
.#{$ti-prefix}-arrow-narrow-up:before { content: $ti-icon-arrow-narrow-up; }
.#{$ti-prefix}-arrow-narrow-up-dashed:before { content: $ti-icon-arrow-narrow-up-dashed; }
.#{$ti-prefix}-arrow-ramp-left:before { content: $ti-icon-arrow-ramp-left; }
.#{$ti-prefix}-arrow-ramp-left-2:before { content: $ti-icon-arrow-ramp-left-2; }
.#{$ti-prefix}-arrow-ramp-left-3:before { content: $ti-icon-arrow-ramp-left-3; }
.#{$ti-prefix}-arrow-ramp-right:before { content: $ti-icon-arrow-ramp-right; }
.#{$ti-prefix}-arrow-ramp-right-2:before { content: $ti-icon-arrow-ramp-right-2; }
.#{$ti-prefix}-arrow-ramp-right-3:before { content: $ti-icon-arrow-ramp-right-3; }
.#{$ti-prefix}-arrow-right:before { content: $ti-icon-arrow-right; }
.#{$ti-prefix}-arrow-right-bar:before { content: $ti-icon-arrow-right-bar; }
.#{$ti-prefix}-arrow-right-circle:before { content: $ti-icon-arrow-right-circle; }
.#{$ti-prefix}-arrow-right-circle-filled:before { content: $ti-icon-arrow-right-circle-filled; }
.#{$ti-prefix}-arrow-right-dashed:before { content: $ti-icon-arrow-right-dashed; }
.#{$ti-prefix}-arrow-right-from-arc:before { content: $ti-icon-arrow-right-from-arc; }
.#{$ti-prefix}-arrow-right-rhombus:before { content: $ti-icon-arrow-right-rhombus; }
.#{$ti-prefix}-arrow-right-rhombus-filled:before { content: $ti-icon-arrow-right-rhombus-filled; }
.#{$ti-prefix}-arrow-right-square:before { content: $ti-icon-arrow-right-square; }
.#{$ti-prefix}-arrow-right-square-filled:before { content: $ti-icon-arrow-right-square-filled; }
.#{$ti-prefix}-arrow-right-tail:before { content: $ti-icon-arrow-right-tail; }
.#{$ti-prefix}-arrow-right-to-arc:before { content: $ti-icon-arrow-right-to-arc; }
.#{$ti-prefix}-arrow-rotary-first-left:before { content: $ti-icon-arrow-rotary-first-left; }
.#{$ti-prefix}-arrow-rotary-first-right:before { content: $ti-icon-arrow-rotary-first-right; }
.#{$ti-prefix}-arrow-rotary-last-left:before { content: $ti-icon-arrow-rotary-last-left; }
.#{$ti-prefix}-arrow-rotary-last-right:before { content: $ti-icon-arrow-rotary-last-right; }
.#{$ti-prefix}-arrow-rotary-left:before { content: $ti-icon-arrow-rotary-left; }
.#{$ti-prefix}-arrow-rotary-right:before { content: $ti-icon-arrow-rotary-right; }
.#{$ti-prefix}-arrow-rotary-straight:before { content: $ti-icon-arrow-rotary-straight; }
.#{$ti-prefix}-arrow-roundabout-left:before { content: $ti-icon-arrow-roundabout-left; }
.#{$ti-prefix}-arrow-roundabout-right:before { content: $ti-icon-arrow-roundabout-right; }
.#{$ti-prefix}-arrow-sharp-turn-left:before { content: $ti-icon-arrow-sharp-turn-left; }
.#{$ti-prefix}-arrow-sharp-turn-right:before { content: $ti-icon-arrow-sharp-turn-right; }
.#{$ti-prefix}-arrow-up:before { content: $ti-icon-arrow-up; }
.#{$ti-prefix}-arrow-up-bar:before { content: $ti-icon-arrow-up-bar; }
.#{$ti-prefix}-arrow-up-circle:before { content: $ti-icon-arrow-up-circle; }
.#{$ti-prefix}-arrow-up-circle-filled:before { content: $ti-icon-arrow-up-circle-filled; }
.#{$ti-prefix}-arrow-up-dashed:before { content: $ti-icon-arrow-up-dashed; }
.#{$ti-prefix}-arrow-up-from-arc:before { content: $ti-icon-arrow-up-from-arc; }
.#{$ti-prefix}-arrow-up-left:before { content: $ti-icon-arrow-up-left; }
.#{$ti-prefix}-arrow-up-left-circle:before { content: $ti-icon-arrow-up-left-circle; }
.#{$ti-prefix}-arrow-up-rhombus:before { content: $ti-icon-arrow-up-rhombus; }
.#{$ti-prefix}-arrow-up-rhombus-filled:before { content: $ti-icon-arrow-up-rhombus-filled; }
.#{$ti-prefix}-arrow-up-right:before { content: $ti-icon-arrow-up-right; }
.#{$ti-prefix}-arrow-up-right-circle:before { content: $ti-icon-arrow-up-right-circle; }
.#{$ti-prefix}-arrow-up-square:before { content: $ti-icon-arrow-up-square; }
.#{$ti-prefix}-arrow-up-square-filled:before { content: $ti-icon-arrow-up-square-filled; }
.#{$ti-prefix}-arrow-up-tail:before { content: $ti-icon-arrow-up-tail; }
.#{$ti-prefix}-arrow-up-to-arc:before { content: $ti-icon-arrow-up-to-arc; }
.#{$ti-prefix}-arrow-wave-left-down:before { content: $ti-icon-arrow-wave-left-down; }
.#{$ti-prefix}-arrow-wave-left-up:before { content: $ti-icon-arrow-wave-left-up; }
.#{$ti-prefix}-arrow-wave-right-down:before { content: $ti-icon-arrow-wave-right-down; }
.#{$ti-prefix}-arrow-wave-right-up:before { content: $ti-icon-arrow-wave-right-up; }
.#{$ti-prefix}-arrow-zig-zag:before { content: $ti-icon-arrow-zig-zag; }
.#{$ti-prefix}-arrows-cross:before { content: $ti-icon-arrows-cross; }
.#{$ti-prefix}-arrows-diagonal:before { content: $ti-icon-arrows-diagonal; }
.#{$ti-prefix}-arrows-diagonal-2:before { content: $ti-icon-arrows-diagonal-2; }
.#{$ti-prefix}-arrows-diagonal-minimize:before { content: $ti-icon-arrows-diagonal-minimize; }
.#{$ti-prefix}-arrows-diagonal-minimize-2:before { content: $ti-icon-arrows-diagonal-minimize-2; }
.#{$ti-prefix}-arrows-diff:before { content: $ti-icon-arrows-diff; }
.#{$ti-prefix}-arrows-double-ne-sw:before { content: $ti-icon-arrows-double-ne-sw; }
.#{$ti-prefix}-arrows-double-nw-se:before { content: $ti-icon-arrows-double-nw-se; }
.#{$ti-prefix}-arrows-double-se-nw:before { content: $ti-icon-arrows-double-se-nw; }
.#{$ti-prefix}-arrows-double-sw-ne:before { content: $ti-icon-arrows-double-sw-ne; }
.#{$ti-prefix}-arrows-down:before { content: $ti-icon-arrows-down; }
.#{$ti-prefix}-arrows-down-up:before { content: $ti-icon-arrows-down-up; }
.#{$ti-prefix}-arrows-exchange:before { content: $ti-icon-arrows-exchange; }
.#{$ti-prefix}-arrows-exchange-2:before { content: $ti-icon-arrows-exchange-2; }
.#{$ti-prefix}-arrows-horizontal:before { content: $ti-icon-arrows-horizontal; }
.#{$ti-prefix}-arrows-join:before { content: $ti-icon-arrows-join; }
.#{$ti-prefix}-arrows-join-2:before { content: $ti-icon-arrows-join-2; }
.#{$ti-prefix}-arrows-left:before { content: $ti-icon-arrows-left; }
.#{$ti-prefix}-arrows-left-down:before { content: $ti-icon-arrows-left-down; }
.#{$ti-prefix}-arrows-left-right:before { content: $ti-icon-arrows-left-right; }
.#{$ti-prefix}-arrows-maximize:before { content: $ti-icon-arrows-maximize; }
.#{$ti-prefix}-arrows-minimize:before { content: $ti-icon-arrows-minimize; }
.#{$ti-prefix}-arrows-move:before { content: $ti-icon-arrows-move; }
.#{$ti-prefix}-arrows-move-horizontal:before { content: $ti-icon-arrows-move-horizontal; }
.#{$ti-prefix}-arrows-move-vertical:before { content: $ti-icon-arrows-move-vertical; }
.#{$ti-prefix}-arrows-random:before { content: $ti-icon-arrows-random; }
.#{$ti-prefix}-arrows-right:before { content: $ti-icon-arrows-right; }
.#{$ti-prefix}-arrows-right-down:before { content: $ti-icon-arrows-right-down; }
.#{$ti-prefix}-arrows-right-left:before { content: $ti-icon-arrows-right-left; }
.#{$ti-prefix}-arrows-shuffle:before { content: $ti-icon-arrows-shuffle; }
.#{$ti-prefix}-arrows-shuffle-2:before { content: $ti-icon-arrows-shuffle-2; }
.#{$ti-prefix}-arrows-sort:before { content: $ti-icon-arrows-sort; }
.#{$ti-prefix}-arrows-split:before { content: $ti-icon-arrows-split; }
.#{$ti-prefix}-arrows-split-2:before { content: $ti-icon-arrows-split-2; }
.#{$ti-prefix}-arrows-transfer-down:before { content: $ti-icon-arrows-transfer-down; }
.#{$ti-prefix}-arrows-transfer-up:before { content: $ti-icon-arrows-transfer-up; }
.#{$ti-prefix}-arrows-transfer-up-down:before { content: $ti-icon-arrows-transfer-up-down; }
.#{$ti-prefix}-arrows-up:before { content: $ti-icon-arrows-up; }
.#{$ti-prefix}-arrows-up-down:before { content: $ti-icon-arrows-up-down; }
.#{$ti-prefix}-arrows-up-left:before { content: $ti-icon-arrows-up-left; }
.#{$ti-prefix}-arrows-up-right:before { content: $ti-icon-arrows-up-right; }
.#{$ti-prefix}-arrows-vertical:before { content: $ti-icon-arrows-vertical; }
.#{$ti-prefix}-artboard:before { content: $ti-icon-artboard; }
.#{$ti-prefix}-artboard-filled:before { content: $ti-icon-artboard-filled; }
.#{$ti-prefix}-artboard-off:before { content: $ti-icon-artboard-off; }
.#{$ti-prefix}-article:before { content: $ti-icon-article; }
.#{$ti-prefix}-article-filled:before { content: $ti-icon-article-filled; }
.#{$ti-prefix}-article-off:before { content: $ti-icon-article-off; }
.#{$ti-prefix}-aspect-ratio:before { content: $ti-icon-aspect-ratio; }
.#{$ti-prefix}-aspect-ratio-filled:before { content: $ti-icon-aspect-ratio-filled; }
.#{$ti-prefix}-aspect-ratio-off:before { content: $ti-icon-aspect-ratio-off; }
.#{$ti-prefix}-assembly:before { content: $ti-icon-assembly; }
.#{$ti-prefix}-assembly-filled:before { content: $ti-icon-assembly-filled; }
.#{$ti-prefix}-assembly-off:before { content: $ti-icon-assembly-off; }
.#{$ti-prefix}-asset:before { content: $ti-icon-asset; }
.#{$ti-prefix}-asset-filled:before { content: $ti-icon-asset-filled; }
.#{$ti-prefix}-asterisk:before { content: $ti-icon-asterisk; }
.#{$ti-prefix}-asterisk-simple:before { content: $ti-icon-asterisk-simple; }
.#{$ti-prefix}-at:before { content: $ti-icon-at; }
.#{$ti-prefix}-at-off:before { content: $ti-icon-at-off; }
.#{$ti-prefix}-atom:before { content: $ti-icon-atom; }
.#{$ti-prefix}-atom-2:before { content: $ti-icon-atom-2; }
.#{$ti-prefix}-atom-2-filled:before { content: $ti-icon-atom-2-filled; }
.#{$ti-prefix}-atom-off:before { content: $ti-icon-atom-off; }
.#{$ti-prefix}-augmented-reality:before { content: $ti-icon-augmented-reality; }
.#{$ti-prefix}-augmented-reality-2:before { content: $ti-icon-augmented-reality-2; }
.#{$ti-prefix}-augmented-reality-off:before { content: $ti-icon-augmented-reality-off; }
.#{$ti-prefix}-auth-2fa:before { content: $ti-icon-auth-2fa; }
.#{$ti-prefix}-automatic-gearbox:before { content: $ti-icon-automatic-gearbox; }
.#{$ti-prefix}-automatic-gearbox-filled:before { content: $ti-icon-automatic-gearbox-filled; }
.#{$ti-prefix}-automation:before { content: $ti-icon-automation; }
.#{$ti-prefix}-avocado:before { content: $ti-icon-avocado; }
.#{$ti-prefix}-award:before { content: $ti-icon-award; }
.#{$ti-prefix}-award-filled:before { content: $ti-icon-award-filled; }
.#{$ti-prefix}-award-off:before { content: $ti-icon-award-off; }
.#{$ti-prefix}-axe:before { content: $ti-icon-axe; }
.#{$ti-prefix}-axis-x:before { content: $ti-icon-axis-x; }
.#{$ti-prefix}-axis-y:before { content: $ti-icon-axis-y; }
.#{$ti-prefix}-baby-bottle:before { content: $ti-icon-baby-bottle; }
.#{$ti-prefix}-baby-carriage:before { content: $ti-icon-baby-carriage; }
.#{$ti-prefix}-baby-carriage-filled:before { content: $ti-icon-baby-carriage-filled; }
.#{$ti-prefix}-background:before { content: $ti-icon-background; }
.#{$ti-prefix}-backhoe:before { content: $ti-icon-backhoe; }
.#{$ti-prefix}-backpack:before { content: $ti-icon-backpack; }
.#{$ti-prefix}-backpack-off:before { content: $ti-icon-backpack-off; }
.#{$ti-prefix}-backslash:before { content: $ti-icon-backslash; }
.#{$ti-prefix}-backspace:before { content: $ti-icon-backspace; }
.#{$ti-prefix}-backspace-filled:before { content: $ti-icon-backspace-filled; }
.#{$ti-prefix}-badge:before { content: $ti-icon-badge; }
.#{$ti-prefix}-badge-3d:before { content: $ti-icon-badge-3d; }
.#{$ti-prefix}-badge-3d-filled:before { content: $ti-icon-badge-3d-filled; }
.#{$ti-prefix}-badge-4k:before { content: $ti-icon-badge-4k; }
.#{$ti-prefix}-badge-4k-filled:before { content: $ti-icon-badge-4k-filled; }
.#{$ti-prefix}-badge-8k:before { content: $ti-icon-badge-8k; }
.#{$ti-prefix}-badge-8k-filled:before { content: $ti-icon-badge-8k-filled; }
.#{$ti-prefix}-badge-ad:before { content: $ti-icon-badge-ad; }
.#{$ti-prefix}-badge-ad-filled:before { content: $ti-icon-badge-ad-filled; }
.#{$ti-prefix}-badge-ad-off:before { content: $ti-icon-badge-ad-off; }
.#{$ti-prefix}-badge-ar:before { content: $ti-icon-badge-ar; }
.#{$ti-prefix}-badge-ar-filled:before { content: $ti-icon-badge-ar-filled; }
.#{$ti-prefix}-badge-cc:before { content: $ti-icon-badge-cc; }
.#{$ti-prefix}-badge-cc-filled:before { content: $ti-icon-badge-cc-filled; }
.#{$ti-prefix}-badge-filled:before { content: $ti-icon-badge-filled; }
.#{$ti-prefix}-badge-hd:before { content: $ti-icon-badge-hd; }
.#{$ti-prefix}-badge-hd-filled:before { content: $ti-icon-badge-hd-filled; }
.#{$ti-prefix}-badge-off:before { content: $ti-icon-badge-off; }
.#{$ti-prefix}-badge-sd:before { content: $ti-icon-badge-sd; }
.#{$ti-prefix}-badge-sd-filled:before { content: $ti-icon-badge-sd-filled; }
.#{$ti-prefix}-badge-tm:before { content: $ti-icon-badge-tm; }
.#{$ti-prefix}-badge-tm-filled:before { content: $ti-icon-badge-tm-filled; }
.#{$ti-prefix}-badge-vo:before { content: $ti-icon-badge-vo; }
.#{$ti-prefix}-badge-vo-filled:before { content: $ti-icon-badge-vo-filled; }
.#{$ti-prefix}-badge-vr:before { content: $ti-icon-badge-vr; }
.#{$ti-prefix}-badge-vr-filled:before { content: $ti-icon-badge-vr-filled; }
.#{$ti-prefix}-badge-wc:before { content: $ti-icon-badge-wc; }
.#{$ti-prefix}-badge-wc-filled:before { content: $ti-icon-badge-wc-filled; }
.#{$ti-prefix}-badges:before { content: $ti-icon-badges; }
.#{$ti-prefix}-badges-filled:before { content: $ti-icon-badges-filled; }
.#{$ti-prefix}-badges-off:before { content: $ti-icon-badges-off; }
.#{$ti-prefix}-baguette:before { content: $ti-icon-baguette; }
.#{$ti-prefix}-ball-american-football:before { content: $ti-icon-ball-american-football; }
.#{$ti-prefix}-ball-american-football-off:before { content: $ti-icon-ball-american-football-off; }
.#{$ti-prefix}-ball-baseball:before { content: $ti-icon-ball-baseball; }
.#{$ti-prefix}-ball-basketball:before { content: $ti-icon-ball-basketball; }
.#{$ti-prefix}-ball-bowling:before { content: $ti-icon-ball-bowling; }
.#{$ti-prefix}-ball-football:before { content: $ti-icon-ball-football; }
.#{$ti-prefix}-ball-football-off:before { content: $ti-icon-ball-football-off; }
.#{$ti-prefix}-ball-tennis:before { content: $ti-icon-ball-tennis; }
.#{$ti-prefix}-ball-volleyball:before { content: $ti-icon-ball-volleyball; }
.#{$ti-prefix}-balloon:before { content: $ti-icon-balloon; }
.#{$ti-prefix}-balloon-filled:before { content: $ti-icon-balloon-filled; }
.#{$ti-prefix}-balloon-off:before { content: $ti-icon-balloon-off; }
.#{$ti-prefix}-ballpen:before { content: $ti-icon-ballpen; }
.#{$ti-prefix}-ballpen-filled:before { content: $ti-icon-ballpen-filled; }
.#{$ti-prefix}-ballpen-off:before { content: $ti-icon-ballpen-off; }
.#{$ti-prefix}-ban:before { content: $ti-icon-ban; }
.#{$ti-prefix}-bandage:before { content: $ti-icon-bandage; }
.#{$ti-prefix}-bandage-filled:before { content: $ti-icon-bandage-filled; }
.#{$ti-prefix}-bandage-off:before { content: $ti-icon-bandage-off; }
.#{$ti-prefix}-barbell:before { content: $ti-icon-barbell; }
.#{$ti-prefix}-barbell-filled:before { content: $ti-icon-barbell-filled; }
.#{$ti-prefix}-barbell-off:before { content: $ti-icon-barbell-off; }
.#{$ti-prefix}-barcode:before { content: $ti-icon-barcode; }
.#{$ti-prefix}-barcode-off:before { content: $ti-icon-barcode-off; }
.#{$ti-prefix}-barrel:before { content: $ti-icon-barrel; }
.#{$ti-prefix}-barrel-off:before { content: $ti-icon-barrel-off; }
.#{$ti-prefix}-barrier-block:before { content: $ti-icon-barrier-block; }
.#{$ti-prefix}-barrier-block-filled:before { content: $ti-icon-barrier-block-filled; }
.#{$ti-prefix}-barrier-block-off:before { content: $ti-icon-barrier-block-off; }
.#{$ti-prefix}-baseline:before { content: $ti-icon-baseline; }
.#{$ti-prefix}-baseline-density-large:before { content: $ti-icon-baseline-density-large; }
.#{$ti-prefix}-baseline-density-medium:before { content: $ti-icon-baseline-density-medium; }
.#{$ti-prefix}-baseline-density-small:before { content: $ti-icon-baseline-density-small; }
.#{$ti-prefix}-basket:before { content: $ti-icon-basket; }
.#{$ti-prefix}-basket-bolt:before { content: $ti-icon-basket-bolt; }
.#{$ti-prefix}-basket-cancel:before { content: $ti-icon-basket-cancel; }
.#{$ti-prefix}-basket-check:before { content: $ti-icon-basket-check; }
.#{$ti-prefix}-basket-code:before { content: $ti-icon-basket-code; }
.#{$ti-prefix}-basket-cog:before { content: $ti-icon-basket-cog; }
.#{$ti-prefix}-basket-discount:before { content: $ti-icon-basket-discount; }
.#{$ti-prefix}-basket-dollar:before { content: $ti-icon-basket-dollar; }
.#{$ti-prefix}-basket-down:before { content: $ti-icon-basket-down; }
.#{$ti-prefix}-basket-exclamation:before { content: $ti-icon-basket-exclamation; }
.#{$ti-prefix}-basket-filled:before { content: $ti-icon-basket-filled; }
.#{$ti-prefix}-basket-heart:before { content: $ti-icon-basket-heart; }
.#{$ti-prefix}-basket-minus:before { content: $ti-icon-basket-minus; }
.#{$ti-prefix}-basket-off:before { content: $ti-icon-basket-off; }
.#{$ti-prefix}-basket-pause:before { content: $ti-icon-basket-pause; }
.#{$ti-prefix}-basket-pin:before { content: $ti-icon-basket-pin; }
.#{$ti-prefix}-basket-plus:before { content: $ti-icon-basket-plus; }
.#{$ti-prefix}-basket-question:before { content: $ti-icon-basket-question; }
.#{$ti-prefix}-basket-search:before { content: $ti-icon-basket-search; }
.#{$ti-prefix}-basket-share:before { content: $ti-icon-basket-share; }
.#{$ti-prefix}-basket-star:before { content: $ti-icon-basket-star; }
.#{$ti-prefix}-basket-up:before { content: $ti-icon-basket-up; }
.#{$ti-prefix}-basket-x:before { content: $ti-icon-basket-x; }
.#{$ti-prefix}-bat:before { content: $ti-icon-bat; }
.#{$ti-prefix}-bath:before { content: $ti-icon-bath; }
.#{$ti-prefix}-bath-filled:before { content: $ti-icon-bath-filled; }
.#{$ti-prefix}-bath-off:before { content: $ti-icon-bath-off; }
.#{$ti-prefix}-battery:before { content: $ti-icon-battery; }
.#{$ti-prefix}-battery-1:before { content: $ti-icon-battery-1; }
.#{$ti-prefix}-battery-1-filled:before { content: $ti-icon-battery-1-filled; }
.#{$ti-prefix}-battery-2:before { content: $ti-icon-battery-2; }
.#{$ti-prefix}-battery-2-filled:before { content: $ti-icon-battery-2-filled; }
.#{$ti-prefix}-battery-3:before { content: $ti-icon-battery-3; }
.#{$ti-prefix}-battery-3-filled:before { content: $ti-icon-battery-3-filled; }
.#{$ti-prefix}-battery-4:before { content: $ti-icon-battery-4; }
.#{$ti-prefix}-battery-4-filled:before { content: $ti-icon-battery-4-filled; }
.#{$ti-prefix}-battery-automotive:before { content: $ti-icon-battery-automotive; }
.#{$ti-prefix}-battery-automotive-filled:before { content: $ti-icon-battery-automotive-filled; }
.#{$ti-prefix}-battery-charging:before { content: $ti-icon-battery-charging; }
.#{$ti-prefix}-battery-charging-2:before { content: $ti-icon-battery-charging-2; }
.#{$ti-prefix}-battery-eco:before { content: $ti-icon-battery-eco; }
.#{$ti-prefix}-battery-exclamation:before { content: $ti-icon-battery-exclamation; }
.#{$ti-prefix}-battery-filled:before { content: $ti-icon-battery-filled; }
.#{$ti-prefix}-battery-off:before { content: $ti-icon-battery-off; }
.#{$ti-prefix}-battery-spark:before { content: $ti-icon-battery-spark; }
.#{$ti-prefix}-battery-vertical:before { content: $ti-icon-battery-vertical; }
.#{$ti-prefix}-battery-vertical-1:before { content: $ti-icon-battery-vertical-1; }
.#{$ti-prefix}-battery-vertical-1-filled:before { content: $ti-icon-battery-vertical-1-filled; }
.#{$ti-prefix}-battery-vertical-2:before { content: $ti-icon-battery-vertical-2; }
.#{$ti-prefix}-battery-vertical-2-filled:before { content: $ti-icon-battery-vertical-2-filled; }
.#{$ti-prefix}-battery-vertical-3:before { content: $ti-icon-battery-vertical-3; }
.#{$ti-prefix}-battery-vertical-3-filled:before { content: $ti-icon-battery-vertical-3-filled; }
.#{$ti-prefix}-battery-vertical-4:before { content: $ti-icon-battery-vertical-4; }
.#{$ti-prefix}-battery-vertical-4-filled:before { content: $ti-icon-battery-vertical-4-filled; }
.#{$ti-prefix}-battery-vertical-charging:before { content: $ti-icon-battery-vertical-charging; }
.#{$ti-prefix}-battery-vertical-charging-2:before { content: $ti-icon-battery-vertical-charging-2; }
.#{$ti-prefix}-battery-vertical-eco:before { content: $ti-icon-battery-vertical-eco; }
.#{$ti-prefix}-battery-vertical-exclamation:before { content: $ti-icon-battery-vertical-exclamation; }
.#{$ti-prefix}-battery-vertical-filled:before { content: $ti-icon-battery-vertical-filled; }
.#{$ti-prefix}-battery-vertical-off:before { content: $ti-icon-battery-vertical-off; }
.#{$ti-prefix}-beach:before { content: $ti-icon-beach; }
.#{$ti-prefix}-beach-off:before { content: $ti-icon-beach-off; }
.#{$ti-prefix}-bed:before { content: $ti-icon-bed; }
.#{$ti-prefix}-bed-filled:before { content: $ti-icon-bed-filled; }
.#{$ti-prefix}-bed-flat:before { content: $ti-icon-bed-flat; }
.#{$ti-prefix}-bed-flat-filled:before { content: $ti-icon-bed-flat-filled; }
.#{$ti-prefix}-bed-off:before { content: $ti-icon-bed-off; }
.#{$ti-prefix}-beer:before { content: $ti-icon-beer; }
.#{$ti-prefix}-beer-filled:before { content: $ti-icon-beer-filled; }
.#{$ti-prefix}-beer-off:before { content: $ti-icon-beer-off; }
.#{$ti-prefix}-bell:before { content: $ti-icon-bell; }
.#{$ti-prefix}-bell-bolt:before { content: $ti-icon-bell-bolt; }
.#{$ti-prefix}-bell-cancel:before { content: $ti-icon-bell-cancel; }
.#{$ti-prefix}-bell-check:before { content: $ti-icon-bell-check; }
.#{$ti-prefix}-bell-code:before { content: $ti-icon-bell-code; }
.#{$ti-prefix}-bell-cog:before { content: $ti-icon-bell-cog; }
.#{$ti-prefix}-bell-dollar:before { content: $ti-icon-bell-dollar; }
.#{$ti-prefix}-bell-down:before { content: $ti-icon-bell-down; }
.#{$ti-prefix}-bell-exclamation:before { content: $ti-icon-bell-exclamation; }
.#{$ti-prefix}-bell-filled:before { content: $ti-icon-bell-filled; }
.#{$ti-prefix}-bell-heart:before { content: $ti-icon-bell-heart; }
.#{$ti-prefix}-bell-minus:before { content: $ti-icon-bell-minus; }
.#{$ti-prefix}-bell-minus-filled:before { content: $ti-icon-bell-minus-filled; }
.#{$ti-prefix}-bell-off:before { content: $ti-icon-bell-off; }
.#{$ti-prefix}-bell-pause:before { content: $ti-icon-bell-pause; }
.#{$ti-prefix}-bell-pin:before { content: $ti-icon-bell-pin; }
.#{$ti-prefix}-bell-plus:before { content: $ti-icon-bell-plus; }
.#{$ti-prefix}-bell-plus-filled:before { content: $ti-icon-bell-plus-filled; }
.#{$ti-prefix}-bell-question:before { content: $ti-icon-bell-question; }
.#{$ti-prefix}-bell-ringing:before { content: $ti-icon-bell-ringing; }
.#{$ti-prefix}-bell-ringing-2:before { content: $ti-icon-bell-ringing-2; }
.#{$ti-prefix}-bell-ringing-2-filled:before { content: $ti-icon-bell-ringing-2-filled; }
.#{$ti-prefix}-bell-ringing-filled:before { content: $ti-icon-bell-ringing-filled; }
.#{$ti-prefix}-bell-school:before { content: $ti-icon-bell-school; }
.#{$ti-prefix}-bell-search:before { content: $ti-icon-bell-search; }
.#{$ti-prefix}-bell-share:before { content: $ti-icon-bell-share; }
.#{$ti-prefix}-bell-star:before { content: $ti-icon-bell-star; }
.#{$ti-prefix}-bell-up:before { content: $ti-icon-bell-up; }
.#{$ti-prefix}-bell-x:before { content: $ti-icon-bell-x; }
.#{$ti-prefix}-bell-x-filled:before { content: $ti-icon-bell-x-filled; }
.#{$ti-prefix}-bell-z:before { content: $ti-icon-bell-z; }
.#{$ti-prefix}-bell-z-filled:before { content: $ti-icon-bell-z-filled; }
.#{$ti-prefix}-beta:before { content: $ti-icon-beta; }
.#{$ti-prefix}-bible:before { content: $ti-icon-bible; }
.#{$ti-prefix}-bike:before { content: $ti-icon-bike; }
.#{$ti-prefix}-bike-filled:before { content: $ti-icon-bike-filled; }
.#{$ti-prefix}-bike-off:before { content: $ti-icon-bike-off; }
.#{$ti-prefix}-binary:before { content: $ti-icon-binary; }
.#{$ti-prefix}-binary-off:before { content: $ti-icon-binary-off; }
.#{$ti-prefix}-binary-tree:before { content: $ti-icon-binary-tree; }
.#{$ti-prefix}-binary-tree-2:before { content: $ti-icon-binary-tree-2; }
.#{$ti-prefix}-binary-tree-2-filled:before { content: $ti-icon-binary-tree-2-filled; }
.#{$ti-prefix}-binary-tree-filled:before { content: $ti-icon-binary-tree-filled; }
.#{$ti-prefix}-binoculars:before { content: $ti-icon-binoculars; }
.#{$ti-prefix}-binoculars-filled:before { content: $ti-icon-binoculars-filled; }
.#{$ti-prefix}-biohazard:before { content: $ti-icon-biohazard; }
.#{$ti-prefix}-biohazard-filled:before { content: $ti-icon-biohazard-filled; }
.#{$ti-prefix}-biohazard-off:before { content: $ti-icon-biohazard-off; }
.#{$ti-prefix}-blade:before { content: $ti-icon-blade; }
.#{$ti-prefix}-blade-filled:before { content: $ti-icon-blade-filled; }
.#{$ti-prefix}-bleach:before { content: $ti-icon-bleach; }
.#{$ti-prefix}-bleach-chlorine:before { content: $ti-icon-bleach-chlorine; }
.#{$ti-prefix}-bleach-no-chlorine:before { content: $ti-icon-bleach-no-chlorine; }
.#{$ti-prefix}-bleach-off:before { content: $ti-icon-bleach-off; }
.#{$ti-prefix}-blend-mode:before { content: $ti-icon-blend-mode; }
.#{$ti-prefix}-blender:before { content: $ti-icon-blender; }
.#{$ti-prefix}-blender-filled:before { content: $ti-icon-blender-filled; }
.#{$ti-prefix}-blob:before { content: $ti-icon-blob; }
.#{$ti-prefix}-blob-filled:before { content: $ti-icon-blob-filled; }
.#{$ti-prefix}-blockquote:before { content: $ti-icon-blockquote; }
.#{$ti-prefix}-bluetooth:before { content: $ti-icon-bluetooth; }
.#{$ti-prefix}-bluetooth-connected:before { content: $ti-icon-bluetooth-connected; }
.#{$ti-prefix}-bluetooth-off:before { content: $ti-icon-bluetooth-off; }
.#{$ti-prefix}-bluetooth-x:before { content: $ti-icon-bluetooth-x; }
.#{$ti-prefix}-blur:before { content: $ti-icon-blur; }
.#{$ti-prefix}-blur-off:before { content: $ti-icon-blur-off; }
.#{$ti-prefix}-bmp:before { content: $ti-icon-bmp; }
.#{$ti-prefix}-body-scan:before { content: $ti-icon-body-scan; }
.#{$ti-prefix}-bold:before { content: $ti-icon-bold; }
.#{$ti-prefix}-bold-off:before { content: $ti-icon-bold-off; }
.#{$ti-prefix}-bolt:before { content: $ti-icon-bolt; }
.#{$ti-prefix}-bolt-filled:before { content: $ti-icon-bolt-filled; }
.#{$ti-prefix}-bolt-off:before { content: $ti-icon-bolt-off; }
.#{$ti-prefix}-bomb:before { content: $ti-icon-bomb; }
.#{$ti-prefix}-bomb-filled:before { content: $ti-icon-bomb-filled; }
.#{$ti-prefix}-bone:before { content: $ti-icon-bone; }
.#{$ti-prefix}-bone-filled:before { content: $ti-icon-bone-filled; }
.#{$ti-prefix}-bone-off:before { content: $ti-icon-bone-off; }
.#{$ti-prefix}-bong:before { content: $ti-icon-bong; }
.#{$ti-prefix}-bong-filled:before { content: $ti-icon-bong-filled; }
.#{$ti-prefix}-bong-off:before { content: $ti-icon-bong-off; }
.#{$ti-prefix}-book:before { content: $ti-icon-book; }
.#{$ti-prefix}-book-2:before { content: $ti-icon-book-2; }
.#{$ti-prefix}-book-download:before { content: $ti-icon-book-download; }
.#{$ti-prefix}-book-filled:before { content: $ti-icon-book-filled; }
.#{$ti-prefix}-book-off:before { content: $ti-icon-book-off; }
.#{$ti-prefix}-book-upload:before { content: $ti-icon-book-upload; }
.#{$ti-prefix}-bookmark:before { content: $ti-icon-bookmark; }
.#{$ti-prefix}-bookmark-ai:before { content: $ti-icon-bookmark-ai; }
.#{$ti-prefix}-bookmark-edit:before { content: $ti-icon-bookmark-edit; }
.#{$ti-prefix}-bookmark-filled:before { content: $ti-icon-bookmark-filled; }
.#{$ti-prefix}-bookmark-minus:before { content: $ti-icon-bookmark-minus; }
.#{$ti-prefix}-bookmark-off:before { content: $ti-icon-bookmark-off; }
.#{$ti-prefix}-bookmark-plus:before { content: $ti-icon-bookmark-plus; }
.#{$ti-prefix}-bookmark-question:before { content: $ti-icon-bookmark-question; }
.#{$ti-prefix}-bookmarks:before { content: $ti-icon-bookmarks; }
.#{$ti-prefix}-bookmarks-filled:before { content: $ti-icon-bookmarks-filled; }
.#{$ti-prefix}-bookmarks-off:before { content: $ti-icon-bookmarks-off; }
.#{$ti-prefix}-books:before { content: $ti-icon-books; }
.#{$ti-prefix}-books-off:before { content: $ti-icon-books-off; }
.#{$ti-prefix}-boom:before { content: $ti-icon-boom; }
.#{$ti-prefix}-boom-filled:before { content: $ti-icon-boom-filled; }
.#{$ti-prefix}-border-all:before { content: $ti-icon-border-all; }
.#{$ti-prefix}-border-bottom:before { content: $ti-icon-border-bottom; }
.#{$ti-prefix}-border-bottom-plus:before { content: $ti-icon-border-bottom-plus; }
.#{$ti-prefix}-border-corner-ios:before { content: $ti-icon-border-corner-ios; }
.#{$ti-prefix}-border-corner-pill:before { content: $ti-icon-border-corner-pill; }
.#{$ti-prefix}-border-corner-rounded:before { content: $ti-icon-border-corner-rounded; }
.#{$ti-prefix}-border-corner-square:before { content: $ti-icon-border-corner-square; }
.#{$ti-prefix}-border-corners:before { content: $ti-icon-border-corners; }
.#{$ti-prefix}-border-horizontal:before { content: $ti-icon-border-horizontal; }
.#{$ti-prefix}-border-inner:before { content: $ti-icon-border-inner; }
.#{$ti-prefix}-border-left:before { content: $ti-icon-border-left; }
.#{$ti-prefix}-border-left-plus:before { content: $ti-icon-border-left-plus; }
.#{$ti-prefix}-border-none:before { content: $ti-icon-border-none; }
.#{$ti-prefix}-border-outer:before { content: $ti-icon-border-outer; }
.#{$ti-prefix}-border-radius:before { content: $ti-icon-border-radius; }
.#{$ti-prefix}-border-right:before { content: $ti-icon-border-right; }
.#{$ti-prefix}-border-right-plus:before { content: $ti-icon-border-right-plus; }
.#{$ti-prefix}-border-sides:before { content: $ti-icon-border-sides; }
.#{$ti-prefix}-border-style:before { content: $ti-icon-border-style; }
.#{$ti-prefix}-border-style-2:before { content: $ti-icon-border-style-2; }
.#{$ti-prefix}-border-top:before { content: $ti-icon-border-top; }
.#{$ti-prefix}-border-top-plus:before { content: $ti-icon-border-top-plus; }
.#{$ti-prefix}-border-vertical:before { content: $ti-icon-border-vertical; }
.#{$ti-prefix}-bottle:before { content: $ti-icon-bottle; }
.#{$ti-prefix}-bottle-filled:before { content: $ti-icon-bottle-filled; }
.#{$ti-prefix}-bottle-off:before { content: $ti-icon-bottle-off; }
.#{$ti-prefix}-bounce-left:before { content: $ti-icon-bounce-left; }
.#{$ti-prefix}-bounce-left-filled:before { content: $ti-icon-bounce-left-filled; }
.#{$ti-prefix}-bounce-right:before { content: $ti-icon-bounce-right; }
.#{$ti-prefix}-bounce-right-filled:before { content: $ti-icon-bounce-right-filled; }
.#{$ti-prefix}-bow:before { content: $ti-icon-bow; }
.#{$ti-prefix}-bow-filled:before { content: $ti-icon-bow-filled; }
.#{$ti-prefix}-bowl:before { content: $ti-icon-bowl; }
.#{$ti-prefix}-bowl-chopsticks:before { content: $ti-icon-bowl-chopsticks; }
.#{$ti-prefix}-bowl-chopsticks-filled:before { content: $ti-icon-bowl-chopsticks-filled; }
.#{$ti-prefix}-bowl-filled:before { content: $ti-icon-bowl-filled; }
.#{$ti-prefix}-bowl-spoon:before { content: $ti-icon-bowl-spoon; }
.#{$ti-prefix}-bowl-spoon-filled:before { content: $ti-icon-bowl-spoon-filled; }
.#{$ti-prefix}-box:before { content: $ti-icon-box; }
.#{$ti-prefix}-box-align-bottom:before { content: $ti-icon-box-align-bottom; }
.#{$ti-prefix}-box-align-bottom-filled:before { content: $ti-icon-box-align-bottom-filled; }
.#{$ti-prefix}-box-align-bottom-left:before { content: $ti-icon-box-align-bottom-left; }
.#{$ti-prefix}-box-align-bottom-left-filled:before { content: $ti-icon-box-align-bottom-left-filled; }
.#{$ti-prefix}-box-align-bottom-right:before { content: $ti-icon-box-align-bottom-right; }
.#{$ti-prefix}-box-align-bottom-right-filled:before { content: $ti-icon-box-align-bottom-right-filled; }
.#{$ti-prefix}-box-align-left:before { content: $ti-icon-box-align-left; }
.#{$ti-prefix}-box-align-left-filled:before { content: $ti-icon-box-align-left-filled; }
.#{$ti-prefix}-box-align-right:before { content: $ti-icon-box-align-right; }
.#{$ti-prefix}-box-align-right-filled:before { content: $ti-icon-box-align-right-filled; }
.#{$ti-prefix}-box-align-top:before { content: $ti-icon-box-align-top; }
.#{$ti-prefix}-box-align-top-filled:before { content: $ti-icon-box-align-top-filled; }
.#{$ti-prefix}-box-align-top-left:before { content: $ti-icon-box-align-top-left; }
.#{$ti-prefix}-box-align-top-left-filled:before { content: $ti-icon-box-align-top-left-filled; }
.#{$ti-prefix}-box-align-top-right:before { content: $ti-icon-box-align-top-right; }
.#{$ti-prefix}-box-align-top-right-filled:before { content: $ti-icon-box-align-top-right-filled; }
.#{$ti-prefix}-box-margin:before { content: $ti-icon-box-margin; }
.#{$ti-prefix}-box-model:before { content: $ti-icon-box-model; }
.#{$ti-prefix}-box-model-2:before { content: $ti-icon-box-model-2; }
.#{$ti-prefix}-box-model-2-off:before { content: $ti-icon-box-model-2-off; }
.#{$ti-prefix}-box-model-off:before { content: $ti-icon-box-model-off; }
.#{$ti-prefix}-box-multiple:before { content: $ti-icon-box-multiple; }
.#{$ti-prefix}-box-multiple-0:before { content: $ti-icon-box-multiple-0; }
.#{$ti-prefix}-box-multiple-1:before { content: $ti-icon-box-multiple-1; }
.#{$ti-prefix}-box-multiple-2:before { content: $ti-icon-box-multiple-2; }
.#{$ti-prefix}-box-multiple-3:before { content: $ti-icon-box-multiple-3; }
.#{$ti-prefix}-box-multiple-4:before { content: $ti-icon-box-multiple-4; }
.#{$ti-prefix}-box-multiple-5:before { content: $ti-icon-box-multiple-5; }
.#{$ti-prefix}-box-multiple-6:before { content: $ti-icon-box-multiple-6; }
.#{$ti-prefix}-box-multiple-7:before { content: $ti-icon-box-multiple-7; }
.#{$ti-prefix}-box-multiple-8:before { content: $ti-icon-box-multiple-8; }
.#{$ti-prefix}-box-multiple-9:before { content: $ti-icon-box-multiple-9; }
.#{$ti-prefix}-box-multiple-filled:before { content: $ti-icon-box-multiple-filled; }
.#{$ti-prefix}-box-off:before { content: $ti-icon-box-off; }
.#{$ti-prefix}-box-padding:before { content: $ti-icon-box-padding; }
.#{$ti-prefix}-braces:before { content: $ti-icon-braces; }
.#{$ti-prefix}-braces-off:before { content: $ti-icon-braces-off; }
.#{$ti-prefix}-brackets:before { content: $ti-icon-brackets; }
.#{$ti-prefix}-brackets-angle:before { content: $ti-icon-brackets-angle; }
.#{$ti-prefix}-brackets-angle-off:before { content: $ti-icon-brackets-angle-off; }
.#{$ti-prefix}-brackets-contain:before { content: $ti-icon-brackets-contain; }
.#{$ti-prefix}-brackets-contain-end:before { content: $ti-icon-brackets-contain-end; }
.#{$ti-prefix}-brackets-contain-start:before { content: $ti-icon-brackets-contain-start; }
.#{$ti-prefix}-brackets-off:before { content: $ti-icon-brackets-off; }
.#{$ti-prefix}-braille:before { content: $ti-icon-braille; }
.#{$ti-prefix}-brain:before { content: $ti-icon-brain; }
.#{$ti-prefix}-brand-4chan:before { content: $ti-icon-brand-4chan; }
.#{$ti-prefix}-brand-abstract:before { content: $ti-icon-brand-abstract; }
.#{$ti-prefix}-brand-adobe:before { content: $ti-icon-brand-adobe; }
.#{$ti-prefix}-brand-adobe-after-effect:before { content: $ti-icon-brand-adobe-after-effect; }
.#{$ti-prefix}-brand-adobe-illustrator:before { content: $ti-icon-brand-adobe-illustrator; }
.#{$ti-prefix}-brand-adobe-indesign:before { content: $ti-icon-brand-adobe-indesign; }
.#{$ti-prefix}-brand-adobe-photoshop:before { content: $ti-icon-brand-adobe-photoshop; }
.#{$ti-prefix}-brand-adobe-premier:before { content: $ti-icon-brand-adobe-premier; }
.#{$ti-prefix}-brand-adobe-xd:before { content: $ti-icon-brand-adobe-xd; }
.#{$ti-prefix}-brand-adonis-js:before { content: $ti-icon-brand-adonis-js; }
.#{$ti-prefix}-brand-airbnb:before { content: $ti-icon-brand-airbnb; }
.#{$ti-prefix}-brand-airtable:before { content: $ti-icon-brand-airtable; }
.#{$ti-prefix}-brand-algolia:before { content: $ti-icon-brand-algolia; }
.#{$ti-prefix}-brand-alipay:before { content: $ti-icon-brand-alipay; }
.#{$ti-prefix}-brand-alpine-js:before { content: $ti-icon-brand-alpine-js; }
.#{$ti-prefix}-brand-amazon:before { content: $ti-icon-brand-amazon; }
.#{$ti-prefix}-brand-amd:before { content: $ti-icon-brand-amd; }
.#{$ti-prefix}-brand-amie:before { content: $ti-icon-brand-amie; }
.#{$ti-prefix}-brand-amigo:before { content: $ti-icon-brand-amigo; }
.#{$ti-prefix}-brand-among-us:before { content: $ti-icon-brand-among-us; }
.#{$ti-prefix}-brand-android:before { content: $ti-icon-brand-android; }
.#{$ti-prefix}-brand-angular:before { content: $ti-icon-brand-angular; }
.#{$ti-prefix}-brand-ansible:before { content: $ti-icon-brand-ansible; }
.#{$ti-prefix}-brand-ao3:before { content: $ti-icon-brand-ao3; }
.#{$ti-prefix}-brand-appgallery:before { content: $ti-icon-brand-appgallery; }
.#{$ti-prefix}-brand-apple:before { content: $ti-icon-brand-apple; }
.#{$ti-prefix}-brand-apple-arcade:before { content: $ti-icon-brand-apple-arcade; }
.#{$ti-prefix}-brand-apple-filled:before { content: $ti-icon-brand-apple-filled; }
.#{$ti-prefix}-brand-apple-news:before { content: $ti-icon-brand-apple-news; }
.#{$ti-prefix}-brand-apple-podcast:before { content: $ti-icon-brand-apple-podcast; }
.#{$ti-prefix}-brand-appstore:before { content: $ti-icon-brand-appstore; }
.#{$ti-prefix}-brand-arc:before { content: $ti-icon-brand-arc; }
.#{$ti-prefix}-brand-asana:before { content: $ti-icon-brand-asana; }
.#{$ti-prefix}-brand-astro:before { content: $ti-icon-brand-astro; }
.#{$ti-prefix}-brand-auth0:before { content: $ti-icon-brand-auth0; }
.#{$ti-prefix}-brand-aws:before { content: $ti-icon-brand-aws; }
.#{$ti-prefix}-brand-azure:before { content: $ti-icon-brand-azure; }
.#{$ti-prefix}-brand-backbone:before { content: $ti-icon-brand-backbone; }
.#{$ti-prefix}-brand-badoo:before { content: $ti-icon-brand-badoo; }
.#{$ti-prefix}-brand-baidu:before { content: $ti-icon-brand-baidu; }
.#{$ti-prefix}-brand-bandcamp:before { content: $ti-icon-brand-bandcamp; }
.#{$ti-prefix}-brand-bandlab:before { content: $ti-icon-brand-bandlab; }
.#{$ti-prefix}-brand-beats:before { content: $ti-icon-brand-beats; }
.#{$ti-prefix}-brand-bebo:before { content: $ti-icon-brand-bebo; }
.#{$ti-prefix}-brand-behance:before { content: $ti-icon-brand-behance; }
.#{$ti-prefix}-brand-bilibili:before { content: $ti-icon-brand-bilibili; }
.#{$ti-prefix}-brand-binance:before { content: $ti-icon-brand-binance; }
.#{$ti-prefix}-brand-bing:before { content: $ti-icon-brand-bing; }
.#{$ti-prefix}-brand-bitbucket:before { content: $ti-icon-brand-bitbucket; }
.#{$ti-prefix}-brand-blackberry:before { content: $ti-icon-brand-blackberry; }
.#{$ti-prefix}-brand-blender:before { content: $ti-icon-brand-blender; }
.#{$ti-prefix}-brand-blogger:before { content: $ti-icon-brand-blogger; }
.#{$ti-prefix}-brand-bluesky:before { content: $ti-icon-brand-bluesky; }
.#{$ti-prefix}-brand-booking:before { content: $ti-icon-brand-booking; }
.#{$ti-prefix}-brand-bootstrap:before { content: $ti-icon-brand-bootstrap; }
.#{$ti-prefix}-brand-bulma:before { content: $ti-icon-brand-bulma; }
.#{$ti-prefix}-brand-bumble:before { content: $ti-icon-brand-bumble; }
.#{$ti-prefix}-brand-bunpo:before { content: $ti-icon-brand-bunpo; }
.#{$ti-prefix}-brand-c-sharp:before { content: $ti-icon-brand-c-sharp; }
.#{$ti-prefix}-brand-cake:before { content: $ti-icon-brand-cake; }
.#{$ti-prefix}-brand-cakephp:before { content: $ti-icon-brand-cakephp; }
.#{$ti-prefix}-brand-campaignmonitor:before { content: $ti-icon-brand-campaignmonitor; }
.#{$ti-prefix}-brand-carbon:before { content: $ti-icon-brand-carbon; }
.#{$ti-prefix}-brand-cashapp:before { content: $ti-icon-brand-cashapp; }
.#{$ti-prefix}-brand-chrome:before { content: $ti-icon-brand-chrome; }
.#{$ti-prefix}-brand-cinema-4d:before { content: $ti-icon-brand-cinema-4d; }
.#{$ti-prefix}-brand-citymapper:before { content: $ti-icon-brand-citymapper; }
.#{$ti-prefix}-brand-cloudflare:before { content: $ti-icon-brand-cloudflare; }
.#{$ti-prefix}-brand-codecov:before { content: $ti-icon-brand-codecov; }
.#{$ti-prefix}-brand-codepen:before { content: $ti-icon-brand-codepen; }
.#{$ti-prefix}-brand-codesandbox:before { content: $ti-icon-brand-codesandbox; }
.#{$ti-prefix}-brand-cohost:before { content: $ti-icon-brand-cohost; }
.#{$ti-prefix}-brand-coinbase:before { content: $ti-icon-brand-coinbase; }
.#{$ti-prefix}-brand-comedy-central:before { content: $ti-icon-brand-comedy-central; }
.#{$ti-prefix}-brand-coreos:before { content: $ti-icon-brand-coreos; }
.#{$ti-prefix}-brand-couchdb:before { content: $ti-icon-brand-couchdb; }
.#{$ti-prefix}-brand-couchsurfing:before { content: $ti-icon-brand-couchsurfing; }
.#{$ti-prefix}-brand-cpp:before { content: $ti-icon-brand-cpp; }
.#{$ti-prefix}-brand-craft:before { content: $ti-icon-brand-craft; }
.#{$ti-prefix}-brand-crunchbase:before { content: $ti-icon-brand-crunchbase; }
.#{$ti-prefix}-brand-css3:before { content: $ti-icon-brand-css3; }
.#{$ti-prefix}-brand-ctemplar:before { content: $ti-icon-brand-ctemplar; }
.#{$ti-prefix}-brand-cucumber:before { content: $ti-icon-brand-cucumber; }
.#{$ti-prefix}-brand-cupra:before { content: $ti-icon-brand-cupra; }
.#{$ti-prefix}-brand-cypress:before { content: $ti-icon-brand-cypress; }
.#{$ti-prefix}-brand-d3:before { content: $ti-icon-brand-d3; }
.#{$ti-prefix}-brand-databricks:before { content: $ti-icon-brand-databricks; }
.#{$ti-prefix}-brand-days-counter:before { content: $ti-icon-brand-days-counter; }
.#{$ti-prefix}-brand-dcos:before { content: $ti-icon-brand-dcos; }
.#{$ti-prefix}-brand-debian:before { content: $ti-icon-brand-debian; }
.#{$ti-prefix}-brand-deezer:before { content: $ti-icon-brand-deezer; }
.#{$ti-prefix}-brand-deliveroo:before { content: $ti-icon-brand-deliveroo; }
.#{$ti-prefix}-brand-deno:before { content: $ti-icon-brand-deno; }
.#{$ti-prefix}-brand-denodo:before { content: $ti-icon-brand-denodo; }
.#{$ti-prefix}-brand-deviantart:before { content: $ti-icon-brand-deviantart; }
.#{$ti-prefix}-brand-digg:before { content: $ti-icon-brand-digg; }
.#{$ti-prefix}-brand-dingtalk:before { content: $ti-icon-brand-dingtalk; }
.#{$ti-prefix}-brand-discord:before { content: $ti-icon-brand-discord; }
.#{$ti-prefix}-brand-discord-filled:before { content: $ti-icon-brand-discord-filled; }
.#{$ti-prefix}-brand-disney:before { content: $ti-icon-brand-disney; }
.#{$ti-prefix}-brand-disqus:before { content: $ti-icon-brand-disqus; }
.#{$ti-prefix}-brand-django:before { content: $ti-icon-brand-django; }
.#{$ti-prefix}-brand-docker:before { content: $ti-icon-brand-docker; }
.#{$ti-prefix}-brand-doctrine:before { content: $ti-icon-brand-doctrine; }
.#{$ti-prefix}-brand-dolby-digital:before { content: $ti-icon-brand-dolby-digital; }
.#{$ti-prefix}-brand-douban:before { content: $ti-icon-brand-douban; }
.#{$ti-prefix}-brand-dribbble:before { content: $ti-icon-brand-dribbble; }
.#{$ti-prefix}-brand-dribbble-filled:before { content: $ti-icon-brand-dribbble-filled; }
.#{$ti-prefix}-brand-drops:before { content: $ti-icon-brand-drops; }
.#{$ti-prefix}-brand-drupal:before { content: $ti-icon-brand-drupal; }
.#{$ti-prefix}-brand-edge:before { content: $ti-icon-brand-edge; }
.#{$ti-prefix}-brand-elastic:before { content: $ti-icon-brand-elastic; }
.#{$ti-prefix}-brand-electronic-arts:before { content: $ti-icon-brand-electronic-arts; }
.#{$ti-prefix}-brand-ember:before { content: $ti-icon-brand-ember; }
.#{$ti-prefix}-brand-envato:before { content: $ti-icon-brand-envato; }
.#{$ti-prefix}-brand-etsy:before { content: $ti-icon-brand-etsy; }
.#{$ti-prefix}-brand-evernote:before { content: $ti-icon-brand-evernote; }
.#{$ti-prefix}-brand-facebook:before { content: $ti-icon-brand-facebook; }
.#{$ti-prefix}-brand-facebook-filled:before { content: $ti-icon-brand-facebook-filled; }
.#{$ti-prefix}-brand-feedly:before { content: $ti-icon-brand-feedly; }
.#{$ti-prefix}-brand-figma:before { content: $ti-icon-brand-figma; }
.#{$ti-prefix}-brand-filezilla:before { content: $ti-icon-brand-filezilla; }
.#{$ti-prefix}-brand-finder:before { content: $ti-icon-brand-finder; }
.#{$ti-prefix}-brand-firebase:before { content: $ti-icon-brand-firebase; }
.#{$ti-prefix}-brand-firefox:before { content: $ti-icon-brand-firefox; }
.#{$ti-prefix}-brand-fiverr:before { content: $ti-icon-brand-fiverr; }
.#{$ti-prefix}-brand-flickr:before { content: $ti-icon-brand-flickr; }
.#{$ti-prefix}-brand-flightradar24:before { content: $ti-icon-brand-flightradar24; }
.#{$ti-prefix}-brand-flipboard:before { content: $ti-icon-brand-flipboard; }
.#{$ti-prefix}-brand-flutter:before { content: $ti-icon-brand-flutter; }
.#{$ti-prefix}-brand-fortnite:before { content: $ti-icon-brand-fortnite; }
.#{$ti-prefix}-brand-foursquare:before { content: $ti-icon-brand-foursquare; }
.#{$ti-prefix}-brand-framer:before { content: $ti-icon-brand-framer; }
.#{$ti-prefix}-brand-framer-motion:before { content: $ti-icon-brand-framer-motion; }
.#{$ti-prefix}-brand-funimation:before { content: $ti-icon-brand-funimation; }
.#{$ti-prefix}-brand-gatsby:before { content: $ti-icon-brand-gatsby; }
.#{$ti-prefix}-brand-git:before { content: $ti-icon-brand-git; }
.#{$ti-prefix}-brand-github:before { content: $ti-icon-brand-github; }
.#{$ti-prefix}-brand-github-copilot:before { content: $ti-icon-brand-github-copilot; }
.#{$ti-prefix}-brand-github-filled:before { content: $ti-icon-brand-github-filled; }
.#{$ti-prefix}-brand-gitlab:before { content: $ti-icon-brand-gitlab; }
.#{$ti-prefix}-brand-gmail:before { content: $ti-icon-brand-gmail; }
.#{$ti-prefix}-brand-golang:before { content: $ti-icon-brand-golang; }
.#{$ti-prefix}-brand-google:before { content: $ti-icon-brand-google; }
.#{$ti-prefix}-brand-google-analytics:before { content: $ti-icon-brand-google-analytics; }
.#{$ti-prefix}-brand-google-big-query:before { content: $ti-icon-brand-google-big-query; }
.#{$ti-prefix}-brand-google-drive:before { content: $ti-icon-brand-google-drive; }
.#{$ti-prefix}-brand-google-filled:before { content: $ti-icon-brand-google-filled; }
.#{$ti-prefix}-brand-google-fit:before { content: $ti-icon-brand-google-fit; }
.#{$ti-prefix}-brand-google-home:before { content: $ti-icon-brand-google-home; }
.#{$ti-prefix}-brand-google-maps:before { content: $ti-icon-brand-google-maps; }
.#{$ti-prefix}-brand-google-one:before { content: $ti-icon-brand-google-one; }
.#{$ti-prefix}-brand-google-photos:before { content: $ti-icon-brand-google-photos; }
.#{$ti-prefix}-brand-google-play:before { content: $ti-icon-brand-google-play; }
.#{$ti-prefix}-brand-google-podcasts:before { content: $ti-icon-brand-google-podcasts; }
.#{$ti-prefix}-brand-grammarly:before { content: $ti-icon-brand-grammarly; }
.#{$ti-prefix}-brand-graphql:before { content: $ti-icon-brand-graphql; }
.#{$ti-prefix}-brand-gravatar:before { content: $ti-icon-brand-gravatar; }
.#{$ti-prefix}-brand-grindr:before { content: $ti-icon-brand-grindr; }
.#{$ti-prefix}-brand-guardian:before { content: $ti-icon-brand-guardian; }
.#{$ti-prefix}-brand-gumroad:before { content: $ti-icon-brand-gumroad; }
.#{$ti-prefix}-brand-hackerrank:before { content: $ti-icon-brand-hackerrank; }
.#{$ti-prefix}-brand-hbo:before { content: $ti-icon-brand-hbo; }
.#{$ti-prefix}-brand-headlessui:before { content: $ti-icon-brand-headlessui; }
.#{$ti-prefix}-brand-hexo:before { content: $ti-icon-brand-hexo; }
.#{$ti-prefix}-brand-hipchat:before { content: $ti-icon-brand-hipchat; }
.#{$ti-prefix}-brand-html5:before { content: $ti-icon-brand-html5; }
.#{$ti-prefix}-brand-inertia:before { content: $ti-icon-brand-inertia; }
.#{$ti-prefix}-brand-instagram:before { content: $ti-icon-brand-instagram; }
.#{$ti-prefix}-brand-intercom:before { content: $ti-icon-brand-intercom; }
.#{$ti-prefix}-brand-itch:before { content: $ti-icon-brand-itch; }
.#{$ti-prefix}-brand-javascript:before { content: $ti-icon-brand-javascript; }
.#{$ti-prefix}-brand-juejin:before { content: $ti-icon-brand-juejin; }
.#{$ti-prefix}-brand-kako-talk:before { content: $ti-icon-brand-kako-talk; }
.#{$ti-prefix}-brand-kbin:before { content: $ti-icon-brand-kbin; }
.#{$ti-prefix}-brand-kick:before { content: $ti-icon-brand-kick; }
.#{$ti-prefix}-brand-kickstarter:before { content: $ti-icon-brand-kickstarter; }
.#{$ti-prefix}-brand-kotlin:before { content: $ti-icon-brand-kotlin; }
.#{$ti-prefix}-brand-laravel:before { content: $ti-icon-brand-laravel; }
.#{$ti-prefix}-brand-lastfm:before { content: $ti-icon-brand-lastfm; }
.#{$ti-prefix}-brand-leetcode:before { content: $ti-icon-brand-leetcode; }
.#{$ti-prefix}-brand-letterboxd:before { content: $ti-icon-brand-letterboxd; }
.#{$ti-prefix}-brand-line:before { content: $ti-icon-brand-line; }
.#{$ti-prefix}-brand-linkedin:before { content: $ti-icon-brand-linkedin; }
.#{$ti-prefix}-brand-linktree:before { content: $ti-icon-brand-linktree; }
.#{$ti-prefix}-brand-linqpad:before { content: $ti-icon-brand-linqpad; }
.#{$ti-prefix}-brand-livewire:before { content: $ti-icon-brand-livewire; }
.#{$ti-prefix}-brand-loom:before { content: $ti-icon-brand-loom; }
.#{$ti-prefix}-brand-mailgun:before { content: $ti-icon-brand-mailgun; }
.#{$ti-prefix}-brand-mantine:before { content: $ti-icon-brand-mantine; }
.#{$ti-prefix}-brand-mastercard:before { content: $ti-icon-brand-mastercard; }
.#{$ti-prefix}-brand-mastodon:before { content: $ti-icon-brand-mastodon; }
.#{$ti-prefix}-brand-matrix:before { content: $ti-icon-brand-matrix; }
.#{$ti-prefix}-brand-mcdonalds:before { content: $ti-icon-brand-mcdonalds; }
.#{$ti-prefix}-brand-medium:before { content: $ti-icon-brand-medium; }
.#{$ti-prefix}-brand-meetup:before { content: $ti-icon-brand-meetup; }
.#{$ti-prefix}-brand-mercedes:before { content: $ti-icon-brand-mercedes; }
.#{$ti-prefix}-brand-messenger:before { content: $ti-icon-brand-messenger; }
.#{$ti-prefix}-brand-meta:before { content: $ti-icon-brand-meta; }
.#{$ti-prefix}-brand-metabrainz:before { content: $ti-icon-brand-metabrainz; }
.#{$ti-prefix}-brand-minecraft:before { content: $ti-icon-brand-minecraft; }
.#{$ti-prefix}-brand-miniprogram:before { content: $ti-icon-brand-miniprogram; }
.#{$ti-prefix}-brand-mixpanel:before { content: $ti-icon-brand-mixpanel; }
.#{$ti-prefix}-brand-monday:before { content: $ti-icon-brand-monday; }
.#{$ti-prefix}-brand-mongodb:before { content: $ti-icon-brand-mongodb; }
.#{$ti-prefix}-brand-my-oppo:before { content: $ti-icon-brand-my-oppo; }
.#{$ti-prefix}-brand-mysql:before { content: $ti-icon-brand-mysql; }
.#{$ti-prefix}-brand-national-geographic:before { content: $ti-icon-brand-national-geographic; }
.#{$ti-prefix}-brand-nem:before { content: $ti-icon-brand-nem; }
.#{$ti-prefix}-brand-netbeans:before { content: $ti-icon-brand-netbeans; }
.#{$ti-prefix}-brand-netease-music:before { content: $ti-icon-brand-netease-music; }
.#{$ti-prefix}-brand-netflix:before { content: $ti-icon-brand-netflix; }
.#{$ti-prefix}-brand-nexo:before { content: $ti-icon-brand-nexo; }
.#{$ti-prefix}-brand-nextcloud:before { content: $ti-icon-brand-nextcloud; }
.#{$ti-prefix}-brand-nextjs:before { content: $ti-icon-brand-nextjs; }
.#{$ti-prefix}-brand-nodejs:before { content: $ti-icon-brand-nodejs; }
.#{$ti-prefix}-brand-nord-vpn:before { content: $ti-icon-brand-nord-vpn; }
.#{$ti-prefix}-brand-notion:before { content: $ti-icon-brand-notion; }
.#{$ti-prefix}-brand-npm:before { content: $ti-icon-brand-npm; }
.#{$ti-prefix}-brand-nuxt:before { content: $ti-icon-brand-nuxt; }
.#{$ti-prefix}-brand-nytimes:before { content: $ti-icon-brand-nytimes; }
.#{$ti-prefix}-brand-oauth:before { content: $ti-icon-brand-oauth; }
.#{$ti-prefix}-brand-office:before { content: $ti-icon-brand-office; }
.#{$ti-prefix}-brand-ok-ru:before { content: $ti-icon-brand-ok-ru; }
.#{$ti-prefix}-brand-onedrive:before { content: $ti-icon-brand-onedrive; }
.#{$ti-prefix}-brand-onlyfans:before { content: $ti-icon-brand-onlyfans; }
.#{$ti-prefix}-brand-open-source:before { content: $ti-icon-brand-open-source; }
.#{$ti-prefix}-brand-openai:before { content: $ti-icon-brand-openai; }
.#{$ti-prefix}-brand-openvpn:before { content: $ti-icon-brand-openvpn; }
.#{$ti-prefix}-brand-opera:before { content: $ti-icon-brand-opera; }
.#{$ti-prefix}-brand-pagekit:before { content: $ti-icon-brand-pagekit; }
.#{$ti-prefix}-brand-parsinta:before { content: $ti-icon-brand-parsinta; }
.#{$ti-prefix}-brand-patreon:before { content: $ti-icon-brand-patreon; }
.#{$ti-prefix}-brand-patreon-filled:before { content: $ti-icon-brand-patreon-filled; }
.#{$ti-prefix}-brand-paypal:before { content: $ti-icon-brand-paypal; }
.#{$ti-prefix}-brand-paypal-filled:before { content: $ti-icon-brand-paypal-filled; }
.#{$ti-prefix}-brand-paypay:before { content: $ti-icon-brand-paypay; }
.#{$ti-prefix}-brand-peanut:before { content: $ti-icon-brand-peanut; }
.#{$ti-prefix}-brand-pepsi:before { content: $ti-icon-brand-pepsi; }
.#{$ti-prefix}-brand-php:before { content: $ti-icon-brand-php; }
.#{$ti-prefix}-brand-picsart:before { content: $ti-icon-brand-picsart; }
.#{$ti-prefix}-brand-pinterest:before { content: $ti-icon-brand-pinterest; }
.#{$ti-prefix}-brand-planetscale:before { content: $ti-icon-brand-planetscale; }
.#{$ti-prefix}-brand-pnpm:before { content: $ti-icon-brand-pnpm; }
.#{$ti-prefix}-brand-pocket:before { content: $ti-icon-brand-pocket; }
.#{$ti-prefix}-brand-polymer:before { content: $ti-icon-brand-polymer; }
.#{$ti-prefix}-brand-powershell:before { content: $ti-icon-brand-powershell; }
.#{$ti-prefix}-brand-printables:before { content: $ti-icon-brand-printables; }
.#{$ti-prefix}-brand-prisma:before { content: $ti-icon-brand-prisma; }
.#{$ti-prefix}-brand-producthunt:before { content: $ti-icon-brand-producthunt; }
.#{$ti-prefix}-brand-pushbullet:before { content: $ti-icon-brand-pushbullet; }
.#{$ti-prefix}-brand-pushover:before { content: $ti-icon-brand-pushover; }
.#{$ti-prefix}-brand-python:before { content: $ti-icon-brand-python; }
.#{$ti-prefix}-brand-qq:before { content: $ti-icon-brand-qq; }
.#{$ti-prefix}-brand-radix-ui:before { content: $ti-icon-brand-radix-ui; }
.#{$ti-prefix}-brand-react:before { content: $ti-icon-brand-react; }
.#{$ti-prefix}-brand-react-native:before { content: $ti-icon-brand-react-native; }
.#{$ti-prefix}-brand-reason:before { content: $ti-icon-brand-reason; }
.#{$ti-prefix}-brand-reddit:before { content: $ti-icon-brand-reddit; }
.#{$ti-prefix}-brand-redhat:before { content: $ti-icon-brand-redhat; }
.#{$ti-prefix}-brand-redux:before { content: $ti-icon-brand-redux; }
.#{$ti-prefix}-brand-revolut:before { content: $ti-icon-brand-revolut; }
.#{$ti-prefix}-brand-rumble:before { content: $ti-icon-brand-rumble; }
.#{$ti-prefix}-brand-rust:before { content: $ti-icon-brand-rust; }
.#{$ti-prefix}-brand-safari:before { content: $ti-icon-brand-safari; }
.#{$ti-prefix}-brand-samsungpass:before { content: $ti-icon-brand-samsungpass; }
.#{$ti-prefix}-brand-sass:before { content: $ti-icon-brand-sass; }
.#{$ti-prefix}-brand-sentry:before { content: $ti-icon-brand-sentry; }
.#{$ti-prefix}-brand-sharik:before { content: $ti-icon-brand-sharik; }
.#{$ti-prefix}-brand-shazam:before { content: $ti-icon-brand-shazam; }
.#{$ti-prefix}-brand-shopee:before { content: $ti-icon-brand-shopee; }
.#{$ti-prefix}-brand-sketch:before { content: $ti-icon-brand-sketch; }
.#{$ti-prefix}-brand-skype:before { content: $ti-icon-brand-skype; }
.#{$ti-prefix}-brand-slack:before { content: $ti-icon-brand-slack; }
.#{$ti-prefix}-brand-snapchat:before { content: $ti-icon-brand-snapchat; }
.#{$ti-prefix}-brand-snapseed:before { content: $ti-icon-brand-snapseed; }
.#{$ti-prefix}-brand-snowflake:before { content: $ti-icon-brand-snowflake; }
.#{$ti-prefix}-brand-socket-io:before { content: $ti-icon-brand-socket-io; }
.#{$ti-prefix}-brand-solidjs:before { content: $ti-icon-brand-solidjs; }
.#{$ti-prefix}-brand-soundcloud:before { content: $ti-icon-brand-soundcloud; }
.#{$ti-prefix}-brand-spacehey:before { content: $ti-icon-brand-spacehey; }
.#{$ti-prefix}-brand-speedtest:before { content: $ti-icon-brand-speedtest; }
.#{$ti-prefix}-brand-spotify:before { content: $ti-icon-brand-spotify; }
.#{$ti-prefix}-brand-spotify-filled:before { content: $ti-icon-brand-spotify-filled; }
.#{$ti-prefix}-brand-stackoverflow:before { content: $ti-icon-brand-stackoverflow; }
.#{$ti-prefix}-brand-stackshare:before { content: $ti-icon-brand-stackshare; }
.#{$ti-prefix}-brand-steam:before { content: $ti-icon-brand-steam; }
.#{$ti-prefix}-brand-stocktwits:before { content: $ti-icon-brand-stocktwits; }
.#{$ti-prefix}-brand-storj:before { content: $ti-icon-brand-storj; }
.#{$ti-prefix}-brand-storybook:before { content: $ti-icon-brand-storybook; }
.#{$ti-prefix}-brand-storytel:before { content: $ti-icon-brand-storytel; }
.#{$ti-prefix}-brand-strava:before { content: $ti-icon-brand-strava; }
.#{$ti-prefix}-brand-stripe:before { content: $ti-icon-brand-stripe; }
.#{$ti-prefix}-brand-sublime-text:before { content: $ti-icon-brand-sublime-text; }
.#{$ti-prefix}-brand-sugarizer:before { content: $ti-icon-brand-sugarizer; }
.#{$ti-prefix}-brand-supabase:before { content: $ti-icon-brand-supabase; }
.#{$ti-prefix}-brand-superhuman:before { content: $ti-icon-brand-superhuman; }
.#{$ti-prefix}-brand-supernova:before { content: $ti-icon-brand-supernova; }
.#{$ti-prefix}-brand-surfshark:before { content: $ti-icon-brand-surfshark; }
.#{$ti-prefix}-brand-svelte:before { content: $ti-icon-brand-svelte; }
.#{$ti-prefix}-brand-swift:before { content: $ti-icon-brand-swift; }
.#{$ti-prefix}-brand-symfony:before { content: $ti-icon-brand-symfony; }
.#{$ti-prefix}-brand-tabler:before { content: $ti-icon-brand-tabler; }
.#{$ti-prefix}-brand-tailwind:before { content: $ti-icon-brand-tailwind; }
.#{$ti-prefix}-brand-taobao:before { content: $ti-icon-brand-taobao; }
.#{$ti-prefix}-brand-teams:before { content: $ti-icon-brand-teams; }
.#{$ti-prefix}-brand-ted:before { content: $ti-icon-brand-ted; }
.#{$ti-prefix}-brand-telegram:before { content: $ti-icon-brand-telegram; }
.#{$ti-prefix}-brand-terraform:before { content: $ti-icon-brand-terraform; }
.#{$ti-prefix}-brand-tether:before { content: $ti-icon-brand-tether; }
.#{$ti-prefix}-brand-thingiverse:before { content: $ti-icon-brand-thingiverse; }
.#{$ti-prefix}-brand-threads:before { content: $ti-icon-brand-threads; }
.#{$ti-prefix}-brand-threejs:before { content: $ti-icon-brand-threejs; }
.#{$ti-prefix}-brand-tidal:before { content: $ti-icon-brand-tidal; }
.#{$ti-prefix}-brand-tiktok:before { content: $ti-icon-brand-tiktok; }
.#{$ti-prefix}-brand-tiktok-filled:before { content: $ti-icon-brand-tiktok-filled; }
.#{$ti-prefix}-brand-tinder:before { content: $ti-icon-brand-tinder; }
.#{$ti-prefix}-brand-topbuzz:before { content: $ti-icon-brand-topbuzz; }
.#{$ti-prefix}-brand-torchain:before { content: $ti-icon-brand-torchain; }
.#{$ti-prefix}-brand-toyota:before { content: $ti-icon-brand-toyota; }
.#{$ti-prefix}-brand-trello:before { content: $ti-icon-brand-trello; }
.#{$ti-prefix}-brand-tripadvisor:before { content: $ti-icon-brand-tripadvisor; }
.#{$ti-prefix}-brand-tumblr:before { content: $ti-icon-brand-tumblr; }
.#{$ti-prefix}-brand-twilio:before { content: $ti-icon-brand-twilio; }
.#{$ti-prefix}-brand-twitch:before { content: $ti-icon-brand-twitch; }
.#{$ti-prefix}-brand-twitter:before { content: $ti-icon-brand-twitter; }
.#{$ti-prefix}-brand-twitter-filled:before { content: $ti-icon-brand-twitter-filled; }
.#{$ti-prefix}-brand-typescript:before { content: $ti-icon-brand-typescript; }
.#{$ti-prefix}-brand-uber:before { content: $ti-icon-brand-uber; }
.#{$ti-prefix}-brand-ubuntu:before { content: $ti-icon-brand-ubuntu; }
.#{$ti-prefix}-brand-unity:before { content: $ti-icon-brand-unity; }
.#{$ti-prefix}-brand-unsplash:before { content: $ti-icon-brand-unsplash; }
.#{$ti-prefix}-brand-upwork:before { content: $ti-icon-brand-upwork; }
.#{$ti-prefix}-brand-valorant:before { content: $ti-icon-brand-valorant; }
.#{$ti-prefix}-brand-vercel:before { content: $ti-icon-brand-vercel; }
.#{$ti-prefix}-brand-vimeo:before { content: $ti-icon-brand-vimeo; }
.#{$ti-prefix}-brand-vinted:before { content: $ti-icon-brand-vinted; }
.#{$ti-prefix}-brand-visa:before { content: $ti-icon-brand-visa; }
.#{$ti-prefix}-brand-visual-studio:before { content: $ti-icon-brand-visual-studio; }
.#{$ti-prefix}-brand-vite:before { content: $ti-icon-brand-vite; }
.#{$ti-prefix}-brand-vivaldi:before { content: $ti-icon-brand-vivaldi; }
.#{$ti-prefix}-brand-vk:before { content: $ti-icon-brand-vk; }
.#{$ti-prefix}-brand-vlc:before { content: $ti-icon-brand-vlc; }
.#{$ti-prefix}-brand-volkswagen:before { content: $ti-icon-brand-volkswagen; }
.#{$ti-prefix}-brand-vsco:before { content: $ti-icon-brand-vsco; }
.#{$ti-prefix}-brand-vscode:before { content: $ti-icon-brand-vscode; }
.#{$ti-prefix}-brand-vue:before { content: $ti-icon-brand-vue; }
.#{$ti-prefix}-brand-walmart:before { content: $ti-icon-brand-walmart; }
.#{$ti-prefix}-brand-waze:before { content: $ti-icon-brand-waze; }
.#{$ti-prefix}-brand-webflow:before { content: $ti-icon-brand-webflow; }
.#{$ti-prefix}-brand-wechat:before { content: $ti-icon-brand-wechat; }
.#{$ti-prefix}-brand-weibo:before { content: $ti-icon-brand-weibo; }
.#{$ti-prefix}-brand-whatsapp:before { content: $ti-icon-brand-whatsapp; }
.#{$ti-prefix}-brand-wikipedia:before { content: $ti-icon-brand-wikipedia; }
.#{$ti-prefix}-brand-windows:before { content: $ti-icon-brand-windows; }
.#{$ti-prefix}-brand-windy:before { content: $ti-icon-brand-windy; }
.#{$ti-prefix}-brand-wish:before { content: $ti-icon-brand-wish; }
.#{$ti-prefix}-brand-wix:before { content: $ti-icon-brand-wix; }
.#{$ti-prefix}-brand-wordpress:before { content: $ti-icon-brand-wordpress; }
.#{$ti-prefix}-brand-x:before { content: $ti-icon-brand-x; }
.#{$ti-prefix}-brand-x-filled:before { content: $ti-icon-brand-x-filled; }
.#{$ti-prefix}-brand-xamarin:before { content: $ti-icon-brand-xamarin; }
.#{$ti-prefix}-brand-xbox:before { content: $ti-icon-brand-xbox; }
.#{$ti-prefix}-brand-xdeep:before { content: $ti-icon-brand-xdeep; }
.#{$ti-prefix}-brand-xing:before { content: $ti-icon-brand-xing; }
.#{$ti-prefix}-brand-yahoo:before { content: $ti-icon-brand-yahoo; }
.#{$ti-prefix}-brand-yandex:before { content: $ti-icon-brand-yandex; }
.#{$ti-prefix}-brand-yarn:before { content: $ti-icon-brand-yarn; }
.#{$ti-prefix}-brand-yatse:before { content: $ti-icon-brand-yatse; }
.#{$ti-prefix}-brand-ycombinator:before { content: $ti-icon-brand-ycombinator; }
.#{$ti-prefix}-brand-youtube:before { content: $ti-icon-brand-youtube; }
.#{$ti-prefix}-brand-youtube-filled:before { content: $ti-icon-brand-youtube-filled; }
.#{$ti-prefix}-brand-youtube-kids:before { content: $ti-icon-brand-youtube-kids; }
.#{$ti-prefix}-brand-zalando:before { content: $ti-icon-brand-zalando; }
.#{$ti-prefix}-brand-zapier:before { content: $ti-icon-brand-zapier; }
.#{$ti-prefix}-brand-zeit:before { content: $ti-icon-brand-zeit; }
.#{$ti-prefix}-brand-zhihu:before { content: $ti-icon-brand-zhihu; }
.#{$ti-prefix}-brand-zoom:before { content: $ti-icon-brand-zoom; }
.#{$ti-prefix}-brand-zulip:before { content: $ti-icon-brand-zulip; }
.#{$ti-prefix}-brand-zwift:before { content: $ti-icon-brand-zwift; }
.#{$ti-prefix}-bread:before { content: $ti-icon-bread; }
.#{$ti-prefix}-bread-filled:before { content: $ti-icon-bread-filled; }
.#{$ti-prefix}-bread-off:before { content: $ti-icon-bread-off; }
.#{$ti-prefix}-briefcase:before { content: $ti-icon-briefcase; }
.#{$ti-prefix}-briefcase-2:before { content: $ti-icon-briefcase-2; }
.#{$ti-prefix}-briefcase-2-filled:before { content: $ti-icon-briefcase-2-filled; }
.#{$ti-prefix}-briefcase-filled:before { content: $ti-icon-briefcase-filled; }
.#{$ti-prefix}-briefcase-off:before { content: $ti-icon-briefcase-off; }
.#{$ti-prefix}-brightness:before { content: $ti-icon-brightness; }
.#{$ti-prefix}-brightness-2:before { content: $ti-icon-brightness-2; }
.#{$ti-prefix}-brightness-auto:before { content: $ti-icon-brightness-auto; }
.#{$ti-prefix}-brightness-auto-filled:before { content: $ti-icon-brightness-auto-filled; }
.#{$ti-prefix}-brightness-down:before { content: $ti-icon-brightness-down; }
.#{$ti-prefix}-brightness-down-filled:before { content: $ti-icon-brightness-down-filled; }
.#{$ti-prefix}-brightness-filled:before { content: $ti-icon-brightness-filled; }
.#{$ti-prefix}-brightness-half:before { content: $ti-icon-brightness-half; }
.#{$ti-prefix}-brightness-off:before { content: $ti-icon-brightness-off; }
.#{$ti-prefix}-brightness-up:before { content: $ti-icon-brightness-up; }
.#{$ti-prefix}-brightness-up-filled:before { content: $ti-icon-brightness-up-filled; }
.#{$ti-prefix}-broadcast:before { content: $ti-icon-broadcast; }
.#{$ti-prefix}-broadcast-off:before { content: $ti-icon-broadcast-off; }
.#{$ti-prefix}-browser:before { content: $ti-icon-browser; }
.#{$ti-prefix}-browser-check:before { content: $ti-icon-browser-check; }
.#{$ti-prefix}-browser-off:before { content: $ti-icon-browser-off; }
.#{$ti-prefix}-browser-plus:before { content: $ti-icon-browser-plus; }
.#{$ti-prefix}-browser-x:before { content: $ti-icon-browser-x; }
.#{$ti-prefix}-brush:before { content: $ti-icon-brush; }
.#{$ti-prefix}-brush-off:before { content: $ti-icon-brush-off; }
.#{$ti-prefix}-bubble:before { content: $ti-icon-bubble; }
.#{$ti-prefix}-bubble-filled:before { content: $ti-icon-bubble-filled; }
.#{$ti-prefix}-bubble-minus:before { content: $ti-icon-bubble-minus; }
.#{$ti-prefix}-bubble-plus:before { content: $ti-icon-bubble-plus; }
.#{$ti-prefix}-bubble-tea:before { content: $ti-icon-bubble-tea; }
.#{$ti-prefix}-bubble-tea-2:before { content: $ti-icon-bubble-tea-2; }
.#{$ti-prefix}-bubble-text:before { content: $ti-icon-bubble-text; }
.#{$ti-prefix}-bubble-x:before { content: $ti-icon-bubble-x; }
.#{$ti-prefix}-bucket:before { content: $ti-icon-bucket; }
.#{$ti-prefix}-bucket-droplet:before { content: $ti-icon-bucket-droplet; }
.#{$ti-prefix}-bucket-off:before { content: $ti-icon-bucket-off; }
.#{$ti-prefix}-bug:before { content: $ti-icon-bug; }
.#{$ti-prefix}-bug-filled:before { content: $ti-icon-bug-filled; }
.#{$ti-prefix}-bug-off:before { content: $ti-icon-bug-off; }
.#{$ti-prefix}-building:before { content: $ti-icon-building; }
.#{$ti-prefix}-building-airport:before { content: $ti-icon-building-airport; }
.#{$ti-prefix}-building-arch:before { content: $ti-icon-building-arch; }
.#{$ti-prefix}-building-bank:before { content: $ti-icon-building-bank; }
.#{$ti-prefix}-building-bridge:before { content: $ti-icon-building-bridge; }
.#{$ti-prefix}-building-bridge-2:before { content: $ti-icon-building-bridge-2; }
.#{$ti-prefix}-building-broadcast-tower:before { content: $ti-icon-building-broadcast-tower; }
.#{$ti-prefix}-building-broadcast-tower-filled:before { content: $ti-icon-building-broadcast-tower-filled; }
.#{$ti-prefix}-building-burj-al-arab:before { content: $ti-icon-building-burj-al-arab; }
.#{$ti-prefix}-building-carousel:before { content: $ti-icon-building-carousel; }
.#{$ti-prefix}-building-castle:before { content: $ti-icon-building-castle; }
.#{$ti-prefix}-building-church:before { content: $ti-icon-building-church; }
.#{$ti-prefix}-building-circus:before { content: $ti-icon-building-circus; }
.#{$ti-prefix}-building-cog:before { content: $ti-icon-building-cog; }
.#{$ti-prefix}-building-community:before { content: $ti-icon-building-community; }
.#{$ti-prefix}-building-cottage:before { content: $ti-icon-building-cottage; }
.#{$ti-prefix}-building-estate:before { content: $ti-icon-building-estate; }
.#{$ti-prefix}-building-factory:before { content: $ti-icon-building-factory; }
.#{$ti-prefix}-building-factory-2:before { content: $ti-icon-building-factory-2; }
.#{$ti-prefix}-building-fortress:before { content: $ti-icon-building-fortress; }
.#{$ti-prefix}-building-hospital:before { content: $ti-icon-building-hospital; }
.#{$ti-prefix}-building-lighthouse:before { content: $ti-icon-building-lighthouse; }
.#{$ti-prefix}-building-minus:before { content: $ti-icon-building-minus; }
.#{$ti-prefix}-building-monument:before { content: $ti-icon-building-monument; }
.#{$ti-prefix}-building-mosque:before { content: $ti-icon-building-mosque; }
.#{$ti-prefix}-building-off:before { content: $ti-icon-building-off; }
.#{$ti-prefix}-building-pavilion:before { content: $ti-icon-building-pavilion; }
.#{$ti-prefix}-building-plus:before { content: $ti-icon-building-plus; }
.#{$ti-prefix}-building-skyscraper:before { content: $ti-icon-building-skyscraper; }
.#{$ti-prefix}-building-stadium:before { content: $ti-icon-building-stadium; }
.#{$ti-prefix}-building-store:before { content: $ti-icon-building-store; }
.#{$ti-prefix}-building-tunnel:before { content: $ti-icon-building-tunnel; }
.#{$ti-prefix}-building-warehouse:before { content: $ti-icon-building-warehouse; }
.#{$ti-prefix}-building-wind-turbine:before { content: $ti-icon-building-wind-turbine; }
.#{$ti-prefix}-buildings:before { content: $ti-icon-buildings; }
.#{$ti-prefix}-bulb:before { content: $ti-icon-bulb; }
.#{$ti-prefix}-bulb-filled:before { content: $ti-icon-bulb-filled; }
.#{$ti-prefix}-bulb-off:before { content: $ti-icon-bulb-off; }
.#{$ti-prefix}-bulldozer:before { content: $ti-icon-bulldozer; }
.#{$ti-prefix}-burger:before { content: $ti-icon-burger; }
.#{$ti-prefix}-bus:before { content: $ti-icon-bus; }
.#{$ti-prefix}-bus-off:before { content: $ti-icon-bus-off; }
.#{$ti-prefix}-bus-stop:before { content: $ti-icon-bus-stop; }
.#{$ti-prefix}-businessplan:before { content: $ti-icon-businessplan; }
.#{$ti-prefix}-butterfly:before { content: $ti-icon-butterfly; }
.#{$ti-prefix}-butterfly-filled:before { content: $ti-icon-butterfly-filled; }
.#{$ti-prefix}-cactus:before { content: $ti-icon-cactus; }
.#{$ti-prefix}-cactus-filled:before { content: $ti-icon-cactus-filled; }
.#{$ti-prefix}-cactus-off:before { content: $ti-icon-cactus-off; }
.#{$ti-prefix}-cake:before { content: $ti-icon-cake; }
.#{$ti-prefix}-cake-off:before { content: $ti-icon-cake-off; }
.#{$ti-prefix}-calculator:before { content: $ti-icon-calculator; }
.#{$ti-prefix}-calculator-filled:before { content: $ti-icon-calculator-filled; }
.#{$ti-prefix}-calculator-off:before { content: $ti-icon-calculator-off; }
.#{$ti-prefix}-calendar:before { content: $ti-icon-calendar; }
.#{$ti-prefix}-calendar-bolt:before { content: $ti-icon-calendar-bolt; }
.#{$ti-prefix}-calendar-cancel:before { content: $ti-icon-calendar-cancel; }
.#{$ti-prefix}-calendar-check:before { content: $ti-icon-calendar-check; }
.#{$ti-prefix}-calendar-clock:before { content: $ti-icon-calendar-clock; }
.#{$ti-prefix}-calendar-code:before { content: $ti-icon-calendar-code; }
.#{$ti-prefix}-calendar-cog:before { content: $ti-icon-calendar-cog; }
.#{$ti-prefix}-calendar-dollar:before { content: $ti-icon-calendar-dollar; }
.#{$ti-prefix}-calendar-dot:before { content: $ti-icon-calendar-dot; }
.#{$ti-prefix}-calendar-down:before { content: $ti-icon-calendar-down; }
.#{$ti-prefix}-calendar-due:before { content: $ti-icon-calendar-due; }
.#{$ti-prefix}-calendar-event:before { content: $ti-icon-calendar-event; }
.#{$ti-prefix}-calendar-exclamation:before { content: $ti-icon-calendar-exclamation; }
.#{$ti-prefix}-calendar-filled:before { content: $ti-icon-calendar-filled; }
.#{$ti-prefix}-calendar-heart:before { content: $ti-icon-calendar-heart; }
.#{$ti-prefix}-calendar-minus:before { content: $ti-icon-calendar-minus; }
.#{$ti-prefix}-calendar-month:before { content: $ti-icon-calendar-month; }
.#{$ti-prefix}-calendar-off:before { content: $ti-icon-calendar-off; }
.#{$ti-prefix}-calendar-pause:before { content: $ti-icon-calendar-pause; }
.#{$ti-prefix}-calendar-pin:before { content: $ti-icon-calendar-pin; }
.#{$ti-prefix}-calendar-plus:before { content: $ti-icon-calendar-plus; }
.#{$ti-prefix}-calendar-question:before { content: $ti-icon-calendar-question; }
.#{$ti-prefix}-calendar-repeat:before { content: $ti-icon-calendar-repeat; }
.#{$ti-prefix}-calendar-sad:before { content: $ti-icon-calendar-sad; }
.#{$ti-prefix}-calendar-search:before { content: $ti-icon-calendar-search; }
.#{$ti-prefix}-calendar-share:before { content: $ti-icon-calendar-share; }
.#{$ti-prefix}-calendar-smile:before { content: $ti-icon-calendar-smile; }
.#{$ti-prefix}-calendar-star:before { content: $ti-icon-calendar-star; }
.#{$ti-prefix}-calendar-stats:before { content: $ti-icon-calendar-stats; }
.#{$ti-prefix}-calendar-time:before { content: $ti-icon-calendar-time; }
.#{$ti-prefix}-calendar-up:before { content: $ti-icon-calendar-up; }
.#{$ti-prefix}-calendar-user:before { content: $ti-icon-calendar-user; }
.#{$ti-prefix}-calendar-week:before { content: $ti-icon-calendar-week; }
.#{$ti-prefix}-calendar-x:before { content: $ti-icon-calendar-x; }
.#{$ti-prefix}-camera:before { content: $ti-icon-camera; }
.#{$ti-prefix}-camera-ai:before { content: $ti-icon-camera-ai; }
.#{$ti-prefix}-camera-bitcoin:before { content: $ti-icon-camera-bitcoin; }
.#{$ti-prefix}-camera-bolt:before { content: $ti-icon-camera-bolt; }
.#{$ti-prefix}-camera-cancel:before { content: $ti-icon-camera-cancel; }
.#{$ti-prefix}-camera-check:before { content: $ti-icon-camera-check; }
.#{$ti-prefix}-camera-code:before { content: $ti-icon-camera-code; }
.#{$ti-prefix}-camera-cog:before { content: $ti-icon-camera-cog; }
.#{$ti-prefix}-camera-dollar:before { content: $ti-icon-camera-dollar; }
.#{$ti-prefix}-camera-down:before { content: $ti-icon-camera-down; }
.#{$ti-prefix}-camera-exclamation:before { content: $ti-icon-camera-exclamation; }
.#{$ti-prefix}-camera-filled:before { content: $ti-icon-camera-filled; }
.#{$ti-prefix}-camera-heart:before { content: $ti-icon-camera-heart; }
.#{$ti-prefix}-camera-minus:before { content: $ti-icon-camera-minus; }
.#{$ti-prefix}-camera-moon:before { content: $ti-icon-camera-moon; }
.#{$ti-prefix}-camera-off:before { content: $ti-icon-camera-off; }
.#{$ti-prefix}-camera-pause:before { content: $ti-icon-camera-pause; }
.#{$ti-prefix}-camera-pin:before { content: $ti-icon-camera-pin; }
.#{$ti-prefix}-camera-plus:before { content: $ti-icon-camera-plus; }
.#{$ti-prefix}-camera-question:before { content: $ti-icon-camera-question; }
.#{$ti-prefix}-camera-rotate:before { content: $ti-icon-camera-rotate; }
.#{$ti-prefix}-camera-search:before { content: $ti-icon-camera-search; }
.#{$ti-prefix}-camera-selfie:before { content: $ti-icon-camera-selfie; }
.#{$ti-prefix}-camera-share:before { content: $ti-icon-camera-share; }
.#{$ti-prefix}-camera-spark:before { content: $ti-icon-camera-spark; }
.#{$ti-prefix}-camera-star:before { content: $ti-icon-camera-star; }
.#{$ti-prefix}-camera-up:before { content: $ti-icon-camera-up; }
.#{$ti-prefix}-camera-x:before { content: $ti-icon-camera-x; }
.#{$ti-prefix}-camper:before { content: $ti-icon-camper; }
.#{$ti-prefix}-campfire:before { content: $ti-icon-campfire; }
.#{$ti-prefix}-campfire-filled:before { content: $ti-icon-campfire-filled; }
.#{$ti-prefix}-cancel:before { content: $ti-icon-cancel; }
.#{$ti-prefix}-candle:before { content: $ti-icon-candle; }
.#{$ti-prefix}-candle-filled:before { content: $ti-icon-candle-filled; }
.#{$ti-prefix}-candy:before { content: $ti-icon-candy; }
.#{$ti-prefix}-candy-off:before { content: $ti-icon-candy-off; }
.#{$ti-prefix}-cane:before { content: $ti-icon-cane; }
.#{$ti-prefix}-cannabis:before { content: $ti-icon-cannabis; }
.#{$ti-prefix}-cannabis-filled:before { content: $ti-icon-cannabis-filled; }
.#{$ti-prefix}-cap-projecting:before { content: $ti-icon-cap-projecting; }
.#{$ti-prefix}-cap-rounded:before { content: $ti-icon-cap-rounded; }
.#{$ti-prefix}-cap-straight:before { content: $ti-icon-cap-straight; }
.#{$ti-prefix}-capsule:before { content: $ti-icon-capsule; }
.#{$ti-prefix}-capsule-filled:before { content: $ti-icon-capsule-filled; }
.#{$ti-prefix}-capsule-horizontal:before { content: $ti-icon-capsule-horizontal; }
.#{$ti-prefix}-capsule-horizontal-filled:before { content: $ti-icon-capsule-horizontal-filled; }
.#{$ti-prefix}-capture:before { content: $ti-icon-capture; }
.#{$ti-prefix}-capture-filled:before { content: $ti-icon-capture-filled; }
.#{$ti-prefix}-capture-off:before { content: $ti-icon-capture-off; }
.#{$ti-prefix}-car:before { content: $ti-icon-car; }
.#{$ti-prefix}-car-4wd:before { content: $ti-icon-car-4wd; }
.#{$ti-prefix}-car-4wd-filled:before { content: $ti-icon-car-4wd-filled; }
.#{$ti-prefix}-car-crane:before { content: $ti-icon-car-crane; }
.#{$ti-prefix}-car-crash:before { content: $ti-icon-car-crash; }
.#{$ti-prefix}-car-fan:before { content: $ti-icon-car-fan; }
.#{$ti-prefix}-car-fan-1:before { content: $ti-icon-car-fan-1; }
.#{$ti-prefix}-car-fan-2:before { content: $ti-icon-car-fan-2; }
.#{$ti-prefix}-car-fan-3:before { content: $ti-icon-car-fan-3; }
.#{$ti-prefix}-car-fan-auto:before { content: $ti-icon-car-fan-auto; }
.#{$ti-prefix}-car-fan-filled:before { content: $ti-icon-car-fan-filled; }
.#{$ti-prefix}-car-filled:before { content: $ti-icon-car-filled; }
.#{$ti-prefix}-car-garage:before { content: $ti-icon-car-garage; }
.#{$ti-prefix}-car-off:before { content: $ti-icon-car-off; }
.#{$ti-prefix}-car-suv:before { content: $ti-icon-car-suv; }
.#{$ti-prefix}-car-suv-filled:before { content: $ti-icon-car-suv-filled; }
.#{$ti-prefix}-car-turbine:before { content: $ti-icon-car-turbine; }
.#{$ti-prefix}-carambola:before { content: $ti-icon-carambola; }
.#{$ti-prefix}-carambola-filled:before { content: $ti-icon-carambola-filled; }
.#{$ti-prefix}-caravan:before { content: $ti-icon-caravan; }
.#{$ti-prefix}-cardboards:before { content: $ti-icon-cardboards; }
.#{$ti-prefix}-cardboards-filled:before { content: $ti-icon-cardboards-filled; }
.#{$ti-prefix}-cardboards-off:before { content: $ti-icon-cardboards-off; }
.#{$ti-prefix}-cards:before { content: $ti-icon-cards; }
.#{$ti-prefix}-cards-filled:before { content: $ti-icon-cards-filled; }
.#{$ti-prefix}-caret-down:before { content: $ti-icon-caret-down; }
.#{$ti-prefix}-caret-down-filled:before { content: $ti-icon-caret-down-filled; }
.#{$ti-prefix}-caret-left:before { content: $ti-icon-caret-left; }
.#{$ti-prefix}-caret-left-filled:before { content: $ti-icon-caret-left-filled; }
.#{$ti-prefix}-caret-left-right:before { content: $ti-icon-caret-left-right; }
.#{$ti-prefix}-caret-left-right-filled:before { content: $ti-icon-caret-left-right-filled; }
.#{$ti-prefix}-caret-right:before { content: $ti-icon-caret-right; }
.#{$ti-prefix}-caret-right-filled:before { content: $ti-icon-caret-right-filled; }
.#{$ti-prefix}-caret-up:before { content: $ti-icon-caret-up; }
.#{$ti-prefix}-caret-up-down:before { content: $ti-icon-caret-up-down; }
.#{$ti-prefix}-caret-up-down-filled:before { content: $ti-icon-caret-up-down-filled; }
.#{$ti-prefix}-caret-up-filled:before { content: $ti-icon-caret-up-filled; }
.#{$ti-prefix}-carousel-horizontal:before { content: $ti-icon-carousel-horizontal; }
.#{$ti-prefix}-carousel-horizontal-filled:before { content: $ti-icon-carousel-horizontal-filled; }
.#{$ti-prefix}-carousel-vertical:before { content: $ti-icon-carousel-vertical; }
.#{$ti-prefix}-carousel-vertical-filled:before { content: $ti-icon-carousel-vertical-filled; }
.#{$ti-prefix}-carrot:before { content: $ti-icon-carrot; }
.#{$ti-prefix}-carrot-off:before { content: $ti-icon-carrot-off; }
.#{$ti-prefix}-cash:before { content: $ti-icon-cash; }
.#{$ti-prefix}-cash-banknote:before { content: $ti-icon-cash-banknote; }
.#{$ti-prefix}-cash-banknote-filled:before { content: $ti-icon-cash-banknote-filled; }
.#{$ti-prefix}-cash-banknote-off:before { content: $ti-icon-cash-banknote-off; }
.#{$ti-prefix}-cash-off:before { content: $ti-icon-cash-off; }
.#{$ti-prefix}-cash-register:before { content: $ti-icon-cash-register; }
.#{$ti-prefix}-cast:before { content: $ti-icon-cast; }
.#{$ti-prefix}-cast-off:before { content: $ti-icon-cast-off; }
.#{$ti-prefix}-cat:before { content: $ti-icon-cat; }
.#{$ti-prefix}-category:before { content: $ti-icon-category; }
.#{$ti-prefix}-category-2:before { content: $ti-icon-category-2; }
.#{$ti-prefix}-category-filled:before { content: $ti-icon-category-filled; }
.#{$ti-prefix}-category-minus:before { content: $ti-icon-category-minus; }
.#{$ti-prefix}-category-plus:before { content: $ti-icon-category-plus; }
.#{$ti-prefix}-ce:before { content: $ti-icon-ce; }
.#{$ti-prefix}-ce-off:before { content: $ti-icon-ce-off; }
.#{$ti-prefix}-cell:before { content: $ti-icon-cell; }
.#{$ti-prefix}-cell-signal-1:before { content: $ti-icon-cell-signal-1; }
.#{$ti-prefix}-cell-signal-2:before { content: $ti-icon-cell-signal-2; }
.#{$ti-prefix}-cell-signal-3:before { content: $ti-icon-cell-signal-3; }
.#{$ti-prefix}-cell-signal-4:before { content: $ti-icon-cell-signal-4; }
.#{$ti-prefix}-cell-signal-5:before { content: $ti-icon-cell-signal-5; }
.#{$ti-prefix}-cell-signal-off:before { content: $ti-icon-cell-signal-off; }
.#{$ti-prefix}-certificate:before { content: $ti-icon-certificate; }
.#{$ti-prefix}-certificate-2:before { content: $ti-icon-certificate-2; }
.#{$ti-prefix}-certificate-2-off:before { content: $ti-icon-certificate-2-off; }
.#{$ti-prefix}-certificate-off:before { content: $ti-icon-certificate-off; }
.#{$ti-prefix}-chair-director:before { content: $ti-icon-chair-director; }
.#{$ti-prefix}-chalkboard:before { content: $ti-icon-chalkboard; }
.#{$ti-prefix}-chalkboard-off:before { content: $ti-icon-chalkboard-off; }
.#{$ti-prefix}-charging-pile:before { content: $ti-icon-charging-pile; }
.#{$ti-prefix}-charging-pile-filled:before { content: $ti-icon-charging-pile-filled; }
.#{$ti-prefix}-chart-arcs:before { content: $ti-icon-chart-arcs; }
.#{$ti-prefix}-chart-arcs-3:before { content: $ti-icon-chart-arcs-3; }
.#{$ti-prefix}-chart-area:before { content: $ti-icon-chart-area; }
.#{$ti-prefix}-chart-area-filled:before { content: $ti-icon-chart-area-filled; }
.#{$ti-prefix}-chart-area-line:before { content: $ti-icon-chart-area-line; }
.#{$ti-prefix}-chart-area-line-filled:before { content: $ti-icon-chart-area-line-filled; }
.#{$ti-prefix}-chart-arrows:before { content: $ti-icon-chart-arrows; }
.#{$ti-prefix}-chart-arrows-vertical:before { content: $ti-icon-chart-arrows-vertical; }
.#{$ti-prefix}-chart-bar:before { content: $ti-icon-chart-bar; }
.#{$ti-prefix}-chart-bar-off:before { content: $ti-icon-chart-bar-off; }
.#{$ti-prefix}-chart-bar-popular:before { content: $ti-icon-chart-bar-popular; }
.#{$ti-prefix}-chart-bubble:before { content: $ti-icon-chart-bubble; }
.#{$ti-prefix}-chart-bubble-filled:before { content: $ti-icon-chart-bubble-filled; }
.#{$ti-prefix}-chart-candle:before { content: $ti-icon-chart-candle; }
.#{$ti-prefix}-chart-candle-filled:before { content: $ti-icon-chart-candle-filled; }
.#{$ti-prefix}-chart-circles:before { content: $ti-icon-chart-circles; }
.#{$ti-prefix}-chart-cohort:before { content: $ti-icon-chart-cohort; }
.#{$ti-prefix}-chart-column:before { content: $ti-icon-chart-column; }
.#{$ti-prefix}-chart-covariate:before { content: $ti-icon-chart-covariate; }
.#{$ti-prefix}-chart-donut:before { content: $ti-icon-chart-donut; }
.#{$ti-prefix}-chart-donut-2:before { content: $ti-icon-chart-donut-2; }
.#{$ti-prefix}-chart-donut-3:before { content: $ti-icon-chart-donut-3; }
.#{$ti-prefix}-chart-donut-4:before { content: $ti-icon-chart-donut-4; }
.#{$ti-prefix}-chart-donut-filled:before { content: $ti-icon-chart-donut-filled; }
.#{$ti-prefix}-chart-dots:before { content: $ti-icon-chart-dots; }
.#{$ti-prefix}-chart-dots-2:before { content: $ti-icon-chart-dots-2; }
.#{$ti-prefix}-chart-dots-3:before { content: $ti-icon-chart-dots-3; }
.#{$ti-prefix}-chart-dots-filled:before { content: $ti-icon-chart-dots-filled; }
.#{$ti-prefix}-chart-funnel:before { content: $ti-icon-chart-funnel; }
.#{$ti-prefix}-chart-grid-dots:before { content: $ti-icon-chart-grid-dots; }
.#{$ti-prefix}-chart-grid-dots-filled:before { content: $ti-icon-chart-grid-dots-filled; }
.#{$ti-prefix}-chart-histogram:before { content: $ti-icon-chart-histogram; }
.#{$ti-prefix}-chart-infographic:before { content: $ti-icon-chart-infographic; }
.#{$ti-prefix}-chart-line:before { content: $ti-icon-chart-line; }
.#{$ti-prefix}-chart-pie:before { content: $ti-icon-chart-pie; }
.#{$ti-prefix}-chart-pie-2:before { content: $ti-icon-chart-pie-2; }
.#{$ti-prefix}-chart-pie-3:before { content: $ti-icon-chart-pie-3; }
.#{$ti-prefix}-chart-pie-4:before { content: $ti-icon-chart-pie-4; }
.#{$ti-prefix}-chart-pie-filled:before { content: $ti-icon-chart-pie-filled; }
.#{$ti-prefix}-chart-pie-off:before { content: $ti-icon-chart-pie-off; }
.#{$ti-prefix}-chart-ppf:before { content: $ti-icon-chart-ppf; }
.#{$ti-prefix}-chart-radar:before { content: $ti-icon-chart-radar; }
.#{$ti-prefix}-chart-sankey:before { content: $ti-icon-chart-sankey; }
.#{$ti-prefix}-chart-scatter:before { content: $ti-icon-chart-scatter; }
.#{$ti-prefix}-chart-scatter-3d:before { content: $ti-icon-chart-scatter-3d; }
.#{$ti-prefix}-chart-treemap:before { content: $ti-icon-chart-treemap; }
.#{$ti-prefix}-check:before { content: $ti-icon-check; }
.#{$ti-prefix}-checkbox:before { content: $ti-icon-checkbox; }
.#{$ti-prefix}-checklist:before { content: $ti-icon-checklist; }
.#{$ti-prefix}-checks:before { content: $ti-icon-checks; }
.#{$ti-prefix}-checkup-list:before { content: $ti-icon-checkup-list; }
.#{$ti-prefix}-cheese:before { content: $ti-icon-cheese; }
.#{$ti-prefix}-chef-hat:before { content: $ti-icon-chef-hat; }
.#{$ti-prefix}-chef-hat-off:before { content: $ti-icon-chef-hat-off; }
.#{$ti-prefix}-cherry:before { content: $ti-icon-cherry; }
.#{$ti-prefix}-cherry-filled:before { content: $ti-icon-cherry-filled; }
.#{$ti-prefix}-chess:before { content: $ti-icon-chess; }
.#{$ti-prefix}-chess-bishop:before { content: $ti-icon-chess-bishop; }
.#{$ti-prefix}-chess-bishop-filled:before { content: $ti-icon-chess-bishop-filled; }
.#{$ti-prefix}-chess-filled:before { content: $ti-icon-chess-filled; }
.#{$ti-prefix}-chess-king:before { content: $ti-icon-chess-king; }
.#{$ti-prefix}-chess-king-filled:before { content: $ti-icon-chess-king-filled; }
.#{$ti-prefix}-chess-knight:before { content: $ti-icon-chess-knight; }
.#{$ti-prefix}-chess-knight-filled:before { content: $ti-icon-chess-knight-filled; }
.#{$ti-prefix}-chess-queen:before { content: $ti-icon-chess-queen; }
.#{$ti-prefix}-chess-queen-filled:before { content: $ti-icon-chess-queen-filled; }
.#{$ti-prefix}-chess-rook:before { content: $ti-icon-chess-rook; }
.#{$ti-prefix}-chess-rook-filled:before { content: $ti-icon-chess-rook-filled; }
.#{$ti-prefix}-chevron-compact-down:before { content: $ti-icon-chevron-compact-down; }
.#{$ti-prefix}-chevron-compact-left:before { content: $ti-icon-chevron-compact-left; }
.#{$ti-prefix}-chevron-compact-right:before { content: $ti-icon-chevron-compact-right; }
.#{$ti-prefix}-chevron-compact-up:before { content: $ti-icon-chevron-compact-up; }
.#{$ti-prefix}-chevron-down:before { content: $ti-icon-chevron-down; }
.#{$ti-prefix}-chevron-down-left:before { content: $ti-icon-chevron-down-left; }
.#{$ti-prefix}-chevron-down-right:before { content: $ti-icon-chevron-down-right; }
.#{$ti-prefix}-chevron-left:before { content: $ti-icon-chevron-left; }
.#{$ti-prefix}-chevron-left-pipe:before { content: $ti-icon-chevron-left-pipe; }
.#{$ti-prefix}-chevron-right:before { content: $ti-icon-chevron-right; }
.#{$ti-prefix}-chevron-right-pipe:before { content: $ti-icon-chevron-right-pipe; }
.#{$ti-prefix}-chevron-up:before { content: $ti-icon-chevron-up; }
.#{$ti-prefix}-chevron-up-left:before { content: $ti-icon-chevron-up-left; }
.#{$ti-prefix}-chevron-up-right:before { content: $ti-icon-chevron-up-right; }
.#{$ti-prefix}-chevrons-down:before { content: $ti-icon-chevrons-down; }
.#{$ti-prefix}-chevrons-down-left:before { content: $ti-icon-chevrons-down-left; }
.#{$ti-prefix}-chevrons-down-right:before { content: $ti-icon-chevrons-down-right; }
.#{$ti-prefix}-chevrons-left:before { content: $ti-icon-chevrons-left; }
.#{$ti-prefix}-chevrons-right:before { content: $ti-icon-chevrons-right; }
.#{$ti-prefix}-chevrons-up:before { content: $ti-icon-chevrons-up; }
.#{$ti-prefix}-chevrons-up-left:before { content: $ti-icon-chevrons-up-left; }
.#{$ti-prefix}-chevrons-up-right:before { content: $ti-icon-chevrons-up-right; }
.#{$ti-prefix}-chisel:before { content: $ti-icon-chisel; }
.#{$ti-prefix}-christmas-ball:before { content: $ti-icon-christmas-ball; }
.#{$ti-prefix}-christmas-tree:before { content: $ti-icon-christmas-tree; }
.#{$ti-prefix}-christmas-tree-filled:before { content: $ti-icon-christmas-tree-filled; }
.#{$ti-prefix}-christmas-tree-off:before { content: $ti-icon-christmas-tree-off; }
.#{$ti-prefix}-circle:before { content: $ti-icon-circle; }
.#{$ti-prefix}-circle-arrow-down:before { content: $ti-icon-circle-arrow-down; }
.#{$ti-prefix}-circle-arrow-down-filled:before { content: $ti-icon-circle-arrow-down-filled; }
.#{$ti-prefix}-circle-arrow-down-left:before { content: $ti-icon-circle-arrow-down-left; }
.#{$ti-prefix}-circle-arrow-down-left-filled:before { content: $ti-icon-circle-arrow-down-left-filled; }
.#{$ti-prefix}-circle-arrow-down-right:before { content: $ti-icon-circle-arrow-down-right; }
.#{$ti-prefix}-circle-arrow-down-right-filled:before { content: $ti-icon-circle-arrow-down-right-filled; }
.#{$ti-prefix}-circle-arrow-left:before { content: $ti-icon-circle-arrow-left; }
.#{$ti-prefix}-circle-arrow-left-filled:before { content: $ti-icon-circle-arrow-left-filled; }
.#{$ti-prefix}-circle-arrow-right:before { content: $ti-icon-circle-arrow-right; }
.#{$ti-prefix}-circle-arrow-right-filled:before { content: $ti-icon-circle-arrow-right-filled; }
.#{$ti-prefix}-circle-arrow-up:before { content: $ti-icon-circle-arrow-up; }
.#{$ti-prefix}-circle-arrow-up-filled:before { content: $ti-icon-circle-arrow-up-filled; }
.#{$ti-prefix}-circle-arrow-up-left:before { content: $ti-icon-circle-arrow-up-left; }
.#{$ti-prefix}-circle-arrow-up-left-filled:before { content: $ti-icon-circle-arrow-up-left-filled; }
.#{$ti-prefix}-circle-arrow-up-right:before { content: $ti-icon-circle-arrow-up-right; }
.#{$ti-prefix}-circle-arrow-up-right-filled:before { content: $ti-icon-circle-arrow-up-right-filled; }
.#{$ti-prefix}-circle-caret-down:before { content: $ti-icon-circle-caret-down; }
.#{$ti-prefix}-circle-caret-left:before { content: $ti-icon-circle-caret-left; }
.#{$ti-prefix}-circle-caret-right:before { content: $ti-icon-circle-caret-right; }
.#{$ti-prefix}-circle-caret-up:before { content: $ti-icon-circle-caret-up; }
.#{$ti-prefix}-circle-check:before { content: $ti-icon-circle-check; }
.#{$ti-prefix}-circle-check-filled:before { content: $ti-icon-circle-check-filled; }
.#{$ti-prefix}-circle-chevron-down:before { content: $ti-icon-circle-chevron-down; }
.#{$ti-prefix}-circle-chevron-left:before { content: $ti-icon-circle-chevron-left; }
.#{$ti-prefix}-circle-chevron-right:before { content: $ti-icon-circle-chevron-right; }
.#{$ti-prefix}-circle-chevron-up:before { content: $ti-icon-circle-chevron-up; }
.#{$ti-prefix}-circle-chevrons-down:before { content: $ti-icon-circle-chevrons-down; }
.#{$ti-prefix}-circle-chevrons-left:before { content: $ti-icon-circle-chevrons-left; }
.#{$ti-prefix}-circle-chevrons-right:before { content: $ti-icon-circle-chevrons-right; }
.#{$ti-prefix}-circle-chevrons-up:before { content: $ti-icon-circle-chevrons-up; }
.#{$ti-prefix}-circle-dashed:before { content: $ti-icon-circle-dashed; }
.#{$ti-prefix}-circle-dashed-check:before { content: $ti-icon-circle-dashed-check; }
.#{$ti-prefix}-circle-dashed-letter-a:before { content: $ti-icon-circle-dashed-letter-a; }
.#{$ti-prefix}-circle-dashed-letter-b:before { content: $ti-icon-circle-dashed-letter-b; }
.#{$ti-prefix}-circle-dashed-letter-c:before { content: $ti-icon-circle-dashed-letter-c; }
.#{$ti-prefix}-circle-dashed-letter-d:before { content: $ti-icon-circle-dashed-letter-d; }
.#{$ti-prefix}-circle-dashed-letter-e:before { content: $ti-icon-circle-dashed-letter-e; }
.#{$ti-prefix}-circle-dashed-letter-f:before { content: $ti-icon-circle-dashed-letter-f; }
.#{$ti-prefix}-circle-dashed-letter-g:before { content: $ti-icon-circle-dashed-letter-g; }
.#{$ti-prefix}-circle-dashed-letter-h:before { content: $ti-icon-circle-dashed-letter-h; }
.#{$ti-prefix}-circle-dashed-letter-i:before { content: $ti-icon-circle-dashed-letter-i; }
.#{$ti-prefix}-circle-dashed-letter-j:before { content: $ti-icon-circle-dashed-letter-j; }
.#{$ti-prefix}-circle-dashed-letter-k:before { content: $ti-icon-circle-dashed-letter-k; }
.#{$ti-prefix}-circle-dashed-letter-l:before { content: $ti-icon-circle-dashed-letter-l; }
.#{$ti-prefix}-circle-dashed-letter-letter-v:before { content: $ti-icon-circle-dashed-letter-letter-v; }
.#{$ti-prefix}-circle-dashed-letter-m:before { content: $ti-icon-circle-dashed-letter-m; }
.#{$ti-prefix}-circle-dashed-letter-n:before { content: $ti-icon-circle-dashed-letter-n; }
.#{$ti-prefix}-circle-dashed-letter-o:before { content: $ti-icon-circle-dashed-letter-o; }
.#{$ti-prefix}-circle-dashed-letter-p:before { content: $ti-icon-circle-dashed-letter-p; }
.#{$ti-prefix}-circle-dashed-letter-q:before { content: $ti-icon-circle-dashed-letter-q; }
.#{$ti-prefix}-circle-dashed-letter-r:before { content: $ti-icon-circle-dashed-letter-r; }
.#{$ti-prefix}-circle-dashed-letter-s:before { content: $ti-icon-circle-dashed-letter-s; }
.#{$ti-prefix}-circle-dashed-letter-t:before { content: $ti-icon-circle-dashed-letter-t; }
.#{$ti-prefix}-circle-dashed-letter-u:before { content: $ti-icon-circle-dashed-letter-u; }
.#{$ti-prefix}-circle-dashed-letter-v:before { content: $ti-icon-circle-dashed-letter-v; }
.#{$ti-prefix}-circle-dashed-letter-w:before { content: $ti-icon-circle-dashed-letter-w; }
.#{$ti-prefix}-circle-dashed-letter-x:before { content: $ti-icon-circle-dashed-letter-x; }
.#{$ti-prefix}-circle-dashed-letter-y:before { content: $ti-icon-circle-dashed-letter-y; }
.#{$ti-prefix}-circle-dashed-letter-z:before { content: $ti-icon-circle-dashed-letter-z; }
.#{$ti-prefix}-circle-dashed-minus:before { content: $ti-icon-circle-dashed-minus; }
.#{$ti-prefix}-circle-dashed-number-0:before { content: $ti-icon-circle-dashed-number-0; }
.#{$ti-prefix}-circle-dashed-number-1:before { content: $ti-icon-circle-dashed-number-1; }
.#{$ti-prefix}-circle-dashed-number-2:before { content: $ti-icon-circle-dashed-number-2; }
.#{$ti-prefix}-circle-dashed-number-3:before { content: $ti-icon-circle-dashed-number-3; }
.#{$ti-prefix}-circle-dashed-number-4:before { content: $ti-icon-circle-dashed-number-4; }
.#{$ti-prefix}-circle-dashed-number-5:before { content: $ti-icon-circle-dashed-number-5; }
.#{$ti-prefix}-circle-dashed-number-6:before { content: $ti-icon-circle-dashed-number-6; }
.#{$ti-prefix}-circle-dashed-number-7:before { content: $ti-icon-circle-dashed-number-7; }
.#{$ti-prefix}-circle-dashed-number-8:before { content: $ti-icon-circle-dashed-number-8; }
.#{$ti-prefix}-circle-dashed-number-9:before { content: $ti-icon-circle-dashed-number-9; }
.#{$ti-prefix}-circle-dashed-percentage:before { content: $ti-icon-circle-dashed-percentage; }
.#{$ti-prefix}-circle-dashed-plus:before { content: $ti-icon-circle-dashed-plus; }
.#{$ti-prefix}-circle-dashed-x:before { content: $ti-icon-circle-dashed-x; }
.#{$ti-prefix}-circle-dot:before { content: $ti-icon-circle-dot; }
.#{$ti-prefix}-circle-dot-filled:before { content: $ti-icon-circle-dot-filled; }
.#{$ti-prefix}-circle-dotted:before { content: $ti-icon-circle-dotted; }
.#{$ti-prefix}-circle-dotted-letter-a:before { content: $ti-icon-circle-dotted-letter-a; }
.#{$ti-prefix}-circle-dotted-letter-b:before { content: $ti-icon-circle-dotted-letter-b; }
.#{$ti-prefix}-circle-dotted-letter-c:before { content: $ti-icon-circle-dotted-letter-c; }
.#{$ti-prefix}-circle-dotted-letter-d:before { content: $ti-icon-circle-dotted-letter-d; }
.#{$ti-prefix}-circle-dotted-letter-e:before { content: $ti-icon-circle-dotted-letter-e; }
.#{$ti-prefix}-circle-dotted-letter-f:before { content: $ti-icon-circle-dotted-letter-f; }
.#{$ti-prefix}-circle-dotted-letter-g:before { content: $ti-icon-circle-dotted-letter-g; }
.#{$ti-prefix}-circle-dotted-letter-h:before { content: $ti-icon-circle-dotted-letter-h; }
.#{$ti-prefix}-circle-dotted-letter-i:before { content: $ti-icon-circle-dotted-letter-i; }
.#{$ti-prefix}-circle-dotted-letter-j:before { content: $ti-icon-circle-dotted-letter-j; }
.#{$ti-prefix}-circle-dotted-letter-k:before { content: $ti-icon-circle-dotted-letter-k; }
.#{$ti-prefix}-circle-dotted-letter-l:before { content: $ti-icon-circle-dotted-letter-l; }
.#{$ti-prefix}-circle-dotted-letter-m:before { content: $ti-icon-circle-dotted-letter-m; }
.#{$ti-prefix}-circle-dotted-letter-n:before { content: $ti-icon-circle-dotted-letter-n; }
.#{$ti-prefix}-circle-dotted-letter-o:before { content: $ti-icon-circle-dotted-letter-o; }
.#{$ti-prefix}-circle-dotted-letter-p:before { content: $ti-icon-circle-dotted-letter-p; }
.#{$ti-prefix}-circle-dotted-letter-q:before { content: $ti-icon-circle-dotted-letter-q; }
.#{$ti-prefix}-circle-dotted-letter-r:before { content: $ti-icon-circle-dotted-letter-r; }
.#{$ti-prefix}-circle-dotted-letter-s:before { content: $ti-icon-circle-dotted-letter-s; }
.#{$ti-prefix}-circle-dotted-letter-t:before { content: $ti-icon-circle-dotted-letter-t; }
.#{$ti-prefix}-circle-dotted-letter-u:before { content: $ti-icon-circle-dotted-letter-u; }
.#{$ti-prefix}-circle-dotted-letter-v:before { content: $ti-icon-circle-dotted-letter-v; }
.#{$ti-prefix}-circle-dotted-letter-w:before { content: $ti-icon-circle-dotted-letter-w; }
.#{$ti-prefix}-circle-dotted-letter-x:before { content: $ti-icon-circle-dotted-letter-x; }
.#{$ti-prefix}-circle-dotted-letter-y:before { content: $ti-icon-circle-dotted-letter-y; }
.#{$ti-prefix}-circle-dotted-letter-z:before { content: $ti-icon-circle-dotted-letter-z; }
.#{$ti-prefix}-circle-filled:before { content: $ti-icon-circle-filled; }
.#{$ti-prefix}-circle-half:before { content: $ti-icon-circle-half; }
.#{$ti-prefix}-circle-half-2:before { content: $ti-icon-circle-half-2; }
.#{$ti-prefix}-circle-half-vertical:before { content: $ti-icon-circle-half-vertical; }
.#{$ti-prefix}-circle-key:before { content: $ti-icon-circle-key; }
.#{$ti-prefix}-circle-key-filled:before { content: $ti-icon-circle-key-filled; }
.#{$ti-prefix}-circle-letter-a:before { content: $ti-icon-circle-letter-a; }
.#{$ti-prefix}-circle-letter-a-filled:before { content: $ti-icon-circle-letter-a-filled; }
.#{$ti-prefix}-circle-letter-b:before { content: $ti-icon-circle-letter-b; }
.#{$ti-prefix}-circle-letter-b-filled:before { content: $ti-icon-circle-letter-b-filled; }
.#{$ti-prefix}-circle-letter-c:before { content: $ti-icon-circle-letter-c; }
.#{$ti-prefix}-circle-letter-c-filled:before { content: $ti-icon-circle-letter-c-filled; }
.#{$ti-prefix}-circle-letter-d:before { content: $ti-icon-circle-letter-d; }
.#{$ti-prefix}-circle-letter-d-filled:before { content: $ti-icon-circle-letter-d-filled; }
.#{$ti-prefix}-circle-letter-e:before { content: $ti-icon-circle-letter-e; }
.#{$ti-prefix}-circle-letter-e-filled:before { content: $ti-icon-circle-letter-e-filled; }
.#{$ti-prefix}-circle-letter-f:before { content: $ti-icon-circle-letter-f; }
.#{$ti-prefix}-circle-letter-f-filled:before { content: $ti-icon-circle-letter-f-filled; }
.#{$ti-prefix}-circle-letter-g:before { content: $ti-icon-circle-letter-g; }
.#{$ti-prefix}-circle-letter-g-filled:before { content: $ti-icon-circle-letter-g-filled; }
.#{$ti-prefix}-circle-letter-h:before { content: $ti-icon-circle-letter-h; }
.#{$ti-prefix}-circle-letter-h-filled:before { content: $ti-icon-circle-letter-h-filled; }
.#{$ti-prefix}-circle-letter-i:before { content: $ti-icon-circle-letter-i; }
.#{$ti-prefix}-circle-letter-i-filled:before { content: $ti-icon-circle-letter-i-filled; }
.#{$ti-prefix}-circle-letter-j:before { content: $ti-icon-circle-letter-j; }
.#{$ti-prefix}-circle-letter-j-filled:before { content: $ti-icon-circle-letter-j-filled; }
.#{$ti-prefix}-circle-letter-k:before { content: $ti-icon-circle-letter-k; }
.#{$ti-prefix}-circle-letter-k-filled:before { content: $ti-icon-circle-letter-k-filled; }
.#{$ti-prefix}-circle-letter-l:before { content: $ti-icon-circle-letter-l; }
.#{$ti-prefix}-circle-letter-l-filled:before { content: $ti-icon-circle-letter-l-filled; }
.#{$ti-prefix}-circle-letter-m:before { content: $ti-icon-circle-letter-m; }
.#{$ti-prefix}-circle-letter-m-filled:before { content: $ti-icon-circle-letter-m-filled; }
.#{$ti-prefix}-circle-letter-n:before { content: $ti-icon-circle-letter-n; }
.#{$ti-prefix}-circle-letter-n-filled:before { content: $ti-icon-circle-letter-n-filled; }
.#{$ti-prefix}-circle-letter-o:before { content: $ti-icon-circle-letter-o; }
.#{$ti-prefix}-circle-letter-o-filled:before { content: $ti-icon-circle-letter-o-filled; }
.#{$ti-prefix}-circle-letter-p:before { content: $ti-icon-circle-letter-p; }
.#{$ti-prefix}-circle-letter-p-filled:before { content: $ti-icon-circle-letter-p-filled; }
.#{$ti-prefix}-circle-letter-q:before { content: $ti-icon-circle-letter-q; }
.#{$ti-prefix}-circle-letter-q-filled:before { content: $ti-icon-circle-letter-q-filled; }
.#{$ti-prefix}-circle-letter-r:before { content: $ti-icon-circle-letter-r; }
.#{$ti-prefix}-circle-letter-r-filled:before { content: $ti-icon-circle-letter-r-filled; }
.#{$ti-prefix}-circle-letter-s:before { content: $ti-icon-circle-letter-s; }
.#{$ti-prefix}-circle-letter-s-filled:before { content: $ti-icon-circle-letter-s-filled; }
.#{$ti-prefix}-circle-letter-t:before { content: $ti-icon-circle-letter-t; }
.#{$ti-prefix}-circle-letter-t-filled:before { content: $ti-icon-circle-letter-t-filled; }
.#{$ti-prefix}-circle-letter-u:before { content: $ti-icon-circle-letter-u; }
.#{$ti-prefix}-circle-letter-u-filled:before { content: $ti-icon-circle-letter-u-filled; }
.#{$ti-prefix}-circle-letter-v:before { content: $ti-icon-circle-letter-v; }
.#{$ti-prefix}-circle-letter-v-filled:before { content: $ti-icon-circle-letter-v-filled; }
.#{$ti-prefix}-circle-letter-w:before { content: $ti-icon-circle-letter-w; }
.#{$ti-prefix}-circle-letter-w-filled:before { content: $ti-icon-circle-letter-w-filled; }
.#{$ti-prefix}-circle-letter-x:before { content: $ti-icon-circle-letter-x; }
.#{$ti-prefix}-circle-letter-x-filled:before { content: $ti-icon-circle-letter-x-filled; }
.#{$ti-prefix}-circle-letter-y:before { content: $ti-icon-circle-letter-y; }
.#{$ti-prefix}-circle-letter-y-filled:before { content: $ti-icon-circle-letter-y-filled; }
.#{$ti-prefix}-circle-letter-z:before { content: $ti-icon-circle-letter-z; }
.#{$ti-prefix}-circle-letter-z-filled:before { content: $ti-icon-circle-letter-z-filled; }
.#{$ti-prefix}-circle-minus:before { content: $ti-icon-circle-minus; }
.#{$ti-prefix}-circle-minus-2:before { content: $ti-icon-circle-minus-2; }
.#{$ti-prefix}-circle-number-0:before { content: $ti-icon-circle-number-0; }
.#{$ti-prefix}-circle-number-0-filled:before { content: $ti-icon-circle-number-0-filled; }
.#{$ti-prefix}-circle-number-1:before { content: $ti-icon-circle-number-1; }
.#{$ti-prefix}-circle-number-1-filled:before { content: $ti-icon-circle-number-1-filled; }
.#{$ti-prefix}-circle-number-2:before { content: $ti-icon-circle-number-2; }
.#{$ti-prefix}-circle-number-2-filled:before { content: $ti-icon-circle-number-2-filled; }
.#{$ti-prefix}-circle-number-3:before { content: $ti-icon-circle-number-3; }
.#{$ti-prefix}-circle-number-3-filled:before { content: $ti-icon-circle-number-3-filled; }
.#{$ti-prefix}-circle-number-4:before { content: $ti-icon-circle-number-4; }
.#{$ti-prefix}-circle-number-4-filled:before { content: $ti-icon-circle-number-4-filled; }
.#{$ti-prefix}-circle-number-5:before { content: $ti-icon-circle-number-5; }
.#{$ti-prefix}-circle-number-5-filled:before { content: $ti-icon-circle-number-5-filled; }
.#{$ti-prefix}-circle-number-6:before { content: $ti-icon-circle-number-6; }
.#{$ti-prefix}-circle-number-6-filled:before { content: $ti-icon-circle-number-6-filled; }
.#{$ti-prefix}-circle-number-7:before { content: $ti-icon-circle-number-7; }
.#{$ti-prefix}-circle-number-7-filled:before { content: $ti-icon-circle-number-7-filled; }
.#{$ti-prefix}-circle-number-8:before { content: $ti-icon-circle-number-8; }
.#{$ti-prefix}-circle-number-8-filled:before { content: $ti-icon-circle-number-8-filled; }
.#{$ti-prefix}-circle-number-9:before { content: $ti-icon-circle-number-9; }
.#{$ti-prefix}-circle-number-9-filled:before { content: $ti-icon-circle-number-9-filled; }
.#{$ti-prefix}-circle-off:before { content: $ti-icon-circle-off; }
.#{$ti-prefix}-circle-percentage:before { content: $ti-icon-circle-percentage; }
.#{$ti-prefix}-circle-percentage-filled:before { content: $ti-icon-circle-percentage-filled; }
.#{$ti-prefix}-circle-plus:before { content: $ti-icon-circle-plus; }
.#{$ti-prefix}-circle-plus-2:before { content: $ti-icon-circle-plus-2; }
.#{$ti-prefix}-circle-plus-filled:before { content: $ti-icon-circle-plus-filled; }
.#{$ti-prefix}-circle-rectangle:before { content: $ti-icon-circle-rectangle; }
.#{$ti-prefix}-circle-rectangle-filled:before { content: $ti-icon-circle-rectangle-filled; }
.#{$ti-prefix}-circle-rectangle-off:before { content: $ti-icon-circle-rectangle-off; }
.#{$ti-prefix}-circle-square:before { content: $ti-icon-circle-square; }
.#{$ti-prefix}-circle-triangle:before { content: $ti-icon-circle-triangle; }
.#{$ti-prefix}-circle-x:before { content: $ti-icon-circle-x; }
.#{$ti-prefix}-circle-x-filled:before { content: $ti-icon-circle-x-filled; }
.#{$ti-prefix}-circles:before { content: $ti-icon-circles; }
.#{$ti-prefix}-circles-filled:before { content: $ti-icon-circles-filled; }
.#{$ti-prefix}-circles-relation:before { content: $ti-icon-circles-relation; }
.#{$ti-prefix}-circuit-ammeter:before { content: $ti-icon-circuit-ammeter; }
.#{$ti-prefix}-circuit-battery:before { content: $ti-icon-circuit-battery; }
.#{$ti-prefix}-circuit-bulb:before { content: $ti-icon-circuit-bulb; }
.#{$ti-prefix}-circuit-capacitor:before { content: $ti-icon-circuit-capacitor; }
.#{$ti-prefix}-circuit-capacitor-polarized:before { content: $ti-icon-circuit-capacitor-polarized; }
.#{$ti-prefix}-circuit-cell:before { content: $ti-icon-circuit-cell; }
.#{$ti-prefix}-circuit-cell-plus:before { content: $ti-icon-circuit-cell-plus; }
.#{$ti-prefix}-circuit-changeover:before { content: $ti-icon-circuit-changeover; }
.#{$ti-prefix}-circuit-diode:before { content: $ti-icon-circuit-diode; }
.#{$ti-prefix}-circuit-diode-zener:before { content: $ti-icon-circuit-diode-zener; }
.#{$ti-prefix}-circuit-ground:before { content: $ti-icon-circuit-ground; }
.#{$ti-prefix}-circuit-ground-digital:before { content: $ti-icon-circuit-ground-digital; }
.#{$ti-prefix}-circuit-inductor:before { content: $ti-icon-circuit-inductor; }
.#{$ti-prefix}-circuit-motor:before { content: $ti-icon-circuit-motor; }
.#{$ti-prefix}-circuit-pushbutton:before { content: $ti-icon-circuit-pushbutton; }
.#{$ti-prefix}-circuit-resistor:before { content: $ti-icon-circuit-resistor; }
.#{$ti-prefix}-circuit-switch-closed:before { content: $ti-icon-circuit-switch-closed; }
.#{$ti-prefix}-circuit-switch-open:before { content: $ti-icon-circuit-switch-open; }
.#{$ti-prefix}-circuit-voltmeter:before { content: $ti-icon-circuit-voltmeter; }
.#{$ti-prefix}-clear-all:before { content: $ti-icon-clear-all; }
.#{$ti-prefix}-clear-formatting:before { content: $ti-icon-clear-formatting; }
.#{$ti-prefix}-click:before { content: $ti-icon-click; }
.#{$ti-prefix}-cliff-jumping:before { content: $ti-icon-cliff-jumping; }
.#{$ti-prefix}-clipboard:before { content: $ti-icon-clipboard; }
.#{$ti-prefix}-clipboard-check:before { content: $ti-icon-clipboard-check; }
.#{$ti-prefix}-clipboard-copy:before { content: $ti-icon-clipboard-copy; }
.#{$ti-prefix}-clipboard-data:before { content: $ti-icon-clipboard-data; }
.#{$ti-prefix}-clipboard-heart:before { content: $ti-icon-clipboard-heart; }
.#{$ti-prefix}-clipboard-list:before { content: $ti-icon-clipboard-list; }
.#{$ti-prefix}-clipboard-off:before { content: $ti-icon-clipboard-off; }
.#{$ti-prefix}-clipboard-plus:before { content: $ti-icon-clipboard-plus; }
.#{$ti-prefix}-clipboard-smile:before { content: $ti-icon-clipboard-smile; }
.#{$ti-prefix}-clipboard-text:before { content: $ti-icon-clipboard-text; }
.#{$ti-prefix}-clipboard-typography:before { content: $ti-icon-clipboard-typography; }
.#{$ti-prefix}-clipboard-x:before { content: $ti-icon-clipboard-x; }
.#{$ti-prefix}-clock:before { content: $ti-icon-clock; }
.#{$ti-prefix}-clock-12:before { content: $ti-icon-clock-12; }
.#{$ti-prefix}-clock-2:before { content: $ti-icon-clock-2; }
.#{$ti-prefix}-clock-24:before { content: $ti-icon-clock-24; }
.#{$ti-prefix}-clock-bitcoin:before { content: $ti-icon-clock-bitcoin; }
.#{$ti-prefix}-clock-bolt:before { content: $ti-icon-clock-bolt; }
.#{$ti-prefix}-clock-cancel:before { content: $ti-icon-clock-cancel; }
.#{$ti-prefix}-clock-check:before { content: $ti-icon-clock-check; }
.#{$ti-prefix}-clock-code:before { content: $ti-icon-clock-code; }
.#{$ti-prefix}-clock-cog:before { content: $ti-icon-clock-cog; }
.#{$ti-prefix}-clock-dollar:before { content: $ti-icon-clock-dollar; }
.#{$ti-prefix}-clock-down:before { content: $ti-icon-clock-down; }
.#{$ti-prefix}-clock-edit:before { content: $ti-icon-clock-edit; }
.#{$ti-prefix}-clock-exclamation:before { content: $ti-icon-clock-exclamation; }
.#{$ti-prefix}-clock-filled:before { content: $ti-icon-clock-filled; }
.#{$ti-prefix}-clock-heart:before { content: $ti-icon-clock-heart; }
.#{$ti-prefix}-clock-hour-1:before { content: $ti-icon-clock-hour-1; }
.#{$ti-prefix}-clock-hour-1-filled:before { content: $ti-icon-clock-hour-1-filled; }
.#{$ti-prefix}-clock-hour-10:before { content: $ti-icon-clock-hour-10; }
.#{$ti-prefix}-clock-hour-10-filled:before { content: $ti-icon-clock-hour-10-filled; }
.#{$ti-prefix}-clock-hour-11:before { content: $ti-icon-clock-hour-11; }
.#{$ti-prefix}-clock-hour-11-filled:before { content: $ti-icon-clock-hour-11-filled; }
.#{$ti-prefix}-clock-hour-12:before { content: $ti-icon-clock-hour-12; }
.#{$ti-prefix}-clock-hour-12-filled:before { content: $ti-icon-clock-hour-12-filled; }
.#{$ti-prefix}-clock-hour-2:before { content: $ti-icon-clock-hour-2; }
.#{$ti-prefix}-clock-hour-2-filled:before { content: $ti-icon-clock-hour-2-filled; }
.#{$ti-prefix}-clock-hour-3:before { content: $ti-icon-clock-hour-3; }
.#{$ti-prefix}-clock-hour-3-filled:before { content: $ti-icon-clock-hour-3-filled; }
.#{$ti-prefix}-clock-hour-4:before { content: $ti-icon-clock-hour-4; }
.#{$ti-prefix}-clock-hour-4-filled:before { content: $ti-icon-clock-hour-4-filled; }
.#{$ti-prefix}-clock-hour-5:before { content: $ti-icon-clock-hour-5; }
.#{$ti-prefix}-clock-hour-5-filled:before { content: $ti-icon-clock-hour-5-filled; }
.#{$ti-prefix}-clock-hour-6:before { content: $ti-icon-clock-hour-6; }
.#{$ti-prefix}-clock-hour-6-filled:before { content: $ti-icon-clock-hour-6-filled; }
.#{$ti-prefix}-clock-hour-7:before { content: $ti-icon-clock-hour-7; }
.#{$ti-prefix}-clock-hour-7-filled:before { content: $ti-icon-clock-hour-7-filled; }
.#{$ti-prefix}-clock-hour-8:before { content: $ti-icon-clock-hour-8; }
.#{$ti-prefix}-clock-hour-8-filled:before { content: $ti-icon-clock-hour-8-filled; }
.#{$ti-prefix}-clock-hour-9:before { content: $ti-icon-clock-hour-9; }
.#{$ti-prefix}-clock-hour-9-filled:before { content: $ti-icon-clock-hour-9-filled; }
.#{$ti-prefix}-clock-minus:before { content: $ti-icon-clock-minus; }
.#{$ti-prefix}-clock-off:before { content: $ti-icon-clock-off; }
.#{$ti-prefix}-clock-pause:before { content: $ti-icon-clock-pause; }
.#{$ti-prefix}-clock-pin:before { content: $ti-icon-clock-pin; }
.#{$ti-prefix}-clock-play:before { content: $ti-icon-clock-play; }
.#{$ti-prefix}-clock-plus:before { content: $ti-icon-clock-plus; }
.#{$ti-prefix}-clock-question:before { content: $ti-icon-clock-question; }
.#{$ti-prefix}-clock-record:before { content: $ti-icon-clock-record; }
.#{$ti-prefix}-clock-search:before { content: $ti-icon-clock-search; }
.#{$ti-prefix}-clock-share:before { content: $ti-icon-clock-share; }
.#{$ti-prefix}-clock-shield:before { content: $ti-icon-clock-shield; }
.#{$ti-prefix}-clock-star:before { content: $ti-icon-clock-star; }
.#{$ti-prefix}-clock-stop:before { content: $ti-icon-clock-stop; }
.#{$ti-prefix}-clock-up:before { content: $ti-icon-clock-up; }
.#{$ti-prefix}-clock-x:before { content: $ti-icon-clock-x; }
.#{$ti-prefix}-clothes-rack:before { content: $ti-icon-clothes-rack; }
.#{$ti-prefix}-clothes-rack-off:before { content: $ti-icon-clothes-rack-off; }
.#{$ti-prefix}-cloud:before { content: $ti-icon-cloud; }
.#{$ti-prefix}-cloud-bitcoin:before { content: $ti-icon-cloud-bitcoin; }
.#{$ti-prefix}-cloud-bolt:before { content: $ti-icon-cloud-bolt; }
.#{$ti-prefix}-cloud-cancel:before { content: $ti-icon-cloud-cancel; }
.#{$ti-prefix}-cloud-check:before { content: $ti-icon-cloud-check; }
.#{$ti-prefix}-cloud-code:before { content: $ti-icon-cloud-code; }
.#{$ti-prefix}-cloud-cog:before { content: $ti-icon-cloud-cog; }
.#{$ti-prefix}-cloud-computing:before { content: $ti-icon-cloud-computing; }
.#{$ti-prefix}-cloud-data-connection:before { content: $ti-icon-cloud-data-connection; }
.#{$ti-prefix}-cloud-dollar:before { content: $ti-icon-cloud-dollar; }
.#{$ti-prefix}-cloud-down:before { content: $ti-icon-cloud-down; }
.#{$ti-prefix}-cloud-download:before { content: $ti-icon-cloud-download; }
.#{$ti-prefix}-cloud-exclamation:before { content: $ti-icon-cloud-exclamation; }
.#{$ti-prefix}-cloud-filled:before { content: $ti-icon-cloud-filled; }
.#{$ti-prefix}-cloud-fog:before { content: $ti-icon-cloud-fog; }
.#{$ti-prefix}-cloud-heart:before { content: $ti-icon-cloud-heart; }
.#{$ti-prefix}-cloud-lock:before { content: $ti-icon-cloud-lock; }
.#{$ti-prefix}-cloud-lock-open:before { content: $ti-icon-cloud-lock-open; }
.#{$ti-prefix}-cloud-minus:before { content: $ti-icon-cloud-minus; }
.#{$ti-prefix}-cloud-network:before { content: $ti-icon-cloud-network; }
.#{$ti-prefix}-cloud-off:before { content: $ti-icon-cloud-off; }
.#{$ti-prefix}-cloud-pause:before { content: $ti-icon-cloud-pause; }
.#{$ti-prefix}-cloud-pin:before { content: $ti-icon-cloud-pin; }
.#{$ti-prefix}-cloud-plus:before { content: $ti-icon-cloud-plus; }
.#{$ti-prefix}-cloud-question:before { content: $ti-icon-cloud-question; }
.#{$ti-prefix}-cloud-rain:before { content: $ti-icon-cloud-rain; }
.#{$ti-prefix}-cloud-search:before { content: $ti-icon-cloud-search; }
.#{$ti-prefix}-cloud-share:before { content: $ti-icon-cloud-share; }
.#{$ti-prefix}-cloud-snow:before { content: $ti-icon-cloud-snow; }
.#{$ti-prefix}-cloud-star:before { content: $ti-icon-cloud-star; }
.#{$ti-prefix}-cloud-storm:before { content: $ti-icon-cloud-storm; }
.#{$ti-prefix}-cloud-up:before { content: $ti-icon-cloud-up; }
.#{$ti-prefix}-cloud-upload:before { content: $ti-icon-cloud-upload; }
.#{$ti-prefix}-cloud-x:before { content: $ti-icon-cloud-x; }
.#{$ti-prefix}-clover:before { content: $ti-icon-clover; }
.#{$ti-prefix}-clover-2:before { content: $ti-icon-clover-2; }
.#{$ti-prefix}-clover-filled:before { content: $ti-icon-clover-filled; }
.#{$ti-prefix}-clubs:before { content: $ti-icon-clubs; }
.#{$ti-prefix}-clubs-filled:before { content: $ti-icon-clubs-filled; }
.#{$ti-prefix}-code:before { content: $ti-icon-code; }
.#{$ti-prefix}-code-asterisk:before { content: $ti-icon-code-asterisk; }
.#{$ti-prefix}-code-circle:before { content: $ti-icon-code-circle; }
.#{$ti-prefix}-code-circle-2:before { content: $ti-icon-code-circle-2; }
.#{$ti-prefix}-code-circle-2-filled:before { content: $ti-icon-code-circle-2-filled; }
.#{$ti-prefix}-code-circle-filled:before { content: $ti-icon-code-circle-filled; }
.#{$ti-prefix}-code-dots:before { content: $ti-icon-code-dots; }
.#{$ti-prefix}-code-minus:before { content: $ti-icon-code-minus; }
.#{$ti-prefix}-code-off:before { content: $ti-icon-code-off; }
.#{$ti-prefix}-code-plus:before { content: $ti-icon-code-plus; }
.#{$ti-prefix}-coffee:before { content: $ti-icon-coffee; }
.#{$ti-prefix}-coffee-off:before { content: $ti-icon-coffee-off; }
.#{$ti-prefix}-coffin:before { content: $ti-icon-coffin; }
.#{$ti-prefix}-coin:before { content: $ti-icon-coin; }
.#{$ti-prefix}-coin-bitcoin:before { content: $ti-icon-coin-bitcoin; }
.#{$ti-prefix}-coin-bitcoin-filled:before { content: $ti-icon-coin-bitcoin-filled; }
.#{$ti-prefix}-coin-euro:before { content: $ti-icon-coin-euro; }
.#{$ti-prefix}-coin-euro-filled:before { content: $ti-icon-coin-euro-filled; }
.#{$ti-prefix}-coin-filled:before { content: $ti-icon-coin-filled; }
.#{$ti-prefix}-coin-monero:before { content: $ti-icon-coin-monero; }
.#{$ti-prefix}-coin-monero-filled:before { content: $ti-icon-coin-monero-filled; }
.#{$ti-prefix}-coin-off:before { content: $ti-icon-coin-off; }
.#{$ti-prefix}-coin-pound:before { content: $ti-icon-coin-pound; }
.#{$ti-prefix}-coin-pound-filled:before { content: $ti-icon-coin-pound-filled; }
.#{$ti-prefix}-coin-rupee:before { content: $ti-icon-coin-rupee; }
.#{$ti-prefix}-coin-rupee-filled:before { content: $ti-icon-coin-rupee-filled; }
.#{$ti-prefix}-coin-taka:before { content: $ti-icon-coin-taka; }
.#{$ti-prefix}-coin-taka-filled:before { content: $ti-icon-coin-taka-filled; }
.#{$ti-prefix}-coin-yen:before { content: $ti-icon-coin-yen; }
.#{$ti-prefix}-coin-yen-filled:before { content: $ti-icon-coin-yen-filled; }
.#{$ti-prefix}-coin-yuan:before { content: $ti-icon-coin-yuan; }
.#{$ti-prefix}-coin-yuan-filled:before { content: $ti-icon-coin-yuan-filled; }
.#{$ti-prefix}-coins:before { content: $ti-icon-coins; }
.#{$ti-prefix}-color-filter:before { content: $ti-icon-color-filter; }
.#{$ti-prefix}-color-picker:before { content: $ti-icon-color-picker; }
.#{$ti-prefix}-color-picker-off:before { content: $ti-icon-color-picker-off; }
.#{$ti-prefix}-color-swatch:before { content: $ti-icon-color-swatch; }
.#{$ti-prefix}-color-swatch-off:before { content: $ti-icon-color-swatch-off; }
.#{$ti-prefix}-column-insert-left:before { content: $ti-icon-column-insert-left; }
.#{$ti-prefix}-column-insert-right:before { content: $ti-icon-column-insert-right; }
.#{$ti-prefix}-column-remove:before { content: $ti-icon-column-remove; }
.#{$ti-prefix}-columns:before { content: $ti-icon-columns; }
.#{$ti-prefix}-columns-1:before { content: $ti-icon-columns-1; }
.#{$ti-prefix}-columns-2:before { content: $ti-icon-columns-2; }
.#{$ti-prefix}-columns-3:before { content: $ti-icon-columns-3; }
.#{$ti-prefix}-columns-off:before { content: $ti-icon-columns-off; }
.#{$ti-prefix}-comet:before { content: $ti-icon-comet; }
.#{$ti-prefix}-command:before { content: $ti-icon-command; }
.#{$ti-prefix}-command-off:before { content: $ti-icon-command-off; }
.#{$ti-prefix}-compass:before { content: $ti-icon-compass; }
.#{$ti-prefix}-compass-filled:before { content: $ti-icon-compass-filled; }
.#{$ti-prefix}-compass-off:before { content: $ti-icon-compass-off; }
.#{$ti-prefix}-components:before { content: $ti-icon-components; }
.#{$ti-prefix}-components-off:before { content: $ti-icon-components-off; }
.#{$ti-prefix}-cone:before { content: $ti-icon-cone; }
.#{$ti-prefix}-cone-2:before { content: $ti-icon-cone-2; }
.#{$ti-prefix}-cone-2-filled:before { content: $ti-icon-cone-2-filled; }
.#{$ti-prefix}-cone-filled:before { content: $ti-icon-cone-filled; }
.#{$ti-prefix}-cone-off:before { content: $ti-icon-cone-off; }
.#{$ti-prefix}-cone-plus:before { content: $ti-icon-cone-plus; }
.#{$ti-prefix}-confetti:before { content: $ti-icon-confetti; }
.#{$ti-prefix}-confetti-off:before { content: $ti-icon-confetti-off; }
.#{$ti-prefix}-confucius:before { content: $ti-icon-confucius; }
.#{$ti-prefix}-congruent-to:before { content: $ti-icon-congruent-to; }
.#{$ti-prefix}-container:before { content: $ti-icon-container; }
.#{$ti-prefix}-container-off:before { content: $ti-icon-container-off; }
.#{$ti-prefix}-contract:before { content: $ti-icon-contract; }
.#{$ti-prefix}-contrast:before { content: $ti-icon-contrast; }
.#{$ti-prefix}-contrast-2:before { content: $ti-icon-contrast-2; }
.#{$ti-prefix}-contrast-2-filled:before { content: $ti-icon-contrast-2-filled; }
.#{$ti-prefix}-contrast-2-off:before { content: $ti-icon-contrast-2-off; }
.#{$ti-prefix}-contrast-filled:before { content: $ti-icon-contrast-filled; }
.#{$ti-prefix}-contrast-off:before { content: $ti-icon-contrast-off; }
.#{$ti-prefix}-cooker:before { content: $ti-icon-cooker; }
.#{$ti-prefix}-cookie:before { content: $ti-icon-cookie; }
.#{$ti-prefix}-cookie-filled:before { content: $ti-icon-cookie-filled; }
.#{$ti-prefix}-cookie-man:before { content: $ti-icon-cookie-man; }
.#{$ti-prefix}-cookie-man-filled:before { content: $ti-icon-cookie-man-filled; }
.#{$ti-prefix}-cookie-off:before { content: $ti-icon-cookie-off; }
.#{$ti-prefix}-copy:before { content: $ti-icon-copy; }
.#{$ti-prefix}-copy-check:before { content: $ti-icon-copy-check; }
.#{$ti-prefix}-copy-check-filled:before { content: $ti-icon-copy-check-filled; }
.#{$ti-prefix}-copy-minus:before { content: $ti-icon-copy-minus; }
.#{$ti-prefix}-copy-minus-filled:before { content: $ti-icon-copy-minus-filled; }
.#{$ti-prefix}-copy-off:before { content: $ti-icon-copy-off; }
.#{$ti-prefix}-copy-plus:before { content: $ti-icon-copy-plus; }
.#{$ti-prefix}-copy-plus-filled:before { content: $ti-icon-copy-plus-filled; }
.#{$ti-prefix}-copy-x:before { content: $ti-icon-copy-x; }
.#{$ti-prefix}-copy-x-filled:before { content: $ti-icon-copy-x-filled; }
.#{$ti-prefix}-copyleft:before { content: $ti-icon-copyleft; }
.#{$ti-prefix}-copyleft-filled:before { content: $ti-icon-copyleft-filled; }
.#{$ti-prefix}-copyleft-off:before { content: $ti-icon-copyleft-off; }
.#{$ti-prefix}-copyright:before { content: $ti-icon-copyright; }
.#{$ti-prefix}-copyright-filled:before { content: $ti-icon-copyright-filled; }
.#{$ti-prefix}-copyright-off:before { content: $ti-icon-copyright-off; }
.#{$ti-prefix}-corner-down-left:before { content: $ti-icon-corner-down-left; }
.#{$ti-prefix}-corner-down-left-double:before { content: $ti-icon-corner-down-left-double; }
.#{$ti-prefix}-corner-down-right:before { content: $ti-icon-corner-down-right; }
.#{$ti-prefix}-corner-down-right-double:before { content: $ti-icon-corner-down-right-double; }
.#{$ti-prefix}-corner-left-down:before { content: $ti-icon-corner-left-down; }
.#{$ti-prefix}-corner-left-down-double:before { content: $ti-icon-corner-left-down-double; }
.#{$ti-prefix}-corner-left-up:before { content: $ti-icon-corner-left-up; }
.#{$ti-prefix}-corner-left-up-double:before { content: $ti-icon-corner-left-up-double; }
.#{$ti-prefix}-corner-right-down:before { content: $ti-icon-corner-right-down; }
.#{$ti-prefix}-corner-right-down-double:before { content: $ti-icon-corner-right-down-double; }
.#{$ti-prefix}-corner-right-up:before { content: $ti-icon-corner-right-up; }
.#{$ti-prefix}-corner-right-up-double:before { content: $ti-icon-corner-right-up-double; }
.#{$ti-prefix}-corner-up-left:before { content: $ti-icon-corner-up-left; }
.#{$ti-prefix}-corner-up-left-double:before { content: $ti-icon-corner-up-left-double; }
.#{$ti-prefix}-corner-up-right:before { content: $ti-icon-corner-up-right; }
.#{$ti-prefix}-corner-up-right-double:before { content: $ti-icon-corner-up-right-double; }
.#{$ti-prefix}-cpu:before { content: $ti-icon-cpu; }
.#{$ti-prefix}-cpu-2:before { content: $ti-icon-cpu-2; }
.#{$ti-prefix}-cpu-off:before { content: $ti-icon-cpu-off; }
.#{$ti-prefix}-crane:before { content: $ti-icon-crane; }
.#{$ti-prefix}-crane-off:before { content: $ti-icon-crane-off; }
.#{$ti-prefix}-creative-commons:before { content: $ti-icon-creative-commons; }
.#{$ti-prefix}-creative-commons-by:before { content: $ti-icon-creative-commons-by; }
.#{$ti-prefix}-creative-commons-nc:before { content: $ti-icon-creative-commons-nc; }
.#{$ti-prefix}-creative-commons-nd:before { content: $ti-icon-creative-commons-nd; }
.#{$ti-prefix}-creative-commons-off:before { content: $ti-icon-creative-commons-off; }
.#{$ti-prefix}-creative-commons-sa:before { content: $ti-icon-creative-commons-sa; }
.#{$ti-prefix}-creative-commons-zero:before { content: $ti-icon-creative-commons-zero; }
.#{$ti-prefix}-credit-card:before { content: $ti-icon-credit-card; }
.#{$ti-prefix}-credit-card-filled:before { content: $ti-icon-credit-card-filled; }
.#{$ti-prefix}-credit-card-off:before { content: $ti-icon-credit-card-off; }
.#{$ti-prefix}-credit-card-pay:before { content: $ti-icon-credit-card-pay; }
.#{$ti-prefix}-credit-card-refund:before { content: $ti-icon-credit-card-refund; }
.#{$ti-prefix}-cricket:before { content: $ti-icon-cricket; }
.#{$ti-prefix}-crop:before { content: $ti-icon-crop; }
.#{$ti-prefix}-crop-1-1:before { content: $ti-icon-crop-1-1; }
.#{$ti-prefix}-crop-1-1-filled:before { content: $ti-icon-crop-1-1-filled; }
.#{$ti-prefix}-crop-16-9:before { content: $ti-icon-crop-16-9; }
.#{$ti-prefix}-crop-16-9-filled:before { content: $ti-icon-crop-16-9-filled; }
.#{$ti-prefix}-crop-3-2:before { content: $ti-icon-crop-3-2; }
.#{$ti-prefix}-crop-3-2-filled:before { content: $ti-icon-crop-3-2-filled; }
.#{$ti-prefix}-crop-5-4:before { content: $ti-icon-crop-5-4; }
.#{$ti-prefix}-crop-5-4-filled:before { content: $ti-icon-crop-5-4-filled; }
.#{$ti-prefix}-crop-7-5:before { content: $ti-icon-crop-7-5; }
.#{$ti-prefix}-crop-7-5-filled:before { content: $ti-icon-crop-7-5-filled; }
.#{$ti-prefix}-crop-landscape:before { content: $ti-icon-crop-landscape; }
.#{$ti-prefix}-crop-landscape-filled:before { content: $ti-icon-crop-landscape-filled; }
.#{$ti-prefix}-crop-portrait:before { content: $ti-icon-crop-portrait; }
.#{$ti-prefix}-crop-portrait-filled:before { content: $ti-icon-crop-portrait-filled; }
.#{$ti-prefix}-cross:before { content: $ti-icon-cross; }
.#{$ti-prefix}-cross-filled:before { content: $ti-icon-cross-filled; }
.#{$ti-prefix}-cross-off:before { content: $ti-icon-cross-off; }
.#{$ti-prefix}-crosshair:before { content: $ti-icon-crosshair; }
.#{$ti-prefix}-crown:before { content: $ti-icon-crown; }
.#{$ti-prefix}-crown-off:before { content: $ti-icon-crown-off; }
.#{$ti-prefix}-crutches:before { content: $ti-icon-crutches; }
.#{$ti-prefix}-crutches-off:before { content: $ti-icon-crutches-off; }
.#{$ti-prefix}-crystal-ball:before { content: $ti-icon-crystal-ball; }
.#{$ti-prefix}-csv:before { content: $ti-icon-csv; }
.#{$ti-prefix}-cube:before { content: $ti-icon-cube; }
.#{$ti-prefix}-cube-3d-sphere:before { content: $ti-icon-cube-3d-sphere; }
.#{$ti-prefix}-cube-3d-sphere-off:before { content: $ti-icon-cube-3d-sphere-off; }
.#{$ti-prefix}-cube-off:before { content: $ti-icon-cube-off; }
.#{$ti-prefix}-cube-plus:before { content: $ti-icon-cube-plus; }
.#{$ti-prefix}-cube-send:before { content: $ti-icon-cube-send; }
.#{$ti-prefix}-cube-spark:before { content: $ti-icon-cube-spark; }
.#{$ti-prefix}-cube-unfolded:before { content: $ti-icon-cube-unfolded; }
.#{$ti-prefix}-cup:before { content: $ti-icon-cup; }
.#{$ti-prefix}-cup-off:before { content: $ti-icon-cup-off; }
.#{$ti-prefix}-curling:before { content: $ti-icon-curling; }
.#{$ti-prefix}-curly-loop:before { content: $ti-icon-curly-loop; }
.#{$ti-prefix}-currency:before { content: $ti-icon-currency; }
.#{$ti-prefix}-currency-afghani:before { content: $ti-icon-currency-afghani; }
.#{$ti-prefix}-currency-bahraini:before { content: $ti-icon-currency-bahraini; }
.#{$ti-prefix}-currency-baht:before { content: $ti-icon-currency-baht; }
.#{$ti-prefix}-currency-bitcoin:before { content: $ti-icon-currency-bitcoin; }
.#{$ti-prefix}-currency-cent:before { content: $ti-icon-currency-cent; }
.#{$ti-prefix}-currency-dinar:before { content: $ti-icon-currency-dinar; }
.#{$ti-prefix}-currency-dirham:before { content: $ti-icon-currency-dirham; }
.#{$ti-prefix}-currency-dogecoin:before { content: $ti-icon-currency-dogecoin; }
.#{$ti-prefix}-currency-dollar:before { content: $ti-icon-currency-dollar; }
.#{$ti-prefix}-currency-dollar-australian:before { content: $ti-icon-currency-dollar-australian; }
.#{$ti-prefix}-currency-dollar-brunei:before { content: $ti-icon-currency-dollar-brunei; }
.#{$ti-prefix}-currency-dollar-canadian:before { content: $ti-icon-currency-dollar-canadian; }
.#{$ti-prefix}-currency-dollar-guyanese:before { content: $ti-icon-currency-dollar-guyanese; }
.#{$ti-prefix}-currency-dollar-off:before { content: $ti-icon-currency-dollar-off; }
.#{$ti-prefix}-currency-dollar-singapore:before { content: $ti-icon-currency-dollar-singapore; }
.#{$ti-prefix}-currency-dollar-zimbabwean:before { content: $ti-icon-currency-dollar-zimbabwean; }
.#{$ti-prefix}-currency-dong:before { content: $ti-icon-currency-dong; }
.#{$ti-prefix}-currency-dram:before { content: $ti-icon-currency-dram; }
.#{$ti-prefix}-currency-ethereum:before { content: $ti-icon-currency-ethereum; }
.#{$ti-prefix}-currency-euro:before { content: $ti-icon-currency-euro; }
.#{$ti-prefix}-currency-euro-off:before { content: $ti-icon-currency-euro-off; }
.#{$ti-prefix}-currency-florin:before { content: $ti-icon-currency-florin; }
.#{$ti-prefix}-currency-forint:before { content: $ti-icon-currency-forint; }
.#{$ti-prefix}-currency-frank:before { content: $ti-icon-currency-frank; }
.#{$ti-prefix}-currency-guarani:before { content: $ti-icon-currency-guarani; }
.#{$ti-prefix}-currency-hryvnia:before { content: $ti-icon-currency-hryvnia; }
.#{$ti-prefix}-currency-iranian-rial:before { content: $ti-icon-currency-iranian-rial; }
.#{$ti-prefix}-currency-kip:before { content: $ti-icon-currency-kip; }
.#{$ti-prefix}-currency-krone-czech:before { content: $ti-icon-currency-krone-czech; }
.#{$ti-prefix}-currency-krone-danish:before { content: $ti-icon-currency-krone-danish; }
.#{$ti-prefix}-currency-krone-swedish:before { content: $ti-icon-currency-krone-swedish; }
.#{$ti-prefix}-currency-lari:before { content: $ti-icon-currency-lari; }
.#{$ti-prefix}-currency-leu:before { content: $ti-icon-currency-leu; }
.#{$ti-prefix}-currency-lira:before { content: $ti-icon-currency-lira; }
.#{$ti-prefix}-currency-litecoin:before { content: $ti-icon-currency-litecoin; }
.#{$ti-prefix}-currency-lyd:before { content: $ti-icon-currency-lyd; }
.#{$ti-prefix}-currency-manat:before { content: $ti-icon-currency-manat; }
.#{$ti-prefix}-currency-monero:before { content: $ti-icon-currency-monero; }
.#{$ti-prefix}-currency-naira:before { content: $ti-icon-currency-naira; }
.#{$ti-prefix}-currency-nano:before { content: $ti-icon-currency-nano; }
.#{$ti-prefix}-currency-off:before { content: $ti-icon-currency-off; }
.#{$ti-prefix}-currency-paanga:before { content: $ti-icon-currency-paanga; }
.#{$ti-prefix}-currency-peso:before { content: $ti-icon-currency-peso; }
.#{$ti-prefix}-currency-pound:before { content: $ti-icon-currency-pound; }
.#{$ti-prefix}-currency-pound-off:before { content: $ti-icon-currency-pound-off; }
.#{$ti-prefix}-currency-quetzal:before { content: $ti-icon-currency-quetzal; }
.#{$ti-prefix}-currency-real:before { content: $ti-icon-currency-real; }
.#{$ti-prefix}-currency-renminbi:before { content: $ti-icon-currency-renminbi; }
.#{$ti-prefix}-currency-ripple:before { content: $ti-icon-currency-ripple; }
.#{$ti-prefix}-currency-riyal:before { content: $ti-icon-currency-riyal; }
.#{$ti-prefix}-currency-rubel:before { content: $ti-icon-currency-rubel; }
.#{$ti-prefix}-currency-rufiyaa:before { content: $ti-icon-currency-rufiyaa; }
.#{$ti-prefix}-currency-rupee:before { content: $ti-icon-currency-rupee; }
.#{$ti-prefix}-currency-rupee-nepalese:before { content: $ti-icon-currency-rupee-nepalese; }
.#{$ti-prefix}-currency-shekel:before { content: $ti-icon-currency-shekel; }
.#{$ti-prefix}-currency-solana:before { content: $ti-icon-currency-solana; }
.#{$ti-prefix}-currency-som:before { content: $ti-icon-currency-som; }
.#{$ti-prefix}-currency-taka:before { content: $ti-icon-currency-taka; }
.#{$ti-prefix}-currency-tenge:before { content: $ti-icon-currency-tenge; }
.#{$ti-prefix}-currency-tugrik:before { content: $ti-icon-currency-tugrik; }
.#{$ti-prefix}-currency-won:before { content: $ti-icon-currency-won; }
.#{$ti-prefix}-currency-xrp:before { content: $ti-icon-currency-xrp; }
.#{$ti-prefix}-currency-yen:before { content: $ti-icon-currency-yen; }
.#{$ti-prefix}-currency-yen-off:before { content: $ti-icon-currency-yen-off; }
.#{$ti-prefix}-currency-yuan:before { content: $ti-icon-currency-yuan; }
.#{$ti-prefix}-currency-zloty:before { content: $ti-icon-currency-zloty; }
.#{$ti-prefix}-current-location:before { content: $ti-icon-current-location; }
.#{$ti-prefix}-current-location-off:before { content: $ti-icon-current-location-off; }
.#{$ti-prefix}-cursor-off:before { content: $ti-icon-cursor-off; }
.#{$ti-prefix}-cursor-text:before { content: $ti-icon-cursor-text; }
.#{$ti-prefix}-cut:before { content: $ti-icon-cut; }
.#{$ti-prefix}-cylinder:before { content: $ti-icon-cylinder; }
.#{$ti-prefix}-cylinder-off:before { content: $ti-icon-cylinder-off; }
.#{$ti-prefix}-cylinder-plus:before { content: $ti-icon-cylinder-plus; }
.#{$ti-prefix}-dashboard:before { content: $ti-icon-dashboard; }
.#{$ti-prefix}-dashboard-filled:before { content: $ti-icon-dashboard-filled; }
.#{$ti-prefix}-dashboard-off:before { content: $ti-icon-dashboard-off; }
.#{$ti-prefix}-database:before { content: $ti-icon-database; }
.#{$ti-prefix}-database-cog:before { content: $ti-icon-database-cog; }
.#{$ti-prefix}-database-dollar:before { content: $ti-icon-database-dollar; }
.#{$ti-prefix}-database-edit:before { content: $ti-icon-database-edit; }
.#{$ti-prefix}-database-exclamation:before { content: $ti-icon-database-exclamation; }
.#{$ti-prefix}-database-export:before { content: $ti-icon-database-export; }
.#{$ti-prefix}-database-heart:before { content: $ti-icon-database-heart; }
.#{$ti-prefix}-database-import:before { content: $ti-icon-database-import; }
.#{$ti-prefix}-database-leak:before { content: $ti-icon-database-leak; }
.#{$ti-prefix}-database-minus:before { content: $ti-icon-database-minus; }
.#{$ti-prefix}-database-off:before { content: $ti-icon-database-off; }
.#{$ti-prefix}-database-plus:before { content: $ti-icon-database-plus; }
.#{$ti-prefix}-database-search:before { content: $ti-icon-database-search; }
.#{$ti-prefix}-database-share:before { content: $ti-icon-database-share; }
.#{$ti-prefix}-database-smile:before { content: $ti-icon-database-smile; }
.#{$ti-prefix}-database-star:before { content: $ti-icon-database-star; }
.#{$ti-prefix}-database-x:before { content: $ti-icon-database-x; }
.#{$ti-prefix}-decimal:before { content: $ti-icon-decimal; }
.#{$ti-prefix}-deer:before { content: $ti-icon-deer; }
.#{$ti-prefix}-delta:before { content: $ti-icon-delta; }
.#{$ti-prefix}-dental:before { content: $ti-icon-dental; }
.#{$ti-prefix}-dental-broken:before { content: $ti-icon-dental-broken; }
.#{$ti-prefix}-dental-off:before { content: $ti-icon-dental-off; }
.#{$ti-prefix}-deselect:before { content: $ti-icon-deselect; }
.#{$ti-prefix}-desk:before { content: $ti-icon-desk; }
.#{$ti-prefix}-details:before { content: $ti-icon-details; }
.#{$ti-prefix}-details-off:before { content: $ti-icon-details-off; }
.#{$ti-prefix}-device-airpods:before { content: $ti-icon-device-airpods; }
.#{$ti-prefix}-device-airpods-case:before { content: $ti-icon-device-airpods-case; }
.#{$ti-prefix}-device-airtag:before { content: $ti-icon-device-airtag; }
.#{$ti-prefix}-device-analytics:before { content: $ti-icon-device-analytics; }
.#{$ti-prefix}-device-audio-tape:before { content: $ti-icon-device-audio-tape; }
.#{$ti-prefix}-device-camera-phone:before { content: $ti-icon-device-camera-phone; }
.#{$ti-prefix}-device-cctv:before { content: $ti-icon-device-cctv; }
.#{$ti-prefix}-device-cctv-filled:before { content: $ti-icon-device-cctv-filled; }
.#{$ti-prefix}-device-cctv-off:before { content: $ti-icon-device-cctv-off; }
.#{$ti-prefix}-device-computer-camera:before { content: $ti-icon-device-computer-camera; }
.#{$ti-prefix}-device-computer-camera-off:before { content: $ti-icon-device-computer-camera-off; }
.#{$ti-prefix}-device-desktop:before { content: $ti-icon-device-desktop; }
.#{$ti-prefix}-device-desktop-analytics:before { content: $ti-icon-device-desktop-analytics; }
.#{$ti-prefix}-device-desktop-bolt:before { content: $ti-icon-device-desktop-bolt; }
.#{$ti-prefix}-device-desktop-cancel:before { content: $ti-icon-device-desktop-cancel; }
.#{$ti-prefix}-device-desktop-check:before { content: $ti-icon-device-desktop-check; }
.#{$ti-prefix}-device-desktop-code:before { content: $ti-icon-device-desktop-code; }
.#{$ti-prefix}-device-desktop-cog:before { content: $ti-icon-device-desktop-cog; }
.#{$ti-prefix}-device-desktop-dollar:before { content: $ti-icon-device-desktop-dollar; }
.#{$ti-prefix}-device-desktop-down:before { content: $ti-icon-device-desktop-down; }
.#{$ti-prefix}-device-desktop-exclamation:before { content: $ti-icon-device-desktop-exclamation; }
.#{$ti-prefix}-device-desktop-filled:before { content: $ti-icon-device-desktop-filled; }
.#{$ti-prefix}-device-desktop-heart:before { content: $ti-icon-device-desktop-heart; }
.#{$ti-prefix}-device-desktop-minus:before { content: $ti-icon-device-desktop-minus; }
.#{$ti-prefix}-device-desktop-off:before { content: $ti-icon-device-desktop-off; }
.#{$ti-prefix}-device-desktop-pause:before { content: $ti-icon-device-desktop-pause; }
.#{$ti-prefix}-device-desktop-pin:before { content: $ti-icon-device-desktop-pin; }
.#{$ti-prefix}-device-desktop-plus:before { content: $ti-icon-device-desktop-plus; }
.#{$ti-prefix}-device-desktop-question:before { content: $ti-icon-device-desktop-question; }
.#{$ti-prefix}-device-desktop-search:before { content: $ti-icon-device-desktop-search; }
.#{$ti-prefix}-device-desktop-share:before { content: $ti-icon-device-desktop-share; }
.#{$ti-prefix}-device-desktop-star:before { content: $ti-icon-device-desktop-star; }
.#{$ti-prefix}-device-desktop-up:before { content: $ti-icon-device-desktop-up; }
.#{$ti-prefix}-device-desktop-x:before { content: $ti-icon-device-desktop-x; }
.#{$ti-prefix}-device-floppy:before { content: $ti-icon-device-floppy; }
.#{$ti-prefix}-device-gamepad:before { content: $ti-icon-device-gamepad; }
.#{$ti-prefix}-device-gamepad-2:before { content: $ti-icon-device-gamepad-2; }
.#{$ti-prefix}-device-gamepad-3:before { content: $ti-icon-device-gamepad-3; }
.#{$ti-prefix}-device-gamepad-3-filled:before { content: $ti-icon-device-gamepad-3-filled; }
.#{$ti-prefix}-device-heart-monitor:before { content: $ti-icon-device-heart-monitor; }
.#{$ti-prefix}-device-heart-monitor-filled:before { content: $ti-icon-device-heart-monitor-filled; }
.#{$ti-prefix}-device-imac:before { content: $ti-icon-device-imac; }
.#{$ti-prefix}-device-imac-bolt:before { content: $ti-icon-device-imac-bolt; }
.#{$ti-prefix}-device-imac-cancel:before { content: $ti-icon-device-imac-cancel; }
.#{$ti-prefix}-device-imac-check:before { content: $ti-icon-device-imac-check; }
.#{$ti-prefix}-device-imac-code:before { content: $ti-icon-device-imac-code; }
.#{$ti-prefix}-device-imac-cog:before { content: $ti-icon-device-imac-cog; }
.#{$ti-prefix}-device-imac-dollar:before { content: $ti-icon-device-imac-dollar; }
.#{$ti-prefix}-device-imac-down:before { content: $ti-icon-device-imac-down; }
.#{$ti-prefix}-device-imac-exclamation:before { content: $ti-icon-device-imac-exclamation; }
.#{$ti-prefix}-device-imac-filled:before { content: $ti-icon-device-imac-filled; }
.#{$ti-prefix}-device-imac-heart:before { content: $ti-icon-device-imac-heart; }
.#{$ti-prefix}-device-imac-minus:before { content: $ti-icon-device-imac-minus; }
.#{$ti-prefix}-device-imac-off:before { content: $ti-icon-device-imac-off; }
.#{$ti-prefix}-device-imac-pause:before { content: $ti-icon-device-imac-pause; }
.#{$ti-prefix}-device-imac-pin:before { content: $ti-icon-device-imac-pin; }
.#{$ti-prefix}-device-imac-plus:before { content: $ti-icon-device-imac-plus; }
.#{$ti-prefix}-device-imac-question:before { content: $ti-icon-device-imac-question; }
.#{$ti-prefix}-device-imac-search:before { content: $ti-icon-device-imac-search; }
.#{$ti-prefix}-device-imac-share:before { content: $ti-icon-device-imac-share; }
.#{$ti-prefix}-device-imac-star:before { content: $ti-icon-device-imac-star; }
.#{$ti-prefix}-device-imac-up:before { content: $ti-icon-device-imac-up; }
.#{$ti-prefix}-device-imac-x:before { content: $ti-icon-device-imac-x; }
.#{$ti-prefix}-device-ipad:before { content: $ti-icon-device-ipad; }
.#{$ti-prefix}-device-ipad-bolt:before { content: $ti-icon-device-ipad-bolt; }
.#{$ti-prefix}-device-ipad-cancel:before { content: $ti-icon-device-ipad-cancel; }
.#{$ti-prefix}-device-ipad-check:before { content: $ti-icon-device-ipad-check; }
.#{$ti-prefix}-device-ipad-code:before { content: $ti-icon-device-ipad-code; }
.#{$ti-prefix}-device-ipad-cog:before { content: $ti-icon-device-ipad-cog; }
.#{$ti-prefix}-device-ipad-dollar:before { content: $ti-icon-device-ipad-dollar; }
.#{$ti-prefix}-device-ipad-down:before { content: $ti-icon-device-ipad-down; }
.#{$ti-prefix}-device-ipad-exclamation:before { content: $ti-icon-device-ipad-exclamation; }
.#{$ti-prefix}-device-ipad-filled:before { content: $ti-icon-device-ipad-filled; }
.#{$ti-prefix}-device-ipad-heart:before { content: $ti-icon-device-ipad-heart; }
.#{$ti-prefix}-device-ipad-horizontal:before { content: $ti-icon-device-ipad-horizontal; }
.#{$ti-prefix}-device-ipad-horizontal-bolt:before { content: $ti-icon-device-ipad-horizontal-bolt; }
.#{$ti-prefix}-device-ipad-horizontal-cancel:before { content: $ti-icon-device-ipad-horizontal-cancel; }
.#{$ti-prefix}-device-ipad-horizontal-check:before { content: $ti-icon-device-ipad-horizontal-check; }
.#{$ti-prefix}-device-ipad-horizontal-code:before { content: $ti-icon-device-ipad-horizontal-code; }
.#{$ti-prefix}-device-ipad-horizontal-cog:before { content: $ti-icon-device-ipad-horizontal-cog; }
.#{$ti-prefix}-device-ipad-horizontal-dollar:before { content: $ti-icon-device-ipad-horizontal-dollar; }
.#{$ti-prefix}-device-ipad-horizontal-down:before { content: $ti-icon-device-ipad-horizontal-down; }
.#{$ti-prefix}-device-ipad-horizontal-exclamation:before { content: $ti-icon-device-ipad-horizontal-exclamation; }
.#{$ti-prefix}-device-ipad-horizontal-heart:before { content: $ti-icon-device-ipad-horizontal-heart; }
.#{$ti-prefix}-device-ipad-horizontal-minus:before { content: $ti-icon-device-ipad-horizontal-minus; }
.#{$ti-prefix}-device-ipad-horizontal-off:before { content: $ti-icon-device-ipad-horizontal-off; }
.#{$ti-prefix}-device-ipad-horizontal-pause:before { content: $ti-icon-device-ipad-horizontal-pause; }
.#{$ti-prefix}-device-ipad-horizontal-pin:before { content: $ti-icon-device-ipad-horizontal-pin; }
.#{$ti-prefix}-device-ipad-horizontal-plus:before { content: $ti-icon-device-ipad-horizontal-plus; }
.#{$ti-prefix}-device-ipad-horizontal-question:before { content: $ti-icon-device-ipad-horizontal-question; }
.#{$ti-prefix}-device-ipad-horizontal-search:before { content: $ti-icon-device-ipad-horizontal-search; }
.#{$ti-prefix}-device-ipad-horizontal-share:before { content: $ti-icon-device-ipad-horizontal-share; }
.#{$ti-prefix}-device-ipad-horizontal-star:before { content: $ti-icon-device-ipad-horizontal-star; }
.#{$ti-prefix}-device-ipad-horizontal-up:before { content: $ti-icon-device-ipad-horizontal-up; }
.#{$ti-prefix}-device-ipad-horizontal-x:before { content: $ti-icon-device-ipad-horizontal-x; }
.#{$ti-prefix}-device-ipad-minus:before { content: $ti-icon-device-ipad-minus; }
.#{$ti-prefix}-device-ipad-off:before { content: $ti-icon-device-ipad-off; }
.#{$ti-prefix}-device-ipad-pause:before { content: $ti-icon-device-ipad-pause; }
.#{$ti-prefix}-device-ipad-pin:before { content: $ti-icon-device-ipad-pin; }
.#{$ti-prefix}-device-ipad-plus:before { content: $ti-icon-device-ipad-plus; }
.#{$ti-prefix}-device-ipad-question:before { content: $ti-icon-device-ipad-question; }
.#{$ti-prefix}-device-ipad-search:before { content: $ti-icon-device-ipad-search; }
.#{$ti-prefix}-device-ipad-share:before { content: $ti-icon-device-ipad-share; }
.#{$ti-prefix}-device-ipad-star:before { content: $ti-icon-device-ipad-star; }
.#{$ti-prefix}-device-ipad-up:before { content: $ti-icon-device-ipad-up; }
.#{$ti-prefix}-device-ipad-x:before { content: $ti-icon-device-ipad-x; }
.#{$ti-prefix}-device-landline-phone:before { content: $ti-icon-device-landline-phone; }
.#{$ti-prefix}-device-laptop:before { content: $ti-icon-device-laptop; }
.#{$ti-prefix}-device-laptop-off:before { content: $ti-icon-device-laptop-off; }
.#{$ti-prefix}-device-mobile:before { content: $ti-icon-device-mobile; }
.#{$ti-prefix}-device-mobile-bolt:before { content: $ti-icon-device-mobile-bolt; }
.#{$ti-prefix}-device-mobile-cancel:before { content: $ti-icon-device-mobile-cancel; }
.#{$ti-prefix}-device-mobile-charging:before { content: $ti-icon-device-mobile-charging; }
.#{$ti-prefix}-device-mobile-check:before { content: $ti-icon-device-mobile-check; }
.#{$ti-prefix}-device-mobile-code:before { content: $ti-icon-device-mobile-code; }
.#{$ti-prefix}-device-mobile-cog:before { content: $ti-icon-device-mobile-cog; }
.#{$ti-prefix}-device-mobile-dollar:before { content: $ti-icon-device-mobile-dollar; }
.#{$ti-prefix}-device-mobile-down:before { content: $ti-icon-device-mobile-down; }
.#{$ti-prefix}-device-mobile-exclamation:before { content: $ti-icon-device-mobile-exclamation; }
.#{$ti-prefix}-device-mobile-filled:before { content: $ti-icon-device-mobile-filled; }
.#{$ti-prefix}-device-mobile-heart:before { content: $ti-icon-device-mobile-heart; }
.#{$ti-prefix}-device-mobile-message:before { content: $ti-icon-device-mobile-message; }
.#{$ti-prefix}-device-mobile-minus:before { content: $ti-icon-device-mobile-minus; }
.#{$ti-prefix}-device-mobile-off:before { content: $ti-icon-device-mobile-off; }
.#{$ti-prefix}-device-mobile-pause:before { content: $ti-icon-device-mobile-pause; }
.#{$ti-prefix}-device-mobile-pin:before { content: $ti-icon-device-mobile-pin; }
.#{$ti-prefix}-device-mobile-plus:before { content: $ti-icon-device-mobile-plus; }
.#{$ti-prefix}-device-mobile-question:before { content: $ti-icon-device-mobile-question; }
.#{$ti-prefix}-device-mobile-rotated:before { content: $ti-icon-device-mobile-rotated; }
.#{$ti-prefix}-device-mobile-search:before { content: $ti-icon-device-mobile-search; }
.#{$ti-prefix}-device-mobile-share:before { content: $ti-icon-device-mobile-share; }
.#{$ti-prefix}-device-mobile-star:before { content: $ti-icon-device-mobile-star; }
.#{$ti-prefix}-device-mobile-up:before { content: $ti-icon-device-mobile-up; }
.#{$ti-prefix}-device-mobile-vibration:before { content: $ti-icon-device-mobile-vibration; }
.#{$ti-prefix}-device-mobile-x:before { content: $ti-icon-device-mobile-x; }
.#{$ti-prefix}-device-nintendo:before { content: $ti-icon-device-nintendo; }
.#{$ti-prefix}-device-nintendo-off:before { content: $ti-icon-device-nintendo-off; }
.#{$ti-prefix}-device-projector:before { content: $ti-icon-device-projector; }
.#{$ti-prefix}-device-remote:before { content: $ti-icon-device-remote; }
.#{$ti-prefix}-device-remote-filled:before { content: $ti-icon-device-remote-filled; }
.#{$ti-prefix}-device-sd-card:before { content: $ti-icon-device-sd-card; }
.#{$ti-prefix}-device-sim:before { content: $ti-icon-device-sim; }
.#{$ti-prefix}-device-sim-1:before { content: $ti-icon-device-sim-1; }
.#{$ti-prefix}-device-sim-2:before { content: $ti-icon-device-sim-2; }
.#{$ti-prefix}-device-sim-3:before { content: $ti-icon-device-sim-3; }
.#{$ti-prefix}-device-speaker:before { content: $ti-icon-device-speaker; }
.#{$ti-prefix}-device-speaker-filled:before { content: $ti-icon-device-speaker-filled; }
.#{$ti-prefix}-device-speaker-off:before { content: $ti-icon-device-speaker-off; }
.#{$ti-prefix}-device-tablet:before { content: $ti-icon-device-tablet; }
.#{$ti-prefix}-device-tablet-bolt:before { content: $ti-icon-device-tablet-bolt; }
.#{$ti-prefix}-device-tablet-cancel:before { content: $ti-icon-device-tablet-cancel; }
.#{$ti-prefix}-device-tablet-check:before { content: $ti-icon-device-tablet-check; }
.#{$ti-prefix}-device-tablet-code:before { content: $ti-icon-device-tablet-code; }
.#{$ti-prefix}-device-tablet-cog:before { content: $ti-icon-device-tablet-cog; }
.#{$ti-prefix}-device-tablet-dollar:before { content: $ti-icon-device-tablet-dollar; }
.#{$ti-prefix}-device-tablet-down:before { content: $ti-icon-device-tablet-down; }
.#{$ti-prefix}-device-tablet-exclamation:before { content: $ti-icon-device-tablet-exclamation; }
.#{$ti-prefix}-device-tablet-filled:before { content: $ti-icon-device-tablet-filled; }
.#{$ti-prefix}-device-tablet-heart:before { content: $ti-icon-device-tablet-heart; }
.#{$ti-prefix}-device-tablet-minus:before { content: $ti-icon-device-tablet-minus; }
.#{$ti-prefix}-device-tablet-off:before { content: $ti-icon-device-tablet-off; }
.#{$ti-prefix}-device-tablet-pause:before { content: $ti-icon-device-tablet-pause; }
.#{$ti-prefix}-device-tablet-pin:before { content: $ti-icon-device-tablet-pin; }
.#{$ti-prefix}-device-tablet-plus:before { content: $ti-icon-device-tablet-plus; }
.#{$ti-prefix}-device-tablet-question:before { content: $ti-icon-device-tablet-question; }
.#{$ti-prefix}-device-tablet-search:before { content: $ti-icon-device-tablet-search; }
.#{$ti-prefix}-device-tablet-share:before { content: $ti-icon-device-tablet-share; }
.#{$ti-prefix}-device-tablet-star:before { content: $ti-icon-device-tablet-star; }
.#{$ti-prefix}-device-tablet-up:before { content: $ti-icon-device-tablet-up; }
.#{$ti-prefix}-device-tablet-x:before { content: $ti-icon-device-tablet-x; }
.#{$ti-prefix}-device-tv:before { content: $ti-icon-device-tv; }
.#{$ti-prefix}-device-tv-filled:before { content: $ti-icon-device-tv-filled; }
.#{$ti-prefix}-device-tv-off:before { content: $ti-icon-device-tv-off; }
.#{$ti-prefix}-device-tv-old:before { content: $ti-icon-device-tv-old; }
.#{$ti-prefix}-device-tv-old-filled:before { content: $ti-icon-device-tv-old-filled; }
.#{$ti-prefix}-device-unknown:before { content: $ti-icon-device-unknown; }
.#{$ti-prefix}-device-unknown-filled:before { content: $ti-icon-device-unknown-filled; }
.#{$ti-prefix}-device-usb:before { content: $ti-icon-device-usb; }
.#{$ti-prefix}-device-usb-filled:before { content: $ti-icon-device-usb-filled; }
.#{$ti-prefix}-device-vision-pro:before { content: $ti-icon-device-vision-pro; }
.#{$ti-prefix}-device-vision-pro-filled:before { content: $ti-icon-device-vision-pro-filled; }
.#{$ti-prefix}-device-watch:before { content: $ti-icon-device-watch; }
.#{$ti-prefix}-device-watch-bolt:before { content: $ti-icon-device-watch-bolt; }
.#{$ti-prefix}-device-watch-cancel:before { content: $ti-icon-device-watch-cancel; }
.#{$ti-prefix}-device-watch-check:before { content: $ti-icon-device-watch-check; }
.#{$ti-prefix}-device-watch-code:before { content: $ti-icon-device-watch-code; }
.#{$ti-prefix}-device-watch-cog:before { content: $ti-icon-device-watch-cog; }
.#{$ti-prefix}-device-watch-dollar:before { content: $ti-icon-device-watch-dollar; }
.#{$ti-prefix}-device-watch-down:before { content: $ti-icon-device-watch-down; }
.#{$ti-prefix}-device-watch-exclamation:before { content: $ti-icon-device-watch-exclamation; }
.#{$ti-prefix}-device-watch-filled:before { content: $ti-icon-device-watch-filled; }
.#{$ti-prefix}-device-watch-heart:before { content: $ti-icon-device-watch-heart; }
.#{$ti-prefix}-device-watch-minus:before { content: $ti-icon-device-watch-minus; }
.#{$ti-prefix}-device-watch-off:before { content: $ti-icon-device-watch-off; }
.#{$ti-prefix}-device-watch-pause:before { content: $ti-icon-device-watch-pause; }
.#{$ti-prefix}-device-watch-pin:before { content: $ti-icon-device-watch-pin; }
.#{$ti-prefix}-device-watch-plus:before { content: $ti-icon-device-watch-plus; }
.#{$ti-prefix}-device-watch-question:before { content: $ti-icon-device-watch-question; }
.#{$ti-prefix}-device-watch-search:before { content: $ti-icon-device-watch-search; }
.#{$ti-prefix}-device-watch-share:before { content: $ti-icon-device-watch-share; }
.#{$ti-prefix}-device-watch-star:before { content: $ti-icon-device-watch-star; }
.#{$ti-prefix}-device-watch-stats:before { content: $ti-icon-device-watch-stats; }
.#{$ti-prefix}-device-watch-stats-2:before { content: $ti-icon-device-watch-stats-2; }
.#{$ti-prefix}-device-watch-up:before { content: $ti-icon-device-watch-up; }
.#{$ti-prefix}-device-watch-x:before { content: $ti-icon-device-watch-x; }
.#{$ti-prefix}-devices:before { content: $ti-icon-devices; }
.#{$ti-prefix}-devices-2:before { content: $ti-icon-devices-2; }
.#{$ti-prefix}-devices-bolt:before { content: $ti-icon-devices-bolt; }
.#{$ti-prefix}-devices-cancel:before { content: $ti-icon-devices-cancel; }
.#{$ti-prefix}-devices-check:before { content: $ti-icon-devices-check; }
.#{$ti-prefix}-devices-code:before { content: $ti-icon-devices-code; }
.#{$ti-prefix}-devices-cog:before { content: $ti-icon-devices-cog; }
.#{$ti-prefix}-devices-dollar:before { content: $ti-icon-devices-dollar; }
.#{$ti-prefix}-devices-down:before { content: $ti-icon-devices-down; }
.#{$ti-prefix}-devices-exclamation:before { content: $ti-icon-devices-exclamation; }
.#{$ti-prefix}-devices-heart:before { content: $ti-icon-devices-heart; }
.#{$ti-prefix}-devices-minus:before { content: $ti-icon-devices-minus; }
.#{$ti-prefix}-devices-off:before { content: $ti-icon-devices-off; }
.#{$ti-prefix}-devices-pause:before { content: $ti-icon-devices-pause; }
.#{$ti-prefix}-devices-pc:before { content: $ti-icon-devices-pc; }
.#{$ti-prefix}-devices-pc-off:before { content: $ti-icon-devices-pc-off; }
.#{$ti-prefix}-devices-pin:before { content: $ti-icon-devices-pin; }
.#{$ti-prefix}-devices-plus:before { content: $ti-icon-devices-plus; }
.#{$ti-prefix}-devices-question:before { content: $ti-icon-devices-question; }
.#{$ti-prefix}-devices-search:before { content: $ti-icon-devices-search; }
.#{$ti-prefix}-devices-share:before { content: $ti-icon-devices-share; }
.#{$ti-prefix}-devices-star:before { content: $ti-icon-devices-star; }
.#{$ti-prefix}-devices-up:before { content: $ti-icon-devices-up; }
.#{$ti-prefix}-devices-x:before { content: $ti-icon-devices-x; }
.#{$ti-prefix}-diabolo:before { content: $ti-icon-diabolo; }
.#{$ti-prefix}-diabolo-off:before { content: $ti-icon-diabolo-off; }
.#{$ti-prefix}-diabolo-plus:before { content: $ti-icon-diabolo-plus; }
.#{$ti-prefix}-dialpad:before { content: $ti-icon-dialpad; }
.#{$ti-prefix}-dialpad-filled:before { content: $ti-icon-dialpad-filled; }
.#{$ti-prefix}-dialpad-off:before { content: $ti-icon-dialpad-off; }
.#{$ti-prefix}-diamond:before { content: $ti-icon-diamond; }
.#{$ti-prefix}-diamond-filled:before { content: $ti-icon-diamond-filled; }
.#{$ti-prefix}-diamond-off:before { content: $ti-icon-diamond-off; }
.#{$ti-prefix}-diamonds:before { content: $ti-icon-diamonds; }
.#{$ti-prefix}-diamonds-filled:before { content: $ti-icon-diamonds-filled; }
.#{$ti-prefix}-diaper:before { content: $ti-icon-diaper; }
.#{$ti-prefix}-dice:before { content: $ti-icon-dice; }
.#{$ti-prefix}-dice-1:before { content: $ti-icon-dice-1; }
.#{$ti-prefix}-dice-1-filled:before { content: $ti-icon-dice-1-filled; }
.#{$ti-prefix}-dice-2:before { content: $ti-icon-dice-2; }
.#{$ti-prefix}-dice-2-filled:before { content: $ti-icon-dice-2-filled; }
.#{$ti-prefix}-dice-3:before { content: $ti-icon-dice-3; }
.#{$ti-prefix}-dice-3-filled:before { content: $ti-icon-dice-3-filled; }
.#{$ti-prefix}-dice-4:before { content: $ti-icon-dice-4; }
.#{$ti-prefix}-dice-4-filled:before { content: $ti-icon-dice-4-filled; }
.#{$ti-prefix}-dice-5:before { content: $ti-icon-dice-5; }
.#{$ti-prefix}-dice-5-filled:before { content: $ti-icon-dice-5-filled; }
.#{$ti-prefix}-dice-6:before { content: $ti-icon-dice-6; }
.#{$ti-prefix}-dice-6-filled:before { content: $ti-icon-dice-6-filled; }
.#{$ti-prefix}-dice-filled:before { content: $ti-icon-dice-filled; }
.#{$ti-prefix}-dimensions:before { content: $ti-icon-dimensions; }
.#{$ti-prefix}-direction:before { content: $ti-icon-direction; }
.#{$ti-prefix}-direction-arrows:before { content: $ti-icon-direction-arrows; }
.#{$ti-prefix}-direction-horizontal:before { content: $ti-icon-direction-horizontal; }
.#{$ti-prefix}-direction-sign:before { content: $ti-icon-direction-sign; }
.#{$ti-prefix}-direction-sign-filled:before { content: $ti-icon-direction-sign-filled; }
.#{$ti-prefix}-direction-sign-off:before { content: $ti-icon-direction-sign-off; }
.#{$ti-prefix}-directions:before { content: $ti-icon-directions; }
.#{$ti-prefix}-directions-filled:before { content: $ti-icon-directions-filled; }
.#{$ti-prefix}-directions-off:before { content: $ti-icon-directions-off; }
.#{$ti-prefix}-disabled:before { content: $ti-icon-disabled; }
.#{$ti-prefix}-disabled-2:before { content: $ti-icon-disabled-2; }
.#{$ti-prefix}-disabled-off:before { content: $ti-icon-disabled-off; }
.#{$ti-prefix}-disc:before { content: $ti-icon-disc; }
.#{$ti-prefix}-disc-filled:before { content: $ti-icon-disc-filled; }
.#{$ti-prefix}-disc-golf:before { content: $ti-icon-disc-golf; }
.#{$ti-prefix}-disc-off:before { content: $ti-icon-disc-off; }
.#{$ti-prefix}-discount:before { content: $ti-icon-discount; }
.#{$ti-prefix}-discount-filled:before { content: $ti-icon-discount-filled; }
.#{$ti-prefix}-discount-off:before { content: $ti-icon-discount-off; }
.#{$ti-prefix}-divide:before { content: $ti-icon-divide; }
.#{$ti-prefix}-dna:before { content: $ti-icon-dna; }
.#{$ti-prefix}-dna-2:before { content: $ti-icon-dna-2; }
.#{$ti-prefix}-dna-2-off:before { content: $ti-icon-dna-2-off; }
.#{$ti-prefix}-dna-off:before { content: $ti-icon-dna-off; }
.#{$ti-prefix}-dog:before { content: $ti-icon-dog; }
.#{$ti-prefix}-dog-bowl:before { content: $ti-icon-dog-bowl; }
.#{$ti-prefix}-door:before { content: $ti-icon-door; }
.#{$ti-prefix}-door-enter:before { content: $ti-icon-door-enter; }
.#{$ti-prefix}-door-exit:before { content: $ti-icon-door-exit; }
.#{$ti-prefix}-door-off:before { content: $ti-icon-door-off; }
.#{$ti-prefix}-dots:before { content: $ti-icon-dots; }
.#{$ti-prefix}-dots-circle-horizontal:before { content: $ti-icon-dots-circle-horizontal; }
.#{$ti-prefix}-dots-diagonal:before { content: $ti-icon-dots-diagonal; }
.#{$ti-prefix}-dots-diagonal-2:before { content: $ti-icon-dots-diagonal-2; }
.#{$ti-prefix}-dots-vertical:before { content: $ti-icon-dots-vertical; }
.#{$ti-prefix}-download:before { content: $ti-icon-download; }
.#{$ti-prefix}-download-off:before { content: $ti-icon-download-off; }
.#{$ti-prefix}-drag-drop:before { content: $ti-icon-drag-drop; }
.#{$ti-prefix}-drag-drop-2:before { content: $ti-icon-drag-drop-2; }
.#{$ti-prefix}-drone:before { content: $ti-icon-drone; }
.#{$ti-prefix}-drone-off:before { content: $ti-icon-drone-off; }
.#{$ti-prefix}-drop-circle:before { content: $ti-icon-drop-circle; }
.#{$ti-prefix}-droplet:before { content: $ti-icon-droplet; }
.#{$ti-prefix}-droplet-bolt:before { content: $ti-icon-droplet-bolt; }
.#{$ti-prefix}-droplet-cancel:before { content: $ti-icon-droplet-cancel; }
.#{$ti-prefix}-droplet-check:before { content: $ti-icon-droplet-check; }
.#{$ti-prefix}-droplet-code:before { content: $ti-icon-droplet-code; }
.#{$ti-prefix}-droplet-cog:before { content: $ti-icon-droplet-cog; }
.#{$ti-prefix}-droplet-dollar:before { content: $ti-icon-droplet-dollar; }
.#{$ti-prefix}-droplet-down:before { content: $ti-icon-droplet-down; }
.#{$ti-prefix}-droplet-exclamation:before { content: $ti-icon-droplet-exclamation; }
.#{$ti-prefix}-droplet-filled:before { content: $ti-icon-droplet-filled; }
.#{$ti-prefix}-droplet-half:before { content: $ti-icon-droplet-half; }
.#{$ti-prefix}-droplet-half-2:before { content: $ti-icon-droplet-half-2; }
.#{$ti-prefix}-droplet-half-2-filled:before { content: $ti-icon-droplet-half-2-filled; }
.#{$ti-prefix}-droplet-half-filled:before { content: $ti-icon-droplet-half-filled; }
.#{$ti-prefix}-droplet-heart:before { content: $ti-icon-droplet-heart; }
.#{$ti-prefix}-droplet-minus:before { content: $ti-icon-droplet-minus; }
.#{$ti-prefix}-droplet-off:before { content: $ti-icon-droplet-off; }
.#{$ti-prefix}-droplet-pause:before { content: $ti-icon-droplet-pause; }
.#{$ti-prefix}-droplet-pin:before { content: $ti-icon-droplet-pin; }
.#{$ti-prefix}-droplet-plus:before { content: $ti-icon-droplet-plus; }
.#{$ti-prefix}-droplet-question:before { content: $ti-icon-droplet-question; }
.#{$ti-prefix}-droplet-search:before { content: $ti-icon-droplet-search; }
.#{$ti-prefix}-droplet-share:before { content: $ti-icon-droplet-share; }
.#{$ti-prefix}-droplet-star:before { content: $ti-icon-droplet-star; }
.#{$ti-prefix}-droplet-up:before { content: $ti-icon-droplet-up; }
.#{$ti-prefix}-droplet-x:before { content: $ti-icon-droplet-x; }
.#{$ti-prefix}-droplets:before { content: $ti-icon-droplets; }
.#{$ti-prefix}-dual-screen:before { content: $ti-icon-dual-screen; }
.#{$ti-prefix}-dumpling:before { content: $ti-icon-dumpling; }
.#{$ti-prefix}-e-passport:before { content: $ti-icon-e-passport; }
.#{$ti-prefix}-ear:before { content: $ti-icon-ear; }
.#{$ti-prefix}-ear-off:before { content: $ti-icon-ear-off; }
.#{$ti-prefix}-ear-scan:before { content: $ti-icon-ear-scan; }
.#{$ti-prefix}-ease-in:before { content: $ti-icon-ease-in; }
.#{$ti-prefix}-ease-in-control-point:before { content: $ti-icon-ease-in-control-point; }
.#{$ti-prefix}-ease-in-out:before { content: $ti-icon-ease-in-out; }
.#{$ti-prefix}-ease-in-out-control-points:before { content: $ti-icon-ease-in-out-control-points; }
.#{$ti-prefix}-ease-out:before { content: $ti-icon-ease-out; }
.#{$ti-prefix}-ease-out-control-point:before { content: $ti-icon-ease-out-control-point; }
.#{$ti-prefix}-edit:before { content: $ti-icon-edit; }
.#{$ti-prefix}-edit-circle:before { content: $ti-icon-edit-circle; }
.#{$ti-prefix}-edit-circle-off:before { content: $ti-icon-edit-circle-off; }
.#{$ti-prefix}-edit-off:before { content: $ti-icon-edit-off; }
.#{$ti-prefix}-egg:before { content: $ti-icon-egg; }
.#{$ti-prefix}-egg-cracked:before { content: $ti-icon-egg-cracked; }
.#{$ti-prefix}-egg-cracked-filled:before { content: $ti-icon-egg-cracked-filled; }
.#{$ti-prefix}-egg-filled:before { content: $ti-icon-egg-filled; }
.#{$ti-prefix}-egg-fried:before { content: $ti-icon-egg-fried; }
.#{$ti-prefix}-egg-off:before { content: $ti-icon-egg-off; }
.#{$ti-prefix}-eggs:before { content: $ti-icon-eggs; }
.#{$ti-prefix}-elevator:before { content: $ti-icon-elevator; }
.#{$ti-prefix}-elevator-filled:before { content: $ti-icon-elevator-filled; }
.#{$ti-prefix}-elevator-off:before { content: $ti-icon-elevator-off; }
.#{$ti-prefix}-emergency-bed:before { content: $ti-icon-emergency-bed; }
.#{$ti-prefix}-empathize:before { content: $ti-icon-empathize; }
.#{$ti-prefix}-empathize-off:before { content: $ti-icon-empathize-off; }
.#{$ti-prefix}-emphasis:before { content: $ti-icon-emphasis; }
.#{$ti-prefix}-engine:before { content: $ti-icon-engine; }
.#{$ti-prefix}-engine-off:before { content: $ti-icon-engine-off; }
.#{$ti-prefix}-equal:before { content: $ti-icon-equal; }
.#{$ti-prefix}-equal-double:before { content: $ti-icon-equal-double; }
.#{$ti-prefix}-equal-not:before { content: $ti-icon-equal-not; }
.#{$ti-prefix}-eraser:before { content: $ti-icon-eraser; }
.#{$ti-prefix}-eraser-off:before { content: $ti-icon-eraser-off; }
.#{$ti-prefix}-error-404:before { content: $ti-icon-error-404; }
.#{$ti-prefix}-error-404-off:before { content: $ti-icon-error-404-off; }
.#{$ti-prefix}-escalator:before { content: $ti-icon-escalator; }
.#{$ti-prefix}-escalator-down:before { content: $ti-icon-escalator-down; }
.#{$ti-prefix}-escalator-up:before { content: $ti-icon-escalator-up; }
.#{$ti-prefix}-exchange:before { content: $ti-icon-exchange; }
.#{$ti-prefix}-exchange-off:before { content: $ti-icon-exchange-off; }
.#{$ti-prefix}-exclamation-circle:before { content: $ti-icon-exclamation-circle; }
.#{$ti-prefix}-exclamation-circle-filled:before { content: $ti-icon-exclamation-circle-filled; }
.#{$ti-prefix}-exclamation-mark:before { content: $ti-icon-exclamation-mark; }
.#{$ti-prefix}-exclamation-mark-off:before { content: $ti-icon-exclamation-mark-off; }
.#{$ti-prefix}-explicit:before { content: $ti-icon-explicit; }
.#{$ti-prefix}-explicit-off:before { content: $ti-icon-explicit-off; }
.#{$ti-prefix}-exposure:before { content: $ti-icon-exposure; }
.#{$ti-prefix}-exposure-0:before { content: $ti-icon-exposure-0; }
.#{$ti-prefix}-exposure-minus-1:before { content: $ti-icon-exposure-minus-1; }
.#{$ti-prefix}-exposure-minus-2:before { content: $ti-icon-exposure-minus-2; }
.#{$ti-prefix}-exposure-off:before { content: $ti-icon-exposure-off; }
.#{$ti-prefix}-exposure-plus-1:before { content: $ti-icon-exposure-plus-1; }
.#{$ti-prefix}-exposure-plus-2:before { content: $ti-icon-exposure-plus-2; }
.#{$ti-prefix}-external-link:before { content: $ti-icon-external-link; }
.#{$ti-prefix}-external-link-off:before { content: $ti-icon-external-link-off; }
.#{$ti-prefix}-eye:before { content: $ti-icon-eye; }
.#{$ti-prefix}-eye-bitcoin:before { content: $ti-icon-eye-bitcoin; }
.#{$ti-prefix}-eye-bolt:before { content: $ti-icon-eye-bolt; }
.#{$ti-prefix}-eye-cancel:before { content: $ti-icon-eye-cancel; }
.#{$ti-prefix}-eye-check:before { content: $ti-icon-eye-check; }
.#{$ti-prefix}-eye-closed:before { content: $ti-icon-eye-closed; }
.#{$ti-prefix}-eye-code:before { content: $ti-icon-eye-code; }
.#{$ti-prefix}-eye-cog:before { content: $ti-icon-eye-cog; }
.#{$ti-prefix}-eye-discount:before { content: $ti-icon-eye-discount; }
.#{$ti-prefix}-eye-dollar:before { content: $ti-icon-eye-dollar; }
.#{$ti-prefix}-eye-dotted:before { content: $ti-icon-eye-dotted; }
.#{$ti-prefix}-eye-down:before { content: $ti-icon-eye-down; }
.#{$ti-prefix}-eye-edit:before { content: $ti-icon-eye-edit; }
.#{$ti-prefix}-eye-exclamation:before { content: $ti-icon-eye-exclamation; }
.#{$ti-prefix}-eye-filled:before { content: $ti-icon-eye-filled; }
.#{$ti-prefix}-eye-heart:before { content: $ti-icon-eye-heart; }
.#{$ti-prefix}-eye-minus:before { content: $ti-icon-eye-minus; }
.#{$ti-prefix}-eye-off:before { content: $ti-icon-eye-off; }
.#{$ti-prefix}-eye-pause:before { content: $ti-icon-eye-pause; }
.#{$ti-prefix}-eye-pin:before { content: $ti-icon-eye-pin; }
.#{$ti-prefix}-eye-plus:before { content: $ti-icon-eye-plus; }
.#{$ti-prefix}-eye-question:before { content: $ti-icon-eye-question; }
.#{$ti-prefix}-eye-search:before { content: $ti-icon-eye-search; }
.#{$ti-prefix}-eye-share:before { content: $ti-icon-eye-share; }
.#{$ti-prefix}-eye-spark:before { content: $ti-icon-eye-spark; }
.#{$ti-prefix}-eye-star:before { content: $ti-icon-eye-star; }
.#{$ti-prefix}-eye-table:before { content: $ti-icon-eye-table; }
.#{$ti-prefix}-eye-up:before { content: $ti-icon-eye-up; }
.#{$ti-prefix}-eye-x:before { content: $ti-icon-eye-x; }
.#{$ti-prefix}-eyeglass:before { content: $ti-icon-eyeglass; }
.#{$ti-prefix}-eyeglass-2:before { content: $ti-icon-eyeglass-2; }
.#{$ti-prefix}-eyeglass-off:before { content: $ti-icon-eyeglass-off; }
.#{$ti-prefix}-face-id:before { content: $ti-icon-face-id; }
.#{$ti-prefix}-face-id-error:before { content: $ti-icon-face-id-error; }
.#{$ti-prefix}-face-mask:before { content: $ti-icon-face-mask; }
.#{$ti-prefix}-face-mask-off:before { content: $ti-icon-face-mask-off; }
.#{$ti-prefix}-fall:before { content: $ti-icon-fall; }
.#{$ti-prefix}-favicon:before { content: $ti-icon-favicon; }
.#{$ti-prefix}-favicon-filled:before { content: $ti-icon-favicon-filled; }
.#{$ti-prefix}-feather:before { content: $ti-icon-feather; }
.#{$ti-prefix}-feather-filled:before { content: $ti-icon-feather-filled; }
.#{$ti-prefix}-feather-off:before { content: $ti-icon-feather-off; }
.#{$ti-prefix}-fence:before { content: $ti-icon-fence; }
.#{$ti-prefix}-fence-off:before { content: $ti-icon-fence-off; }
.#{$ti-prefix}-ferry:before { content: $ti-icon-ferry; }
.#{$ti-prefix}-fidget-spinner:before { content: $ti-icon-fidget-spinner; }
.#{$ti-prefix}-file:before { content: $ti-icon-file; }
.#{$ti-prefix}-file-3d:before { content: $ti-icon-file-3d; }
.#{$ti-prefix}-file-ai:before { content: $ti-icon-file-ai; }
.#{$ti-prefix}-file-alert:before { content: $ti-icon-file-alert; }
.#{$ti-prefix}-file-analytics:before { content: $ti-icon-file-analytics; }
.#{$ti-prefix}-file-arrow-left:before { content: $ti-icon-file-arrow-left; }
.#{$ti-prefix}-file-arrow-right:before { content: $ti-icon-file-arrow-right; }
.#{$ti-prefix}-file-barcode:before { content: $ti-icon-file-barcode; }
.#{$ti-prefix}-file-bitcoin:before { content: $ti-icon-file-bitcoin; }
.#{$ti-prefix}-file-broken:before { content: $ti-icon-file-broken; }
.#{$ti-prefix}-file-certificate:before { content: $ti-icon-file-certificate; }
.#{$ti-prefix}-file-chart:before { content: $ti-icon-file-chart; }
.#{$ti-prefix}-file-check:before { content: $ti-icon-file-check; }
.#{$ti-prefix}-file-code:before { content: $ti-icon-file-code; }
.#{$ti-prefix}-file-code-2:before { content: $ti-icon-file-code-2; }
.#{$ti-prefix}-file-cv:before { content: $ti-icon-file-cv; }
.#{$ti-prefix}-file-database:before { content: $ti-icon-file-database; }
.#{$ti-prefix}-file-delta:before { content: $ti-icon-file-delta; }
.#{$ti-prefix}-file-description:before { content: $ti-icon-file-description; }
.#{$ti-prefix}-file-diff:before { content: $ti-icon-file-diff; }
.#{$ti-prefix}-file-digit:before { content: $ti-icon-file-digit; }
.#{$ti-prefix}-file-dislike:before { content: $ti-icon-file-dislike; }
.#{$ti-prefix}-file-dollar:before { content: $ti-icon-file-dollar; }
.#{$ti-prefix}-file-dots:before { content: $ti-icon-file-dots; }
.#{$ti-prefix}-file-download:before { content: $ti-icon-file-download; }
.#{$ti-prefix}-file-euro:before { content: $ti-icon-file-euro; }
.#{$ti-prefix}-file-excel:before { content: $ti-icon-file-excel; }
.#{$ti-prefix}-file-export:before { content: $ti-icon-file-export; }
.#{$ti-prefix}-file-filled:before { content: $ti-icon-file-filled; }
.#{$ti-prefix}-file-function:before { content: $ti-icon-file-function; }
.#{$ti-prefix}-file-horizontal:before { content: $ti-icon-file-horizontal; }
.#{$ti-prefix}-file-import:before { content: $ti-icon-file-import; }
.#{$ti-prefix}-file-infinity:before { content: $ti-icon-file-infinity; }
.#{$ti-prefix}-file-info:before { content: $ti-icon-file-info; }
.#{$ti-prefix}-file-invoice:before { content: $ti-icon-file-invoice; }
.#{$ti-prefix}-file-isr:before { content: $ti-icon-file-isr; }
.#{$ti-prefix}-file-lambda:before { content: $ti-icon-file-lambda; }
.#{$ti-prefix}-file-like:before { content: $ti-icon-file-like; }
.#{$ti-prefix}-file-minus:before { content: $ti-icon-file-minus; }
.#{$ti-prefix}-file-music:before { content: $ti-icon-file-music; }
.#{$ti-prefix}-file-neutral:before { content: $ti-icon-file-neutral; }
.#{$ti-prefix}-file-off:before { content: $ti-icon-file-off; }
.#{$ti-prefix}-file-orientation:before { content: $ti-icon-file-orientation; }
.#{$ti-prefix}-file-pencil:before { content: $ti-icon-file-pencil; }
.#{$ti-prefix}-file-percent:before { content: $ti-icon-file-percent; }
.#{$ti-prefix}-file-phone:before { content: $ti-icon-file-phone; }
.#{$ti-prefix}-file-plus:before { content: $ti-icon-file-plus; }
.#{$ti-prefix}-file-power:before { content: $ti-icon-file-power; }
.#{$ti-prefix}-file-report:before { content: $ti-icon-file-report; }
.#{$ti-prefix}-file-rss:before { content: $ti-icon-file-rss; }
.#{$ti-prefix}-file-sad:before { content: $ti-icon-file-sad; }
.#{$ti-prefix}-file-scissors:before { content: $ti-icon-file-scissors; }
.#{$ti-prefix}-file-search:before { content: $ti-icon-file-search; }
.#{$ti-prefix}-file-settings:before { content: $ti-icon-file-settings; }
.#{$ti-prefix}-file-shredder:before { content: $ti-icon-file-shredder; }
.#{$ti-prefix}-file-signal:before { content: $ti-icon-file-signal; }
.#{$ti-prefix}-file-smile:before { content: $ti-icon-file-smile; }
.#{$ti-prefix}-file-spark:before { content: $ti-icon-file-spark; }
.#{$ti-prefix}-file-spreadsheet:before { content: $ti-icon-file-spreadsheet; }
.#{$ti-prefix}-file-stack:before { content: $ti-icon-file-stack; }
.#{$ti-prefix}-file-star:before { content: $ti-icon-file-star; }
.#{$ti-prefix}-file-symlink:before { content: $ti-icon-file-symlink; }
.#{$ti-prefix}-file-text:before { content: $ti-icon-file-text; }
.#{$ti-prefix}-file-text-ai:before { content: $ti-icon-file-text-ai; }
.#{$ti-prefix}-file-text-spark:before { content: $ti-icon-file-text-spark; }
.#{$ti-prefix}-file-time:before { content: $ti-icon-file-time; }
.#{$ti-prefix}-file-type-bmp:before { content: $ti-icon-file-type-bmp; }
.#{$ti-prefix}-file-type-css:before { content: $ti-icon-file-type-css; }
.#{$ti-prefix}-file-type-csv:before { content: $ti-icon-file-type-csv; }
.#{$ti-prefix}-file-type-doc:before { content: $ti-icon-file-type-doc; }
.#{$ti-prefix}-file-type-docx:before { content: $ti-icon-file-type-docx; }
.#{$ti-prefix}-file-type-html:before { content: $ti-icon-file-type-html; }
.#{$ti-prefix}-file-type-jpg:before { content: $ti-icon-file-type-jpg; }
.#{$ti-prefix}-file-type-js:before { content: $ti-icon-file-type-js; }
.#{$ti-prefix}-file-type-jsx:before { content: $ti-icon-file-type-jsx; }
.#{$ti-prefix}-file-type-pdf:before { content: $ti-icon-file-type-pdf; }
.#{$ti-prefix}-file-type-php:before { content: $ti-icon-file-type-php; }
.#{$ti-prefix}-file-type-png:before { content: $ti-icon-file-type-png; }
.#{$ti-prefix}-file-type-ppt:before { content: $ti-icon-file-type-ppt; }
.#{$ti-prefix}-file-type-rs:before { content: $ti-icon-file-type-rs; }
.#{$ti-prefix}-file-type-sql:before { content: $ti-icon-file-type-sql; }
.#{$ti-prefix}-file-type-svg:before { content: $ti-icon-file-type-svg; }
.#{$ti-prefix}-file-type-ts:before { content: $ti-icon-file-type-ts; }
.#{$ti-prefix}-file-type-tsx:before { content: $ti-icon-file-type-tsx; }
.#{$ti-prefix}-file-type-txt:before { content: $ti-icon-file-type-txt; }
.#{$ti-prefix}-file-type-vue:before { content: $ti-icon-file-type-vue; }
.#{$ti-prefix}-file-type-xls:before { content: $ti-icon-file-type-xls; }
.#{$ti-prefix}-file-type-xml:before { content: $ti-icon-file-type-xml; }
.#{$ti-prefix}-file-type-zip:before { content: $ti-icon-file-type-zip; }
.#{$ti-prefix}-file-typography:before { content: $ti-icon-file-typography; }
.#{$ti-prefix}-file-unknown:before { content: $ti-icon-file-unknown; }
.#{$ti-prefix}-file-upload:before { content: $ti-icon-file-upload; }
.#{$ti-prefix}-file-vector:before { content: $ti-icon-file-vector; }
.#{$ti-prefix}-file-word:before { content: $ti-icon-file-word; }
.#{$ti-prefix}-file-x:before { content: $ti-icon-file-x; }
.#{$ti-prefix}-file-x-filled:before { content: $ti-icon-file-x-filled; }
.#{$ti-prefix}-file-zip:before { content: $ti-icon-file-zip; }
.#{$ti-prefix}-files:before { content: $ti-icon-files; }
.#{$ti-prefix}-files-off:before { content: $ti-icon-files-off; }
.#{$ti-prefix}-filter:before { content: $ti-icon-filter; }
.#{$ti-prefix}-filter-bolt:before { content: $ti-icon-filter-bolt; }
.#{$ti-prefix}-filter-cancel:before { content: $ti-icon-filter-cancel; }
.#{$ti-prefix}-filter-check:before { content: $ti-icon-filter-check; }
.#{$ti-prefix}-filter-code:before { content: $ti-icon-filter-code; }
.#{$ti-prefix}-filter-cog:before { content: $ti-icon-filter-cog; }
.#{$ti-prefix}-filter-discount:before { content: $ti-icon-filter-discount; }
.#{$ti-prefix}-filter-dollar:before { content: $ti-icon-filter-dollar; }
.#{$ti-prefix}-filter-down:before { content: $ti-icon-filter-down; }
.#{$ti-prefix}-filter-edit:before { content: $ti-icon-filter-edit; }
.#{$ti-prefix}-filter-exclamation:before { content: $ti-icon-filter-exclamation; }
.#{$ti-prefix}-filter-filled:before { content: $ti-icon-filter-filled; }
.#{$ti-prefix}-filter-heart:before { content: $ti-icon-filter-heart; }
.#{$ti-prefix}-filter-minus:before { content: $ti-icon-filter-minus; }
.#{$ti-prefix}-filter-off:before { content: $ti-icon-filter-off; }
.#{$ti-prefix}-filter-pause:before { content: $ti-icon-filter-pause; }
.#{$ti-prefix}-filter-pin:before { content: $ti-icon-filter-pin; }
.#{$ti-prefix}-filter-plus:before { content: $ti-icon-filter-plus; }
.#{$ti-prefix}-filter-question:before { content: $ti-icon-filter-question; }
.#{$ti-prefix}-filter-search:before { content: $ti-icon-filter-search; }
.#{$ti-prefix}-filter-share:before { content: $ti-icon-filter-share; }
.#{$ti-prefix}-filter-star:before { content: $ti-icon-filter-star; }
.#{$ti-prefix}-filter-up:before { content: $ti-icon-filter-up; }
.#{$ti-prefix}-filter-x:before { content: $ti-icon-filter-x; }
.#{$ti-prefix}-filters:before { content: $ti-icon-filters; }
.#{$ti-prefix}-fingerprint:before { content: $ti-icon-fingerprint; }
.#{$ti-prefix}-fingerprint-off:before { content: $ti-icon-fingerprint-off; }
.#{$ti-prefix}-fingerprint-scan:before { content: $ti-icon-fingerprint-scan; }
.#{$ti-prefix}-fire-extinguisher:before { content: $ti-icon-fire-extinguisher; }
.#{$ti-prefix}-fire-hydrant:before { content: $ti-icon-fire-hydrant; }
.#{$ti-prefix}-fire-hydrant-off:before { content: $ti-icon-fire-hydrant-off; }
.#{$ti-prefix}-firetruck:before { content: $ti-icon-firetruck; }
.#{$ti-prefix}-first-aid-kit:before { content: $ti-icon-first-aid-kit; }
.#{$ti-prefix}-first-aid-kit-off:before { content: $ti-icon-first-aid-kit-off; }
.#{$ti-prefix}-fish:before { content: $ti-icon-fish; }
.#{$ti-prefix}-fish-bone:before { content: $ti-icon-fish-bone; }
.#{$ti-prefix}-fish-christianity:before { content: $ti-icon-fish-christianity; }
.#{$ti-prefix}-fish-hook:before { content: $ti-icon-fish-hook; }
.#{$ti-prefix}-fish-hook-off:before { content: $ti-icon-fish-hook-off; }
.#{$ti-prefix}-fish-off:before { content: $ti-icon-fish-off; }
.#{$ti-prefix}-flag:before { content: $ti-icon-flag; }
.#{$ti-prefix}-flag-2:before { content: $ti-icon-flag-2; }
.#{$ti-prefix}-flag-2-filled:before { content: $ti-icon-flag-2-filled; }
.#{$ti-prefix}-flag-2-off:before { content: $ti-icon-flag-2-off; }
.#{$ti-prefix}-flag-3:before { content: $ti-icon-flag-3; }
.#{$ti-prefix}-flag-3-filled:before { content: $ti-icon-flag-3-filled; }
.#{$ti-prefix}-flag-bitcoin:before { content: $ti-icon-flag-bitcoin; }
.#{$ti-prefix}-flag-bolt:before { content: $ti-icon-flag-bolt; }
.#{$ti-prefix}-flag-cancel:before { content: $ti-icon-flag-cancel; }
.#{$ti-prefix}-flag-check:before { content: $ti-icon-flag-check; }
.#{$ti-prefix}-flag-code:before { content: $ti-icon-flag-code; }
.#{$ti-prefix}-flag-cog:before { content: $ti-icon-flag-cog; }
.#{$ti-prefix}-flag-discount:before { content: $ti-icon-flag-discount; }
.#{$ti-prefix}-flag-dollar:before { content: $ti-icon-flag-dollar; }
.#{$ti-prefix}-flag-down:before { content: $ti-icon-flag-down; }
.#{$ti-prefix}-flag-exclamation:before { content: $ti-icon-flag-exclamation; }
.#{$ti-prefix}-flag-filled:before { content: $ti-icon-flag-filled; }
.#{$ti-prefix}-flag-heart:before { content: $ti-icon-flag-heart; }
.#{$ti-prefix}-flag-minus:before { content: $ti-icon-flag-minus; }
.#{$ti-prefix}-flag-off:before { content: $ti-icon-flag-off; }
.#{$ti-prefix}-flag-pause:before { content: $ti-icon-flag-pause; }
.#{$ti-prefix}-flag-pin:before { content: $ti-icon-flag-pin; }
.#{$ti-prefix}-flag-plus:before { content: $ti-icon-flag-plus; }
.#{$ti-prefix}-flag-question:before { content: $ti-icon-flag-question; }
.#{$ti-prefix}-flag-search:before { content: $ti-icon-flag-search; }
.#{$ti-prefix}-flag-share:before { content: $ti-icon-flag-share; }
.#{$ti-prefix}-flag-spark:before { content: $ti-icon-flag-spark; }
.#{$ti-prefix}-flag-star:before { content: $ti-icon-flag-star; }
.#{$ti-prefix}-flag-up:before { content: $ti-icon-flag-up; }
.#{$ti-prefix}-flag-x:before { content: $ti-icon-flag-x; }
.#{$ti-prefix}-flame:before { content: $ti-icon-flame; }
.#{$ti-prefix}-flame-off:before { content: $ti-icon-flame-off; }
.#{$ti-prefix}-flare:before { content: $ti-icon-flare; }
.#{$ti-prefix}-flask:before { content: $ti-icon-flask; }
.#{$ti-prefix}-flask-2:before { content: $ti-icon-flask-2; }
.#{$ti-prefix}-flask-2-filled:before { content: $ti-icon-flask-2-filled; }
.#{$ti-prefix}-flask-2-off:before { content: $ti-icon-flask-2-off; }
.#{$ti-prefix}-flask-filled:before { content: $ti-icon-flask-filled; }
.#{$ti-prefix}-flask-off:before { content: $ti-icon-flask-off; }
.#{$ti-prefix}-flip-flops:before { content: $ti-icon-flip-flops; }
.#{$ti-prefix}-flip-horizontal:before { content: $ti-icon-flip-horizontal; }
.#{$ti-prefix}-flip-vertical:before { content: $ti-icon-flip-vertical; }
.#{$ti-prefix}-float-center:before { content: $ti-icon-float-center; }
.#{$ti-prefix}-float-left:before { content: $ti-icon-float-left; }
.#{$ti-prefix}-float-none:before { content: $ti-icon-float-none; }
.#{$ti-prefix}-float-right:before { content: $ti-icon-float-right; }
.#{$ti-prefix}-flower:before { content: $ti-icon-flower; }
.#{$ti-prefix}-flower-filled:before { content: $ti-icon-flower-filled; }
.#{$ti-prefix}-flower-off:before { content: $ti-icon-flower-off; }
.#{$ti-prefix}-focus:before { content: $ti-icon-focus; }
.#{$ti-prefix}-focus-2:before { content: $ti-icon-focus-2; }
.#{$ti-prefix}-focus-auto:before { content: $ti-icon-focus-auto; }
.#{$ti-prefix}-focus-centered:before { content: $ti-icon-focus-centered; }
.#{$ti-prefix}-fold:before { content: $ti-icon-fold; }
.#{$ti-prefix}-fold-down:before { content: $ti-icon-fold-down; }
.#{$ti-prefix}-fold-up:before { content: $ti-icon-fold-up; }
.#{$ti-prefix}-folder:before { content: $ti-icon-folder; }
.#{$ti-prefix}-folder-bolt:before { content: $ti-icon-folder-bolt; }
.#{$ti-prefix}-folder-cancel:before { content: $ti-icon-folder-cancel; }
.#{$ti-prefix}-folder-check:before { content: $ti-icon-folder-check; }
.#{$ti-prefix}-folder-code:before { content: $ti-icon-folder-code; }
.#{$ti-prefix}-folder-cog:before { content: $ti-icon-folder-cog; }
.#{$ti-prefix}-folder-dollar:before { content: $ti-icon-folder-dollar; }
.#{$ti-prefix}-folder-down:before { content: $ti-icon-folder-down; }
.#{$ti-prefix}-folder-exclamation:before { content: $ti-icon-folder-exclamation; }
.#{$ti-prefix}-folder-filled:before { content: $ti-icon-folder-filled; }
.#{$ti-prefix}-folder-heart:before { content: $ti-icon-folder-heart; }
.#{$ti-prefix}-folder-minus:before { content: $ti-icon-folder-minus; }
.#{$ti-prefix}-folder-off:before { content: $ti-icon-folder-off; }
.#{$ti-prefix}-folder-open:before { content: $ti-icon-folder-open; }
.#{$ti-prefix}-folder-pause:before { content: $ti-icon-folder-pause; }
.#{$ti-prefix}-folder-pin:before { content: $ti-icon-folder-pin; }
.#{$ti-prefix}-folder-plus:before { content: $ti-icon-folder-plus; }
.#{$ti-prefix}-folder-question:before { content: $ti-icon-folder-question; }
.#{$ti-prefix}-folder-root:before { content: $ti-icon-folder-root; }
.#{$ti-prefix}-folder-search:before { content: $ti-icon-folder-search; }
.#{$ti-prefix}-folder-share:before { content: $ti-icon-folder-share; }
.#{$ti-prefix}-folder-star:before { content: $ti-icon-folder-star; }
.#{$ti-prefix}-folder-symlink:before { content: $ti-icon-folder-symlink; }
.#{$ti-prefix}-folder-up:before { content: $ti-icon-folder-up; }
.#{$ti-prefix}-folder-x:before { content: $ti-icon-folder-x; }
.#{$ti-prefix}-folders:before { content: $ti-icon-folders; }
.#{$ti-prefix}-folders-off:before { content: $ti-icon-folders-off; }
.#{$ti-prefix}-forbid:before { content: $ti-icon-forbid; }
.#{$ti-prefix}-forbid-2:before { content: $ti-icon-forbid-2; }
.#{$ti-prefix}-forbid-2-filled:before { content: $ti-icon-forbid-2-filled; }
.#{$ti-prefix}-forbid-filled:before { content: $ti-icon-forbid-filled; }
.#{$ti-prefix}-forklift:before { content: $ti-icon-forklift; }
.#{$ti-prefix}-forms:before { content: $ti-icon-forms; }
.#{$ti-prefix}-fountain:before { content: $ti-icon-fountain; }
.#{$ti-prefix}-fountain-filled:before { content: $ti-icon-fountain-filled; }
.#{$ti-prefix}-fountain-off:before { content: $ti-icon-fountain-off; }
.#{$ti-prefix}-frame:before { content: $ti-icon-frame; }
.#{$ti-prefix}-frame-off:before { content: $ti-icon-frame-off; }
.#{$ti-prefix}-free-rights:before { content: $ti-icon-free-rights; }
.#{$ti-prefix}-freeze-column:before { content: $ti-icon-freeze-column; }
.#{$ti-prefix}-freeze-row:before { content: $ti-icon-freeze-row; }
.#{$ti-prefix}-freeze-row-column:before { content: $ti-icon-freeze-row-column; }
.#{$ti-prefix}-fridge:before { content: $ti-icon-fridge; }
.#{$ti-prefix}-fridge-off:before { content: $ti-icon-fridge-off; }
.#{$ti-prefix}-friends:before { content: $ti-icon-friends; }
.#{$ti-prefix}-friends-off:before { content: $ti-icon-friends-off; }
.#{$ti-prefix}-frustum:before { content: $ti-icon-frustum; }
.#{$ti-prefix}-frustum-off:before { content: $ti-icon-frustum-off; }
.#{$ti-prefix}-frustum-plus:before { content: $ti-icon-frustum-plus; }
.#{$ti-prefix}-function:before { content: $ti-icon-function; }
.#{$ti-prefix}-function-filled:before { content: $ti-icon-function-filled; }
.#{$ti-prefix}-function-off:before { content: $ti-icon-function-off; }
.#{$ti-prefix}-galaxy:before { content: $ti-icon-galaxy; }
.#{$ti-prefix}-garden-cart:before { content: $ti-icon-garden-cart; }
.#{$ti-prefix}-garden-cart-off:before { content: $ti-icon-garden-cart-off; }
.#{$ti-prefix}-gas-station:before { content: $ti-icon-gas-station; }
.#{$ti-prefix}-gas-station-off:before { content: $ti-icon-gas-station-off; }
.#{$ti-prefix}-gauge:before { content: $ti-icon-gauge; }
.#{$ti-prefix}-gauge-filled:before { content: $ti-icon-gauge-filled; }
.#{$ti-prefix}-gauge-off:before { content: $ti-icon-gauge-off; }
.#{$ti-prefix}-gavel:before { content: $ti-icon-gavel; }
.#{$ti-prefix}-gender-agender:before { content: $ti-icon-gender-agender; }
.#{$ti-prefix}-gender-androgyne:before { content: $ti-icon-gender-androgyne; }
.#{$ti-prefix}-gender-bigender:before { content: $ti-icon-gender-bigender; }
.#{$ti-prefix}-gender-demiboy:before { content: $ti-icon-gender-demiboy; }
.#{$ti-prefix}-gender-demigirl:before { content: $ti-icon-gender-demigirl; }
.#{$ti-prefix}-gender-epicene:before { content: $ti-icon-gender-epicene; }
.#{$ti-prefix}-gender-female:before { content: $ti-icon-gender-female; }
.#{$ti-prefix}-gender-femme:before { content: $ti-icon-gender-femme; }
.#{$ti-prefix}-gender-genderfluid:before { content: $ti-icon-gender-genderfluid; }
.#{$ti-prefix}-gender-genderless:before { content: $ti-icon-gender-genderless; }
.#{$ti-prefix}-gender-genderqueer:before { content: $ti-icon-gender-genderqueer; }
.#{$ti-prefix}-gender-hermaphrodite:before { content: $ti-icon-gender-hermaphrodite; }
.#{$ti-prefix}-gender-intergender:before { content: $ti-icon-gender-intergender; }
.#{$ti-prefix}-gender-male:before { content: $ti-icon-gender-male; }
.#{$ti-prefix}-gender-neutrois:before { content: $ti-icon-gender-neutrois; }
.#{$ti-prefix}-gender-third:before { content: $ti-icon-gender-third; }
.#{$ti-prefix}-gender-transgender:before { content: $ti-icon-gender-transgender; }
.#{$ti-prefix}-gender-trasvesti:before { content: $ti-icon-gender-trasvesti; }
.#{$ti-prefix}-geometry:before { content: $ti-icon-geometry; }
.#{$ti-prefix}-ghost:before { content: $ti-icon-ghost; }
.#{$ti-prefix}-ghost-2:before { content: $ti-icon-ghost-2; }
.#{$ti-prefix}-ghost-2-filled:before { content: $ti-icon-ghost-2-filled; }
.#{$ti-prefix}-ghost-3:before { content: $ti-icon-ghost-3; }
.#{$ti-prefix}-ghost-filled:before { content: $ti-icon-ghost-filled; }
.#{$ti-prefix}-ghost-off:before { content: $ti-icon-ghost-off; }
.#{$ti-prefix}-gif:before { content: $ti-icon-gif; }
.#{$ti-prefix}-gift:before { content: $ti-icon-gift; }
.#{$ti-prefix}-gift-card:before { content: $ti-icon-gift-card; }
.#{$ti-prefix}-gift-card-filled:before { content: $ti-icon-gift-card-filled; }
.#{$ti-prefix}-gift-filled:before { content: $ti-icon-gift-filled; }
.#{$ti-prefix}-gift-off:before { content: $ti-icon-gift-off; }
.#{$ti-prefix}-git-branch:before { content: $ti-icon-git-branch; }
.#{$ti-prefix}-git-branch-deleted:before { content: $ti-icon-git-branch-deleted; }
.#{$ti-prefix}-git-cherry-pick:before { content: $ti-icon-git-cherry-pick; }
.#{$ti-prefix}-git-commit:before { content: $ti-icon-git-commit; }
.#{$ti-prefix}-git-compare:before { content: $ti-icon-git-compare; }
.#{$ti-prefix}-git-fork:before { content: $ti-icon-git-fork; }
.#{$ti-prefix}-git-merge:before { content: $ti-icon-git-merge; }
.#{$ti-prefix}-git-pull-request:before { content: $ti-icon-git-pull-request; }
.#{$ti-prefix}-git-pull-request-closed:before { content: $ti-icon-git-pull-request-closed; }
.#{$ti-prefix}-git-pull-request-draft:before { content: $ti-icon-git-pull-request-draft; }
.#{$ti-prefix}-gizmo:before { content: $ti-icon-gizmo; }
.#{$ti-prefix}-glass:before { content: $ti-icon-glass; }
.#{$ti-prefix}-glass-champagne:before { content: $ti-icon-glass-champagne; }
.#{$ti-prefix}-glass-cocktail:before { content: $ti-icon-glass-cocktail; }
.#{$ti-prefix}-glass-filled:before { content: $ti-icon-glass-filled; }
.#{$ti-prefix}-glass-full:before { content: $ti-icon-glass-full; }
.#{$ti-prefix}-glass-full-filled:before { content: $ti-icon-glass-full-filled; }
.#{$ti-prefix}-glass-gin:before { content: $ti-icon-glass-gin; }
.#{$ti-prefix}-glass-off:before { content: $ti-icon-glass-off; }
.#{$ti-prefix}-globe:before { content: $ti-icon-globe; }
.#{$ti-prefix}-globe-filled:before { content: $ti-icon-globe-filled; }
.#{$ti-prefix}-globe-off:before { content: $ti-icon-globe-off; }
.#{$ti-prefix}-go-game:before { content: $ti-icon-go-game; }
.#{$ti-prefix}-golf:before { content: $ti-icon-golf; }
.#{$ti-prefix}-golf-off:before { content: $ti-icon-golf-off; }
.#{$ti-prefix}-gps:before { content: $ti-icon-gps; }
.#{$ti-prefix}-gps-filled:before { content: $ti-icon-gps-filled; }
.#{$ti-prefix}-gradienter:before { content: $ti-icon-gradienter; }
.#{$ti-prefix}-grain:before { content: $ti-icon-grain; }
.#{$ti-prefix}-graph:before { content: $ti-icon-graph; }
.#{$ti-prefix}-graph-filled:before { content: $ti-icon-graph-filled; }
.#{$ti-prefix}-graph-off:before { content: $ti-icon-graph-off; }
.#{$ti-prefix}-grave:before { content: $ti-icon-grave; }
.#{$ti-prefix}-grave-2:before { content: $ti-icon-grave-2; }
.#{$ti-prefix}-grid-3x3:before { content: $ti-icon-grid-3x3; }
.#{$ti-prefix}-grid-4x4:before { content: $ti-icon-grid-4x4; }
.#{$ti-prefix}-grid-dots:before { content: $ti-icon-grid-dots; }
.#{$ti-prefix}-grid-goldenratio:before { content: $ti-icon-grid-goldenratio; }
.#{$ti-prefix}-grid-pattern:before { content: $ti-icon-grid-pattern; }
.#{$ti-prefix}-grid-scan:before { content: $ti-icon-grid-scan; }
.#{$ti-prefix}-grill:before { content: $ti-icon-grill; }
.#{$ti-prefix}-grill-fork:before { content: $ti-icon-grill-fork; }
.#{$ti-prefix}-grill-off:before { content: $ti-icon-grill-off; }
.#{$ti-prefix}-grill-spatula:before { content: $ti-icon-grill-spatula; }
.#{$ti-prefix}-grip-horizontal:before { content: $ti-icon-grip-horizontal; }
.#{$ti-prefix}-grip-vertical:before { content: $ti-icon-grip-vertical; }
.#{$ti-prefix}-growth:before { content: $ti-icon-growth; }
.#{$ti-prefix}-guitar-pick:before { content: $ti-icon-guitar-pick; }
.#{$ti-prefix}-guitar-pick-filled:before { content: $ti-icon-guitar-pick-filled; }
.#{$ti-prefix}-gymnastics:before { content: $ti-icon-gymnastics; }
.#{$ti-prefix}-h-1:before { content: $ti-icon-h-1; }
.#{$ti-prefix}-h-2:before { content: $ti-icon-h-2; }
.#{$ti-prefix}-h-3:before { content: $ti-icon-h-3; }
.#{$ti-prefix}-h-4:before { content: $ti-icon-h-4; }
.#{$ti-prefix}-h-5:before { content: $ti-icon-h-5; }
.#{$ti-prefix}-h-6:before { content: $ti-icon-h-6; }
.#{$ti-prefix}-hammer:before { content: $ti-icon-hammer; }
.#{$ti-prefix}-hammer-off:before { content: $ti-icon-hammer-off; }
.#{$ti-prefix}-hand-click:before { content: $ti-icon-hand-click; }
.#{$ti-prefix}-hand-finger:before { content: $ti-icon-hand-finger; }
.#{$ti-prefix}-hand-finger-down:before { content: $ti-icon-hand-finger-down; }
.#{$ti-prefix}-hand-finger-left:before { content: $ti-icon-hand-finger-left; }
.#{$ti-prefix}-hand-finger-off:before { content: $ti-icon-hand-finger-off; }
.#{$ti-prefix}-hand-finger-right:before { content: $ti-icon-hand-finger-right; }
.#{$ti-prefix}-hand-grab:before { content: $ti-icon-hand-grab; }
.#{$ti-prefix}-hand-little-finger:before { content: $ti-icon-hand-little-finger; }
.#{$ti-prefix}-hand-love-you:before { content: $ti-icon-hand-love-you; }
.#{$ti-prefix}-hand-middle-finger:before { content: $ti-icon-hand-middle-finger; }
.#{$ti-prefix}-hand-move:before { content: $ti-icon-hand-move; }
.#{$ti-prefix}-hand-off:before { content: $ti-icon-hand-off; }
.#{$ti-prefix}-hand-ring-finger:before { content: $ti-icon-hand-ring-finger; }
.#{$ti-prefix}-hand-sanitizer:before { content: $ti-icon-hand-sanitizer; }
.#{$ti-prefix}-hand-stop:before { content: $ti-icon-hand-stop; }
.#{$ti-prefix}-hand-three-fingers:before { content: $ti-icon-hand-three-fingers; }
.#{$ti-prefix}-hand-two-fingers:before { content: $ti-icon-hand-two-fingers; }
.#{$ti-prefix}-hanger:before { content: $ti-icon-hanger; }
.#{$ti-prefix}-hanger-2:before { content: $ti-icon-hanger-2; }
.#{$ti-prefix}-hanger-2-filled:before { content: $ti-icon-hanger-2-filled; }
.#{$ti-prefix}-hanger-off:before { content: $ti-icon-hanger-off; }
.#{$ti-prefix}-hash:before { content: $ti-icon-hash; }
.#{$ti-prefix}-haze:before { content: $ti-icon-haze; }
.#{$ti-prefix}-haze-moon:before { content: $ti-icon-haze-moon; }
.#{$ti-prefix}-hdr:before { content: $ti-icon-hdr; }
.#{$ti-prefix}-heading:before { content: $ti-icon-heading; }
.#{$ti-prefix}-heading-off:before { content: $ti-icon-heading-off; }
.#{$ti-prefix}-headphones:before { content: $ti-icon-headphones; }
.#{$ti-prefix}-headphones-filled:before { content: $ti-icon-headphones-filled; }
.#{$ti-prefix}-headphones-off:before { content: $ti-icon-headphones-off; }
.#{$ti-prefix}-headset:before { content: $ti-icon-headset; }
.#{$ti-prefix}-headset-off:before { content: $ti-icon-headset-off; }
.#{$ti-prefix}-health-recognition:before { content: $ti-icon-health-recognition; }
.#{$ti-prefix}-heart:before { content: $ti-icon-heart; }
.#{$ti-prefix}-heart-bitcoin:before { content: $ti-icon-heart-bitcoin; }
.#{$ti-prefix}-heart-bolt:before { content: $ti-icon-heart-bolt; }
.#{$ti-prefix}-heart-broken:before { content: $ti-icon-heart-broken; }
.#{$ti-prefix}-heart-cancel:before { content: $ti-icon-heart-cancel; }
.#{$ti-prefix}-heart-check:before { content: $ti-icon-heart-check; }
.#{$ti-prefix}-heart-code:before { content: $ti-icon-heart-code; }
.#{$ti-prefix}-heart-cog:before { content: $ti-icon-heart-cog; }
.#{$ti-prefix}-heart-discount:before { content: $ti-icon-heart-discount; }
.#{$ti-prefix}-heart-dollar:before { content: $ti-icon-heart-dollar; }
.#{$ti-prefix}-heart-down:before { content: $ti-icon-heart-down; }
.#{$ti-prefix}-heart-exclamation:before { content: $ti-icon-heart-exclamation; }
.#{$ti-prefix}-heart-filled:before { content: $ti-icon-heart-filled; }
.#{$ti-prefix}-heart-handshake:before { content: $ti-icon-heart-handshake; }
.#{$ti-prefix}-heart-minus:before { content: $ti-icon-heart-minus; }
.#{$ti-prefix}-heart-off:before { content: $ti-icon-heart-off; }
.#{$ti-prefix}-heart-pause:before { content: $ti-icon-heart-pause; }
.#{$ti-prefix}-heart-pin:before { content: $ti-icon-heart-pin; }
.#{$ti-prefix}-heart-plus:before { content: $ti-icon-heart-plus; }
.#{$ti-prefix}-heart-question:before { content: $ti-icon-heart-question; }
.#{$ti-prefix}-heart-rate-monitor:before { content: $ti-icon-heart-rate-monitor; }
.#{$ti-prefix}-heart-search:before { content: $ti-icon-heart-search; }
.#{$ti-prefix}-heart-share:before { content: $ti-icon-heart-share; }
.#{$ti-prefix}-heart-spark:before { content: $ti-icon-heart-spark; }
.#{$ti-prefix}-heart-star:before { content: $ti-icon-heart-star; }
.#{$ti-prefix}-heart-up:before { content: $ti-icon-heart-up; }
.#{$ti-prefix}-heart-x:before { content: $ti-icon-heart-x; }
.#{$ti-prefix}-heartbeat:before { content: $ti-icon-heartbeat; }
.#{$ti-prefix}-hearts:before { content: $ti-icon-hearts; }
.#{$ti-prefix}-hearts-off:before { content: $ti-icon-hearts-off; }
.#{$ti-prefix}-helicopter:before { content: $ti-icon-helicopter; }
.#{$ti-prefix}-helicopter-landing:before { content: $ti-icon-helicopter-landing; }
.#{$ti-prefix}-helmet:before { content: $ti-icon-helmet; }
.#{$ti-prefix}-helmet-off:before { content: $ti-icon-helmet-off; }
.#{$ti-prefix}-help:before { content: $ti-icon-help; }
.#{$ti-prefix}-help-circle:before { content: $ti-icon-help-circle; }
.#{$ti-prefix}-help-circle-filled:before { content: $ti-icon-help-circle-filled; }
.#{$ti-prefix}-help-hexagon:before { content: $ti-icon-help-hexagon; }
.#{$ti-prefix}-help-hexagon-filled:before { content: $ti-icon-help-hexagon-filled; }
.#{$ti-prefix}-help-octagon:before { content: $ti-icon-help-octagon; }
.#{$ti-prefix}-help-octagon-filled:before { content: $ti-icon-help-octagon-filled; }
.#{$ti-prefix}-help-off:before { content: $ti-icon-help-off; }
.#{$ti-prefix}-help-small:before { content: $ti-icon-help-small; }
.#{$ti-prefix}-help-square:before { content: $ti-icon-help-square; }
.#{$ti-prefix}-help-square-filled:before { content: $ti-icon-help-square-filled; }
.#{$ti-prefix}-help-square-rounded:before { content: $ti-icon-help-square-rounded; }
.#{$ti-prefix}-help-square-rounded-filled:before { content: $ti-icon-help-square-rounded-filled; }
.#{$ti-prefix}-help-triangle:before { content: $ti-icon-help-triangle; }
.#{$ti-prefix}-help-triangle-filled:before { content: $ti-icon-help-triangle-filled; }
.#{$ti-prefix}-hemisphere:before { content: $ti-icon-hemisphere; }
.#{$ti-prefix}-hemisphere-off:before { content: $ti-icon-hemisphere-off; }
.#{$ti-prefix}-hemisphere-plus:before { content: $ti-icon-hemisphere-plus; }
.#{$ti-prefix}-hexagon:before { content: $ti-icon-hexagon; }
.#{$ti-prefix}-hexagon-3d:before { content: $ti-icon-hexagon-3d; }
.#{$ti-prefix}-hexagon-filled:before { content: $ti-icon-hexagon-filled; }
.#{$ti-prefix}-hexagon-letter-a:before { content: $ti-icon-hexagon-letter-a; }
.#{$ti-prefix}-hexagon-letter-a-filled:before { content: $ti-icon-hexagon-letter-a-filled; }
.#{$ti-prefix}-hexagon-letter-b:before { content: $ti-icon-hexagon-letter-b; }
.#{$ti-prefix}-hexagon-letter-b-filled:before { content: $ti-icon-hexagon-letter-b-filled; }
.#{$ti-prefix}-hexagon-letter-c:before { content: $ti-icon-hexagon-letter-c; }
.#{$ti-prefix}-hexagon-letter-c-filled:before { content: $ti-icon-hexagon-letter-c-filled; }
.#{$ti-prefix}-hexagon-letter-d:before { content: $ti-icon-hexagon-letter-d; }
.#{$ti-prefix}-hexagon-letter-d-filled:before { content: $ti-icon-hexagon-letter-d-filled; }
.#{$ti-prefix}-hexagon-letter-e:before { content: $ti-icon-hexagon-letter-e; }
.#{$ti-prefix}-hexagon-letter-e-filled:before { content: $ti-icon-hexagon-letter-e-filled; }
.#{$ti-prefix}-hexagon-letter-f:before { content: $ti-icon-hexagon-letter-f; }
.#{$ti-prefix}-hexagon-letter-f-filled:before { content: $ti-icon-hexagon-letter-f-filled; }
.#{$ti-prefix}-hexagon-letter-g:before { content: $ti-icon-hexagon-letter-g; }
.#{$ti-prefix}-hexagon-letter-g-filled:before { content: $ti-icon-hexagon-letter-g-filled; }
.#{$ti-prefix}-hexagon-letter-h:before { content: $ti-icon-hexagon-letter-h; }
.#{$ti-prefix}-hexagon-letter-h-filled:before { content: $ti-icon-hexagon-letter-h-filled; }
.#{$ti-prefix}-hexagon-letter-i:before { content: $ti-icon-hexagon-letter-i; }
.#{$ti-prefix}-hexagon-letter-i-filled:before { content: $ti-icon-hexagon-letter-i-filled; }
.#{$ti-prefix}-hexagon-letter-j:before { content: $ti-icon-hexagon-letter-j; }
.#{$ti-prefix}-hexagon-letter-j-filled:before { content: $ti-icon-hexagon-letter-j-filled; }
.#{$ti-prefix}-hexagon-letter-k:before { content: $ti-icon-hexagon-letter-k; }
.#{$ti-prefix}-hexagon-letter-k-filled:before { content: $ti-icon-hexagon-letter-k-filled; }
.#{$ti-prefix}-hexagon-letter-l:before { content: $ti-icon-hexagon-letter-l; }
.#{$ti-prefix}-hexagon-letter-l-filled:before { content: $ti-icon-hexagon-letter-l-filled; }
.#{$ti-prefix}-hexagon-letter-m:before { content: $ti-icon-hexagon-letter-m; }
.#{$ti-prefix}-hexagon-letter-m-filled:before { content: $ti-icon-hexagon-letter-m-filled; }
.#{$ti-prefix}-hexagon-letter-n:before { content: $ti-icon-hexagon-letter-n; }
.#{$ti-prefix}-hexagon-letter-n-filled:before { content: $ti-icon-hexagon-letter-n-filled; }
.#{$ti-prefix}-hexagon-letter-o:before { content: $ti-icon-hexagon-letter-o; }
.#{$ti-prefix}-hexagon-letter-o-filled:before { content: $ti-icon-hexagon-letter-o-filled; }
.#{$ti-prefix}-hexagon-letter-p:before { content: $ti-icon-hexagon-letter-p; }
.#{$ti-prefix}-hexagon-letter-p-filled:before { content: $ti-icon-hexagon-letter-p-filled; }
.#{$ti-prefix}-hexagon-letter-q:before { content: $ti-icon-hexagon-letter-q; }
.#{$ti-prefix}-hexagon-letter-q-filled:before { content: $ti-icon-hexagon-letter-q-filled; }
.#{$ti-prefix}-hexagon-letter-r:before { content: $ti-icon-hexagon-letter-r; }
.#{$ti-prefix}-hexagon-letter-r-filled:before { content: $ti-icon-hexagon-letter-r-filled; }
.#{$ti-prefix}-hexagon-letter-s:before { content: $ti-icon-hexagon-letter-s; }
.#{$ti-prefix}-hexagon-letter-s-filled:before { content: $ti-icon-hexagon-letter-s-filled; }
.#{$ti-prefix}-hexagon-letter-t:before { content: $ti-icon-hexagon-letter-t; }
.#{$ti-prefix}-hexagon-letter-t-filled:before { content: $ti-icon-hexagon-letter-t-filled; }
.#{$ti-prefix}-hexagon-letter-u:before { content: $ti-icon-hexagon-letter-u; }
.#{$ti-prefix}-hexagon-letter-u-filled:before { content: $ti-icon-hexagon-letter-u-filled; }
.#{$ti-prefix}-hexagon-letter-v:before { content: $ti-icon-hexagon-letter-v; }
.#{$ti-prefix}-hexagon-letter-v-filled:before { content: $ti-icon-hexagon-letter-v-filled; }
.#{$ti-prefix}-hexagon-letter-w:before { content: $ti-icon-hexagon-letter-w; }
.#{$ti-prefix}-hexagon-letter-w-filled:before { content: $ti-icon-hexagon-letter-w-filled; }
.#{$ti-prefix}-hexagon-letter-x:before { content: $ti-icon-hexagon-letter-x; }
.#{$ti-prefix}-hexagon-letter-x-filled:before { content: $ti-icon-hexagon-letter-x-filled; }
.#{$ti-prefix}-hexagon-letter-y:before { content: $ti-icon-hexagon-letter-y; }
.#{$ti-prefix}-hexagon-letter-y-filled:before { content: $ti-icon-hexagon-letter-y-filled; }
.#{$ti-prefix}-hexagon-letter-z:before { content: $ti-icon-hexagon-letter-z; }
.#{$ti-prefix}-hexagon-letter-z-filled:before { content: $ti-icon-hexagon-letter-z-filled; }
.#{$ti-prefix}-hexagon-minus:before { content: $ti-icon-hexagon-minus; }
.#{$ti-prefix}-hexagon-minus-2:before { content: $ti-icon-hexagon-minus-2; }
.#{$ti-prefix}-hexagon-minus-filled:before { content: $ti-icon-hexagon-minus-filled; }
.#{$ti-prefix}-hexagon-number-0:before { content: $ti-icon-hexagon-number-0; }
.#{$ti-prefix}-hexagon-number-0-filled:before { content: $ti-icon-hexagon-number-0-filled; }
.#{$ti-prefix}-hexagon-number-1:before { content: $ti-icon-hexagon-number-1; }
.#{$ti-prefix}-hexagon-number-1-filled:before { content: $ti-icon-hexagon-number-1-filled; }
.#{$ti-prefix}-hexagon-number-2:before { content: $ti-icon-hexagon-number-2; }
.#{$ti-prefix}-hexagon-number-2-filled:before { content: $ti-icon-hexagon-number-2-filled; }
.#{$ti-prefix}-hexagon-number-3:before { content: $ti-icon-hexagon-number-3; }
.#{$ti-prefix}-hexagon-number-3-filled:before { content: $ti-icon-hexagon-number-3-filled; }
.#{$ti-prefix}-hexagon-number-4:before { content: $ti-icon-hexagon-number-4; }
.#{$ti-prefix}-hexagon-number-4-filled:before { content: $ti-icon-hexagon-number-4-filled; }
.#{$ti-prefix}-hexagon-number-5:before { content: $ti-icon-hexagon-number-5; }
.#{$ti-prefix}-hexagon-number-5-filled:before { content: $ti-icon-hexagon-number-5-filled; }
.#{$ti-prefix}-hexagon-number-6:before { content: $ti-icon-hexagon-number-6; }
.#{$ti-prefix}-hexagon-number-6-filled:before { content: $ti-icon-hexagon-number-6-filled; }
.#{$ti-prefix}-hexagon-number-7:before { content: $ti-icon-hexagon-number-7; }
.#{$ti-prefix}-hexagon-number-7-filled:before { content: $ti-icon-hexagon-number-7-filled; }
.#{$ti-prefix}-hexagon-number-8:before { content: $ti-icon-hexagon-number-8; }
.#{$ti-prefix}-hexagon-number-8-filled:before { content: $ti-icon-hexagon-number-8-filled; }
.#{$ti-prefix}-hexagon-number-9:before { content: $ti-icon-hexagon-number-9; }
.#{$ti-prefix}-hexagon-number-9-filled:before { content: $ti-icon-hexagon-number-9-filled; }
.#{$ti-prefix}-hexagon-off:before { content: $ti-icon-hexagon-off; }
.#{$ti-prefix}-hexagon-plus:before { content: $ti-icon-hexagon-plus; }
.#{$ti-prefix}-hexagon-plus-2:before { content: $ti-icon-hexagon-plus-2; }
.#{$ti-prefix}-hexagon-plus-filled:before { content: $ti-icon-hexagon-plus-filled; }
.#{$ti-prefix}-hexagonal-prism:before { content: $ti-icon-hexagonal-prism; }
.#{$ti-prefix}-hexagonal-prism-off:before { content: $ti-icon-hexagonal-prism-off; }
.#{$ti-prefix}-hexagonal-prism-plus:before { content: $ti-icon-hexagonal-prism-plus; }
.#{$ti-prefix}-hexagonal-pyramid:before { content: $ti-icon-hexagonal-pyramid; }
.#{$ti-prefix}-hexagonal-pyramid-off:before { content: $ti-icon-hexagonal-pyramid-off; }
.#{$ti-prefix}-hexagonal-pyramid-plus:before { content: $ti-icon-hexagonal-pyramid-plus; }
.#{$ti-prefix}-hexagons:before { content: $ti-icon-hexagons; }
.#{$ti-prefix}-hexagons-off:before { content: $ti-icon-hexagons-off; }
.#{$ti-prefix}-hierarchy:before { content: $ti-icon-hierarchy; }
.#{$ti-prefix}-hierarchy-2:before { content: $ti-icon-hierarchy-2; }
.#{$ti-prefix}-hierarchy-3:before { content: $ti-icon-hierarchy-3; }
.#{$ti-prefix}-hierarchy-off:before { content: $ti-icon-hierarchy-off; }
.#{$ti-prefix}-highlight:before { content: $ti-icon-highlight; }
.#{$ti-prefix}-highlight-off:before { content: $ti-icon-highlight-off; }
.#{$ti-prefix}-history:before { content: $ti-icon-history; }
.#{$ti-prefix}-history-off:before { content: $ti-icon-history-off; }
.#{$ti-prefix}-history-toggle:before { content: $ti-icon-history-toggle; }
.#{$ti-prefix}-home:before { content: $ti-icon-home; }
.#{$ti-prefix}-home-2:before { content: $ti-icon-home-2; }
.#{$ti-prefix}-home-bitcoin:before { content: $ti-icon-home-bitcoin; }
.#{$ti-prefix}-home-bolt:before { content: $ti-icon-home-bolt; }
.#{$ti-prefix}-home-cancel:before { content: $ti-icon-home-cancel; }
.#{$ti-prefix}-home-check:before { content: $ti-icon-home-check; }
.#{$ti-prefix}-home-cog:before { content: $ti-icon-home-cog; }
.#{$ti-prefix}-home-dollar:before { content: $ti-icon-home-dollar; }
.#{$ti-prefix}-home-dot:before { content: $ti-icon-home-dot; }
.#{$ti-prefix}-home-down:before { content: $ti-icon-home-down; }
.#{$ti-prefix}-home-eco:before { content: $ti-icon-home-eco; }
.#{$ti-prefix}-home-edit:before { content: $ti-icon-home-edit; }
.#{$ti-prefix}-home-exclamation:before { content: $ti-icon-home-exclamation; }
.#{$ti-prefix}-home-filled:before { content: $ti-icon-home-filled; }
.#{$ti-prefix}-home-hand:before { content: $ti-icon-home-hand; }
.#{$ti-prefix}-home-heart:before { content: $ti-icon-home-heart; }
.#{$ti-prefix}-home-infinity:before { content: $ti-icon-home-infinity; }
.#{$ti-prefix}-home-link:before { content: $ti-icon-home-link; }
.#{$ti-prefix}-home-minus:before { content: $ti-icon-home-minus; }
.#{$ti-prefix}-home-move:before { content: $ti-icon-home-move; }
.#{$ti-prefix}-home-off:before { content: $ti-icon-home-off; }
.#{$ti-prefix}-home-plus:before { content: $ti-icon-home-plus; }
.#{$ti-prefix}-home-question:before { content: $ti-icon-home-question; }
.#{$ti-prefix}-home-ribbon:before { content: $ti-icon-home-ribbon; }
.#{$ti-prefix}-home-search:before { content: $ti-icon-home-search; }
.#{$ti-prefix}-home-share:before { content: $ti-icon-home-share; }
.#{$ti-prefix}-home-shield:before { content: $ti-icon-home-shield; }
.#{$ti-prefix}-home-signal:before { content: $ti-icon-home-signal; }
.#{$ti-prefix}-home-spark:before { content: $ti-icon-home-spark; }
.#{$ti-prefix}-home-star:before { content: $ti-icon-home-star; }
.#{$ti-prefix}-home-stats:before { content: $ti-icon-home-stats; }
.#{$ti-prefix}-home-up:before { content: $ti-icon-home-up; }
.#{$ti-prefix}-home-x:before { content: $ti-icon-home-x; }
.#{$ti-prefix}-horse:before { content: $ti-icon-horse; }
.#{$ti-prefix}-horse-toy:before { content: $ti-icon-horse-toy; }
.#{$ti-prefix}-horseshoe:before { content: $ti-icon-horseshoe; }
.#{$ti-prefix}-hospital:before { content: $ti-icon-hospital; }
.#{$ti-prefix}-hospital-circle:before { content: $ti-icon-hospital-circle; }
.#{$ti-prefix}-hospital-circle-filled:before { content: $ti-icon-hospital-circle-filled; }
.#{$ti-prefix}-hotel-service:before { content: $ti-icon-hotel-service; }
.#{$ti-prefix}-hourglass:before { content: $ti-icon-hourglass; }
.#{$ti-prefix}-hourglass-empty:before { content: $ti-icon-hourglass-empty; }
.#{$ti-prefix}-hourglass-filled:before { content: $ti-icon-hourglass-filled; }
.#{$ti-prefix}-hourglass-high:before { content: $ti-icon-hourglass-high; }
.#{$ti-prefix}-hourglass-low:before { content: $ti-icon-hourglass-low; }
.#{$ti-prefix}-hourglass-off:before { content: $ti-icon-hourglass-off; }
.#{$ti-prefix}-hours-12:before { content: $ti-icon-hours-12; }
.#{$ti-prefix}-hours-24:before { content: $ti-icon-hours-24; }
.#{$ti-prefix}-html:before { content: $ti-icon-html; }
.#{$ti-prefix}-http-connect:before { content: $ti-icon-http-connect; }
.#{$ti-prefix}-http-delete:before { content: $ti-icon-http-delete; }
.#{$ti-prefix}-http-get:before { content: $ti-icon-http-get; }
.#{$ti-prefix}-http-head:before { content: $ti-icon-http-head; }
.#{$ti-prefix}-http-options:before { content: $ti-icon-http-options; }
.#{$ti-prefix}-http-patch:before { content: $ti-icon-http-patch; }
.#{$ti-prefix}-http-post:before { content: $ti-icon-http-post; }
.#{$ti-prefix}-http-put:before { content: $ti-icon-http-put; }
.#{$ti-prefix}-http-que:before { content: $ti-icon-http-que; }
.#{$ti-prefix}-http-trace:before { content: $ti-icon-http-trace; }
.#{$ti-prefix}-ice-cream:before { content: $ti-icon-ice-cream; }
.#{$ti-prefix}-ice-cream-2:before { content: $ti-icon-ice-cream-2; }
.#{$ti-prefix}-ice-cream-off:before { content: $ti-icon-ice-cream-off; }
.#{$ti-prefix}-ice-skating:before { content: $ti-icon-ice-skating; }
.#{$ti-prefix}-icons:before { content: $ti-icon-icons; }
.#{$ti-prefix}-icons-filled:before { content: $ti-icon-icons-filled; }
.#{$ti-prefix}-icons-off:before { content: $ti-icon-icons-off; }
.#{$ti-prefix}-id:before { content: $ti-icon-id; }
.#{$ti-prefix}-id-badge:before { content: $ti-icon-id-badge; }
.#{$ti-prefix}-id-badge-2:before { content: $ti-icon-id-badge-2; }
.#{$ti-prefix}-id-badge-off:before { content: $ti-icon-id-badge-off; }
.#{$ti-prefix}-id-off:before { content: $ti-icon-id-off; }
.#{$ti-prefix}-ikosaedr:before { content: $ti-icon-ikosaedr; }
.#{$ti-prefix}-image-in-picture:before { content: $ti-icon-image-in-picture; }
.#{$ti-prefix}-inbox:before { content: $ti-icon-inbox; }
.#{$ti-prefix}-inbox-off:before { content: $ti-icon-inbox-off; }
.#{$ti-prefix}-indent-decrease:before { content: $ti-icon-indent-decrease; }
.#{$ti-prefix}-indent-increase:before { content: $ti-icon-indent-increase; }
.#{$ti-prefix}-infinity:before { content: $ti-icon-infinity; }
.#{$ti-prefix}-infinity-off:before { content: $ti-icon-infinity-off; }
.#{$ti-prefix}-info-circle:before { content: $ti-icon-info-circle; }
.#{$ti-prefix}-info-circle-filled:before { content: $ti-icon-info-circle-filled; }
.#{$ti-prefix}-info-hexagon:before { content: $ti-icon-info-hexagon; }
.#{$ti-prefix}-info-hexagon-filled:before { content: $ti-icon-info-hexagon-filled; }
.#{$ti-prefix}-info-octagon:before { content: $ti-icon-info-octagon; }
.#{$ti-prefix}-info-octagon-filled:before { content: $ti-icon-info-octagon-filled; }
.#{$ti-prefix}-info-small:before { content: $ti-icon-info-small; }
.#{$ti-prefix}-info-square:before { content: $ti-icon-info-square; }
.#{$ti-prefix}-info-square-filled:before { content: $ti-icon-info-square-filled; }
.#{$ti-prefix}-info-square-rounded:before { content: $ti-icon-info-square-rounded; }
.#{$ti-prefix}-info-square-rounded-filled:before { content: $ti-icon-info-square-rounded-filled; }
.#{$ti-prefix}-info-triangle:before { content: $ti-icon-info-triangle; }
.#{$ti-prefix}-info-triangle-filled:before { content: $ti-icon-info-triangle-filled; }
.#{$ti-prefix}-inner-shadow-bottom:before { content: $ti-icon-inner-shadow-bottom; }
.#{$ti-prefix}-inner-shadow-bottom-filled:before { content: $ti-icon-inner-shadow-bottom-filled; }
.#{$ti-prefix}-inner-shadow-bottom-left:before { content: $ti-icon-inner-shadow-bottom-left; }
.#{$ti-prefix}-inner-shadow-bottom-left-filled:before { content: $ti-icon-inner-shadow-bottom-left-filled; }
.#{$ti-prefix}-inner-shadow-bottom-right:before { content: $ti-icon-inner-shadow-bottom-right; }
.#{$ti-prefix}-inner-shadow-bottom-right-filled:before { content: $ti-icon-inner-shadow-bottom-right-filled; }
.#{$ti-prefix}-inner-shadow-left:before { content: $ti-icon-inner-shadow-left; }
.#{$ti-prefix}-inner-shadow-left-filled:before { content: $ti-icon-inner-shadow-left-filled; }
.#{$ti-prefix}-inner-shadow-right:before { content: $ti-icon-inner-shadow-right; }
.#{$ti-prefix}-inner-shadow-right-filled:before { content: $ti-icon-inner-shadow-right-filled; }
.#{$ti-prefix}-inner-shadow-top:before { content: $ti-icon-inner-shadow-top; }
.#{$ti-prefix}-inner-shadow-top-filled:before { content: $ti-icon-inner-shadow-top-filled; }
.#{$ti-prefix}-inner-shadow-top-left:before { content: $ti-icon-inner-shadow-top-left; }
.#{$ti-prefix}-inner-shadow-top-left-filled:before { content: $ti-icon-inner-shadow-top-left-filled; }
.#{$ti-prefix}-inner-shadow-top-right:before { content: $ti-icon-inner-shadow-top-right; }
.#{$ti-prefix}-inner-shadow-top-right-filled:before { content: $ti-icon-inner-shadow-top-right-filled; }
.#{$ti-prefix}-input-ai:before { content: $ti-icon-input-ai; }
.#{$ti-prefix}-input-check:before { content: $ti-icon-input-check; }
.#{$ti-prefix}-input-search:before { content: $ti-icon-input-search; }
.#{$ti-prefix}-input-spark:before { content: $ti-icon-input-spark; }
.#{$ti-prefix}-input-x:before { content: $ti-icon-input-x; }
.#{$ti-prefix}-invoice:before { content: $ti-icon-invoice; }
.#{$ti-prefix}-ironing:before { content: $ti-icon-ironing; }
.#{$ti-prefix}-ironing-1:before { content: $ti-icon-ironing-1; }
.#{$ti-prefix}-ironing-1-filled:before { content: $ti-icon-ironing-1-filled; }
.#{$ti-prefix}-ironing-2:before { content: $ti-icon-ironing-2; }
.#{$ti-prefix}-ironing-2-filled:before { content: $ti-icon-ironing-2-filled; }
.#{$ti-prefix}-ironing-3:before { content: $ti-icon-ironing-3; }
.#{$ti-prefix}-ironing-3-filled:before { content: $ti-icon-ironing-3-filled; }
.#{$ti-prefix}-ironing-filled:before { content: $ti-icon-ironing-filled; }
.#{$ti-prefix}-ironing-off:before { content: $ti-icon-ironing-off; }
.#{$ti-prefix}-ironing-steam:before { content: $ti-icon-ironing-steam; }
.#{$ti-prefix}-ironing-steam-filled:before { content: $ti-icon-ironing-steam-filled; }
.#{$ti-prefix}-ironing-steam-off:before { content: $ti-icon-ironing-steam-off; }
.#{$ti-prefix}-irregular-polyhedron:before { content: $ti-icon-irregular-polyhedron; }
.#{$ti-prefix}-irregular-polyhedron-off:before { content: $ti-icon-irregular-polyhedron-off; }
.#{$ti-prefix}-irregular-polyhedron-plus:before { content: $ti-icon-irregular-polyhedron-plus; }
.#{$ti-prefix}-italic:before { content: $ti-icon-italic; }
.#{$ti-prefix}-jacket:before { content: $ti-icon-jacket; }
.#{$ti-prefix}-jetpack:before { content: $ti-icon-jetpack; }
.#{$ti-prefix}-jetpack-filled:before { content: $ti-icon-jetpack-filled; }
.#{$ti-prefix}-jewish-star:before { content: $ti-icon-jewish-star; }
.#{$ti-prefix}-jewish-star-filled:before { content: $ti-icon-jewish-star-filled; }
.#{$ti-prefix}-join-bevel:before { content: $ti-icon-join-bevel; }
.#{$ti-prefix}-join-round:before { content: $ti-icon-join-round; }
.#{$ti-prefix}-join-straight:before { content: $ti-icon-join-straight; }
.#{$ti-prefix}-joker:before { content: $ti-icon-joker; }
.#{$ti-prefix}-jpg:before { content: $ti-icon-jpg; }
.#{$ti-prefix}-json:before { content: $ti-icon-json; }
.#{$ti-prefix}-jump-rope:before { content: $ti-icon-jump-rope; }
.#{$ti-prefix}-karate:before { content: $ti-icon-karate; }
.#{$ti-prefix}-kayak:before { content: $ti-icon-kayak; }
.#{$ti-prefix}-kerning:before { content: $ti-icon-kerning; }
.#{$ti-prefix}-key:before { content: $ti-icon-key; }
.#{$ti-prefix}-key-filled:before { content: $ti-icon-key-filled; }
.#{$ti-prefix}-key-off:before { content: $ti-icon-key-off; }
.#{$ti-prefix}-keyboard:before { content: $ti-icon-keyboard; }
.#{$ti-prefix}-keyboard-hide:before { content: $ti-icon-keyboard-hide; }
.#{$ti-prefix}-keyboard-off:before { content: $ti-icon-keyboard-off; }
.#{$ti-prefix}-keyboard-show:before { content: $ti-icon-keyboard-show; }
.#{$ti-prefix}-keyframe:before { content: $ti-icon-keyframe; }
.#{$ti-prefix}-keyframe-align-center:before { content: $ti-icon-keyframe-align-center; }
.#{$ti-prefix}-keyframe-align-center-filled:before { content: $ti-icon-keyframe-align-center-filled; }
.#{$ti-prefix}-keyframe-align-horizontal:before { content: $ti-icon-keyframe-align-horizontal; }
.#{$ti-prefix}-keyframe-align-horizontal-filled:before { content: $ti-icon-keyframe-align-horizontal-filled; }
.#{$ti-prefix}-keyframe-align-vertical:before { content: $ti-icon-keyframe-align-vertical; }
.#{$ti-prefix}-keyframe-align-vertical-filled:before { content: $ti-icon-keyframe-align-vertical-filled; }
.#{$ti-prefix}-keyframe-filled:before { content: $ti-icon-keyframe-filled; }
.#{$ti-prefix}-keyframes:before { content: $ti-icon-keyframes; }
.#{$ti-prefix}-keyframes-filled:before { content: $ti-icon-keyframes-filled; }
.#{$ti-prefix}-label:before { content: $ti-icon-label; }
.#{$ti-prefix}-label-filled:before { content: $ti-icon-label-filled; }
.#{$ti-prefix}-label-important:before { content: $ti-icon-label-important; }
.#{$ti-prefix}-label-important-filled:before { content: $ti-icon-label-important-filled; }
.#{$ti-prefix}-label-off:before { content: $ti-icon-label-off; }
.#{$ti-prefix}-ladder:before { content: $ti-icon-ladder; }
.#{$ti-prefix}-ladder-off:before { content: $ti-icon-ladder-off; }
.#{$ti-prefix}-ladle:before { content: $ti-icon-ladle; }
.#{$ti-prefix}-lambda:before { content: $ti-icon-lambda; }
.#{$ti-prefix}-lamp:before { content: $ti-icon-lamp; }
.#{$ti-prefix}-lamp-2:before { content: $ti-icon-lamp-2; }
.#{$ti-prefix}-lamp-off:before { content: $ti-icon-lamp-off; }
.#{$ti-prefix}-lane:before { content: $ti-icon-lane; }
.#{$ti-prefix}-language:before { content: $ti-icon-language; }
.#{$ti-prefix}-language-hiragana:before { content: $ti-icon-language-hiragana; }
.#{$ti-prefix}-language-katakana:before { content: $ti-icon-language-katakana; }
.#{$ti-prefix}-language-off:before { content: $ti-icon-language-off; }
.#{$ti-prefix}-lasso:before { content: $ti-icon-lasso; }
.#{$ti-prefix}-lasso-off:before { content: $ti-icon-lasso-off; }
.#{$ti-prefix}-lasso-polygon:before { content: $ti-icon-lasso-polygon; }
.#{$ti-prefix}-lasso-polygon-filled:before { content: $ti-icon-lasso-polygon-filled; }
.#{$ti-prefix}-laurel-wreath:before { content: $ti-icon-laurel-wreath; }
.#{$ti-prefix}-laurel-wreath-1:before { content: $ti-icon-laurel-wreath-1; }
.#{$ti-prefix}-laurel-wreath-2:before { content: $ti-icon-laurel-wreath-2; }
.#{$ti-prefix}-laurel-wreath-3:before { content: $ti-icon-laurel-wreath-3; }
.#{$ti-prefix}-layers-difference:before { content: $ti-icon-layers-difference; }
.#{$ti-prefix}-layers-intersect:before { content: $ti-icon-layers-intersect; }
.#{$ti-prefix}-layers-intersect-2:before { content: $ti-icon-layers-intersect-2; }
.#{$ti-prefix}-layers-linked:before { content: $ti-icon-layers-linked; }
.#{$ti-prefix}-layers-off:before { content: $ti-icon-layers-off; }
.#{$ti-prefix}-layers-selected:before { content: $ti-icon-layers-selected; }
.#{$ti-prefix}-layers-selected-bottom:before { content: $ti-icon-layers-selected-bottom; }
.#{$ti-prefix}-layers-subtract:before { content: $ti-icon-layers-subtract; }
.#{$ti-prefix}-layers-union:before { content: $ti-icon-layers-union; }
.#{$ti-prefix}-layout:before { content: $ti-icon-layout; }
.#{$ti-prefix}-layout-2:before { content: $ti-icon-layout-2; }
.#{$ti-prefix}-layout-2-filled:before { content: $ti-icon-layout-2-filled; }
.#{$ti-prefix}-layout-align-bottom:before { content: $ti-icon-layout-align-bottom; }
.#{$ti-prefix}-layout-align-bottom-filled:before { content: $ti-icon-layout-align-bottom-filled; }
.#{$ti-prefix}-layout-align-center:before { content: $ti-icon-layout-align-center; }
.#{$ti-prefix}-layout-align-center-filled:before { content: $ti-icon-layout-align-center-filled; }
.#{$ti-prefix}-layout-align-left:before { content: $ti-icon-layout-align-left; }
.#{$ti-prefix}-layout-align-left-filled:before { content: $ti-icon-layout-align-left-filled; }
.#{$ti-prefix}-layout-align-middle:before { content: $ti-icon-layout-align-middle; }
.#{$ti-prefix}-layout-align-middle-filled:before { content: $ti-icon-layout-align-middle-filled; }
.#{$ti-prefix}-layout-align-right:before { content: $ti-icon-layout-align-right; }
.#{$ti-prefix}-layout-align-right-filled:before { content: $ti-icon-layout-align-right-filled; }
.#{$ti-prefix}-layout-align-top:before { content: $ti-icon-layout-align-top; }
.#{$ti-prefix}-layout-align-top-filled:before { content: $ti-icon-layout-align-top-filled; }
.#{$ti-prefix}-layout-board:before { content: $ti-icon-layout-board; }
.#{$ti-prefix}-layout-board-split:before { content: $ti-icon-layout-board-split; }
.#{$ti-prefix}-layout-bottombar:before { content: $ti-icon-layout-bottombar; }
.#{$ti-prefix}-layout-bottombar-collapse:before { content: $ti-icon-layout-bottombar-collapse; }
.#{$ti-prefix}-layout-bottombar-collapse-filled:before { content: $ti-icon-layout-bottombar-collapse-filled; }
.#{$ti-prefix}-layout-bottombar-expand:before { content: $ti-icon-layout-bottombar-expand; }
.#{$ti-prefix}-layout-bottombar-expand-filled:before { content: $ti-icon-layout-bottombar-expand-filled; }
.#{$ti-prefix}-layout-bottombar-filled:before { content: $ti-icon-layout-bottombar-filled; }
.#{$ti-prefix}-layout-bottombar-inactive:before { content: $ti-icon-layout-bottombar-inactive; }
.#{$ti-prefix}-layout-cards:before { content: $ti-icon-layout-cards; }
.#{$ti-prefix}-layout-cards-filled:before { content: $ti-icon-layout-cards-filled; }
.#{$ti-prefix}-layout-collage:before { content: $ti-icon-layout-collage; }
.#{$ti-prefix}-layout-columns:before { content: $ti-icon-layout-columns; }
.#{$ti-prefix}-layout-dashboard:before { content: $ti-icon-layout-dashboard; }
.#{$ti-prefix}-layout-dashboard-filled:before { content: $ti-icon-layout-dashboard-filled; }
.#{$ti-prefix}-layout-distribute-horizontal:before { content: $ti-icon-layout-distribute-horizontal; }
.#{$ti-prefix}-layout-distribute-horizontal-filled:before { content: $ti-icon-layout-distribute-horizontal-filled; }
.#{$ti-prefix}-layout-distribute-vertical:before { content: $ti-icon-layout-distribute-vertical; }
.#{$ti-prefix}-layout-distribute-vertical-filled:before { content: $ti-icon-layout-distribute-vertical-filled; }
.#{$ti-prefix}-layout-filled:before { content: $ti-icon-layout-filled; }
.#{$ti-prefix}-layout-grid:before { content: $ti-icon-layout-grid; }
.#{$ti-prefix}-layout-grid-add:before { content: $ti-icon-layout-grid-add; }
.#{$ti-prefix}-layout-grid-filled:before { content: $ti-icon-layout-grid-filled; }
.#{$ti-prefix}-layout-grid-remove:before { content: $ti-icon-layout-grid-remove; }
.#{$ti-prefix}-layout-kanban:before { content: $ti-icon-layout-kanban; }
.#{$ti-prefix}-layout-kanban-filled:before { content: $ti-icon-layout-kanban-filled; }
.#{$ti-prefix}-layout-list:before { content: $ti-icon-layout-list; }
.#{$ti-prefix}-layout-list-filled:before { content: $ti-icon-layout-list-filled; }
.#{$ti-prefix}-layout-navbar:before { content: $ti-icon-layout-navbar; }
.#{$ti-prefix}-layout-navbar-collapse:before { content: $ti-icon-layout-navbar-collapse; }
.#{$ti-prefix}-layout-navbar-collapse-filled:before { content: $ti-icon-layout-navbar-collapse-filled; }
.#{$ti-prefix}-layout-navbar-expand:before { content: $ti-icon-layout-navbar-expand; }
.#{$ti-prefix}-layout-navbar-expand-filled:before { content: $ti-icon-layout-navbar-expand-filled; }
.#{$ti-prefix}-layout-navbar-filled:before { content: $ti-icon-layout-navbar-filled; }
.#{$ti-prefix}-layout-navbar-inactive:before { content: $ti-icon-layout-navbar-inactive; }
.#{$ti-prefix}-layout-off:before { content: $ti-icon-layout-off; }
.#{$ti-prefix}-layout-rows:before { content: $ti-icon-layout-rows; }
.#{$ti-prefix}-layout-sidebar:before { content: $ti-icon-layout-sidebar; }
.#{$ti-prefix}-layout-sidebar-filled:before { content: $ti-icon-layout-sidebar-filled; }
.#{$ti-prefix}-layout-sidebar-inactive:before { content: $ti-icon-layout-sidebar-inactive; }
.#{$ti-prefix}-layout-sidebar-left-collapse:before { content: $ti-icon-layout-sidebar-left-collapse; }
.#{$ti-prefix}-layout-sidebar-left-collapse-filled:before { content: $ti-icon-layout-sidebar-left-collapse-filled; }
.#{$ti-prefix}-layout-sidebar-left-expand:before { content: $ti-icon-layout-sidebar-left-expand; }
.#{$ti-prefix}-layout-sidebar-left-expand-filled:before { content: $ti-icon-layout-sidebar-left-expand-filled; }
.#{$ti-prefix}-layout-sidebar-right:before { content: $ti-icon-layout-sidebar-right; }
.#{$ti-prefix}-layout-sidebar-right-collapse:before { content: $ti-icon-layout-sidebar-right-collapse; }
.#{$ti-prefix}-layout-sidebar-right-collapse-filled:before { content: $ti-icon-layout-sidebar-right-collapse-filled; }
.#{$ti-prefix}-layout-sidebar-right-expand:before { content: $ti-icon-layout-sidebar-right-expand; }
.#{$ti-prefix}-layout-sidebar-right-expand-filled:before { content: $ti-icon-layout-sidebar-right-expand-filled; }
.#{$ti-prefix}-layout-sidebar-right-filled:before { content: $ti-icon-layout-sidebar-right-filled; }
.#{$ti-prefix}-layout-sidebar-right-inactive:before { content: $ti-icon-layout-sidebar-right-inactive; }
.#{$ti-prefix}-leaf:before { content: $ti-icon-leaf; }
.#{$ti-prefix}-leaf-2:before { content: $ti-icon-leaf-2; }
.#{$ti-prefix}-leaf-off:before { content: $ti-icon-leaf-off; }
.#{$ti-prefix}-lego:before { content: $ti-icon-lego; }
.#{$ti-prefix}-lego-filled:before { content: $ti-icon-lego-filled; }
.#{$ti-prefix}-lego-off:before { content: $ti-icon-lego-off; }
.#{$ti-prefix}-lemon:before { content: $ti-icon-lemon; }
.#{$ti-prefix}-lemon-2:before { content: $ti-icon-lemon-2; }
.#{$ti-prefix}-letter-a:before { content: $ti-icon-letter-a; }
.#{$ti-prefix}-letter-a-small:before { content: $ti-icon-letter-a-small; }
.#{$ti-prefix}-letter-b:before { content: $ti-icon-letter-b; }
.#{$ti-prefix}-letter-b-small:before { content: $ti-icon-letter-b-small; }
.#{$ti-prefix}-letter-c:before { content: $ti-icon-letter-c; }
.#{$ti-prefix}-letter-c-small:before { content: $ti-icon-letter-c-small; }
.#{$ti-prefix}-letter-case:before { content: $ti-icon-letter-case; }
.#{$ti-prefix}-letter-case-lower:before { content: $ti-icon-letter-case-lower; }
.#{$ti-prefix}-letter-case-toggle:before { content: $ti-icon-letter-case-toggle; }
.#{$ti-prefix}-letter-case-upper:before { content: $ti-icon-letter-case-upper; }
.#{$ti-prefix}-letter-d:before { content: $ti-icon-letter-d; }
.#{$ti-prefix}-letter-d-small:before { content: $ti-icon-letter-d-small; }
.#{$ti-prefix}-letter-e:before { content: $ti-icon-letter-e; }
.#{$ti-prefix}-letter-e-small:before { content: $ti-icon-letter-e-small; }
.#{$ti-prefix}-letter-f:before { content: $ti-icon-letter-f; }
.#{$ti-prefix}-letter-f-small:before { content: $ti-icon-letter-f-small; }
.#{$ti-prefix}-letter-g:before { content: $ti-icon-letter-g; }
.#{$ti-prefix}-letter-g-small:before { content: $ti-icon-letter-g-small; }
.#{$ti-prefix}-letter-h:before { content: $ti-icon-letter-h; }
.#{$ti-prefix}-letter-h-small:before { content: $ti-icon-letter-h-small; }
.#{$ti-prefix}-letter-i:before { content: $ti-icon-letter-i; }
.#{$ti-prefix}-letter-i-small:before { content: $ti-icon-letter-i-small; }
.#{$ti-prefix}-letter-j:before { content: $ti-icon-letter-j; }
.#{$ti-prefix}-letter-j-small:before { content: $ti-icon-letter-j-small; }
.#{$ti-prefix}-letter-k:before { content: $ti-icon-letter-k; }
.#{$ti-prefix}-letter-k-small:before { content: $ti-icon-letter-k-small; }
.#{$ti-prefix}-letter-l:before { content: $ti-icon-letter-l; }
.#{$ti-prefix}-letter-l-small:before { content: $ti-icon-letter-l-small; }
.#{$ti-prefix}-letter-m:before { content: $ti-icon-letter-m; }
.#{$ti-prefix}-letter-m-small:before { content: $ti-icon-letter-m-small; }
.#{$ti-prefix}-letter-n:before { content: $ti-icon-letter-n; }
.#{$ti-prefix}-letter-n-small:before { content: $ti-icon-letter-n-small; }
.#{$ti-prefix}-letter-o:before { content: $ti-icon-letter-o; }
.#{$ti-prefix}-letter-o-small:before { content: $ti-icon-letter-o-small; }
.#{$ti-prefix}-letter-p:before { content: $ti-icon-letter-p; }
.#{$ti-prefix}-letter-p-small:before { content: $ti-icon-letter-p-small; }
.#{$ti-prefix}-letter-q:before { content: $ti-icon-letter-q; }
.#{$ti-prefix}-letter-q-small:before { content: $ti-icon-letter-q-small; }
.#{$ti-prefix}-letter-r:before { content: $ti-icon-letter-r; }
.#{$ti-prefix}-letter-r-small:before { content: $ti-icon-letter-r-small; }
.#{$ti-prefix}-letter-s:before { content: $ti-icon-letter-s; }
.#{$ti-prefix}-letter-s-small:before { content: $ti-icon-letter-s-small; }
.#{$ti-prefix}-letter-spacing:before { content: $ti-icon-letter-spacing; }
.#{$ti-prefix}-letter-t:before { content: $ti-icon-letter-t; }
.#{$ti-prefix}-letter-t-small:before { content: $ti-icon-letter-t-small; }
.#{$ti-prefix}-letter-u:before { content: $ti-icon-letter-u; }
.#{$ti-prefix}-letter-u-small:before { content: $ti-icon-letter-u-small; }
.#{$ti-prefix}-letter-v:before { content: $ti-icon-letter-v; }
.#{$ti-prefix}-letter-v-small:before { content: $ti-icon-letter-v-small; }
.#{$ti-prefix}-letter-w:before { content: $ti-icon-letter-w; }
.#{$ti-prefix}-letter-w-small:before { content: $ti-icon-letter-w-small; }
.#{$ti-prefix}-letter-x:before { content: $ti-icon-letter-x; }
.#{$ti-prefix}-letter-x-small:before { content: $ti-icon-letter-x-small; }
.#{$ti-prefix}-letter-y:before { content: $ti-icon-letter-y; }
.#{$ti-prefix}-letter-y-small:before { content: $ti-icon-letter-y-small; }
.#{$ti-prefix}-letter-z:before { content: $ti-icon-letter-z; }
.#{$ti-prefix}-letter-z-small:before { content: $ti-icon-letter-z-small; }
.#{$ti-prefix}-library:before { content: $ti-icon-library; }
.#{$ti-prefix}-library-minus:before { content: $ti-icon-library-minus; }
.#{$ti-prefix}-library-photo:before { content: $ti-icon-library-photo; }
.#{$ti-prefix}-library-plus:before { content: $ti-icon-library-plus; }
.#{$ti-prefix}-license:before { content: $ti-icon-license; }
.#{$ti-prefix}-license-off:before { content: $ti-icon-license-off; }
.#{$ti-prefix}-lifebuoy:before { content: $ti-icon-lifebuoy; }
.#{$ti-prefix}-lifebuoy-off:before { content: $ti-icon-lifebuoy-off; }
.#{$ti-prefix}-lighter:before { content: $ti-icon-lighter; }
.#{$ti-prefix}-line:before { content: $ti-icon-line; }
.#{$ti-prefix}-line-dashed:before { content: $ti-icon-line-dashed; }
.#{$ti-prefix}-line-dotted:before { content: $ti-icon-line-dotted; }
.#{$ti-prefix}-line-height:before { content: $ti-icon-line-height; }
.#{$ti-prefix}-line-scan:before { content: $ti-icon-line-scan; }
.#{$ti-prefix}-link:before { content: $ti-icon-link; }
.#{$ti-prefix}-link-minus:before { content: $ti-icon-link-minus; }
.#{$ti-prefix}-link-off:before { content: $ti-icon-link-off; }
.#{$ti-prefix}-link-plus:before { content: $ti-icon-link-plus; }
.#{$ti-prefix}-list:before { content: $ti-icon-list; }
.#{$ti-prefix}-list-check:before { content: $ti-icon-list-check; }
.#{$ti-prefix}-list-details:before { content: $ti-icon-list-details; }
.#{$ti-prefix}-list-letters:before { content: $ti-icon-list-letters; }
.#{$ti-prefix}-list-numbers:before { content: $ti-icon-list-numbers; }
.#{$ti-prefix}-list-search:before { content: $ti-icon-list-search; }
.#{$ti-prefix}-list-tree:before { content: $ti-icon-list-tree; }
.#{$ti-prefix}-live-photo:before { content: $ti-icon-live-photo; }
.#{$ti-prefix}-live-photo-filled:before { content: $ti-icon-live-photo-filled; }
.#{$ti-prefix}-live-photo-off:before { content: $ti-icon-live-photo-off; }
.#{$ti-prefix}-live-view:before { content: $ti-icon-live-view; }
.#{$ti-prefix}-load-balancer:before { content: $ti-icon-load-balancer; }
.#{$ti-prefix}-loader:before { content: $ti-icon-loader; }
.#{$ti-prefix}-loader-2:before { content: $ti-icon-loader-2; }
.#{$ti-prefix}-loader-3:before { content: $ti-icon-loader-3; }
.#{$ti-prefix}-loader-quarter:before { content: $ti-icon-loader-quarter; }
.#{$ti-prefix}-location:before { content: $ti-icon-location; }
.#{$ti-prefix}-location-bolt:before { content: $ti-icon-location-bolt; }
.#{$ti-prefix}-location-broken:before { content: $ti-icon-location-broken; }
.#{$ti-prefix}-location-cancel:before { content: $ti-icon-location-cancel; }
.#{$ti-prefix}-location-check:before { content: $ti-icon-location-check; }
.#{$ti-prefix}-location-code:before { content: $ti-icon-location-code; }
.#{$ti-prefix}-location-cog:before { content: $ti-icon-location-cog; }
.#{$ti-prefix}-location-discount:before { content: $ti-icon-location-discount; }
.#{$ti-prefix}-location-dollar:before { content: $ti-icon-location-dollar; }
.#{$ti-prefix}-location-down:before { content: $ti-icon-location-down; }
.#{$ti-prefix}-location-exclamation:before { content: $ti-icon-location-exclamation; }
.#{$ti-prefix}-location-filled:before { content: $ti-icon-location-filled; }
.#{$ti-prefix}-location-heart:before { content: $ti-icon-location-heart; }
.#{$ti-prefix}-location-minus:before { content: $ti-icon-location-minus; }
.#{$ti-prefix}-location-off:before { content: $ti-icon-location-off; }
.#{$ti-prefix}-location-pause:before { content: $ti-icon-location-pause; }
.#{$ti-prefix}-location-pin:before { content: $ti-icon-location-pin; }
.#{$ti-prefix}-location-plus:before { content: $ti-icon-location-plus; }
.#{$ti-prefix}-location-question:before { content: $ti-icon-location-question; }
.#{$ti-prefix}-location-search:before { content: $ti-icon-location-search; }
.#{$ti-prefix}-location-share:before { content: $ti-icon-location-share; }
.#{$ti-prefix}-location-star:before { content: $ti-icon-location-star; }
.#{$ti-prefix}-location-up:before { content: $ti-icon-location-up; }
.#{$ti-prefix}-location-x:before { content: $ti-icon-location-x; }
.#{$ti-prefix}-lock:before { content: $ti-icon-lock; }
.#{$ti-prefix}-lock-access:before { content: $ti-icon-lock-access; }
.#{$ti-prefix}-lock-access-off:before { content: $ti-icon-lock-access-off; }
.#{$ti-prefix}-lock-bitcoin:before { content: $ti-icon-lock-bitcoin; }
.#{$ti-prefix}-lock-bolt:before { content: $ti-icon-lock-bolt; }
.#{$ti-prefix}-lock-cancel:before { content: $ti-icon-lock-cancel; }
.#{$ti-prefix}-lock-check:before { content: $ti-icon-lock-check; }
.#{$ti-prefix}-lock-code:before { content: $ti-icon-lock-code; }
.#{$ti-prefix}-lock-cog:before { content: $ti-icon-lock-cog; }
.#{$ti-prefix}-lock-dollar:before { content: $ti-icon-lock-dollar; }
.#{$ti-prefix}-lock-down:before { content: $ti-icon-lock-down; }
.#{$ti-prefix}-lock-exclamation:before { content: $ti-icon-lock-exclamation; }
.#{$ti-prefix}-lock-filled:before { content: $ti-icon-lock-filled; }
.#{$ti-prefix}-lock-heart:before { content: $ti-icon-lock-heart; }
.#{$ti-prefix}-lock-minus:before { content: $ti-icon-lock-minus; }
.#{$ti-prefix}-lock-off:before { content: $ti-icon-lock-off; }
.#{$ti-prefix}-lock-open:before { content: $ti-icon-lock-open; }
.#{$ti-prefix}-lock-open-2:before { content: $ti-icon-lock-open-2; }
.#{$ti-prefix}-lock-open-off:before { content: $ti-icon-lock-open-off; }
.#{$ti-prefix}-lock-password:before { content: $ti-icon-lock-password; }
.#{$ti-prefix}-lock-pause:before { content: $ti-icon-lock-pause; }
.#{$ti-prefix}-lock-pin:before { content: $ti-icon-lock-pin; }
.#{$ti-prefix}-lock-plus:before { content: $ti-icon-lock-plus; }
.#{$ti-prefix}-lock-question:before { content: $ti-icon-lock-question; }
.#{$ti-prefix}-lock-search:before { content: $ti-icon-lock-search; }
.#{$ti-prefix}-lock-share:before { content: $ti-icon-lock-share; }
.#{$ti-prefix}-lock-square:before { content: $ti-icon-lock-square; }
.#{$ti-prefix}-lock-square-rounded:before { content: $ti-icon-lock-square-rounded; }
.#{$ti-prefix}-lock-square-rounded-filled:before { content: $ti-icon-lock-square-rounded-filled; }
.#{$ti-prefix}-lock-star:before { content: $ti-icon-lock-star; }
.#{$ti-prefix}-lock-up:before { content: $ti-icon-lock-up; }
.#{$ti-prefix}-lock-x:before { content: $ti-icon-lock-x; }
.#{$ti-prefix}-logic-and:before { content: $ti-icon-logic-and; }
.#{$ti-prefix}-logic-buffer:before { content: $ti-icon-logic-buffer; }
.#{$ti-prefix}-logic-nand:before { content: $ti-icon-logic-nand; }
.#{$ti-prefix}-logic-nor:before { content: $ti-icon-logic-nor; }
.#{$ti-prefix}-logic-not:before { content: $ti-icon-logic-not; }
.#{$ti-prefix}-logic-or:before { content: $ti-icon-logic-or; }
.#{$ti-prefix}-logic-xnor:before { content: $ti-icon-logic-xnor; }
.#{$ti-prefix}-logic-xor:before { content: $ti-icon-logic-xor; }
.#{$ti-prefix}-login:before { content: $ti-icon-login; }
.#{$ti-prefix}-login-2:before { content: $ti-icon-login-2; }
.#{$ti-prefix}-logout:before { content: $ti-icon-logout; }
.#{$ti-prefix}-logout-2:before { content: $ti-icon-logout-2; }
.#{$ti-prefix}-logs:before { content: $ti-icon-logs; }
.#{$ti-prefix}-lollipop:before { content: $ti-icon-lollipop; }
.#{$ti-prefix}-lollipop-off:before { content: $ti-icon-lollipop-off; }
.#{$ti-prefix}-luggage:before { content: $ti-icon-luggage; }
.#{$ti-prefix}-luggage-off:before { content: $ti-icon-luggage-off; }
.#{$ti-prefix}-lungs:before { content: $ti-icon-lungs; }
.#{$ti-prefix}-lungs-filled:before { content: $ti-icon-lungs-filled; }
.#{$ti-prefix}-lungs-off:before { content: $ti-icon-lungs-off; }
.#{$ti-prefix}-macro:before { content: $ti-icon-macro; }
.#{$ti-prefix}-macro-filled:before { content: $ti-icon-macro-filled; }
.#{$ti-prefix}-macro-off:before { content: $ti-icon-macro-off; }
.#{$ti-prefix}-magnet:before { content: $ti-icon-magnet; }
.#{$ti-prefix}-magnet-filled:before { content: $ti-icon-magnet-filled; }
.#{$ti-prefix}-magnet-off:before { content: $ti-icon-magnet-off; }
.#{$ti-prefix}-magnetic:before { content: $ti-icon-magnetic; }
.#{$ti-prefix}-mail:before { content: $ti-icon-mail; }
.#{$ti-prefix}-mail-ai:before { content: $ti-icon-mail-ai; }
.#{$ti-prefix}-mail-bitcoin:before { content: $ti-icon-mail-bitcoin; }
.#{$ti-prefix}-mail-bolt:before { content: $ti-icon-mail-bolt; }
.#{$ti-prefix}-mail-cancel:before { content: $ti-icon-mail-cancel; }
.#{$ti-prefix}-mail-check:before { content: $ti-icon-mail-check; }
.#{$ti-prefix}-mail-code:before { content: $ti-icon-mail-code; }
.#{$ti-prefix}-mail-cog:before { content: $ti-icon-mail-cog; }
.#{$ti-prefix}-mail-dollar:before { content: $ti-icon-mail-dollar; }
.#{$ti-prefix}-mail-down:before { content: $ti-icon-mail-down; }
.#{$ti-prefix}-mail-exclamation:before { content: $ti-icon-mail-exclamation; }
.#{$ti-prefix}-mail-fast:before { content: $ti-icon-mail-fast; }
.#{$ti-prefix}-mail-filled:before { content: $ti-icon-mail-filled; }
.#{$ti-prefix}-mail-forward:before { content: $ti-icon-mail-forward; }
.#{$ti-prefix}-mail-heart:before { content: $ti-icon-mail-heart; }
.#{$ti-prefix}-mail-minus:before { content: $ti-icon-mail-minus; }
.#{$ti-prefix}-mail-off:before { content: $ti-icon-mail-off; }
.#{$ti-prefix}-mail-opened:before { content: $ti-icon-mail-opened; }
.#{$ti-prefix}-mail-opened-filled:before { content: $ti-icon-mail-opened-filled; }
.#{$ti-prefix}-mail-pause:before { content: $ti-icon-mail-pause; }
.#{$ti-prefix}-mail-pin:before { content: $ti-icon-mail-pin; }
.#{$ti-prefix}-mail-plus:before { content: $ti-icon-mail-plus; }
.#{$ti-prefix}-mail-question:before { content: $ti-icon-mail-question; }
.#{$ti-prefix}-mail-search:before { content: $ti-icon-mail-search; }
.#{$ti-prefix}-mail-share:before { content: $ti-icon-mail-share; }
.#{$ti-prefix}-mail-spark:before { content: $ti-icon-mail-spark; }
.#{$ti-prefix}-mail-star:before { content: $ti-icon-mail-star; }
.#{$ti-prefix}-mail-up:before { content: $ti-icon-mail-up; }
.#{$ti-prefix}-mail-x:before { content: $ti-icon-mail-x; }
.#{$ti-prefix}-mailbox:before { content: $ti-icon-mailbox; }
.#{$ti-prefix}-mailbox-off:before { content: $ti-icon-mailbox-off; }
.#{$ti-prefix}-man:before { content: $ti-icon-man; }
.#{$ti-prefix}-man-filled:before { content: $ti-icon-man-filled; }
.#{$ti-prefix}-manual-gearbox:before { content: $ti-icon-manual-gearbox; }
.#{$ti-prefix}-manual-gearbox-filled:before { content: $ti-icon-manual-gearbox-filled; }
.#{$ti-prefix}-map:before { content: $ti-icon-map; }
.#{$ti-prefix}-map-2:before { content: $ti-icon-map-2; }
.#{$ti-prefix}-map-bolt:before { content: $ti-icon-map-bolt; }
.#{$ti-prefix}-map-cancel:before { content: $ti-icon-map-cancel; }
.#{$ti-prefix}-map-check:before { content: $ti-icon-map-check; }
.#{$ti-prefix}-map-code:before { content: $ti-icon-map-code; }
.#{$ti-prefix}-map-cog:before { content: $ti-icon-map-cog; }
.#{$ti-prefix}-map-discount:before { content: $ti-icon-map-discount; }
.#{$ti-prefix}-map-dollar:before { content: $ti-icon-map-dollar; }
.#{$ti-prefix}-map-down:before { content: $ti-icon-map-down; }
.#{$ti-prefix}-map-east:before { content: $ti-icon-map-east; }
.#{$ti-prefix}-map-exclamation:before { content: $ti-icon-map-exclamation; }
.#{$ti-prefix}-map-heart:before { content: $ti-icon-map-heart; }
.#{$ti-prefix}-map-minus:before { content: $ti-icon-map-minus; }
.#{$ti-prefix}-map-north:before { content: $ti-icon-map-north; }
.#{$ti-prefix}-map-off:before { content: $ti-icon-map-off; }
.#{$ti-prefix}-map-pause:before { content: $ti-icon-map-pause; }
.#{$ti-prefix}-map-pin:before { content: $ti-icon-map-pin; }
.#{$ti-prefix}-map-pin-2:before { content: $ti-icon-map-pin-2; }
.#{$ti-prefix}-map-pin-bolt:before { content: $ti-icon-map-pin-bolt; }
.#{$ti-prefix}-map-pin-cancel:before { content: $ti-icon-map-pin-cancel; }
.#{$ti-prefix}-map-pin-check:before { content: $ti-icon-map-pin-check; }
.#{$ti-prefix}-map-pin-code:before { content: $ti-icon-map-pin-code; }
.#{$ti-prefix}-map-pin-cog:before { content: $ti-icon-map-pin-cog; }
.#{$ti-prefix}-map-pin-dollar:before { content: $ti-icon-map-pin-dollar; }
.#{$ti-prefix}-map-pin-down:before { content: $ti-icon-map-pin-down; }
.#{$ti-prefix}-map-pin-exclamation:before { content: $ti-icon-map-pin-exclamation; }
.#{$ti-prefix}-map-pin-filled:before { content: $ti-icon-map-pin-filled; }
.#{$ti-prefix}-map-pin-heart:before { content: $ti-icon-map-pin-heart; }
.#{$ti-prefix}-map-pin-minus:before { content: $ti-icon-map-pin-minus; }
.#{$ti-prefix}-map-pin-off:before { content: $ti-icon-map-pin-off; }
.#{$ti-prefix}-map-pin-pause:before { content: $ti-icon-map-pin-pause; }
.#{$ti-prefix}-map-pin-pin:before { content: $ti-icon-map-pin-pin; }
.#{$ti-prefix}-map-pin-plus:before { content: $ti-icon-map-pin-plus; }
.#{$ti-prefix}-map-pin-question:before { content: $ti-icon-map-pin-question; }
.#{$ti-prefix}-map-pin-search:before { content: $ti-icon-map-pin-search; }
.#{$ti-prefix}-map-pin-share:before { content: $ti-icon-map-pin-share; }
.#{$ti-prefix}-map-pin-star:before { content: $ti-icon-map-pin-star; }
.#{$ti-prefix}-map-pin-up:before { content: $ti-icon-map-pin-up; }
.#{$ti-prefix}-map-pin-x:before { content: $ti-icon-map-pin-x; }
.#{$ti-prefix}-map-pins:before { content: $ti-icon-map-pins; }
.#{$ti-prefix}-map-plus:before { content: $ti-icon-map-plus; }
.#{$ti-prefix}-map-question:before { content: $ti-icon-map-question; }
.#{$ti-prefix}-map-route:before { content: $ti-icon-map-route; }
.#{$ti-prefix}-map-search:before { content: $ti-icon-map-search; }
.#{$ti-prefix}-map-share:before { content: $ti-icon-map-share; }
.#{$ti-prefix}-map-south:before { content: $ti-icon-map-south; }
.#{$ti-prefix}-map-star:before { content: $ti-icon-map-star; }
.#{$ti-prefix}-map-up:before { content: $ti-icon-map-up; }
.#{$ti-prefix}-map-west:before { content: $ti-icon-map-west; }
.#{$ti-prefix}-map-x:before { content: $ti-icon-map-x; }
.#{$ti-prefix}-markdown:before { content: $ti-icon-markdown; }
.#{$ti-prefix}-markdown-off:before { content: $ti-icon-markdown-off; }
.#{$ti-prefix}-marquee:before { content: $ti-icon-marquee; }
.#{$ti-prefix}-marquee-2:before { content: $ti-icon-marquee-2; }
.#{$ti-prefix}-marquee-off:before { content: $ti-icon-marquee-off; }
.#{$ti-prefix}-mars:before { content: $ti-icon-mars; }
.#{$ti-prefix}-mask:before { content: $ti-icon-mask; }
.#{$ti-prefix}-mask-off:before { content: $ti-icon-mask-off; }
.#{$ti-prefix}-masks-theater:before { content: $ti-icon-masks-theater; }
.#{$ti-prefix}-masks-theater-off:before { content: $ti-icon-masks-theater-off; }
.#{$ti-prefix}-massage:before { content: $ti-icon-massage; }
.#{$ti-prefix}-matchstick:before { content: $ti-icon-matchstick; }
.#{$ti-prefix}-math:before { content: $ti-icon-math; }
.#{$ti-prefix}-math-1-divide-2:before { content: $ti-icon-math-1-divide-2; }
.#{$ti-prefix}-math-1-divide-3:before { content: $ti-icon-math-1-divide-3; }
.#{$ti-prefix}-math-avg:before { content: $ti-icon-math-avg; }
.#{$ti-prefix}-math-cos:before { content: $ti-icon-math-cos; }
.#{$ti-prefix}-math-ctg:before { content: $ti-icon-math-ctg; }
.#{$ti-prefix}-math-equal-greater:before { content: $ti-icon-math-equal-greater; }
.#{$ti-prefix}-math-equal-lower:before { content: $ti-icon-math-equal-lower; }
.#{$ti-prefix}-math-function:before { content: $ti-icon-math-function; }
.#{$ti-prefix}-math-function-off:before { content: $ti-icon-math-function-off; }
.#{$ti-prefix}-math-function-y:before { content: $ti-icon-math-function-y; }
.#{$ti-prefix}-math-greater:before { content: $ti-icon-math-greater; }
.#{$ti-prefix}-math-integral:before { content: $ti-icon-math-integral; }
.#{$ti-prefix}-math-integral-x:before { content: $ti-icon-math-integral-x; }
.#{$ti-prefix}-math-integrals:before { content: $ti-icon-math-integrals; }
.#{$ti-prefix}-math-lower:before { content: $ti-icon-math-lower; }
.#{$ti-prefix}-math-max:before { content: $ti-icon-math-max; }
.#{$ti-prefix}-math-max-min:before { content: $ti-icon-math-max-min; }
.#{$ti-prefix}-math-min:before { content: $ti-icon-math-min; }
.#{$ti-prefix}-math-not:before { content: $ti-icon-math-not; }
.#{$ti-prefix}-math-off:before { content: $ti-icon-math-off; }
.#{$ti-prefix}-math-pi:before { content: $ti-icon-math-pi; }
.#{$ti-prefix}-math-pi-divide-2:before { content: $ti-icon-math-pi-divide-2; }
.#{$ti-prefix}-math-sec:before { content: $ti-icon-math-sec; }
.#{$ti-prefix}-math-sin:before { content: $ti-icon-math-sin; }
.#{$ti-prefix}-math-symbols:before { content: $ti-icon-math-symbols; }
.#{$ti-prefix}-math-tg:before { content: $ti-icon-math-tg; }
.#{$ti-prefix}-math-x-divide-2:before { content: $ti-icon-math-x-divide-2; }
.#{$ti-prefix}-math-x-divide-y:before { content: $ti-icon-math-x-divide-y; }
.#{$ti-prefix}-math-x-divide-y-2:before { content: $ti-icon-math-x-divide-y-2; }
.#{$ti-prefix}-math-x-floor-divide-y:before { content: $ti-icon-math-x-floor-divide-y; }
.#{$ti-prefix}-math-x-minus-x:before { content: $ti-icon-math-x-minus-x; }
.#{$ti-prefix}-math-x-minus-y:before { content: $ti-icon-math-x-minus-y; }
.#{$ti-prefix}-math-x-plus-x:before { content: $ti-icon-math-x-plus-x; }
.#{$ti-prefix}-math-x-plus-y:before { content: $ti-icon-math-x-plus-y; }
.#{$ti-prefix}-math-xy:before { content: $ti-icon-math-xy; }
.#{$ti-prefix}-math-y-minus-y:before { content: $ti-icon-math-y-minus-y; }
.#{$ti-prefix}-math-y-plus-y:before { content: $ti-icon-math-y-plus-y; }
.#{$ti-prefix}-maximize:before { content: $ti-icon-maximize; }
.#{$ti-prefix}-maximize-off:before { content: $ti-icon-maximize-off; }
.#{$ti-prefix}-meat:before { content: $ti-icon-meat; }
.#{$ti-prefix}-meat-off:before { content: $ti-icon-meat-off; }
.#{$ti-prefix}-medal:before { content: $ti-icon-medal; }
.#{$ti-prefix}-medal-2:before { content: $ti-icon-medal-2; }
.#{$ti-prefix}-medical-cross:before { content: $ti-icon-medical-cross; }
.#{$ti-prefix}-medical-cross-circle:before { content: $ti-icon-medical-cross-circle; }
.#{$ti-prefix}-medical-cross-filled:before { content: $ti-icon-medical-cross-filled; }
.#{$ti-prefix}-medical-cross-off:before { content: $ti-icon-medical-cross-off; }
.#{$ti-prefix}-medicine-syrup:before { content: $ti-icon-medicine-syrup; }
.#{$ti-prefix}-meeple:before { content: $ti-icon-meeple; }
.#{$ti-prefix}-melon:before { content: $ti-icon-melon; }
.#{$ti-prefix}-melon-filled:before { content: $ti-icon-melon-filled; }
.#{$ti-prefix}-menorah:before { content: $ti-icon-menorah; }
.#{$ti-prefix}-menu:before { content: $ti-icon-menu; }
.#{$ti-prefix}-menu-2:before { content: $ti-icon-menu-2; }
.#{$ti-prefix}-menu-3:before { content: $ti-icon-menu-3; }
.#{$ti-prefix}-menu-4:before { content: $ti-icon-menu-4; }
.#{$ti-prefix}-menu-deep:before { content: $ti-icon-menu-deep; }
.#{$ti-prefix}-menu-order:before { content: $ti-icon-menu-order; }
.#{$ti-prefix}-message:before { content: $ti-icon-message; }
.#{$ti-prefix}-message-2:before { content: $ti-icon-message-2; }
.#{$ti-prefix}-message-2-bolt:before { content: $ti-icon-message-2-bolt; }
.#{$ti-prefix}-message-2-cancel:before { content: $ti-icon-message-2-cancel; }
.#{$ti-prefix}-message-2-check:before { content: $ti-icon-message-2-check; }
.#{$ti-prefix}-message-2-code:before { content: $ti-icon-message-2-code; }
.#{$ti-prefix}-message-2-cog:before { content: $ti-icon-message-2-cog; }
.#{$ti-prefix}-message-2-dollar:before { content: $ti-icon-message-2-dollar; }
.#{$ti-prefix}-message-2-down:before { content: $ti-icon-message-2-down; }
.#{$ti-prefix}-message-2-exclamation:before { content: $ti-icon-message-2-exclamation; }
.#{$ti-prefix}-message-2-heart:before { content: $ti-icon-message-2-heart; }
.#{$ti-prefix}-message-2-minus:before { content: $ti-icon-message-2-minus; }
.#{$ti-prefix}-message-2-off:before { content: $ti-icon-message-2-off; }
.#{$ti-prefix}-message-2-pause:before { content: $ti-icon-message-2-pause; }
.#{$ti-prefix}-message-2-pin:before { content: $ti-icon-message-2-pin; }
.#{$ti-prefix}-message-2-plus:before { content: $ti-icon-message-2-plus; }
.#{$ti-prefix}-message-2-question:before { content: $ti-icon-message-2-question; }
.#{$ti-prefix}-message-2-search:before { content: $ti-icon-message-2-search; }
.#{$ti-prefix}-message-2-share:before { content: $ti-icon-message-2-share; }
.#{$ti-prefix}-message-2-star:before { content: $ti-icon-message-2-star; }
.#{$ti-prefix}-message-2-up:before { content: $ti-icon-message-2-up; }
.#{$ti-prefix}-message-2-x:before { content: $ti-icon-message-2-x; }
.#{$ti-prefix}-message-bolt:before { content: $ti-icon-message-bolt; }
.#{$ti-prefix}-message-cancel:before { content: $ti-icon-message-cancel; }
.#{$ti-prefix}-message-chatbot:before { content: $ti-icon-message-chatbot; }
.#{$ti-prefix}-message-chatbot-filled:before { content: $ti-icon-message-chatbot-filled; }
.#{$ti-prefix}-message-check:before { content: $ti-icon-message-check; }
.#{$ti-prefix}-message-circle:before { content: $ti-icon-message-circle; }
.#{$ti-prefix}-message-circle-bolt:before { content: $ti-icon-message-circle-bolt; }
.#{$ti-prefix}-message-circle-cancel:before { content: $ti-icon-message-circle-cancel; }
.#{$ti-prefix}-message-circle-check:before { content: $ti-icon-message-circle-check; }
.#{$ti-prefix}-message-circle-code:before { content: $ti-icon-message-circle-code; }
.#{$ti-prefix}-message-circle-cog:before { content: $ti-icon-message-circle-cog; }
.#{$ti-prefix}-message-circle-dollar:before { content: $ti-icon-message-circle-dollar; }
.#{$ti-prefix}-message-circle-down:before { content: $ti-icon-message-circle-down; }
.#{$ti-prefix}-message-circle-exclamation:before { content: $ti-icon-message-circle-exclamation; }
.#{$ti-prefix}-message-circle-filled:before { content: $ti-icon-message-circle-filled; }
.#{$ti-prefix}-message-circle-heart:before { content: $ti-icon-message-circle-heart; }
.#{$ti-prefix}-message-circle-minus:before { content: $ti-icon-message-circle-minus; }
.#{$ti-prefix}-message-circle-off:before { content: $ti-icon-message-circle-off; }
.#{$ti-prefix}-message-circle-pause:before { content: $ti-icon-message-circle-pause; }
.#{$ti-prefix}-message-circle-pin:before { content: $ti-icon-message-circle-pin; }
.#{$ti-prefix}-message-circle-plus:before { content: $ti-icon-message-circle-plus; }
.#{$ti-prefix}-message-circle-question:before { content: $ti-icon-message-circle-question; }
.#{$ti-prefix}-message-circle-search:before { content: $ti-icon-message-circle-search; }
.#{$ti-prefix}-message-circle-share:before { content: $ti-icon-message-circle-share; }
.#{$ti-prefix}-message-circle-star:before { content: $ti-icon-message-circle-star; }
.#{$ti-prefix}-message-circle-up:before { content: $ti-icon-message-circle-up; }
.#{$ti-prefix}-message-circle-user:before { content: $ti-icon-message-circle-user; }
.#{$ti-prefix}-message-circle-x:before { content: $ti-icon-message-circle-x; }
.#{$ti-prefix}-message-code:before { content: $ti-icon-message-code; }
.#{$ti-prefix}-message-cog:before { content: $ti-icon-message-cog; }
.#{$ti-prefix}-message-dollar:before { content: $ti-icon-message-dollar; }
.#{$ti-prefix}-message-dots:before { content: $ti-icon-message-dots; }
.#{$ti-prefix}-message-down:before { content: $ti-icon-message-down; }
.#{$ti-prefix}-message-exclamation:before { content: $ti-icon-message-exclamation; }
.#{$ti-prefix}-message-filled:before { content: $ti-icon-message-filled; }
.#{$ti-prefix}-message-forward:before { content: $ti-icon-message-forward; }
.#{$ti-prefix}-message-heart:before { content: $ti-icon-message-heart; }
.#{$ti-prefix}-message-language:before { content: $ti-icon-message-language; }
.#{$ti-prefix}-message-minus:before { content: $ti-icon-message-minus; }
.#{$ti-prefix}-message-off:before { content: $ti-icon-message-off; }
.#{$ti-prefix}-message-pause:before { content: $ti-icon-message-pause; }
.#{$ti-prefix}-message-pin:before { content: $ti-icon-message-pin; }
.#{$ti-prefix}-message-plus:before { content: $ti-icon-message-plus; }
.#{$ti-prefix}-message-question:before { content: $ti-icon-message-question; }
.#{$ti-prefix}-message-reply:before { content: $ti-icon-message-reply; }
.#{$ti-prefix}-message-report:before { content: $ti-icon-message-report; }
.#{$ti-prefix}-message-report-filled:before { content: $ti-icon-message-report-filled; }
.#{$ti-prefix}-message-search:before { content: $ti-icon-message-search; }
.#{$ti-prefix}-message-share:before { content: $ti-icon-message-share; }
.#{$ti-prefix}-message-star:before { content: $ti-icon-message-star; }
.#{$ti-prefix}-message-up:before { content: $ti-icon-message-up; }
.#{$ti-prefix}-message-user:before { content: $ti-icon-message-user; }
.#{$ti-prefix}-message-x:before { content: $ti-icon-message-x; }
.#{$ti-prefix}-messages:before { content: $ti-icon-messages; }
.#{$ti-prefix}-messages-off:before { content: $ti-icon-messages-off; }
.#{$ti-prefix}-meteor:before { content: $ti-icon-meteor; }
.#{$ti-prefix}-meteor-filled:before { content: $ti-icon-meteor-filled; }
.#{$ti-prefix}-meteor-off:before { content: $ti-icon-meteor-off; }
.#{$ti-prefix}-meter-cube:before { content: $ti-icon-meter-cube; }
.#{$ti-prefix}-meter-square:before { content: $ti-icon-meter-square; }
.#{$ti-prefix}-metronome:before { content: $ti-icon-metronome; }
.#{$ti-prefix}-michelin-bib-gourmand:before { content: $ti-icon-michelin-bib-gourmand; }
.#{$ti-prefix}-michelin-star:before { content: $ti-icon-michelin-star; }
.#{$ti-prefix}-michelin-star-filled:before { content: $ti-icon-michelin-star-filled; }
.#{$ti-prefix}-michelin-star-green:before { content: $ti-icon-michelin-star-green; }
.#{$ti-prefix}-mickey:before { content: $ti-icon-mickey; }
.#{$ti-prefix}-mickey-filled:before { content: $ti-icon-mickey-filled; }
.#{$ti-prefix}-microphone:before { content: $ti-icon-microphone; }
.#{$ti-prefix}-microphone-2:before { content: $ti-icon-microphone-2; }
.#{$ti-prefix}-microphone-2-off:before { content: $ti-icon-microphone-2-off; }
.#{$ti-prefix}-microphone-filled:before { content: $ti-icon-microphone-filled; }
.#{$ti-prefix}-microphone-off:before { content: $ti-icon-microphone-off; }
.#{$ti-prefix}-microscope:before { content: $ti-icon-microscope; }
.#{$ti-prefix}-microscope-off:before { content: $ti-icon-microscope-off; }
.#{$ti-prefix}-microwave:before { content: $ti-icon-microwave; }
.#{$ti-prefix}-microwave-filled:before { content: $ti-icon-microwave-filled; }
.#{$ti-prefix}-microwave-off:before { content: $ti-icon-microwave-off; }
.#{$ti-prefix}-military-award:before { content: $ti-icon-military-award; }
.#{$ti-prefix}-military-rank:before { content: $ti-icon-military-rank; }
.#{$ti-prefix}-military-rank-filled:before { content: $ti-icon-military-rank-filled; }
.#{$ti-prefix}-milk:before { content: $ti-icon-milk; }
.#{$ti-prefix}-milk-filled:before { content: $ti-icon-milk-filled; }
.#{$ti-prefix}-milk-off:before { content: $ti-icon-milk-off; }
.#{$ti-prefix}-milkshake:before { content: $ti-icon-milkshake; }
.#{$ti-prefix}-minimize:before { content: $ti-icon-minimize; }
.#{$ti-prefix}-minus:before { content: $ti-icon-minus; }
.#{$ti-prefix}-minus-vertical:before { content: $ti-icon-minus-vertical; }
.#{$ti-prefix}-mist:before { content: $ti-icon-mist; }
.#{$ti-prefix}-mist-off:before { content: $ti-icon-mist-off; }
.#{$ti-prefix}-mobiledata:before { content: $ti-icon-mobiledata; }
.#{$ti-prefix}-mobiledata-off:before { content: $ti-icon-mobiledata-off; }
.#{$ti-prefix}-moneybag:before { content: $ti-icon-moneybag; }
.#{$ti-prefix}-monkeybar:before { content: $ti-icon-monkeybar; }
.#{$ti-prefix}-mood-angry:before { content: $ti-icon-mood-angry; }
.#{$ti-prefix}-mood-angry-filled:before { content: $ti-icon-mood-angry-filled; }
.#{$ti-prefix}-mood-annoyed:before { content: $ti-icon-mood-annoyed; }
.#{$ti-prefix}-mood-annoyed-2:before { content: $ti-icon-mood-annoyed-2; }
.#{$ti-prefix}-mood-bitcoin:before { content: $ti-icon-mood-bitcoin; }
.#{$ti-prefix}-mood-boy:before { content: $ti-icon-mood-boy; }
.#{$ti-prefix}-mood-check:before { content: $ti-icon-mood-check; }
.#{$ti-prefix}-mood-cog:before { content: $ti-icon-mood-cog; }
.#{$ti-prefix}-mood-confuzed:before { content: $ti-icon-mood-confuzed; }
.#{$ti-prefix}-mood-confuzed-filled:before { content: $ti-icon-mood-confuzed-filled; }
.#{$ti-prefix}-mood-crazy-happy:before { content: $ti-icon-mood-crazy-happy; }
.#{$ti-prefix}-mood-crazy-happy-filled:before { content: $ti-icon-mood-crazy-happy-filled; }
.#{$ti-prefix}-mood-cry:before { content: $ti-icon-mood-cry; }
.#{$ti-prefix}-mood-dollar:before { content: $ti-icon-mood-dollar; }
.#{$ti-prefix}-mood-edit:before { content: $ti-icon-mood-edit; }
.#{$ti-prefix}-mood-empty:before { content: $ti-icon-mood-empty; }
.#{$ti-prefix}-mood-empty-filled:before { content: $ti-icon-mood-empty-filled; }
.#{$ti-prefix}-mood-happy:before { content: $ti-icon-mood-happy; }
.#{$ti-prefix}-mood-happy-filled:before { content: $ti-icon-mood-happy-filled; }
.#{$ti-prefix}-mood-heart:before { content: $ti-icon-mood-heart; }
.#{$ti-prefix}-mood-kid:before { content: $ti-icon-mood-kid; }
.#{$ti-prefix}-mood-kid-filled:before { content: $ti-icon-mood-kid-filled; }
.#{$ti-prefix}-mood-look-down:before { content: $ti-icon-mood-look-down; }
.#{$ti-prefix}-mood-look-left:before { content: $ti-icon-mood-look-left; }
.#{$ti-prefix}-mood-look-right:before { content: $ti-icon-mood-look-right; }
.#{$ti-prefix}-mood-look-up:before { content: $ti-icon-mood-look-up; }
.#{$ti-prefix}-mood-minus:before { content: $ti-icon-mood-minus; }
.#{$ti-prefix}-mood-nerd:before { content: $ti-icon-mood-nerd; }
.#{$ti-prefix}-mood-nervous:before { content: $ti-icon-mood-nervous; }
.#{$ti-prefix}-mood-neutral:before { content: $ti-icon-mood-neutral; }
.#{$ti-prefix}-mood-neutral-filled:before { content: $ti-icon-mood-neutral-filled; }
.#{$ti-prefix}-mood-off:before { content: $ti-icon-mood-off; }
.#{$ti-prefix}-mood-pin:before { content: $ti-icon-mood-pin; }
.#{$ti-prefix}-mood-plus:before { content: $ti-icon-mood-plus; }
.#{$ti-prefix}-mood-puzzled:before { content: $ti-icon-mood-puzzled; }
.#{$ti-prefix}-mood-sad:before { content: $ti-icon-mood-sad; }
.#{$ti-prefix}-mood-sad-2:before { content: $ti-icon-mood-sad-2; }
.#{$ti-prefix}-mood-sad-dizzy:before { content: $ti-icon-mood-sad-dizzy; }
.#{$ti-prefix}-mood-sad-filled:before { content: $ti-icon-mood-sad-filled; }
.#{$ti-prefix}-mood-sad-squint:before { content: $ti-icon-mood-sad-squint; }
.#{$ti-prefix}-mood-search:before { content: $ti-icon-mood-search; }
.#{$ti-prefix}-mood-share:before { content: $ti-icon-mood-share; }
.#{$ti-prefix}-mood-sick:before { content: $ti-icon-mood-sick; }
.#{$ti-prefix}-mood-silence:before { content: $ti-icon-mood-silence; }
.#{$ti-prefix}-mood-sing:before { content: $ti-icon-mood-sing; }
.#{$ti-prefix}-mood-smile:before { content: $ti-icon-mood-smile; }
.#{$ti-prefix}-mood-smile-beam:before { content: $ti-icon-mood-smile-beam; }
.#{$ti-prefix}-mood-smile-dizzy:before { content: $ti-icon-mood-smile-dizzy; }
.#{$ti-prefix}-mood-smile-filled:before { content: $ti-icon-mood-smile-filled; }
.#{$ti-prefix}-mood-spark:before { content: $ti-icon-mood-spark; }
.#{$ti-prefix}-mood-surprised:before { content: $ti-icon-mood-surprised; }
.#{$ti-prefix}-mood-tongue:before { content: $ti-icon-mood-tongue; }
.#{$ti-prefix}-mood-tongue-wink:before { content: $ti-icon-mood-tongue-wink; }
.#{$ti-prefix}-mood-tongue-wink-2:before { content: $ti-icon-mood-tongue-wink-2; }
.#{$ti-prefix}-mood-unamused:before { content: $ti-icon-mood-unamused; }
.#{$ti-prefix}-mood-up:before { content: $ti-icon-mood-up; }
.#{$ti-prefix}-mood-wink:before { content: $ti-icon-mood-wink; }
.#{$ti-prefix}-mood-wink-2:before { content: $ti-icon-mood-wink-2; }
.#{$ti-prefix}-mood-wrrr:before { content: $ti-icon-mood-wrrr; }
.#{$ti-prefix}-mood-wrrr-filled:before { content: $ti-icon-mood-wrrr-filled; }
.#{$ti-prefix}-mood-x:before { content: $ti-icon-mood-x; }
.#{$ti-prefix}-mood-xd:before { content: $ti-icon-mood-xd; }
.#{$ti-prefix}-moon:before { content: $ti-icon-moon; }
.#{$ti-prefix}-moon-2:before { content: $ti-icon-moon-2; }
.#{$ti-prefix}-moon-filled:before { content: $ti-icon-moon-filled; }
.#{$ti-prefix}-moon-off:before { content: $ti-icon-moon-off; }
.#{$ti-prefix}-moon-stars:before { content: $ti-icon-moon-stars; }
.#{$ti-prefix}-moped:before { content: $ti-icon-moped; }
.#{$ti-prefix}-motorbike:before { content: $ti-icon-motorbike; }
.#{$ti-prefix}-mountain:before { content: $ti-icon-mountain; }
.#{$ti-prefix}-mountain-filled:before { content: $ti-icon-mountain-filled; }
.#{$ti-prefix}-mountain-off:before { content: $ti-icon-mountain-off; }
.#{$ti-prefix}-mouse:before { content: $ti-icon-mouse; }
.#{$ti-prefix}-mouse-2:before { content: $ti-icon-mouse-2; }
.#{$ti-prefix}-mouse-filled:before { content: $ti-icon-mouse-filled; }
.#{$ti-prefix}-mouse-off:before { content: $ti-icon-mouse-off; }
.#{$ti-prefix}-moustache:before { content: $ti-icon-moustache; }
.#{$ti-prefix}-movie:before { content: $ti-icon-movie; }
.#{$ti-prefix}-movie-off:before { content: $ti-icon-movie-off; }
.#{$ti-prefix}-mug:before { content: $ti-icon-mug; }
.#{$ti-prefix}-mug-filled:before { content: $ti-icon-mug-filled; }
.#{$ti-prefix}-mug-off:before { content: $ti-icon-mug-off; }
.#{$ti-prefix}-multiplier-0-5x:before { content: $ti-icon-multiplier-0-5x; }
.#{$ti-prefix}-multiplier-1-5x:before { content: $ti-icon-multiplier-1-5x; }
.#{$ti-prefix}-multiplier-1x:before { content: $ti-icon-multiplier-1x; }
.#{$ti-prefix}-multiplier-2x:before { content: $ti-icon-multiplier-2x; }
.#{$ti-prefix}-mushroom:before { content: $ti-icon-mushroom; }
.#{$ti-prefix}-mushroom-filled:before { content: $ti-icon-mushroom-filled; }
.#{$ti-prefix}-mushroom-off:before { content: $ti-icon-mushroom-off; }
.#{$ti-prefix}-music:before { content: $ti-icon-music; }
.#{$ti-prefix}-music-bolt:before { content: $ti-icon-music-bolt; }
.#{$ti-prefix}-music-cancel:before { content: $ti-icon-music-cancel; }
.#{$ti-prefix}-music-check:before { content: $ti-icon-music-check; }
.#{$ti-prefix}-music-code:before { content: $ti-icon-music-code; }
.#{$ti-prefix}-music-cog:before { content: $ti-icon-music-cog; }
.#{$ti-prefix}-music-discount:before { content: $ti-icon-music-discount; }
.#{$ti-prefix}-music-dollar:before { content: $ti-icon-music-dollar; }
.#{$ti-prefix}-music-down:before { content: $ti-icon-music-down; }
.#{$ti-prefix}-music-exclamation:before { content: $ti-icon-music-exclamation; }
.#{$ti-prefix}-music-heart:before { content: $ti-icon-music-heart; }
.#{$ti-prefix}-music-minus:before { content: $ti-icon-music-minus; }
.#{$ti-prefix}-music-off:before { content: $ti-icon-music-off; }
.#{$ti-prefix}-music-pause:before { content: $ti-icon-music-pause; }
.#{$ti-prefix}-music-pin:before { content: $ti-icon-music-pin; }
.#{$ti-prefix}-music-plus:before { content: $ti-icon-music-plus; }
.#{$ti-prefix}-music-question:before { content: $ti-icon-music-question; }
.#{$ti-prefix}-music-search:before { content: $ti-icon-music-search; }
.#{$ti-prefix}-music-share:before { content: $ti-icon-music-share; }
.#{$ti-prefix}-music-star:before { content: $ti-icon-music-star; }
.#{$ti-prefix}-music-up:before { content: $ti-icon-music-up; }
.#{$ti-prefix}-music-x:before { content: $ti-icon-music-x; }
.#{$ti-prefix}-navigation:before { content: $ti-icon-navigation; }
.#{$ti-prefix}-navigation-bolt:before { content: $ti-icon-navigation-bolt; }
.#{$ti-prefix}-navigation-cancel:before { content: $ti-icon-navigation-cancel; }
.#{$ti-prefix}-navigation-check:before { content: $ti-icon-navigation-check; }
.#{$ti-prefix}-navigation-code:before { content: $ti-icon-navigation-code; }
.#{$ti-prefix}-navigation-cog:before { content: $ti-icon-navigation-cog; }
.#{$ti-prefix}-navigation-discount:before { content: $ti-icon-navigation-discount; }
.#{$ti-prefix}-navigation-dollar:before { content: $ti-icon-navigation-dollar; }
.#{$ti-prefix}-navigation-down:before { content: $ti-icon-navigation-down; }
.#{$ti-prefix}-navigation-east:before { content: $ti-icon-navigation-east; }
.#{$ti-prefix}-navigation-exclamation:before { content: $ti-icon-navigation-exclamation; }
.#{$ti-prefix}-navigation-filled:before { content: $ti-icon-navigation-filled; }
.#{$ti-prefix}-navigation-heart:before { content: $ti-icon-navigation-heart; }
.#{$ti-prefix}-navigation-minus:before { content: $ti-icon-navigation-minus; }
.#{$ti-prefix}-navigation-north:before { content: $ti-icon-navigation-north; }
.#{$ti-prefix}-navigation-off:before { content: $ti-icon-navigation-off; }
.#{$ti-prefix}-navigation-pause:before { content: $ti-icon-navigation-pause; }
.#{$ti-prefix}-navigation-pin:before { content: $ti-icon-navigation-pin; }
.#{$ti-prefix}-navigation-plus:before { content: $ti-icon-navigation-plus; }
.#{$ti-prefix}-navigation-question:before { content: $ti-icon-navigation-question; }
.#{$ti-prefix}-navigation-search:before { content: $ti-icon-navigation-search; }
.#{$ti-prefix}-navigation-share:before { content: $ti-icon-navigation-share; }
.#{$ti-prefix}-navigation-south:before { content: $ti-icon-navigation-south; }
.#{$ti-prefix}-navigation-star:before { content: $ti-icon-navigation-star; }
.#{$ti-prefix}-navigation-top:before { content: $ti-icon-navigation-top; }
.#{$ti-prefix}-navigation-up:before { content: $ti-icon-navigation-up; }
.#{$ti-prefix}-navigation-west:before { content: $ti-icon-navigation-west; }
.#{$ti-prefix}-navigation-x:before { content: $ti-icon-navigation-x; }
.#{$ti-prefix}-needle:before { content: $ti-icon-needle; }
.#{$ti-prefix}-needle-thread:before { content: $ti-icon-needle-thread; }
.#{$ti-prefix}-network:before { content: $ti-icon-network; }
.#{$ti-prefix}-network-off:before { content: $ti-icon-network-off; }
.#{$ti-prefix}-new-section:before { content: $ti-icon-new-section; }
.#{$ti-prefix}-news:before { content: $ti-icon-news; }
.#{$ti-prefix}-news-off:before { content: $ti-icon-news-off; }
.#{$ti-prefix}-nfc:before { content: $ti-icon-nfc; }
.#{$ti-prefix}-nfc-off:before { content: $ti-icon-nfc-off; }
.#{$ti-prefix}-no-copyright:before { content: $ti-icon-no-copyright; }
.#{$ti-prefix}-no-creative-commons:before { content: $ti-icon-no-creative-commons; }
.#{$ti-prefix}-no-derivatives:before { content: $ti-icon-no-derivatives; }
.#{$ti-prefix}-north-star:before { content: $ti-icon-north-star; }
.#{$ti-prefix}-note:before { content: $ti-icon-note; }
.#{$ti-prefix}-note-off:before { content: $ti-icon-note-off; }
.#{$ti-prefix}-notebook:before { content: $ti-icon-notebook; }
.#{$ti-prefix}-notebook-off:before { content: $ti-icon-notebook-off; }
.#{$ti-prefix}-notes:before { content: $ti-icon-notes; }
.#{$ti-prefix}-notes-off:before { content: $ti-icon-notes-off; }
.#{$ti-prefix}-notification:before { content: $ti-icon-notification; }
.#{$ti-prefix}-notification-off:before { content: $ti-icon-notification-off; }
.#{$ti-prefix}-number:before { content: $ti-icon-number; }
.#{$ti-prefix}-number-0:before { content: $ti-icon-number-0; }
.#{$ti-prefix}-number-0-small:before { content: $ti-icon-number-0-small; }
.#{$ti-prefix}-number-1:before { content: $ti-icon-number-1; }
.#{$ti-prefix}-number-1-small:before { content: $ti-icon-number-1-small; }
.#{$ti-prefix}-number-10:before { content: $ti-icon-number-10; }
.#{$ti-prefix}-number-10-small:before { content: $ti-icon-number-10-small; }
.#{$ti-prefix}-number-100-small:before { content: $ti-icon-number-100-small; }
.#{$ti-prefix}-number-11:before { content: $ti-icon-number-11; }
.#{$ti-prefix}-number-11-small:before { content: $ti-icon-number-11-small; }
.#{$ti-prefix}-number-12-small:before { content: $ti-icon-number-12-small; }
.#{$ti-prefix}-number-123:before { content: $ti-icon-number-123; }
.#{$ti-prefix}-number-13-small:before { content: $ti-icon-number-13-small; }
.#{$ti-prefix}-number-14-small:before { content: $ti-icon-number-14-small; }
.#{$ti-prefix}-number-15-small:before { content: $ti-icon-number-15-small; }
.#{$ti-prefix}-number-16-small:before { content: $ti-icon-number-16-small; }
.#{$ti-prefix}-number-17-small:before { content: $ti-icon-number-17-small; }
.#{$ti-prefix}-number-18-small:before { content: $ti-icon-number-18-small; }
.#{$ti-prefix}-number-19-small:before { content: $ti-icon-number-19-small; }
.#{$ti-prefix}-number-2:before { content: $ti-icon-number-2; }
.#{$ti-prefix}-number-2-small:before { content: $ti-icon-number-2-small; }
.#{$ti-prefix}-number-20-small:before { content: $ti-icon-number-20-small; }
.#{$ti-prefix}-number-21-small:before { content: $ti-icon-number-21-small; }
.#{$ti-prefix}-number-22-small:before { content: $ti-icon-number-22-small; }
.#{$ti-prefix}-number-23-small:before { content: $ti-icon-number-23-small; }
.#{$ti-prefix}-number-24-small:before { content: $ti-icon-number-24-small; }
.#{$ti-prefix}-number-25-small:before { content: $ti-icon-number-25-small; }
.#{$ti-prefix}-number-26-small:before { content: $ti-icon-number-26-small; }
.#{$ti-prefix}-number-27-small:before { content: $ti-icon-number-27-small; }
.#{$ti-prefix}-number-28-small:before { content: $ti-icon-number-28-small; }
.#{$ti-prefix}-number-29-small:before { content: $ti-icon-number-29-small; }
.#{$ti-prefix}-number-3:before { content: $ti-icon-number-3; }
.#{$ti-prefix}-number-3-small:before { content: $ti-icon-number-3-small; }
.#{$ti-prefix}-number-30-small:before { content: $ti-icon-number-30-small; }
.#{$ti-prefix}-number-31-small:before { content: $ti-icon-number-31-small; }
.#{$ti-prefix}-number-32-small:before { content: $ti-icon-number-32-small; }
.#{$ti-prefix}-number-33-small:before { content: $ti-icon-number-33-small; }
.#{$ti-prefix}-number-34-small:before { content: $ti-icon-number-34-small; }
.#{$ti-prefix}-number-35-small:before { content: $ti-icon-number-35-small; }
.#{$ti-prefix}-number-36-small:before { content: $ti-icon-number-36-small; }
.#{$ti-prefix}-number-37-small:before { content: $ti-icon-number-37-small; }
.#{$ti-prefix}-number-38-small:before { content: $ti-icon-number-38-small; }
.#{$ti-prefix}-number-39-small:before { content: $ti-icon-number-39-small; }
.#{$ti-prefix}-number-4:before { content: $ti-icon-number-4; }
.#{$ti-prefix}-number-4-small:before { content: $ti-icon-number-4-small; }
.#{$ti-prefix}-number-40-small:before { content: $ti-icon-number-40-small; }
.#{$ti-prefix}-number-41-small:before { content: $ti-icon-number-41-small; }
.#{$ti-prefix}-number-42-small:before { content: $ti-icon-number-42-small; }
.#{$ti-prefix}-number-43-small:before { content: $ti-icon-number-43-small; }
.#{$ti-prefix}-number-44-small:before { content: $ti-icon-number-44-small; }
.#{$ti-prefix}-number-45-small:before { content: $ti-icon-number-45-small; }
.#{$ti-prefix}-number-46-small:before { content: $ti-icon-number-46-small; }
.#{$ti-prefix}-number-47-small:before { content: $ti-icon-number-47-small; }
.#{$ti-prefix}-number-48-small:before { content: $ti-icon-number-48-small; }
.#{$ti-prefix}-number-49-small:before { content: $ti-icon-number-49-small; }
.#{$ti-prefix}-number-5:before { content: $ti-icon-number-5; }
.#{$ti-prefix}-number-5-small:before { content: $ti-icon-number-5-small; }
.#{$ti-prefix}-number-50-small:before { content: $ti-icon-number-50-small; }
.#{$ti-prefix}-number-51-small:before { content: $ti-icon-number-51-small; }
.#{$ti-prefix}-number-52-small:before { content: $ti-icon-number-52-small; }
.#{$ti-prefix}-number-53-small:before { content: $ti-icon-number-53-small; }
.#{$ti-prefix}-number-54-small:before { content: $ti-icon-number-54-small; }
.#{$ti-prefix}-number-55-small:before { content: $ti-icon-number-55-small; }
.#{$ti-prefix}-number-56-small:before { content: $ti-icon-number-56-small; }
.#{$ti-prefix}-number-57-small:before { content: $ti-icon-number-57-small; }
.#{$ti-prefix}-number-58-small:before { content: $ti-icon-number-58-small; }
.#{$ti-prefix}-number-59-small:before { content: $ti-icon-number-59-small; }
.#{$ti-prefix}-number-6:before { content: $ti-icon-number-6; }
.#{$ti-prefix}-number-6-small:before { content: $ti-icon-number-6-small; }
.#{$ti-prefix}-number-60-small:before { content: $ti-icon-number-60-small; }
.#{$ti-prefix}-number-61-small:before { content: $ti-icon-number-61-small; }
.#{$ti-prefix}-number-62-small:before { content: $ti-icon-number-62-small; }
.#{$ti-prefix}-number-63-small:before { content: $ti-icon-number-63-small; }
.#{$ti-prefix}-number-64-small:before { content: $ti-icon-number-64-small; }
.#{$ti-prefix}-number-65-small:before { content: $ti-icon-number-65-small; }
.#{$ti-prefix}-number-66-small:before { content: $ti-icon-number-66-small; }
.#{$ti-prefix}-number-67-small:before { content: $ti-icon-number-67-small; }
.#{$ti-prefix}-number-68-small:before { content: $ti-icon-number-68-small; }
.#{$ti-prefix}-number-69-small:before { content: $ti-icon-number-69-small; }
.#{$ti-prefix}-number-7:before { content: $ti-icon-number-7; }
.#{$ti-prefix}-number-7-small:before { content: $ti-icon-number-7-small; }
.#{$ti-prefix}-number-70-small:before { content: $ti-icon-number-70-small; }
.#{$ti-prefix}-number-71-small:before { content: $ti-icon-number-71-small; }
.#{$ti-prefix}-number-72-small:before { content: $ti-icon-number-72-small; }
.#{$ti-prefix}-number-73-small:before { content: $ti-icon-number-73-small; }
.#{$ti-prefix}-number-74-small:before { content: $ti-icon-number-74-small; }
.#{$ti-prefix}-number-75-small:before { content: $ti-icon-number-75-small; }
.#{$ti-prefix}-number-76-small:before { content: $ti-icon-number-76-small; }
.#{$ti-prefix}-number-77-small:before { content: $ti-icon-number-77-small; }
.#{$ti-prefix}-number-78-small:before { content: $ti-icon-number-78-small; }
.#{$ti-prefix}-number-79-small:before { content: $ti-icon-number-79-small; }
.#{$ti-prefix}-number-8:before { content: $ti-icon-number-8; }
.#{$ti-prefix}-number-8-small:before { content: $ti-icon-number-8-small; }
.#{$ti-prefix}-number-80-small:before { content: $ti-icon-number-80-small; }
.#{$ti-prefix}-number-81-small:before { content: $ti-icon-number-81-small; }
.#{$ti-prefix}-number-82-small:before { content: $ti-icon-number-82-small; }
.#{$ti-prefix}-number-83-small:before { content: $ti-icon-number-83-small; }
.#{$ti-prefix}-number-84-small:before { content: $ti-icon-number-84-small; }
.#{$ti-prefix}-number-85-small:before { content: $ti-icon-number-85-small; }
.#{$ti-prefix}-number-86-small:before { content: $ti-icon-number-86-small; }
.#{$ti-prefix}-number-87-small:before { content: $ti-icon-number-87-small; }
.#{$ti-prefix}-number-88-small:before { content: $ti-icon-number-88-small; }
.#{$ti-prefix}-number-89-small:before { content: $ti-icon-number-89-small; }
.#{$ti-prefix}-number-9:before { content: $ti-icon-number-9; }
.#{$ti-prefix}-number-9-small:before { content: $ti-icon-number-9-small; }
.#{$ti-prefix}-number-90-small:before { content: $ti-icon-number-90-small; }
.#{$ti-prefix}-number-91-small:before { content: $ti-icon-number-91-small; }
.#{$ti-prefix}-number-92-small:before { content: $ti-icon-number-92-small; }
.#{$ti-prefix}-number-93-small:before { content: $ti-icon-number-93-small; }
.#{$ti-prefix}-number-94-small:before { content: $ti-icon-number-94-small; }
.#{$ti-prefix}-number-95-small:before { content: $ti-icon-number-95-small; }
.#{$ti-prefix}-number-96-small:before { content: $ti-icon-number-96-small; }
.#{$ti-prefix}-number-97-small:before { content: $ti-icon-number-97-small; }
.#{$ti-prefix}-number-98-small:before { content: $ti-icon-number-98-small; }
.#{$ti-prefix}-number-99-small:before { content: $ti-icon-number-99-small; }
.#{$ti-prefix}-numbers:before { content: $ti-icon-numbers; }
.#{$ti-prefix}-nurse:before { content: $ti-icon-nurse; }
.#{$ti-prefix}-nut:before { content: $ti-icon-nut; }
.#{$ti-prefix}-object-scan:before { content: $ti-icon-object-scan; }
.#{$ti-prefix}-octagon:before { content: $ti-icon-octagon; }
.#{$ti-prefix}-octagon-filled:before { content: $ti-icon-octagon-filled; }
.#{$ti-prefix}-octagon-minus:before { content: $ti-icon-octagon-minus; }
.#{$ti-prefix}-octagon-minus-2:before { content: $ti-icon-octagon-minus-2; }
.#{$ti-prefix}-octagon-off:before { content: $ti-icon-octagon-off; }
.#{$ti-prefix}-octagon-plus:before { content: $ti-icon-octagon-plus; }
.#{$ti-prefix}-octagon-plus-2:before { content: $ti-icon-octagon-plus-2; }
.#{$ti-prefix}-octahedron:before { content: $ti-icon-octahedron; }
.#{$ti-prefix}-octahedron-off:before { content: $ti-icon-octahedron-off; }
.#{$ti-prefix}-octahedron-plus:before { content: $ti-icon-octahedron-plus; }
.#{$ti-prefix}-old:before { content: $ti-icon-old; }
.#{$ti-prefix}-olympics:before { content: $ti-icon-olympics; }
.#{$ti-prefix}-olympics-off:before { content: $ti-icon-olympics-off; }
.#{$ti-prefix}-om:before { content: $ti-icon-om; }
.#{$ti-prefix}-omega:before { content: $ti-icon-omega; }
.#{$ti-prefix}-outbound:before { content: $ti-icon-outbound; }
.#{$ti-prefix}-outlet:before { content: $ti-icon-outlet; }
.#{$ti-prefix}-oval:before { content: $ti-icon-oval; }
.#{$ti-prefix}-oval-filled:before { content: $ti-icon-oval-filled; }
.#{$ti-prefix}-oval-vertical:before { content: $ti-icon-oval-vertical; }
.#{$ti-prefix}-oval-vertical-filled:before { content: $ti-icon-oval-vertical-filled; }
.#{$ti-prefix}-overline:before { content: $ti-icon-overline; }
.#{$ti-prefix}-package:before { content: $ti-icon-package; }
.#{$ti-prefix}-package-export:before { content: $ti-icon-package-export; }
.#{$ti-prefix}-package-import:before { content: $ti-icon-package-import; }
.#{$ti-prefix}-package-off:before { content: $ti-icon-package-off; }
.#{$ti-prefix}-packages:before { content: $ti-icon-packages; }
.#{$ti-prefix}-pacman:before { content: $ti-icon-pacman; }
.#{$ti-prefix}-page-break:before { content: $ti-icon-page-break; }
.#{$ti-prefix}-paint:before { content: $ti-icon-paint; }
.#{$ti-prefix}-paint-filled:before { content: $ti-icon-paint-filled; }
.#{$ti-prefix}-paint-off:before { content: $ti-icon-paint-off; }
.#{$ti-prefix}-palette:before { content: $ti-icon-palette; }
.#{$ti-prefix}-palette-off:before { content: $ti-icon-palette-off; }
.#{$ti-prefix}-panorama-horizontal:before { content: $ti-icon-panorama-horizontal; }
.#{$ti-prefix}-panorama-horizontal-filled:before { content: $ti-icon-panorama-horizontal-filled; }
.#{$ti-prefix}-panorama-horizontal-off:before { content: $ti-icon-panorama-horizontal-off; }
.#{$ti-prefix}-panorama-vertical:before { content: $ti-icon-panorama-vertical; }
.#{$ti-prefix}-panorama-vertical-filled:before { content: $ti-icon-panorama-vertical-filled; }
.#{$ti-prefix}-panorama-vertical-off:before { content: $ti-icon-panorama-vertical-off; }
.#{$ti-prefix}-paper-bag:before { content: $ti-icon-paper-bag; }
.#{$ti-prefix}-paper-bag-off:before { content: $ti-icon-paper-bag-off; }
.#{$ti-prefix}-paperclip:before { content: $ti-icon-paperclip; }
.#{$ti-prefix}-parachute:before { content: $ti-icon-parachute; }
.#{$ti-prefix}-parachute-off:before { content: $ti-icon-parachute-off; }
.#{$ti-prefix}-parentheses:before { content: $ti-icon-parentheses; }
.#{$ti-prefix}-parentheses-off:before { content: $ti-icon-parentheses-off; }
.#{$ti-prefix}-parking:before { content: $ti-icon-parking; }
.#{$ti-prefix}-parking-circle:before { content: $ti-icon-parking-circle; }
.#{$ti-prefix}-parking-circle-filled:before { content: $ti-icon-parking-circle-filled; }
.#{$ti-prefix}-parking-off:before { content: $ti-icon-parking-off; }
.#{$ti-prefix}-password:before { content: $ti-icon-password; }
.#{$ti-prefix}-password-fingerprint:before { content: $ti-icon-password-fingerprint; }
.#{$ti-prefix}-password-mobile-phone:before { content: $ti-icon-password-mobile-phone; }
.#{$ti-prefix}-password-user:before { content: $ti-icon-password-user; }
.#{$ti-prefix}-paw:before { content: $ti-icon-paw; }
.#{$ti-prefix}-paw-filled:before { content: $ti-icon-paw-filled; }
.#{$ti-prefix}-paw-off:before { content: $ti-icon-paw-off; }
.#{$ti-prefix}-paywall:before { content: $ti-icon-paywall; }
.#{$ti-prefix}-pdf:before { content: $ti-icon-pdf; }
.#{$ti-prefix}-peace:before { content: $ti-icon-peace; }
.#{$ti-prefix}-pencil:before { content: $ti-icon-pencil; }
.#{$ti-prefix}-pencil-bolt:before { content: $ti-icon-pencil-bolt; }
.#{$ti-prefix}-pencil-cancel:before { content: $ti-icon-pencil-cancel; }
.#{$ti-prefix}-pencil-check:before { content: $ti-icon-pencil-check; }
.#{$ti-prefix}-pencil-code:before { content: $ti-icon-pencil-code; }
.#{$ti-prefix}-pencil-cog:before { content: $ti-icon-pencil-cog; }
.#{$ti-prefix}-pencil-discount:before { content: $ti-icon-pencil-discount; }
.#{$ti-prefix}-pencil-dollar:before { content: $ti-icon-pencil-dollar; }
.#{$ti-prefix}-pencil-down:before { content: $ti-icon-pencil-down; }
.#{$ti-prefix}-pencil-exclamation:before { content: $ti-icon-pencil-exclamation; }
.#{$ti-prefix}-pencil-heart:before { content: $ti-icon-pencil-heart; }
.#{$ti-prefix}-pencil-minus:before { content: $ti-icon-pencil-minus; }
.#{$ti-prefix}-pencil-off:before { content: $ti-icon-pencil-off; }
.#{$ti-prefix}-pencil-pause:before { content: $ti-icon-pencil-pause; }
.#{$ti-prefix}-pencil-pin:before { content: $ti-icon-pencil-pin; }
.#{$ti-prefix}-pencil-plus:before { content: $ti-icon-pencil-plus; }
.#{$ti-prefix}-pencil-question:before { content: $ti-icon-pencil-question; }
.#{$ti-prefix}-pencil-search:before { content: $ti-icon-pencil-search; }
.#{$ti-prefix}-pencil-share:before { content: $ti-icon-pencil-share; }
.#{$ti-prefix}-pencil-star:before { content: $ti-icon-pencil-star; }
.#{$ti-prefix}-pencil-up:before { content: $ti-icon-pencil-up; }
.#{$ti-prefix}-pencil-x:before { content: $ti-icon-pencil-x; }
.#{$ti-prefix}-pennant:before { content: $ti-icon-pennant; }
.#{$ti-prefix}-pennant-2:before { content: $ti-icon-pennant-2; }
.#{$ti-prefix}-pennant-2-filled:before { content: $ti-icon-pennant-2-filled; }
.#{$ti-prefix}-pennant-filled:before { content: $ti-icon-pennant-filled; }
.#{$ti-prefix}-pennant-off:before { content: $ti-icon-pennant-off; }
.#{$ti-prefix}-pentagon:before { content: $ti-icon-pentagon; }
.#{$ti-prefix}-pentagon-filled:before { content: $ti-icon-pentagon-filled; }
.#{$ti-prefix}-pentagon-minus:before { content: $ti-icon-pentagon-minus; }
.#{$ti-prefix}-pentagon-number-0:before { content: $ti-icon-pentagon-number-0; }
.#{$ti-prefix}-pentagon-number-1:before { content: $ti-icon-pentagon-number-1; }
.#{$ti-prefix}-pentagon-number-2:before { content: $ti-icon-pentagon-number-2; }
.#{$ti-prefix}-pentagon-number-3:before { content: $ti-icon-pentagon-number-3; }
.#{$ti-prefix}-pentagon-number-4:before { content: $ti-icon-pentagon-number-4; }
.#{$ti-prefix}-pentagon-number-5:before { content: $ti-icon-pentagon-number-5; }
.#{$ti-prefix}-pentagon-number-6:before { content: $ti-icon-pentagon-number-6; }
.#{$ti-prefix}-pentagon-number-7:before { content: $ti-icon-pentagon-number-7; }
.#{$ti-prefix}-pentagon-number-8:before { content: $ti-icon-pentagon-number-8; }
.#{$ti-prefix}-pentagon-number-9:before { content: $ti-icon-pentagon-number-9; }
.#{$ti-prefix}-pentagon-off:before { content: $ti-icon-pentagon-off; }
.#{$ti-prefix}-pentagon-plus:before { content: $ti-icon-pentagon-plus; }
.#{$ti-prefix}-pentagon-x:before { content: $ti-icon-pentagon-x; }
.#{$ti-prefix}-pentagram:before { content: $ti-icon-pentagram; }
.#{$ti-prefix}-pepper:before { content: $ti-icon-pepper; }
.#{$ti-prefix}-pepper-off:before { content: $ti-icon-pepper-off; }
.#{$ti-prefix}-percentage:before { content: $ti-icon-percentage; }
.#{$ti-prefix}-percentage-0:before { content: $ti-icon-percentage-0; }
.#{$ti-prefix}-percentage-10:before { content: $ti-icon-percentage-10; }
.#{$ti-prefix}-percentage-100:before { content: $ti-icon-percentage-100; }
.#{$ti-prefix}-percentage-20:before { content: $ti-icon-percentage-20; }
.#{$ti-prefix}-percentage-25:before { content: $ti-icon-percentage-25; }
.#{$ti-prefix}-percentage-30:before { content: $ti-icon-percentage-30; }
.#{$ti-prefix}-percentage-33:before { content: $ti-icon-percentage-33; }
.#{$ti-prefix}-percentage-40:before { content: $ti-icon-percentage-40; }
.#{$ti-prefix}-percentage-50:before { content: $ti-icon-percentage-50; }
.#{$ti-prefix}-percentage-60:before { content: $ti-icon-percentage-60; }
.#{$ti-prefix}-percentage-66:before { content: $ti-icon-percentage-66; }
.#{$ti-prefix}-percentage-70:before { content: $ti-icon-percentage-70; }
.#{$ti-prefix}-percentage-75:before { content: $ti-icon-percentage-75; }
.#{$ti-prefix}-percentage-80:before { content: $ti-icon-percentage-80; }
.#{$ti-prefix}-percentage-90:before { content: $ti-icon-percentage-90; }
.#{$ti-prefix}-perfume:before { content: $ti-icon-perfume; }
.#{$ti-prefix}-perspective:before { content: $ti-icon-perspective; }
.#{$ti-prefix}-perspective-off:before { content: $ti-icon-perspective-off; }
.#{$ti-prefix}-phone:before { content: $ti-icon-phone; }
.#{$ti-prefix}-phone-call:before { content: $ti-icon-phone-call; }
.#{$ti-prefix}-phone-calling:before { content: $ti-icon-phone-calling; }
.#{$ti-prefix}-phone-check:before { content: $ti-icon-phone-check; }
.#{$ti-prefix}-phone-done:before { content: $ti-icon-phone-done; }
.#{$ti-prefix}-phone-end:before { content: $ti-icon-phone-end; }
.#{$ti-prefix}-phone-filled:before { content: $ti-icon-phone-filled; }
.#{$ti-prefix}-phone-incoming:before { content: $ti-icon-phone-incoming; }
.#{$ti-prefix}-phone-off:before { content: $ti-icon-phone-off; }
.#{$ti-prefix}-phone-outgoing:before { content: $ti-icon-phone-outgoing; }
.#{$ti-prefix}-phone-pause:before { content: $ti-icon-phone-pause; }
.#{$ti-prefix}-phone-plus:before { content: $ti-icon-phone-plus; }
.#{$ti-prefix}-phone-ringing:before { content: $ti-icon-phone-ringing; }
.#{$ti-prefix}-phone-spark:before { content: $ti-icon-phone-spark; }
.#{$ti-prefix}-phone-x:before { content: $ti-icon-phone-x; }
.#{$ti-prefix}-photo:before { content: $ti-icon-photo; }
.#{$ti-prefix}-photo-ai:before { content: $ti-icon-photo-ai; }
.#{$ti-prefix}-photo-bitcoin:before { content: $ti-icon-photo-bitcoin; }
.#{$ti-prefix}-photo-bolt:before { content: $ti-icon-photo-bolt; }
.#{$ti-prefix}-photo-cancel:before { content: $ti-icon-photo-cancel; }
.#{$ti-prefix}-photo-check:before { content: $ti-icon-photo-check; }
.#{$ti-prefix}-photo-circle:before { content: $ti-icon-photo-circle; }
.#{$ti-prefix}-photo-circle-minus:before { content: $ti-icon-photo-circle-minus; }
.#{$ti-prefix}-photo-circle-plus:before { content: $ti-icon-photo-circle-plus; }
.#{$ti-prefix}-photo-code:before { content: $ti-icon-photo-code; }
.#{$ti-prefix}-photo-cog:before { content: $ti-icon-photo-cog; }
.#{$ti-prefix}-photo-dollar:before { content: $ti-icon-photo-dollar; }
.#{$ti-prefix}-photo-down:before { content: $ti-icon-photo-down; }
.#{$ti-prefix}-photo-edit:before { content: $ti-icon-photo-edit; }
.#{$ti-prefix}-photo-exclamation:before { content: $ti-icon-photo-exclamation; }
.#{$ti-prefix}-photo-filled:before { content: $ti-icon-photo-filled; }
.#{$ti-prefix}-photo-heart:before { content: $ti-icon-photo-heart; }
.#{$ti-prefix}-photo-hexagon:before { content: $ti-icon-photo-hexagon; }
.#{$ti-prefix}-photo-minus:before { content: $ti-icon-photo-minus; }
.#{$ti-prefix}-photo-off:before { content: $ti-icon-photo-off; }
.#{$ti-prefix}-photo-pause:before { content: $ti-icon-photo-pause; }
.#{$ti-prefix}-photo-pentagon:before { content: $ti-icon-photo-pentagon; }
.#{$ti-prefix}-photo-pin:before { content: $ti-icon-photo-pin; }
.#{$ti-prefix}-photo-plus:before { content: $ti-icon-photo-plus; }
.#{$ti-prefix}-photo-question:before { content: $ti-icon-photo-question; }
.#{$ti-prefix}-photo-scan:before { content: $ti-icon-photo-scan; }
.#{$ti-prefix}-photo-search:before { content: $ti-icon-photo-search; }
.#{$ti-prefix}-photo-sensor:before { content: $ti-icon-photo-sensor; }
.#{$ti-prefix}-photo-sensor-2:before { content: $ti-icon-photo-sensor-2; }
.#{$ti-prefix}-photo-sensor-3:before { content: $ti-icon-photo-sensor-3; }
.#{$ti-prefix}-photo-share:before { content: $ti-icon-photo-share; }
.#{$ti-prefix}-photo-shield:before { content: $ti-icon-photo-shield; }
.#{$ti-prefix}-photo-spark:before { content: $ti-icon-photo-spark; }
.#{$ti-prefix}-photo-square-rounded:before { content: $ti-icon-photo-square-rounded; }
.#{$ti-prefix}-photo-star:before { content: $ti-icon-photo-star; }
.#{$ti-prefix}-photo-up:before { content: $ti-icon-photo-up; }
.#{$ti-prefix}-photo-video:before { content: $ti-icon-photo-video; }
.#{$ti-prefix}-photo-x:before { content: $ti-icon-photo-x; }
.#{$ti-prefix}-physotherapist:before { content: $ti-icon-physotherapist; }
.#{$ti-prefix}-piano:before { content: $ti-icon-piano; }
.#{$ti-prefix}-pick:before { content: $ti-icon-pick; }
.#{$ti-prefix}-picnic-table:before { content: $ti-icon-picnic-table; }
.#{$ti-prefix}-picture-in-picture:before { content: $ti-icon-picture-in-picture; }
.#{$ti-prefix}-picture-in-picture-filled:before { content: $ti-icon-picture-in-picture-filled; }
.#{$ti-prefix}-picture-in-picture-off:before { content: $ti-icon-picture-in-picture-off; }
.#{$ti-prefix}-picture-in-picture-on:before { content: $ti-icon-picture-in-picture-on; }
.#{$ti-prefix}-picture-in-picture-top:before { content: $ti-icon-picture-in-picture-top; }
.#{$ti-prefix}-picture-in-picture-top-filled:before { content: $ti-icon-picture-in-picture-top-filled; }
.#{$ti-prefix}-pig:before { content: $ti-icon-pig; }
.#{$ti-prefix}-pig-money:before { content: $ti-icon-pig-money; }
.#{$ti-prefix}-pig-off:before { content: $ti-icon-pig-off; }
.#{$ti-prefix}-pilcrow:before { content: $ti-icon-pilcrow; }
.#{$ti-prefix}-pilcrow-left:before { content: $ti-icon-pilcrow-left; }
.#{$ti-prefix}-pilcrow-right:before { content: $ti-icon-pilcrow-right; }
.#{$ti-prefix}-pill:before { content: $ti-icon-pill; }
.#{$ti-prefix}-pill-filled:before { content: $ti-icon-pill-filled; }
.#{$ti-prefix}-pill-off:before { content: $ti-icon-pill-off; }
.#{$ti-prefix}-pills:before { content: $ti-icon-pills; }
.#{$ti-prefix}-pin:before { content: $ti-icon-pin; }
.#{$ti-prefix}-pin-end:before { content: $ti-icon-pin-end; }
.#{$ti-prefix}-pin-filled:before { content: $ti-icon-pin-filled; }
.#{$ti-prefix}-pin-invoke:before { content: $ti-icon-pin-invoke; }
.#{$ti-prefix}-ping-pong:before { content: $ti-icon-ping-pong; }
.#{$ti-prefix}-pinned:before { content: $ti-icon-pinned; }
.#{$ti-prefix}-pinned-filled:before { content: $ti-icon-pinned-filled; }
.#{$ti-prefix}-pinned-off:before { content: $ti-icon-pinned-off; }
.#{$ti-prefix}-pizza:before { content: $ti-icon-pizza; }
.#{$ti-prefix}-pizza-filled:before { content: $ti-icon-pizza-filled; }
.#{$ti-prefix}-pizza-off:before { content: $ti-icon-pizza-off; }
.#{$ti-prefix}-placeholder:before { content: $ti-icon-placeholder; }
.#{$ti-prefix}-plane:before { content: $ti-icon-plane; }
.#{$ti-prefix}-plane-arrival:before { content: $ti-icon-plane-arrival; }
.#{$ti-prefix}-plane-departure:before { content: $ti-icon-plane-departure; }
.#{$ti-prefix}-plane-inflight:before { content: $ti-icon-plane-inflight; }
.#{$ti-prefix}-plane-off:before { content: $ti-icon-plane-off; }
.#{$ti-prefix}-plane-tilt:before { content: $ti-icon-plane-tilt; }
.#{$ti-prefix}-planet:before { content: $ti-icon-planet; }
.#{$ti-prefix}-planet-off:before { content: $ti-icon-planet-off; }
.#{$ti-prefix}-plant:before { content: $ti-icon-plant; }
.#{$ti-prefix}-plant-2:before { content: $ti-icon-plant-2; }
.#{$ti-prefix}-plant-2-off:before { content: $ti-icon-plant-2-off; }
.#{$ti-prefix}-plant-off:before { content: $ti-icon-plant-off; }
.#{$ti-prefix}-play-basketball:before { content: $ti-icon-play-basketball; }
.#{$ti-prefix}-play-card:before { content: $ti-icon-play-card; }
.#{$ti-prefix}-play-card-1:before { content: $ti-icon-play-card-1; }
.#{$ti-prefix}-play-card-1-filled:before { content: $ti-icon-play-card-1-filled; }
.#{$ti-prefix}-play-card-10:before { content: $ti-icon-play-card-10; }
.#{$ti-prefix}-play-card-10-filled:before { content: $ti-icon-play-card-10-filled; }
.#{$ti-prefix}-play-card-2:before { content: $ti-icon-play-card-2; }
.#{$ti-prefix}-play-card-2-filled:before { content: $ti-icon-play-card-2-filled; }
.#{$ti-prefix}-play-card-3:before { content: $ti-icon-play-card-3; }
.#{$ti-prefix}-play-card-3-filled:before { content: $ti-icon-play-card-3-filled; }
.#{$ti-prefix}-play-card-4:before { content: $ti-icon-play-card-4; }
.#{$ti-prefix}-play-card-4-filled:before { content: $ti-icon-play-card-4-filled; }
.#{$ti-prefix}-play-card-5:before { content: $ti-icon-play-card-5; }
.#{$ti-prefix}-play-card-5-filled:before { content: $ti-icon-play-card-5-filled; }
.#{$ti-prefix}-play-card-6:before { content: $ti-icon-play-card-6; }
.#{$ti-prefix}-play-card-6-filled:before { content: $ti-icon-play-card-6-filled; }
.#{$ti-prefix}-play-card-7:before { content: $ti-icon-play-card-7; }
.#{$ti-prefix}-play-card-7-filled:before { content: $ti-icon-play-card-7-filled; }
.#{$ti-prefix}-play-card-8:before { content: $ti-icon-play-card-8; }
.#{$ti-prefix}-play-card-8-filled:before { content: $ti-icon-play-card-8-filled; }
.#{$ti-prefix}-play-card-9:before { content: $ti-icon-play-card-9; }
.#{$ti-prefix}-play-card-9-filled:before { content: $ti-icon-play-card-9-filled; }
.#{$ti-prefix}-play-card-a:before { content: $ti-icon-play-card-a; }
.#{$ti-prefix}-play-card-a-filled:before { content: $ti-icon-play-card-a-filled; }
.#{$ti-prefix}-play-card-j:before { content: $ti-icon-play-card-j; }
.#{$ti-prefix}-play-card-j-filled:before { content: $ti-icon-play-card-j-filled; }
.#{$ti-prefix}-play-card-k:before { content: $ti-icon-play-card-k; }
.#{$ti-prefix}-play-card-k-filled:before { content: $ti-icon-play-card-k-filled; }
.#{$ti-prefix}-play-card-off:before { content: $ti-icon-play-card-off; }
.#{$ti-prefix}-play-card-q:before { content: $ti-icon-play-card-q; }
.#{$ti-prefix}-play-card-q-filled:before { content: $ti-icon-play-card-q-filled; }
.#{$ti-prefix}-play-card-star:before { content: $ti-icon-play-card-star; }
.#{$ti-prefix}-play-card-star-filled:before { content: $ti-icon-play-card-star-filled; }
.#{$ti-prefix}-play-football:before { content: $ti-icon-play-football; }
.#{$ti-prefix}-play-handball:before { content: $ti-icon-play-handball; }
.#{$ti-prefix}-play-volleyball:before { content: $ti-icon-play-volleyball; }
.#{$ti-prefix}-player-eject:before { content: $ti-icon-player-eject; }
.#{$ti-prefix}-player-eject-filled:before { content: $ti-icon-player-eject-filled; }
.#{$ti-prefix}-player-pause:before { content: $ti-icon-player-pause; }
.#{$ti-prefix}-player-pause-filled:before { content: $ti-icon-player-pause-filled; }
.#{$ti-prefix}-player-play:before { content: $ti-icon-player-play; }
.#{$ti-prefix}-player-play-filled:before { content: $ti-icon-player-play-filled; }
.#{$ti-prefix}-player-record:before { content: $ti-icon-player-record; }
.#{$ti-prefix}-player-record-filled:before { content: $ti-icon-player-record-filled; }
.#{$ti-prefix}-player-skip-back:before { content: $ti-icon-player-skip-back; }
.#{$ti-prefix}-player-skip-back-filled:before { content: $ti-icon-player-skip-back-filled; }
.#{$ti-prefix}-player-skip-forward:before { content: $ti-icon-player-skip-forward; }
.#{$ti-prefix}-player-skip-forward-filled:before { content: $ti-icon-player-skip-forward-filled; }
.#{$ti-prefix}-player-stop:before { content: $ti-icon-player-stop; }
.#{$ti-prefix}-player-stop-filled:before { content: $ti-icon-player-stop-filled; }
.#{$ti-prefix}-player-track-next:before { content: $ti-icon-player-track-next; }
.#{$ti-prefix}-player-track-next-filled:before { content: $ti-icon-player-track-next-filled; }
.#{$ti-prefix}-player-track-prev:before { content: $ti-icon-player-track-prev; }
.#{$ti-prefix}-player-track-prev-filled:before { content: $ti-icon-player-track-prev-filled; }
.#{$ti-prefix}-playlist:before { content: $ti-icon-playlist; }
.#{$ti-prefix}-playlist-add:before { content: $ti-icon-playlist-add; }
.#{$ti-prefix}-playlist-off:before { content: $ti-icon-playlist-off; }
.#{$ti-prefix}-playlist-x:before { content: $ti-icon-playlist-x; }
.#{$ti-prefix}-playstation-circle:before { content: $ti-icon-playstation-circle; }
.#{$ti-prefix}-playstation-square:before { content: $ti-icon-playstation-square; }
.#{$ti-prefix}-playstation-triangle:before { content: $ti-icon-playstation-triangle; }
.#{$ti-prefix}-playstation-x:before { content: $ti-icon-playstation-x; }
.#{$ti-prefix}-plug:before { content: $ti-icon-plug; }
.#{$ti-prefix}-plug-connected:before { content: $ti-icon-plug-connected; }
.#{$ti-prefix}-plug-connected-x:before { content: $ti-icon-plug-connected-x; }
.#{$ti-prefix}-plug-off:before { content: $ti-icon-plug-off; }
.#{$ti-prefix}-plug-x:before { content: $ti-icon-plug-x; }
.#{$ti-prefix}-plus:before { content: $ti-icon-plus; }
.#{$ti-prefix}-plus-equal:before { content: $ti-icon-plus-equal; }
.#{$ti-prefix}-plus-minus:before { content: $ti-icon-plus-minus; }
.#{$ti-prefix}-png:before { content: $ti-icon-png; }
.#{$ti-prefix}-podium:before { content: $ti-icon-podium; }
.#{$ti-prefix}-podium-off:before { content: $ti-icon-podium-off; }
.#{$ti-prefix}-point:before { content: $ti-icon-point; }
.#{$ti-prefix}-point-filled:before { content: $ti-icon-point-filled; }
.#{$ti-prefix}-point-off:before { content: $ti-icon-point-off; }
.#{$ti-prefix}-pointer:before { content: $ti-icon-pointer; }
.#{$ti-prefix}-pointer-bolt:before { content: $ti-icon-pointer-bolt; }
.#{$ti-prefix}-pointer-cancel:before { content: $ti-icon-pointer-cancel; }
.#{$ti-prefix}-pointer-check:before { content: $ti-icon-pointer-check; }
.#{$ti-prefix}-pointer-code:before { content: $ti-icon-pointer-code; }
.#{$ti-prefix}-pointer-cog:before { content: $ti-icon-pointer-cog; }
.#{$ti-prefix}-pointer-dollar:before { content: $ti-icon-pointer-dollar; }
.#{$ti-prefix}-pointer-down:before { content: $ti-icon-pointer-down; }
.#{$ti-prefix}-pointer-exclamation:before { content: $ti-icon-pointer-exclamation; }
.#{$ti-prefix}-pointer-filled:before { content: $ti-icon-pointer-filled; }
.#{$ti-prefix}-pointer-heart:before { content: $ti-icon-pointer-heart; }
.#{$ti-prefix}-pointer-minus:before { content: $ti-icon-pointer-minus; }
.#{$ti-prefix}-pointer-off:before { content: $ti-icon-pointer-off; }
.#{$ti-prefix}-pointer-pause:before { content: $ti-icon-pointer-pause; }
.#{$ti-prefix}-pointer-pin:before { content: $ti-icon-pointer-pin; }
.#{$ti-prefix}-pointer-plus:before { content: $ti-icon-pointer-plus; }
.#{$ti-prefix}-pointer-question:before { content: $ti-icon-pointer-question; }
.#{$ti-prefix}-pointer-search:before { content: $ti-icon-pointer-search; }
.#{$ti-prefix}-pointer-share:before { content: $ti-icon-pointer-share; }
.#{$ti-prefix}-pointer-star:before { content: $ti-icon-pointer-star; }
.#{$ti-prefix}-pointer-up:before { content: $ti-icon-pointer-up; }
.#{$ti-prefix}-pointer-x:before { content: $ti-icon-pointer-x; }
.#{$ti-prefix}-pokeball:before { content: $ti-icon-pokeball; }
.#{$ti-prefix}-pokeball-off:before { content: $ti-icon-pokeball-off; }
.#{$ti-prefix}-poker-chip:before { content: $ti-icon-poker-chip; }
.#{$ti-prefix}-polaroid:before { content: $ti-icon-polaroid; }
.#{$ti-prefix}-polaroid-filled:before { content: $ti-icon-polaroid-filled; }
.#{$ti-prefix}-polygon:before { content: $ti-icon-polygon; }
.#{$ti-prefix}-polygon-off:before { content: $ti-icon-polygon-off; }
.#{$ti-prefix}-poo:before { content: $ti-icon-poo; }
.#{$ti-prefix}-poo-filled:before { content: $ti-icon-poo-filled; }
.#{$ti-prefix}-pool:before { content: $ti-icon-pool; }
.#{$ti-prefix}-pool-off:before { content: $ti-icon-pool-off; }
.#{$ti-prefix}-power:before { content: $ti-icon-power; }
.#{$ti-prefix}-pray:before { content: $ti-icon-pray; }
.#{$ti-prefix}-premium-rights:before { content: $ti-icon-premium-rights; }
.#{$ti-prefix}-prescription:before { content: $ti-icon-prescription; }
.#{$ti-prefix}-presentation:before { content: $ti-icon-presentation; }
.#{$ti-prefix}-presentation-analytics:before { content: $ti-icon-presentation-analytics; }
.#{$ti-prefix}-presentation-analytics-filled:before { content: $ti-icon-presentation-analytics-filled; }
.#{$ti-prefix}-presentation-filled:before { content: $ti-icon-presentation-filled; }
.#{$ti-prefix}-presentation-off:before { content: $ti-icon-presentation-off; }
.#{$ti-prefix}-printer:before { content: $ti-icon-printer; }
.#{$ti-prefix}-printer-off:before { content: $ti-icon-printer-off; }
.#{$ti-prefix}-prism:before { content: $ti-icon-prism; }
.#{$ti-prefix}-prism-light:before { content: $ti-icon-prism-light; }
.#{$ti-prefix}-prism-off:before { content: $ti-icon-prism-off; }
.#{$ti-prefix}-prism-plus:before { content: $ti-icon-prism-plus; }
.#{$ti-prefix}-prison:before { content: $ti-icon-prison; }
.#{$ti-prefix}-progress:before { content: $ti-icon-progress; }
.#{$ti-prefix}-progress-alert:before { content: $ti-icon-progress-alert; }
.#{$ti-prefix}-progress-bolt:before { content: $ti-icon-progress-bolt; }
.#{$ti-prefix}-progress-check:before { content: $ti-icon-progress-check; }
.#{$ti-prefix}-progress-down:before { content: $ti-icon-progress-down; }
.#{$ti-prefix}-progress-help:before { content: $ti-icon-progress-help; }
.#{$ti-prefix}-progress-x:before { content: $ti-icon-progress-x; }
.#{$ti-prefix}-prompt:before { content: $ti-icon-prompt; }
.#{$ti-prefix}-prong:before { content: $ti-icon-prong; }
.#{$ti-prefix}-propeller:before { content: $ti-icon-propeller; }
.#{$ti-prefix}-propeller-off:before { content: $ti-icon-propeller-off; }
.#{$ti-prefix}-protocol:before { content: $ti-icon-protocol; }
.#{$ti-prefix}-pumpkin-scary:before { content: $ti-icon-pumpkin-scary; }
.#{$ti-prefix}-puzzle:before { content: $ti-icon-puzzle; }
.#{$ti-prefix}-puzzle-2:before { content: $ti-icon-puzzle-2; }
.#{$ti-prefix}-puzzle-filled:before { content: $ti-icon-puzzle-filled; }
.#{$ti-prefix}-puzzle-off:before { content: $ti-icon-puzzle-off; }
.#{$ti-prefix}-pyramid:before { content: $ti-icon-pyramid; }
.#{$ti-prefix}-pyramid-off:before { content: $ti-icon-pyramid-off; }
.#{$ti-prefix}-pyramid-plus:before { content: $ti-icon-pyramid-plus; }
.#{$ti-prefix}-qrcode:before { content: $ti-icon-qrcode; }
.#{$ti-prefix}-qrcode-off:before { content: $ti-icon-qrcode-off; }
.#{$ti-prefix}-question-mark:before { content: $ti-icon-question-mark; }
.#{$ti-prefix}-quote:before { content: $ti-icon-quote; }
.#{$ti-prefix}-quote-off:before { content: $ti-icon-quote-off; }
.#{$ti-prefix}-quotes:before { content: $ti-icon-quotes; }
.#{$ti-prefix}-radar:before { content: $ti-icon-radar; }
.#{$ti-prefix}-radar-2:before { content: $ti-icon-radar-2; }
.#{$ti-prefix}-radar-filled:before { content: $ti-icon-radar-filled; }
.#{$ti-prefix}-radar-off:before { content: $ti-icon-radar-off; }
.#{$ti-prefix}-radio:before { content: $ti-icon-radio; }
.#{$ti-prefix}-radio-off:before { content: $ti-icon-radio-off; }
.#{$ti-prefix}-radioactive:before { content: $ti-icon-radioactive; }
.#{$ti-prefix}-radioactive-filled:before { content: $ti-icon-radioactive-filled; }
.#{$ti-prefix}-radioactive-off:before { content: $ti-icon-radioactive-off; }
.#{$ti-prefix}-radius-bottom-left:before { content: $ti-icon-radius-bottom-left; }
.#{$ti-prefix}-radius-bottom-right:before { content: $ti-icon-radius-bottom-right; }
.#{$ti-prefix}-radius-top-left:before { content: $ti-icon-radius-top-left; }
.#{$ti-prefix}-radius-top-right:before { content: $ti-icon-radius-top-right; }
.#{$ti-prefix}-rainbow:before { content: $ti-icon-rainbow; }
.#{$ti-prefix}-rainbow-off:before { content: $ti-icon-rainbow-off; }
.#{$ti-prefix}-rating-12-plus:before { content: $ti-icon-rating-12-plus; }
.#{$ti-prefix}-rating-14-plus:before { content: $ti-icon-rating-14-plus; }
.#{$ti-prefix}-rating-16-plus:before { content: $ti-icon-rating-16-plus; }
.#{$ti-prefix}-rating-18-plus:before { content: $ti-icon-rating-18-plus; }
.#{$ti-prefix}-rating-21-plus:before { content: $ti-icon-rating-21-plus; }
.#{$ti-prefix}-razor:before { content: $ti-icon-razor; }
.#{$ti-prefix}-razor-electric:before { content: $ti-icon-razor-electric; }
.#{$ti-prefix}-receipt:before { content: $ti-icon-receipt; }
.#{$ti-prefix}-receipt-2:before { content: $ti-icon-receipt-2; }
.#{$ti-prefix}-receipt-bitcoin:before { content: $ti-icon-receipt-bitcoin; }
.#{$ti-prefix}-receipt-dollar:before { content: $ti-icon-receipt-dollar; }
.#{$ti-prefix}-receipt-euro:before { content: $ti-icon-receipt-euro; }
.#{$ti-prefix}-receipt-filled:before { content: $ti-icon-receipt-filled; }
.#{$ti-prefix}-receipt-off:before { content: $ti-icon-receipt-off; }
.#{$ti-prefix}-receipt-pound:before { content: $ti-icon-receipt-pound; }
.#{$ti-prefix}-receipt-refund:before { content: $ti-icon-receipt-refund; }
.#{$ti-prefix}-receipt-rupee:before { content: $ti-icon-receipt-rupee; }
.#{$ti-prefix}-receipt-tax:before { content: $ti-icon-receipt-tax; }
.#{$ti-prefix}-receipt-yen:before { content: $ti-icon-receipt-yen; }
.#{$ti-prefix}-receipt-yuan:before { content: $ti-icon-receipt-yuan; }
.#{$ti-prefix}-recharging:before { content: $ti-icon-recharging; }
.#{$ti-prefix}-record-mail:before { content: $ti-icon-record-mail; }
.#{$ti-prefix}-record-mail-off:before { content: $ti-icon-record-mail-off; }
.#{$ti-prefix}-rectangle:before { content: $ti-icon-rectangle; }
.#{$ti-prefix}-rectangle-filled:before { content: $ti-icon-rectangle-filled; }
.#{$ti-prefix}-rectangle-rounded-bottom:before { content: $ti-icon-rectangle-rounded-bottom; }
.#{$ti-prefix}-rectangle-rounded-top:before { content: $ti-icon-rectangle-rounded-top; }
.#{$ti-prefix}-rectangle-vertical:before { content: $ti-icon-rectangle-vertical; }
.#{$ti-prefix}-rectangle-vertical-filled:before { content: $ti-icon-rectangle-vertical-filled; }
.#{$ti-prefix}-rectangular-prism:before { content: $ti-icon-rectangular-prism; }
.#{$ti-prefix}-rectangular-prism-off:before { content: $ti-icon-rectangular-prism-off; }
.#{$ti-prefix}-rectangular-prism-plus:before { content: $ti-icon-rectangular-prism-plus; }
.#{$ti-prefix}-recycle:before { content: $ti-icon-recycle; }
.#{$ti-prefix}-recycle-off:before { content: $ti-icon-recycle-off; }
.#{$ti-prefix}-refresh:before { content: $ti-icon-refresh; }
.#{$ti-prefix}-refresh-alert:before { content: $ti-icon-refresh-alert; }
.#{$ti-prefix}-refresh-dot:before { content: $ti-icon-refresh-dot; }
.#{$ti-prefix}-refresh-off:before { content: $ti-icon-refresh-off; }
.#{$ti-prefix}-regex:before { content: $ti-icon-regex; }
.#{$ti-prefix}-regex-off:before { content: $ti-icon-regex-off; }
.#{$ti-prefix}-registered:before { content: $ti-icon-registered; }
.#{$ti-prefix}-relation-many-to-many:before { content: $ti-icon-relation-many-to-many; }
.#{$ti-prefix}-relation-many-to-many-filled:before { content: $ti-icon-relation-many-to-many-filled; }
.#{$ti-prefix}-relation-one-to-many:before { content: $ti-icon-relation-one-to-many; }
.#{$ti-prefix}-relation-one-to-many-filled:before { content: $ti-icon-relation-one-to-many-filled; }
.#{$ti-prefix}-relation-one-to-one:before { content: $ti-icon-relation-one-to-one; }
.#{$ti-prefix}-relation-one-to-one-filled:before { content: $ti-icon-relation-one-to-one-filled; }
.#{$ti-prefix}-reload:before { content: $ti-icon-reload; }
.#{$ti-prefix}-reorder:before { content: $ti-icon-reorder; }
.#{$ti-prefix}-repeat:before { content: $ti-icon-repeat; }
.#{$ti-prefix}-repeat-off:before { content: $ti-icon-repeat-off; }
.#{$ti-prefix}-repeat-once:before { content: $ti-icon-repeat-once; }
.#{$ti-prefix}-replace:before { content: $ti-icon-replace; }
.#{$ti-prefix}-replace-filled:before { content: $ti-icon-replace-filled; }
.#{$ti-prefix}-replace-off:before { content: $ti-icon-replace-off; }
.#{$ti-prefix}-report:before { content: $ti-icon-report; }
.#{$ti-prefix}-report-analytics:before { content: $ti-icon-report-analytics; }
.#{$ti-prefix}-report-medical:before { content: $ti-icon-report-medical; }
.#{$ti-prefix}-report-money:before { content: $ti-icon-report-money; }
.#{$ti-prefix}-report-off:before { content: $ti-icon-report-off; }
.#{$ti-prefix}-report-search:before { content: $ti-icon-report-search; }
.#{$ti-prefix}-reserved-line:before { content: $ti-icon-reserved-line; }
.#{$ti-prefix}-resize:before { content: $ti-icon-resize; }
.#{$ti-prefix}-restore:before { content: $ti-icon-restore; }
.#{$ti-prefix}-rewind-backward-10:before { content: $ti-icon-rewind-backward-10; }
.#{$ti-prefix}-rewind-backward-15:before { content: $ti-icon-rewind-backward-15; }
.#{$ti-prefix}-rewind-backward-20:before { content: $ti-icon-rewind-backward-20; }
.#{$ti-prefix}-rewind-backward-30:before { content: $ti-icon-rewind-backward-30; }
.#{$ti-prefix}-rewind-backward-40:before { content: $ti-icon-rewind-backward-40; }
.#{$ti-prefix}-rewind-backward-5:before { content: $ti-icon-rewind-backward-5; }
.#{$ti-prefix}-rewind-backward-50:before { content: $ti-icon-rewind-backward-50; }
.#{$ti-prefix}-rewind-backward-60:before { content: $ti-icon-rewind-backward-60; }
.#{$ti-prefix}-rewind-forward-10:before { content: $ti-icon-rewind-forward-10; }
.#{$ti-prefix}-rewind-forward-15:before { content: $ti-icon-rewind-forward-15; }
.#{$ti-prefix}-rewind-forward-20:before { content: $ti-icon-rewind-forward-20; }
.#{$ti-prefix}-rewind-forward-30:before { content: $ti-icon-rewind-forward-30; }
.#{$ti-prefix}-rewind-forward-40:before { content: $ti-icon-rewind-forward-40; }
.#{$ti-prefix}-rewind-forward-5:before { content: $ti-icon-rewind-forward-5; }
.#{$ti-prefix}-rewind-forward-50:before { content: $ti-icon-rewind-forward-50; }
.#{$ti-prefix}-rewind-forward-60:before { content: $ti-icon-rewind-forward-60; }
.#{$ti-prefix}-ribbon-health:before { content: $ti-icon-ribbon-health; }
.#{$ti-prefix}-rings:before { content: $ti-icon-rings; }
.#{$ti-prefix}-ripple:before { content: $ti-icon-ripple; }
.#{$ti-prefix}-ripple-off:before { content: $ti-icon-ripple-off; }
.#{$ti-prefix}-road:before { content: $ti-icon-road; }
.#{$ti-prefix}-road-off:before { content: $ti-icon-road-off; }
.#{$ti-prefix}-road-sign:before { content: $ti-icon-road-sign; }
.#{$ti-prefix}-robot:before { content: $ti-icon-robot; }
.#{$ti-prefix}-robot-face:before { content: $ti-icon-robot-face; }
.#{$ti-prefix}-robot-off:before { content: $ti-icon-robot-off; }
.#{$ti-prefix}-rocket:before { content: $ti-icon-rocket; }
.#{$ti-prefix}-rocket-off:before { content: $ti-icon-rocket-off; }
.#{$ti-prefix}-roller-skating:before { content: $ti-icon-roller-skating; }
.#{$ti-prefix}-rollercoaster:before { content: $ti-icon-rollercoaster; }
.#{$ti-prefix}-rollercoaster-off:before { content: $ti-icon-rollercoaster-off; }
.#{$ti-prefix}-rosette:before { content: $ti-icon-rosette; }
.#{$ti-prefix}-rosette-discount:before { content: $ti-icon-rosette-discount; }
.#{$ti-prefix}-rosette-discount-check:before { content: $ti-icon-rosette-discount-check; }
.#{$ti-prefix}-rosette-discount-check-filled:before { content: $ti-icon-rosette-discount-check-filled; }
.#{$ti-prefix}-rosette-discount-check-off:before { content: $ti-icon-rosette-discount-check-off; }
.#{$ti-prefix}-rosette-discount-filled:before { content: $ti-icon-rosette-discount-filled; }
.#{$ti-prefix}-rosette-discount-off:before { content: $ti-icon-rosette-discount-off; }
.#{$ti-prefix}-rosette-filled:before { content: $ti-icon-rosette-filled; }
.#{$ti-prefix}-rosette-number-0:before { content: $ti-icon-rosette-number-0; }
.#{$ti-prefix}-rosette-number-1:before { content: $ti-icon-rosette-number-1; }
.#{$ti-prefix}-rosette-number-2:before { content: $ti-icon-rosette-number-2; }
.#{$ti-prefix}-rosette-number-3:before { content: $ti-icon-rosette-number-3; }
.#{$ti-prefix}-rosette-number-4:before { content: $ti-icon-rosette-number-4; }
.#{$ti-prefix}-rosette-number-5:before { content: $ti-icon-rosette-number-5; }
.#{$ti-prefix}-rosette-number-6:before { content: $ti-icon-rosette-number-6; }
.#{$ti-prefix}-rosette-number-7:before { content: $ti-icon-rosette-number-7; }
.#{$ti-prefix}-rosette-number-8:before { content: $ti-icon-rosette-number-8; }
.#{$ti-prefix}-rosette-number-9:before { content: $ti-icon-rosette-number-9; }
.#{$ti-prefix}-rotate:before { content: $ti-icon-rotate; }
.#{$ti-prefix}-rotate-2:before { content: $ti-icon-rotate-2; }
.#{$ti-prefix}-rotate-360:before { content: $ti-icon-rotate-360; }
.#{$ti-prefix}-rotate-3d:before { content: $ti-icon-rotate-3d; }
.#{$ti-prefix}-rotate-clockwise:before { content: $ti-icon-rotate-clockwise; }
.#{$ti-prefix}-rotate-clockwise-2:before { content: $ti-icon-rotate-clockwise-2; }
.#{$ti-prefix}-rotate-dot:before { content: $ti-icon-rotate-dot; }
.#{$ti-prefix}-rotate-rectangle:before { content: $ti-icon-rotate-rectangle; }
.#{$ti-prefix}-route:before { content: $ti-icon-route; }
.#{$ti-prefix}-route-2:before { content: $ti-icon-route-2; }
.#{$ti-prefix}-route-alt-left:before { content: $ti-icon-route-alt-left; }
.#{$ti-prefix}-route-alt-right:before { content: $ti-icon-route-alt-right; }
.#{$ti-prefix}-route-off:before { content: $ti-icon-route-off; }
.#{$ti-prefix}-route-scan:before { content: $ti-icon-route-scan; }
.#{$ti-prefix}-route-square:before { content: $ti-icon-route-square; }
.#{$ti-prefix}-route-square-2:before { content: $ti-icon-route-square-2; }
.#{$ti-prefix}-route-x:before { content: $ti-icon-route-x; }
.#{$ti-prefix}-route-x-2:before { content: $ti-icon-route-x-2; }
.#{$ti-prefix}-router:before { content: $ti-icon-router; }
.#{$ti-prefix}-router-off:before { content: $ti-icon-router-off; }
.#{$ti-prefix}-row-insert-bottom:before { content: $ti-icon-row-insert-bottom; }
.#{$ti-prefix}-row-insert-top:before { content: $ti-icon-row-insert-top; }
.#{$ti-prefix}-row-remove:before { content: $ti-icon-row-remove; }
.#{$ti-prefix}-rss:before { content: $ti-icon-rss; }
.#{$ti-prefix}-rubber-stamp:before { content: $ti-icon-rubber-stamp; }
.#{$ti-prefix}-rubber-stamp-off:before { content: $ti-icon-rubber-stamp-off; }
.#{$ti-prefix}-ruler:before { content: $ti-icon-ruler; }
.#{$ti-prefix}-ruler-2:before { content: $ti-icon-ruler-2; }
.#{$ti-prefix}-ruler-2-off:before { content: $ti-icon-ruler-2-off; }
.#{$ti-prefix}-ruler-3:before { content: $ti-icon-ruler-3; }
.#{$ti-prefix}-ruler-measure:before { content: $ti-icon-ruler-measure; }
.#{$ti-prefix}-ruler-measure-2:before { content: $ti-icon-ruler-measure-2; }
.#{$ti-prefix}-ruler-off:before { content: $ti-icon-ruler-off; }
.#{$ti-prefix}-run:before { content: $ti-icon-run; }
.#{$ti-prefix}-rv-truck:before { content: $ti-icon-rv-truck; }
.#{$ti-prefix}-s-turn-down:before { content: $ti-icon-s-turn-down; }
.#{$ti-prefix}-s-turn-left:before { content: $ti-icon-s-turn-left; }
.#{$ti-prefix}-s-turn-right:before { content: $ti-icon-s-turn-right; }
.#{$ti-prefix}-s-turn-up:before { content: $ti-icon-s-turn-up; }
.#{$ti-prefix}-sailboat:before { content: $ti-icon-sailboat; }
.#{$ti-prefix}-sailboat-2:before { content: $ti-icon-sailboat-2; }
.#{$ti-prefix}-sailboat-off:before { content: $ti-icon-sailboat-off; }
.#{$ti-prefix}-salad:before { content: $ti-icon-salad; }
.#{$ti-prefix}-salad-filled:before { content: $ti-icon-salad-filled; }
.#{$ti-prefix}-salt:before { content: $ti-icon-salt; }
.#{$ti-prefix}-sandbox:before { content: $ti-icon-sandbox; }
.#{$ti-prefix}-satellite:before { content: $ti-icon-satellite; }
.#{$ti-prefix}-satellite-off:before { content: $ti-icon-satellite-off; }
.#{$ti-prefix}-sausage:before { content: $ti-icon-sausage; }
.#{$ti-prefix}-scale:before { content: $ti-icon-scale; }
.#{$ti-prefix}-scale-off:before { content: $ti-icon-scale-off; }
.#{$ti-prefix}-scale-outline:before { content: $ti-icon-scale-outline; }
.#{$ti-prefix}-scale-outline-off:before { content: $ti-icon-scale-outline-off; }
.#{$ti-prefix}-scan:before { content: $ti-icon-scan; }
.#{$ti-prefix}-scan-eye:before { content: $ti-icon-scan-eye; }
.#{$ti-prefix}-scan-position:before { content: $ti-icon-scan-position; }
.#{$ti-prefix}-schema:before { content: $ti-icon-schema; }
.#{$ti-prefix}-schema-off:before { content: $ti-icon-schema-off; }
.#{$ti-prefix}-school:before { content: $ti-icon-school; }
.#{$ti-prefix}-school-bell:before { content: $ti-icon-school-bell; }
.#{$ti-prefix}-school-off:before { content: $ti-icon-school-off; }
.#{$ti-prefix}-scissors:before { content: $ti-icon-scissors; }
.#{$ti-prefix}-scissors-off:before { content: $ti-icon-scissors-off; }
.#{$ti-prefix}-scooter:before { content: $ti-icon-scooter; }
.#{$ti-prefix}-scooter-electric:before { content: $ti-icon-scooter-electric; }
.#{$ti-prefix}-scoreboard:before { content: $ti-icon-scoreboard; }
.#{$ti-prefix}-screen-share:before { content: $ti-icon-screen-share; }
.#{$ti-prefix}-screen-share-off:before { content: $ti-icon-screen-share-off; }
.#{$ti-prefix}-screenshot:before { content: $ti-icon-screenshot; }
.#{$ti-prefix}-scribble:before { content: $ti-icon-scribble; }
.#{$ti-prefix}-scribble-off:before { content: $ti-icon-scribble-off; }
.#{$ti-prefix}-script:before { content: $ti-icon-script; }
.#{$ti-prefix}-script-minus:before { content: $ti-icon-script-minus; }
.#{$ti-prefix}-script-plus:before { content: $ti-icon-script-plus; }
.#{$ti-prefix}-script-x:before { content: $ti-icon-script-x; }
.#{$ti-prefix}-scuba-diving:before { content: $ti-icon-scuba-diving; }
.#{$ti-prefix}-scuba-diving-tank:before { content: $ti-icon-scuba-diving-tank; }
.#{$ti-prefix}-scuba-diving-tank-filled:before { content: $ti-icon-scuba-diving-tank-filled; }
.#{$ti-prefix}-scuba-mask:before { content: $ti-icon-scuba-mask; }
.#{$ti-prefix}-scuba-mask-off:before { content: $ti-icon-scuba-mask-off; }
.#{$ti-prefix}-sdk:before { content: $ti-icon-sdk; }
.#{$ti-prefix}-search:before { content: $ti-icon-search; }
.#{$ti-prefix}-search-off:before { content: $ti-icon-search-off; }
.#{$ti-prefix}-section:before { content: $ti-icon-section; }
.#{$ti-prefix}-section-filled:before { content: $ti-icon-section-filled; }
.#{$ti-prefix}-section-sign:before { content: $ti-icon-section-sign; }
.#{$ti-prefix}-seeding:before { content: $ti-icon-seeding; }
.#{$ti-prefix}-seeding-filled:before { content: $ti-icon-seeding-filled; }
.#{$ti-prefix}-seeding-off:before { content: $ti-icon-seeding-off; }
.#{$ti-prefix}-select:before { content: $ti-icon-select; }
.#{$ti-prefix}-select-all:before { content: $ti-icon-select-all; }
.#{$ti-prefix}-selector:before { content: $ti-icon-selector; }
.#{$ti-prefix}-send:before { content: $ti-icon-send; }
.#{$ti-prefix}-send-2:before { content: $ti-icon-send-2; }
.#{$ti-prefix}-send-off:before { content: $ti-icon-send-off; }
.#{$ti-prefix}-seo:before { content: $ti-icon-seo; }
.#{$ti-prefix}-separator:before { content: $ti-icon-separator; }
.#{$ti-prefix}-separator-horizontal:before { content: $ti-icon-separator-horizontal; }
.#{$ti-prefix}-separator-vertical:before { content: $ti-icon-separator-vertical; }
.#{$ti-prefix}-server:before { content: $ti-icon-server; }
.#{$ti-prefix}-server-2:before { content: $ti-icon-server-2; }
.#{$ti-prefix}-server-bolt:before { content: $ti-icon-server-bolt; }
.#{$ti-prefix}-server-cog:before { content: $ti-icon-server-cog; }
.#{$ti-prefix}-server-off:before { content: $ti-icon-server-off; }
.#{$ti-prefix}-server-spark:before { content: $ti-icon-server-spark; }
.#{$ti-prefix}-servicemark:before { content: $ti-icon-servicemark; }
.#{$ti-prefix}-settings:before { content: $ti-icon-settings; }
.#{$ti-prefix}-settings-2:before { content: $ti-icon-settings-2; }
.#{$ti-prefix}-settings-automation:before { content: $ti-icon-settings-automation; }
.#{$ti-prefix}-settings-bolt:before { content: $ti-icon-settings-bolt; }
.#{$ti-prefix}-settings-cancel:before { content: $ti-icon-settings-cancel; }
.#{$ti-prefix}-settings-check:before { content: $ti-icon-settings-check; }
.#{$ti-prefix}-settings-code:before { content: $ti-icon-settings-code; }
.#{$ti-prefix}-settings-cog:before { content: $ti-icon-settings-cog; }
.#{$ti-prefix}-settings-dollar:before { content: $ti-icon-settings-dollar; }
.#{$ti-prefix}-settings-down:before { content: $ti-icon-settings-down; }
.#{$ti-prefix}-settings-exclamation:before { content: $ti-icon-settings-exclamation; }
.#{$ti-prefix}-settings-filled:before { content: $ti-icon-settings-filled; }
.#{$ti-prefix}-settings-heart:before { content: $ti-icon-settings-heart; }
.#{$ti-prefix}-settings-minus:before { content: $ti-icon-settings-minus; }
.#{$ti-prefix}-settings-off:before { content: $ti-icon-settings-off; }
.#{$ti-prefix}-settings-pause:before { content: $ti-icon-settings-pause; }
.#{$ti-prefix}-settings-pin:before { content: $ti-icon-settings-pin; }
.#{$ti-prefix}-settings-plus:before { content: $ti-icon-settings-plus; }
.#{$ti-prefix}-settings-question:before { content: $ti-icon-settings-question; }
.#{$ti-prefix}-settings-search:before { content: $ti-icon-settings-search; }
.#{$ti-prefix}-settings-share:before { content: $ti-icon-settings-share; }
.#{$ti-prefix}-settings-spark:before { content: $ti-icon-settings-spark; }
.#{$ti-prefix}-settings-star:before { content: $ti-icon-settings-star; }
.#{$ti-prefix}-settings-up:before { content: $ti-icon-settings-up; }
.#{$ti-prefix}-settings-x:before { content: $ti-icon-settings-x; }
.#{$ti-prefix}-shadow:before { content: $ti-icon-shadow; }
.#{$ti-prefix}-shadow-off:before { content: $ti-icon-shadow-off; }
.#{$ti-prefix}-shape:before { content: $ti-icon-shape; }
.#{$ti-prefix}-shape-2:before { content: $ti-icon-shape-2; }
.#{$ti-prefix}-shape-3:before { content: $ti-icon-shape-3; }
.#{$ti-prefix}-shape-off:before { content: $ti-icon-shape-off; }
.#{$ti-prefix}-share:before { content: $ti-icon-share; }
.#{$ti-prefix}-share-2:before { content: $ti-icon-share-2; }
.#{$ti-prefix}-share-3:before { content: $ti-icon-share-3; }
.#{$ti-prefix}-share-off:before { content: $ti-icon-share-off; }
.#{$ti-prefix}-shareplay:before { content: $ti-icon-shareplay; }
.#{$ti-prefix}-shield:before { content: $ti-icon-shield; }
.#{$ti-prefix}-shield-bolt:before { content: $ti-icon-shield-bolt; }
.#{$ti-prefix}-shield-cancel:before { content: $ti-icon-shield-cancel; }
.#{$ti-prefix}-shield-check:before { content: $ti-icon-shield-check; }
.#{$ti-prefix}-shield-check-filled:before { content: $ti-icon-shield-check-filled; }
.#{$ti-prefix}-shield-checkered:before { content: $ti-icon-shield-checkered; }
.#{$ti-prefix}-shield-checkered-filled:before { content: $ti-icon-shield-checkered-filled; }
.#{$ti-prefix}-shield-chevron:before { content: $ti-icon-shield-chevron; }
.#{$ti-prefix}-shield-code:before { content: $ti-icon-shield-code; }
.#{$ti-prefix}-shield-cog:before { content: $ti-icon-shield-cog; }
.#{$ti-prefix}-shield-dollar:before { content: $ti-icon-shield-dollar; }
.#{$ti-prefix}-shield-down:before { content: $ti-icon-shield-down; }
.#{$ti-prefix}-shield-exclamation:before { content: $ti-icon-shield-exclamation; }
.#{$ti-prefix}-shield-filled:before { content: $ti-icon-shield-filled; }
.#{$ti-prefix}-shield-half:before { content: $ti-icon-shield-half; }
.#{$ti-prefix}-shield-half-filled:before { content: $ti-icon-shield-half-filled; }
.#{$ti-prefix}-shield-heart:before { content: $ti-icon-shield-heart; }
.#{$ti-prefix}-shield-lock:before { content: $ti-icon-shield-lock; }
.#{$ti-prefix}-shield-lock-filled:before { content: $ti-icon-shield-lock-filled; }
.#{$ti-prefix}-shield-minus:before { content: $ti-icon-shield-minus; }
.#{$ti-prefix}-shield-off:before { content: $ti-icon-shield-off; }
.#{$ti-prefix}-shield-pause:before { content: $ti-icon-shield-pause; }
.#{$ti-prefix}-shield-pin:before { content: $ti-icon-shield-pin; }
.#{$ti-prefix}-shield-plus:before { content: $ti-icon-shield-plus; }
.#{$ti-prefix}-shield-question:before { content: $ti-icon-shield-question; }
.#{$ti-prefix}-shield-search:before { content: $ti-icon-shield-search; }
.#{$ti-prefix}-shield-share:before { content: $ti-icon-shield-share; }
.#{$ti-prefix}-shield-star:before { content: $ti-icon-shield-star; }
.#{$ti-prefix}-shield-up:before { content: $ti-icon-shield-up; }
.#{$ti-prefix}-shield-x:before { content: $ti-icon-shield-x; }
.#{$ti-prefix}-ship:before { content: $ti-icon-ship; }
.#{$ti-prefix}-ship-off:before { content: $ti-icon-ship-off; }
.#{$ti-prefix}-shirt:before { content: $ti-icon-shirt; }
.#{$ti-prefix}-shirt-filled:before { content: $ti-icon-shirt-filled; }
.#{$ti-prefix}-shirt-off:before { content: $ti-icon-shirt-off; }
.#{$ti-prefix}-shirt-sport:before { content: $ti-icon-shirt-sport; }
.#{$ti-prefix}-shoe:before { content: $ti-icon-shoe; }
.#{$ti-prefix}-shoe-off:before { content: $ti-icon-shoe-off; }
.#{$ti-prefix}-shopping-bag:before { content: $ti-icon-shopping-bag; }
.#{$ti-prefix}-shopping-bag-check:before { content: $ti-icon-shopping-bag-check; }
.#{$ti-prefix}-shopping-bag-discount:before { content: $ti-icon-shopping-bag-discount; }
.#{$ti-prefix}-shopping-bag-edit:before { content: $ti-icon-shopping-bag-edit; }
.#{$ti-prefix}-shopping-bag-exclamation:before { content: $ti-icon-shopping-bag-exclamation; }
.#{$ti-prefix}-shopping-bag-heart:before { content: $ti-icon-shopping-bag-heart; }
.#{$ti-prefix}-shopping-bag-minus:before { content: $ti-icon-shopping-bag-minus; }
.#{$ti-prefix}-shopping-bag-plus:before { content: $ti-icon-shopping-bag-plus; }
.#{$ti-prefix}-shopping-bag-search:before { content: $ti-icon-shopping-bag-search; }
.#{$ti-prefix}-shopping-bag-x:before { content: $ti-icon-shopping-bag-x; }
.#{$ti-prefix}-shopping-cart:before { content: $ti-icon-shopping-cart; }
.#{$ti-prefix}-shopping-cart-bolt:before { content: $ti-icon-shopping-cart-bolt; }
.#{$ti-prefix}-shopping-cart-cancel:before { content: $ti-icon-shopping-cart-cancel; }
.#{$ti-prefix}-shopping-cart-check:before { content: $ti-icon-shopping-cart-check; }
.#{$ti-prefix}-shopping-cart-code:before { content: $ti-icon-shopping-cart-code; }
.#{$ti-prefix}-shopping-cart-cog:before { content: $ti-icon-shopping-cart-cog; }
.#{$ti-prefix}-shopping-cart-copy:before { content: $ti-icon-shopping-cart-copy; }
.#{$ti-prefix}-shopping-cart-discount:before { content: $ti-icon-shopping-cart-discount; }
.#{$ti-prefix}-shopping-cart-dollar:before { content: $ti-icon-shopping-cart-dollar; }
.#{$ti-prefix}-shopping-cart-down:before { content: $ti-icon-shopping-cart-down; }
.#{$ti-prefix}-shopping-cart-exclamation:before { content: $ti-icon-shopping-cart-exclamation; }
.#{$ti-prefix}-shopping-cart-filled:before { content: $ti-icon-shopping-cart-filled; }
.#{$ti-prefix}-shopping-cart-heart:before { content: $ti-icon-shopping-cart-heart; }
.#{$ti-prefix}-shopping-cart-minus:before { content: $ti-icon-shopping-cart-minus; }
.#{$ti-prefix}-shopping-cart-off:before { content: $ti-icon-shopping-cart-off; }
.#{$ti-prefix}-shopping-cart-pause:before { content: $ti-icon-shopping-cart-pause; }
.#{$ti-prefix}-shopping-cart-pin:before { content: $ti-icon-shopping-cart-pin; }
.#{$ti-prefix}-shopping-cart-plus:before { content: $ti-icon-shopping-cart-plus; }
.#{$ti-prefix}-shopping-cart-question:before { content: $ti-icon-shopping-cart-question; }
.#{$ti-prefix}-shopping-cart-search:before { content: $ti-icon-shopping-cart-search; }
.#{$ti-prefix}-shopping-cart-share:before { content: $ti-icon-shopping-cart-share; }
.#{$ti-prefix}-shopping-cart-star:before { content: $ti-icon-shopping-cart-star; }
.#{$ti-prefix}-shopping-cart-up:before { content: $ti-icon-shopping-cart-up; }
.#{$ti-prefix}-shopping-cart-x:before { content: $ti-icon-shopping-cart-x; }
.#{$ti-prefix}-shovel:before { content: $ti-icon-shovel; }
.#{$ti-prefix}-shovel-pitchforks:before { content: $ti-icon-shovel-pitchforks; }
.#{$ti-prefix}-shredder:before { content: $ti-icon-shredder; }
.#{$ti-prefix}-sign-left:before { content: $ti-icon-sign-left; }
.#{$ti-prefix}-sign-left-filled:before { content: $ti-icon-sign-left-filled; }
.#{$ti-prefix}-sign-right:before { content: $ti-icon-sign-right; }
.#{$ti-prefix}-sign-right-filled:before { content: $ti-icon-sign-right-filled; }
.#{$ti-prefix}-signal-2g:before { content: $ti-icon-signal-2g; }
.#{$ti-prefix}-signal-3g:before { content: $ti-icon-signal-3g; }
.#{$ti-prefix}-signal-4g:before { content: $ti-icon-signal-4g; }
.#{$ti-prefix}-signal-4g-plus:before { content: $ti-icon-signal-4g-plus; }
.#{$ti-prefix}-signal-5g:before { content: $ti-icon-signal-5g; }
.#{$ti-prefix}-signal-6g:before { content: $ti-icon-signal-6g; }
.#{$ti-prefix}-signal-e:before { content: $ti-icon-signal-e; }
.#{$ti-prefix}-signal-g:before { content: $ti-icon-signal-g; }
.#{$ti-prefix}-signal-h:before { content: $ti-icon-signal-h; }
.#{$ti-prefix}-signal-h-plus:before { content: $ti-icon-signal-h-plus; }
.#{$ti-prefix}-signal-lte:before { content: $ti-icon-signal-lte; }
.#{$ti-prefix}-signature:before { content: $ti-icon-signature; }
.#{$ti-prefix}-signature-off:before { content: $ti-icon-signature-off; }
.#{$ti-prefix}-sitemap:before { content: $ti-icon-sitemap; }
.#{$ti-prefix}-sitemap-filled:before { content: $ti-icon-sitemap-filled; }
.#{$ti-prefix}-sitemap-off:before { content: $ti-icon-sitemap-off; }
.#{$ti-prefix}-skateboard:before { content: $ti-icon-skateboard; }
.#{$ti-prefix}-skateboard-off:before { content: $ti-icon-skateboard-off; }
.#{$ti-prefix}-skateboarding:before { content: $ti-icon-skateboarding; }
.#{$ti-prefix}-skew-x:before { content: $ti-icon-skew-x; }
.#{$ti-prefix}-skew-y:before { content: $ti-icon-skew-y; }
.#{$ti-prefix}-ski-jumping:before { content: $ti-icon-ski-jumping; }
.#{$ti-prefix}-skull:before { content: $ti-icon-skull; }
.#{$ti-prefix}-slash:before { content: $ti-icon-slash; }
.#{$ti-prefix}-slashes:before { content: $ti-icon-slashes; }
.#{$ti-prefix}-sleigh:before { content: $ti-icon-sleigh; }
.#{$ti-prefix}-slice:before { content: $ti-icon-slice; }
.#{$ti-prefix}-slideshow:before { content: $ti-icon-slideshow; }
.#{$ti-prefix}-smart-home:before { content: $ti-icon-smart-home; }
.#{$ti-prefix}-smart-home-off:before { content: $ti-icon-smart-home-off; }
.#{$ti-prefix}-smoking:before { content: $ti-icon-smoking; }
.#{$ti-prefix}-smoking-no:before { content: $ti-icon-smoking-no; }
.#{$ti-prefix}-snowboarding:before { content: $ti-icon-snowboarding; }
.#{$ti-prefix}-snowflake:before { content: $ti-icon-snowflake; }
.#{$ti-prefix}-snowflake-off:before { content: $ti-icon-snowflake-off; }
.#{$ti-prefix}-snowman:before { content: $ti-icon-snowman; }
.#{$ti-prefix}-soccer-field:before { content: $ti-icon-soccer-field; }
.#{$ti-prefix}-social:before { content: $ti-icon-social; }
.#{$ti-prefix}-social-off:before { content: $ti-icon-social-off; }
.#{$ti-prefix}-sock:before { content: $ti-icon-sock; }
.#{$ti-prefix}-sofa:before { content: $ti-icon-sofa; }
.#{$ti-prefix}-sofa-off:before { content: $ti-icon-sofa-off; }
.#{$ti-prefix}-solar-electricity:before { content: $ti-icon-solar-electricity; }
.#{$ti-prefix}-solar-panel:before { content: $ti-icon-solar-panel; }
.#{$ti-prefix}-solar-panel-2:before { content: $ti-icon-solar-panel-2; }
.#{$ti-prefix}-sort-0-9:before { content: $ti-icon-sort-0-9; }
.#{$ti-prefix}-sort-9-0:before { content: $ti-icon-sort-9-0; }
.#{$ti-prefix}-sort-a-z:before { content: $ti-icon-sort-a-z; }
.#{$ti-prefix}-sort-ascending:before { content: $ti-icon-sort-ascending; }
.#{$ti-prefix}-sort-ascending-2:before { content: $ti-icon-sort-ascending-2; }
.#{$ti-prefix}-sort-ascending-2-filled:before { content: $ti-icon-sort-ascending-2-filled; }
.#{$ti-prefix}-sort-ascending-letters:before { content: $ti-icon-sort-ascending-letters; }
.#{$ti-prefix}-sort-ascending-numbers:before { content: $ti-icon-sort-ascending-numbers; }
.#{$ti-prefix}-sort-ascending-shapes:before { content: $ti-icon-sort-ascending-shapes; }
.#{$ti-prefix}-sort-ascending-shapes-filled:before { content: $ti-icon-sort-ascending-shapes-filled; }
.#{$ti-prefix}-sort-ascending-small-big:before { content: $ti-icon-sort-ascending-small-big; }
.#{$ti-prefix}-sort-descending:before { content: $ti-icon-sort-descending; }
.#{$ti-prefix}-sort-descending-2:before { content: $ti-icon-sort-descending-2; }
.#{$ti-prefix}-sort-descending-2-filled:before { content: $ti-icon-sort-descending-2-filled; }
.#{$ti-prefix}-sort-descending-letters:before { content: $ti-icon-sort-descending-letters; }
.#{$ti-prefix}-sort-descending-numbers:before { content: $ti-icon-sort-descending-numbers; }
.#{$ti-prefix}-sort-descending-shapes:before { content: $ti-icon-sort-descending-shapes; }
.#{$ti-prefix}-sort-descending-shapes-filled:before { content: $ti-icon-sort-descending-shapes-filled; }
.#{$ti-prefix}-sort-descending-small-big:before { content: $ti-icon-sort-descending-small-big; }
.#{$ti-prefix}-sort-z-a:before { content: $ti-icon-sort-z-a; }
.#{$ti-prefix}-sos:before { content: $ti-icon-sos; }
.#{$ti-prefix}-soup:before { content: $ti-icon-soup; }
.#{$ti-prefix}-soup-filled:before { content: $ti-icon-soup-filled; }
.#{$ti-prefix}-soup-off:before { content: $ti-icon-soup-off; }
.#{$ti-prefix}-source-code:before { content: $ti-icon-source-code; }
.#{$ti-prefix}-space:before { content: $ti-icon-space; }
.#{$ti-prefix}-space-off:before { content: $ti-icon-space-off; }
.#{$ti-prefix}-spaces:before { content: $ti-icon-spaces; }
.#{$ti-prefix}-spacing-horizontal:before { content: $ti-icon-spacing-horizontal; }
.#{$ti-prefix}-spacing-vertical:before { content: $ti-icon-spacing-vertical; }
.#{$ti-prefix}-spade:before { content: $ti-icon-spade; }
.#{$ti-prefix}-spade-filled:before { content: $ti-icon-spade-filled; }
.#{$ti-prefix}-sparkles:before { content: $ti-icon-sparkles; }
.#{$ti-prefix}-speakerphone:before { content: $ti-icon-speakerphone; }
.#{$ti-prefix}-speedboat:before { content: $ti-icon-speedboat; }
.#{$ti-prefix}-sphere:before { content: $ti-icon-sphere; }
.#{$ti-prefix}-sphere-off:before { content: $ti-icon-sphere-off; }
.#{$ti-prefix}-sphere-plus:before { content: $ti-icon-sphere-plus; }
.#{$ti-prefix}-spider:before { content: $ti-icon-spider; }
.#{$ti-prefix}-spiral:before { content: $ti-icon-spiral; }
.#{$ti-prefix}-spiral-off:before { content: $ti-icon-spiral-off; }
.#{$ti-prefix}-sport-billard:before { content: $ti-icon-sport-billard; }
.#{$ti-prefix}-spray:before { content: $ti-icon-spray; }
.#{$ti-prefix}-spy:before { content: $ti-icon-spy; }
.#{$ti-prefix}-spy-off:before { content: $ti-icon-spy-off; }
.#{$ti-prefix}-sql:before { content: $ti-icon-sql; }
.#{$ti-prefix}-square:before { content: $ti-icon-square; }
.#{$ti-prefix}-square-arrow-down:before { content: $ti-icon-square-arrow-down; }
.#{$ti-prefix}-square-arrow-down-filled:before { content: $ti-icon-square-arrow-down-filled; }
.#{$ti-prefix}-square-arrow-left:before { content: $ti-icon-square-arrow-left; }
.#{$ti-prefix}-square-arrow-left-filled:before { content: $ti-icon-square-arrow-left-filled; }
.#{$ti-prefix}-square-arrow-right:before { content: $ti-icon-square-arrow-right; }
.#{$ti-prefix}-square-arrow-right-filled:before { content: $ti-icon-square-arrow-right-filled; }
.#{$ti-prefix}-square-arrow-up:before { content: $ti-icon-square-arrow-up; }
.#{$ti-prefix}-square-arrow-up-filled:before { content: $ti-icon-square-arrow-up-filled; }
.#{$ti-prefix}-square-asterisk:before { content: $ti-icon-square-asterisk; }
.#{$ti-prefix}-square-asterisk-filled:before { content: $ti-icon-square-asterisk-filled; }
.#{$ti-prefix}-square-check:before { content: $ti-icon-square-check; }
.#{$ti-prefix}-square-check-filled:before { content: $ti-icon-square-check-filled; }
.#{$ti-prefix}-square-chevron-down:before { content: $ti-icon-square-chevron-down; }
.#{$ti-prefix}-square-chevron-down-filled:before { content: $ti-icon-square-chevron-down-filled; }
.#{$ti-prefix}-square-chevron-left:before { content: $ti-icon-square-chevron-left; }
.#{$ti-prefix}-square-chevron-left-filled:before { content: $ti-icon-square-chevron-left-filled; }
.#{$ti-prefix}-square-chevron-right:before { content: $ti-icon-square-chevron-right; }
.#{$ti-prefix}-square-chevron-right-filled:before { content: $ti-icon-square-chevron-right-filled; }
.#{$ti-prefix}-square-chevron-up:before { content: $ti-icon-square-chevron-up; }
.#{$ti-prefix}-square-chevron-up-filled:before { content: $ti-icon-square-chevron-up-filled; }
.#{$ti-prefix}-square-chevrons-down:before { content: $ti-icon-square-chevrons-down; }
.#{$ti-prefix}-square-chevrons-down-filled:before { content: $ti-icon-square-chevrons-down-filled; }
.#{$ti-prefix}-square-chevrons-left:before { content: $ti-icon-square-chevrons-left; }
.#{$ti-prefix}-square-chevrons-left-filled:before { content: $ti-icon-square-chevrons-left-filled; }
.#{$ti-prefix}-square-chevrons-right:before { content: $ti-icon-square-chevrons-right; }
.#{$ti-prefix}-square-chevrons-right-filled:before { content: $ti-icon-square-chevrons-right-filled; }
.#{$ti-prefix}-square-chevrons-up:before { content: $ti-icon-square-chevrons-up; }
.#{$ti-prefix}-square-chevrons-up-filled:before { content: $ti-icon-square-chevrons-up-filled; }
.#{$ti-prefix}-square-dot:before { content: $ti-icon-square-dot; }
.#{$ti-prefix}-square-dot-filled:before { content: $ti-icon-square-dot-filled; }
.#{$ti-prefix}-square-f0:before { content: $ti-icon-square-f0; }
.#{$ti-prefix}-square-f0-filled:before { content: $ti-icon-square-f0-filled; }
.#{$ti-prefix}-square-f1:before { content: $ti-icon-square-f1; }
.#{$ti-prefix}-square-f1-filled:before { content: $ti-icon-square-f1-filled; }
.#{$ti-prefix}-square-f2:before { content: $ti-icon-square-f2; }
.#{$ti-prefix}-square-f2-filled:before { content: $ti-icon-square-f2-filled; }
.#{$ti-prefix}-square-f3:before { content: $ti-icon-square-f3; }
.#{$ti-prefix}-square-f3-filled:before { content: $ti-icon-square-f3-filled; }
.#{$ti-prefix}-square-f4:before { content: $ti-icon-square-f4; }
.#{$ti-prefix}-square-f4-filled:before { content: $ti-icon-square-f4-filled; }
.#{$ti-prefix}-square-f5:before { content: $ti-icon-square-f5; }
.#{$ti-prefix}-square-f5-filled:before { content: $ti-icon-square-f5-filled; }
.#{$ti-prefix}-square-f6:before { content: $ti-icon-square-f6; }
.#{$ti-prefix}-square-f6-filled:before { content: $ti-icon-square-f6-filled; }
.#{$ti-prefix}-square-f7:before { content: $ti-icon-square-f7; }
.#{$ti-prefix}-square-f7-filled:before { content: $ti-icon-square-f7-filled; }
.#{$ti-prefix}-square-f8:before { content: $ti-icon-square-f8; }
.#{$ti-prefix}-square-f8-filled:before { content: $ti-icon-square-f8-filled; }
.#{$ti-prefix}-square-f9:before { content: $ti-icon-square-f9; }
.#{$ti-prefix}-square-f9-filled:before { content: $ti-icon-square-f9-filled; }
.#{$ti-prefix}-square-filled:before { content: $ti-icon-square-filled; }
.#{$ti-prefix}-square-forbid:before { content: $ti-icon-square-forbid; }
.#{$ti-prefix}-square-forbid-2:before { content: $ti-icon-square-forbid-2; }
.#{$ti-prefix}-square-half:before { content: $ti-icon-square-half; }
.#{$ti-prefix}-square-key:before { content: $ti-icon-square-key; }
.#{$ti-prefix}-square-letter-a:before { content: $ti-icon-square-letter-a; }
.#{$ti-prefix}-square-letter-a-filled:before { content: $ti-icon-square-letter-a-filled; }
.#{$ti-prefix}-square-letter-b:before { content: $ti-icon-square-letter-b; }
.#{$ti-prefix}-square-letter-b-filled:before { content: $ti-icon-square-letter-b-filled; }
.#{$ti-prefix}-square-letter-c:before { content: $ti-icon-square-letter-c; }
.#{$ti-prefix}-square-letter-c-filled:before { content: $ti-icon-square-letter-c-filled; }
.#{$ti-prefix}-square-letter-d:before { content: $ti-icon-square-letter-d; }
.#{$ti-prefix}-square-letter-d-filled:before { content: $ti-icon-square-letter-d-filled; }
.#{$ti-prefix}-square-letter-e:before { content: $ti-icon-square-letter-e; }
.#{$ti-prefix}-square-letter-e-filled:before { content: $ti-icon-square-letter-e-filled; }
.#{$ti-prefix}-square-letter-f:before { content: $ti-icon-square-letter-f; }
.#{$ti-prefix}-square-letter-f-filled:before { content: $ti-icon-square-letter-f-filled; }
.#{$ti-prefix}-square-letter-g:before { content: $ti-icon-square-letter-g; }
.#{$ti-prefix}-square-letter-g-filled:before { content: $ti-icon-square-letter-g-filled; }
.#{$ti-prefix}-square-letter-h:before { content: $ti-icon-square-letter-h; }
.#{$ti-prefix}-square-letter-h-filled:before { content: $ti-icon-square-letter-h-filled; }
.#{$ti-prefix}-square-letter-i:before { content: $ti-icon-square-letter-i; }
.#{$ti-prefix}-square-letter-i-filled:before { content: $ti-icon-square-letter-i-filled; }
.#{$ti-prefix}-square-letter-j:before { content: $ti-icon-square-letter-j; }
.#{$ti-prefix}-square-letter-j-filled:before { content: $ti-icon-square-letter-j-filled; }
.#{$ti-prefix}-square-letter-k:before { content: $ti-icon-square-letter-k; }
.#{$ti-prefix}-square-letter-k-filled:before { content: $ti-icon-square-letter-k-filled; }
.#{$ti-prefix}-square-letter-l:before { content: $ti-icon-square-letter-l; }
.#{$ti-prefix}-square-letter-l-filled:before { content: $ti-icon-square-letter-l-filled; }
.#{$ti-prefix}-square-letter-m:before { content: $ti-icon-square-letter-m; }
.#{$ti-prefix}-square-letter-m-filled:before { content: $ti-icon-square-letter-m-filled; }
.#{$ti-prefix}-square-letter-n:before { content: $ti-icon-square-letter-n; }
.#{$ti-prefix}-square-letter-n-filled:before { content: $ti-icon-square-letter-n-filled; }
.#{$ti-prefix}-square-letter-o:before { content: $ti-icon-square-letter-o; }
.#{$ti-prefix}-square-letter-o-filled:before { content: $ti-icon-square-letter-o-filled; }
.#{$ti-prefix}-square-letter-p:before { content: $ti-icon-square-letter-p; }
.#{$ti-prefix}-square-letter-p-filled:before { content: $ti-icon-square-letter-p-filled; }
.#{$ti-prefix}-square-letter-q:before { content: $ti-icon-square-letter-q; }
.#{$ti-prefix}-square-letter-q-filled:before { content: $ti-icon-square-letter-q-filled; }
.#{$ti-prefix}-square-letter-r:before { content: $ti-icon-square-letter-r; }
.#{$ti-prefix}-square-letter-r-filled:before { content: $ti-icon-square-letter-r-filled; }
.#{$ti-prefix}-square-letter-s:before { content: $ti-icon-square-letter-s; }
.#{$ti-prefix}-square-letter-s-filled:before { content: $ti-icon-square-letter-s-filled; }
.#{$ti-prefix}-square-letter-t:before { content: $ti-icon-square-letter-t; }
.#{$ti-prefix}-square-letter-t-filled:before { content: $ti-icon-square-letter-t-filled; }
.#{$ti-prefix}-square-letter-u:before { content: $ti-icon-square-letter-u; }
.#{$ti-prefix}-square-letter-u-filled:before { content: $ti-icon-square-letter-u-filled; }
.#{$ti-prefix}-square-letter-v:before { content: $ti-icon-square-letter-v; }
.#{$ti-prefix}-square-letter-v-filled:before { content: $ti-icon-square-letter-v-filled; }
.#{$ti-prefix}-square-letter-w:before { content: $ti-icon-square-letter-w; }
.#{$ti-prefix}-square-letter-w-filled:before { content: $ti-icon-square-letter-w-filled; }
.#{$ti-prefix}-square-letter-x:before { content: $ti-icon-square-letter-x; }
.#{$ti-prefix}-square-letter-x-filled:before { content: $ti-icon-square-letter-x-filled; }
.#{$ti-prefix}-square-letter-y:before { content: $ti-icon-square-letter-y; }
.#{$ti-prefix}-square-letter-y-filled:before { content: $ti-icon-square-letter-y-filled; }
.#{$ti-prefix}-square-letter-z:before { content: $ti-icon-square-letter-z; }
.#{$ti-prefix}-square-letter-z-filled:before { content: $ti-icon-square-letter-z-filled; }
.#{$ti-prefix}-square-minus:before { content: $ti-icon-square-minus; }
.#{$ti-prefix}-square-minus-filled:before { content: $ti-icon-square-minus-filled; }
.#{$ti-prefix}-square-number-0:before { content: $ti-icon-square-number-0; }
.#{$ti-prefix}-square-number-0-filled:before { content: $ti-icon-square-number-0-filled; }
.#{$ti-prefix}-square-number-1:before { content: $ti-icon-square-number-1; }
.#{$ti-prefix}-square-number-1-filled:before { content: $ti-icon-square-number-1-filled; }
.#{$ti-prefix}-square-number-2:before { content: $ti-icon-square-number-2; }
.#{$ti-prefix}-square-number-2-filled:before { content: $ti-icon-square-number-2-filled; }
.#{$ti-prefix}-square-number-3:before { content: $ti-icon-square-number-3; }
.#{$ti-prefix}-square-number-3-filled:before { content: $ti-icon-square-number-3-filled; }
.#{$ti-prefix}-square-number-4:before { content: $ti-icon-square-number-4; }
.#{$ti-prefix}-square-number-4-filled:before { content: $ti-icon-square-number-4-filled; }
.#{$ti-prefix}-square-number-5:before { content: $ti-icon-square-number-5; }
.#{$ti-prefix}-square-number-5-filled:before { content: $ti-icon-square-number-5-filled; }
.#{$ti-prefix}-square-number-6:before { content: $ti-icon-square-number-6; }
.#{$ti-prefix}-square-number-6-filled:before { content: $ti-icon-square-number-6-filled; }
.#{$ti-prefix}-square-number-7:before { content: $ti-icon-square-number-7; }
.#{$ti-prefix}-square-number-7-filled:before { content: $ti-icon-square-number-7-filled; }
.#{$ti-prefix}-square-number-8:before { content: $ti-icon-square-number-8; }
.#{$ti-prefix}-square-number-8-filled:before { content: $ti-icon-square-number-8-filled; }
.#{$ti-prefix}-square-number-9:before { content: $ti-icon-square-number-9; }
.#{$ti-prefix}-square-number-9-filled:before { content: $ti-icon-square-number-9-filled; }
.#{$ti-prefix}-square-off:before { content: $ti-icon-square-off; }
.#{$ti-prefix}-square-percentage:before { content: $ti-icon-square-percentage; }
.#{$ti-prefix}-square-plus:before { content: $ti-icon-square-plus; }
.#{$ti-prefix}-square-plus-2:before { content: $ti-icon-square-plus-2; }
.#{$ti-prefix}-square-root:before { content: $ti-icon-square-root; }
.#{$ti-prefix}-square-root-2:before { content: $ti-icon-square-root-2; }
.#{$ti-prefix}-square-rotated:before { content: $ti-icon-square-rotated; }
.#{$ti-prefix}-square-rotated-filled:before { content: $ti-icon-square-rotated-filled; }
.#{$ti-prefix}-square-rotated-forbid:before { content: $ti-icon-square-rotated-forbid; }
.#{$ti-prefix}-square-rotated-forbid-2:before { content: $ti-icon-square-rotated-forbid-2; }
.#{$ti-prefix}-square-rotated-off:before { content: $ti-icon-square-rotated-off; }
.#{$ti-prefix}-square-rounded:before { content: $ti-icon-square-rounded; }
.#{$ti-prefix}-square-rounded-arrow-down:before { content: $ti-icon-square-rounded-arrow-down; }
.#{$ti-prefix}-square-rounded-arrow-down-filled:before { content: $ti-icon-square-rounded-arrow-down-filled; }
.#{$ti-prefix}-square-rounded-arrow-left:before { content: $ti-icon-square-rounded-arrow-left; }
.#{$ti-prefix}-square-rounded-arrow-left-filled:before { content: $ti-icon-square-rounded-arrow-left-filled; }
.#{$ti-prefix}-square-rounded-arrow-right:before { content: $ti-icon-square-rounded-arrow-right; }
.#{$ti-prefix}-square-rounded-arrow-right-filled:before { content: $ti-icon-square-rounded-arrow-right-filled; }
.#{$ti-prefix}-square-rounded-arrow-up:before { content: $ti-icon-square-rounded-arrow-up; }
.#{$ti-prefix}-square-rounded-arrow-up-filled:before { content: $ti-icon-square-rounded-arrow-up-filled; }
.#{$ti-prefix}-square-rounded-check:before { content: $ti-icon-square-rounded-check; }
.#{$ti-prefix}-square-rounded-check-filled:before { content: $ti-icon-square-rounded-check-filled; }
.#{$ti-prefix}-square-rounded-chevron-down:before { content: $ti-icon-square-rounded-chevron-down; }
.#{$ti-prefix}-square-rounded-chevron-down-filled:before { content: $ti-icon-square-rounded-chevron-down-filled; }
.#{$ti-prefix}-square-rounded-chevron-left:before { content: $ti-icon-square-rounded-chevron-left; }
.#{$ti-prefix}-square-rounded-chevron-left-filled:before { content: $ti-icon-square-rounded-chevron-left-filled; }
.#{$ti-prefix}-square-rounded-chevron-right:before { content: $ti-icon-square-rounded-chevron-right; }
.#{$ti-prefix}-square-rounded-chevron-right-filled:before { content: $ti-icon-square-rounded-chevron-right-filled; }
.#{$ti-prefix}-square-rounded-chevron-up:before { content: $ti-icon-square-rounded-chevron-up; }
.#{$ti-prefix}-square-rounded-chevron-up-filled:before { content: $ti-icon-square-rounded-chevron-up-filled; }
.#{$ti-prefix}-square-rounded-chevrons-down:before { content: $ti-icon-square-rounded-chevrons-down; }
.#{$ti-prefix}-square-rounded-chevrons-down-filled:before { content: $ti-icon-square-rounded-chevrons-down-filled; }
.#{$ti-prefix}-square-rounded-chevrons-left:before { content: $ti-icon-square-rounded-chevrons-left; }
.#{$ti-prefix}-square-rounded-chevrons-left-filled:before { content: $ti-icon-square-rounded-chevrons-left-filled; }
.#{$ti-prefix}-square-rounded-chevrons-right:before { content: $ti-icon-square-rounded-chevrons-right; }
.#{$ti-prefix}-square-rounded-chevrons-right-filled:before { content: $ti-icon-square-rounded-chevrons-right-filled; }
.#{$ti-prefix}-square-rounded-chevrons-up:before { content: $ti-icon-square-rounded-chevrons-up; }
.#{$ti-prefix}-square-rounded-chevrons-up-filled:before { content: $ti-icon-square-rounded-chevrons-up-filled; }
.#{$ti-prefix}-square-rounded-filled:before { content: $ti-icon-square-rounded-filled; }
.#{$ti-prefix}-square-rounded-letter-a:before { content: $ti-icon-square-rounded-letter-a; }
.#{$ti-prefix}-square-rounded-letter-a-filled:before { content: $ti-icon-square-rounded-letter-a-filled; }
.#{$ti-prefix}-square-rounded-letter-b:before { content: $ti-icon-square-rounded-letter-b; }
.#{$ti-prefix}-square-rounded-letter-b-filled:before { content: $ti-icon-square-rounded-letter-b-filled; }
.#{$ti-prefix}-square-rounded-letter-c:before { content: $ti-icon-square-rounded-letter-c; }
.#{$ti-prefix}-square-rounded-letter-c-filled:before { content: $ti-icon-square-rounded-letter-c-filled; }
.#{$ti-prefix}-square-rounded-letter-d:before { content: $ti-icon-square-rounded-letter-d; }
.#{$ti-prefix}-square-rounded-letter-d-filled:before { content: $ti-icon-square-rounded-letter-d-filled; }
.#{$ti-prefix}-square-rounded-letter-e:before { content: $ti-icon-square-rounded-letter-e; }
.#{$ti-prefix}-square-rounded-letter-e-filled:before { content: $ti-icon-square-rounded-letter-e-filled; }
.#{$ti-prefix}-square-rounded-letter-f:before { content: $ti-icon-square-rounded-letter-f; }
.#{$ti-prefix}-square-rounded-letter-f-filled:before { content: $ti-icon-square-rounded-letter-f-filled; }
.#{$ti-prefix}-square-rounded-letter-g:before { content: $ti-icon-square-rounded-letter-g; }
.#{$ti-prefix}-square-rounded-letter-g-filled:before { content: $ti-icon-square-rounded-letter-g-filled; }
.#{$ti-prefix}-square-rounded-letter-h:before { content: $ti-icon-square-rounded-letter-h; }
.#{$ti-prefix}-square-rounded-letter-h-filled:before { content: $ti-icon-square-rounded-letter-h-filled; }
.#{$ti-prefix}-square-rounded-letter-i:before { content: $ti-icon-square-rounded-letter-i; }
.#{$ti-prefix}-square-rounded-letter-i-filled:before { content: $ti-icon-square-rounded-letter-i-filled; }
.#{$ti-prefix}-square-rounded-letter-j:before { content: $ti-icon-square-rounded-letter-j; }
.#{$ti-prefix}-square-rounded-letter-j-filled:before { content: $ti-icon-square-rounded-letter-j-filled; }
.#{$ti-prefix}-square-rounded-letter-k:before { content: $ti-icon-square-rounded-letter-k; }
.#{$ti-prefix}-square-rounded-letter-k-filled:before { content: $ti-icon-square-rounded-letter-k-filled; }
.#{$ti-prefix}-square-rounded-letter-l:before { content: $ti-icon-square-rounded-letter-l; }
.#{$ti-prefix}-square-rounded-letter-l-filled:before { content: $ti-icon-square-rounded-letter-l-filled; }
.#{$ti-prefix}-square-rounded-letter-m:before { content: $ti-icon-square-rounded-letter-m; }
.#{$ti-prefix}-square-rounded-letter-m-filled:before { content: $ti-icon-square-rounded-letter-m-filled; }
.#{$ti-prefix}-square-rounded-letter-n:before { content: $ti-icon-square-rounded-letter-n; }
.#{$ti-prefix}-square-rounded-letter-n-filled:before { content: $ti-icon-square-rounded-letter-n-filled; }
.#{$ti-prefix}-square-rounded-letter-o:before { content: $ti-icon-square-rounded-letter-o; }
.#{$ti-prefix}-square-rounded-letter-o-filled:before { content: $ti-icon-square-rounded-letter-o-filled; }
.#{$ti-prefix}-square-rounded-letter-p:before { content: $ti-icon-square-rounded-letter-p; }
.#{$ti-prefix}-square-rounded-letter-p-filled:before { content: $ti-icon-square-rounded-letter-p-filled; }
.#{$ti-prefix}-square-rounded-letter-q:before { content: $ti-icon-square-rounded-letter-q; }
.#{$ti-prefix}-square-rounded-letter-q-filled:before { content: $ti-icon-square-rounded-letter-q-filled; }
.#{$ti-prefix}-square-rounded-letter-r:before { content: $ti-icon-square-rounded-letter-r; }
.#{$ti-prefix}-square-rounded-letter-r-filled:before { content: $ti-icon-square-rounded-letter-r-filled; }
.#{$ti-prefix}-square-rounded-letter-s:before { content: $ti-icon-square-rounded-letter-s; }
.#{$ti-prefix}-square-rounded-letter-s-filled:before { content: $ti-icon-square-rounded-letter-s-filled; }
.#{$ti-prefix}-square-rounded-letter-t:before { content: $ti-icon-square-rounded-letter-t; }
.#{$ti-prefix}-square-rounded-letter-t-filled:before { content: $ti-icon-square-rounded-letter-t-filled; }
.#{$ti-prefix}-square-rounded-letter-u:before { content: $ti-icon-square-rounded-letter-u; }
.#{$ti-prefix}-square-rounded-letter-u-filled:before { content: $ti-icon-square-rounded-letter-u-filled; }
.#{$ti-prefix}-square-rounded-letter-v:before { content: $ti-icon-square-rounded-letter-v; }
.#{$ti-prefix}-square-rounded-letter-v-filled:before { content: $ti-icon-square-rounded-letter-v-filled; }
.#{$ti-prefix}-square-rounded-letter-w:before { content: $ti-icon-square-rounded-letter-w; }
.#{$ti-prefix}-square-rounded-letter-w-filled:before { content: $ti-icon-square-rounded-letter-w-filled; }
.#{$ti-prefix}-square-rounded-letter-x:before { content: $ti-icon-square-rounded-letter-x; }
.#{$ti-prefix}-square-rounded-letter-x-filled:before { content: $ti-icon-square-rounded-letter-x-filled; }
.#{$ti-prefix}-square-rounded-letter-y:before { content: $ti-icon-square-rounded-letter-y; }
.#{$ti-prefix}-square-rounded-letter-y-filled:before { content: $ti-icon-square-rounded-letter-y-filled; }
.#{$ti-prefix}-square-rounded-letter-z:before { content: $ti-icon-square-rounded-letter-z; }
.#{$ti-prefix}-square-rounded-letter-z-filled:before { content: $ti-icon-square-rounded-letter-z-filled; }
.#{$ti-prefix}-square-rounded-minus:before { content: $ti-icon-square-rounded-minus; }
.#{$ti-prefix}-square-rounded-minus-2:before { content: $ti-icon-square-rounded-minus-2; }
.#{$ti-prefix}-square-rounded-minus-filled:before { content: $ti-icon-square-rounded-minus-filled; }
.#{$ti-prefix}-square-rounded-number-0:before { content: $ti-icon-square-rounded-number-0; }
.#{$ti-prefix}-square-rounded-number-0-filled:before { content: $ti-icon-square-rounded-number-0-filled; }
.#{$ti-prefix}-square-rounded-number-1:before { content: $ti-icon-square-rounded-number-1; }
.#{$ti-prefix}-square-rounded-number-1-filled:before { content: $ti-icon-square-rounded-number-1-filled; }
.#{$ti-prefix}-square-rounded-number-2:before { content: $ti-icon-square-rounded-number-2; }
.#{$ti-prefix}-square-rounded-number-2-filled:before { content: $ti-icon-square-rounded-number-2-filled; }
.#{$ti-prefix}-square-rounded-number-3:before { content: $ti-icon-square-rounded-number-3; }
.#{$ti-prefix}-square-rounded-number-3-filled:before { content: $ti-icon-square-rounded-number-3-filled; }
.#{$ti-prefix}-square-rounded-number-4:before { content: $ti-icon-square-rounded-number-4; }
.#{$ti-prefix}-square-rounded-number-4-filled:before { content: $ti-icon-square-rounded-number-4-filled; }
.#{$ti-prefix}-square-rounded-number-5:before { content: $ti-icon-square-rounded-number-5; }
.#{$ti-prefix}-square-rounded-number-5-filled:before { content: $ti-icon-square-rounded-number-5-filled; }
.#{$ti-prefix}-square-rounded-number-6:before { content: $ti-icon-square-rounded-number-6; }
.#{$ti-prefix}-square-rounded-number-6-filled:before { content: $ti-icon-square-rounded-number-6-filled; }
.#{$ti-prefix}-square-rounded-number-7:before { content: $ti-icon-square-rounded-number-7; }
.#{$ti-prefix}-square-rounded-number-7-filled:before { content: $ti-icon-square-rounded-number-7-filled; }
.#{$ti-prefix}-square-rounded-number-8:before { content: $ti-icon-square-rounded-number-8; }
.#{$ti-prefix}-square-rounded-number-8-filled:before { content: $ti-icon-square-rounded-number-8-filled; }
.#{$ti-prefix}-square-rounded-number-9:before { content: $ti-icon-square-rounded-number-9; }
.#{$ti-prefix}-square-rounded-number-9-filled:before { content: $ti-icon-square-rounded-number-9-filled; }
.#{$ti-prefix}-square-rounded-percentage:before { content: $ti-icon-square-rounded-percentage; }
.#{$ti-prefix}-square-rounded-plus:before { content: $ti-icon-square-rounded-plus; }
.#{$ti-prefix}-square-rounded-plus-2:before { content: $ti-icon-square-rounded-plus-2; }
.#{$ti-prefix}-square-rounded-plus-filled:before { content: $ti-icon-square-rounded-plus-filled; }
.#{$ti-prefix}-square-rounded-x:before { content: $ti-icon-square-rounded-x; }
.#{$ti-prefix}-square-rounded-x-filled:before { content: $ti-icon-square-rounded-x-filled; }
.#{$ti-prefix}-square-toggle:before { content: $ti-icon-square-toggle; }
.#{$ti-prefix}-square-toggle-horizontal:before { content: $ti-icon-square-toggle-horizontal; }
.#{$ti-prefix}-square-x:before { content: $ti-icon-square-x; }
.#{$ti-prefix}-square-x-filled:before { content: $ti-icon-square-x-filled; }
.#{$ti-prefix}-squares:before { content: $ti-icon-squares; }
.#{$ti-prefix}-squares-diagonal:before { content: $ti-icon-squares-diagonal; }
.#{$ti-prefix}-squares-filled:before { content: $ti-icon-squares-filled; }
.#{$ti-prefix}-squares-selected:before { content: $ti-icon-squares-selected; }
.#{$ti-prefix}-stack:before { content: $ti-icon-stack; }
.#{$ti-prefix}-stack-2:before { content: $ti-icon-stack-2; }
.#{$ti-prefix}-stack-2-filled:before { content: $ti-icon-stack-2-filled; }
.#{$ti-prefix}-stack-3:before { content: $ti-icon-stack-3; }
.#{$ti-prefix}-stack-3-filled:before { content: $ti-icon-stack-3-filled; }
.#{$ti-prefix}-stack-back:before { content: $ti-icon-stack-back; }
.#{$ti-prefix}-stack-backward:before { content: $ti-icon-stack-backward; }
.#{$ti-prefix}-stack-filled:before { content: $ti-icon-stack-filled; }
.#{$ti-prefix}-stack-forward:before { content: $ti-icon-stack-forward; }
.#{$ti-prefix}-stack-front:before { content: $ti-icon-stack-front; }
.#{$ti-prefix}-stack-middle:before { content: $ti-icon-stack-middle; }
.#{$ti-prefix}-stack-pop:before { content: $ti-icon-stack-pop; }
.#{$ti-prefix}-stack-push:before { content: $ti-icon-stack-push; }
.#{$ti-prefix}-stairs:before { content: $ti-icon-stairs; }
.#{$ti-prefix}-stairs-down:before { content: $ti-icon-stairs-down; }
.#{$ti-prefix}-stairs-up:before { content: $ti-icon-stairs-up; }
.#{$ti-prefix}-star:before { content: $ti-icon-star; }
.#{$ti-prefix}-star-filled:before { content: $ti-icon-star-filled; }
.#{$ti-prefix}-star-half:before { content: $ti-icon-star-half; }
.#{$ti-prefix}-star-half-filled:before { content: $ti-icon-star-half-filled; }
.#{$ti-prefix}-star-off:before { content: $ti-icon-star-off; }
.#{$ti-prefix}-stars:before { content: $ti-icon-stars; }
.#{$ti-prefix}-stars-filled:before { content: $ti-icon-stars-filled; }
.#{$ti-prefix}-stars-off:before { content: $ti-icon-stars-off; }
.#{$ti-prefix}-status-change:before { content: $ti-icon-status-change; }
.#{$ti-prefix}-steam:before { content: $ti-icon-steam; }
.#{$ti-prefix}-steering-wheel:before { content: $ti-icon-steering-wheel; }
.#{$ti-prefix}-steering-wheel-filled:before { content: $ti-icon-steering-wheel-filled; }
.#{$ti-prefix}-steering-wheel-off:before { content: $ti-icon-steering-wheel-off; }
.#{$ti-prefix}-step-into:before { content: $ti-icon-step-into; }
.#{$ti-prefix}-step-out:before { content: $ti-icon-step-out; }
.#{$ti-prefix}-stereo-glasses:before { content: $ti-icon-stereo-glasses; }
.#{$ti-prefix}-stethoscope:before { content: $ti-icon-stethoscope; }
.#{$ti-prefix}-stethoscope-off:before { content: $ti-icon-stethoscope-off; }
.#{$ti-prefix}-sticker:before { content: $ti-icon-sticker; }
.#{$ti-prefix}-sticker-2:before { content: $ti-icon-sticker-2; }
.#{$ti-prefix}-stopwatch:before { content: $ti-icon-stopwatch; }
.#{$ti-prefix}-storm:before { content: $ti-icon-storm; }
.#{$ti-prefix}-storm-off:before { content: $ti-icon-storm-off; }
.#{$ti-prefix}-stretching:before { content: $ti-icon-stretching; }
.#{$ti-prefix}-stretching-2:before { content: $ti-icon-stretching-2; }
.#{$ti-prefix}-strikethrough:before { content: $ti-icon-strikethrough; }
.#{$ti-prefix}-submarine:before { content: $ti-icon-submarine; }
.#{$ti-prefix}-subscript:before { content: $ti-icon-subscript; }
.#{$ti-prefix}-subtask:before { content: $ti-icon-subtask; }
.#{$ti-prefix}-sum:before { content: $ti-icon-sum; }
.#{$ti-prefix}-sum-off:before { content: $ti-icon-sum-off; }
.#{$ti-prefix}-sun:before { content: $ti-icon-sun; }
.#{$ti-prefix}-sun-electricity:before { content: $ti-icon-sun-electricity; }
.#{$ti-prefix}-sun-filled:before { content: $ti-icon-sun-filled; }
.#{$ti-prefix}-sun-high:before { content: $ti-icon-sun-high; }
.#{$ti-prefix}-sun-low:before { content: $ti-icon-sun-low; }
.#{$ti-prefix}-sun-moon:before { content: $ti-icon-sun-moon; }
.#{$ti-prefix}-sun-off:before { content: $ti-icon-sun-off; }
.#{$ti-prefix}-sun-wind:before { content: $ti-icon-sun-wind; }
.#{$ti-prefix}-sunglasses:before { content: $ti-icon-sunglasses; }
.#{$ti-prefix}-sunglasses-filled:before { content: $ti-icon-sunglasses-filled; }
.#{$ti-prefix}-sunrise:before { content: $ti-icon-sunrise; }
.#{$ti-prefix}-sunset:before { content: $ti-icon-sunset; }
.#{$ti-prefix}-sunset-2:before { content: $ti-icon-sunset-2; }
.#{$ti-prefix}-superscript:before { content: $ti-icon-superscript; }
.#{$ti-prefix}-svg:before { content: $ti-icon-svg; }
.#{$ti-prefix}-swimming:before { content: $ti-icon-swimming; }
.#{$ti-prefix}-swipe:before { content: $ti-icon-swipe; }
.#{$ti-prefix}-swipe-down:before { content: $ti-icon-swipe-down; }
.#{$ti-prefix}-swipe-down-filled:before { content: $ti-icon-swipe-down-filled; }
.#{$ti-prefix}-swipe-left:before { content: $ti-icon-swipe-left; }
.#{$ti-prefix}-swipe-left-filled:before { content: $ti-icon-swipe-left-filled; }
.#{$ti-prefix}-swipe-right:before { content: $ti-icon-swipe-right; }
.#{$ti-prefix}-swipe-right-filled:before { content: $ti-icon-swipe-right-filled; }
.#{$ti-prefix}-swipe-up:before { content: $ti-icon-swipe-up; }
.#{$ti-prefix}-swipe-up-filled:before { content: $ti-icon-swipe-up-filled; }
.#{$ti-prefix}-switch:before { content: $ti-icon-switch; }
.#{$ti-prefix}-switch-2:before { content: $ti-icon-switch-2; }
.#{$ti-prefix}-switch-3:before { content: $ti-icon-switch-3; }
.#{$ti-prefix}-switch-horizontal:before { content: $ti-icon-switch-horizontal; }
.#{$ti-prefix}-switch-vertical:before { content: $ti-icon-switch-vertical; }
.#{$ti-prefix}-sword:before { content: $ti-icon-sword; }
.#{$ti-prefix}-sword-off:before { content: $ti-icon-sword-off; }
.#{$ti-prefix}-swords:before { content: $ti-icon-swords; }
.#{$ti-prefix}-table:before { content: $ti-icon-table; }
.#{$ti-prefix}-table-alias:before { content: $ti-icon-table-alias; }
.#{$ti-prefix}-table-column:before { content: $ti-icon-table-column; }
.#{$ti-prefix}-table-down:before { content: $ti-icon-table-down; }
.#{$ti-prefix}-table-export:before { content: $ti-icon-table-export; }
.#{$ti-prefix}-table-filled:before { content: $ti-icon-table-filled; }
.#{$ti-prefix}-table-heart:before { content: $ti-icon-table-heart; }
.#{$ti-prefix}-table-import:before { content: $ti-icon-table-import; }
.#{$ti-prefix}-table-minus:before { content: $ti-icon-table-minus; }
.#{$ti-prefix}-table-off:before { content: $ti-icon-table-off; }
.#{$ti-prefix}-table-options:before { content: $ti-icon-table-options; }
.#{$ti-prefix}-table-plus:before { content: $ti-icon-table-plus; }
.#{$ti-prefix}-table-row:before { content: $ti-icon-table-row; }
.#{$ti-prefix}-table-share:before { content: $ti-icon-table-share; }
.#{$ti-prefix}-table-shortcut:before { content: $ti-icon-table-shortcut; }
.#{$ti-prefix}-table-spark:before { content: $ti-icon-table-spark; }
.#{$ti-prefix}-tag:before { content: $ti-icon-tag; }
.#{$ti-prefix}-tag-filled:before { content: $ti-icon-tag-filled; }
.#{$ti-prefix}-tag-off:before { content: $ti-icon-tag-off; }
.#{$ti-prefix}-tag-starred:before { content: $ti-icon-tag-starred; }
.#{$ti-prefix}-tags:before { content: $ti-icon-tags; }
.#{$ti-prefix}-tags-filled:before { content: $ti-icon-tags-filled; }
.#{$ti-prefix}-tags-off:before { content: $ti-icon-tags-off; }
.#{$ti-prefix}-tallymark-1:before { content: $ti-icon-tallymark-1; }
.#{$ti-prefix}-tallymark-2:before { content: $ti-icon-tallymark-2; }
.#{$ti-prefix}-tallymark-3:before { content: $ti-icon-tallymark-3; }
.#{$ti-prefix}-tallymark-4:before { content: $ti-icon-tallymark-4; }
.#{$ti-prefix}-tallymarks:before { content: $ti-icon-tallymarks; }
.#{$ti-prefix}-tank:before { content: $ti-icon-tank; }
.#{$ti-prefix}-target:before { content: $ti-icon-target; }
.#{$ti-prefix}-target-arrow:before { content: $ti-icon-target-arrow; }
.#{$ti-prefix}-target-off:before { content: $ti-icon-target-off; }
.#{$ti-prefix}-tax:before { content: $ti-icon-tax; }
.#{$ti-prefix}-tax-euro:before { content: $ti-icon-tax-euro; }
.#{$ti-prefix}-tax-pound:before { content: $ti-icon-tax-pound; }
.#{$ti-prefix}-teapot:before { content: $ti-icon-teapot; }
.#{$ti-prefix}-telescope:before { content: $ti-icon-telescope; }
.#{$ti-prefix}-telescope-off:before { content: $ti-icon-telescope-off; }
.#{$ti-prefix}-temperature:before { content: $ti-icon-temperature; }
.#{$ti-prefix}-temperature-celsius:before { content: $ti-icon-temperature-celsius; }
.#{$ti-prefix}-temperature-fahrenheit:before { content: $ti-icon-temperature-fahrenheit; }
.#{$ti-prefix}-temperature-minus:before { content: $ti-icon-temperature-minus; }
.#{$ti-prefix}-temperature-off:before { content: $ti-icon-temperature-off; }
.#{$ti-prefix}-temperature-plus:before { content: $ti-icon-temperature-plus; }
.#{$ti-prefix}-temperature-snow:before { content: $ti-icon-temperature-snow; }
.#{$ti-prefix}-temperature-sun:before { content: $ti-icon-temperature-sun; }
.#{$ti-prefix}-template:before { content: $ti-icon-template; }
.#{$ti-prefix}-template-off:before { content: $ti-icon-template-off; }
.#{$ti-prefix}-tent:before { content: $ti-icon-tent; }
.#{$ti-prefix}-tent-off:before { content: $ti-icon-tent-off; }
.#{$ti-prefix}-terminal:before { content: $ti-icon-terminal; }
.#{$ti-prefix}-terminal-2:before { content: $ti-icon-terminal-2; }
.#{$ti-prefix}-test-pipe:before { content: $ti-icon-test-pipe; }
.#{$ti-prefix}-test-pipe-2:before { content: $ti-icon-test-pipe-2; }
.#{$ti-prefix}-test-pipe-2-filled:before { content: $ti-icon-test-pipe-2-filled; }
.#{$ti-prefix}-test-pipe-off:before { content: $ti-icon-test-pipe-off; }
.#{$ti-prefix}-tex:before { content: $ti-icon-tex; }
.#{$ti-prefix}-text-caption:before { content: $ti-icon-text-caption; }
.#{$ti-prefix}-text-color:before { content: $ti-icon-text-color; }
.#{$ti-prefix}-text-decrease:before { content: $ti-icon-text-decrease; }
.#{$ti-prefix}-text-direction-ltr:before { content: $ti-icon-text-direction-ltr; }
.#{$ti-prefix}-text-direction-rtl:before { content: $ti-icon-text-direction-rtl; }
.#{$ti-prefix}-text-grammar:before { content: $ti-icon-text-grammar; }
.#{$ti-prefix}-text-increase:before { content: $ti-icon-text-increase; }
.#{$ti-prefix}-text-orientation:before { content: $ti-icon-text-orientation; }
.#{$ti-prefix}-text-plus:before { content: $ti-icon-text-plus; }
.#{$ti-prefix}-text-recognition:before { content: $ti-icon-text-recognition; }
.#{$ti-prefix}-text-resize:before { content: $ti-icon-text-resize; }
.#{$ti-prefix}-text-scan-2:before { content: $ti-icon-text-scan-2; }
.#{$ti-prefix}-text-size:before { content: $ti-icon-text-size; }
.#{$ti-prefix}-text-spellcheck:before { content: $ti-icon-text-spellcheck; }
.#{$ti-prefix}-text-wrap:before { content: $ti-icon-text-wrap; }
.#{$ti-prefix}-text-wrap-column:before { content: $ti-icon-text-wrap-column; }
.#{$ti-prefix}-text-wrap-disabled:before { content: $ti-icon-text-wrap-disabled; }
.#{$ti-prefix}-texture:before { content: $ti-icon-texture; }
.#{$ti-prefix}-theater:before { content: $ti-icon-theater; }
.#{$ti-prefix}-thermometer:before { content: $ti-icon-thermometer; }
.#{$ti-prefix}-thumb-down:before { content: $ti-icon-thumb-down; }
.#{$ti-prefix}-thumb-down-filled:before { content: $ti-icon-thumb-down-filled; }
.#{$ti-prefix}-thumb-down-off:before { content: $ti-icon-thumb-down-off; }
.#{$ti-prefix}-thumb-up:before { content: $ti-icon-thumb-up; }
.#{$ti-prefix}-thumb-up-filled:before { content: $ti-icon-thumb-up-filled; }
.#{$ti-prefix}-thumb-up-off:before { content: $ti-icon-thumb-up-off; }
.#{$ti-prefix}-tic-tac:before { content: $ti-icon-tic-tac; }
.#{$ti-prefix}-ticket:before { content: $ti-icon-ticket; }
.#{$ti-prefix}-ticket-off:before { content: $ti-icon-ticket-off; }
.#{$ti-prefix}-tie:before { content: $ti-icon-tie; }
.#{$ti-prefix}-tilde:before { content: $ti-icon-tilde; }
.#{$ti-prefix}-tilt-shift:before { content: $ti-icon-tilt-shift; }
.#{$ti-prefix}-tilt-shift-filled:before { content: $ti-icon-tilt-shift-filled; }
.#{$ti-prefix}-tilt-shift-off:before { content: $ti-icon-tilt-shift-off; }
.#{$ti-prefix}-time-duration-0:before { content: $ti-icon-time-duration-0; }
.#{$ti-prefix}-time-duration-10:before { content: $ti-icon-time-duration-10; }
.#{$ti-prefix}-time-duration-15:before { content: $ti-icon-time-duration-15; }
.#{$ti-prefix}-time-duration-30:before { content: $ti-icon-time-duration-30; }
.#{$ti-prefix}-time-duration-45:before { content: $ti-icon-time-duration-45; }
.#{$ti-prefix}-time-duration-5:before { content: $ti-icon-time-duration-5; }
.#{$ti-prefix}-time-duration-60:before { content: $ti-icon-time-duration-60; }
.#{$ti-prefix}-time-duration-90:before { content: $ti-icon-time-duration-90; }
.#{$ti-prefix}-time-duration-off:before { content: $ti-icon-time-duration-off; }
.#{$ti-prefix}-timeline:before { content: $ti-icon-timeline; }
.#{$ti-prefix}-timeline-event:before { content: $ti-icon-timeline-event; }
.#{$ti-prefix}-timeline-event-exclamation:before { content: $ti-icon-timeline-event-exclamation; }
.#{$ti-prefix}-timeline-event-filled:before { content: $ti-icon-timeline-event-filled; }
.#{$ti-prefix}-timeline-event-minus:before { content: $ti-icon-timeline-event-minus; }
.#{$ti-prefix}-timeline-event-plus:before { content: $ti-icon-timeline-event-plus; }
.#{$ti-prefix}-timeline-event-text:before { content: $ti-icon-timeline-event-text; }
.#{$ti-prefix}-timeline-event-x:before { content: $ti-icon-timeline-event-x; }
.#{$ti-prefix}-timezone:before { content: $ti-icon-timezone; }
.#{$ti-prefix}-tip-jar:before { content: $ti-icon-tip-jar; }
.#{$ti-prefix}-tip-jar-euro:before { content: $ti-icon-tip-jar-euro; }
.#{$ti-prefix}-tip-jar-pound:before { content: $ti-icon-tip-jar-pound; }
.#{$ti-prefix}-tir:before { content: $ti-icon-tir; }
.#{$ti-prefix}-toggle-left:before { content: $ti-icon-toggle-left; }
.#{$ti-prefix}-toggle-left-filled:before { content: $ti-icon-toggle-left-filled; }
.#{$ti-prefix}-toggle-right:before { content: $ti-icon-toggle-right; }
.#{$ti-prefix}-toggle-right-filled:before { content: $ti-icon-toggle-right-filled; }
.#{$ti-prefix}-toilet-paper:before { content: $ti-icon-toilet-paper; }
.#{$ti-prefix}-toilet-paper-off:before { content: $ti-icon-toilet-paper-off; }
.#{$ti-prefix}-toml:before { content: $ti-icon-toml; }
.#{$ti-prefix}-tool:before { content: $ti-icon-tool; }
.#{$ti-prefix}-tools:before { content: $ti-icon-tools; }
.#{$ti-prefix}-tools-kitchen:before { content: $ti-icon-tools-kitchen; }
.#{$ti-prefix}-tools-kitchen-2:before { content: $ti-icon-tools-kitchen-2; }
.#{$ti-prefix}-tools-kitchen-2-off:before { content: $ti-icon-tools-kitchen-2-off; }
.#{$ti-prefix}-tools-kitchen-3:before { content: $ti-icon-tools-kitchen-3; }
.#{$ti-prefix}-tools-kitchen-off:before { content: $ti-icon-tools-kitchen-off; }
.#{$ti-prefix}-tools-off:before { content: $ti-icon-tools-off; }
.#{$ti-prefix}-tooltip:before { content: $ti-icon-tooltip; }
.#{$ti-prefix}-topology-bus:before { content: $ti-icon-topology-bus; }
.#{$ti-prefix}-topology-complex:before { content: $ti-icon-topology-complex; }
.#{$ti-prefix}-topology-full:before { content: $ti-icon-topology-full; }
.#{$ti-prefix}-topology-full-hierarchy:before { content: $ti-icon-topology-full-hierarchy; }
.#{$ti-prefix}-topology-ring:before { content: $ti-icon-topology-ring; }
.#{$ti-prefix}-topology-ring-2:before { content: $ti-icon-topology-ring-2; }
.#{$ti-prefix}-topology-ring-3:before { content: $ti-icon-topology-ring-3; }
.#{$ti-prefix}-topology-star:before { content: $ti-icon-topology-star; }
.#{$ti-prefix}-topology-star-2:before { content: $ti-icon-topology-star-2; }
.#{$ti-prefix}-topology-star-3:before { content: $ti-icon-topology-star-3; }
.#{$ti-prefix}-topology-star-ring:before { content: $ti-icon-topology-star-ring; }
.#{$ti-prefix}-topology-star-ring-2:before { content: $ti-icon-topology-star-ring-2; }
.#{$ti-prefix}-topology-star-ring-3:before { content: $ti-icon-topology-star-ring-3; }
.#{$ti-prefix}-torii:before { content: $ti-icon-torii; }
.#{$ti-prefix}-tornado:before { content: $ti-icon-tornado; }
.#{$ti-prefix}-tournament:before { content: $ti-icon-tournament; }
.#{$ti-prefix}-tower:before { content: $ti-icon-tower; }
.#{$ti-prefix}-tower-off:before { content: $ti-icon-tower-off; }
.#{$ti-prefix}-track:before { content: $ti-icon-track; }
.#{$ti-prefix}-tractor:before { content: $ti-icon-tractor; }
.#{$ti-prefix}-trademark:before { content: $ti-icon-trademark; }
.#{$ti-prefix}-traffic-cone:before { content: $ti-icon-traffic-cone; }
.#{$ti-prefix}-traffic-cone-off:before { content: $ti-icon-traffic-cone-off; }
.#{$ti-prefix}-traffic-lights:before { content: $ti-icon-traffic-lights; }
.#{$ti-prefix}-traffic-lights-off:before { content: $ti-icon-traffic-lights-off; }
.#{$ti-prefix}-train:before { content: $ti-icon-train; }
.#{$ti-prefix}-transaction-bitcoin:before { content: $ti-icon-transaction-bitcoin; }
.#{$ti-prefix}-transaction-dollar:before { content: $ti-icon-transaction-dollar; }
.#{$ti-prefix}-transaction-euro:before { content: $ti-icon-transaction-euro; }
.#{$ti-prefix}-transaction-pound:before { content: $ti-icon-transaction-pound; }
.#{$ti-prefix}-transaction-rupee:before { content: $ti-icon-transaction-rupee; }
.#{$ti-prefix}-transaction-yen:before { content: $ti-icon-transaction-yen; }
.#{$ti-prefix}-transaction-yuan:before { content: $ti-icon-transaction-yuan; }
.#{$ti-prefix}-transfer:before { content: $ti-icon-transfer; }
.#{$ti-prefix}-transfer-in:before { content: $ti-icon-transfer-in; }
.#{$ti-prefix}-transfer-out:before { content: $ti-icon-transfer-out; }
.#{$ti-prefix}-transfer-vertical:before { content: $ti-icon-transfer-vertical; }
.#{$ti-prefix}-transform:before { content: $ti-icon-transform; }
.#{$ti-prefix}-transform-filled:before { content: $ti-icon-transform-filled; }
.#{$ti-prefix}-transform-point:before { content: $ti-icon-transform-point; }
.#{$ti-prefix}-transform-point-bottom-left:before { content: $ti-icon-transform-point-bottom-left; }
.#{$ti-prefix}-transform-point-bottom-right:before { content: $ti-icon-transform-point-bottom-right; }
.#{$ti-prefix}-transform-point-top-left:before { content: $ti-icon-transform-point-top-left; }
.#{$ti-prefix}-transform-point-top-right:before { content: $ti-icon-transform-point-top-right; }
.#{$ti-prefix}-transition-bottom:before { content: $ti-icon-transition-bottom; }
.#{$ti-prefix}-transition-bottom-filled:before { content: $ti-icon-transition-bottom-filled; }
.#{$ti-prefix}-transition-left:before { content: $ti-icon-transition-left; }
.#{$ti-prefix}-transition-left-filled:before { content: $ti-icon-transition-left-filled; }
.#{$ti-prefix}-transition-right:before { content: $ti-icon-transition-right; }
.#{$ti-prefix}-transition-right-filled:before { content: $ti-icon-transition-right-filled; }
.#{$ti-prefix}-transition-top:before { content: $ti-icon-transition-top; }
.#{$ti-prefix}-transition-top-filled:before { content: $ti-icon-transition-top-filled; }
.#{$ti-prefix}-trash:before { content: $ti-icon-trash; }
.#{$ti-prefix}-trash-filled:before { content: $ti-icon-trash-filled; }
.#{$ti-prefix}-trash-off:before { content: $ti-icon-trash-off; }
.#{$ti-prefix}-trash-x:before { content: $ti-icon-trash-x; }
.#{$ti-prefix}-trash-x-filled:before { content: $ti-icon-trash-x-filled; }
.#{$ti-prefix}-treadmill:before { content: $ti-icon-treadmill; }
.#{$ti-prefix}-tree:before { content: $ti-icon-tree; }
.#{$ti-prefix}-trees:before { content: $ti-icon-trees; }
.#{$ti-prefix}-trekking:before { content: $ti-icon-trekking; }
.#{$ti-prefix}-trending-down:before { content: $ti-icon-trending-down; }
.#{$ti-prefix}-trending-down-2:before { content: $ti-icon-trending-down-2; }
.#{$ti-prefix}-trending-down-3:before { content: $ti-icon-trending-down-3; }
.#{$ti-prefix}-trending-up:before { content: $ti-icon-trending-up; }
.#{$ti-prefix}-trending-up-2:before { content: $ti-icon-trending-up-2; }
.#{$ti-prefix}-trending-up-3:before { content: $ti-icon-trending-up-3; }
.#{$ti-prefix}-triangle:before { content: $ti-icon-triangle; }
.#{$ti-prefix}-triangle-filled:before { content: $ti-icon-triangle-filled; }
.#{$ti-prefix}-triangle-inverted:before { content: $ti-icon-triangle-inverted; }
.#{$ti-prefix}-triangle-inverted-filled:before { content: $ti-icon-triangle-inverted-filled; }
.#{$ti-prefix}-triangle-minus:before { content: $ti-icon-triangle-minus; }
.#{$ti-prefix}-triangle-minus-2:before { content: $ti-icon-triangle-minus-2; }
.#{$ti-prefix}-triangle-off:before { content: $ti-icon-triangle-off; }
.#{$ti-prefix}-triangle-plus:before { content: $ti-icon-triangle-plus; }
.#{$ti-prefix}-triangle-plus-2:before { content: $ti-icon-triangle-plus-2; }
.#{$ti-prefix}-triangle-square-circle:before { content: $ti-icon-triangle-square-circle; }
.#{$ti-prefix}-triangle-square-circle-filled:before { content: $ti-icon-triangle-square-circle-filled; }
.#{$ti-prefix}-triangles:before { content: $ti-icon-triangles; }
.#{$ti-prefix}-trident:before { content: $ti-icon-trident; }
.#{$ti-prefix}-trolley:before { content: $ti-icon-trolley; }
.#{$ti-prefix}-trophy:before { content: $ti-icon-trophy; }
.#{$ti-prefix}-trophy-filled:before { content: $ti-icon-trophy-filled; }
.#{$ti-prefix}-trophy-off:before { content: $ti-icon-trophy-off; }
.#{$ti-prefix}-trowel:before { content: $ti-icon-trowel; }
.#{$ti-prefix}-truck:before { content: $ti-icon-truck; }
.#{$ti-prefix}-truck-delivery:before { content: $ti-icon-truck-delivery; }
.#{$ti-prefix}-truck-loading:before { content: $ti-icon-truck-loading; }
.#{$ti-prefix}-truck-off:before { content: $ti-icon-truck-off; }
.#{$ti-prefix}-truck-return:before { content: $ti-icon-truck-return; }
.#{$ti-prefix}-txt:before { content: $ti-icon-txt; }
.#{$ti-prefix}-typeface:before { content: $ti-icon-typeface; }
.#{$ti-prefix}-typography:before { content: $ti-icon-typography; }
.#{$ti-prefix}-typography-off:before { content: $ti-icon-typography-off; }
.#{$ti-prefix}-u-turn-left:before { content: $ti-icon-u-turn-left; }
.#{$ti-prefix}-u-turn-right:before { content: $ti-icon-u-turn-right; }
.#{$ti-prefix}-ufo:before { content: $ti-icon-ufo; }
.#{$ti-prefix}-ufo-off:before { content: $ti-icon-ufo-off; }
.#{$ti-prefix}-umbrella:before { content: $ti-icon-umbrella; }
.#{$ti-prefix}-umbrella-2:before { content: $ti-icon-umbrella-2; }
.#{$ti-prefix}-umbrella-closed:before { content: $ti-icon-umbrella-closed; }
.#{$ti-prefix}-umbrella-closed-2:before { content: $ti-icon-umbrella-closed-2; }
.#{$ti-prefix}-umbrella-filled:before { content: $ti-icon-umbrella-filled; }
.#{$ti-prefix}-umbrella-off:before { content: $ti-icon-umbrella-off; }
.#{$ti-prefix}-underline:before { content: $ti-icon-underline; }
.#{$ti-prefix}-universe:before { content: $ti-icon-universe; }
.#{$ti-prefix}-unlink:before { content: $ti-icon-unlink; }
.#{$ti-prefix}-upload:before { content: $ti-icon-upload; }
.#{$ti-prefix}-urgent:before { content: $ti-icon-urgent; }
.#{$ti-prefix}-usb:before { content: $ti-icon-usb; }
.#{$ti-prefix}-user:before { content: $ti-icon-user; }
.#{$ti-prefix}-user-bitcoin:before { content: $ti-icon-user-bitcoin; }
.#{$ti-prefix}-user-bolt:before { content: $ti-icon-user-bolt; }
.#{$ti-prefix}-user-cancel:before { content: $ti-icon-user-cancel; }
.#{$ti-prefix}-user-check:before { content: $ti-icon-user-check; }
.#{$ti-prefix}-user-circle:before { content: $ti-icon-user-circle; }
.#{$ti-prefix}-user-code:before { content: $ti-icon-user-code; }
.#{$ti-prefix}-user-cog:before { content: $ti-icon-user-cog; }
.#{$ti-prefix}-user-dollar:before { content: $ti-icon-user-dollar; }
.#{$ti-prefix}-user-down:before { content: $ti-icon-user-down; }
.#{$ti-prefix}-user-edit:before { content: $ti-icon-user-edit; }
.#{$ti-prefix}-user-exclamation:before { content: $ti-icon-user-exclamation; }
.#{$ti-prefix}-user-filled:before { content: $ti-icon-user-filled; }
.#{$ti-prefix}-user-heart:before { content: $ti-icon-user-heart; }
.#{$ti-prefix}-user-hexagon:before { content: $ti-icon-user-hexagon; }
.#{$ti-prefix}-user-minus:before { content: $ti-icon-user-minus; }
.#{$ti-prefix}-user-off:before { content: $ti-icon-user-off; }
.#{$ti-prefix}-user-pause:before { content: $ti-icon-user-pause; }
.#{$ti-prefix}-user-pentagon:before { content: $ti-icon-user-pentagon; }
.#{$ti-prefix}-user-pin:before { content: $ti-icon-user-pin; }
.#{$ti-prefix}-user-plus:before { content: $ti-icon-user-plus; }
.#{$ti-prefix}-user-question:before { content: $ti-icon-user-question; }
.#{$ti-prefix}-user-scan:before { content: $ti-icon-user-scan; }
.#{$ti-prefix}-user-screen:before { content: $ti-icon-user-screen; }
.#{$ti-prefix}-user-search:before { content: $ti-icon-user-search; }
.#{$ti-prefix}-user-share:before { content: $ti-icon-user-share; }
.#{$ti-prefix}-user-shield:before { content: $ti-icon-user-shield; }
.#{$ti-prefix}-user-square:before { content: $ti-icon-user-square; }
.#{$ti-prefix}-user-square-rounded:before { content: $ti-icon-user-square-rounded; }
.#{$ti-prefix}-user-star:before { content: $ti-icon-user-star; }
.#{$ti-prefix}-user-up:before { content: $ti-icon-user-up; }
.#{$ti-prefix}-user-x:before { content: $ti-icon-user-x; }
.#{$ti-prefix}-users:before { content: $ti-icon-users; }
.#{$ti-prefix}-users-group:before { content: $ti-icon-users-group; }
.#{$ti-prefix}-users-minus:before { content: $ti-icon-users-minus; }
.#{$ti-prefix}-users-plus:before { content: $ti-icon-users-plus; }
.#{$ti-prefix}-uv-index:before { content: $ti-icon-uv-index; }
.#{$ti-prefix}-ux-circle:before { content: $ti-icon-ux-circle; }
.#{$ti-prefix}-vaccine:before { content: $ti-icon-vaccine; }
.#{$ti-prefix}-vaccine-bottle:before { content: $ti-icon-vaccine-bottle; }
.#{$ti-prefix}-vaccine-bottle-off:before { content: $ti-icon-vaccine-bottle-off; }
.#{$ti-prefix}-vaccine-off:before { content: $ti-icon-vaccine-off; }
.#{$ti-prefix}-vacuum-cleaner:before { content: $ti-icon-vacuum-cleaner; }
.#{$ti-prefix}-variable:before { content: $ti-icon-variable; }
.#{$ti-prefix}-variable-minus:before { content: $ti-icon-variable-minus; }
.#{$ti-prefix}-variable-off:before { content: $ti-icon-variable-off; }
.#{$ti-prefix}-variable-plus:before { content: $ti-icon-variable-plus; }
.#{$ti-prefix}-vector:before { content: $ti-icon-vector; }
.#{$ti-prefix}-vector-bezier:before { content: $ti-icon-vector-bezier; }
.#{$ti-prefix}-vector-bezier-2:before { content: $ti-icon-vector-bezier-2; }
.#{$ti-prefix}-vector-bezier-arc:before { content: $ti-icon-vector-bezier-arc; }
.#{$ti-prefix}-vector-bezier-circle:before { content: $ti-icon-vector-bezier-circle; }
.#{$ti-prefix}-vector-off:before { content: $ti-icon-vector-off; }
.#{$ti-prefix}-vector-spline:before { content: $ti-icon-vector-spline; }
.#{$ti-prefix}-vector-triangle:before { content: $ti-icon-vector-triangle; }
.#{$ti-prefix}-vector-triangle-off:before { content: $ti-icon-vector-triangle-off; }
.#{$ti-prefix}-venus:before { content: $ti-icon-venus; }
.#{$ti-prefix}-versions:before { content: $ti-icon-versions; }
.#{$ti-prefix}-versions-filled:before { content: $ti-icon-versions-filled; }
.#{$ti-prefix}-versions-off:before { content: $ti-icon-versions-off; }
.#{$ti-prefix}-video:before { content: $ti-icon-video; }
.#{$ti-prefix}-video-minus:before { content: $ti-icon-video-minus; }
.#{$ti-prefix}-video-off:before { content: $ti-icon-video-off; }
.#{$ti-prefix}-video-plus:before { content: $ti-icon-video-plus; }
.#{$ti-prefix}-view-360:before { content: $ti-icon-view-360; }
.#{$ti-prefix}-view-360-arrow:before { content: $ti-icon-view-360-arrow; }
.#{$ti-prefix}-view-360-number:before { content: $ti-icon-view-360-number; }
.#{$ti-prefix}-view-360-off:before { content: $ti-icon-view-360-off; }
.#{$ti-prefix}-viewfinder:before { content: $ti-icon-viewfinder; }
.#{$ti-prefix}-viewfinder-off:before { content: $ti-icon-viewfinder-off; }
.#{$ti-prefix}-viewport-narrow:before { content: $ti-icon-viewport-narrow; }
.#{$ti-prefix}-viewport-short:before { content: $ti-icon-viewport-short; }
.#{$ti-prefix}-viewport-tall:before { content: $ti-icon-viewport-tall; }
.#{$ti-prefix}-viewport-wide:before { content: $ti-icon-viewport-wide; }
.#{$ti-prefix}-vinyl:before { content: $ti-icon-vinyl; }
.#{$ti-prefix}-vip:before { content: $ti-icon-vip; }
.#{$ti-prefix}-vip-off:before { content: $ti-icon-vip-off; }
.#{$ti-prefix}-virus:before { content: $ti-icon-virus; }
.#{$ti-prefix}-virus-off:before { content: $ti-icon-virus-off; }
.#{$ti-prefix}-virus-search:before { content: $ti-icon-virus-search; }
.#{$ti-prefix}-vocabulary:before { content: $ti-icon-vocabulary; }
.#{$ti-prefix}-vocabulary-off:before { content: $ti-icon-vocabulary-off; }
.#{$ti-prefix}-volcano:before { content: $ti-icon-volcano; }
.#{$ti-prefix}-volume:before { content: $ti-icon-volume; }
.#{$ti-prefix}-volume-2:before { content: $ti-icon-volume-2; }
.#{$ti-prefix}-volume-3:before { content: $ti-icon-volume-3; }
.#{$ti-prefix}-volume-off:before { content: $ti-icon-volume-off; }
.#{$ti-prefix}-vs:before { content: $ti-icon-vs; }
.#{$ti-prefix}-walk:before { content: $ti-icon-walk; }
.#{$ti-prefix}-wall:before { content: $ti-icon-wall; }
.#{$ti-prefix}-wall-off:before { content: $ti-icon-wall-off; }
.#{$ti-prefix}-wallet:before { content: $ti-icon-wallet; }
.#{$ti-prefix}-wallet-off:before { content: $ti-icon-wallet-off; }
.#{$ti-prefix}-wallpaper:before { content: $ti-icon-wallpaper; }
.#{$ti-prefix}-wallpaper-off:before { content: $ti-icon-wallpaper-off; }
.#{$ti-prefix}-wand:before { content: $ti-icon-wand; }
.#{$ti-prefix}-wand-off:before { content: $ti-icon-wand-off; }
.#{$ti-prefix}-wash:before { content: $ti-icon-wash; }
.#{$ti-prefix}-wash-dry:before { content: $ti-icon-wash-dry; }
.#{$ti-prefix}-wash-dry-1:before { content: $ti-icon-wash-dry-1; }
.#{$ti-prefix}-wash-dry-2:before { content: $ti-icon-wash-dry-2; }
.#{$ti-prefix}-wash-dry-3:before { content: $ti-icon-wash-dry-3; }
.#{$ti-prefix}-wash-dry-a:before { content: $ti-icon-wash-dry-a; }
.#{$ti-prefix}-wash-dry-dip:before { content: $ti-icon-wash-dry-dip; }
.#{$ti-prefix}-wash-dry-f:before { content: $ti-icon-wash-dry-f; }
.#{$ti-prefix}-wash-dry-flat:before { content: $ti-icon-wash-dry-flat; }
.#{$ti-prefix}-wash-dry-hang:before { content: $ti-icon-wash-dry-hang; }
.#{$ti-prefix}-wash-dry-off:before { content: $ti-icon-wash-dry-off; }
.#{$ti-prefix}-wash-dry-p:before { content: $ti-icon-wash-dry-p; }
.#{$ti-prefix}-wash-dry-shade:before { content: $ti-icon-wash-dry-shade; }
.#{$ti-prefix}-wash-dry-w:before { content: $ti-icon-wash-dry-w; }
.#{$ti-prefix}-wash-dryclean:before { content: $ti-icon-wash-dryclean; }
.#{$ti-prefix}-wash-dryclean-off:before { content: $ti-icon-wash-dryclean-off; }
.#{$ti-prefix}-wash-eco:before { content: $ti-icon-wash-eco; }
.#{$ti-prefix}-wash-gentle:before { content: $ti-icon-wash-gentle; }
.#{$ti-prefix}-wash-hand:before { content: $ti-icon-wash-hand; }
.#{$ti-prefix}-wash-machine:before { content: $ti-icon-wash-machine; }
.#{$ti-prefix}-wash-off:before { content: $ti-icon-wash-off; }
.#{$ti-prefix}-wash-press:before { content: $ti-icon-wash-press; }
.#{$ti-prefix}-wash-temperature-1:before { content: $ti-icon-wash-temperature-1; }
.#{$ti-prefix}-wash-temperature-2:before { content: $ti-icon-wash-temperature-2; }
.#{$ti-prefix}-wash-temperature-3:before { content: $ti-icon-wash-temperature-3; }
.#{$ti-prefix}-wash-temperature-4:before { content: $ti-icon-wash-temperature-4; }
.#{$ti-prefix}-wash-temperature-5:before { content: $ti-icon-wash-temperature-5; }
.#{$ti-prefix}-wash-temperature-6:before { content: $ti-icon-wash-temperature-6; }
.#{$ti-prefix}-wash-tumble-dry:before { content: $ti-icon-wash-tumble-dry; }
.#{$ti-prefix}-wash-tumble-off:before { content: $ti-icon-wash-tumble-off; }
.#{$ti-prefix}-waterpolo:before { content: $ti-icon-waterpolo; }
.#{$ti-prefix}-wave-saw-tool:before { content: $ti-icon-wave-saw-tool; }
.#{$ti-prefix}-wave-sine:before { content: $ti-icon-wave-sine; }
.#{$ti-prefix}-wave-square:before { content: $ti-icon-wave-square; }
.#{$ti-prefix}-waves-electricity:before { content: $ti-icon-waves-electricity; }
.#{$ti-prefix}-webhook:before { content: $ti-icon-webhook; }
.#{$ti-prefix}-webhook-off:before { content: $ti-icon-webhook-off; }
.#{$ti-prefix}-weight:before { content: $ti-icon-weight; }
.#{$ti-prefix}-wheel:before { content: $ti-icon-wheel; }
.#{$ti-prefix}-wheelchair:before { content: $ti-icon-wheelchair; }
.#{$ti-prefix}-wheelchair-off:before { content: $ti-icon-wheelchair-off; }
.#{$ti-prefix}-whirl:before { content: $ti-icon-whirl; }
.#{$ti-prefix}-wifi:before { content: $ti-icon-wifi; }
.#{$ti-prefix}-wifi-0:before { content: $ti-icon-wifi-0; }
.#{$ti-prefix}-wifi-1:before { content: $ti-icon-wifi-1; }
.#{$ti-prefix}-wifi-2:before { content: $ti-icon-wifi-2; }
.#{$ti-prefix}-wifi-off:before { content: $ti-icon-wifi-off; }
.#{$ti-prefix}-wind:before { content: $ti-icon-wind; }
.#{$ti-prefix}-wind-electricity:before { content: $ti-icon-wind-electricity; }
.#{$ti-prefix}-wind-off:before { content: $ti-icon-wind-off; }
.#{$ti-prefix}-windmill:before { content: $ti-icon-windmill; }
.#{$ti-prefix}-windmill-filled:before { content: $ti-icon-windmill-filled; }
.#{$ti-prefix}-windmill-off:before { content: $ti-icon-windmill-off; }
.#{$ti-prefix}-window:before { content: $ti-icon-window; }
.#{$ti-prefix}-window-maximize:before { content: $ti-icon-window-maximize; }
.#{$ti-prefix}-window-minimize:before { content: $ti-icon-window-minimize; }
.#{$ti-prefix}-window-off:before { content: $ti-icon-window-off; }
.#{$ti-prefix}-windsock:before { content: $ti-icon-windsock; }
.#{$ti-prefix}-wiper:before { content: $ti-icon-wiper; }
.#{$ti-prefix}-wiper-wash:before { content: $ti-icon-wiper-wash; }
.#{$ti-prefix}-woman:before { content: $ti-icon-woman; }
.#{$ti-prefix}-woman-filled:before { content: $ti-icon-woman-filled; }
.#{$ti-prefix}-wood:before { content: $ti-icon-wood; }
.#{$ti-prefix}-world:before { content: $ti-icon-world; }
.#{$ti-prefix}-world-bolt:before { content: $ti-icon-world-bolt; }
.#{$ti-prefix}-world-cancel:before { content: $ti-icon-world-cancel; }
.#{$ti-prefix}-world-check:before { content: $ti-icon-world-check; }
.#{$ti-prefix}-world-code:before { content: $ti-icon-world-code; }
.#{$ti-prefix}-world-cog:before { content: $ti-icon-world-cog; }
.#{$ti-prefix}-world-dollar:before { content: $ti-icon-world-dollar; }
.#{$ti-prefix}-world-down:before { content: $ti-icon-world-down; }
.#{$ti-prefix}-world-download:before { content: $ti-icon-world-download; }
.#{$ti-prefix}-world-exclamation:before { content: $ti-icon-world-exclamation; }
.#{$ti-prefix}-world-heart:before { content: $ti-icon-world-heart; }
.#{$ti-prefix}-world-latitude:before { content: $ti-icon-world-latitude; }
.#{$ti-prefix}-world-longitude:before { content: $ti-icon-world-longitude; }
.#{$ti-prefix}-world-minus:before { content: $ti-icon-world-minus; }
.#{$ti-prefix}-world-off:before { content: $ti-icon-world-off; }
.#{$ti-prefix}-world-pause:before { content: $ti-icon-world-pause; }
.#{$ti-prefix}-world-pin:before { content: $ti-icon-world-pin; }
.#{$ti-prefix}-world-plus:before { content: $ti-icon-world-plus; }
.#{$ti-prefix}-world-question:before { content: $ti-icon-world-question; }
.#{$ti-prefix}-world-search:before { content: $ti-icon-world-search; }
.#{$ti-prefix}-world-share:before { content: $ti-icon-world-share; }
.#{$ti-prefix}-world-star:before { content: $ti-icon-world-star; }
.#{$ti-prefix}-world-up:before { content: $ti-icon-world-up; }
.#{$ti-prefix}-world-upload:before { content: $ti-icon-world-upload; }
.#{$ti-prefix}-world-www:before { content: $ti-icon-world-www; }
.#{$ti-prefix}-world-x:before { content: $ti-icon-world-x; }
.#{$ti-prefix}-wrecking-ball:before { content: $ti-icon-wrecking-ball; }
.#{$ti-prefix}-writing:before { content: $ti-icon-writing; }
.#{$ti-prefix}-writing-off:before { content: $ti-icon-writing-off; }
.#{$ti-prefix}-writing-sign:before { content: $ti-icon-writing-sign; }
.#{$ti-prefix}-writing-sign-off:before { content: $ti-icon-writing-sign-off; }
.#{$ti-prefix}-x:before { content: $ti-icon-x; }
.#{$ti-prefix}-x-power-y:before { content: $ti-icon-x-power-y; }
.#{$ti-prefix}-xbox-a:before { content: $ti-icon-xbox-a; }
.#{$ti-prefix}-xbox-a-filled:before { content: $ti-icon-xbox-a-filled; }
.#{$ti-prefix}-xbox-b:before { content: $ti-icon-xbox-b; }
.#{$ti-prefix}-xbox-b-filled:before { content: $ti-icon-xbox-b-filled; }
.#{$ti-prefix}-xbox-x:before { content: $ti-icon-xbox-x; }
.#{$ti-prefix}-xbox-x-filled:before { content: $ti-icon-xbox-x-filled; }
.#{$ti-prefix}-xbox-y:before { content: $ti-icon-xbox-y; }
.#{$ti-prefix}-xbox-y-filled:before { content: $ti-icon-xbox-y-filled; }
.#{$ti-prefix}-xd:before { content: $ti-icon-xd; }
.#{$ti-prefix}-xxx:before { content: $ti-icon-xxx; }
.#{$ti-prefix}-yin-yang:before { content: $ti-icon-yin-yang; }
.#{$ti-prefix}-yin-yang-filled:before { content: $ti-icon-yin-yang-filled; }
.#{$ti-prefix}-yoga:before { content: $ti-icon-yoga; }
.#{$ti-prefix}-zeppelin:before { content: $ti-icon-zeppelin; }
.#{$ti-prefix}-zeppelin-filled:before { content: $ti-icon-zeppelin-filled; }
.#{$ti-prefix}-zeppelin-off:before { content: $ti-icon-zeppelin-off; }
.#{$ti-prefix}-zip:before { content: $ti-icon-zip; }
.#{$ti-prefix}-zodiac-aquarius:before { content: $ti-icon-zodiac-aquarius; }
.#{$ti-prefix}-zodiac-aries:before { content: $ti-icon-zodiac-aries; }
.#{$ti-prefix}-zodiac-cancer:before { content: $ti-icon-zodiac-cancer; }
.#{$ti-prefix}-zodiac-capricorn:before { content: $ti-icon-zodiac-capricorn; }
.#{$ti-prefix}-zodiac-gemini:before { content: $ti-icon-zodiac-gemini; }
.#{$ti-prefix}-zodiac-leo:before { content: $ti-icon-zodiac-leo; }
.#{$ti-prefix}-zodiac-libra:before { content: $ti-icon-zodiac-libra; }
.#{$ti-prefix}-zodiac-pisces:before { content: $ti-icon-zodiac-pisces; }
.#{$ti-prefix}-zodiac-sagittarius:before { content: $ti-icon-zodiac-sagittarius; }
.#{$ti-prefix}-zodiac-scorpio:before { content: $ti-icon-zodiac-scorpio; }
.#{$ti-prefix}-zodiac-taurus:before { content: $ti-icon-zodiac-taurus; }
.#{$ti-prefix}-zodiac-virgo:before { content: $ti-icon-zodiac-virgo; }
.#{$ti-prefix}-zoom:before { content: $ti-icon-zoom; }
.#{$ti-prefix}-zoom-cancel:before { content: $ti-icon-zoom-cancel; }
.#{$ti-prefix}-zoom-cancel-filled:before { content: $ti-icon-zoom-cancel-filled; }
.#{$ti-prefix}-zoom-check:before { content: $ti-icon-zoom-check; }
.#{$ti-prefix}-zoom-check-filled:before { content: $ti-icon-zoom-check-filled; }
.#{$ti-prefix}-zoom-code:before { content: $ti-icon-zoom-code; }
.#{$ti-prefix}-zoom-code-filled:before { content: $ti-icon-zoom-code-filled; }
.#{$ti-prefix}-zoom-exclamation:before { content: $ti-icon-zoom-exclamation; }
.#{$ti-prefix}-zoom-exclamation-filled:before { content: $ti-icon-zoom-exclamation-filled; }
.#{$ti-prefix}-zoom-filled:before { content: $ti-icon-zoom-filled; }
.#{$ti-prefix}-zoom-in:before { content: $ti-icon-zoom-in; }
.#{$ti-prefix}-zoom-in-area:before { content: $ti-icon-zoom-in-area; }
.#{$ti-prefix}-zoom-in-area-filled:before { content: $ti-icon-zoom-in-area-filled; }
.#{$ti-prefix}-zoom-in-filled:before { content: $ti-icon-zoom-in-filled; }
.#{$ti-prefix}-zoom-money:before { content: $ti-icon-zoom-money; }
.#{$ti-prefix}-zoom-money-filled:before { content: $ti-icon-zoom-money-filled; }
.#{$ti-prefix}-zoom-out:before { content: $ti-icon-zoom-out; }
.#{$ti-prefix}-zoom-out-area:before { content: $ti-icon-zoom-out-area; }
.#{$ti-prefix}-zoom-out-area-filled:before { content: $ti-icon-zoom-out-area-filled; }
.#{$ti-prefix}-zoom-out-filled:before { content: $ti-icon-zoom-out-filled; }
.#{$ti-prefix}-zoom-pan:before { content: $ti-icon-zoom-pan; }
.#{$ti-prefix}-zoom-pan-filled:before { content: $ti-icon-zoom-pan-filled; }
.#{$ti-prefix}-zoom-question:before { content: $ti-icon-zoom-question; }
.#{$ti-prefix}-zoom-question-filled:before { content: $ti-icon-zoom-question-filled; }
.#{$ti-prefix}-zoom-replace:before { content: $ti-icon-zoom-replace; }
.#{$ti-prefix}-zoom-reset:before { content: $ti-icon-zoom-reset; }
.#{$ti-prefix}-zoom-scan:before { content: $ti-icon-zoom-scan; }
.#{$ti-prefix}-zoom-scan-filled:before { content: $ti-icon-zoom-scan-filled; }
.#{$ti-prefix}-zzz:before { content: $ti-icon-zzz; }
.#{$ti-prefix}-zzz-off:before { content: $ti-icon-zzz-off; }

// Aliases
.#{$ti-prefix}-123:before { content: $ti-icon-number-123; }
.#{$ti-prefix}-360:before { content: $ti-icon-view-360-arrow; }
.#{$ti-prefix}-code-asterix:before { content: $ti-icon-code-asterisk; }
.#{$ti-prefix}-discount-2:before { content: $ti-icon-rosette-discount; }
.#{$ti-prefix}-discount-2-off:before { content: $ti-icon-rosette-discount-off; }
.#{$ti-prefix}-discount-check:before { content: $ti-icon-rosette-discount-check; }
.#{$ti-prefix}-hand-rock:before { content: $ti-icon-hand-love-you; }
.#{$ti-prefix}-sort-deacending-small-big:before { content: $ti-icon-sort-descending-small-big; }
.#{$ti-prefix}-shi-jumping:before { content: $ti-icon-ski-jumping; }
.#{$ti-prefix}-box-seam:before { content: $ti-icon-package; }
.#{$ti-prefix}-kering:before { content: $ti-icon-kerning; }
.#{$ti-prefix}-2fa:before { content: $ti-icon-auth-2fa; }
.#{$ti-prefix}-3d-cube-sphere:before { content: $ti-icon-cube-3d-sphere; }
.#{$ti-prefix}-3d-cube-sphere-off:before { content: $ti-icon-cube-3d-sphere-off; }
.#{$ti-prefix}-3d-rotate:before { content: $ti-icon-rotate-3d; }
.#{$ti-prefix}-12-hours:before { content: $ti-icon-hours-12; }
.#{$ti-prefix}-24-hours:before { content: $ti-icon-hours-24; }
.#{$ti-prefix}-360-view:before { content: $ti-icon-view-360-number; }
.#{$ti-prefix}-circle-0:before { content: $ti-icon-circle-number-0; }
.#{$ti-prefix}-circle-1:before { content: $ti-icon-circle-number-1; }
.#{$ti-prefix}-circle-2:before { content: $ti-icon-circle-number-2; }
.#{$ti-prefix}-circle-3:before { content: $ti-icon-circle-number-3; }
.#{$ti-prefix}-circle-4:before { content: $ti-icon-circle-number-4; }
.#{$ti-prefix}-circle-5:before { content: $ti-icon-circle-number-5; }
.#{$ti-prefix}-circle-6:before { content: $ti-icon-circle-number-6; }
.#{$ti-prefix}-circle-7:before { content: $ti-icon-circle-number-7; }
.#{$ti-prefix}-circle-8:before { content: $ti-icon-circle-number-8; }
.#{$ti-prefix}-circle-9:before { content: $ti-icon-circle-number-9; }
.#{$ti-prefix}-hexagon-0:before { content: $ti-icon-hexagon-number-0; }
.#{$ti-prefix}-hexagon-1:before { content: $ti-icon-hexagon-number-1; }
.#{$ti-prefix}-hexagon-2:before { content: $ti-icon-hexagon-number-2; }
.#{$ti-prefix}-hexagon-3:before { content: $ti-icon-hexagon-number-3; }
.#{$ti-prefix}-hexagon-4:before { content: $ti-icon-hexagon-number-4; }
.#{$ti-prefix}-hexagon-5:before { content: $ti-icon-hexagon-number-5; }
.#{$ti-prefix}-hexagon-6:before { content: $ti-icon-hexagon-number-6; }
.#{$ti-prefix}-hexagon-7:before { content: $ti-icon-hexagon-number-7; }
.#{$ti-prefix}-hexagon-8:before { content: $ti-icon-hexagon-number-8; }
.#{$ti-prefix}-hexagon-9:before { content: $ti-icon-hexagon-number-9; }
.#{$ti-prefix}-square-0:before { content: $ti-icon-square-number-0; }
.#{$ti-prefix}-square-1:before { content: $ti-icon-square-number-1; }
.#{$ti-prefix}-square-2:before { content: $ti-icon-square-number-2; }
.#{$ti-prefix}-square-3:before { content: $ti-icon-square-number-3; }
.#{$ti-prefix}-square-4:before { content: $ti-icon-square-number-4; }
.#{$ti-prefix}-square-5:before { content: $ti-icon-square-number-5; }
.#{$ti-prefix}-square-6:before { content: $ti-icon-square-number-6; }
.#{$ti-prefix}-square-7:before { content: $ti-icon-square-number-7; }
.#{$ti-prefix}-square-8:before { content: $ti-icon-square-number-8; }
.#{$ti-prefix}-square-9:before { content: $ti-icon-square-number-9; }
.#{$ti-prefix}-message-circle-2:before { content: $ti-icon-message-circle; }
.#{$ti-prefix}-mood-suprised:before { content: $ti-icon-mood-surprised; }
.#{$ti-prefix}-circle-dashed-letter-letter-v:before { content: $ti-icon-circle-dashed-letter-v; }
.#{$ti-prefix}-discount-check-filled:before { content: $ti-icon-rosette-discount-check-filled; }
.#{$ti-prefix}-message-circle-2-filled:before { content: $ti-icon-message-circle-filled; }

