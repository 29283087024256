@import 'node_modules/@tabler/core/dist/css/tabler.css';

@import 'node_modules/bootstrap/scss/functions';
@import 'node_modules/bootstrap/scss/variables';
@import 'node_modules/bootstrap/scss/mixins';
@import 'node_modules/ngx-toastr/toastr-bs5-alert';

@import "node_modules/animate.css/animate";
@import "node_modules/@tabler/icons-webfont/dist/tabler-icons";

.app-options-dropdown .dropdown-toggle::after {
  display: none;
}

.modal-content .text-light {
  color: $gray-400 !important;
}

// fix for tabler 1.0.0.alpha6. Already fixed with commit 9e12536
.navbar-collapse .navbar {
  flex-grow: 1;
}

//.nav-link-icon {
//  height: inherit !important;
//}


.table tfoot td {
  border-bottom: none !important;
  padding: 0.5rem 0.5rem 0 0.5rem;
}

[role="button"] {
  cursor: pointer;
}
